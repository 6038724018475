import colors from 'style/palette';
import styled, { css } from 'styled-components';

export const PoolsTableBox = styled.div`
	padding: 1rem;
`;

export const Table = styled.table`
	border-collapse: collapse;
`;

export const TableRow = styled.tr<{ selected?: boolean }>`
	cursor: pointer;

	${({ selected }) =>
		selected &&
		css`
			background-color: ${colors.blueGrey};
		`}
`;

export const TableHead = styled.thead``;

export const TableHeadCell = styled.th`
	padding: 10px;
	border: 1px solid ${colors.white012};
	text-align: center;
`;

export const TableBody = styled.tbody``;

export const TableBodyCell = styled.td`
	padding: 10px;
	border: 1px solid ${colors.white012};
	text-align: center;
`;
