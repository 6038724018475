import { callPOST, callDELETE } from 'network/network';

const _USER_BADGES_PATH = 'v1/users';

export const addUserBadge = async (userId: number, badgeId: number) => {
	try {
		const response = await callPOST(_USER_BADGES_PATH + '/' + userId + '/badges/' + badgeId, {}, true);

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const removeUserBadge = async (userId: number, badgeId: number) => {
	try {
		const response = await callDELETE(_USER_BADGES_PATH + '/' + userId + '/badges/' + badgeId, {}, true);

		return response;
	} catch (error: any) {
		throw error;
	}
};
