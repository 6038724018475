import { FC, useEffect, useState } from 'react';

// hooks
import useHasRole from 'hooks/useHasRole';

//types
import { LevelDetailProps } from './LevelDetail.component.d';
import { TabSelectorSettings, TabSelectorItem } from 'components/TabSelector/TabSelector.component.d';

//components
import { BaseContentCard } from 'style/wrappers/wrappers';
import TabSelector from 'components/TabSelector';
import LevelTab from '../LevelTab';
import ContentTab from '../ContentTab';


const LevelDetail: FC<LevelDetailProps> = ({ level, putLevel, updateLevelData }) => {

	const [tabSelectorValue, setTabSelectorValue] = useState<string>('');

	const isAdmin = useHasRole('ADMIN');
	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isAnimator = useHasRole('ANIMATORE');
	const isCoach = useHasRole('COACH');
	const hasAdminRole = isAdmin || isSuperAdmin;
	const canSeeLevel = hasAdminRole || isAnimator || isCoach;
	
	const TAB_ITEMS: TabSelectorItem[] = [
		{
			title: 'Traguardo',
			value: 'level',
			display: canSeeLevel,
		},
		{
			title: 'Unità e lezioni',
			value: 'content',
		},
	];

	const tabSettings: TabSelectorSettings = { tabItems: TAB_ITEMS, defaultSelection: 'content', handleSelection: setTabSelectorValue };

	return (
		<>
			<TabSelector tabSelectorSettings={tabSettings}></TabSelector>
			<BaseContentCard>
				{tabSelectorValue === 'level' && (
					<LevelTab
						level={level}
						putLevel={putLevel!}
						updateLevelData={updateLevelData}
					></LevelTab>
				)}
				{tabSelectorValue === 'content' && (
					<ContentTab
						level={level}
						putLevel={putLevel!}
					></ContentTab>
				)}
			</BaseContentCard>
		</>
	);
};

export default LevelDetail;
