import { FC, useState } from 'react';

// hooks
import useHasRole from 'hooks/useHasRole';

//types
import { CourseDetailProps } from './CourseDetail.component.d';
import { TabSelectorSettings, TabSelectorItem } from 'components/TabSelector/TabSelector.component.d';

//components
import { BaseContentCard } from 'style/wrappers/wrappers';
import TabSelector from 'components/TabSelector';
import ProductTab from '../ProductTab';
import PercentagesTab from '../PercentagesTab';
import ContentTab from '../ContentTab';
import SalesPageTab from '../SalesPageTab';
import RequirementsTab from '../RequirementsTab';


const CourseDetail: FC<CourseDetailProps> = ({ product, putProduct, updateProductData }) => {

	const [tabSelectorValue, setTabSelectorValue] = useState<string>('');

	const isAdmin = useHasRole('ADMIN');
	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isAnimator = useHasRole('ANIMATORE');
	const hasAdminRole = isAdmin || isSuperAdmin;
	const canSeeCourse = hasAdminRole || isAnimator;
	
	const TAB_ITEMS: TabSelectorItem[] = [
		{
			title: 'Corso',
			value: 'course',
			display: canSeeCourse,
		},
		{
			title: 'Prezzo e %',
			value: 'percentages',
			display: hasAdminRole,
		},
		{
			title: 'Unità e lezioni',
			value: 'content',
		},
		{
			title: 'Pagina di vendita',
			value: 'salesPage',
			display: hasAdminRole,
		},
		{
			title: 'Requisiti',
			value: 'requirements',
			display: hasAdminRole,
		},
	];

	const tabSettings: TabSelectorSettings = { tabItems: TAB_ITEMS, handleSelection: setTabSelectorValue };


	return (
		<>
			<TabSelector tabSelectorSettings={tabSettings}></TabSelector>
			<BaseContentCard>
				{tabSelectorValue === 'course' && (
					<ProductTab
						product={product}
						putProduct={putProduct!}
						updateProductData={updateProductData}
					></ProductTab>
				)}
				{tabSelectorValue === 'percentages' && (
					<PercentagesTab
						product={product}
						putProduct={putProduct!}
					></PercentagesTab>
				)}
				{tabSelectorValue === 'content' && (
					<ContentTab
						product={product}
						putProduct={putProduct!}
					></ContentTab>
				)}
				{tabSelectorValue === 'salesPage' && product.salesData && (
					<SalesPageTab
						product={product}
						putProduct={putProduct!}
						updateProductData={updateProductData}
					></SalesPageTab>
				)}
				{tabSelectorValue === 'requirements' && (
					<RequirementsTab
						product={product}
						putProduct={putProduct}
						updateProductData={updateProductData}
					></RequirementsTab>
				)}
			</BaseContentCard>
		</>
	);
};

export default CourseDetail;
