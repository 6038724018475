import { FC, useEffect, useState } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsMounted } from 'usehooks-ts';

//assets
import { ReactComponent as IsPinnedIcon} from './assets/isPinnedIcon.svg';

//types
import { IsPinnedBroadcastProps } from './IsPinnedBroadcast.component.d';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

//utils

//style
import { IsPinnedBroadcastBox, IsPinnedBroadcastIcon, PriceType, PriceTypeSpec } from './style/IsPinnedBroadcast.component.style';
import useHasRole from 'hooks/useHasRole';

const IsPinnedBroadcast: FC<IsPinnedBroadcastProps> = ({ product, putProduct, disabled }) => {
	const dispatch = useAppDispatch();
	const isMounted = useIsMounted();

	const isAdmin = useHasRole('ADMIN');
	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');

	const clickHandler = async () => {
		
		const snackbarData: SnackbarOptions = {};

		try {
			await putProduct({ id: product.id, productThematicCall: {...product.productThematicCall, pinned: !product.productThematicCall.pinned }});
		} catch (error) {
			snackbarData.type = 'error';
			snackbarData.message = "Errore nell'aggiornamento messa in evidenza";
			dispatch(addSnackbar(snackbarData));
			if (!isMounted()) return;
		}
	};

	return (
		<IsPinnedBroadcastBox>
			<IsPinnedBroadcastIcon ispinnedbroadcast={product.productThematicCall.pinned} canToggle={true} onClick={clickHandler} title={"clicca per "+(product.productThematicCall.pinned ? "de-pinnare" : "pinnare")}><IsPinnedIcon></IsPinnedIcon></IsPinnedBroadcastIcon>
		</IsPinnedBroadcastBox>
	);
};

export default IsPinnedBroadcast;
