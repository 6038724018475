import { useEffect, useRef } from 'react';
import { select } from 'd3';

const useD3 = (renderChartFn: any, dependencies: any[]) => {

    const ref = useRef<SVGSVGElement | null>(null);

    useEffect(() => {

        renderChartFn(select(ref.current));
        return () => {};
    }, dependencies);

    return ref;
};

export default useD3;
