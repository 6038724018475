import { callGET } from 'network/network';

import { OldPaymentsSearchParams } from './OldPaymentsPage.services.d';

const _HIGHWAYROUTES_SEARCH_PATH = 'v1/purchases/old';

export const getSearchOldPayments = async (paymentsSearchParams: OldPaymentsSearchParams) => {
	try {
		const response = await callGET(_HIGHWAYROUTES_SEARCH_PATH, true, paymentsSearchParams);

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};
