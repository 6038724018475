import { FC, useEffect, useState } from 'react';

//types
import { LevelsTableProps } from './LevelsTable.component.d';
import { LevelsTableColumn } from './LevelsTable.component.d';

//utils
import config from 'configs/config';

//assets
import emptyPicIconSVG from './assets/emptyPicIcon.svg';
import { ReactComponent as LinkIcon } from './assets/linkIcon.svg';
import DefaultLevelPic from './assets/defaultLevelPic.png';

//components
import Visible from './Visible';
import RowButtons from './RowButtons';

//style
import { Table, TableBody, TableBodyCell, TableBodyRow, TableHead, TableHeadCell, TableHeadRow, SellImage, LevelName, Subscriptions } from './style/LevelsTable.style';
import useHasRole from 'hooks/useHasRole';



const url = window.location.origin;
const online = !url.includes('localhost');
const test = url.includes('ldl-test') || !online;

const salesPageBaseUrl = test ? config.testUrl : config.productionUrl;

const LevelsTable: FC<LevelsTableProps> = ({ data, putLevel }) => {

	var columns: LevelsTableColumn[] = [
		{ label: '', width: '3%' },
		{ label: 'ID', width: '2%' },
		{ label: 'DESCRIZIONE', width: '80%' },
		{ label: 'TRAGUARDO', width: '15%' },
	];

	const isAdmin = useHasRole('ADMIN');
	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');
	const hasAdminRole = isAdmin || isSuperAdmin;
	
	return (
		<>
			<Table>
				<TableHead>
					<TableHeadRow>
						{columns.map((c) => (
							<TableHeadCell
								key={c.label}
								width={c.width}
								// onClick={() => handleSort(key)}
							>
								{c.label.toUpperCase()}
								{/* {sortColumn === c && <span>{sortDirection === 'asc' ? '⬆️' : '⬇️'}</span>} */}
							</TableHeadCell>
						))}
					</TableHeadRow>
				</TableHead>
				<TableBody>
					{data.map((row, index) => (
						<TableBodyRow key={index}>
							<TableBodyCell
								key={index + '_buttons'}
								id={index + '_buttons'}
							>
								<RowButtons levelId={row.id} />
							</TableBodyCell>
							<TableBodyCell
								key={index + '_id'}
								id={index + '_id'}
							>
								{row.id}
							</TableBodyCell>
							<TableBodyCell
								key={index + '_description'}
								id={index + '_description'}
							>
								<LevelName>
									{row.description}
								</LevelName>
							</TableBodyCell>
							<TableBodyCell
								key={index + '_pathlevel'}
								id={index + '_pathlevel'}
							>
								{row.pathLevel}
							</TableBodyCell>
						</TableBodyRow>
					))}
				</TableBody>
			</Table>

			{/* {totalElements > 0 && (
				<TableFooter>
					<FooterElementsCount>{footerElementsCount({ currentPage: pageNumber, totalPages, totalElements, pageSize })}</FooterElementsCount>
					<FooterNavigationButton
						onClick={prevPageHandler}
						disabled={pageNumber === 1}
					>
						<ChevronLeftIcon />
					</FooterNavigationButton>
					<FooterNavigationButton
						onClick={nextPageHandler}
						disabled={pageNumber === totalPages}
					>
						<ChevronRightIcon />
					</FooterNavigationButton>
				</TableFooter>
			)} */}
		</>
	);
};

export default LevelsTable;
