import { FC } from 'react';

// types
import { FacebookLoginButtonProps } from './FacebookLoginButton';

// assets
import { ReactComponent as FacebookIconSVG } from './assets/facebook-icon.svg';

// style
import { Button } from './style/FacebookLoginButton.style';


/**
 * Facebook button
 * 
 * @author Alessio Grassi
 * 
 * @param onClick - login handler
 * @param children - button text
 * 
 * @returns JSX
 */


const FacebookLoginButton: FC<FacebookLoginButtonProps> = ({ onClick, children }) => {

    const clickHandler = () => {

        if (!onClick) return;
        onClick();
    };

    return (
        <Button
            type='button' 
            onClick={clickHandler}
        >
            <FacebookIconSVG />

            {children}
        </Button>
    );
};

export default FacebookLoginButton;
