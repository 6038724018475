import styled, { css } from 'styled-components';
import colors from 'style/palette/palette';

export const Wrapper = styled.div`
  overflow-x: scroll;
  width: 99%;
  max-height: 200px;
`;

interface ListItemProps {
  isSelected: boolean;
  isOriginal: boolean;
}

export const ListItem = styled.div<ListItemProps>`
  padding: 8px;
  background-color: ${({ isSelected, isOriginal }) => (isSelected ? isOriginal ? colors.primaryColor : colors.lightBlue : '#fff')};
  cursor: pointer;
  user-select: none;
`;
