import styled, { css } from 'styled-components';

import colors from 'style/palette/palette';

export const GenericInfoBox = styled.div`
	margin: 1rem;
	color: ${colors.white};
`;

export const RowBox = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-around;
`;

export const FieldBox = styled.div`
	padding: 1rem;
	display: flex;
`;

export const ProductImageContainer = styled.div`
	display: flex;
	align-items: stretch;
`;

export const ProductImage = styled.img`
	width: 7rem;
	align-self: flex-start;

	cursor: pointer;
`;

export const ImageEditIconOverlay = styled.div`
	margin-left: -16px;

	> svg {
            min-width: 15px;
            min-height: 15px; 
			filter: brightness(0) saturate(100%) invert(84%) sepia(30%) saturate(5368%) hue-rotate(359deg) brightness(103%) contrast(105%);       
    }
`;

export const ProductImageNewFile = styled.input.attrs({ 
    type: 'file',
  })`
    display: none;
`;

export const InfoLabel = styled.div`
	padding: 0.5rem;
	margin: 1rem;
	font-weight: bold;
	min-width: 25%;
	color: ${colors.white};
	text-transform: uppercase;
`;

export const InfoContent = styled.div<{ type?: string }>`
	padding: 0.5rem;
	color: ${colors.white050};
	${({ type }) =>
		type === 'email' &&
		css`
			cursor: copy;
		`}
`;

export const PreviewButton = styled.button``;