import styled from 'styled-components';

export const CategoriesBox = styled.div`
	display: flex;
	gap: 0.5rem;
	flex-wrap: wrap;
	padding: 1rem;
`;

export const ColumnBox = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;
