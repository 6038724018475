import { FC } from 'react';

//types
import { StatsProps } from './Stats.component.d';

//style
import { StatsTable, StatsTableBody, StatsTableCellTitle, StatsTableCellDate, StatsTableRow } from './style/Stats.component.style';

const Stats: FC<StatsProps> = ({ signupDate, lastLoginDate, firstPurchaseDate, lastPurchaseDate }) => {
	return (
		<StatsTable>
			<StatsTableBody>
				<StatsTableRow>
					<StatsTableCellTitle>Iscriz.</StatsTableCellTitle>
					<StatsTableCellDate>{signupDate ? new Date(signupDate).toLocaleDateString() : '-'}</StatsTableCellDate>
				</StatsTableRow>
				<StatsTableRow>
					<StatsTableCellTitle>Login</StatsTableCellTitle>
					<StatsTableCellDate>{lastLoginDate ? new Date(lastLoginDate).toLocaleDateString() : '-'}</StatsTableCellDate>
				</StatsTableRow>
				<StatsTableRow>
					<StatsTableCellTitle>1° acq</StatsTableCellTitle>
					<StatsTableCellDate>{firstPurchaseDate ? new Date(firstPurchaseDate).toLocaleDateString() : '-'}</StatsTableCellDate>
				</StatsTableRow>
				<StatsTableRow>
					<StatsTableCellTitle>Ult acq.</StatsTableCellTitle>
					<StatsTableCellDate>{lastPurchaseDate ? new Date(lastPurchaseDate).toLocaleDateString() : '-'}</StatsTableCellDate>
				</StatsTableRow>			
			</StatsTableBody>
		</StatsTable>
	);
};

export default Stats;
