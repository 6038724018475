import styled from 'styled-components';


interface TabSelectorBarProps {
    display?: boolean;
}
interface TabSelectorTabProps {
    isActive?: boolean;
}

export const TabSelectorBox = styled.div``;

export const TabSelectorBar = styled.div<TabSelectorBarProps>`
    display: ${props => (props.display ? 'flex' : 'none')};
    flex-direction: row;
    justify-content: flex-end;

    width: 100%;
    height: 36px;
    padding: 0 25px 0 0;

    button:first-of-type {
        border-top-left-radius: 4px;
    }

    button:last-of-type {
        border-top-right-radius: 4px;
    }
`;

export const TabSelectorTab = styled.button<TabSelectorTabProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    gap: 10px;
    border: none;
    outline: none;
    background-color: ${props => (props.isActive ? '#4C5862' : '#A9B8C2')};
    font-size: 14px;
    margin-right: 0;
    color: ${props => (props.isActive ? '#ffffff' : '000000')};
    border-right: 1px solid rgba(0, 0, 0, 0.87);
    cursor: pointer;
`;