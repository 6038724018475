import colors from 'style/palette/palette';
import styled from 'styled-components';

export const ModifyLessonBox = styled.div`
	display: flex;
	flex-direction: column;
	width: 700px;
	color: ${colors.white};
	padding: 2rem;
	gap: 1rem;
	background-color: ${colors.veryDarkGrey};
	border-radius: 15px;
`;

export const Label = styled.label``;

export const ButtonsRow = styled.div`
	display: flex;
	width: 70%;
	align-self: center;
	align-items: center;
	justify-content: space-around;
`;

export const LessonSaveButton = styled.button`
	width: 40%;
	/* color: ${colors.green}; */
`;

export const LessonCancelSaveButton = styled.button`
	width: 40%;
	color: ${colors.red};
`;
