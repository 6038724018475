const ADMIN_ROLES = [
    1, // Superadmin
    5, // Admin
    16, // Miniadmin
    25, // Imprenditore
    26, // Hostess
    33, // Admin Autostrada
    37, // Roadmap
];

const AUTHOR_ROLES = [
    1, // Superadmin
    6, // Autore
    7, // Divulgatore
    9, // Marketing
    10, // Divulgatore Pro
    15, // Personal Trainer
    18, // Angolo Staking
    19, // Aspirante Staff
    21, // Soccorritore
    24, // Webinar Manager
    29, // Webinar Host
    26, // Hostess
    32, // Colpo Sicuro
    33, // Admin Autostrada
    34, // Assistente Personale
    36, // Supervisore Autostrada
    37, // Roadmap
];

export const checkIfUserIsAdmin = (userRolesData: any) => {
        
    if (!userRolesData) return false;
    return userRolesData.some((role: number) => ADMIN_ROLES.includes(role));
};

export const checkIfUserIsAuthor = (userRolesData: any) => {
    
    if (!userRolesData) return false;
    return userRolesData.some((role: number) => AUTHOR_ROLES.includes(role));
};
