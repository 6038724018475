import styled, { css } from 'styled-components';

//utlis
import { getEnvironmentLinkUrlForCSS } from 'configs/config.helper';

// types
import { MainProps } from './App.style.d';

// assets
import appLogo from 'assets/imgs/LDL-icon.svg';
import mainBackground from 'assets/imgs/main-background.svg';

// style
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';

const backgroundUrl = getEnvironmentLinkUrlForCSS(mainBackground);

export const MinRangeGuard = styled.div`

    min-width: 100vw;
    min-height: 100vh;

    background: ${colors.grey};
    background-image: url(${appLogo});
    background-repeat: no-repeat;
    background-position: center;

    color: ${colors.white};

    overflow: hidden;

    @media ${devices.appMinWidth} {

        display: none;
    }
`;

export const AppBox = styled.div`

    width: 100%;

    display: none;

    overflow-x: hidden;
    overflow-y: auto;

    @media ${devices.appMinWidth} {

        display: block;
    }
`;

export const Main = styled.div<MainProps>`

    display: flex;
    align-items: center;
    justify-content: flex-start;

    flex-direction: column;

    width: 100%;
    
    background: ${colors.grey};
    background-image: ${backgroundUrl};
    background-repeat: no-repeat;
    background-position: top right;
    background-size: auto;

    overflow-y: auto;
    overflow-x: hidden;

    /* totalHeight - headerHeight */
    /* max-height: calc(100vh - 69.5px); */
    max-height: calc(100vh - (100vh - ${window.innerHeight}px) - 69.5px);

    @media ${devices.tablet} {

        padding: 69px 0 50px;

        background-size: 100%;

        overflow-y: visible;
        overflow-x: visible;

        height: auto;
        max-height: unset;
        /* totalHeight - headerHeight - footerHeight */
        min-height: calc(100vh - 69.5px);
    }

    ${({ isDisabled }) => isDisabled && (

        css`
            filter: blur(15px);

            overflow-y: hidden;
            overflow-x: hidden;
        `
    )}
`;

export const PageContainer = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;

    flex-direction: column;

    flex: 1;

    width: 100%;
    max-width: 1440px;
    
    margin: 0 auto;

    height: inherit;
    /* min-height: calc(100vh - 69.5px - 69.5px); */
`;

export const PageBackgroundOnSubmenuOpen = styled.div`

    width: 100%;
    height: inherit;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;

    background: ${colors.grey};
    background-image: url(${mainBackground});
    background-repeat: no-repeat;
    background-position: top right;
    background-size: auto;
`;
