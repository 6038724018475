import { FC, useState } from 'react';

//types
import { ProductsProps } from './Products.component.d';

//style
import { Courses, ProductsBox, Services, Lessons, ThematicBroadcasts, Initiatives, Row } from './style/Products.component.style';

//assets
import { ReactComponent as CoursesIcon } from './assets/coursesIcon.svg';
import { ReactComponent as ServicesIcon } from './assets/servicesIcon.svg';
import { ReactComponent as LessonsIcon } from './assets/lessonsIcon.svg';
import { ReactComponent as ThematicBroadcastsIcon } from './assets/thematicBroadcastsIcon.svg';
import { ReactComponent as InitiativesIcon } from './assets/initiativesIcon.svg';

const Products: FC<ProductsProps> = ({ productSubscriptions, onClickHandler }) => {
	const coursesNumber = productSubscriptions.filter(p => p.product.type === 'COURSE' && (p.deletionDate === undefined || p.deletionDate === null)).length;
	const servicesNumber = productSubscriptions.filter(p => p.product.type === 'SERVICE' && (p.deletionDate === undefined || p.deletionDate === null)).length;	
	const lessonsNumber = productSubscriptions.filter((p) => p.product.type === 'LESSON_PACK' && (p.deletionDate === undefined || p.deletionDate === null)).length;
	const thematicBroadcastsNumber = productSubscriptions.filter((p) => p.product.type === 'THEMATIC_BROADCAST' && p.product.productThematicCall.type !== 'ESPERIENZA' && (p.deletionDate === undefined || p.deletionDate === null)).length;
	const initiativesNumber = productSubscriptions.filter((p) => p.product.type === 'THEMATIC_BROADCAST' && p.product.productThematicCall.type === 'ESPERIENZA' && (p.deletionDate === undefined || p.deletionDate === null)).length;

	const clickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
		onClickHandler(event, productSubscriptions);
	};

	return (
		<ProductsBox onClick={clickHandler}>
			<Row>
				<Courses>
					<CoursesIcon />
					{coursesNumber}
				</Courses>
				<Services>
					<ServicesIcon />
					{servicesNumber}
				</Services>
			</Row>
			<Row>
				<Lessons>
					<LessonsIcon />
					{lessonsNumber}
				</Lessons>
				<ThematicBroadcasts>
					<ThematicBroadcastsIcon />
					{thematicBroadcastsNumber}
				</ThematicBroadcasts>
			</Row>
			<Row>
				<Initiatives>
					<InitiativesIcon />
					{initiativesNumber}
				</Initiatives>
			</Row>
		</ProductsBox>
	);
};

export default Products;
