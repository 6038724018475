import React, { FC } from 'react';

//types
import { ContentTabProps } from './ContentTab.component.d';

//components
import ContentContainer from './ContentContainer/ContentContainer.component';

//style
import { MainBox } from './style/ContentTab.component.style';

const ContentTab: FC<ContentTabProps> = ({ level, putLevel }) => {
	return (
		<MainBox>
			<ContentContainer
				level={level}
				putLevel={putLevel}
			/>
		</MainBox>
	);
};

export default ContentTab;
