// routing
import { Outlet } from 'react-router-dom';

const Products = () => {
	return (
		<>
			<Outlet />
		</>
	);
};

export default Products;
