import { FC } from 'react';

//types
import { PoolsTableProps } from './PoolsTable.component.d';

//style
import { PoolsTableBox, Table, TableBody, TableBodyCell, TableHead, TableHeadCell, TableRow } from './style/PoolsTable.component.style';

const PoolsTable: FC<PoolsTableProps> = ({ pools, selectedSelfPoolId, setSelectedSelfPoolId }) => {
	return (
		<PoolsTableBox>
			<Table>
				<TableHead>
					<TableRow>
						<TableHeadCell>ID</TableHeadCell>
						<TableHeadCell>NOME</TableHeadCell>
						<TableHeadCell>TIPO</TableHeadCell>
						<TableHeadCell>SALDO TOT</TableHeadCell>
						<TableHeadCell>SALDO DISPO</TableHeadCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{pools.map((pool, index) => {
						return (
							<TableRow
								key={index}
								onClick={() => setSelectedSelfPoolId(pool.id)}
								selected={pool.id === selectedSelfPoolId}
							>
								<TableBodyCell>{pool.id}</TableBodyCell>
								<TableBodyCell>{pool.name}</TableBodyCell>
								<TableBodyCell>{pool.type}</TableBodyCell>
								<TableBodyCell>{pool.balance.toLocaleString('it-IT')}</TableBodyCell>
								<TableBodyCell>{pool.availableBalance.toLocaleString('it-IT')}</TableBodyCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</PoolsTableBox>
	);
};

export default PoolsTable;
