import { FC, useEffect, useState } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';

//types
import { AddLevelModalProps } from './AddLevelModal.component.d';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

//components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';

//utils
import { addLevel } from '../services/LevelsPage.services';

//style
import { CardContent, CloseButton, IconBox, LevelName, TextContent, TextDisclaimer, Title } from './style/AddLevelModal.component.style';
import { Product } from 'types/Product';
import { ProductCourse } from 'types/ProductCourse';
import { ProductService } from 'types/ProductService';
import { ProductLessonPack } from 'types/ProductLessonPack';
import { Pool } from 'types/Pool';
import { ProductPool } from 'types/ProductPool';
import { useNavigate } from 'react-router-dom';
import { enableAppMain } from 'redux/slices/slice-app-main';

const AddLevelModal: FC<AddLevelModalProps> = ({ onCloseButtonModal }) => {
	const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);
	const [productType, setProductType] = useState('COURSE');
	const [productName, setProductName] = useState('');
	const [isAddEnabled, setIsAddEnabled] = useState(false);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const closeButtonHandler = () => {
		if (onCloseButtonModal) onCloseButtonModal();
		dispatch(enableAppMain());
	};

	const onProductTypeChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setProductType(event.currentTarget.value);
	};

	const onProductNameChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setProductName(event.currentTarget.value);
	};

	const addProductHandler = () => {
		if (window.confirm(`Aggiungere il prodotto di tipo ${productType} e nome "${productName}" ?`)) {
			callAddProduct();
		}
	};

	const callAddProduct = async () => {
		const snackbarData: SnackbarOptions = {};
		const newProduct: Partial<Product> = { name: productName, visible: false };
		if (productType === 'COURSE') newProduct.productCourse = { price: 0 } as ProductCourse;
		if (productType === 'SERVICE') newProduct.productService = {} as ProductService;
		if (productType === 'LESSON_PACK') newProduct.productLessonPack = { price: 0, type: { id: 1 }, lessonsNumber: 1 } as ProductLessonPack;
		try {
			const response = await addLevel(newProduct);
			console.log(response);

			snackbarData.type = 'success';
			snackbarData.message = 'Prodotto aggiunto correttamente';
			await dispatch(addSnackbar(snackbarData));
			if (onCloseButtonModal) onCloseButtonModal();
			console.log('reservedArea/products/' + response.id);

			navigate('/reservedArea/products/' + response.id);
		} catch (error: any) {
			snackbarData.type = 'error';
			snackbarData.message = 'Non è stato possibile aggiungere il prodotto.';
			dispatch(addSnackbar(snackbarData));
		}
	};

	useEffect(() => {
		if (productName?.length > 0 && productType?.length > 0) setIsAddEnabled(true);
	}, [productName, productType]);

	return (
		<BaseModal
			isBackdropClickActive={true}
			onClose={closeButtonHandler}
			isCloseButtonClicked={isCloseButtonClicked}
		>
			<BaseCard
				flavor='outlined'
				maxWidth='700px'
			>
				<CardContent>
					<LevelName>
						<label>Nome traguardo:</label>
						<input
							type='text'
							size={50}
							value={productName}
							onChange={onProductNameChangeHandler}
						></input>
					</LevelName>
					<button
						disabled={!isAddEnabled}
						onClick={addProductHandler}
					>
						Aggiungi traguardo
					</button>
				</CardContent>
			</BaseCard>
		</BaseModal>
	);
};

export default AddLevelModal;
