import { updateSalesData } from 'pages/PublicProductPages/services/PublicProductPage.services';
import { FC, useRef } from 'react';

// type
import { ButtonUpdateElementProps } from './ButtonUpdateElement.component.d';

// style
import { 
    CardContent,
    ButtonRow,
    RowContent,
    CloseAndCancel,
    SaveAndCloseButton,
    DeleteElementButton,
    UrlInput,
    UrlLabel    
} from './style/ButtonUpdateElement.style';


const ButtonUpdateElement: FC<ButtonUpdateElementProps> = ({
    onClose,
    updateData,
    productId,
    isRequired
}) => {
    
    const buttonRef = useRef<HTMLInputElement | null>(null);

    const updateText = async () => {

        // to do snackbar and spinner
        try {

            if (buttonRef.current === null) return;
            if (productId === undefined) return;

            const updateData = {
                productId,
                purchaseButtonText: buttonRef.current.value
            };
            if (productId) await updateSalesData(updateData);
        } catch (error: any) {
            console.warn("ERROR:", error);
        }
    };

    const onCancelClickHandler = () => {
        onClose();
    };

    const onSaveAndCloseHandler = async () => {
        
        await updateText();
        if (updateData) await updateData();
        onClose();
    };

    return (
        <CardContent>
            <RowContent>
                <UrlLabel>
                    Nuovo testo del bottone

                    <UrlInput 
                        ref={buttonRef}
                    />
                </UrlLabel>
            </RowContent>

            <ButtonRow>
                {/* {!isRequired && (
                    <DeleteElementButton>
                        Elimina il bottone
                    </DeleteElementButton>
                    )
                } */}

                <CloseAndCancel
                        onClick={onCancelClickHandler}
                >
                    Annulla e Chiudi
                </CloseAndCancel>

                <SaveAndCloseButton
                        onClick={onSaveAndCloseHandler}
                >
                    Salva e Chiudi
                </SaveAndCloseButton>
            </ButtonRow>
        </CardContent>
    );
};

export default ButtonUpdateElement;
