import { callGET, callPUT } from 'network/network';

import { myLessonsSearchParams } from './MyLessonsPage.services.d';
import { apLessonsSearchParams } from './MyLessonsPage.services.d';
import { BrandBox } from 'App/layout/Header/style/Header.style';

import { getBrand } from 'configs/brandMap/brandMap.helper';

const _MYLESSONS_SEARCH_PATH = 'v1/coaches/subscriptions';
const _MYLESSONS_ASSIGN_PATH = 'v1/productlessonpacks/assignlesson/{lessonId}/{apId}';
const _MYLESSONS_PACK_ASSIGN_PATH = 'v1/productlessonpacks/subscriptions/{subscriptionId}/assign/{apId}';
const _MYLESSONS_CLOSE_PATH = 'v1/productlessonpacks/closelessoncoach/{lessonId}';
const _APLESSONS_SEARCH_PATH = 'v1/productlessonpacks/subscriptions';
const _COACHES_GET_PATH = 'v1/coaches';

const brand = getBrand();


export const getSearchMyLessons = async (myLessonsSearchParams: myLessonsSearchParams) => {
	try {
		const response = await callGET(_MYLESSONS_SEARCH_PATH, true, myLessonsSearchParams);

		if (response === undefined || !response) {
			throw new Error();
		}
		console.log(response);

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const assignLessonToAP = async (apLessonId: number, apId: number) => {
	const brand = getBrand();
	if (!brand) return;
    try { 
        const response = await callPUT(_MYLESSONS_ASSIGN_PATH.replace(
			/{lessonId}/g,
			apLessonId.toString()
		  ).replace(
			/{apId}/g,
			apId.toString()
		  ), {}, true, { brandCode: brand.code });

        if (response === undefined || !response) {
            throw new Error();
        }

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const assignPackToAP = async (subscriptionId: number, apId: number) => {
	const brand = getBrand();
	if (!brand) return;
    try { 
        const response = await callPUT(_MYLESSONS_PACK_ASSIGN_PATH.replace(
			/{subscriptionId}/g,
			subscriptionId.toString()
		  ).replace(
			/{apId}/g,
			apId.toString()
		  ), {}, true, { brandCode: brand.code });

        // if (response === undefined || !response) {
        //     throw new Error();
        // }

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const closeAPLesson = async (apLessonId: number, coachNotes: string, coachPrivateNotes: string) => {
	const brand = getBrand();
	if (!brand) return;
    try { 
        const response = await callPUT(_MYLESSONS_CLOSE_PATH.replace(
			/{lessonId}/g,
			apLessonId.toString()
		  ), {}, true, { brandCode: brand.code });

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const getSearchHuntersLessons = async (apLessonsSearchParams: apLessonsSearchParams) => {
	try {
		const response = await callGET(_APLESSONS_SEARCH_PATH, true, apLessonsSearchParams);

		if (response === undefined || !response) {
			throw new Error();
		}
		console.log(response);

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const getCoaches = async () => {
	try {
		const response = await callGET(_COACHES_GET_PATH, true);

		if (response === undefined || !response) {
			throw new Error();
		}
		console.log(response);

		return response;
	} catch (error: any) {
		throw error;
	}
};
