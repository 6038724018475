import styled from 'styled-components';
import devices from '../breakpoints';
import colors from 'style/palette/palette';


export const Button = styled.button`

    width: auto;

    margin: 0;
    padding: 12px 30px;

    background: ${colors.primaryColor};

    border: none;
    border-radius: 4px;
    box-shadow:
        0 3px 1px -2px ${colors.black020},
        0 2px 2px ${colors.black014},
        0 1px 5px ${colors.black012};

    display: inline-flex;
    align-items: center;
    justify-content: center;

    font-size: 14px;
    font-weight: 600;
    color: ${colors.black087};
    letter-spacing: 0.4px;
    text-transform: uppercase;
    white-space: nowrap;

    cursor: pointer;

    :disabled {

        background: ${colors.white050};
        color: ${colors.black087};

        cursor: default;
    }

    @media ${devices.tablet} {

        font-size: 15px;
        padding: 15px 20px;
    }
`;
