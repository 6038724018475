import colors from 'style/palette/palette';
import styled from 'styled-components';

	interface EditableInputBoxProps {
		localContent?: string;
		placeholder?: string;
	}
  
  export const EditableInputBox = styled.input<EditableInputBoxProps>`
	background-color: ${({ localContent }) => localContent === '' ? 'rgba(0, 0, 0, 0.3)' : 'transparent'};
	border: none;
	width: 100%;
	padding-right: 5px;
	font-size: 1rem;
	color: ${colors.white};

	${props =>
		!props.disabled &&
		`
		:hover,
		:focus {
			background-color: ${colors.blueGrey};
			color: ${colors.black};
		}
	`}
`;

export const TitleModify = styled.div`
	margin-left: -2rem;
`;
