import styled, { css } from 'styled-components';

import colors from 'style/palette/palette';

export const UserSingleButton = styled.button<{ name: string }>`
	display: flex;
	background-color: transparent;
	border: none;
	cursor: pointer;

	${({ name }) =>
		name &&
		css`
			> svg path {
				fill: ${name === 'modify' ? colors.lightBlue : colors.primaryColor};
				fill-opacity: 1;
			}
		`}
`;
