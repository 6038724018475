const _AWS_AMPLIFY_ERROR_MESSAGES: any = Object.freeze({

    AccessDeniedException: "Non si dispone dei livelli di accesso per questa azione",
    CodeDeliveryFailureException: "Operazione fallita il codice di verifica non è stato consegnato al backend",
    InvalidParameterException: "Operazione fallita uno dei parametri non è valido",
    IncompleteSignature: "La richiesta non segue gli standard di AWS",
    InternalFailure: "La richiesta è fallita per un errore interno di AWS",
    InvalidAction: "L'operazion è invalida",
    InvalidClientTokenId: "Il client ha un token ID invalido",
    InvalidParameterCombination: "Richiesta fallita perchè più paramentri sono stati usati insieme",
    InvalidParameterValue: "Richiesta fallita perchè sono stati forniti parametri invalidi",
    InvalidPasswordException: "Operazione fallita la password non è valida",
    InvalidQueryParameter: "Richiesta fallita la query non rispetta lo standard di AWS",
    InternalErrorException: "Il Server ha riscontrato qualche problema",
    InvalidUserPoolConfigurationException: "Operazione fallita il sito ha inviato l'utente in modo sbagliato",
    MalformedQueryString: "Richiesta fallita la query contiene errori di sintassi",
    MissingAction: "Richiesta fallita perchè mancano dei parametri",
    MissingAuthenticationToken: "Richiesta fallita il token di autenticazione non è valido",
    MissingParameter: "Richiesta fallita manca un parametro",
    NotAuthorized: "Richiesta fallita non si dispone dei permessi necessari",
    NotAuthorizedException: "Operazione fallita l'utente non è autorizzato",
    OptInRequired: "Richiesta fallita è necessario fornire una iscrizione alla chiave di accesso",
    PasswordResetRequiredException: "Operazione fallita è necessario resettare la password prima di accedere",
    ResourceNotFoundException: "Operazione fallita non è stata trovata la risorsa richiesta",
    RequestExpired: "Richiesta fallita sono trascorsi più di quindici minuti",
    ServiceUnavailable: "Richiesta fallita il servizio non è disponibile",
    ThrottlingException: "Richiesta fallita perchè utilizza throttling",
    TooManyRequestsException: "Operazione fallita sono state inviate troppe richieste",
    UserNotConfirmedException: "Operazione fallita l'utente non ha confermato la registrazione",
    UsernameExistsException: "Operazione fallita la email è giá in uso",
    UserNotFoundException: "Operazione fallita l'utente non è stato trovato",
    ValidationError: "Richiesta fallita gli input forniti non sono validi",
    default: "Si è verificato un problema con AWS"
});

export const getAwsAmplifyErrorMessage = (errorType?: string | undefined) => {

    if (errorType === undefined || typeof errorType !== 'string') {
        return _AWS_AMPLIFY_ERROR_MESSAGES.default;
    }

    return _AWS_AMPLIFY_ERROR_MESSAGES[errorType];
};
