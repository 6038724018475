import { FC, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';

// redux
import { disableAppMain, enableAppMain } from 'redux/slices/slice-app-main';

// hooks
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsMounted } from 'usehooks-ts';

// utils
import { getEnvironmentMonolithLinkUrl } from 'configs/config.helper';
import { getUserBalance, getUsersRoles } from './services/UserProfileSubmenu.services';
import { checkIfUserIsAdmin, checkIfUserIsAuthor } from './UserProfileSubmenu.helper';

// components
import IconTextBox from './IconTextBox';
import LogoutButton from './LogoutButton';

// types
import { UserProfileSubmenuProps } from './UserProfileSubmenu.d';

// assets
import { ReactComponent as UserBalanceIconSVG } from './assets/user-balance-icon.svg';
import { ReactComponent as AccountIconSVG } from './assets/account-icon.svg';
import { ReactComponent as ProductsIconSVG } from './assets/products-icon.svg';
import { ReactComponent as LessonsIconSVG } from './assets/lessons-icon.svg';
import { ReactComponent as SubscriptionSVG } from './assets/subscription-icon.svg';
import { ReactComponent as AutomaticSVG } from './assets/automatic-icon.svg';
import { ReactComponent as AdminSVG } from './assets/admin-icon.svg';
import { ReactComponent as AuthorSVG } from './assets/author-icon.svg';
import { ReactComponent as OddsscasserSVG } from './assets/oddsscasser-icon.svg';
import { ReactComponent as SupportSVG } from './assets/support-icon.svg';
import { ReactComponent as GuadagniSVG } from './assets/guadagni-icon.svg';

// style
import { Row, Col } from 'style/layout';
import { Container, SubmenuCard, Title, MenuBackdrop } from './style/UserProfileSubmenu.style';

/**
 * User Profile Submenu
 *
 * @author Alessio Grassi
 *
 * @param isSubmenuOpen -  the "open" state of the Submenu
 * @param onLinkClick - handler for Submenu's closure
 *
 * @returns JSX
 */

const UserProfileSubmenu: FC<UserProfileSubmenuProps> = ({ isSubmenuOpen, onLinkClick }) => {
	const [libertyPoints, setLibertyPoints] = useState<number>(0);
	const [userRolesData, setUserRolesData] = useState<any>(undefined);
	const { isAppMobile } = useAppSelector((state) => state.ui);
	const { name: userName, surname: userSurname } = useAppSelector((state) => state.user);
	const backdropPortalElement = document.getElementById('backdrop-root');
	const isMounted = useIsMounted();
	const dispatch = useAppDispatch();

	const userBalance = async () => {
		try {
			const balance = await getUserBalance();
			if (!isMounted) return;
			if (!balance) return setLibertyPoints(0);

			setLibertyPoints(Math.round(balance));
		} catch (error: any) {
			console.warn('Unable getting User balance.', error);
		}
	};

	const userRoles = async () => {
		try {
			const roles = await getUsersRoles();
			if (!isMounted) return;

			setUserRolesData(roles);
		} catch (error: any) {
			console.warn('Unable getting User roles.', error);
		}
	};

	// useEffect(() => {
	//     userBalance();
	//     userRoles();
	// }, []);

	// useEffect(() => {

	//     // if ( isAppMobile ) {

	//         if (!isSubmenuOpen) {
	//             dispatch( enableAppMain() )
	//             return;
	//         }

	//         dispatch( disableAppMain() );
	//     // };
	// }, [dispatch, isAppMobile, isSubmenuOpen])

	const userNameAndSurname = `${userName} ${userSurname}`;

	const isUserAdmin = checkIfUserIsAdmin(userRolesData);
	const isUserAuthor = checkIfUserIsAuthor(userRolesData);

	if (backdropPortalElement == null) {
		return <></>;
	}

	if (!isAppMobile) {
		return (
			<>
				<Container isOpen={isSubmenuOpen}>
					<SubmenuCard>
						<Row
							mainAxis='center'
							margin='0 0 35px'
						>
							<IconTextBox
								label={libertyPoints}
								flavor='blue'
								SVGAsset={<UserBalanceIconSVG />}
								isPointBox
							/>
						</Row>

						<Col
							mainAxis='center'
							margin='0 0 35px'
							gap='20.86px'
						>
							<Link
								to='/reservedArea/account-page'
								onClick={onLinkClick}
							>
								<IconTextBox
									label='Account'
									SVGAsset={
										<AccountIconSVG
											width={20}
											height={20}
										/>
									}
								/>
							</Link>

							{/* <a href={getEnvironmentMonolithLinkUrl('reservedArea/mycourses')}>
								<IconTextBox
									label='I miei corsi'
									SVGAsset={
										<ProductsIconSVG
											width={20}
											height={20}
										/>
									}
								/>
							</a> */}

							{/* <a href={getEnvironmentMonolithLinkUrl('reservedArea/myservices')}>
								<IconTextBox
									label='I miei Abbonamenti'
									SVGAsset={<SubscriptionSVG />}
								/>
							</a> */}

							{/* <Link
								to='/reservedArea/libertydashboard'
								onClick={onLinkClick}
							>
								<IconTextBox
									label='I miei guadagni'
									SVGAsset={<GuadagniSVG />}
								/>
							</Link> */}

							{isUserAdmin && (
								<a href={getEnvironmentMonolithLinkUrl('admin/home')}>
									<IconTextBox
										label='Amministratore'
										SVGAsset={<AdminSVG />}
									/>
								</a>
							)}

							{isUserAuthor && (
								<a href={getEnvironmentMonolithLinkUrl('reservedArea/myauthorpage')}>
									<IconTextBox
										label='Autore'
										SVGAsset={<AuthorSVG />}
									/>
								</a>
							)}
						</Col>

						<Row mainAxis='center'>
							<LogoutButton onClick={onLinkClick} />
						</Row>
					</SubmenuCard>
				</Container>

				{isSubmenuOpen && ReactDOM.createPortal(<MenuBackdrop onClick={onLinkClick} />, backdropPortalElement)}
			</>
		);
	}

	return (
		<>
			<Container isOpen={isSubmenuOpen}>
				<Row>
					<Title>{userNameAndSurname}</Title>
				</Row>

				<Row margin='0 0 20px'>
					<SubmenuCard>
						<Row mainAxis='center'>
							<IconTextBox
								label={libertyPoints}
								flavor='blue'
								SVGAsset={<UserBalanceIconSVG />}
								isPointBox
							/>
						</Row>
					</SubmenuCard>
				</Row>

				<SubmenuCard>
					<Col
						mainAxis='center'
						margin='7.92px 0 9.37px'
						gap='26.725px'
					>
						<Link
							to='/reservedArea/account-page'
							onClick={onLinkClick}
						>
							<IconTextBox
								label='Account'
								SVGAsset={
									<AccountIconSVG
										width={20}
										height={20}
									/>
								}
							/>
						</Link>

						{/* <a href={getEnvironmentMonolithLinkUrl('reservedArea/mycourses')}>
							<IconTextBox
								label='I miei corsi'
								SVGAsset={
									<ProductsIconSVG
										width={20}
										height={20}
									/>
								}
							/>
						</a> */}

						{/* <a href={getEnvironmentMonolithLinkUrl('reservedArea/myservices')}>
							<IconTextBox
								label='I miei Abbonamenti'
								SVGAsset={<SubscriptionSVG />}
							/>
						</a> */}

						{/* <a href={getEnvironmentMonolithLinkUrl('reservedArea/home/EntrateAutomatiche110')}>
							<IconTextBox
								label='Entrate automatiche'
								SVGAsset={<AutomaticSVG />}
							/>
						</a> */}

						{isUserAdmin && (
							<a href={getEnvironmentMonolithLinkUrl('admin/home')}>
								<IconTextBox
									label='Amministratore'
									SVGAsset={<AdminSVG />}
								/>
							</a>
						)}

						{isUserAuthor && (
							<a href={getEnvironmentMonolithLinkUrl('reservedArea/myauthorpage')}>
								<IconTextBox
									label='Autore'
									SVGAsset={<AuthorSVG />}
								/>
							</a>
						)}

						{/* <a href={getEnvironmentMonolithLinkUrl('reservedArea/negoziolezioni')}>
							<IconTextBox
								label='Aiuto Privato'
								SVGAsset={
									<LessonsIconSVG
										width={17.52}
										height={20}
									/>
								}
							/>
						</a> */}

						{/* <a href={getEnvironmentMonolithLinkUrl('reservedArea/oddsScasser/puntabanca')}>
							<IconTextBox
								label='OddsScasser'
								SVGAsset={<OddsscasserSVG />}
							/>
						</a> */}

						{/* <a href={getEnvironmentMonolithLinkUrl('public/support')}>
							<IconTextBox
								label='Supporto'
								SVGAsset={<SupportSVG />}
							/>
						</a> */}
					</Col>
				</SubmenuCard>

				<Row
					mainAxis='center'
					margin='35px 0 0'
				>
					<LogoutButton onClick={onLinkClick} />
				</Row>
			</Container>

			{isSubmenuOpen && ReactDOM.createPortal(<MenuBackdrop onClick={onLinkClick} />, backdropPortalElement)}
		</>
	);
};

export default UserProfileSubmenu;
