import { FC } from 'react';

//types
import { ButtonProps } from './Button.component.d';

//style
import { UserSingleButton } from './style/Button.component.style';

const Button: FC<ButtonProps> = ({ name, ButtonIcon, clickHandler }) => {
	return (
		<UserSingleButton
			name={name}
			onClick={clickHandler}
		>
			<ButtonIcon />
		</UserSingleButton>
	);
};

export default Button;
