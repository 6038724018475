import { FC, ChangeEvent, useEffect, useRef, useState, useCallback } from 'react';

// types
import { TextInputProps } from './TextInput.d';

// assets
import { ReactComponent as PasswordToggleHideSVG } from './assets/password-toggle-hide.svg';
import { ReactComponent as PasswordToggleVisibleSVG } from './assets/password-toggle-visible.svg';

// style
import {
    InputBox,
    InputLabelBox,
    Label,
    Input,
    PasswordToggleBox,
    ErrorRow
} from './style/TextInput.style';


/**
 * Input box
 * 
 * @author Alessio Grassi
 * 
 * @param type - input box type (text | password)
 * @param flavor - standard or filled
 * @param label - text near the input box
 * @param value - value inside the input box
 * @param errorMsg - message displayed after an error occured
 * @param isDisabled - to disable the input box
 * @param onChange - handler for the onChange
 * @param onValidate - validation function for the input text
 * 
 * @returns JSX
 */


const TextInput: FC<TextInputProps> = ({
        type,
        flavor,
        label,
        value,
        errorMsg,
        isDisabled,
        onChange,
        onValidate
    }) => {

    const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
    const [inputType, setInputType] = useState<string | undefined>(type);
    const inputRef = useRef<HTMLDivElement>(null);
    const labelRef = useRef<HTMLLabelElement>(null);

    const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {

        const newInputValue = event.target.value;

        if (!onChange) return;
        onChange(newInputValue);
    };

    const focusHandler = () => setIsInputFocused(true);

    const focusInputElement = () => {

        setIsInputFocused(true);

        if (!labelRef || !labelRef?.current) return;
        labelRef.current.focus();
    };

    const passwordToggleHander = () => {

        if ( isDisabled || type !== 'password') {
            return;
        }
        
        if (inputType === "text") {
            
            setInputType("password");
            focusInputElement();
            return;
        }

        setInputType("text");
        focusInputElement();
    };

    const validateInputValue = useCallback(() => {

        if (!onValidate) return;
        onValidate();

    }, [onValidate]);

    useEffect(() => {

        if ( value !== undefined && !value.trim() ) return;
        setIsInputFocused(true);

    }, []);

    useEffect(() => {

        if (type === "password") {
            
            setInputType("password");
            return;
        }

    }, [isDisabled]);

    // this effect checks for outside click events and then blur input field
    useEffect(() => {
        
        const clickOutsideInputHandler = (event: MouseEvent) => {

            if (!isInputFocused) return;
            if (!inputRef || !inputRef?.current) return;
            if (inputRef.current.contains(event.target as Node)) return;
            if (value === undefined || value.trim()) return;

            setIsInputFocused(false);
            validateInputValue();
        };

        document.addEventListener("mousedown", clickOutsideInputHandler);

        return () => {
            document.removeEventListener("mousedown", clickOutsideInputHandler);
        };

    }, [value, validateInputValue, isInputFocused]);

    // this effect checks for key tab pressed and then unblur input field
    useEffect(() => {

        const suppInputRef = inputRef?.current;
        if (!suppInputRef) return;

        const keyShortCutsHandler = (event: KeyboardEvent) => {

            const lowerKey = event.key.toLowerCase();
            
            if ((lowerKey === 'tab') && isInputFocused) {

                if (value === undefined || value.trim()) return;
                setIsInputFocused(false);
            }
        };

        suppInputRef.addEventListener('keydown', keyShortCutsHandler);

        return () => {
            suppInputRef.removeEventListener('keydown', keyShortCutsHandler);
        }

    }, [isInputFocused, value]);

    const isPasswordToggleAssetActive = !isDisabled && (type === 'password');
    const isPasswordToggleAsssetTypeHide = (inputType === 'password');
    const isErrorMsgActive = !!errorMsg;

    return (
        <InputBox>
            <InputLabelBox
                ref={inputRef}
                flavor={flavor}
                onFocus={focusHandler}
                isActive={isInputFocused}
                isDisabled={isDisabled}
                isErrorMsgActive={isErrorMsgActive}
            >
                <Label
                    ref={labelRef}
                    flavor={flavor}
                    isActive={isInputFocused}
                    isDisabled={isDisabled}
                    isErrorMsgActive={isErrorMsgActive}
                >
                    <span>
                        {label}
                    </span>
                    
                    <Input
                        type={inputType}
                        flavor={flavor}
                        value={value}
                        onChange={inputChangeHandler}
                        readOnly={isDisabled}
                    />
                </Label>

                <PasswordToggleBox
                    isActive={isPasswordToggleAssetActive}
                    onClick={passwordToggleHander}
                >
                    {!isDisabled && isPasswordToggleAssetActive && isPasswordToggleAsssetTypeHide && (
                        <PasswordToggleHideSVG />
                    )}

                    {!isDisabled && isPasswordToggleAssetActive && !isPasswordToggleAsssetTypeHide && (
                        <PasswordToggleVisibleSVG />
                    )}
                </PasswordToggleBox>
            </InputLabelBox>
            
            {isErrorMsgActive && (
                <ErrorRow>
                    {errorMsg}
                </ErrorRow>
            )}
        </InputBox>
    );
};

TextInput.defaultProps = {

    type: "text",
    label: "",
    value: "",
    flavor: "standard",
    isDisabled: false
};

export default TextInput;
