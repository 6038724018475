import { callDELETE, callGET, callPOST, callPUT } from 'network/network';
import { RoadmapEvent } from 'types/RoadmapEvent';

const _ROADMAPEVENTS_TYPES_PATH = 'v1/roadmap/eventstypes';
const _ROADMAPEVENTS_TYPES_PICTURE_PATH = 'v1/roadmap/eventstypes/{id}/defaultimage';

export const uploadFile = async (eventTypeId: number, form: FormData, brandCode: string) => {
    try {
        const response = await callPOST(_ROADMAPEVENTS_TYPES_PICTURE_PATH.replace(
			/{id}/g,
			eventTypeId.toString()
		  ), form, true, { brandCode: brandCode});

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const deleteFile = async (eventTypeId: number, brandCode: string) => {
    try {
        const response = await callDELETE(_ROADMAPEVENTS_TYPES_PICTURE_PATH.replace(
			/{id}/g,
			eventTypeId.toString()
		  ), {}, true, { brandCode: brandCode});

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const getRoadmapEventTypeFromId = async (roadmapeventTypeId: number, brandCode: string) => {
	try {
		const response = await callGET(_ROADMAPEVENTS_TYPES_PATH + '/' + roadmapeventTypeId, true, { brandCode });

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const putRoadmapEventType = async (updatedRoadmapEvent: Partial<RoadmapEvent>, brandCode: string) => {
	try {
		await callPUT(_ROADMAPEVENTS_TYPES_PATH, updatedRoadmapEvent, true, { brandCode: brandCode });
	} catch (error: any) {
		throw error;
	}
};

export const deleteRoadmapEventTypeFromId = async (roadmapeventTypeId: number, brandCode: string) => {
	try {
		const response = await callDELETE(_ROADMAPEVENTS_TYPES_PATH + '/' + roadmapeventTypeId, {}, true, { brandCode });

		return response;
	} catch (error: any) {
		throw error;
	}
};
