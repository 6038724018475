import styled, { css } from 'styled-components';

import colors from 'style/palette/palette';

export const RoleBox = styled.div<{ enabled?: boolean, isClickable?: boolean }>`
	font-size: 0.5rem;
	background-color: ${colors.lightGrey};
	padding: 0.2rem;
	border-radius: 0.2rem;
	cursor: ${({isClickable}) => (isClickable ? 'pointer' : 'default')};
	${({ enabled }) =>
		enabled &&
		css`
			background-color: ${colors.green};
		`}
`;
