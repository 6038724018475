import styled, { css } from 'styled-components';
import devices from 'style/breakpoints';
import { Button } from 'style/buttons';
import colors from 'style/palette/palette';

// assets
import euroImg from '../assets/euroIcon.png';

export const ResultsColumn = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
`;

export const CardContent = styled.div`
	display: flex;
	width: 1000px;
	max-height: 900px;

	padding: 10px;

	overflow: auto;

	background-color: ${colors.veryDarkGrey};
	border-radius: 15px;

	text-align: center;

	@media ${devices.tablet} {
		padding: 35px 40px 50px;
	}
`;

export const IconBox = styled.div`
	margin: 30px 0 0;
	text-align: center;

	@media ${devices.tablet} {
		margin: 0;
	}
`;

export const CloseButton = styled.button`
	margin: 0;
	padding: 0;

	background-color: ${colors.transparent};
	border: none;

	cursor: pointer;
`;

export const Title = styled.h2`
	width: 100%;
	margin: 10px 0 15px;
	padding: 0;
	border-bottom: 2px solid ${colors.primaryColor};

	font-size: 16px;
	font-weight: 400;
	line-height: 28px;

	color: ${colors.primaryColor};

	text-align: center;
	text-transform: uppercase;
`;

export const ResultsText = styled.h2`

    margin: 0;
    padding: 0 0 0 10px;

    font-size: 20px;
    font-weight: 400;
    color: ${colors.white};
`;

export const ResultsRow = styled.div`

    display: flex;
    align-items: stretch;
    justify-content: center;

    flex-direction: column;
    gap: 20px;

    width: 100%;

    
    @media ${devices.tablet} {
        flex-direction: row;
        gap: 30px;
    }
`;

export const ResultBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 20px;

    width: 100%;
    padding: 10px 0;

    border-radius: 15px;
    box-shadow:
        0px 3px 1px -2px ${colors.black020},
        0px 2px 2px ${colors.black014},
        0px 1px 5px ${colors.black012};
    background-color: ${colors.darkGrey};

    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        
    -webkit-appearance: none; 
     margin: 0; 
    }

    input[type=number] {
    -moz-appearance:textfield;
    }
`;

export const ResultTitle = styled.h3<{ isDisabled?: boolean | undefined}>`

    margin: 0;
    padding: 0;

    font-size: 20px;
    font-weight: 400;
    color: ${colors.primaryColor};
    text-transform: uppercase;

    ${({isDisabled}) => isDisabled && 
        
        css`
            color: ${colors.white050};
        `
    }
`;

export const ResultImage = styled.div<{ isDisabled?: boolean | undefined}>`

    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
        max-height: 80px;
    }

    ${({isDisabled}) => isDisabled &&
        
        css`
            > svg path {

                    fill: ${colors.transparent};
                    fill-opacity: 0.5;
                    stroke: ${colors.white};
                    stroke-opacity: 0.5;
            }
        `
    }
`;

export const TotalResult = styled.h2`

    width: 100%;
    
    margin: 45px 0 0;
    padding: 0 0 0 10px;

    font-size: 23px;
    font-weight: 400;
    color: ${colors.green};
    text-align: center;
`;

export const TotalCashResult = styled.span`

    margin: 0 0 0 15px;
    padding: 0;

    font-size: 30px;
    font-weight: 600;
`;

export const GraphRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    width: 100%;

    margin: 30px 0 0;

    @media ${devices.tablet} {

        margin: 50px 0 0;
    }
`;

export const GraphBox = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;
    gap: 30px;

    width: 100%;
    /* max-width: 900px; */
    /* max-height: 370px; */

    padding: 20px 0 0;

    @media ${devices.tablet} {
        flex-direction: row;
    }
`;

export const GraphCard = styled.div<{pieChart?: boolean}>`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    width: 100%;
    max-width: 900px;
    /* max-height: 370px; */

    padding: 30px 25px;
    
    background: ${colors.darkGrey};
    box-shadow: 
        0px 3px 1px -2px ${colors.black020},
        0px 2px 2px ${colors.black014},
        0px 1px 5px ${colors.black012};
    border-radius: 15px;

    ${({pieChart}) => pieChart && (

        css`
            max-width: 433px;
        `
    )}
`;

export const LegendRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    margin: 0 0 30px;
`;

export const Year = styled.p`

    margin: 0;
    padding: 0;

    font-size: 13px;
    font-weight: 400;
    color: ${colors.white070};
    text-transform: uppercase;
`;

export const Legend = styled.div`

    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: 30px;
`;

export const GroupLegend = styled.div`

    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: 10px;
`;

export const ColorBox = styled.div<{flavor?: '#FF5957' | '#FF8A00' | '#5E2222'}>`

    width: 20px;
    height: 20px;
    background: ${colors.grey};

    ${({flavor}) => flavor && (
        
        css`
            background: ${flavor};
        `
    )}
`;

export const GroupName = styled.p`

    margin: 0;
    padding: 0;

    font-size: 13px;
    font-weight: 400;
    color: ${colors.white};
    text-transform: uppercase;
`;
