import styled from 'styled-components/macro';
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';


export const HamburgerButton = styled.button`

    min-width: 69px;

    margin: 0;
    padding: 0;

    background: ${colors.transparent};
    border: none;

    cursor: pointer;

    /* just to be safe */
    @media ${devices.tablet} {

        display: none;
    }
`;
