import { FC } from 'react';

//hooks
import useHasRole from 'hooks/useHasRole';
import useHasPermission from 'hooks/useHasPermission';

//types
import { NotesProps } from './Notes.component.d';

//assets
import { ReactComponent as NotesIcon } from './assets/notesIcon.svg';

//style
import { NotesBox } from './style/Notes.component.style';

const Notes: FC<NotesProps> = ({ notes, userId, notesClickHandler }) => {

	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isAdmin = useHasRole('ADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');
	const isAmministrazione = useHasRole('CONTABILITÀ');
	const isAuthor = useHasRole('AUTORE');
	const isPersonalTrainer = useHasRole('PERSONAL TRAINER');
	const isSupervisoreAutostrada = useHasRole('SUPERVISORE_AUTOSTRADA');
	const isAdminAutostrada = useHasRole('ADMIN_AUTOSTRADA');
	const isAssistentePersonale = useHasRole('ASSISTENTE-PERSONALE');

	const canUpdateNotes = isSuperAdmin || isAdmin || isMiniAdmin || isAdminAutostrada || isSupervisoreAutostrada;

	const clickHandler = (event: React.MouseEvent) => {
		if (!canUpdateNotes) return;
		notesClickHandler(event, notes, userId);
	};

	return (
		<NotesBox
			title={notes}
			isSwitchedOn={notes ? true : false}
			isClickable={canUpdateNotes}
		>
			<NotesIcon onClick={clickHandler} />
		</NotesBox>
	);
};

export default Notes;
