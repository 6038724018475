import colors from 'style/palette/palette';
import styled, { css } from 'styled-components';


export const CardContent = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-direction: column;
    gap: 30px;

    width: 1000px;
    max-height: 800px;

    margin: 0;
    padding: 30px;

    border: 1px solid ${colors.primaryColor};
    border-radius: 15px;

    background: ${colors.grey};
    color: ${colors.white};

    overflow: auto;

    ::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;

    div {
        width: 100%;
    }
`

export const RowContent = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 30px;
`;

export const GroupContent = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    gap: 30px;
    flex-wrap: wrap;

    div {
        width: fit-content;
    }
`;

export const ButtonRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-end;

    gap: 30px;

    width: 100%;
`;

export const BoxRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 30px;

    width: 100%;
`;

export const SaveAndCloseButton = styled.button`

    margin: 0;
    padding: 20px;

    border: 1px solid ${colors.primaryColor};
    border-radius: 5px;

    background: ${colors.blueGrey};
    color: ${colors.black087};
    font-size: 15px;
    font-weight: 500;

    cursor: pointer;
`;

export const SaveButton = styled(SaveAndCloseButton)``;

export const CloseAndCancel = styled(SaveAndCloseButton)``;

export const DeleteElementButton = styled(SaveAndCloseButton)``;

export const AddElementButton = styled(SaveAndCloseButton)``;

export const UrlInput = styled.input`

    margin: 0 0 0 10px;
    
    border-radius: 5px;
    border: 1px solid ${colors.white070};
    background: ${colors.lightGrey};
`;

export const UrlLabel = styled.label``;

export const LayoutBoxButton = styled.button`

    width: auto;
    
    margin: 0;
    padding: 50px;

    border: 1px solid ${colors.primaryColor};
    border-radius: 5px;

    background: ${colors.blueGrey};
    color: ${colors.black087};
    font-size: 15px;
    font-weight: 500;

    cursor: pointer;
`;

export const ElementBoxButton = styled(LayoutBoxButton)``;
