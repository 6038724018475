import { FC, useState } from 'react';

// redux
import { enableAppMain } from 'redux/slices/slice-app-main';

// hooks
import useAppDispatch from 'hooks/useAppDispatch';

// type
import { NewElementModalProps } from './NewElementModal.component.d';

// components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';
import VideoUpdateElement from '../UpdateElements/VideoUpdateElement/VideoUpdateElement.component';
import ImageUpdateElement from '../UpdateElements/ImageUpdateElement/ImageUpdateElement.component';
import RowUpdateElement from '../UpdateElements/RowUpdateElement/RowUpdateElement.component';
import GroupUpdateElement from '../UpdateElements/GroupUpdateElement/GroupUpdateElement.component';
import TextUpdateElement from '../UpdateElements/TextUpdateElement/TextUpdateElement.component';
import ButtonUpdateElement from '../UpdateElements/ButtonUpdateElement/ButtonUpdateElement.component';
import BulletListUpdateElement from '../UpdateElements/BulletListUpdateElement/BulletListUpdateElement.component';

// style
import { 
    CardContent,
    ButtonRow,
    BoxRow,
    CloseAndCancel,
    LayoutBoxButton,
    ElementBoxButton
 } from './style/NewElementModal.style';


const NewElementModal: FC<NewElementModalProps> = ({
    // productId,
    webpageId,
    blockId,
    // elementId,
    // editableContent,
    typeOfLayout,
    typeOfElement,
    elementsToDisplay,
    isRequired,
    newBlockPosition,
    newElementPosition,
    onCloseButtonModal,
    // update,
    updateData
}) => {

    const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);
    const [layout, setLayout] = useState<'SINGLE' | 'ROW' | 'GROUP' | undefined>(typeOfLayout);
    const [newElement, setNewElement] = useState<string | undefined>(undefined);
    const dispatch = useAppDispatch();

    const onLayoutClickHandler = (newLayout:'SINGLE' | 'ROW' | 'GROUP') => setLayout(newLayout);
    const onElementClickHandler = (newElement:string) => setNewElement(newElement);

	const closeButtonHandler = () => {
        
		if (onCloseButtonModal) onCloseButtonModal();
        setIsCloseButtonClicked(true);
	};

    const onSaveAndCloseClickHandler = async () => {
        
        // if (update && elementId) await update(elementId, undefined, onChangeTextAreaValue);     
        if (onCloseButtonModal) onCloseButtonModal();
        setIsCloseButtonClicked(true);
        dispatch( enableAppMain() );
    };

    const onSaveClickHandler = async () => {
        
        // if (update && elementId) await update(elementId, undefined, onChangeTextAreaValue);     
    };

    const onCancelClickHandler = () => {
        
        if (onCloseButtonModal) onCloseButtonModal();
        setIsCloseButtonClicked(true);
        dispatch( enableAppMain() );        
    };

    const isLayoutChoiceVisible = (layout === undefined) && (newElement === undefined);
    const isElementChoiceVisible = (layout !== undefined) && (newElement === undefined);
    
    return (
		<BaseModal
			onClose={closeButtonHandler}
			isCloseButtonClicked={isCloseButtonClicked}
		>
			<BaseCard
				flavor='outlined'
				maxWidth='1000px'
			>
                <CardContent>
                    {isLayoutChoiceVisible && (
                        <>
                            Scegli un Layout:

                            <BoxRow>
                                <LayoutBoxButton
                                    onClick={() => onLayoutClickHandler('SINGLE')}
                                >
                                    Elemento singolo
                                </LayoutBoxButton>

                                {/* <LayoutBoxButton
                                    onClick={() => onLayoutClickHandler('ROW')}
                                >
                                    Riga di elementi
                                </LayoutBoxButton>

                                <LayoutBoxButton
                                    onClick={() => onLayoutClickHandler('GROUP')}
                                >
                                    Gruppo di elementi
                                </LayoutBoxButton> */}
                            </BoxRow>
                        </>
                    )}

                    {isElementChoiceVisible && (
                        <>
                            Scegli  il nuovo elemento da inserire:

                            <BoxRow>
                                <ElementBoxButton
                                    onClick={() => onElementClickHandler('TEXT')}
                                >
                                    Testo
                                </ElementBoxButton>

                                {/* <ElementBoxButton
                                    onClick={() => onElementClickHandler('IMAGE')}
                                >
                                    Immagine
                                </ElementBoxButton>

                                <ElementBoxButton
                                    onClick={() => onElementClickHandler('VIDEO')}
                                >
                                    Video
                                </ElementBoxButton> */}
                            </BoxRow>
                        </>
                    )}
                {/* {(typeOfLayout === 'ROW') && (
                    <RowUpdateElement 
                        elementsToDisplay={elementsToDisplay}
                        onCancelClickHandler={onCancelClickHandler}
                        />
                        )}

				{(typeOfLayout === 'GROUP') && (
                    <GroupUpdateElement
                    elementsToDisplay={elementsToDisplay}
                    onCancelClickHandler={onCancelClickHandler}
                    />
                )} */}

                {(newElement === 'TEXT') && (
                    <TextUpdateElement 
                        onCancelClickHandler={onCancelClickHandler}
                        updateData={updateData}
                        webpageId={webpageId}
                        newBlockPosition={newBlockPosition}
                        newBlockLayout={layout}
                        isNewElement
                        newElementPosition={newElementPosition}
                        blockId={blockId}
                    />
                )}

                {(newElement === 'VIDEO') && (
                    <VideoUpdateElement 
                        onClose={onCancelClickHandler}
                        updateData={updateData}
                        webpageId={webpageId}
                        newBlockPosition={newBlockPosition}
                        newBlockLayout={layout}
                        isNewElement
                    />
                )}

                {(newElement === 'IMAGE') && (
                    <ImageUpdateElement 
                        onClose={onCancelClickHandler}
                        updateData={updateData}
                        webpageId={webpageId}
                        newBlockPosition={newBlockPosition}
                        newBlockLayout={layout}
                        isNewElement
                        newElementPosition={newElementPosition}
                        blockId={blockId}
                    />
                )}
                {/* 

                            
                            {(typeOfElement === 'BUTTON') && (
                                <ButtonUpdateElement 
                                onClose={onCancelClickHandler}
                                isRequired={isRequired}
                                productId={productId}
                                updateData={updateData}
                                />
                                )}
                                
                                {(typeOfElement === 'BULLETLIST') && (
                                    <BulletListUpdateElement 
                                    onClose={onCancelClickHandler}
                                    isRequired={isRequired}
                                    elementToDisplay={elementsToDisplay}
                                    productId={productId}
                                    updateData={updateData}
                                    />
                )} */}
                    <ButtonRow>
                        <CloseAndCancel
                            onClick={onCancelClickHandler}
                        >
                            Annulla e Chiudi
                        </CloseAndCancel>
                    </ButtonRow>
                </CardContent>
			</BaseCard>
		</BaseModal>
    )
};

export default NewElementModal;
