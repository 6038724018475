import { useEffect, useState } from 'react';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

//services
import { saveSubscribtionNotes } from './services/WhatsappOpenDetailsTable.services';

//utils
import { footerElementsCount } from './WhatsappOpenDetailsTable.helper';

//types
import { tableColumnAttr, WhatsappOpenDetailsTableProps, WhatsappOpenDetailsTableItem } from './WhatsappOpenDetailsTable.component.d';

//assets
import { ReactComponent as ChevronLeftIcon } from './assets/chevronLeft.svg';
import { ReactComponent as ChevronRightIcon } from './assets/chevronRight.svg';
import { ReactComponent as DownArrowIcon } from './assets/downArrowIcon.svg';

//components
import CheckboxInput from 'components/ui/CheckboxInput';
import MultilineSelect from 'components/MultilineSelect/MultilineSelect.component';


//style
import {
    FooterNavigationButton,
	FooterElementsCount,
	Table,
	TableBody,
	TableBodyCell,
	TableBodyRow,
	TableFooter,
	TableHead,
	TableHeadCell,
	TableHeadRow,
	CloseSuccessButton,
	CloseFailureButton,
	CloseButton,
	Row,
} from './style/WhatsappOpenDetailsTable.style';

const WhatsappOpenDetailsTableColumns: tableColumnAttr[] = [
    {
        name: 'assistant',
    	title: 'Assistente Personale',
		display: 'hidden',
    },
    {
        name: 'packid',
        title: 'Call Id',
        align: 'center',
    },
    {
        name: 'customer',
        title: 'Cacciatore',
        align: 'center',
    },
	{
        name: 'paymentid',
        title: 'Pagamento Id',
        align: 'center',
    },
	{
        name: 'paymentdate',
        title: 'Data Acquisto',
        align: 'center',
    },
	{
        name: 'assignmentdate',
        title: 'Data Assegn.',
        align: 'center',
    },
	{
        name: 'lessons',
        title: 'Lezioni Fatte',
        align: 'center',
		display: 'hidden',
    },
	{
		name: 'openlessons',
		display: 'hidden',
	},
	{
		name: 'coachNotes',
		display: 'hidden',
	}
];

type sortParamsType = {
	column: string | null;
	direction: 'asc' | 'desc' | null;
};

const WhatsappOpenDetailsTable: React.FC<WhatsappOpenDetailsTableProps> = ({ data, totalPages, totalElements, pageSize, searchAssistants, closeWhatsapp, hiddenWhatsapp, getCheckedIds }) => {
	const [tableData, setTableData] = useState<WhatsappOpenDetailsTableItem[]>([]);
	const [sortParams, setSortParams] = useState<sortParamsType>({column: null, direction: null});
	const [currentPage, setCurrentPage] = useState(1);
    const [selectedCheckbox, setSelectedCheckbox] = useState<number[]>([]);

	const [closeFailureReasons, setCloseFailureReasons] = useState<string[]>([]);


	const dispatch = useAppDispatch();

	function isStringDate(inputString: string): boolean {
		if (!inputString) return false;
		
		let hasTwoHyphens: boolean   = false; 
		let hasTwoColons: boolean    = false;
		let hasLetterT: boolean      = false;
	
		for(let i = 0; i < inputString.length; i++) {
			if (inputString[i] === '-') {
				hasTwoHyphens = true;
			}
			if (inputString[i] === ':') {
				hasTwoColons = true;
			}
			if (inputString[i] === 'T') {
				hasLetterT = true;
			}
		}
	
		// If all three conditions are met, return true
		if (hasTwoHyphens && hasTwoColons && hasLetterT) {
			return true;
		} 
		return false;
	}

	const isValidDate = (value: string): boolean => {
		if (!isStringDate(value)) return false;
		const dateObj = new Date(value);
		return !Number.isNaN(dateObj.getTime());
	}

	const convertDate = (value: string): string => {
		const utcDate = new Date(value);
		const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000);
  		return localDate.toLocaleString('IT-it');
	}

	const pageChangeHandler = (page: number) => {
		setCurrentPage(page);
	};

	const sortTable = (items: WhatsappOpenDetailsTableItem[], sortBy: string | null, direction: 'asc' | 'desc' | null) => {
		if (sortBy === null) return items;
		const sortedItems = [...items].sort((a, b) => {
			if (a[sortBy] < b[sortBy]) {
			  return -1;
			}
			if (a[sortBy] > b[sortBy]) {
			  return 1;
			}
			return 0;
		});
		
		if (direction === 'desc') {
			return sortedItems.reverse();
		}
		
		return sortedItems;
	};

	const handleSort = (key: string) => {
		if (key === sortParams.column) {
			setSortParams({column: key, direction: sortParams.direction === 'asc' ? 'desc' : 'asc'});
		} else {
			setSortParams({column: key, direction: 'desc'});
		}
	};

	const handleCloseSuccess = (id: number) => {
		closeWhatsapp(id, true);
	}

	const handleCloseFailure = (id: number) => {
		closeWhatsapp(id, false);
	}

    const handleCheckChange = (rowID: number, value: boolean) => {
        let newCheckbox = selectedCheckbox;
        const index = newCheckbox.indexOf(rowID);
        if ((value === true) && (index === -1)) newCheckbox = [...newCheckbox, rowID];
        if ((value === false) && (index !== -1)) newCheckbox = newCheckbox.filter(e => e !== rowID);
        setSelectedCheckbox(newCheckbox);
    }

	const updateCloseFailureReasons = (idLesson: number, reason: string | undefined) => {
		if ((!reason) || (!idLesson)) return;
		setCloseFailureReasons(prevReasons => {
			const reasonsCopy = [...prevReasons];
			reasonsCopy[idLesson] = reason;
			return reasonsCopy;
		  });
	}

	const closeFailure = async (idSubscription: number) => {
		if (!closeFailureReasons[idSubscription]) {
			const snackbarData: SnackbarOptions = {};
			snackbarData.type = 'error';
			snackbarData.message = `Devi specificare il motivo per chiudere la lezione come fallimento!`;
			dispatch(addSnackbar(snackbarData));
			return;
		}
		// eslint-disable-next-line no-restricted-globals
		if (confirm("Vuoi veramente chiudere la lezione pack #"+idSubscription+"?")) {
			try{
				await saveSubscribtionNotes(idSubscription, false, closeFailureReasons[idSubscription]);
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'success';
				snackbarData.message = `Lezione chiusa con il motivo selezionato!`;
				dispatch(addSnackbar(snackbarData));
				setTimeout(() => {searchAssistants(currentPage);}, 1500);
			} catch (error: any) {
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'error';
				snackbarData.message = `C'è stato qualche problema nel chiudere la lezione!`;
				dispatch(addSnackbar(snackbarData));
				throw error;
			}
		}
	}

	useEffect(() => {
		console.log('tableData', tableData);
	}, [tableData]);

	useEffect(() => {
		searchAssistants(currentPage);
	}, [currentPage]);

	useEffect(() => {
		const sortedTable = sortTable(tableData, sortParams.column, sortParams.direction);
		setTableData(sortedTable);
	}, [sortParams]);

    useEffect(() => {
        getCheckedIds(selectedCheckbox);
    }, [selectedCheckbox]);

	useEffect(() => {
		const sortedTable = sortTable(data, sortParams.column, sortParams.direction);
		setTableData(sortedTable);
	}, [data]);

    let columns: string[] = [];
	if (tableData.length > 0) columns = Object.keys(tableData[0]);

	const closeWithoutSuccessOptions = [
		{ value: "1. Non ha risposto", label: "1. Non ha risposto" },
		{ value: "2. Non si è presentato", label: "2. Non si è presentato" },
		{ value: "3. Non è interessato", label: "3. Non è interessato" },
		{ value: "4. Aveva detto che l'avrebbe comprata ma non l'ha fatto", label: "4. Aveva detto che l'avrebbe comprata ma non l'ha fatto" },
		{ value: "5. È interessato ma non ora (p.e. non ha soldi)", label: "5. È interessato ma non ora (p.e. non ha soldi)" }
	  ];

	return (
		<>
			<Table>
				<TableHead>
					<TableHeadRow>
						{columns.map((c) => (
							<>
							{!(WhatsappOpenDetailsTableColumns.find(x => x?.name === c)?.display && WhatsappOpenDetailsTableColumns.find(x => x?.name === c)?.display === 'hidden') &&
							<TableHeadCell
								key={c}
								onClick={() => handleSort(c)}
							>
								{WhatsappOpenDetailsTableColumns.find(x => x?.name === c)?.title ? WhatsappOpenDetailsTableColumns.find(x => x?.name === c)?.title?.toUpperCase() : c.toUpperCase()}
								{(sortParams.column === c && (sortParams.direction === 'desc' ? <DownArrowIcon transform="rotate(180 0 0)"/> : <DownArrowIcon />))}
							</TableHeadCell>
							}
							</>
						))}
                        <TableHeadCell
                            key={"dettaglio"}>
                            
                        </TableHeadCell>
						<TableHeadCell
                            key={"assegna"}>
                            RIASSEGNA A
                        </TableHeadCell>
					</TableHeadRow>
				</TableHead>
				<TableBody>
					{tableData.map((row, index) => (
						<TableBodyRow key={index} hidden={hiddenWhatsapp.find(element => element === row['packid']) === row['packid']}>
							{columns.map((c) => {
								const value = row[c as keyof WhatsappOpenDetailsTableItem];
								return (
									<>
									{!(WhatsappOpenDetailsTableColumns.find(x => x?.name === c)?.display && WhatsappOpenDetailsTableColumns.find(x => x?.name === c)?.display === 'hidden') &&
									<TableBodyCell
                                        align={WhatsappOpenDetailsTableColumns.find(x => x?.name === c)?.align}
										key={c}
										id={c}
									>
										{(isValidDate(value)) ? convertDate(value) : value}
									</TableBodyCell>
									}
									</>
								);
							})}
                            <TableBodyCell 
                                align={"center"}
                            >
								{row['coachNotes'] && <Row>
									<MultilineSelect title="MOTIVO" options={closeWithoutSuccessOptions} maxWidth="180px" minWidth="180px" dropdownMinWidth="200px" isAbsolutePosition={false} onSelect={(selectedValue) => updateCloseFailureReasons(Number(row['packid']), selectedValue)}></MultilineSelect>
									<CloseButton
											onClick={async () => closeFailure(row['packid'])}
											title='Chiudi con Fallimento'
										>
											&#10006;
									</CloseButton>
								</Row>}
							</TableBodyCell>
							<TableBodyCell 
                                align={"center"}
                                key={"assegna"+row['id']}
                                id={"assegna"+row['id']}
                            >
                               {!row['coachNotes'] && <CheckboxInput inputName={"check"+row['packid']} isChecked={(selectedCheckbox.indexOf(row['packid']) !== -1)} onChange={(event) => handleCheckChange(row['packid'], event.valueOf())}></CheckboxInput> }
                            </TableBodyCell>
						</TableBodyRow>
					))}
				</TableBody>
			</Table>
			{totalElements > 0 && (
				<TableFooter>
					<FooterElementsCount>{footerElementsCount({ currentPage, totalPages, totalElements, pageSize })}</FooterElementsCount>
					<FooterNavigationButton
						onClick={() => pageChangeHandler(currentPage - 1)}
						disabled={currentPage === 1}
					>
						<ChevronLeftIcon />
					</FooterNavigationButton>
					<FooterNavigationButton
						onClick={() => pageChangeHandler(currentPage + 1)}
						disabled={currentPage === totalPages}
					>
						<ChevronRightIcon />
					</FooterNavigationButton>
				</TableFooter>
			)}
		</>
	);
};

export default WhatsappOpenDetailsTable;