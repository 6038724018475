import config from "./config";

const checkPath = (path: string) => {
    
    if (path.at(0) === '/') return path.slice(1);
    return path;
};

export const getEnvironmentBaseUrl = (path: string) =>{ 

    const finalPath = checkPath(path);

    if (config.environment === 'production') return `${config.productionUrl}${finalPath}`;
    if (config.environment === 'test') return `${config.testUrl}${finalPath}`;
    return `http://localhost:3000/${finalPath}`;
};

export const getEnvironmentTestBaseUrl = (path: string) =>{ 

    const finalPath = checkPath(path);

    if (config.environment === 'production') return `${config.productionUrl}${finalPath}`;
    return `${config.testUrl}${finalPath}`;
};

export const getEnvironmentMonolithLinkUrl = (path: string) =>{ 

    const finalPath = checkPath(path);

    if (config.environment === 'production') return `${config.productionUrl}${finalPath}`;
    if (config.environment === 'test') return `${config.testUrl}${finalPath}`;
    return `http://localhost:8080/${finalPath}`;
};

export const getEnvironmentAssetLinkUrl = (path: string) =>{ 

    const finalPath = checkPath(path);

    if (config.environment === 'production') return `${config.baseProductionFrontendUrl}${finalPath}`;
    if (config.environment === 'test') return `${config.baseTestFrontendUrl}${finalPath}`;
    return `http://localhost:3000/${finalPath}`;
};

export const getEnvironmentLinkUrlForCSS = (path: string) =>{ 

    const finalPath = checkPath(path);

    if (config.environment === 'production') return `url(${config.baseProductionFrontendUrl}${finalPath})`;
    if (config.environment === 'test') return `url(${config.baseTestFrontendUrl}${finalPath})`;
    return `url(http://localhost:3000/${finalPath})`;
};

