import styled from 'styled-components';

export const StatsTable = styled.table`
	font-size: 0.65rem;
`;

export const StatsTableBody = styled.tbody``;

export const StatsTableRow = styled.tr``;

export const StatsTableCellTitle = styled.td`
	text-align: right;
`;

export const StatsTableCellDate = styled.td`
	text-align: left;
`;
