import styled, { css } from 'styled-components';

export const Chapters = styled.div<{isActive?: boolean}>`

display: flex;
justify-content: space-between;
align-items: flex-start;

width: 100%;
border: none;

padding: 17px 34px 17px 16px;

font-weight: 500;
font-size: 14px;
letter-spacing: 0.1px;

color: rgba(255, 255, 255, 0.5);

text-transform: uppercase;

background: #1B1F22;

cursor: pointer;


    ${({isActive}) => isActive && 
        css`
            background: #FFC000;

            font-weight: 600;
            font-size: 14px;
            line-height: 18px;

            color: #000000;
        `
    }
`;