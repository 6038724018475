import styled from "styled-components";

export const Page = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 0 30px;

    width: 100%;
    height: 100%;

    overflow: hidden;
`;

export const SearchChannelBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    max-width: 1360px;
`;

export const SearchInput = styled.input`
    display: flex;
    align-items: center;
    flex-direction: column;

    width: 100%;
    height: 29px;
    padding: 7px 9px;
    margin: 0 13px 0 0;

    background-color: transparent;
    border: 1px solid #FFFFFF;
    border-radius: 100px;

    color: white;
    :focus{
        outline: none;
        border-color: white;
        box-shadow: 0 0 0px white;
        -moz-box-shadow: 0 0 0px white;
        -webkit-box-shadow: 0 0 0px white;
    }
`;

export const SearchClearButton = styled.div`
    position: relative;
    top: 1px;
    left: -38px;
    width: 0;

    cursor: pointer;

    > svg {
            min-width: 15px;
            min-height: 15px;          
    }
`;

export const SearchButton = styled.div`
    > svg {
            min-width: 23px;
            min-height: 23px;          
    }
`;

export const AssignBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: right;
    width: 90%;
    margin: 0 0 25px;
`;

export const AssignSelect = styled.select`
    margin: 0 10px 0 0;
    background-color: transparent;
    color: #ffffff;
`;

export const AssignButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3px 4px;

    width: 84px;
    height: 24px;

    border-radius: 416px;
    border: 0;
    background-color: #66BB6A;

    font-weight: 400;
    font-size: 13px;
    color: #ffffff;
	cursor: pointer;

    :disabled {
        background-color: #FFFFFF1F;
        color: #29353E;
        cursor: not-allowed;
    }
`;