import DeleteElementModal from 'components/DeleteElementModal/DeleteElementModal.component';
import { createNewBlock, createNewElement, updateImageIntoElements } from 'pages/PublicProductPages/services/PublicProductPage.services';
import { FC, useRef, useState } from 'react';

// type
import { ImageUpdateElementProps } from './ImageUpdateElement.component.d';

// style
import { 
    CardContent,
    ButtonRow,
    RowContent,
    CloseAndCancel,
    SaveAndCloseButton,
    DeleteElementButton,
    UrlInput,
    UrlLabel,    
    UploadImageButton
} from './style/ImageUpdateElement.style';


const ImageUpdateElement: FC<ImageUpdateElementProps> = ({
    webpageId,
    blockId,
    elementId,
    isNewElement,
    newBlockLayout,
    newBlockPosition,
    newElementPosition,
    numberOfElements,
    typeOfLayout,
    update,
    updateData,
    onClose
}) => {
    
    const [imageToUpload, setImageToUpload] = useState<File>();
    const [isRemoveElementModalVisible, setIsRemoveElementModalVisible] = useState<boolean>(false);

    const onDeleteButtonHandler = () => setIsRemoveElementModalVisible(true);

    const handleFileUpload = (newFile: FileList | null) => {

        if (!newFile) return;
        setImageToUpload(newFile[0]);
    };
    
    const onCancelClickHandler = () => {
        onClose();
    };
    
    const onSaveAndCloseHandler = async () => {
        
        console.log("NEW?:", isNewElement);
        console.log("WEB ID:", webpageId);
        console.log("B ID:", blockId);
        console.log("NEW B L:", newBlockLayout);
        console.log("NEW B P:", newBlockPosition);
        console.log("NEW E P:", newElementPosition);
        
        if (isNewElement && (newBlockLayout !== undefined) && ((newElementPosition === undefined) || (newElementPosition === 0))) {

            await createNewImageElement();
            if (updateData) await updateData();
            onCancelClickHandler();    
            return;
        }

        if (isNewElement && (newElementPosition !== undefined && newElementPosition > 0)) {

            await createNewImageElementInsideABlock();
            if (updateData) await updateData();
            onCancelClickHandler();    
            return;
        }

        await uploadImage();
        if (updateData) await updateData();
        onClose();
    };

    const uploadImage = async () => {

        // to do snackbar and spinner
        try {
            
            console.log("W", webpageId);
            console.log("E", elementId);
            
            if (!elementId || !webpageId || !imageToUpload) return;

            const updatedElement = await updateImageIntoElements(webpageId, elementId, imageToUpload);
            console.log("UPDATED EL:",updatedElement);
            
            // await update(elementId, undefined, updatedElement.id);
        } catch (error: any) {
            console.warn("ERROR:", error);
        }
    };
    
    const createNewImageElement = async () => {

        // to do snackbar and spinner
        try {

            if (webpageId === undefined) return;
            if (newBlockPosition === undefined) return;
            if (newBlockLayout === undefined) return;
            const newBlock = await createNewBlock(webpageId, newBlockPosition, newBlockLayout);
            
            if (newBlock.id === undefined) return;
            const newElement = await createNewElement(newBlock.id, 'IMAGE', 0);
            
            if (newElement.id === undefined) return;
            if (imageToUpload === undefined) return;
            await updateImageIntoElements(webpageId, newElement.id, imageToUpload);
        } catch (error: any) {
            console.warn("ERROR:", error);
        }
    };

    const createNewImageElementInsideABlock = async () => {

        // to do snackbar and spinner
        try {

            if (webpageId === undefined) return;
            if (blockId === undefined) return;
            if (newElementPosition === undefined) return;
            // const newBlock = await createNewBlock(webpageId, newBlockPosition, newBlockLayout);
            
            // if (newBlock.id === undefined) return;
            const newElement = await createNewElement(blockId, 'IMAGE', newElementPosition);
            
            if (newElement.id === undefined) return;
            if (imageToUpload === undefined) return;
            await updateImageIntoElements(webpageId, newElement.id, imageToUpload);
        } catch (error: any) {
            console.warn("ERROR:", error);
        }
    };


    return (
        <>
            <CardContent>
                <RowContent>
                    <UrlLabel>
                        Carica l'immagine nuova

                        <UploadImageButton onClick={() => document.getElementById('image')?.click()} >
                            Sfoglia...
                        </UploadImageButton>

                        <UrlInput 
                            type="file"
                            id="image"
                            accept=".png, .jpeg, .jpg"
                            onChange={(e) => handleFileUpload(e.target.files)}
                            style={{ display: 'none' }}
                        />

                        {imageToUpload ? imageToUpload.name : "Nessuna immagine selezionata"}
                    </UrlLabel>
                </RowContent>

                <ButtonRow>
                    {!isNewElement && 
                    (newBlockLayout === undefined) && (
                        <DeleteElementButton
                            onClick={onDeleteButtonHandler}
                        >
                            Elimina l'immagine
                        </DeleteElementButton>
                    )}

                    <CloseAndCancel
                            onClick={onCancelClickHandler}
                    >
                        Annulla e Chiudi
                    </CloseAndCancel>

                    <SaveAndCloseButton
                            onClick={onSaveAndCloseHandler}
                    >
                        Salva e Chiudi
                    </SaveAndCloseButton>
                </ButtonRow>
            </CardContent>

            {isRemoveElementModalVisible && (
                <DeleteElementModal 
                    onCloseButtonModal={onCancelClickHandler}
                    elementId={elementId}
                    updateData={updateData}
                    typeOfLayout={typeOfLayout}
                    numberOfElements={numberOfElements}
                />)
            }
        </>
    );
};

export default ImageUpdateElement;
