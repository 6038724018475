export const formatNumbers = (num: number) => {
	return new Intl.NumberFormat('it-IT', {
		style: 'decimal',
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(num);
};

export const roundTwoDigits = (num: number) => {
	return Math.round(num * 100) / 100;
};
