import colors from "style/palette";
import styled, { css } from "styled-components";

export const TestimonialBox = styled.div<{ isDragging: boolean}>`
	display: flex;
	align-items: center;
	gap: 1rem;
	border: 1px solid;
	border-radius: 0.25rem;
	width: 100%;
	padding: 0.5rem 1rem;
	justify-content: space-between;
	color: ${colors.white};
	background-color: ${colors.veryDarkGrey}

	${({ isDragging }) =>
		isDragging &&
		css`
			opacity: 0;
		`}
`;


export const LeftSection = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
	width: 68%;
`;

export const Handle = styled.div`
	> svg {
		cursor: grab;
		width: 15px;
		height: 15px;
		> path {
			fill: ${colors.white};
		}
	}
`;

export const Visible = styled.div<{visible: boolean}>`
	> svg {
		cursor: pointer;
		width: 15px;
		height: 15px;
		> path {
			fill: ${({visible}) => visible ? colors.green : colors.red};
		}
	}
`;

export const LessonSection = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 1rem;
	width: 7%;
`;

export const Video = styled.div`
	cursor: pointer;
	> svg {
		width: 15px;
		height: 15px;
		> path {
			fill: ${colors.red};
		}
	}
`;

export const ProfilePicture = styled.div<{ imgUrl: string }>`
	> svg {
	width: 15px;
	height: 15px;
	> path {
		/* fill: ${colors.red}; */
	}
	}

	position: relative;

	.preview {
		content: "";
		position: absolute;
		display: none;
		width: 30rem;
		z-index: 10;
		top: 100%;
		left: 50%;
		transform: translate(-50%, 0);
		border: 2px solid #000; // Optional border
		box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); // Optional box-shadow
		overflow: hidden; // Clip the image if it exceeds the width or height
	}

	.preview img {
		width: 100%;
		height: auto;
	}

	&:hover .preview {
		display: block;
	}
`;

export const Description = styled.div`
	> svg {
		width: 15px;
		height: 15px;
		> path {
			fill: ${colors.primaryColor};
		}
	}
`;

export const RightSection = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 0.25rem;
	width: 25%;
`;

export const TestimonialId = styled.div`
	font-size: 0.5rem;
`;

export const ModifyTestimonial = styled.div`
	display: flex;
	cursor: pointer;
	justify-self: flex-end;
	> svg {
		width: 15px;
		height: 15px;
		> path {
			fill: ${colors.lightBlue};
		}
	}
`;

export const DeleteTestimonial = styled.button`
	background-color: transparent;
	border: none;
	display: flex;
	cursor: pointer;
	justify-self: flex-end;
	> svg {
		width: 15px;
		height: 15px;
		> path {
			fill: ${colors.red};
		}
	}
`;