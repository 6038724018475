import colors from 'style/palette/palette';
import styled, { css } from 'styled-components';

export const NotesBox = styled.div<{ isSwitchedOn: boolean, isClickable?: boolean }>`
	display: flex;
	justify-content: center;
	cursor: ${({isClickable}) => (isClickable ? 'pointer' : 'default')};

	${({ isSwitchedOn }) =>
		!isSwitchedOn &&
		css`
			> svg path {
				fill: ${colors.lightGrey};
			}
		`}

	${({ isSwitchedOn }) =>
		isSwitchedOn &&
		css`
			> svg path {
				fill: ${colors.primaryColor};
				fill-opacity: 1;
			}
		`}
`;
