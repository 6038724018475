import { FC, useEffect, useRef, useState } from 'react';
import { Label } from '../style/ModifyLessonModal.component.style';

//redux
import { enableAppMain } from 'redux/slices/slice-app-main';
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';

//utils
import { uploadAttachment } from './services/LessonAttachments.services';
import { putCourseContent } from 'services/course.services';

//types
import { LessonAttachmentsProps } from './LessonAttachments.component.d';

//style
import { 
	Attachments, 
	LessonAttachmentsBox, 
	AttachmentNewFile, 
	AttachNewFileButton, 
	AttachDeleteButton,
	Row,
} from './style/LessonAttachments.component.style';

//components
import { CourseNode } from 'pages/PublicProductPages/types/Course';


const LessonAttachments: FC<LessonAttachmentsProps> = ({ node, setIsContentModified, updateLocalNode, handleURLButton }) => {

	const [attachments, setAttachments] = useState<any[]>([]);

	const dispatch = useAppDispatch();

	const attachmentNewFileInput = useRef<HTMLInputElement>(null);
	
	const uploadB64 = async (nodeId: number, fileB64: string, fileName: string): Promise<any | null> => {
		if (typeof fileB64 === 'undefined') return null;
		if (fileB64.length === 0) return null;
		var uploaded: any;
		await fetch(fileB64)
			.then((res) => res.blob())
			.then(async (blob) => {
				var formData = new FormData();
				formData.append('file', blob, fileName);
				uploaded = await uploadAttachment(nodeId, formData);
			});
		return uploaded;
	};

	const extractFilename = (filePath: string) => {
		const segments = filePath.split('/');
		const filename = segments[segments.length - 1];
		return (filename !== '') ? filename : filePath;
	  };

	const getFileContentInBase64 = (files: File[]) => {
		const fileReadPromises = files.map((file) => {
			return new Promise<string>((resolve, reject) => {
				const reader = new FileReader();
				reader.onload = () => {
					resolve(reader.result as string);
				};
				reader.onerror = (error) => {
					reject(error);
				};
				reader.readAsDataURL(file);
			});
		});
		return Promise.all(fileReadPromises);
	};

	const handleSingleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		if (files === null) return;
		if (files && files.length > 0) {
			const fileB64: string[] = await getFileContentInBase64([files[0]]);
			const fileName = extractFilename(files[0].name);
			var uploaded = await uploadB64(node.id, fileB64[0], fileName);
			setAttachments(uploaded.attachments);
			setIsContentModified();
			var lesson = node.lesson;
			lesson.attachments = uploaded.attachments;
			const updatedNode = { ...node, lesson: lesson } as CourseNode;
			updateLocalNode(node.id, updatedNode);
			dispatch(enableAppMain());
		}
	};

	const handleFileButton = () => {
		if (attachmentNewFileInput.current) {
			attachmentNewFileInput.current.click();
		}
	}

	const handleDeleteAttachment = async (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		// eslint-disable-next-line no-restricted-globals
		if (confirm("Vuoi veramente cancellare l'allegato???")) {
			const button: HTMLButtonElement = event.currentTarget;
			var updatedAttachments = node.lesson.attachments.filter((a) => a.id !== Number(button.name));
			var updatedNode = { ...node} as Partial<CourseNode>;
			if (updatedNode.lesson) updatedNode.lesson.attachments = updatedAttachments;
			try {
				const response = await putCourseContent([updatedNode]);
				updateLocalNode(node.id, updatedNode);
				setIsContentModified();
			} catch (error) {
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'error';
				snackbarData.message = `C'è stato qualche problema nella cancellazione dell'allegato!`;
				dispatch(addSnackbar(snackbarData));
				throw error;
			}
		}
		return true;
	};

	useEffect(() => {
		// setAttachments(node.lesson.attachments);
	}, [node]);

	return (
		<LessonAttachmentsBox>
			<Row>
				<Label>Allegati</Label>
				
				<AttachNewFileButton onClick={handleFileButton}>
					Sfoglia
				</AttachNewFileButton>

				<AttachNewFileButton onClick={handleURLButton}>
					Usa URL
				</AttachNewFileButton>
			</Row>
			<Attachments>
				{node.lesson.attachments?.map((a) => {
					return <span>{a.fileName ? a.fileName : extractFilename(a.url)} <AttachDeleteButton onClick={handleDeleteAttachment} name={a.id.toString()}>&#10006;</AttachDeleteButton></span>;
				})}
			</Attachments>
			


			<AttachmentNewFile
				ref={attachmentNewFileInput}
				onChange={handleSingleFileChange}
			/>
		</LessonAttachmentsBox>
	);
};

export default LessonAttachments;
