import styled from 'styled-components';
import colors from 'style/palette/palette';


export const TabBarBox = styled.div<{ isUserLoggedIn?: boolean | undefined }>`

    width: 100%;
    min-height: 69px;

    padding: ${({ isUserLoggedIn }) => isUserLoggedIn ? "0 0 0 20px" : " 0 20px 0"};

    border-top: 0.5px solid ${colors.veryLightGrey};
    background: ${colors.veryDarkGrey};

    display: flex;
    align-items: stretch;
    justify-content: center;

    position: fixed;
    bottom: 0;
    z-index: 20;
`;

export const BrandBox = styled.div`

    align-self: center;
    flex: 1;

    a {

        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    a > svg {
        
        min-width: 71.39px;
        width: 71.39px;

        height: 35px;
    }
`;

export const SignupText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    text-align: center;
    flex: 2;

    > a {

        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
        text-decoration: none;

        color: ${colors.primaryColor};
    }
`;

export const IconsBar = styled.div`

    width: 100%;

    display: flex;
    align-items: stretch;
    justify-content: space-evenly;

    flex-direction: row;
`;
