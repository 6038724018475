import { FC, useState, useEffect } from 'react';

// routing
import BuildRoutes from 'routes/BuildRoutes';
import { useSearchParams } from 'react-router-dom';

// types
import { Brand } from 'types/Brand';

// redux
import { refreshUserSession, checkIfUserIsLoggedWithFacebook, saveFacebookPicture } from 'redux/actions/actions-user';
import { saveUserId, savePermissions, saveRoles } from 'redux/actions/actions-user';
import { setBrand } from 'redux/actions/actions-ui';

// hooks
import useAppIsMobile from 'hooks/useAppIsMobile';
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsFirstRender } from 'usehooks-ts';
import { useIsMounted } from 'usehooks-ts';

// utils
import config from 'configs/config';
import { extractGlobalRoles, facebookCheckConnection } from './App.helper';
import { getBrandsList } from 'services/brand.services';
import { getCurrentAuthUser } from 'services/user.services';
import { extractRolesAndPermissionFromUser } from './App.helper';
import { getRoles, getCategories, getSubCategories, getProducts, getLevels, getUsersByRoles, getPaymentTypes } from 'services/globalServices';
import { deleteAllNamedCookieByStrings, getCookieByStrings } from 'utils/cookie';

// components
import Header from './layout/Header';
import Footer from './layout/Footer';
import TabBar from './layout/Header/TabBar';
import SnackbarList from 'components/ui/SnackbarList';

//utils
import { authorPoolsRoles, staffRoles } from 'configs/staffRoles';

// style
import { MinRangeGuard, AppBox, Main, PageContainer, PageBackgroundOnSubmenuOpen } from './style/App.style';
import {
	saveAllCategories,
	saveAllProducts,
	saveAllLevels,
	saveAllRoles,
	saveAllSubCategories,
	saveAuthorPoolsUsers,
	savePaymentTypes,
	saveStaffUsers,
} from 'redux/actions/actions-global';
import { useLocation } from 'react-router-dom';

const App: FC<{}> = () => {
	facebookCheckConnection();

	const lastcookieuser = getCookieByStrings('CognitoIdentityServiceProvider', 'LastAuthUser');
    if (lastcookieuser && lastcookieuser != "") deleteAllNamedCookieByStrings('CognitoIdentityServiceProvider', 'LastAuthUser', lastcookieuser);

	const [userData, setUserData] = useState<any>(undefined);
	const [cognitoData, setCognitoData] = useState<any>([]);
	const [facebookData, setFacebookData] = useState<any>([]);
	const [isSubmenuOpen, setIsSubmenuOpen] = useState<boolean>(false);
	const { isAppMobile, brand } = useAppSelector((state) => state.ui);
	const { isMainActive } = useAppSelector((state) => state.appMain);
	const { tokenID } = useAppSelector((state) => state.user);
	const isFirstRender = useIsFirstRender();
	const dispatch = useAppDispatch();
	const isMounted = useIsMounted();
	const location = useLocation();
	const [searchParams] = useSearchParams();

	useEffect(() => {
		const pathSegments = location.pathname.split('/').filter((segment) => segment !== '');
		const title = `Backoffice ${pathSegments.slice(1).join('/')}`;
		document.title = title;
	  }, [location]);

	useAppIsMobile();

	const userSubmenuToggleHandler = () => setIsSubmenuOpen((prevState) => !prevState);
	const userSubmenuCloseHandler = () => setIsSubmenuOpen(false);

	useEffect(() => {
		if (isFirstRender) {
			dispatch(checkIfUserIsLoggedWithFacebook());
			dispatch(saveFacebookPicture());
		}

		dispatch(refreshUserSession());
		const intervalID = setInterval(() => dispatch(refreshUserSession()), 2700000);

		return () => clearInterval(intervalID);
	}, [isFirstRender, dispatch]);

	useEffect(() => {
        const gb = async () => {
            const brandList: Brand[] = await getBrandsList();
            var localBrand: Brand | undefined = undefined;
            localBrand = brandList.find((b) => window.location.hostname.includes(b.domain.replace('app.','')));
			if (searchParams.get('brand') && window.location.hostname.includes('localhost')) localBrand = brandList.find((b) => b.code === searchParams.get('brand'));
			if (!localBrand) localBrand = config.defaultBrand;
			console.log("BRAND SET: "+JSON.stringify(localBrand));
            dispatch(setBrand(localBrand));
        }
        gb();
    }, [location.pathname]);

	if (config.environment === 'production') console.log = function no_console() {};

	const getUserConnectedAccountData = (userData: any, accountType: string) => {
		if (!userData || userData === undefined) return;
		if (!userData.federateds || userData.federateds === undefined) return;

		return userData.federateds.filter((federatedAccount: any) => federatedAccount.federatedName === accountType);
	};

	const getUserData = async () => {
		try {
			const userCompleteData = await getCurrentAuthUser();
			if (!isMounted) return;

			if (!userCompleteData || userCompleteData === undefined) {
				throw new Error('User Data are undefined.');
			}
			setUserData(userCompleteData);
		} catch (error: any) {
			console.warn('Unable getting User Data.', error);
		}
	};

	const getAllRoles = async () => {
		try {
			const response = await getRoles();
			// const { roles } = extractGlobalRoles(response);
			dispatch(saveAllRoles(response));
		} catch (error: any) {}
	};

	const getAllCategories = async () => {
		if (!brand) return;
		try {
			const response = await getCategories(brand.code);
			// const { roles } = extractGlobalRoles(response);
			dispatch(saveAllCategories(response));
		} catch (error: any) {}
	};

	const getAllSubCategories = async () => {
		if (!brand) return;
		try {
			const response = await getSubCategories(brand.code);
			// const { roles } = extractGlobalRoles(response);
			dispatch(saveAllSubCategories(response));
		} catch (error: any) {}
	};

	const getAllProducts = async () => {
		if (!brand) return;
		try {
			const types = ['COURSE', 'SERVICE', 'AUTOMATIC_INCOME', 'CREDIT_PACK', 'LESSON_PACK', 'THEMATIC_BROADCAST'];
			const response = await getProducts(brand.code, types);
			dispatch(saveAllProducts(response));
		} catch (error: any) {}
	};

	const getAllLevels = async () => {
		try {
			const response = await getLevels();
			dispatch(saveAllLevels(response));
		} catch (error: any) {}
	};

	const getAllStaffUsers = async () => {
		try {
			const response = await getUsersByRoles({ roles: staffRoles, size: 200 });
			// const { roles } = extractGlobalRoles(response);
			// console.log("STAFF RESPONSE: "+JSON.stringify(response));
			dispatch(saveStaffUsers(response.content));
		} catch (error: any) {}
	};

	const getAuthorPoolsUsers = async () => {
		try {
			const response = await getUsersByRoles({ roles: authorPoolsRoles, size: 200 });
			// const { roles } = extractGlobalRoles(response);
			dispatch(saveAuthorPoolsUsers(response.content));
		} catch (error: any) {}
	};

	const getAllPaymentTypes = async () => {
		try {
			const response = await getPaymentTypes();
			// const { roles } = extractGlobalRoles(response);
			dispatch(savePaymentTypes(response));
		} catch (error: any) {}
	};

	//initial loadings
	useEffect(() => {
		if (!userData || userData === undefined) return;
		getAllRoles();
		getAllCategories();
		getAllSubCategories();
		getAllProducts();
		getAllLevels();
		getAllStaffUsers();
		getAuthorPoolsUsers();
		getAllPaymentTypes();
	}, [userData]);
 
	useEffect(() => {
		getUserData();
	}, [tokenID]);

	useEffect(() => {
		if (!userData || userData === undefined) return;

		const cognitoGlobalData = getUserConnectedAccountData(userData, 'Cognito');
		const facebookGlobalData = getUserConnectedAccountData(userData, 'Facebook');

		if (cognitoGlobalData) setCognitoData(cognitoGlobalData[0]);
		if (facebookGlobalData) setFacebookData(facebookGlobalData[0]);

		// console.log('UserData: ', userData);
		dispatch(saveUserId(userData.id));

		const { permissions, roles } = extractRolesAndPermissionFromUser(userData);
		dispatch(saveRoles(roles));
		dispatch(savePermissions(permissions));
	}, [userData]);

	return (
		<>
			<MinRangeGuard />

			<AppBox>
				{!isAppMobile && (
					<Header
						isSubmenuOpen={isSubmenuOpen}
						onSubmenuButtonClick={userSubmenuToggleHandler}
						onSubmenuLinkClick={userSubmenuCloseHandler}
					/>
				)}

				<Main isDisabled={!isMainActive}>
					<PageContainer>
						<BuildRoutes />
					</PageContainer>

					{isSubmenuOpen && isAppMobile && <PageBackgroundOnSubmenuOpen />}
				</Main>

				{!isAppMobile && <Footer />}

				{isAppMobile && (
					<TabBar
						isSubmenuOpen={isSubmenuOpen}
						onSubmenuButtonClick={userSubmenuToggleHandler}
						onSubmenuLinkClick={userSubmenuCloseHandler}
					/>
				)}
			</AppBox>

			<SnackbarList />
		</>
	);
};

export default App;
