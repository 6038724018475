import { FC, useEffect, useState } from 'react';

//redux
import { enableAppMain } from 'redux/slices/slice-app-main';
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';

//types
import { ModifyLessonModalProps } from './ModifyLessonModal.component.d';
import { CourseLesson } from 'types/Course';
import { LdLFile } from 'types/LdLFile';

//components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';
import LessonTitle from './LessonTitle';
import LessonDescription from './LessonDescription';
import LessonVideo from './LessonVideo';
import LessonAttachments from './LessonAttachments';
import { CourseNode } from 'pages/PublicProductPages/types/Course';
import AttachmentByURLModal from './LessonAttachments/AttachmentByURLModal/AttachmentByURLModal.component';

//utils
import { uploadVideo } from './LessonVideo/services/LessonVideo.services';
import { putCourseContent } from 'services/course.services';

//tools
import { CKEditor } from 'ckeditor4-react';

//style
import { ButtonsRow, LessonCancelSaveButton, LessonSaveButton, ModifyLessonBox } from './style/ModifyLessonModal.component.style';

const ModifyLessonModal: FC<ModifyLessonModalProps> = ({ node, onCloseButtonModal, updateLocalNode }) => {
	const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);

	const [localTitle, setLocalTitle] = useState('');
	const [localDescription, setLocalDescription] = useState('');

	const [localVideoUrl, setLocalVideoUrl] = useState('');
	const [localAttachmentsList, setLocalAttachmentsList] = useState('');

	const [isContentModified, setIsContentModified] = useState<boolean>(false);

	const [isAttachmentByURLModalVisible, setIsAttachmentByURLModalVisible] = useState<boolean>(false);

	const dispatch = useAppDispatch();

	const onModified = () => {
		setIsContentModified(true);
	}

	const closeAttachmentByURLModalVisible = () => {
		setIsAttachmentByURLModalVisible(false);
	}

	const closeButtonHandler = () => {
		if (onCloseButtonModal) onCloseButtonModal();
		dispatch(enableAppMain());
	};

	const onSaveClickHandler = async () => {
		//save node
		const updatedNode = { ...node, title: localTitle } as Partial<CourseNode>;
		const localVideo = node.lesson.video ? { ...node.lesson.video } : null;
		if (localDescription || localVideoUrl) updatedNode.lesson = { id: node.lesson.id, description: node.lesson.description, video: localVideo, attachments: node.lesson.attachments } as CourseLesson;
		if ((updatedNode.lesson)&&(localDescription !== node.lesson.description)){
			updatedNode.lesson.description = localDescription;
			try {
				const response = await putCourseContent([updatedNode]);	
			} catch (error) {
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'error';
				snackbarData.message = `C'è stato qualche problema nel salvataggio della descrizione!`;
				dispatch(addSnackbar(snackbarData));
				throw error;
			}
		}

		if (((node.lesson.video) && (localVideoUrl !== node.lesson.video.url)) || (localVideoUrl)) {
			if (updatedNode.lesson) {
				if (((node.lesson.video==null)||(localVideoUrl !== node.lesson.video.url))) {
					try {
						var uploadedVideo = await uploadVideo(node.lesson.id, localVideoUrl);
						updatedNode.lesson.video = uploadedVideo.video;
					} catch (error) {
						const snackbarData: SnackbarOptions = {};
						snackbarData.type = 'error';
						snackbarData.message = `C'è stato qualche problema nel salvataggio del video!`;
						dispatch(addSnackbar(snackbarData));
						throw error;
					}
				} else {
					// updatedNode.lesson.video = {} as LdLFile;
					// updatedNode.lesson.video.url = localVideoUrl;
					updatedNode.lesson.video = node.lesson.video;
				}
			}
		}

		updateLocalNode(node.id, updatedNode);
		if (onCloseButtonModal) onCloseButtonModal();
		dispatch(enableAppMain());
	};

	const attachmentsReducer = (a: string, b: LdLFile) => a + b.fileName;

	useEffect(() => {
		setLocalTitle(node.title);
	}, [node]);

	useEffect(() => {
		setLocalDescription(node.lesson.description);
	}, [node.lesson.description]);

	useEffect(() => {
		setLocalVideoUrl(node.lesson.video?.url);
	}, [node.lesson.video?.url]);

	useEffect(() => {
		setLocalAttachmentsList(node.lesson.attachments?.reduce(attachmentsReducer, ''));
	}, [node.lesson.attachments]);

	//check modifications to save
	useEffect(() => {
		setIsContentModified(
			localTitle !== node.title ||
				localDescription !== node.lesson.description ||
				localVideoUrl !== node.lesson.video?.url //||
		);
	}, [
		//localAttachmentsList,
		localDescription,
		localTitle,
		localVideoUrl,
		//node.lesson.attachments,
		node.lesson.description,
		node.lesson.video?.url,
		node.title,
	]);

	return (
		<>
		<BaseModal
			isBackdropClickActive={true}
			onClose={closeButtonHandler}
			isCloseButtonClicked={isCloseButtonClicked}
		>
			<BaseCard
				flavor='outlined'
				maxWidth='700px'
			>
				<ModifyLessonBox>
					<LessonTitle
						title={localTitle}
						setLocalTitle={setLocalTitle}
					/>
					<CKEditor
						onChange={(evt) => {
							setLocalDescription(evt.editor.getData());
						}}
						initData={node.lesson.description}
						onInstanceReady={(editor) => {}}
						config={{
							language: 'it',
							height: '300px',
							removeButtons: 'Image',
							removePlugins: 'resize',
							extraPlugins: 'emoji',
						}}
					/>
					<LessonVideo
						videoUrl={localVideoUrl}
						setLocalVideoUrl={setLocalVideoUrl}
					/>
					<LessonAttachments
						node={node}
						setIsContentModified={onModified}
						updateLocalNode={updateLocalNode}
						handleURLButton={() => setIsAttachmentByURLModalVisible(true)}
					/>
					<ButtonsRow>
						<LessonSaveButton
							disabled={!isContentModified}
							onClick={onSaveClickHandler}
						>
							Salva Modifiche
						</LessonSaveButton>
						<LessonCancelSaveButton onClick={closeButtonHandler}>Chiudi</LessonCancelSaveButton>
					</ButtonsRow>
				</ModifyLessonBox>
			</BaseCard>
		</BaseModal>
		{isAttachmentByURLModalVisible && (
			<AttachmentByURLModal
				onCloseButtonModal={closeAttachmentByURLModalVisible}
				node={node}
				updateLocalNode={updateLocalNode}
				setIsContentModified={() => {setIsContentModified(true)}}
			/>
		)}
		</>
	);
};

export default ModifyLessonModal;
