import { FC } from 'react';
import { Label } from '../style/ModifyTestimonialModal.component.style';

//types
import { TestimonialProfilePicProps } from './TestimonialProfilePic.component.d';

//style
import { LessonVideoBox, VideoUrl } from './style/TestimonialProfilePic.component.style';

const TestimonialProfilePic: FC<TestimonialProfilePicProps> = ({ profilePicUrl, setLocalProfilePicUrl }) => {
	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLocalProfilePicUrl(event.currentTarget.value);
	};
	return (
		<LessonVideoBox>
			<Label>Profile Pic</Label>
			<VideoUrl
				value={profilePicUrl}
				onChange={onChangeHandler}
				placeholder='Inserire url ftp'
			></VideoUrl>
		</LessonVideoBox>
	);
};

export default TestimonialProfilePic;
