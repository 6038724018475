import styled, { css } from 'styled-components';

import colors from 'style/palette';

export const CategoryBox = styled.div<{ enabled?: boolean }>`
	font-size: 0.75rem;
	background-color: ${colors.black020};
	border: none;
	padding: 0.5rem;
	text-align: center;
	color: ${colors.white};
	border-radius: 0.2rem;
	cursor: pointer;
	${({ enabled }) =>
		enabled &&
		css`
			background-color: ${colors.green};
		`}
`;
