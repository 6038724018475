import { FC, useEffect, useState } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsMounted } from 'usehooks-ts';

//types
import { VisibleProps } from './Visible.component.d';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

//assets
import { ReactComponent as VisibleOnIcon } from './assets/visibleON.svg';
import { ReactComponent as VisibleOffIcon } from './assets/visibleOFF.svg';

//utils

//style
import { VisibleBox, VisibleIcon } from './style/Visible.component.style';
import useHasRole from 'hooks/useHasRole';

const Visible: FC<VisibleProps> = ({ productId, visible, putProduct }) => {
	const dispatch = useAppDispatch();
	const isMounted = useIsMounted();

	const isAdmin = useHasRole('ADMIN');
	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');
	var isDiretta = useHasRole('DIRETTA');
	const isRegistaLDL = useHasRole('REGISTA-LDL');
	const isRegistaMB = useHasRole('REGISTA-MB')
	const isRegistaTCR = useHasRole('REGISTA-TCR');
	const isEditorLDL = useHasRole('EDITOR-LDL');
	const isEditorMB = useHasRole('EDITOR-MB');
	const isEditorTCR = useHasRole('EDITOR-TCR');
	isDiretta = isDiretta || isRegistaLDL || isRegistaMB || isRegistaTCR || isEditorLDL || isEditorMB || isEditorTCR;
	
	const clickHandler = async () => {
		const snackbarData: SnackbarOptions = {};

		try {
			await putProduct({ id: productId, visible: !visible });
		} catch (error) {
			snackbarData.type = 'error';
			snackbarData.message = "Errore nell'aggiornamento visibilità";
			dispatch(addSnackbar(snackbarData));
			if (!isMounted()) return;
		}
	};

	return (
		<VisibleBox>
			<VisibleIcon
				title={isSuperAdmin ? (visible ? 'Nascondi' : 'Mostra') : ''}
				visible={visible}
				canToggle={isSuperAdmin || isDiretta}
				onClick={(isSuperAdmin || isDiretta) ? clickHandler : () => alert('Non hai i permessi per modificare la visibilità del prodotto')}
			>
				{visible && <VisibleOnIcon />}
				{!visible && <VisibleOffIcon />}
			</VisibleIcon>
		</VisibleBox>
	);
};

export default Visible;
