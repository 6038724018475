import { FC } from "react";

//style
import {
    DescriptionBox,
    AttachmentLink,
    LinkAttachment,
} from './style/DescriptionTabDesktop.style';


const DescriptionTabDesktop: FC<{courseItem?: any}> = ({courseItem}) => {

    let lesson;
    let attachments = null;

    if (courseItem !== undefined) {
    lesson = courseItem.lesson ?? null;
        if ( lesson !== undefined && lesson !== null){
        attachments = lesson.attachments ?? null;
        }
    }

    return (
        <DescriptionBox>
            {attachments !== null && 
                attachments.map((element:any, index:number) => {
                    return (<AttachmentLink key={index}>
                                <LinkAttachment href={element.url}>
                                    {element.fileName}
                                </LinkAttachment>
                            </AttachmentLink>)
                })
            }
        </DescriptionBox>
    );
};


export default DescriptionTabDesktop;