import DeleteElementModal from 'components/DeleteElementModal/DeleteElementModal.component';
import NewElementModal from 'components/NewElementModal/NewElementModal.component';
import { FC, useState } from 'react';

// types
import { GroupUpdateElementProps } from './GroupUpdateElement.component.d';

// style
import { 
    CardContent,
    GroupContent,
    ButtonRow,
    AddElementButton,
    DeleteElementButton,
    CloseAndCancel
} from './style/GroupUpdateElement.style';


const GroupUpdateElement: FC<GroupUpdateElementProps> = ({
    onCancelClickHandler,
    elementsToDisplay,
    updateData,
    blockId,
    newElementPos,
    webpageId
}) => {

    const [isAddNewElementModalVisible, setIsAddNewElementModalVisible] = useState<boolean>(false);
    const [isRemoveGroupModalVisible, setIsRemoveGroupModalVisible] = useState<boolean>(false);

    
    const addNewElementHandler = () => setIsAddNewElementModalVisible(prevState => !prevState);
    const onDeleteButtonHandler = () => setIsRemoveGroupModalVisible(true);

    const onCancelButtonClickHandler = () => {
        
        setIsAddNewElementModalVisible(false);
        if (onCancelClickHandler) onCancelClickHandler();
    }
    

    return (
        <>
            <CardContent>
                <GroupContent>
                    {elementsToDisplay}
                </GroupContent>

                <ButtonRow>
                    <AddElementButton
                        onClick={addNewElementHandler}
                    >
                        Aggiungi un elemento
                    </AddElementButton>

                    <DeleteElementButton
                        onClick={onDeleteButtonHandler}
                    >
                        Elimina tutto il gruppo
                    </DeleteElementButton>

                    <CloseAndCancel    
                            onClick={onCancelClickHandler}
                    >
                        Chiudi
                    </CloseAndCancel>
                </ButtonRow>
            </CardContent>        

            {isAddNewElementModalVisible && (
                <NewElementModal 
                    onCloseButtonModal={onCancelButtonClickHandler}
                    typeOfLayout='ROW'
                    newElementPosition={newElementPos}
                    blockId={blockId}
                    updateData={updateData}
                    webpageId={webpageId}
                />)
            }
            
            {isRemoveGroupModalVisible && (
                <DeleteElementModal 
                    onCloseButtonModal={onCancelButtonClickHandler}
                    blockId={blockId}
                    updateData={updateData}
                />)
            }
        </>
    );
};

export default GroupUpdateElement;
