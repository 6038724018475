import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SnackbarOptions } from './slice-snackbar.d';


interface SnackbarData extends SnackbarOptions {

    id: string
};

interface SnackbarState {

    items: SnackbarData[]
};

// initial slice state
const initialState: SnackbarState = {

    items: []
};

const snackbarSlice = createSlice(
    {
        name: 'snackbar',
        initialState,

        reducers: {

            setItem: (state, action: PayloadAction<SnackbarData[]>) => {
                state.items = action.payload;
            }
        }
    }
);

// slice actions mapping
export const {
    setItem
} = snackbarSlice.actions;

// reducer for store
export default snackbarSlice.reducer;
