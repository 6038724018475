import { callPUT } from 'network/network';

import { getBrand } from 'configs/brandMap/brandMap.helper';

const _SUBSCRIPTION_NUMBER_LIMIT_PATH = 'v1/products/{id}/maxsubscriptions/remove';
const _SUBSCRIPTION_DATE_LIMIT_PATH = 'v1/products/{id}/subscriptionmaxdate/remove';
const _THEMATICBROADCAST_PROTAGONIST_NUMBER_LIMIT_PATH = 'v1/products/{id}/thematicbroadcast/maxprotagonist/remove';
const _THEMATICBROADCAST_SPECTATOR_NUMBER_LIMIT_PATH = 'v1/products/{id}/thematicbroadcast/maxspectator/remove';
const _THEMATICBROADCAST_LIVE_DATE_LIMIT_PATH = 'v1/products/{id}/thematicbroadcast/livedate/remove';
const _THEMATICBROADCAST_SALECOLLECTION_DATE_LIMIT_PATH = 'v1/products/{id}/thematicbroadcast/salecollectiondate/remove';
const _THEMATICBROADCAST_EXPIRATION_DATE_LIMIT_PATH = 'v1/products/{id}/thematicbroadcast/expirationdate/remove';
const _THEMATICBROADCAST_SCHEDULE_PATH = 'v1/products/{id}/thematicbroadcast/status/schedulated';


export const removeSubscriptionNumberLimit = async (productId: number) => {
	const brand = getBrand();
	if (!brand) return;
	try {
		const response = await callPUT(_SUBSCRIPTION_NUMBER_LIMIT_PATH.replace(
			/{id}/g,
			productId.toString()
		  ), {}, true, { brandCode: brand.code });

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const removeSubscriptionDateLimit = async (productId: number) => {
	const brand = getBrand();
	if (!brand) return;
	try {
		const response = await callPUT(_SUBSCRIPTION_DATE_LIMIT_PATH.replace(
			/{id}/g,
			productId.toString()
		  ), {}, true, { brandCode: brand.code });

		return response;
	} catch (error: any) {
		throw error;
	}
};


export const removeThematicBroadcastProtagonistNumberLimit = async (productId: number) => {
	const brand = getBrand();
	if (!brand) return;
	try {
		const response = await callPUT(_THEMATICBROADCAST_PROTAGONIST_NUMBER_LIMIT_PATH.replace(
			/{id}/g,
			productId.toString()
		  ), {}, true, { brandCode: brand.code });

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const removeThematicBroadcastSpectatorNumberLimit = async (productId: number) => {
	const brand = getBrand();
	if (!brand) return;
	try {
		const response = await callPUT(_THEMATICBROADCAST_SPECTATOR_NUMBER_LIMIT_PATH.replace(
			/{id}/g,
			productId.toString()
		  ), {}, true, { brandCode: brand.code });

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const removeThematicBroadcastLiveDateLimit = async (productId: number) => {
	const brand = getBrand();
	if (!brand) return;
	try {
		const response = await callPUT(_THEMATICBROADCAST_LIVE_DATE_LIMIT_PATH.replace(
			/{id}/g,
			productId.toString()
		  ), {}, true, { brandCode: brand.code });

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const removeThematicBroadcastSaleCollectionDateLimit = async (productId: number) => {
	const brand = getBrand();
	if (!brand) return;
	try {
		const response = await callPUT(_THEMATICBROADCAST_SALECOLLECTION_DATE_LIMIT_PATH.replace(
			/{id}/g,
			productId.toString()
		  ), {}, true, { brandCode: brand.code });

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const removeThematicBroadcastExpirationDateLimit = async (productId: number) => {
	const brand = getBrand();
	if (!brand) return;
	try {
		const response = await callPUT(_THEMATICBROADCAST_EXPIRATION_DATE_LIMIT_PATH.replace(
			/{id}/g,
			productId.toString()
		  ), {}, true, { brandCode: brand.code });

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const scheduleThematicBroadcast = async (productId: number) => {
	const brand = getBrand();
	if (!brand) return;
	try {
		const response = await callPUT(_THEMATICBROADCAST_SCHEDULE_PATH.replace(
			/{id}/g,
			productId.toString()
		  ), {}, true, { brandCode: brand.code });

		return response;
	} catch (error: any) {
		throw error;
	}
};