import { FC } from 'react';

// types
// import { Footer } from './Footer.d.ts';

// ultils
import config from 'configs/config';

// assets
import { ReactComponent as LDLHorizontalLogoSVG } from './assets/LDL-extended-logo.svg';
import { ReactComponent as LDLShortIconSVG } from './assets/LDL-icon.svg';
import { ReactComponent as FacebookIconSVG } from './assets/facebook-icon.svg';
import { ReactComponent as TelegramIconSVG } from './assets/telegram-icon.svg';
import { ReactComponent as YouTubeIconSVG } from './assets/youtube-icon.svg';
import { ReactComponent as InstagramIconSVG } from './assets/instagram-icon.svg';
import { ReactComponent as TwitchIconSVG } from './assets/twitch-icon.svg';
import { ReactComponent as TikTokIconSVG } from './assets/tiktok-icon.svg';

// style
import { FooterBar, BrandBox, InfoBox, PrivacyAndSocials, Socials, SocietyName, SocietyAddress } from './style/Footer.style';

/**
 * Footer
 *
 * @author Alessio Grassi
 *
 * @returns JSX
 */

const Footer: FC<{}> = () => {
	const { socialLinks } = config;

	return (
		<FooterBar>
			<BrandBox>
				<LDLShortIconSVG />

				<LDLHorizontalLogoSVG />

				<InfoBox>
					<SocietyName>EASYTENERLIFE SL </SocietyName>
					<SocietyAddress>Carretera La Curte Bajo, nùmero 105 - puerta 2 - planta baja - Los Menores - 38678 Adeje - S.C. Tenerife NIF: B76784420</SocietyAddress>
				</InfoBox>
			</BrandBox>

			<PrivacyAndSocials>
				<Socials>
					<a
						href={socialLinks?.facebook}
						target='_blank'
						rel='noreferrer noopener'
					>
						<FacebookIconSVG />
					</a>

					<a
						href={socialLinks?.telegram}
						target='_blank'
						rel='noreferrer noopener'
					>
						<TelegramIconSVG />
					</a>

					<a
						href={socialLinks?.youtube}
						target='_blank'
						rel='noreferrer noopener'
					>
						<YouTubeIconSVG />
					</a>

					<a
						href={socialLinks?.instagram}
						target='_blank'
						rel='noreferrer noopener'
					>
						<InstagramIconSVG />
					</a>

					<a
						href={socialLinks?.twitch}
						target='_blank'
						rel='noreferrer noopener'
					>
						<TwitchIconSVG />
					</a>

					<a
						href={socialLinks?.tikTok}
						target='_blank'
						rel='noreferrer noopener'
					>
						<TikTokIconSVG />
					</a>
				</Socials>
			</PrivacyAndSocials>
		</FooterBar>
	);
};

export default Footer;
