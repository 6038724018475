import { callGET, callPUT } from 'network/network';
import { Level } from 'types/Level';

const _LEVELS_PATH = 'v1/vippathlevel';

export const getLevelFromId = async (levelId: number, brandCode: string) => {
	try {
		const response = await callGET(_LEVELS_PATH + '/' + levelId, true, {});

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const putLevel = async (updatedLevel: Partial<Level>) => {
	try {
		await callPUT(_LEVELS_PATH, updatedLevel, true, {});
	} catch (error: any) {
		throw error;
	}
};
