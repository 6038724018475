import {FC, useRef, useState} from 'react'

//hooks
import { useDrag, useDrop } from 'react-dnd';


//types
import {TestimonialDragItem, TestimonialProps} from './Testimonial.component.d'
import type { Identifier, XYCoord } from 'dnd-core';

//assets
import { ReactComponent as DragHandleIcon } from './assets/dragHandleIcon.svg';
import { ReactComponent as VideoIcon } from './assets/onDemandVideoIcon.svg';
import { ReactComponent as DescriptionIcon } from './assets/descriptionIcon.svg';
import { ReactComponent as ModifyIcon } from './assets/modifyIcon.svg';
import { ReactComponent as DeleteIcon } from './assets/deleteIcon.svg';
import { ReactComponent as FaceIcon } from './assets/faceIcon.svg';
import { ReactComponent as VisibleOnIcon } from './assets/visibleON.svg';
import { ReactComponent as VisibleOffIcon } from './assets/visibleOFF.svg';

//components
import VideoPreviewModal from 'components/VideoPreviewModal';
import TestimonialTitle from './TestimonialTitle';
import ModifyTestimonialModal from './ModifyTestimonialModal';

//style
import { DeleteTestimonial, Description, Handle, LeftSection, LessonSection, ModifyTestimonial, ProfilePicture, RightSection, TestimonialBox, TestimonialId, Video, Visible } from './style/Testimonial.component.style'

export const ItemTypes = {
	TESTIMONIAL: 'testimonial',
};

const Testimonial: FC<TestimonialProps> = ({
	testimonial, 
	index, 
	moveTestimonial, 
	deleteTestimonial, 
	updateLocalTestimonial,
	putRemoteTestimonial
}) => {
	const [isOnHover, setIsOnHover] = useState(false);
	
	const [isVideoPreviewModalVisible, setIsVideoPreviewModalVisible] = useState<boolean>(false);
	const [isModifyTestimonialModalVisible, setIsModifyTestimonialModalVisible] = useState<boolean>(false);
	
    const dragRef = useRef<HTMLDivElement>(null);
	const previewRef = useRef<HTMLDivElement>(null);

	const onMouseOverHandler = (event: React.MouseEvent<HTMLInputElement>) => {
		setIsOnHover(true);
	};

	const onMouseLeaveHandler = (event: React.MouseEvent<HTMLInputElement>) => {
		setIsOnHover(false);
	};

	const videoPreviewClickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
		setIsVideoPreviewModalVisible(true);
	};

	const closeVideoPreviewModal = () => {
		setIsVideoPreviewModalVisible(false);
	};

	const modifyTestimonialClickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
		setIsModifyTestimonialModalVisible(true);
	};

	const closeModifyTestimonialModal = () => {
		setIsModifyTestimonialModalVisible(false);
	};

	const visibleClickHandler = async () => {
		try {
			await putRemoteTestimonial(testimonial.id, {visible: !testimonial.visible})
			updateLocalTestimonial(testimonial.id, {visible: !testimonial.visible, weight: 0})
		} catch (error) {
			console.log(error);
			
		}
	}

	const deleteTestimonialClickHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
		if (window.confirm('Cancellare la testimonianza ' + testimonial.id + ' | ' + testimonial.title + '?')) 
		await deleteTestimonial(testimonial.id);
	};

	const [{ handlerId }, drop] = useDrop<TestimonialDragItem, void, { handlerId: Identifier | null }>({
		accept: ItemTypes.TESTIMONIAL,
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
			};
		},
		hover(item: TestimonialDragItem, monitor) {
			if (!dragRef.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;

			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return;
			}

			// Determine rectangle on screen
			const hoverBoundingRect = dragRef.current?.getBoundingClientRect();

			// Get vertical middle
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

			// Determine mouse position
			const clientOffset = monitor.getClientOffset();

			// Get pixels to the top
			const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%

			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}

			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}

			// Time to actually perform the action
			moveTestimonial(dragIndex, hoverIndex);

			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex;
		},
	});

	const [{ isDragging }, drag, preview] = useDrag({
		type: ItemTypes.TESTIMONIAL,
		item: () => {
			return { testimonialId: testimonial.id, index: index } as TestimonialDragItem;
		},
		collect: (monitor: any) => ({
			isDragging: monitor.isDragging(),
		}),
	});

	drag(dragRef);
	drop(preview(previewRef));
    
    return  (
		<>
			<TestimonialBox
					ref={previewRef}
					data-handler-id={handlerId}
					isDragging={isDragging}
					onMouseOver={onMouseOverHandler}
					onMouseLeave={onMouseLeaveHandler}
					title={
						`video: ${testimonial.video ? testimonial?.video?.url : ''}\nCover: ${testimonial?.videoCover ? testimonial.videoCover?.url : ''}\ndescription: ${testimonial?.description ? testimonial.description : ''}\nProfile Pic: ${testimonial?.profilePicture ? testimonial.profilePicture?.url : ''}`}
			>
				<LeftSection>
					<Handle
						ref={dragRef}
					>
						<DragHandleIcon />
					</Handle>
					<Visible visible={testimonial.visible} onClick={visibleClickHandler}>
						{testimonial.visible && <VisibleOnIcon/>}
						{!testimonial.visible && <VisibleOffIcon/>}
					</Visible>
					<TestimonialTitle
						testimonialId={testimonial.id}
						testimonialTitle={testimonial.title}
						updateLocalTestimonial={updateLocalTestimonial}
						putRemoteTestimonial={putRemoteTestimonial}
					/>
				</LeftSection>
				<LessonSection>
					{testimonial.video && (
						<Video
							onClick={videoPreviewClickHandler}
							title={testimonial.video.url}
						>
							<VideoIcon />
						</Video>
					)}
					{testimonial.profilePicture && (
						<ProfilePicture
							title={testimonial.profilePicture.url}
							imgUrl={testimonial.profilePicture.url}
						>
							<FaceIcon />
							<div className="preview">
								<img src={testimonial.profilePicture.url} alt="Profile" />
							</div>
						</ProfilePicture>
					)}
					{testimonial.description && (
						<Description>
							<DescriptionIcon />
						</Description>
					)}
					
				</LessonSection>
				<RightSection>
					<TestimonialId>ID testimonianza: {testimonial.id}</TestimonialId>
					{isOnHover && (
						<ModifyTestimonial
							title='Modifica testimonianza'
							onClick={modifyTestimonialClickHandler}
						>
							<ModifyIcon />
						</ModifyTestimonial>
					)}
					{isOnHover && (
						<DeleteTestimonial
							title='Cancella nodo'
							onClick={deleteTestimonialClickHandler}
						>
							<DeleteIcon />
						</DeleteTestimonial>
					)}
				</RightSection>
			</TestimonialBox>
				
			{isVideoPreviewModalVisible && (
				<VideoPreviewModal
					onCloseButtonModal={closeVideoPreviewModal}
					url={testimonial.video?.url}
					coverUrl={testimonial.videoCover?.url}
				/>
			)}
			{isModifyTestimonialModalVisible && (
				<ModifyTestimonialModal
					onCloseButtonModal={closeModifyTestimonialModal}
					testimonial={testimonial}
					updateLocalTestimonial={updateLocalTestimonial}
				/>
			)}
		</>
	)
}

export default Testimonial