import React, { FC, useEffect, useRef, useState } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { updateProduct } from 'redux/actions/actions-global';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsMounted } from 'usehooks-ts';
import useAppSelector from 'hooks/useAppSelector';
import { getBrand } from 'configs/brandMap/brandMap.helper';

//types
import { PriceProps } from './Price.component.d';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { Product } from 'types/Product';

//assets
import { ReactComponent as ModifyIcon } from './assets/modifyIcon.svg';
import { ReactComponent as CheckCircleIcon } from './assets/checkCircleIcon.svg';
import { ReactComponent as VipIcon } from './assets/vipIcon.svg';


//utils
import { getProductPrice, setProductPriceAttributes } from 'pages/ProductsPage/ProductsPage.helper';

//style
import { PriceBox, PriceField, PriceModifyButton } from './style/Price.component.style';
import useHasRole from 'hooks/useHasRole';

const Price: FC<PriceProps> = ({ product, putProduct }) => {
	const [isEditing, setIsEditing] = useState(false);
	const [price, setPrice] = useState(0);
	const [input, setInput] = useState('');

	const isAdmin = useHasRole('ADMIN');
	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');
	const hasAdminRole = isAdmin || isSuperAdmin;

	const productsList = useAppSelector((state) => state.global.allProducts) as Product[];

	const priceFieldRef = useRef<HTMLInputElement>(null);
	const dispatch = useAppDispatch();
	const isMounted = useIsMounted();
	const brand = getBrand();

	useEffect(() => {
		setPrice(getProductPrice(product));
	}, [product]);

	useEffect(() => {
		let priceString = price.toString();
		if (!isEditing) {
			priceString = priceString.replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
		}
		setInput(priceString);
	}, [price, isEditing]);

	const clickEditingButtonHandler = () => {
		setIsEditing(true);
	};

	const clickConfirmModifyButtonHandler = async () => {
		if (+input !== price) {
			const res = window.confirm('Modificare il prezzo di ' + product.name + ' in ' + input + ' € ?');
			if (res) {
				try {
					const updatedProduct = setProductPriceAttributes(product, price) as Partial<Product>;
					await updateProductPrice();
					dispatch(updateProduct(productsList, updatedProduct));
				} catch (error) {}
			}
			if (!res) {
				setInput(price?.toString());
				priceFieldRef.current?.blur();
				setIsEditing(false);
			}
		}
		setIsEditing(false);
	};

	const inputPriceChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		//validazione
		// console.log('type of input', typeof input);
		// console.log('type of +input', typeof +input);
		// console.log('+input', +input);
		// console.log('isNan', isNaN(+input));

		setInput(event?.currentTarget.value);
	};

	const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
		// console.log('input', +input);
		// console.log('price', price);

		if (+input !== price) {
			// setInput(price?.toLocaleString('it-IT'));
		}
		// setIsEditing(false);
	};

	const onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
		const code = event.code;

		if (code === 'Escape') {
			setInput(price?.toString());
			priceFieldRef.current?.blur();
			setIsEditing(false);
		}
		if (code === 'Enter' || code === 'NumpadEnter') {
			updateProductPrice();
			priceFieldRef.current?.blur();
			setIsEditing(false);
		}
	};

	const updateProductPrice = async () => {
		const snackbarData: SnackbarOptions = {};

		try {
			const updatedProduct: Partial<Product> = { id: product.id };
			if (product.type === 'COURSE') updatedProduct.productCourse = { ...product.productCourse, price: +input };
			if (product.type === 'LESSON_PACK') updatedProduct.productLessonPack = { ...product.productLessonPack, price: +input };
			await putProduct(updatedProduct);
		} catch (error) {
			snackbarData.type = 'error';
			snackbarData.message = "Errore nell'aggiornamento prezzo";
			dispatch(addSnackbar(snackbarData));
			if (!isMounted()) return;
			console.log(price);
			setInput(price?.toString());
			priceFieldRef.current?.blur();
			setIsEditing(false);
		}
	};

	useEffect(() => {
		if (isEditing) {
			priceFieldRef.current?.focus();
		}
	}, [isEditing]);

	return (
		<PriceBox>
			<PriceField
				id={product.id + '_price-field'}
				ref={priceFieldRef}
				isEditing={isEditing}
				type={'text'}
				disabled={!isEditing}
				value={input}
				onChange={inputPriceChangeHandler}
				onBlur={onBlurHandler}
				onKeyDown={onKeyDownHandler}
			/>
			{product.type === 'THEMATIC_BROADCAST' && product?.productThematicCall.type === 'ESPERIENZA' && brand?.code === 'VDR' ? 
			<VipIcon /> : 
			'€'
			}
			{product.type === 'COURSE' && hasAdminRole && (
				<PriceModifyButton isEditing={isEditing}>
					{!isEditing && <ModifyIcon onClick={clickEditingButtonHandler} />}
					{isEditing && <CheckCircleIcon onClick={clickConfirmModifyButtonHandler} />}
				</PriceModifyButton>
			)}
		</PriceBox>
	);
};

export default Price;
