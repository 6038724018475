import { useEffect, useState } from 'react';

//services

//utils
import { footerElementsCount } from './APLessonsClosedDetailsTable.helper';

//types
import { tableColumnAttr, APLessonsClosedDetailsTableProps, APLessonsClosedDetailsTableItem } from './APLessonsClosedDetailsTable.component.d';

//assets
import { ReactComponent as ChevronLeftIcon } from './assets/chevronLeft.svg';
import { ReactComponent as ChevronRightIcon } from './assets/chevronRight.svg';
import { ReactComponent as DownArrowIcon } from './assets/downArrowIcon.svg';


//style
import {
    FooterNavigationButton,
	FooterElementsCount,
	Table,
	TableBody,
	TableBodyCell,
	TableBodyRow,
	TableFooter,
	TableHead,
	TableHeadCell,
	TableHeadRow,
	CloseSuccessButton,
	CloseFailureButton,
} from './style/APLessonsClosedDetailsTable.style';

const APLessonsClosedDetailsTableColumns: tableColumnAttr[] = [
	{
		name: 'id',
		title: 'Id Lez.',
		align: 'center',
	},
	{
        name: 'assistant',
    	title: 'Assistente Personale',
		display: 'hidden',
    },
    {
        name: 'customer',
        title: 'Cliente',
        align: 'center',
    },
	{
        name: 'giftdate',
        title: 'Data Acquisto / Regalo',
        align: 'center',
    },
	{
		name: 'rating',
		title: 'Rating',
		align: 'center',
	}
];

type sortParamsType = {
	column: string | null;
	direction: 'asc' | 'desc' | null;
};

function isStringDate(inputString: string): boolean {
	let hasTwoHyphens: boolean   = false; 
	let hasTwoColons: boolean    = false;
	let hasLetterT: boolean      = false;

	for(let i = 0; i < inputString.length; i++) {
		if (inputString[i] === '-') {
			hasTwoHyphens = true;
		}
		if (inputString[i] === ':') {
			hasTwoColons = true;
		}
		if (inputString[i] === 'T') {
			hasLetterT = true;
		}
	}

	// If all three conditions are met, return true
	if (hasTwoHyphens && hasTwoColons && hasLetterT) {
		return true;
	} 
	return false;
}

const isValidDate = (value: string): boolean => {
	if (!value) return false;
	if (!isStringDate(value)) return false;
	const dateObj = new Date(value);
	return !Number.isNaN(dateObj.getTime());
}

const convertDate = (value: string): string => {
	const utcDate = new Date(value);
	const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000);
	  return localDate.toLocaleString('IT-it');
}

const APLessonsClosedDetailsTable: React.FC<APLessonsClosedDetailsTableProps> = ({ data, totalPages, totalElements, pageSize, searchAssistants }) => {
	const [tableData, setTableData] = useState<APLessonsClosedDetailsTableItem[]>([]);
	const [sortParams, setSortParams] = useState<sortParamsType>({column: null, direction: null});
	const [currentPage, setCurrentPage] = useState(1);

	const pageChangeHandler = (page: number) => {
		setCurrentPage(page);
	};

	const sortTable = (items: APLessonsClosedDetailsTableItem[], sortBy: string | null, direction: 'asc' | 'desc' | null) => {
		if (sortBy === null) return items;
		const sortedItems = [...items].sort((a, b) => {
			if (a[sortBy] < b[sortBy]) {
			  return -1;
			}
			if (a[sortBy] > b[sortBy]) {
			  return 1;
			}
			return 0;
		});
		
		if (direction === 'desc') {
			return sortedItems.reverse();
		}
		
		return sortedItems;
	};

	const handleSort = (key: string) => {
		if (key === sortParams.column) {
			setSortParams({column: key, direction: sortParams.direction === 'asc' ? 'desc' : 'asc'});
		} else {
			setSortParams({column: key, direction: 'desc'});
		}
	};

	const handleCloseSuccess = (id: number) => {
		// eslint-disable-next-line no-restricted-globals
		if (confirm('Vuoi veramente chiudere con SUCCESSO il percorso #' + id + ' ???')) {
			
		}
	}

	const handleCloseFailure = (id: number) => {
		// eslint-disable-next-line no-restricted-globals
		if (confirm('Vuoi veramente chiudere con FALLIMENTO il percorso #' + id + ' ???')) {
			
		}
	}

	useEffect(() => {
		console.log('tableData', tableData);
	}, [tableData]);

	useEffect(() => {
		searchAssistants(currentPage);
	}, [currentPage]);

	useEffect(() => {
		const sortedTable = sortTable(tableData, sortParams.column, sortParams.direction);
		setTableData(sortedTable);
	}, [sortParams]);

	useEffect(() => {
		const sortedTable = sortTable(data, sortParams.column, sortParams.direction);
		setTableData(sortedTable);
	}, [data]);

    let columns: string[] = [];
	if (tableData.length > 0) columns = Object.keys(tableData[0]);

	return (
		<>
			<Table>
				<TableHead>
					<TableHeadRow>
						{columns.map((c) => (
							<>
							{!(APLessonsClosedDetailsTableColumns.find(x => x?.name === c)?.display && APLessonsClosedDetailsTableColumns.find(x => x?.name === c)?.display === 'hidden') &&
							<TableHeadCell
								key={c}
								onClick={() => handleSort(c)}
							>
								{APLessonsClosedDetailsTableColumns.find(x => x?.name === c)?.title ? APLessonsClosedDetailsTableColumns.find(x => x?.name === c)?.title?.toUpperCase() : c.toUpperCase()}
								{(sortParams.column === c && (sortParams.direction === 'desc' ? <DownArrowIcon transform="rotate(180 0 0)"/> : <DownArrowIcon />))}
							</TableHeadCell>
							}
							</>
						))}
                        {/* <TableHeadCell
                            key={"dettaglio"}>
                            
                        </TableHeadCell> */}
					</TableHeadRow>
				</TableHead>
				<TableBody>
					{tableData.map((row, index) => (
						<TableBodyRow key={index}>
							{columns.map((c) => {
								const value = row[c as keyof APLessonsClosedDetailsTableItem];
								return (
									<>
									{!(APLessonsClosedDetailsTableColumns.find(x => x?.name === c)?.display && APLessonsClosedDetailsTableColumns.find(x => x?.name === c)?.display === 'hidden') &&
									<TableBodyCell
                                        align={APLessonsClosedDetailsTableColumns.find(x => x?.name === c)?.align}
										key={c}
										id={c}
									>
										{/* {(Object.prototype.toString.call(value) === '[object Date]') ? value.toLocaleString("it-IT") : value} */}
										{(isValidDate(value)) ? convertDate(value) : value}
									</TableBodyCell>
									}
									</>
								);
							})}
                            {/* <TableBodyCell 
                                align={"center"}
                            >
                                <CloseSuccessButton onClick={()=>handleCloseSuccess(row['highwayid'])}>Chiudi Successo</CloseSuccessButton>
								<CloseFailureButton onClick={()=>handleCloseFailure(row['highwayid'])}>Chiudi Fallimento</CloseFailureButton>
                            </TableBodyCell> */}
						</TableBodyRow>
					))}
				</TableBody>
			</Table>
			{totalElements > 0 && (
				<TableFooter>
					<FooterElementsCount>{footerElementsCount({ currentPage, totalPages, totalElements, pageSize })}</FooterElementsCount>
					<FooterNavigationButton
						onClick={() => pageChangeHandler(currentPage - 1)}
						disabled={currentPage === 1}
					>
						<ChevronLeftIcon />
					</FooterNavigationButton>
					<FooterNavigationButton
						onClick={() => pageChangeHandler(currentPage + 1)}
						disabled={currentPage === totalPages}
					>
						<ChevronRightIcon />
					</FooterNavigationButton>
				</TableFooter>
			)}
		</>
	);
};

export default APLessonsClosedDetailsTable;