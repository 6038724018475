import { FC, useCallback, useEffect, useState } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { saveAllProducts } from 'redux/actions/actions-global';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';

//components
import DashboardTitleSection from 'components/DashboardTitleSection';
import ProductBox from './ProductBox/ProductBox.component';

//utils
import update from 'immutability-helper';
import { putProductsWeights } from './services/shopPage.services';
import { getProducts } from 'services/globalServices';

//types
import { ShopPageProps } from './ShopPage.component.d';
import { TitleBreadCrumbSettings } from 'components/DashboardTitleSection/DashboardTitleSection.component.d';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { Product } from 'types/Product';
import { ProductSalesData } from 'types/ProductSalesData';

//style
import { Page, PageContent, SaveButton, ShopProductsBox } from './style/ShopPage.component.style';
import { BaseContentCard } from 'style/wrappers/wrappers';

import { getBrand } from 'configs/brandMap/brandMap.helper';

const weightStep = 500;

const ShopPage: FC<ShopPageProps> = ({}) => {
	const [remoteProducts, setRemoteProducts] = useState<Product[]>([]);
	const [products, setProducts] = useState<Product[]>([]);
	const [isSaveEnabled, setIsSaveEnabled] = useState(false);

	const dispatch = useAppDispatch();

	const getRemoteProducts = async () => {
		const brand = getBrand();
		if (!brand) return;
		try {
			const types = ['COURSE', 'SERVICE'];
			const response = await getProducts(brand.code, types);
			setRemoteProducts(response);
		} catch (error: any) {}
	};

	const saveOrderClickHandler = async () => {
		const snackbarData: SnackbarOptions = {};
		if (!products) return;
		try {
			let productsSalesDatas: Partial<ProductSalesData>[] = [];
			let weight = (products.length - 1) * weightStep;
			for (let i = 0; i < products.length; i++) {
				productsSalesDatas.push({ productId: products[i].id, weight });
				weight = weight - weightStep;
			}
			await putProductsWeights(productsSalesDatas);
			snackbarData.type = 'success';
			snackbarData.message = `Ordinamento salvato con successo`;
			dispatch(addSnackbar(snackbarData));
			setIsSaveEnabled(false);
			getRemoteProducts();
		} catch (error) {
			snackbarData.type = 'error';
			snackbarData.message = "Errore nel salvataggio dell'ordinamento";
			dispatch(addSnackbar(snackbarData));
		}
	};

	useEffect(() => {
		getRemoteProducts();
	}, []);

	useEffect(() => {
		if (!remoteProducts) return;
		setProducts(
			remoteProducts
				.filter((p) => p.visible)
				.sort((a, b) => {
					if (a.salesData?.weight < b.salesData?.weight) return 1;
					if (a.salesData?.weight > b.salesData?.weight) return -1;
					return 0;
				})
		);
	}, [remoteProducts]);

	useEffect(() => {
		setIsSaveEnabled(JSON.stringify(remoteProducts.filter((p) => p.visible)) !== JSON.stringify(products));
	}, [products, remoteProducts]);

	const moveProduct = useCallback((dragIndex: number, hoverIndex: number) => {
		setProducts((prevState) =>
			update(prevState, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, prevState[dragIndex] as Product],
				],
			})
		);
	}, []);

	const renderProduct = useCallback((p: Product, i: number) => {
		return (
			<ProductBox
				key={p.id}
				index={i}
				product={p}
				moveProduct={moveProduct}
			/>
		);
	}, []);

	const breadCrumbSettings: TitleBreadCrumbSettings = { subSectionText: 'Negozio' };

	return (
		<Page>
			<DashboardTitleSection breadCrumbSettings={breadCrumbSettings}></DashboardTitleSection>
			<BaseContentCard>
				<PageContent>
					<SaveButton
						disabled={!isSaveEnabled}
						onClick={saveOrderClickHandler}
					>
						Salva Ordinamento
					</SaveButton>
					<ShopProductsBox>{products?.map((p, i) => renderProduct(p, i))}</ShopProductsBox>
				</PageContent>
			</BaseContentCard>
		</Page>
	);
};

export default ShopPage;
