import styled from 'styled-components';

import colors from 'style/palette';

export const ContentContainerBox = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 100%;
	align-items: center;
	/* background-color: ${colors.black050}; */
	padding: 2rem;
`;

export const ContentNodesBox = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	width: 100%;
	align-items: flex-end;
`;

export const ButtonRow = styled.div`

	display: flex;
	align-items: center;
	justify-content: center;

	gap: 30px;
`;

export const SaveButton = styled.button``;

export const AddNode = styled.button`
	/* background-color: transparent; */
	display: flex;
	align-items: center;
	> svg {
		width: 30px;
		height: 30px;
		> path {
			fill: ${colors.green};
		}
	}
`;
