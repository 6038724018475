import { callGET } from 'network/network';

import { usersSearchParams } from './UsersPage.services.d';

const _USERS_SEARCH_PATH = 'v1/users/search';

export const getSearchUsers = async (usersSearchParams: usersSearchParams) => {
	try {
		const response = await callGET(_USERS_SEARCH_PATH, true, usersSearchParams);

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};
