import styled, { css } from 'styled-components';

export const GenericInfoTable = styled.table`
	font-size: 0.8rem;
`;

export const GenericInfoTableBody = styled.tbody``;

export const GenericInfoTableRow = styled.tr``;

export const GenericInfoTableCellTitle = styled.td`
	text-align: right;
`;

export const GenericInfoTableCellData = styled.td<{ copyable?: boolean }>`
	text-align: left;
	${({ copyable }) =>
		copyable &&
		css`
			cursor: copy;
		`}
`;
