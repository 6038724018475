import styled from 'styled-components';

// types
import { BrandBoxProps } from './Header.style.d';

// style
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';


export const Container = styled.div<{isTest?: boolean}>`

    width: 100%;
    height: 69px;

    padding: 0 50px;

    border-bottom: 0.5px solid ${colors.veryLightGrey};
    background: ${({isTest})=> isTest ? colors.red : colors.veryDarkGrey};

    display: none;
    position: fixed;
    z-index: 20;

    @media ${devices.tablet} {

        display: flex;
    }
`;

export const Content = styled.div`

    width: 100%;
    min-height: inherit;

    background: inherit;

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 10px;

    position: relative;
`;

export const BrandBox = styled.div<BrandBoxProps>`

    width: 100%;

    display: ${({ isNavbarVisible }) => isNavbarVisible ? "none" : "flex"};
    align-items: center;
    justify-content: flex-start;

    flex: 1;

    a {

        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    a > svg {

        width: 91.80px;
        height: 45px;
    }

    @media ${devices.desktop} {

        display: flex;
    }
`;

export const SignupText = styled.div`

    width: 100%;

    text-align: center;
    flex: 2;

    > a {

        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
        text-decoration: none;

        color: ${colors.primaryColor};
    }
`;

export const LoginArea = styled.div`

    width: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    gap: 18px;

    flex: 1;
`;

export const SupportBox = styled.a`

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 10px;

    margin: 0 15px 0 0;

    font-size: 15px;
    font-weight: 500;
    
    color: ${colors.white};
    text-decoration: none;
`;

export const NotifyBotton = styled.button`

    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0;
    padding: 0;

    font-size: 15px;
    font-weight: 500;
    
    background: ${colors.transparent};
    color: ${colors.white};
    border: none;
    text-decoration: none;

    position: relative;
    cursor: pointer;
`;

export const NewNotificationAlert = styled.div`

    width: 20px;
    height: 20px;

    border-radius: 50%;
    background: ${colors.primaryColor};

    position: absolute;

    top: -5px;
    left: 15px;
`;

export const LoginBox = styled.div`

    > a {
        
        display: flex;
        align-items: center;
        justify-content: center;
    
        gap: 12px;
    
        font-size: 15px;
        font-weight: 500;
        text-transform: uppercase;
        text-decoration: none;
        
        color: ${colors.white};
    }
`;

export const UserArea = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-end;

    flex: 1;

    svg {

        width: 24.80px;
        height: 29.17px;

        margin: 0 0 0 20.33px;
    }

    svg:first-child {

        margin: 0;
    }
`;

export const UserData = styled.div`

    margin: 0 0 0 35.33px;

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 16px;

    cursor: pointer;
`;

export const UserProfileImage = styled.img`

    min-width: 40px;
    width: 40px;
    max-width: 40px;

    min-height: 40px;
    height: 40px;
    max-height: 40px;

    border-radius: 50%;
    border: none;
    background: ${colors.transparent};
`;

export const UserInfo = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;

    font-size: 14px;
    font-weight: 500;

    color: ${colors.white056};

    white-space: nowrap;
`;

export const UserInfoTitle = styled.h3`

    margin: 0 0 3px;
    padding: 0;

    font-size: 15px;
    font-weight: 500;

    color: ${colors.white};
`;
