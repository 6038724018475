// routing
import { Outlet } from 'react-router-dom';

// hooks
import useCheckUserLogin from 'hooks/useCheckUserLogin';


const ReservedArea = () => {

    useCheckUserLogin();

    return (
        <>
            <Outlet />
        </>
    )
};

export default ReservedArea;