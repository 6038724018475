import { FC, useEffect, useState } from 'react';

//hooks
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';
import useHasRole from 'hooks/useHasRole';
import useHasPermission from 'hooks/useHasPermission';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

//utils
import { getCoaches, getSearchWhatsapp, assignLessonToCoach, reassignLessonToAP, closeWhatsapp } from './services/WhatsappPage.services';
import { getBrand } from 'configs/brandMap/brandMap.helper';

//types
import { TitleBreadCrumbSettings } from 'components/DashboardTitleSection/DashboardTitleSection.component.d';
import { TabSelectorSettings, TabSelectorItem } from 'components/TabSelector/TabSelector.component.d';
import { WhatsappNewTableItem } from './WhatsappNewTable/WhatsappNewTable.component.d';
import { WhatsappOpenTableItem } from './WhatsappOpenTable/WhatsappOpenTable.component.d';
import { WhatsappClosedTableItem } from './WhatsappClosedTable/WhatsappClosedTable.component.d';
import { PersonalAssistant } from './WhatsappPage.d';
import { WhatsappOpenDetailsTableItem } from './WhatsappOpenDetailsTable/WhatsappOpenDetailsTable.component.d';
import { User } from 'types/User.d';


//components
import DashboardTitleSection from 'components/DashboardTitleSection';
import TabSelector from 'components/TabSelector/TabSelector.component';
import WhatsappNewTable from './WhatsappNewTable/WhatsappNewTable.component';
import WhatsappOpenTable from './WhatsappOpenTable/WhatsappOpenTable.component';
import WhatsappOpenDetailsTable from './WhatsappOpenDetailsTable/WhatsappOpenDetailsTable.component';
import WhatsappClosedTable from './WhatsappClosedTable/WhatsappClosedTable.component';


//assets
import { ReactComponent as SearchIconSVG } from './assets/searchIcon.svg';
import { ReactComponent as ClearButtonIconSVG } from './assets/clearButtonIcon.svg';
import CloseWhatsappModal from './WhatsappOpenDetailsTable/CloseWhatsappModal';


//style
import { BaseContentCard } from 'style/wrappers/wrappers';
import {
    Page,
    SearchChannelBox,
    SearchInput,
    SearchClearButton,
    SearchButton,
    AssignBox,
    AssignSelect,
    AssignButton,
    TableDetailsHeader,
    TableDetailsName,
    TableDetailsBackButton,
} from './style/WhatsappPage.style';


const WhatsappPage: FC<{}> = () => {
	const [searchInput, setSearchInput] = useState<any>('');
    const [searchNewResults, setSearchNewResults] = useState<WhatsappNewTableItem[]>([]);
    const [searchOpenResults, setSearchOpenResults] = useState<WhatsappOpenTableItem[]>([]);
    const [originalOpenResults, setOriginalOpenResults] = useState<any>([]);
    const [searchClosedResults, setSearchClosedResults] = useState<WhatsappClosedTableItem[]>([]);
    const [openDetailsResults, setOpenDetailsResults] = useState<WhatsappOpenDetailsTableItem[]>([]);
    const [hiddenOpenDetails, setHiddenOpenDetails] = useState<number[]>([]);
    const [originalClosedResults, setOriginalClosedResults] = useState<any>([]);
    const [totalPages, setTotalPages] = useState<number>(0);
	const [totalElements, setTotalElements] = useState<number>(0);
	const [pageSize, setPageSize] = useState<number>(0);
    const [coaches, setCoaches] = useState<any[]>([]);
    const [coachDetails, setCoachDetails] = useState<number | null>(null);
    const [personalAssistants, setPersonalAssistants] = useState<any[]>([]);
    const [tabSelectorValue, setTabSelectorValue] = useState<string>('');
    const [assignAssistantSelect, setAssignAssistantSelect] = useState<number>(0);
    const [selectedIds, setSelectedIds] = useState<number[]>([]);
	const [isCloseWhatsappModalVisible, setIsCloseWhatsappModalVisible] = useState<boolean>(false);
    const [activeWhatsappForEditing, setActiveWhatsappForEditing] = useState<number | null>(null);
    const [successWhatsappForEditing, setSuccessWhatsappForEditing] = useState<boolean>(false);

    const isAdmin = useHasRole('ADMIN');
    const isSuperAdmin = useHasRole('SUPERADMIN');
	const isSupervisoreAutostrada = useHasRole('SUPERVISORE_AUTOSTRADA');
	const isAdminAutostrada = useHasRole('ADMIN_AUTOSTRADA');

	interface CoachData {
		id: string;
		name: string;
		totalLessons: number;
		totalRatedLessons: number;
		averageRating: number;
        totalPacks: number;
        totalSuccess: number;
        totalFailure: number;
	}

	const dispatch = useAppDispatch();

    const clearSearchHandler = () => {
		setSearchInput('');
	};

	const searchChangeHandler = (searchValue: any) => {
		setSearchInput(searchValue.target.value);
	};

	const searchKeyHandler = (event: any) => {
		if (event.keyCode === 27) {
			clearSearchHandler();
		}
	};

	const openCloseWhatsappModal = (idWhatsapp: number, success: boolean) => {
		setActiveWhatsappForEditing(idWhatsapp);
        setSuccessWhatsappForEditing(success);
		setIsCloseWhatsappModalVisible(true);
	}

	const closeCloseWhatsappModal = () => {
		setIsCloseWhatsappModalVisible(false);
        setActiveWhatsappForEditing(null);
	}

	const saveCloseWhatsappModal = (notes: string) => {
		if (activeWhatsappForEditing) closeSubscription(activeWhatsappForEditing, successWhatsappForEditing, notes);
	}

    const getAllCoaches = async () => {
		var coaches = [];
		const response = await getCoaches();
		if (response) {
			coaches = response.filter((coach: any) => coach.deleted === false);
		}
		setCoaches(coaches);
	}
	
	function getCoachData(closedResults: any): CoachData[] {
		const coachMap = new Map<string, CoachData>();
		
		closedResults.forEach((result: any) => {
			const coach = result.productLessonPack.coach;
			const coachId = coach.userId;
			const lessonCount = result.productLessonPack.lessons.length;
		
			if (!coachMap.has(coachId)) {
				coachMap.set(coachId, {
					id: coachId,
					name: coach.name,
					totalLessons: 0,
					totalRatedLessons: 0,
					averageRating: 0,
                    totalPacks: 0,
                    totalSuccess: 0,
                    totalFailure: 0,
				});
			}
		
			const coachData = coachMap.get(coachId);
			if (coachData) {
                coachData.totalLessons += lessonCount;
                coachData.totalPacks += 1; 
                if (result.productLessonPack.status === 'CLOSED_SUCCESS') {
                    coachData.totalSuccess += 1;
                }
                if (result.productLessonPack.status === 'CLOSED_FAILURE') {
                    coachData.totalFailure += 1;
                }
            }
		
			result.productLessonPack.lessons.forEach((lesson: any) => {
			if ((lesson.rating !== null)&&(coachData)&&(coachData.averageRating !== null)) {
				coachData.totalRatedLessons += 1;
				coachData.averageRating += lesson.rating;
			}
			});
		});
		
		return Array.from(coachMap.values()).map((coachData) => ({
			...coachData,
			averageRating: (coachData.totalRatedLessons>0) ? (coachData.averageRating / coachData.totalRatedLessons) : 0,
		}));
	}

	function extractLessonsByCoachIdAndUserId(
		results: any[],
		coachId: number
	  ): any[] {
		const lessons: any[] = [];
	  
		results.forEach((result) => {
			if (result.productLessonPack.coach.userId === coachId) {
                var closedLessons = 0;
                var openLessons = 0;
                result.productLessonPack.lessons.forEach((lesson: any) => {
                    if (lesson.type === 5) {
                        if (lesson.status === 'CLOSED') {
                            closedLessons += 1;
                        } else {
                            openLessons += 1;
                        }
                    }
			    });
                lessons.push({ packid: result.productLessonPack.productSubscriptionId, lessons: closedLessons, openLessons: openLessons, user: result.user, creationDate: result.creationDate, assistant: result.productLessonPack.coach.userId+" | "+result.productLessonPack.coach.name, coachNotes: result.productLessonPack.lessons[0].coachNotes });
			}
		  });
	  
		return lessons;
	  }

    const searchNewCalls = async (page: number) => {
        const brand = getBrand();
        if (!brand) return;
        var lessons = [];
		const response = await getSearchWhatsapp({ q: searchInput, assigned: false, types: [3], brandCode: brand.code });
		if (response) {
			lessons = response;
		}
		setSearchNewResults(
			lessons.map((lesson: any ) => ({ id: lesson.productLessonPack.productSubscriptionId, customer: lesson.user.id+" | "+lesson.user.name+" "+lesson.user.surname, purchaseDate: lesson.creationDate }))
		);

		getAllCoaches();

		setTotalElements(response.totalElements);
		setTotalPages(response.totalPages);
		setPageSize(response.size);    
    };

    const searchOpenCalls = async (page: number) => {
        const brand = getBrand();
        if (!brand) return;
        var lessons = [];
		const response = await getSearchWhatsapp({ assigned: true, statuses: ["OPENED"], types: [5], brandCode: brand.code });
		if (response) {
			lessons = response; 
		}

		const openTable = getCoachData(lessons);

        setOriginalOpenResults(lessons);
		setSearchOpenResults(
			openTable.map((assistant: any ) => ({ id: assistant.id, assistant: assistant.name, opencalls: assistant.totalPacks }))
		);

		setTotalElements(response.totalElements);
		setTotalPages(response.totalPages);
		setPageSize(response.size);
    };

    const searchAssistantDetails = async (assistantId: number) => {
        if (assistantId !== 0) setCoachDetails(assistantId);
        await searchOpenCalls(0);
    }

    const handleCloseDetails = () => {
        setCoachDetails(null);
        setHiddenOpenDetails([]);
		setOpenDetailsResults([]);
    }

    const searchClosedCalls = async (page: number) => {
        const brand = getBrand();
        if (!brand) return;
        var lessons = [];
		const response = await getSearchWhatsapp({ assigned: true, statuses: ['CLOSED_SUCCESS', 'CLOSED_FAILURE', 'CLOSED_EXPIRED'], types: [5], brandCode: brand.code });
		if (response) {
			lessons = response;
		}

		setOriginalClosedResults(lessons);

		const closedTable = getCoachData(lessons);
		setSearchClosedResults(
			closedTable.map((assistant: any ) => ({ id: assistant.id, assistant: assistant.name, closedcalls: assistant.totalSuccess, failed: assistant.totalFailure }))
		);

		setTotalElements(response.totalElements);
		setTotalPages(response.totalPages);
		setPageSize(response.size);
    };

    const handleAssignAssistantSelectChange = (value: string) => {
        setAssignAssistantSelect(Number(value)); 
    };

    const getCheckedIds = (checkboxes: number[]) => {
        setSelectedIds(checkboxes);
    }

    const assignLessonTo = async () => {
        // eslint-disable-next-line no-restricted-globals
		if (confirm("Vuoi veramente riassegnare la call?")) {
            try {
                await selectedIds.forEach(async (id) => {
                    await reassignLessonToAP(id, assignAssistantSelect);
                });
                const snackbarData: SnackbarOptions = {};
                snackbarData.type = 'success';
                snackbarData.message = `Call assegnata al nuovo Assistente Personale!`;
                dispatch(addSnackbar(snackbarData));
                setTimeout(() => {
                    getAllCoaches();
                    searchAssistantDetails(0);
                }, 2000);
            } catch (error: any) {
                const snackbarData: SnackbarOptions = {};
                snackbarData.type = 'error';
                snackbarData.message = `C'è stato qualche problema nel riassegnare le call!`;
                dispatch(addSnackbar(snackbarData));
                throw error;
            }
        }
    };

    const closeSubscription = async (id: number, success: boolean, notes: string) => {
			try {
				await closeWhatsapp(id, success, notes);
                await searchOpenCalls(0);
                setHiddenOpenDetails([...hiddenOpenDetails, id]);
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'success';
				snackbarData.message = `Percorso chiuso correttamente!`;
				dispatch(addSnackbar(snackbarData));
                // if (coachDetails) {
                //     setTimeout(async () => {
                //         setHiddenOpenDetails([]);
                //         searchAssistantDetails(coachDetails);
                //     }, 2000);
                // }
			} catch (error: any) {
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'error';
				snackbarData.message = `C'è stato qualche problema nella chiusura del percorso!`;
				dispatch(addSnackbar(snackbarData));
				throw error;
			}
    }

    useEffect(() => {
		const prepareAssistantsList = async () => {
			if (originalOpenResults.length === 0) 
				{
					await searchOpenCalls(0);
					return;
				}
			if (coaches.length === 0) return;
			if (searchOpenResults.length === 0) return;
			var assistants: any[] = [];
			if (coaches) {
				assistants = coaches.filter((item: any) => {
					if (item.user.roles) {
						return item.user.roles.some((role: any) => role.role === 'ASSISTENTE-PERSONALE');
					}
					return false;
				});
			} 
			const assistantsWithLessonCount = assistants.map((assistant) => {
				var assistantCopy = {...assistant}; 
				if (searchOpenResults.length>0) {
					assistantCopy.opencalls = originalOpenResults.reduce((count: number, lesson: any) => {
					if (lesson.productLessonPack && lesson.productLessonPack?.coach?.userId === assistant.userId) {
						return count + 1;
					}
					return count;
					}, 0);
				} else {
					assistantCopy.opencalls = 0;
				}
				return assistantCopy;
			  });
			  
			setPersonalAssistants(assistantsWithLessonCount.sort((a: any, b: any) => {
				return a.user.surname.localeCompare(b.user.surname);
			}));
		}
		prepareAssistantsList();
	}, [coaches, originalOpenResults]);

	// useEffect(() => {
	// 	if (!personalAssistants) return;
	// 	if (!searchNewResults) return;

	// }, [personalAssistants, searchNewResults]);

    useEffect(() => {
        if (!coachDetails) return;
        const detailsTable = extractLessonsByCoachIdAndUserId(originalOpenResults, coachDetails);
        setOpenDetailsResults(
			detailsTable.map((lesson: any ) => ({ packid: lesson.packid, customer: lesson.user.id+" | "+lesson.user.name+" "+lesson.user.surname, assistant: lesson.assistant, paymentdate: lesson.creationDate, lessons: lesson.lessons, openlessons: lesson.openLessons, coachNotes: lesson.coachNotes }))
        );

        getAllCoaches();

        // setTotalElements(response.totalElements);
        // setTotalPages(response.totalPages);
        // setPageSize(response.size);
    }, [coachDetails, originalOpenResults]);

    const breadCrumbSettings: TitleBreadCrumbSettings = { subSectionText: 'Gestione Call Whatsapp' };
    const tabItems: TabSelectorItem[] = [
        // {
        //     title: "Da assegnare",
        //     value: "tobeassigned",
        // },
        {
            title: "In corso",
            value: "open",
        },
        {
            title: "Concluse",
            value: "closed"
        }
    ];
    const tabSettings: TabSelectorSettings = { tabItems: tabItems, handleSelection: setTabSelectorValue, display: openDetailsResults.length === 0 ? true : false};


    const assignBox =  (<AssignBox>
                        <AssignSelect disabled={(personalAssistants.length === 0)} value={assignAssistantSelect} onChange={(event) => handleAssignAssistantSelectChange(event.target.value)}>
                            <option value={0}>Scegli un assistente</option>
                            {personalAssistants.map((a) => {
                                return (
                                    <option value={a.userId}>
                                        {a.name} {a.surname} ({a.opencalls ? a.opencalls : 0})
                                    </option>
                                )
                            })}
                        </AssignSelect>

                        <AssignButton
                            onClick={() => assignLessonTo()}
                            disabled={(assignAssistantSelect === 0) || (selectedIds.length === 0)}
                        >
                        Assegna
                        </AssignButton>
                    </AssignBox>);

    if (!isSuperAdmin && !isAdmin && !isSupervisoreAutostrada && !isAdminAutostrada) return (<></>);

    return(
        <>
        <Page>
            <DashboardTitleSection breadCrumbSettings={breadCrumbSettings}>
                { false && <SearchChannelBox>
                    <SearchInput
                        onKeyDown={searchKeyHandler}
                        onChange={searchChangeHandler}
                        value={searchInput}
                    />

                    {searchInput !== '' && (
                        <SearchClearButton onClick={clearSearchHandler}>
                            <ClearButtonIconSVG />
                        </SearchClearButton>
                    )}

                    <SearchButton>
                        <SearchIconSVG />
                    </SearchButton>
                </SearchChannelBox>
                }
            </DashboardTitleSection>

            <TabSelector tabSelectorSettings={tabSettings}>
            </TabSelector>


            { tabSelectorValue === "open" && openDetailsResults.length > 0 &&
                <TableDetailsHeader>
                    <TableDetailsName>{openDetailsResults[0].assistant}: in corso</TableDetailsName>

                    <TableDetailsBackButton onClick={() => handleCloseDetails()}>Chiudi dettagli</TableDetailsBackButton>
                </TableDetailsHeader>
            }

            <BaseContentCard>
                { tabSelectorValue === "tobeassigned" &&
                    <>
                   {assignBox}

                    <WhatsappNewTable
                        data={searchNewResults}
                        totalElements={totalElements}
                        totalPages={totalPages}
                        pageSize={pageSize}
                        searchCalls={searchNewCalls}
                        getCheckedIds={getCheckedIds}
                    />

                    {assignBox}
                    </>
                }
                { tabSelectorValue === "open" && openDetailsResults.length === 0 &&
                    <>
                    <WhatsappOpenTable
                        data={searchOpenResults}
                        totalElements={totalElements}
                        totalPages={totalPages}
                        pageSize={pageSize}
                        searchAssistants={searchOpenCalls}
                        searchAssistantDetails={searchAssistantDetails}
                    />
                    </>
                }
                { tabSelectorValue === "open" && openDetailsResults.length > 0 &&
                    <>
                    {assignBox}

                    <WhatsappOpenDetailsTable
                        data={openDetailsResults}
                        totalElements={totalElements}
                        totalPages={totalPages}
                        pageSize={pageSize}
                        searchAssistants={searchOpenCalls}
                        closeWhatsapp={openCloseWhatsappModal}
                        closeCloseWhatsappModal={closeCloseWhatsappModal}
                        saveCloseWhatsappModal={saveCloseWhatsappModal}
                        hiddenWhatsapp={hiddenOpenDetails}
                        getCheckedIds={getCheckedIds}
                    />

                    {assignBox}
                    </>
                }
                { tabSelectorValue === "closed" &&
                    <>
                    <WhatsappClosedTable
                        data={searchClosedResults}
                        totalElements={totalElements}
                        totalPages={totalPages}
                        pageSize={pageSize}
                        searchAssistants={searchClosedCalls}
                    />
                    </>
                }
            </BaseContentCard>
        </Page>
        {isCloseWhatsappModalVisible && (
            <CloseWhatsappModal
                success={successWhatsappForEditing}
                onCloseButtonModal={closeCloseWhatsappModal}
                onSaveButtonModal={saveCloseWhatsappModal}
            />
        )}
        </>
    );

}

export default WhatsappPage;
