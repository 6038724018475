import { FC, useEffect, useState } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';

//types
import { NotesModalProps } from './NotesModal.component.d';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

//components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';

//utils
import { putUserNotes } from '../services/Notes.services';

//style
import { CardContent } from './style/NotesModal.component.style';
import { enableAppMain } from 'redux/slices/slice-app-main';

const NotesModal: FC<NotesModalProps> = ({ onCloseButtonModal, notes, userId, searchActiveInactive }) => {
	const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);
	const [localNotes, setLocalNotes] = useState('');
	const [isSaveEnabled, setIsSaveEnabled] = useState(false);

	const dispatch = useAppDispatch();

	const closeButtonHandler = () => {
		if (onCloseButtonModal) onCloseButtonModal();
	};

	const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setLocalNotes(event.currentTarget.value);
	};

	useEffect(() => {
		setIsSaveEnabled(localNotes !== notes);
	}, [localNotes, notes]);
	const onSaveHandler = async () => {
		try {
			await putUserNotes(userId, localNotes);
			const snackbarData: SnackbarOptions = {};
			snackbarData.type = 'success';
			snackbarData.message = `Note utente salvate correttamente!`;
			dispatch(addSnackbar(snackbarData));
			searchActiveInactive();
			if (onCloseButtonModal) onCloseButtonModal();
			dispatch(enableAppMain());
		} catch (error: any) {
			const snackbarData: SnackbarOptions = {};
			snackbarData.type = 'error';
			snackbarData.message = `C'è stato un problema nel salvataggio note utente!`;
			dispatch(addSnackbar(snackbarData));
			throw error;
		}
	};

	useEffect(() => {
		setLocalNotes(notes);
	}, [notes]);

	return (
		<BaseModal
			isBackdropClickActive={true}
			onClose={closeButtonHandler}
			isCloseButtonClicked={isCloseButtonClicked}
		>
			<BaseCard
				flavor='outlined'
				maxWidth='700px'
			>
				<CardContent>
					<textarea
						rows={4}
						cols={75}
						value={localNotes}
						onChange={onChangeHandler}
					/>
					<button
						disabled={!isSaveEnabled}
						onClick={onSaveHandler}
					>
						SALVA MODIFICHE
					</button>
				</CardContent>
			</BaseCard>
		</BaseModal>
	);
};

export default NotesModal;
