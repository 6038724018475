import { FC } from 'react';

//types
import { ButtonsProps } from './Buttons.component.d';

//components
import Button from './Button/Button.component';

//hooks
import { useNavigate } from 'react-router-dom';

//assets
import { ReactComponent as ModifyIcon } from './assets/modifyIcon.svg';

//style
import { ButtonsBox } from './style/Buttons.component.style';

const Buttons: FC<ButtonsProps> = ({ userId }) => {
	const navigate = useNavigate();

	const modifyButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
			window.open('users/' + userId.toString(), '_blank');
	};

	return (
		<ButtonsBox>
			<Button
				name='modify'
				ButtonIcon={ModifyIcon}
				clickHandler={modifyButtonHandler}
			></Button>
		</ButtonsBox>
	);
};

export default Buttons;
