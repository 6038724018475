import { callGET, callPUT } from 'network/network';

import { getBrand } from 'configs/brandMap/brandMap.helper';

const _STANDARD_POOLS_SEARCH_PATH = 'v1/pools/standard';
const _THEMATICBROADCAST_REMOVE_DISCOUNT_PRICES_PATH = 'v1/products/{id}/thematicbroadcast/discountprices/remove';

const brand = getBrand();


export const getStandardPools = async () => {
	try {
		const response = await callGET(_STANDARD_POOLS_SEARCH_PATH, true);

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const removeThematicBroadcastDiscountPrices = async (productId: number) => {
	const brand = getBrand();
	if (!brand) return;
	try {
		const response = await callPUT(_THEMATICBROADCAST_REMOVE_DISCOUNT_PRICES_PATH.replace(
			/{id}/g,
			productId.toString()
		  ), {}, true, { brandCode: brand.code });

		return response;
	} catch (error: any) {
		throw error;
	}
};
