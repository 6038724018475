import { callGET } from 'network/network';

import { activeinactiveSearchParams } from './ActiveInactivePage.services.d';
import { productsSearchParams } from 'pages/ProductsPage/services/ProductsPage.services.d';

const _ACTIVEINACTIVE_SEARCH_PATH = 'v1/productsubscriptions/products/{productId}';
const _PRODUCTS_SELF_PATH = 'v1/self/products';
const _PRODUCTS_PATH = 'v1/products';

export const getSearchActiveInactive = async (productId: number, activeinactiveSearchParams: activeinactiveSearchParams) => {
	try {
		const response = await callGET(_ACTIVEINACTIVE_SEARCH_PATH.replace(
			/{productId}/g,
			productId.toString()
		  ), true, activeinactiveSearchParams);

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const getProducts = async (productsSearchParams: productsSearchParams, self: boolean) => {
	const _PRODUCTS_PATH_REAL = self ? _PRODUCTS_SELF_PATH : _PRODUCTS_PATH;
	try {
		const response = await callGET(_PRODUCTS_PATH_REAL, true, productsSearchParams);

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};