import styled from 'styled-components';

export const DescriptionBox = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    padding: 25px 20px;

    width: 100%;

    background: #4C5862;

    overflow: hidden;

    p {
        word-wrap: break-word;
        width: 100%;
    }

    img {
        max-width: 100%;
    }
`;

export const AttachmentLink = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;

    font-size: 20px;

    overflow: hidden;

    padding: 5px
`;

export const LinkAttachment = styled.a`
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    color: #FFFFFF;
`;