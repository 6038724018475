import styled from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette/palette'; 


export const FooterBar = styled.div`

    width: 100%;
    min-height: 69px;

    border-top: 0.5px solid ${colors.veryLightGrey};
    background: ${colors.veryDarkGrey};

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    gap: 4px;
`;

export const BrandBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;

    > svg {

        flex-shrink: 0;
    }

    /* short logo */
    > svg:first-child {

        display: none;

        @media ${devices.tablet} {

            display: inline;
        }

        @media ${devices.desktop} {

            display: none;
        }
    }

    > svg:nth-child(2) {

        display: none;

        @media ${devices.desktop} {

            display: inline;
        }
    }
`;

export const InfoBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;

    flex-direction: row;

    flex-wrap: wrap;
`;

export const SocietyName = styled.span`

    margin: 0 0 0 10px;

    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
    white-space: nowrap;

    color: ${colors.white};
`;

export const SocietyAddress = styled.span`

    margin: 0 0 0 10px;

    font-size: 13px;
    font-weight: 400;

    color: ${colors.white056};
`;

export const PrivacyAndSocials = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 30px;
`;

export const PrivacyBox = styled.div`

    font-size: 13px;
    font-weight: 400;
    
    color: ${colors.white056};

    display: inline-flex;
    align-items: center;
    justify-content: flex-start;

    column-gap: 0;
    row-gap: 2px;
    flex-direction: row;
    flex-wrap: nowrap;

    > span {

        display: inline-block;
        margin: 0 0 0 15px;

        white-space: nowrap;

        > a {

            color: ${colors.white056};
            text-decoration: none;
        }
    }
`;

export const Socials = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 15px;

    > a {
        max-height: 20px;
    }
`;

export const SeparatorLine = styled.span`

    display: inline-block;
    margin: 0 0 0 15px;
`;
