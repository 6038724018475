import { callPUT } from 'network/network';

import { getBrand } from 'configs/brandMap/brandMap.helper';

const _SAVE_CLOSE_PACK_PATH = 'v1/productlessonpacks/subscriptions/{subscriptionId}/close';


export const saveSubscribtionNotes = async (subscriptionId: number, success: boolean, closingNotes?: string) => {
    const brand = getBrand();
    if (!brand) return;
    try { 
        var params: any = {
            brandCode: brand.code,
            success: success,
        }
        if (closingNotes) {
            params = {...params, closingNotes: closingNotes};
        }

        const response = await callPUT(_SAVE_CLOSE_PACK_PATH.replace(
			/{subscriptionId}/g,
			subscriptionId.toString()
		  ), {}, true, params);

        return response;
    } catch (error: any) {
        throw error;
    }
};