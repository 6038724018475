import styled, { css } from 'styled-components';

import colors from 'style/palette';

export const SubCategoryBox = styled.div<{ enabled?: boolean }>`
	font-size: 0.5rem;
	background-color: ${colors.lightGrey};
	padding: 0.2rem 0.4rem;
	border-radius: 0.2rem;
	text-align: center;
	cursor: pointer;
	${({ enabled }) =>
		enabled &&
		css`
			background-color: ${colors.green};
		`}
`;
