import styled, { css } from 'styled-components';

import colors from 'style/palette/palette';

export const GenericInfoBox = styled.div`
	margin: 1rem;
`;

export const RowBox = styled.div`
	display: flex;
`;

export const FieldBox = styled.div`
	padding: 1rem;
`;

export const InfoLabel = styled.div`
	padding: 0.5rem;
	font-weight: bold;
	color: ${colors.white};
	text-transform: uppercase;
`;

export const InfoContent = styled.div<{ type?: string }>`
	padding: 0.5rem;
	color: ${colors.white050};
	${({ type }) =>
		(type === 'email' || type === 'mobile') &&
		css`
			cursor: copy;
		`}
`;
