import { FC, useEffect, useState } from 'react';

// routing
import { useNavigate } from 'react-router-dom';

//hooks
import { useIsMounted } from 'usehooks-ts';

//types
import { ResultsModalProps } from './ResultsModal.component.d';

//services
import { getHistoricalResults } from './services/ResultsModal.services';

//components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';
import AnnualTrendChart from './AnnualTrendGraph/AnnualTrendGraph.component';

//style
import { 
	CardContent,
	ResultsColumn, 
	ResultsText,
    TotalResult,
    TotalCashResult,
	GraphRow,
    GraphBox,
    GraphCard,
} from './style/ResultsModal.component.style';

const ProductsModal: FC<ResultsModalProps> = ({ onCloseButtonModal, userId, userName }) => {
	const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);
	const [isHistoricalLoading, setIsHistoricalLoading] = useState<boolean>(true);
	const [historicalData, setHistoricalData] = useState<any[]>([]);
    const [prevMonthData, setPrevMonthData] = useState<any>({});
    const [last12MonthsIncoming, setLast12MonthsIncoming] = useState<number>(0);
	const season = 'last 12 months';

    const isMounted = useIsMounted();
	const navigate = useNavigate();

	const closeButtonHandler = () => {
		if (onCloseButtonModal) onCloseButtonModal();
	};

	const formatDate = (when: string): string => {
		const options: Intl.DateTimeFormatOptions = {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: 'numeric',
			minute: 'numeric',
			second: 'numeric',
			hour12: false,
			timeZone: 'Europe/Rome'
		};
		if (!when) return "";
		return new Intl.DateTimeFormat('it-IT', options).format(new Date(when)).split(',')[0];
	}

	const getLast12MonthsIncoming = (incoming: number) => setLast12MonthsIncoming(incoming);

	const historicalResults = async () => {
		setIsHistoricalLoading(true);
		const prevMonth = new Date().getMonth();

		try {
			const historicalData = await getHistoricalResults(userId);
			if (!isMounted) return;

			if (!historicalData || historicalData === undefined) {
				new Error("Historical Data are undefined");
			}

            if (historicalData.length === 0) {
                new Error("No Historical Data.")    
            };
            
            setHistoricalData(historicalData);
            const prevMonthHistoricalData = historicalData.filter((monthData: any) => monthData.month === prevMonth);
            setPrevMonthData(prevMonthHistoricalData[0]);
            setIsHistoricalLoading(false);
        } catch (error: any) {
            if (error.message === '403') navigate('/fallback-page/permission-denied');
            console.warn("Unable to get Historical Results", error);
            setIsHistoricalLoading(false);
        }
    };

	useEffect(() => {
		historicalResults();
	}, [userId]);

	return (
		<BaseModal
			isBackdropClickActive={true}
			onClose={closeButtonHandler}
			isCloseButtonClicked={isCloseButtonClicked}
		>
			<BaseCard
				flavor='outlined'
				maxWidth='1000px'
			>
				<CardContent>
					<ResultsColumn>
						<GraphRow>
							<ResultsText>Storico Risultati di {userName}</ResultsText>

							<GraphBox id='graphBox'>                        
								<GraphCard>
									<AnnualTrendChart data={historicalData} season={season} getResultsFunction={getLast12MonthsIncoming} />
								</GraphCard>

							</GraphBox>

							{(season === 'last 12 months') && <TotalResult>
								Totale degli ultimi 12 mesi: <TotalCashResult>{last12MonthsIncoming.toLocaleString()} €</TotalCashResult>
							</TotalResult>}                    
						</GraphRow>
					</ResultsColumn>
				</CardContent>
			</BaseCard>
		</BaseModal>
	);
};

export default ProductsModal;
