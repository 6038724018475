import styled, { css } from 'styled-components';

//style
import colors from 'style/palette/palette';

export const RequisitesAndOrBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const LogicOperator = styled.div`
	display: flex;
	flex-wrap: nowrap;
	color: ${colors.white};
`;
