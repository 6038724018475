import { FC, useState } from 'react';

//hooks
import useHasRole from 'hooks/useHasRole';
import useHasPermission from 'hooks/useHasPermission';

//types
import { AdministrationPageProps } from './AdministratinPage.component.d';
import { TitleBreadCrumbSettings } from 'components/DashboardTitleSection/DashboardTitleSection.component.d';
import { TabSelectorItem, TabSelectorSettings } from 'components/TabSelector/TabSelector.component.d';

//components
import DashboardTitleSection from 'components/DashboardTitleSection';
import TabSelector from 'components/TabSelector';

//style
import { Page } from './style/AdministrationPage.component.style';
import { BaseContentCard } from 'style/wrappers/wrappers';
import WithdrawalsTab from './WithdrawalsTab';

const AdministrationPage: FC<AdministrationPageProps> = () => {
	const [tabSelectorValue, setTabSelectorValue] = useState<string>('');

	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isAmministrazione = useHasRole('CONTABILITÀ');

	const breadCrumbSettings: TitleBreadCrumbSettings = { subSectionText: 'Amministrazione' };

	const tabItems: TabSelectorItem[] = [
		{
			title: 'Prelievi Mensili',
			value: 'withdrawals',
		},
	];

	const tabSettings: TabSelectorSettings = { tabItems: tabItems, handleSelection: setTabSelectorValue };

	if (!isSuperAdmin && !isAmministrazione) return (<></>);

	return (
		<Page>
			<DashboardTitleSection breadCrumbSettings={breadCrumbSettings}></DashboardTitleSection>
			<TabSelector tabSelectorSettings={tabSettings}></TabSelector>
			<BaseContentCard>{tabSelectorValue === 'withdrawals' && <WithdrawalsTab />}</BaseContentCard>
		</Page>
	);
};

export default AdministrationPage;
