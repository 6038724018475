import { callGET, callOldGET } from "network/network";

export const getHistoricalResults =async (userId: number) => {
    
    if (!userId || userId === 0) return null;

    try {
        const results = await callGET('v1/userresults/user/'+userId.toString(), true);
        if (!results || results === undefined) {
            throw new Error ("Unable getting Historical Data.");
        }

        return results;
    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        throw error;
    }
    // try {
    //     const results = await callOldGET('libertydashboardresult/getmyresults', true)
    //     if (!results || results === undefined) {
    //         throw new Error ("Unable getting Historical Data.")
    //     }

    //     return results;
    // } catch (error: any) {
    //     if (error.message.includes('403')) throw new Error ('403');
    //     throw error;
    // }
};