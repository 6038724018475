import {FC, useCallback, useEffect, useState} from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';


//types
import {TestimonialsPageProps} from './TestimonialsPage.component.d';
import { Testimonial } from 'types/Testimonial';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { TitleBreadCrumbSettings } from 'components/DashboardTitleSection/DashboardTitleSection.component.d';

//utils
import { createTestimonial, deleteTestimonialById, getTestimonials, putTestimonial, putTestimonialsWeights } from './services/testimonialsPage.services';
import update from 'immutability-helper';
import config from 'configs/config';
import { getBrand } from 'configs/brandMap/brandMap.helper';

//components
import DashboardTitleSection from 'components/DashboardTitleSection';
import TestimonialNode from './Testimonial';

//assets
import { ReactComponent as AddBoxIcon } from './assets/addBoxIcon.svg';
import { ReactComponent as LinkIcon } from './assets/linkIcon.svg';

//style
import { AddTestimonial, ButtonsRow, Page, PageContent, SaveButton, TestimonialsBox } from './style/TestimonialsPage.component.style';
import { BaseContentCard } from 'style/wrappers/wrappers';

const weightStep = 500;


const url = window.location.origin;
const online = !url.includes('localhost');
const test = url.includes('ldl-test') || !online;

const testimonialsPageBaseUrl = test ? config.testUrl : config.productionUrl;

const TestimonialsPage: FC<TestimonialsPageProps> = () => {
    const [remoteTestimonials, setRemoteTestimonials] = useState<Testimonial[]>([]);
	const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
	const [isSaveEnabled, setIsSaveEnabled] = useState(false);

	const dispatch = useAppDispatch();

	const addTestimonialClickHandler = async () => {
		try {
			const response = await createTestimonial({title: 'Titolo', weight: 100000})
			setTestimonials(prevState => [response, ...prevState])
		} catch (error) {
			
		}
	}

    const getRemoteTestimonials = async () => {
		const brand = getBrand();
		if (!brand) return;
		try {
			const response: Testimonial[] = await getTestimonials(brand.code);
			const sortedTestimonials = sortTestimonials(response)
			setRemoteTestimonials(sortedTestimonials);
		} catch (error: any) {}
	};

	const deleteTestimonial = async (id: number) => {
		try {
			await deleteTestimonialById(id);
			await getRemoteTestimonials();
		} catch (error) {
			console.log(error);
		}
	};

	const updateLocalTestimonial = (testimonialId: number, updatedTestimonial: Partial<Testimonial>) => {
		setTestimonials((prevState) =>
			prevState.map((t) => {
				if (t.id === testimonialId) return { ...t, ...updatedTestimonial };
				return t;
			})
		);
	};

	const putRemoteTestimonial = async (testimonialId: number, updatedTestimonial: Partial<Testimonial>) => {
		try {
			await putTestimonial({ id: testimonialId, ...updatedTestimonial })
		} catch (error) {
			console.log(error);
		}
	};

	const saveOrderClickHandler = async () => {
		const snackbarData: SnackbarOptions = {};
		if (!testimonials) return;
		try {
			let testimonialsData: Partial<Testimonial>[] = [];
			let weight = (testimonials.length - 1) * weightStep;
			for (let i = 0; i < testimonials.length; i++) {
				testimonialsData.push({ id: testimonials[i].id, weight });
				weight = weight - weightStep;
			}
			await putTestimonialsWeights(testimonialsData);
			snackbarData.type = 'success';
			snackbarData.message = `Ordinamento salvato con successo`;
			dispatch(addSnackbar(snackbarData));
			setIsSaveEnabled(false);
			getRemoteTestimonials();
		} catch (error) {
			snackbarData.type = 'error';
			snackbarData.message = "Errore nel salvataggio dell'ordinamento";
			dispatch(addSnackbar(snackbarData));
		}
	};

	useEffect(() => {
		getRemoteTestimonials();
	}, []);

	useEffect(() => {
		if (!remoteTestimonials) return;
		setTestimonials(
			remoteTestimonials
				// .filter((p) => p.visible)
				// .sort((a, b) => {
				// 	if (a.salesData?.weight < b.salesData?.weight) return 1;
				// 	if (a.salesData?.weight > b.salesData?.weight) return -1;
				// 	return 0;
				// })
		);
	}, [remoteTestimonials]);

	useEffect(() => {
		setIsSaveEnabled(JSON.stringify(remoteTestimonials) !== JSON.stringify(testimonials));
	}, [testimonials, remoteTestimonials]);

	useEffect(() => {
		console.log("Updated Testimonials:", testimonials);
	  }, [testimonials]);

    const moveTestimonial = useCallback((dragIndex: number, hoverIndex: number) => {
		setTestimonials((prevState) =>
			update(prevState, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, prevState[dragIndex] as Testimonial],
				],
			})
		);
	}, []);

	const sortTestimonials = (testimonials: Testimonial[]) => {
		return testimonials.sort((a, b) => {
			if (a.visible === b.visible) {
			  return b.weight - a.weight;
			} else {
			  return a.visible ? -1 : 1;
			}
		  });
	}

	const renderTestimonial = useCallback((t: Testimonial, i: number) => {
		return (
			<TestimonialNode
				key={t.id}
				index={i}
				testimonial={t}
				moveTestimonial={moveTestimonial}
				deleteTestimonial={deleteTestimonial}
				updateLocalTestimonial={updateLocalTestimonial}
				putRemoteTestimonial={putRemoteTestimonial}
			/>
		);
	}, []);

	const breadCrumbSettings: TitleBreadCrumbSettings = { subSectionText: 'Testimonianze' };

    return (
        <Page>
            <DashboardTitleSection breadCrumbSettings={breadCrumbSettings}></DashboardTitleSection>
                <BaseContentCard>
                    <PageContent>
						<ButtonsRow>
							<AddTestimonial onClick={addTestimonialClickHandler}>
								<AddBoxIcon /> Aggiungi Testimonianza
							</AddTestimonial>
							<SaveButton
								disabled={!isSaveEnabled}
								onClick={saveOrderClickHandler}
							>
								Salva Modifiche
							</SaveButton>
							<a
										target='_blank'
										rel='noreferrer'
										href={`${testimonialsPageBaseUrl}app/testimonials`}
									>
								<LinkIcon />
							</a>
						</ButtonsRow>
                        <TestimonialsBox>{testimonials?.map((t, i) => renderTestimonial(t, i))}</TestimonialsBox>
                    </PageContent>
                </BaseContentCard>
        </Page>
    )
}

export default TestimonialsPage