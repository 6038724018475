import styled from 'styled-components';

export const ProductsBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	gap: 0.5rem;
`;

export const Courses = styled.div`
	display: flex;
	align-items: center;
	gap: 0.25rem;

	margin: 2px;
`;

export const Services = styled.div`
	display: flex;
	align-items: center;
	gap: 0.25rem;

	margin: 2px;

`;

export const Lessons = styled.div`
	display: flex;
	align-items: center;
	gap: 0.25rem;

	margin: 2px;

`;

export const ThematicBroadcasts = styled.div`
	display: flex;
	align-items: center;
	gap: 0.25rem;

	margin: 2px;

`;

export const Initiatives = styled.div`
	display: flex;
	align-items: center;
	gap: 0.25rem;

	margin: 2px;

`;

export const Row = styled.div`
	display: flex;
	justify-content: space-evenly;
	align-items: center;

	width: 100%;
`;