import { FC } from 'react';

//types
import { RoadmapEventTypesTableProps } from './RoadmapEventTypesTable.component.d';
import { RoadmapEventTypesTableColumn } from './RoadmapEventTypesTable.component.d';

//utils
import config from 'configs/config';

//assets
import emptyPicIconSVG from './assets/emptyPicIcon.svg';
import { ReactComponent as LinkIcon } from './assets/linkIcon.svg';
import DefaultEventPic from './assets/defaultEventPic.png';

//components
import RowButtons from './RowButtons';

//style
import { Table, TableBody, TableBodyCell, TableBodyRow, TableHead, TableHeadCell, TableHeadRow, SellImage, EventName, Subscriptions } from './style/RoadmapEventTypesTable.style';
import useHasRole from 'hooks/useHasRole';



const url = window.location.origin;
const online = !url.includes('localhost');
const test = url.includes('ldl-test') || !online;

const RoadmapEventTypesTable: FC<RoadmapEventTypesTableProps> = ({ data, putEvent }) => {

	var columns: RoadmapEventTypesTableColumn[] = [
		{ label: '', width: '3%' },
		{ label: 'ID', width: '2%' },
		{ label: '', width: '10%' },
		{ label: 'NOME', width: '85%' },
		// { label: 'METODI PAGAMENTO', width: '5%' },
		// { label: 'PESO', width: '5%' },
		// { label: 'VISIBILE', width: '3%' },
		// { label: 'BRAND', width: '5%' },
		// { label: 'PRODOTTI', width: '4%' },
		// {label:'AUTOSTRADA',width: '1%'},
		// {label:'LEZ. AP',width: '1%'},
		// { label: 'STATS', width: '5%' },
		// { label: 'NOTE', width: '2%' },
	];
	
	const eventTypeLabel = {
		COURSE: 'CORSO',
		SERVICE: 'SERVIZIO',
		LESSON_PACK: 'PACCHETTO LEZIONI',
		AUTOMATIC_INCOME: 'ENTRATA AUTOMATICA',
		CREDIT_PACK: 'PACCHETTO CREDITI',
		THEMATIC_BROADCAST: 'DIRETTA TEMATICA',
	};

	const isAdmin = useHasRole('ADMIN');
	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');
	const hasAdminRole = isAdmin || isSuperAdmin;

	return (
		<>
			<Table>
				<TableHead>
					<TableHeadRow>
						{columns.map((c) => (
							<TableHeadCell
								key={c.label}
								width={c.width}
								// onClick={() => handleSort(key)}
							>
								{c.label.toUpperCase()}
								{/* {sortColumn === c && <span>{sortDirection === 'asc' ? '⬆️' : '⬇️'}</span>} */}
							</TableHeadCell>
						))}
					</TableHeadRow>
				</TableHead>
				<TableBody>
					{data.map((row, index) => (
						<TableBodyRow key={index}>
							<TableBodyCell
								key={index + '_buttons'}
								id={index + '_buttons'}
							>
								<RowButtons eventTypeId={row.id} />
							</TableBodyCell>
							<TableBodyCell
								key={index + '_id'}
								id={index + '_id'}
							>
								{row.id}
							</TableBodyCell>

							<TableBodyCell
								key={index + '_image'}
								id={index + '_image'}
							>
								{row.defaultImage?.url && <SellImage
									alt='-'
									src={row.defaultImage?.url ? row.defaultImage.url : DefaultEventPic}
								/>}
							</TableBodyCell>						

							<TableBodyCell
								key={index + '_name'}
								id={index + '_name'}
							>
								<EventName>
									{/* <a
										target='_blank'
										rel='noreferrer'
										href={`${row.id}`}
									>
										<LinkIcon />
									</a> */}
									{row.name}
								</EventName>
							</TableBodyCell>
							
						</TableBodyRow>
					))}
				</TableBody>
			</Table>

			{/* {totalElements > 0 && (
				<TableFooter>
					<FooterElementsCount>{footerElementsCount({ currentPage: pageNumber, totalPages, totalElements, pageSize })}</FooterElementsCount>
					<FooterNavigationButton
						onClick={prevPageHandler}
						disabled={pageNumber === 1}
					>
						<ChevronLeftIcon />
					</FooterNavigationButton>
					<FooterNavigationButton
						onClick={nextPageHandler}
						disabled={pageNumber === totalPages}
					>
						<ChevronRightIcon />
					</FooterNavigationButton>
				</TableFooter>
			)} */}
		</>
	);
};

export default RoadmapEventTypesTable;
