import { FC } from 'react';
import { Label } from '../style/ModifyTestimonialModal.component.style';

//types
import { TestimonialVideoCoverProps } from './TestimonialVideoCover.component.d';

//style
import { LessonVideoBox, VideoUrl } from './style/TestimonialVideoCover.component.style';

const TestimonialVideoCover: FC<TestimonialVideoCoverProps> = ({ videoCoverUrl, setLocalVideoCoverUrl }) => {
	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLocalVideoCoverUrl(event.currentTarget.value);
	};
	return (
		<LessonVideoBox>
			<Label>Video Cover</Label>
			<VideoUrl
				value={videoCoverUrl}
				onChange={onChangeHandler}
				placeholder='Inserire url ftp'
			></VideoUrl>
		</LessonVideoBox>
	);
};

export default TestimonialVideoCover;
