import { FC, useEffect, useState } from 'react';
import { Wrapper, ListItem } from './style/ScrollableTextList.style';

interface Item {
  id: number;
  label: string;
}

interface Props {
  items: Item[] | null;
  selectedItemId: number | null | undefined;
  onItemSelected: (itemId: number) => void;
}

const ScrollableTextList: FC<Props> = ({ items, selectedItemId, onItemSelected }) => {
  
  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const handleItemClick = (itemId: number) => {
    setSelectedItem(itemId);
    onItemSelected(itemId);
  };

  useEffect(() => {
    if (selectedItemId) setSelectedItem(selectedItemId);
  }, [selectedItemId]);

  return (
    <Wrapper>
      {items && items.map((item) => (
        <ListItem
          key={item.id}
          isSelected={item.id === selectedItem}
          isOriginal={item.id === selectedItemId}
          onClick={() => handleItemClick(item.id)}
        >
          {item.label}
        </ListItem>
      ))}
    </Wrapper>
  );
};

export default ScrollableTextList;
