import styled, { css } from 'styled-components';
import { RowProps, ColProps } from 'style/layout/layout.d';

import colors from 'style/palette/palette';

export const GenericInfoBox = styled.div`
	margin: 1rem;
	color: ${colors.white};
`;

export const RowBox = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-around;
`;

export const FieldBox = styled.div`
	padding: 1rem;
	display: flex;
`;

export const LevelImageContainer = styled.div`
	display: flex;
	align-items: stretch;
`;

export const LevelImage = styled.img`
	width: 7rem;
	align-self: flex-start;

	cursor: pointer;
`;

export const ImageEditIconOverlay = styled.div`
	margin-left: -16px;

	> svg {
            min-width: 15px;
            min-height: 15px; 
			filter: brightness(0) saturate(100%) invert(84%) sepia(30%) saturate(5368%) hue-rotate(359deg) brightness(103%) contrast(105%);       
    }
`;

export const LevelImageNewFile = styled.input.attrs({ 
    type: 'file',
  })`
    display: none;
`;

export const InfoLabel = styled.div`
	padding: 0.5rem;
	margin: 1rem;
	font-weight: bold;
	min-width: 25%;
	color: ${colors.white};
	text-transform: uppercase;
`;

export const InfoContent = styled.div<{ type?: string }>`
	padding: 0.5rem;
	color: ${colors.white050};
	${({ type }) =>
		type === 'email' &&
		css`
			cursor: copy;
		`}
`;

export const SelectInput = styled.select`
	
`;

export const Row = styled.div<RowProps>`

    width: ${({ width }) => width ?? "100%"};

    margin: ${({ margin }) => margin ?? "0"};
    padding: ${({ padding }) => padding ?? "0"};

    display: flex;
    align-items: ${({ crossAxis }) => crossAxis ?? "center"};
    justify-content: ${({ mainAxis }) => mainAxis ?? "flex-start"};

    gap: ${({ gap }) => gap ?? "normal"};
    ${({ rowGap }) => rowGap ?? css`row-gap:${rowGap};`}
    ${({ columnGap }) => columnGap ?? css`column-gap:${columnGap};`}
    flex-direction: row;
`;

export const Col = styled.div<ColProps>`

    width: ${({ width }) => width ?? "auto"};

    margin: ${({ margin }) => margin ?? "0"};
    padding: ${({ padding }) => padding ?? "0"};

    display: flex;
    align-items: ${({ crossAxis }) => crossAxis ?? "center"};
    justify-content: ${({ mainAxis }) => mainAxis ?? "flex-start"};

    gap: ${({ gap }) => gap ?? "normal"};
    ${({ rowGap }) => rowGap ?? css`row-gap:${rowGap};`}
    ${({ columnGap }) => columnGap ?? css`column-gap:${columnGap};`}
    flex-direction: row;
`;
