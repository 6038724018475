import { FC, useState } from 'react';

// hooks
import useHasRole from 'hooks/useHasRole';

//types
import { ServiceDetailProps } from './ServiceDetail.component.d';
import { TabSelectorSettings, TabSelectorItem } from 'components/TabSelector/TabSelector.component.d';

//components
import { BaseContentCard } from 'style/wrappers/wrappers';
import TabSelector from 'components/TabSelector/TabSelector.component';
import ProductTab from '../ProductTab';
import ContentTab from '../ContentTab';
import PercentagesTab from '../PercentagesTab';
import PaymentPlansTab from '../PaymentPlansTab';
import SalesPageTab from '../SalesPageTab';
import RequirementsTab from '../RequirementsTab';



const ServiceDetail: FC<ServiceDetailProps> = ({ product, putProduct, updateProductData }) => {
	const isAdmin = useHasRole('ADMIN');
	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isAnimator = useHasRole('ANIMATORE');
	const hasAdminRole = isAdmin || isSuperAdmin;
	const canSeeService = hasAdminRole || isAnimator;
	
	const TAB_ITEMS: TabSelectorItem[] = [
		{
			title: 'Servizio',
			value: 'service',
			display: canSeeService,
		},
		{
			title: 'Prezzo e %',
			value: 'percentages',
			display: hasAdminRole,
		},
		{
			title: 'Piano pagamento',
			value: 'paymentplans',
			display: hasAdminRole,
		},
		{
			title: 'Guida',
			value: 'content',
		},
		{
			title: 'Pagina di vendita',
			value: 'salesPage',
			display: hasAdminRole,
		},
		{
			title: 'Requisiti',
			value: 'requirements',
			display: hasAdminRole,
		},
	];

	const [tabSelectorValue, setTabSelectorValue] = useState<string>('');

	const tabSettings: TabSelectorSettings = { tabItems: TAB_ITEMS, handleSelection: setTabSelectorValue };

	return (
		<>
			<TabSelector tabSelectorSettings={tabSettings}></TabSelector>
			<BaseContentCard>
				{tabSelectorValue === 'service' && (
					<ProductTab
						product={product}
						putProduct={putProduct}
					></ProductTab>
				)}
				{tabSelectorValue === 'percentages' && (
					<PercentagesTab
						product={product}
						putProduct={putProduct}
					></PercentagesTab>
				)}
				{tabSelectorValue === 'paymentplans' && (
					<PaymentPlansTab
						product={product}
						putProduct={putProduct}
					></PaymentPlansTab>
				)}
				{tabSelectorValue === 'content' && (
					<ContentTab
						product={product}
						putProduct={putProduct}
					></ContentTab>
				)}
				{tabSelectorValue === 'salesPage' && (
					<SalesPageTab
						product={product}
						putProduct={putProduct}
						updateProductData={updateProductData}
					></SalesPageTab>
				)}
				{tabSelectorValue === 'requirements' && (
					<RequirementsTab
						product={product}
						putProduct={putProduct}
						updateProductData={updateProductData}
					></RequirementsTab>
				)}
			</BaseContentCard>
		</>
	);
};

export default ServiceDetail;
