import styled, { css } from 'styled-components';
import colors from 'style/palette/palette';

export const FacebookFederatedContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

export const FirstRow = styled.div<{ hasFacebook: boolean }>`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 0.5rem;
	${({ hasFacebook }) =>
		hasFacebook &&
		css`
			cursor: pointer;
		`}
`;

export const FacebookImg = styled.img`
	height: 40px;
	border-radius: 50%;
	border: 2px solid ${colors.blue};
`;

export const FacebookName = styled.div``;

export const SecondRow = styled.div`
	display: flex;
`;

export const FacebookCognitoId = styled.div`
	font-size: 0.5rem;
	cursor: copy;
`;
