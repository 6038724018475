import { FC, useEffect, useState } from 'react';

//types
import { PoolTransaction } from 'types/PoolTransaction';
import { PoolTransactionsProps } from './PoolTransactions.component.d';

//utils
import { getSinglePoolTransactions } from '../services/PointsPage.services';

//style
import { PoolTransactionsBox } from './style/PoolTransactions.component.style';
import PoolTransactionsTable from './PoolTransactionsTable';

const PoolTransactions: FC<PoolTransactionsProps> = ({ selectedSelfPoolId }) => {
	const [searchResults, setSearchResults] = useState<PoolTransaction[]>([]);
	const [totalPages, setTotalPages] = useState<number>(0);
	const [totalElements, setTotalElements] = useState<number>(0);
	const [pageSize, setPageSize] = useState<number>(0);

	const getPoolTxs = async (page: number) => {
		try {
			const response = await getSinglePoolTransactions(selectedSelfPoolId, page);
			setSearchResults(response.content);
			setTotalElements(response.totalElements);
			setTotalPages(response.totalPages);
			setPageSize(response.size);
		} catch (error) {
			throw error;
		}
	};

	useEffect(() => {
		getPoolTxs(0);
	}, [selectedSelfPoolId]);

	return (
		<PoolTransactionsBox>
			<PoolTransactionsTable
				data={searchResults}
				pageSize={pageSize}
				totalElements={totalElements}
				totalPages={totalPages}
				searchPoolTransactions={getPoolTxs}
			/>
		</PoolTransactionsBox>
	);
};

export default PoolTransactions;
