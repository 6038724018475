import colors from 'style/palette/palette';
import styled from 'styled-components';

export const NodeTitleBox = styled.input`
	background-color: transparent;
	border: none;
	width: auto;
	max-width: 100%;
	font-size: 1rem;
	color: ${colors.white};

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	${props =>
		!props.disabled &&
		`
		:hover,
		:focus {
			background-color: ${colors.blueGrey};
			color: ${colors.black};
		}
	`}
`;

export const TitleModify = styled.div`
	margin-left: -2rem;
`;
