import { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Routes, Route, useNavigate } from 'react-router-dom';

// utils
import { AppRoute } from 'routes';
import appRoutes from 'routes';
import RedirectToDefault from 'routes/RedirectToDefault';
import { checkActualUrlforBrand } from 'configs/brandMap/brandMap.helper';


const buildElementForRoute = (route: AppRoute) => {

    return (
        // <RedirectToDefault isRouteProtected={route?.isProtected}>
            route?.element ?? <></>
            // {route?.element ?? <></>}
        // </RedirectToDefault>
    );
};

const buildRoutes = (routes: AppRoute[]) => {

    return (
        <>
            {routes.map((route, _) => {

                if (!route.subRoutes) {

                    return (
                        <Route
                            key={uuidv4()}
                            path={route.isIndex === undefined ? route.path : ""}
                            index={route?.isIndex}
                            element={buildElementForRoute(route)}
                        />
                    );
                }

                return (
                    <Route
                        key={uuidv4()}
                        path={route.isIndex === undefined ? route.path : ""}
                        // index={route?.isIndex}
                        element={buildElementForRoute(route)}
                    >
                        {buildRoutes(route.subRoutes)}
                    </Route>
                );
            })}
        </>
    );
};

/**
 * Routes builder
 * 
 * @author Alessio Grassi
 * 
 * @returns JSX - Routes
 */

const BuildRoutes: FC<{}> = () => {

    const navigateTo = useNavigate();

    const domain = window.location.hostname;

    var brandHome = `/reservedArea`;

    if (!checkActualUrlforBrand()) {
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const searchParams = new URLSearchParams(url.search);

        if (domain.includes('localhost')) {
            brandHome += '?'+searchParams.toString();
        }
        navigateTo(brandHome);
    } else {
        
    if (!appRoutes || appRoutes === undefined || !Array.isArray(appRoutes) || appRoutes.length === 0) {

        return (
            <Routes>
                <Route
                    index
                    element={<></>}
                />

                <Route
                    path='*'
                    element={<></>}
                />
            </Routes>
        );
    }

    return (
        <Routes>
            {buildRoutes(appRoutes)}
        </Routes>
    );
    }
    return (<></>);
};

export default BuildRoutes;
