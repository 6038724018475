import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';

//redux
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';

//types
import { CoachProps } from './Coach.component.d';

//assets
import { ReactComponent as EditIconSVG } from './assets/editIcon.svg';
import emptyPicIconSVG from './assets/emptyPicIcon.svg';
import { ReactComponent as CalendarIconSVG } from './assets/calendar.svg';

//utils
import { updateCoach, uploadFile } from './services/Coach.services';

//style
import { 
	CoachBox,
	CoachImageContainer,
	CoachImage,
	ImageEditIconOverlay,
	CoachImageNewFile,
	Col,
	Row,
	CoachTopic,
	CoachDescription,
	CoachCalendly,
	CoachSaveButton,
 } from './style/Coach.component.style';

 
const Coach: FC<CoachProps> = ({user, updateLocalUser}) => {
	const [imageIsClickable, setImageIsClickable] = useState<boolean>(false);
	const [textHasChanged, setTextHasChanged] = useState<boolean>(false);
	const [topic, setTopic] = useState<string>('');
	const [description, setDescription] = useState<string>('');
	const [calendly, setCalendly] = useState<string>('');

	const dispatch = useAppDispatch();

	const productImageNewFileInput = useRef<HTMLInputElement>(null);

	const uploadImageFile = async (userId: number, fileFile: File): Promise<any | null> => {
		if (typeof fileFile === 'undefined') return null;
		var uploaded: any;

		var formData = new FormData();
		formData.append('file', fileFile);
		try {
			uploaded = await uploadFile(userId, formData);
		} catch (err) {
			throw err;
		}
		return uploaded;
	};

	const handleSingleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => { 
		if (!user) return;
		if (!user.id) return;
		const files = event.target.files;
		if (files && files.length > 0) {
			try {
				var uploaded = await uploadImageFile(user.id, files[0]);
				setTimeout(() => {
					updateLocalUser();
				}, 1000);
			} catch (err) {
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'error';
				snackbarData.message = 'Errore nel caricamento immagine';
				dispatch(addSnackbar(snackbarData));
			}
		}
	};
	
	const handleSaveText = async () => {
		if (!user) return;
		if (!user.id) return;
		try {
			var updated = await updateCoach({ userId: user.id, topic: topic, description: description, calendly: calendly});
			setTextHasChanged(false);
		} catch (err) {
			const snackbarData: SnackbarOptions = {};
			snackbarData.type = 'error';
			snackbarData.message = "Errore nel salvataggio delle modifiche";
			dispatch(addSnackbar(snackbarData));
		} 
	}

	const handleTopicChange = (event: ChangeEvent<HTMLInputElement>) => {
		setTopic(event.target.value);
		setTextHasChanged(true);
	}

	const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
		setDescription(event.target.value);
		setTextHasChanged(true);
	}

	const handleCalendlyChange = (event: ChangeEvent<HTMLInputElement>) => {
		setCalendly(event.target.value);
		setTextHasChanged(true);
	}

	const onImageOver = () => {
		setImageIsClickable(true);
	};
	
	const onImageOut = () => {
		setImageIsClickable(false);
	};
	
	const onImageClick = () => {
		if (imageIsClickable) {
			if (productImageNewFileInput.current) {
				productImageNewFileInput.current.click();
			}
		}
	};
	
	useEffect(() => { 
		if (user.coachInfo?.description) setDescription(user.coachInfo.description);
		if (user.coachInfo?.topic) setTopic(user.coachInfo.topic);
		if (user.coachInfo?.calendly) setCalendly(user.coachInfo.calendly);
	}, [user]);

	return <CoachBox>
				<CoachImageContainer>
					<CoachImage
						src={user?.coachInfo?.profilePicture?.url ? user.coachInfo.profilePicture.url : emptyPicIconSVG}
						onMouseOver={onImageOver}
						onMouseOut={onImageOut}
						onClick={onImageClick}
					/>

					<ImageEditIconOverlay style={{ opacity: imageIsClickable ? 1 : 0 }}>
						<EditIconSVG />
					</ImageEditIconOverlay>
				</CoachImageContainer>

				<CoachImageNewFile
					ref={productImageNewFileInput}
					onChange={handleSingleFileChange}
				/>

				<Col>
					<CoachTopic
						onChange={handleTopicChange}
						value={topic}
					/>

					<CoachDescription 
						rows={3} 
						onChange={handleDescriptionChange}
						value={description}>
					</CoachDescription>

					<Row>
						<CalendarIconSVG />

						<CoachCalendly
							onChange={handleCalendlyChange}
							value={calendly}
						/>
					</Row>

					<CoachSaveButton 
						disabled={!textHasChanged}
						onClick={handleSaveText}
					>Salva</CoachSaveButton>
				</Col>
	</CoachBox>;
};

export default Coach;
