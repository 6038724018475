import { FC, useEffect, useState } from 'react';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsMounted } from 'usehooks-ts';

//types
import { PoolTransactionsTableProps } from './PoolTransactionsTable.component.d';
import { PoolTransaction } from 'types/PoolTransaction';

//assets
import { ReactComponent as ChevronLeftIcon } from './assets/chevronLeft.svg';
import { ReactComponent as ChevronRightIcon } from './assets/chevronRight.svg';

//style
import { Box, FooterElementsCount, FooterNavigationButton, TableFooter, TxsTable } from './style/PoolTransactionsTable.component.style';
import { footerElementsCount } from 'pages/APLessonsPage/APLessonsNewTable/APLessonsNewTable.helper';

const PoolTransactionsTable: FC<PoolTransactionsTableProps> = ({ data, totalPages, totalElements, pageSize, searchPoolTransactions }) => {
	const [tableData, setTableData] = useState<PoolTransaction[]>([]);
	const [currentPage, setCurrentPage] = useState(1);

	const dispatch = useAppDispatch();
	const isMounted = useIsMounted();

	const pageChangeHandler = (page: number) => {
		setCurrentPage(page);
	};

	useEffect(() => {
		setTableData(data);
	}, [data]);

	useEffect(() => {
		searchPoolTransactions(currentPage - 1);
	}, [currentPage]);

	return (
		<Box>
			<TxsTable>
				<thead>
					<th>ID TX</th>
					<th>DATA</th>
					<th>DESCRIZIONE</th>
					<th>PUNTI</th>
					<th>REF POOL</th>
				</thead>
				<tbody>
					{tableData.map((row, index) => {
						return (
							<tr>
								<td>{row.id}</td>
								<td>{new Date(row.creationDate).toLocaleDateString()}</td>
								<td>{row.description}</td>
								<td>{row.amount}</td>
								<td>{row.referencePool?.name}</td>
							</tr>
						);
					})}
				</tbody>
			</TxsTable>
			{totalElements > 0 && (
				<TableFooter>
					<FooterElementsCount>{footerElementsCount({ currentPage, totalPages, totalElements, pageSize })}</FooterElementsCount>
					<FooterNavigationButton
						onClick={() => pageChangeHandler(currentPage - 1)}
						disabled={currentPage === 1}
					>
						<ChevronLeftIcon />
					</FooterNavigationButton>
					<FooterNavigationButton
						onClick={() => pageChangeHandler(currentPage + 1)}
						disabled={currentPage === totalPages}
					>
						<ChevronRightIcon />
					</FooterNavigationButton>
				</TableFooter>
			)}
		</Box>
	);
};

export default PoolTransactionsTable;
