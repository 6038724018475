import React from 'react';
import ReactDOM from 'react-dom';

// routing
import { BrowserRouter as Router } from 'react-router-dom';

// redux
import { Provider } from 'react-redux';
import store from 'redux/store';

import './index.css';

import App from './App/App.component';
// import reportWebVitals from "./reportWebVitals";

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import config from 'configs/config';

// aws amplify stuff
// doc link:
//      https://docs.amplify.aws/lib/auth/start/q/platform/js/
//      https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_SignUp.html#API_SignUp_Errors
// refresh session:
//      https://github.com/aws-amplify/amplify-js/issues/2560
// facebook logout error (unresolved)
//      https://github.com/aws-amplify/amplify-js/issues/1298
//      https://github.com/aws-amplify/amplify-js/issues/2423
// facebook automatic refresh session (unresolved)
//      https://docs.amplify.aws/lib/auth/advanced/q/platform/js/#pre-authentication-and-pre-sign-up-lambda-triggers
import { Amplify } from 'aws-amplify';
import devAuthConfig from 'configs/aws/cognito-config-development';
import testAuthConfig from 'configs/aws/cognito-config-test';
import prodAuthConfig from 'configs/aws/cognito-config-production';

let finalAuthConfiguration;
if (config.environment === 'development') finalAuthConfiguration = devAuthConfig;
if (config.environment === 'test') finalAuthConfiguration = testAuthConfig;
if (config.environment === 'production') finalAuthConfiguration = prodAuthConfig;

Amplify.configure({ Auth: finalAuthConfiguration });

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<Provider store={store}>
				<DndProvider backend={HTML5Backend}>
					<App />
				</DndProvider>
			</Provider>
		</Router>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
