import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const BreadCrumb = styled.h1`
	width: 100%;
	max-width: 1360px;

	padding: 0;

	font-size: 18px;
	font-weight: 400;
	line-height: 20px;

	color: #ffffffb3;

	display: flex;
	align-items: center;
	justify-content: flex-start;
`;

export const Root = styled(Link)`
	color: white;
`;

export const TextSeparator = styled.span`
	display: inline-block;
	margin: 0 10px;

	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
	color: inherit;
`;

export const SubSectionText = styled.span`
	font-size: 16px;
	font-weight: 400;
	line-height: 16px;

	color: #fff;
`;

export const SubSectionLink = styled(Link)`
	color: white;
`;

export const SubSubSectionText = styled.span`
	font-size: 16px;
	font-weight: 400;
	line-height: 16px;

	color: #fff;
`;
