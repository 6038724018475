import { callGET, callPOST } from 'network/network';

import { getBrand } from 'configs/brandMap/brandMap.helper';


const _USER_FROM_ID_PATH = 'v1/users';
const _PRODUCT_SUBSCRIPTIONS_PATH = 'v1/productsubscriptions/users/{userId}/products/{productId}';


export const getSingleUserFromId = async (userId: number) => {
	try {
		const response = await callGET(_USER_FROM_ID_PATH + '/' + userId, true);

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const subscribeUserToProduct = async (userId: number, productId: number, days?: number, paymentTypeId?: number, price?: number, paymentId?: string) => {
	const brand = getBrand();
	if (!brand) return;
	try {
		const params = { brandCode: brand.code, days, paymentTypeId, price, paymentId };
		if (days) params.days = days;
		const response = await callPOST(
			_PRODUCT_SUBSCRIPTIONS_PATH.replace(/{userId}/g, userId.toString()).replace(/{productId}/g, productId.toString()),
			{},
			true,
			params
		);

		//204 response

		return response;
	} catch (error: any) {
		throw error;
	}
};
