import { FC, useEffect, useState } from 'react';

//types
import { ZoomLinkModalProps } from './ZoomLinkModal.component.d';

//components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';

//style
import { 
	ButtonsRow, 
	CancelButton, 
	SaveButton, 
	LinkTitle, 
	ZoomLinkBox, 
	LinkInput 
} from './style/ZoomLinkModal.component.style';
import { enableAppMain } from 'redux/slices/slice-app-main';
import useAppDispatch from 'hooks/useAppDispatch';

const ZoomLinkModal: FC<ZoomLinkModalProps> = ({ onCloseButtonModal, onSaveButtonModal, url }) => {
	const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);

	const [zoomLink, setZoomLink] = useState<string>(url);

	const [isContentModified, setIsContentModified] = useState<boolean>(false);

	const dispatch = useAppDispatch();

	const clearInput = () => {
		setZoomLink('');
	}

	const inputChangeHandler = (inputValue: any) => {
		if (isContentModified === false) setIsContentModified(true);
		setZoomLink(inputValue.target.value);
	};

	const inputKeyHandler = (event: any) => {
		if (event.keyCode === 27) {
			closeButtonHandler();
		}
		if (event.keyCode === 13 && !event.shiftKey && isContentModified) {
			onSaveClickHandler();
		}
	};

	const closeButtonHandler = () => {
		if (onCloseButtonModal) onCloseButtonModal();
		clearInput();
		dispatch(enableAppMain());
	};

	const onSaveClickHandler = () => {
		if (onCloseButtonModal) onCloseButtonModal();
		onSaveButtonModal(zoomLink);
		clearInput();
		dispatch(enableAppMain());
	};

	return (
		<BaseModal
			isBackdropClickActive={true}
			onClose={closeButtonHandler}
			isCloseButtonClicked={isCloseButtonClicked}
		>
			<BaseCard
				flavor='outlined'
				maxWidth='700px'
			>
				<ZoomLinkBox>
					<LinkTitle>Link per la lezione su Zoom:</LinkTitle>
					<LinkInput
						onKeyDown={inputKeyHandler}
						onChange={inputChangeHandler}
						value={zoomLink}
						autoFocus
					/>
					<ButtonsRow>
						<CancelButton onClick={closeButtonHandler}>Annulla</CancelButton>

						<SaveButton
							disabled={!isContentModified}
							onClick={onSaveClickHandler}
						>
							Salva Modifiche
						</SaveButton>
					</ButtonsRow>
				</ZoomLinkBox>
			</BaseCard>
		</BaseModal>
	);
};

export default ZoomLinkModal;
