import { callGET, callPUT, callPOST } from 'network/network';

import { apLessonsSearchParams } from './APLessonsPage.services.d';

import { getBrand } from 'configs/brandMap/brandMap.helper';

const PRODUCT_ID = 250; // Lezione Assistente Personale
const _COACHES_GET_PATH = 'v1/coaches';
const _APLESSONS_SEARCH_PATH = 'v1/productlessonpacks/subscriptions';
const _APLESSONS_ASSIGN_PATH = 'v1/productlessonpacks/subscriptions/{subscriptionId}/assign/{apId}';
const _APLESSONS_CLOSE_PATH = 'v1/productlessonpacks/closelessoncoach/{lessonId}';

export const createCoach = async () => {

	  try { 
        //const response = await callPOST(_COACHES_GET_PATH, newCoach, true);
		const response = true;
        if (response === undefined || !response) {
            throw new Error();
        }

        return response;
    } catch (error: any) {
        throw error;
    }

}

export const getCoaches = async () => {
	try {
		const response = await callGET(_COACHES_GET_PATH, true);

		if (response === undefined || !response) {
			throw new Error();
		}
		console.log(response);

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const getSearchAPLessons = async (apLessonsSearchParams: apLessonsSearchParams) => {
	try {
		const response = await callGET(_APLESSONS_SEARCH_PATH, true, apLessonsSearchParams);

		if (response === undefined || !response) {
			throw new Error();
		}
		console.log(response);

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const assignLessonToAP = async (subscriptionId: number, apId: number) => {
	const brand = getBrand();
	if (!brand) return;
    try { 
        const response = await callPUT(_APLESSONS_ASSIGN_PATH.replace(
			/{subscriptionId}/g,
			subscriptionId.toString()
		  ).replace(
			/{apId}/g,
			apId.toString()
		  ), {}, true, { brandCode: brand.code });

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const closeAPLesson = async (apLessonId: number, coachNotes: string, coachPrivateNotes: string) => {
	const brand = getBrand();
	if (!brand) return;
    try { 
        const response = await callPUT(_APLESSONS_CLOSE_PATH.replace(
			/{lessonId}/g,
			apLessonId.toString()
		  ), {}, true, { brandCode: brand.code });

        if (response === undefined || !response) {
            throw new Error();
        }

        return response;
    } catch (error: any) {
        throw error;
    }
};