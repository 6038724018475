import { useEffect, useState } from 'react';

// types
import { Brand } from 'types/Brand';

// hooks
import useAppSelector from 'hooks/useAppSelector';

const useBrand = (): Brand | undefined => {
    const brand = useAppSelector(state => state.ui.brand);

    return brand;
};

export default useBrand;
