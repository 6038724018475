import styled, { css } from 'styled-components';
import colors from 'style/palette/palette';

export const Table = styled.table`
	table-layout: fixed;
	color: ${colors.white};
	border-collapse: collapse;
	width: 100%;
	font-size: 0.85rem;
`;

export const TableHead = styled.thead``;

export const TableHeadRow = styled.tr`
	font-weight: 600;
	font-size: 12px;
	color: ${colors.white050};
	background-color: ${colors.black050};
	border-radius: 15px;
`;

export const TableHeadCell = styled.th<{ width: string }>`
	padding: 10px;
	border-bottom: 1px solid ${colors.white012};
	${({ width }) =>
		width &&
		css`
			width: ${width};
		`}
`;

export const TableBody = styled.tbody`
	background-color: ${colors.darkGrey};
`;

export const TableBodyRow = styled.tr`
	&:hover {
		background-color: ${colors.tableRowHover};
		/* td {
			border-bottom: 1px solid ${colors.lightBlue};
		} */
	}
`;

export const TableBodyCell = styled.td`
	padding: 10px;
	border-bottom: 1px solid ${colors.white012};
	text-align: center;
`;

export const TableFooter = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	gap: 25px;
	width: 90%;
	padding: 10px;
	margin-top: 10px;
`;

export const FooterElementsCount = styled.div`
	color: ${colors.white};
	font-size: 0.75rem;
	font-weight: 400;
	margin: 0 15px;
`;

export const FooterNavigationButton = styled.button`
	background: none;
	border: none;
	cursor: pointer;
	> svg {
		fill: ${colors.white};
		margin: auto;
	}
	&:disabled {
		cursor: not-allowed;
		> svg {
			fill: ${colors.white009};
		}
	}
`;

export const SellImage = styled.img`
	width: 4rem;
`;

export const ProductName = styled.div`
	font-size: 1.25rem;
	font-weight: bold;
	display: flex;
	gap: 1rem;
	align-items: center;
	text-align: left;
`;

export const Subscriptions = styled.div`
	
`;

export const RedBackText = styled.div`
	background-color: red;
`;

export const GreenBackText = styled.div`
	background-color: green;
`;

export const ThematicBroadcastDoneLink = styled.div`
	background-color: transparent;
	cursor: pointer;
`;
