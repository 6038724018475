import { FC } from 'react';
import Federated from './Federated/Federated.component';

//types
import { FederatedsProps } from './Federateds.component.d';

//style
import { FederatedsBox } from './style/Federateds.component.style';

const Federateds: FC<FederatedsProps> = ({ federateds }) => {
	return (
		<FederatedsBox>
			{federateds.map((f, i) => {
				return (
					<Federated
						key={i}
						federated={f}
					/>
				);
			})}
		</FederatedsBox>
	);
};

export default Federateds;
