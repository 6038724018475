import { FC, useState } from 'react';

//types
import { VideoPreviewModalProps } from './VideoPreviewModal.component.d';

//components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';

//style
import { CardContent, CloseButton, IconBox, ProductName, ProductsColumn, TextContent, TextDisclaimer, Title } from './style/VideoPreviewModal.component.style';
import MediaPlayer from 'components/MediaPlayer';

const VideoPreviewModal: FC<VideoPreviewModalProps> = ({ onCloseButtonModal, url, coverUrl }) => {
	const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);

	const closeButtonHandler = () => {
		if (onCloseButtonModal) onCloseButtonModal();
	};

	return (
		<BaseModal
			isBackdropClickActive={true}
			onClose={closeButtonHandler}
			isCloseButtonClicked={isCloseButtonClicked}
		>
			<BaseCard
				flavor='outlined'
				maxWidth='700px'
			>
				<CardContent>
					<MediaPlayer media={{ src: url, type: 'video/mp4' }} poster={coverUrl} isAutoPlayEnabled={true}/>
				</CardContent>
			</BaseCard>
		</BaseModal>
	);
};

export default VideoPreviewModal;
