import { callPUT, callPOST } from 'network/network';

const _COACH_PATH = 'v1/coaches';
const _COACH_IMAGE_PATH = 'v1/coaches/{userId}/addpicture';

export const updateCoach = async (user: any) => {
    try {
        const response = await callPUT(_COACH_PATH, user, true);

        if (response === undefined || !response) {
            throw new Error();
        }

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const uploadFile = async (userId: number, form: FormData) => {
    try {
        const response = await callPOST(_COACH_IMAGE_PATH.replace(
			/{userId}/g,
			userId.toString()
		  ), form, true);

        return response;
    } catch (error: any) {
        throw error;
    }
};