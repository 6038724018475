import styled, { css } from 'styled-components';

import colors from 'style/palette';

export const PriceBox = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.1rem;
`;

export const PriceField = styled.input<{ isEditing: boolean }>`
	background-color: transparent;
	border: none;
	text-align: right;
	width: 100%;
	color: inherit;
	font-size: inherit;

	${({ isEditing }) =>
		isEditing &&
		css`
			background-color: ${colors.white};
			color: black;
		`}
`;

export const PriceModifyButton = styled.button<{ isEditing: boolean }>`
	background-color: transparent;
	border: none;
	margin-left: 1rem;
	> svg path {
		cursor: pointer;
	}
	${({ isEditing }) =>
		isEditing &&
		css`
			> svg path {
				fill: ${colors.green};
				fill-opacity: 1;
			}
		`};
`;
