import styled from 'styled-components';

export const Page = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: stretch;
	flex: 1;

	width: 100%;
	height: 100%;
	padding: 0 30px;
`;

export const ShopProductsBox = styled.div`
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-wrap: wrap;
	width: 100%;

	/* padding: 30px 25px; */
	/* margin: 20px 0 0; */

	/* background: #4c5862; */
	/* box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12); */
	/* border-radius: 15px; */

	gap: 1rem;
`;

export const PageContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 100%;
`;

export const SaveButton = styled.button`
	align-self: center;
`;
