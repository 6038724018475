// resources:
// https://freshman.tech/custom-html5-video/
// https://github.com/Freshman-tech/custom-htm5-video-starter-files/blob/master/index.html
// https://github.com/Freshman-tech/custom-htm5-video-starter-files/blob/master/style.css

import { FC, useEffect, useState, useRef, useCallback } from 'react';
import Slider from 'react-input-slider';

// utils
import { formatDuration } from './MediaPlayer.helper';

// types
import { MediaPlayerProps, KeyboardEvent } from './MediaPlayer.d';

// components
import VideoProgress from './VideoProgress';
import BlurTogglerButton from './BlurTogglerButton';

// assets
import { ReactComponent as PlaySVG } from './assets/play-pause/play-icon.svg';
import { ReactComponent as PauseSVG } from './assets/play-pause/pause-icon.svg';
import { ReactComponent as MutedSVG } from './assets/volume/volume-muted-icon.svg';
import { ReactComponent as VolumeHighSVG } from './assets/volume/volume-high-icon.svg';
import { ReactComponent as VolumeLowSVG } from './assets/volume/volume-low-icon.svg';
import { ReactComponent as TheaterSVG } from './assets/theater/theater-icon.svg';
import { ReactComponent as TheaterCloseSVG } from './assets/theater/theater-close-icon.svg';
import { ReactComponent as FullScreenSVG } from './assets/full-screen/full-screen-icon.svg';
import { ReactComponent as FullScreenCloseSVG } from './assets/full-screen/full-screen-close-icon.svg';
import { ReactComponent as PlayOverlayButtonSVG } from './assets/play-overlay/play-overlay-button.svg';

// style
import colors from 'style/palette/palette';
import {
    Container,
    VideoContainer,
    ButtonsBar,
    Controls,
    LeftControls,
    Button,
    Volume,
    Time,
    RightControls,
    PlayButtonOverlay,
    OverlayButton
} from './style/MediaPlayer.style';

/*
    16/09/2022 HOW TO USE FOLLOWING COMPONENT:

    1. Prepare custom media obj:

        const localMedia: Media = {
    
            src: "http://[...].mp4",
            type: "video/mp4"
        };

    2. Set preload option, generally 'metadata'
    3. Set default media HTML5 control to disable
    4. Render Media component like:

        <MediaPlayer
            media={localMedia}
            preload='medatada'
        />
*/


const _INITIAL_VOLUME_LEVEL = "0.4";

/**
 * Media Player
 * 
 * @author Alessio Grassi
 * 
 * @param media - object with src (source string of the video) 
 *                  and type (string of the type of the video ex. "video/mp4")
 * @param preload - what kind of data you want to preload (auto|metadata|none)
 * @param maxWidth - maximum width of the media player
 * @param maxHeight - masimum height of the media player
 * @param isDefaultControlEnabled - user can uses default controls
 * @param isAutoPlayEnabled - the video starts automatically after the page loading
 * @param isLoopEnabled - after the video ends it play from the begin automatically
 * @param isVideoMute - video starts without sound
 * 
 * @returns JSX
 */

const MediaPlayer: FC<MediaPlayerProps> = ({
        media,
        poster,
        preload,
        maxWidth,
        maxHeight,
        isAppMobile,
        isDefaultControlEnabled,
        isAutoPlayEnabled,
        isLoopEnabled,
        isVideoMute
    }) => {

    const [isPaused, setIsPaused] = useState<boolean | undefined>(!isAutoPlayEnabled);
    const [isMuted, setIsMuted] = useState<boolean | undefined>(isVideoMute);
    const [isVolumeHigh, setIsVolumeHigh] = useState<boolean | undefined>(false);
    const [volumeLevel, setVolumeLevel] = useState<string | undefined>(_INITIAL_VOLUME_LEVEL);
    const [mediaCurrentTime, setMediaCurrentTime] = useState<number>(0);
    const [mediaDuration, setMediaDuration] = useState<number>(0);
    const [playbackRate, setPlaybackRate] = useState<number>(1);
    const [isTheater, setIsTheater] = useState<boolean | undefined>(false);
    const [isFullScreen, setIsFullScreen] = useState<boolean | undefined>(false);
    const [isPlayOverlayOpen, setIsPlayOverlayOpen] = useState<boolean>(!isAutoPlayEnabled);
    const containerRef = useRef<HTMLDivElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);

    const playPauseHandler = () => {

        if (!videoRef || !videoRef?.current) return;
        const isVideoPaused = videoRef.current.paused;

        if (isVideoPaused) {

            videoRef.current.play();
            setIsPaused(false);

            return;
        }

        videoRef.current.pause();
        setIsPaused(true);
    };

    const muteVolumeToggleHandler = useCallback(() => {

        if (!videoRef || !videoRef?.current) return;
        videoRef.current.muted = !isMuted;
        setIsMuted(!isMuted);

    }, [isMuted]);

    const volumeSliderChangeHandler = (newVolume: { x: number, y: number }) => {

        const volumeLevel = newVolume.x;
        if (isNaN(volumeLevel)) return;
        const isMuted = (volumeLevel === 0);

        if (volumeLevel >= 0.5) setIsVolumeHigh(true);
        if (volumeLevel < 0.5) setIsVolumeHigh(false);
        setVolumeLevel(volumeLevel.toString());
        if (!videoRef || !videoRef?.current) return;
        videoRef.current.volume = volumeLevel;
        videoRef.current.muted = isMuted;
        setIsMuted(isMuted);
    };

    const playbackRateHandler = () => {

        if (!videoRef || !videoRef?.current) return;
        let newPlayBackRate = videoRef.current.playbackRate + 0.25;

        if (newPlayBackRate > 2) newPlayBackRate = 0.25;
        videoRef.current.playbackRate = newPlayBackRate;
        setPlaybackRate(newPlayBackRate);
    };

    const theaterToggleHandler = () => {

        if (!videoRef || !videoRef?.current) return;
        setIsTheater(prevState => !prevState);
    };

    const fullScreenToggleHandler = () => {

        if (!containerRef || !containerRef?.current) return;

        if (document.fullscreenElement == null) {

            setIsFullScreen(true);
            containerRef.current.requestFullscreen();

            return;
        }

        setIsFullScreen(false);
        document.exitFullscreen();
    };

    const skipMediaTimeHandler = (duration: number) => {

        if (!videoRef || !videoRef?.current) return;
        videoRef.current.currentTime += duration;
    };

    const setMediaTimeHandler = (duration: number) => {

        if (!videoRef || !videoRef?.current) return;
        videoRef.current.currentTime = duration;
    };

    const playOverlayHandler = () => {

        playPauseHandler();
        setIsPlayOverlayOpen(false);
    };

    useEffect(() => {

        if (!videoRef || !videoRef?.current) return;
        videoRef.current.volume = (+_INITIAL_VOLUME_LEVEL);
        videoRef.current.muted = !!isVideoMute;
        setVolumeLevel(_INITIAL_VOLUME_LEVEL);

    }, [isVideoMute]);

    useEffect(() => {

        if (!videoRef || !videoRef?.current) return;
        const videoRefCurrSupp = videoRef.current;

        const saveMediaDuration = () => setMediaDuration(videoRefCurrSupp.duration);
        videoRefCurrSupp.addEventListener('loadeddata', saveMediaDuration);

        return () => videoRefCurrSupp.removeEventListener('loadeddata', saveMediaDuration);

    }, [videoRef]);

    useEffect(() => {

        if (!videoRef || !videoRef?.current) return;
        const videoRefCurrSupp = videoRef.current;

        const saveCurrentVideoTime = () => setMediaCurrentTime(videoRefCurrSupp.currentTime);
        videoRefCurrSupp.addEventListener('timeupdate', saveCurrentVideoTime);

        return () => videoRefCurrSupp.removeEventListener('timeupdate', saveCurrentVideoTime);

    }, [videoRef]);

    useEffect(() => {

        if (!videoRef || !videoRef?.current) return;
        const videoRefCurrSupp = videoRef.current;

        const pauseVideo = () => setIsPaused(true);
        videoRefCurrSupp.addEventListener('ended', pauseVideo);

        return () => videoRefCurrSupp.removeEventListener('ended', pauseVideo);

    }, [videoRef]);

    // useEffect(() => {

    //     const keyShortCutsHandler = (event: KeyboardEvent): void | undefined => {

    //         const lowerKey = event.key.toLowerCase();
    //         const lowerTargetName = event.target.tagName.toLowerCase();

    //         if (lowerTargetName === 'input') return;

    //         if ( (lowerKey === 'k' || lowerKey === ' ') && lowerTargetName !== 'button' ) {
    //             playPauseHandler();
    //         }

    //         if (lowerKey === 't') theaterToggleHandler();
    //         if (lowerKey === 'f') fullScreenToggleHandler();
    //         if (lowerKey === 'm') muteVolumeToggleHandler();
    //         if (lowerKey === 'arrowleft') skipMediaTimeHandler(-5);
    //         if (lowerKey === 'arrowright') skipMediaTimeHandler(5);
    //     };

    //     document.addEventListener('keydown', keyShortCutsHandler);

    //     return () => document.removeEventListener('keydown', keyShortCutsHandler);

    // }, [muteVolumeToggleHandler]);

    useEffect(() => {

        const videoPlayers = document.getElementsByTagName('video')
        for (let i = 0; i < videoPlayers.length; i++) {
            videoPlayers[i].load()
        }
    }, [media]);


    const formattedMediaDuration = formatDuration(mediaDuration);
    const formattedMediaCurrentTime = formatDuration(mediaCurrentTime);
    const formattedPlaybackRate = `${playbackRate}x`;

    return (
        <Container
            isTheater={isTheater}
            isFullScreen={isFullScreen}
            isAppMobile={isAppMobile}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            ref={containerRef}
        >
            <VideoContainer onClick={playPauseHandler}>
                <video
                    ref={videoRef}
                    poster={poster}
                    preload={preload}
                    controls={isDefaultControlEnabled}
                    autoPlay={isAutoPlayEnabled}
                    loop={isLoopEnabled}
                    muted={isVideoMute}
                >
                    <source
                        src={media.src}
                        type={media.type}
                    />

                    Your browser does not support the video tag.
                </video>
            </VideoContainer>

            {!isPlayOverlayOpen && (
                <Controls isPaused={isPaused}>
                    <VideoProgress
                        videoCurrentTime={mediaCurrentTime}
                        maxVideoDuration={mediaDuration}
                        onSeekerChange={setMediaTimeHandler}
                    />

                    <ButtonsBar>
                        <LeftControls>
                            <BlurTogglerButton
                                isOpen={isPaused}
                                onClick={playPauseHandler}
                                assetOpen={<PlaySVG />}
                                assetClose={<PauseSVG />}
                            />
                            
                            <Volume>
                                <Button onClick={muteVolumeToggleHandler} >
                                    {isMuted && <MutedSVG />}
                                    {!isMuted && isVolumeHigh && <VolumeHighSVG />}
                                    {!isMuted && !isVolumeHigh && <VolumeLowSVG />}
                                </Button>

                                <Slider
                                    axis='x'
                                    x={+(volumeLevel ?? 0)}
                                    xmin={0}
                                    xmax={1}
                                    xstep={0.01}
                                    onChange={volumeSliderChangeHandler}
                                    styles={{
                                        track: {
                                            width: "100%",
                                            height: "3px",
                                            borderRadius: "0",
                                            backgroundColor: `${colors.lightSimpleGrey}`,
                                            cursor: "pointer"
                                        },
                                        active: {
                                            backgroundColor: `${colors.white}`
                                        },
                                        thumb: {
                                            width: "12px",
                                            height: "12px",
                                            borderRadius: "50%",
                                            backgroundColor: `${colors.white}`
                                        }
                                    }}
                                />
                            </Volume>

                            <Time>
                                <time>{formattedMediaCurrentTime}</time>
                                <span> / </span>
                                <time>{formattedMediaDuration}</time>
                            </Time>
                        </LeftControls>

                        <RightControls>
                            <Button onClick={playbackRateHandler}>
                                {formattedPlaybackRate}
                            </Button>

                            {/* <BlurTogglerButton
                                isOpen={isTheater}
                                onClick={theaterToggleHandler}
                                assetOpen={<TheaterCloseSVG />}
                                assetClose={<TheaterSVG />}
                            /> */}

                            <BlurTogglerButton
                                isOpen={isFullScreen}
                                onClick={fullScreenToggleHandler}
                                assetOpen={<FullScreenCloseSVG />}
                                assetClose={<FullScreenSVG />}
                            />
                        </RightControls>
                    </ButtonsBar>
                </Controls>
            )}

            {isPlayOverlayOpen && (
                <PlayButtonOverlay>
                    <OverlayButton onClick={playOverlayHandler}>
                        <PlayOverlayButtonSVG />
                    </OverlayButton>
                </PlayButtonOverlay>
            )}
        </Container>
    );
};

export default MediaPlayer;
