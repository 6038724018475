import { FC } from 'react';

//hooks
import useHasRole from 'hooks/useHasRole';
import useHasPermission from 'hooks/useHasPermission';

//types
import { ProductSubscriptionsProps } from './ProductSubscriptions.component.d';

//components
import ProductSubscription from './ProductSubscription';

//style
import { ProductSubscriptionsBox } from './style/ProductSubscriptions.component.style';

const ProductSubscriptions: FC<ProductSubscriptionsProps> = ({ productSubscriptions, updateLocalUser }) => {
	
	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isAdmin = useHasRole('ADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');
	const isAmministrazione = useHasRole('CONTABILITÀ');
	const isAuthor = useHasRole('AUTORE');
	const isPersonalTrainer = useHasRole('PERSONAL TRAINER');
	const isSupervisoreAutostrada = useHasRole('SUPERVISORE_AUTOSTRADA');
	const isAdminAutostrada = useHasRole('ADMIN_AUTOSTRADA');
	const isAssistentePersonale = useHasRole('ASSISTENTE-PERSONALE');

	const canUpdateSubscriptions = isSuperAdmin || isAdmin || isMiniAdmin|| isAdminAutostrada || isSupervisoreAutostrada;
	
	return (
		<ProductSubscriptionsBox>
			{productSubscriptions
				?.filter((sub) => sub.productService?.status !== 'CREATED')
				.filter((sub) => !sub.deletionDate)
				.map((productSubscription, index) => {
					return (
						<ProductSubscription
							key={index}
							productSubscription={productSubscription}
							updateLocalUser={updateLocalUser}
							isClickable={canUpdateSubscriptions}
						></ProductSubscription>
					);
				})}
		</ProductSubscriptionsBox>
	);
};

export default ProductSubscriptions;
