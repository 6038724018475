import { useEffect, useState } from 'react';

//hooks
import useHasRole from 'hooks/useHasRole';
import useHasPermission from 'hooks/useHasPermission';

//types
import { BadgesProps } from './Badges.component.d';

//style
import { BadgesBox } from './style/Badges.component.style';

//components
import Badge from './Badge/Badge.component';

//assets
import { ReactComponent as BannedIcon } from './assets/bannedIcon.svg';
import { ReactComponent as CheaterIcon } from './assets/cheaterIcon.svg';
import { ReactComponent as ProblematicIcon } from './assets/problematicIcon.svg';

//utils
import { addUserBadge, removeUserBadge } from './services/Badges.services';

const _BADGES = [
	{ id: 1, name: 'BANNATO' },
	{ id: 3, name: 'Cliente Problematico' },
	{ id: 5, name: 'TRUFFATORE' },
];

const Badges: React.FC<BadgesProps> = ({ userId, badges }) => {
	//TO-DO: leggere badge attuali utente
	const [banned, setBanned] = useState<boolean>(badges.some((m) => m.id === 1));
	const [cheater, setCheater] = useState<boolean>(badges.some((m) => m.id === 5));
	const [problematic, setProblematic] = useState<boolean>(badges.some((m) => m.id === 3));

	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isAdmin = useHasRole('ADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');
	const isAmministrazione = useHasRole('CONTABILITÀ');
	const isAuthor = useHasRole('AUTORE');
	const isPersonalTrainer = useHasRole('PERSONAL TRAINER');
	const isSupervisoreAutostrada = useHasRole('SUPERVISORE_AUTOSTRADA');
	const isAdminAutostrada = useHasRole('ADMIN_AUTOSTRADA');
	const isAssistentePersonale = useHasRole('ASSISTENTE-PERSONALE');
	
	const canUpdateUserRole = isSuperAdmin || isAdmin || isMiniAdmin || isAdminAutostrada || isSupervisoreAutostrada;
	
	//TO-DO: click handler corretti quando disponibili
	useEffect(() => {
		setBanned(badges.some((m) => m.id === 1));
		setCheater(badges.some((m) => m.id === 5));
		setProblematic(badges.some((m) => m.id === 3));
	}, [badges]);

	const bannedClickHandler = async () => {
		if (!canUpdateUserRole) return;
		try {
			if (!banned) {
				await addUserBadge(userId, 1);
			}
			if (banned) {
				await removeUserBadge(userId, 1);
			}
			setBanned((banned) => !banned);
		} catch (error: any) {
			throw error;
		}
	};

	const cheaterClickHandler = async () => {
		if (!canUpdateUserRole) return;
		try {
			if (!cheater) {
				await addUserBadge(userId, 5);
			}
			if (cheater) {
				await removeUserBadge(userId, 5);
			}
			setCheater((cheater) => !cheater);
		} catch (error: any) {
			throw error;
		}
	};

	const problematicClickHandler = async () => {
		if (!canUpdateUserRole) return;
		try {
			if (!problematic) {
				await addUserBadge(userId, 3);
			}
			if (problematic) {
				await removeUserBadge(userId, 3);
			}
			setProblematic((problematic) => !problematic);
		} catch (error: any) {
			throw error;
		}
	};

	return (
		<BadgesBox>
			<Badge
				title='BANNATO'
				key={userId + '_banned_badge'}
				name={'banned'}
				BadgeIcon={BannedIcon}
				isSwitchedOn={banned}
				clickHandler={bannedClickHandler}
				isClickable={canUpdateUserRole}
			/>
			<Badge
				title='TRUFFATORE'
				key={userId + '_cheater_badge'}
				name={'cheater'}
				BadgeIcon={CheaterIcon}
				isSwitchedOn={cheater}
				clickHandler={cheaterClickHandler}
				isClickable={canUpdateUserRole}
			/>
			<Badge
				title='PROBLEMATICO'
				key={userId + '_problematic_badge'}
				name={'problematic'}
				BadgeIcon={ProblematicIcon}
				isSwitchedOn={problematic}
				clickHandler={problematicClickHandler}
				isClickable={canUpdateUserRole}
			/>
		</BadgesBox>
	);
};

export default Badges;
