import colors from 'style/palette/palette';
import styled from 'styled-components';

export const PointsWithdrawBox = styled.div`
	padding: 1rem;

	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

export const WithdrawalOptions = styled.div`
	display: flex;
	gap: 1rem;
`;

export const Withdraw = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1rem;
`;

export const Instructions = styled.div`
	display: flex;
	flex-direction: column;
	white-space: pre-wrap;
`;

export const Attachment = styled.a`
	padding: 0.25rem;
	border-radius: 0.25rem;
	color: white;
	background-color: ${colors.blueGrey};
`;
