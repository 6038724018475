import { callDELETE, callGET, callPOST, callPUT } from 'network/network';
import { Testimonial } from 'types/Testimonial';

import { getBrand } from 'configs/brandMap/brandMap.helper';

const _TESTIMONIALS_PATH = 'v1/testimonials';
const _TESTIMONIALS_WEIGHTS_PATH = 'v1/testimonials/weights';
const _TESTIMONIAL_PROFILE_PIC_PATH = 'v1/testimonials/{id}/profilepicture';
const _TESTIMONIAL_VIDEO_PATH = 'v1/testimonials/{id}/video';
const _TESTIMONIAL_VIDEO_COVER_PATH = 'v1/testimonials/{id}/videocover';


export const getTestimonials = async (brandCode: string) => {
	try {
		const response = await callGET(_TESTIMONIALS_PATH, true, { brandCode });

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const createTestimonial = async (newTestimonial: Partial<Testimonial>) => {
	try {
		const response = await callPOST(_TESTIMONIALS_PATH, newTestimonial, true);

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const putTestimonialsWeights = async (testimonialsData: Partial<Testimonial>[]) => {
	const brand = getBrand();
	if (!brand) return;
	try {
		const response = await callPUT(_TESTIMONIALS_WEIGHTS_PATH, testimonialsData, true, { brandCode: brand.code });

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const putTestimonial = async (updatedTestimonial: Partial<Testimonial>) => {
	const brand = getBrand();
	if (!brand) return;
	try {
		const response = await callPUT(_TESTIMONIALS_PATH, updatedTestimonial, true, { brandCode: brand.code });

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const deleteTestimonialById = async (testimonialId: number) => {
	try {
		const response = await callDELETE(_TESTIMONIALS_PATH + '/' + testimonialId, {}, true);

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const uploadProfilePic = async (testimonialId: number, profilePicUrl: string) => {
	const brand = getBrand();
	if (!brand) return;
    try {
        const response = await callPUT(_TESTIMONIAL_PROFILE_PIC_PATH.replace(/{id}/g,
        testimonialId.toString()), {}, true, { url: profilePicUrl, brandCode: brand.code});

        if (response === undefined || !response) {
            throw new Error();
        }

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const uploadVideo = async (testimonialId: number, videoUrl: string) => {
	const brand = getBrand();
	if (!brand) return;
    try {
        const response = await callPUT(_TESTIMONIAL_VIDEO_PATH.replace(/{id}/g,
        testimonialId.toString()), {}, true, { url: videoUrl, brandCode: brand.code});

        if (response === undefined || !response) {
            throw new Error();
        }

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const uploadVideoCover = async (testimonialId: number, videoCoverUrl: string) => {
	const brand = getBrand();
	if (!brand) return;
    try {
        const response = await callPUT(_TESTIMONIAL_VIDEO_COVER_PATH.replace(/{id}/g,
        testimonialId.toString()), {}, true, { url: videoCoverUrl, brandCode: brand.code});

        if (response === undefined || !response) {
            throw new Error();
        }

        return response;
    } catch (error: any) {
        throw error;
    }
};