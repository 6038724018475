export const stringCodingToConsole = (stringToCode: string) => {

    const suppArray = stringToCode.split('');
    const finalArray = suppArray.map((el: string) => el.charCodeAt(0))

    console.log(`CODED STRING "${stringToCode}":`, finalArray.join(''));
};

export const formattedCurrency = (numberToFormat: number | undefined, numberOfDecimals?: number) => {

    const decimals = numberOfDecimals ?? 2;
    if (numberToFormat === undefined || ! numberToFormat) return '0';
    return numberToFormat.toLocaleString('it-IT', {
        
        minimumFractionDigits: decimals,      
        maximumFractionDigits: decimals,
    })
};
