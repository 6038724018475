import { FC } from 'react';
import Slider from 'react-input-slider';

// types
import { VideoProgressProps } from './VideoProgress.d';

// style
import colors from 'style/palette/palette';
import {
    Container,
    SliderBox
} from './style/VideoProgress.style';


/**
 * Video progress bar
 * 
 * @author Alessio Grassi
 * 
 * @param videoCurrentTime - current time of played video
 * @param maxVideoDuration - total time of played video
 * @param onSeekerChange - set progress value function
 * 
 * @returns JSX
 */

const VideoProgress: FC<VideoProgressProps> = ({
        videoCurrentTime,
        maxVideoDuration,
        onSeekerChange
    }) => {

    const seekerInputChangeHandler = (newValue: { x: number, y: number }) => {

        const newInputValue = newValue.x;
        onSeekerChange(newInputValue);
    };

    const abbreviatedCurrentTime = Math.floor(videoCurrentTime);
    const abbreviatedMaxDuration = Math.floor(maxVideoDuration);

    return (
        <Container>
            <SliderBox>
                <Slider
                    axis='x'
                    x={abbreviatedCurrentTime}
                    xmin={0}
                    xmax={abbreviatedMaxDuration}
                    xstep={1}
                    onChange={seekerInputChangeHandler}
                    styles={{
                        track: {
                            width: "100%",
                            height: "100%",
                            borderRadius: "0",
                            backgroundColor: `${colors.lightSimpleGrey}`,
                            cursor: "pointer"
                        },
                        active: {
                            backgroundColor: `${colors.red}`
                        },
                        thumb: {
                            width: "1em",
                            height: "1em",
                            backgroundColor: `${colors.red}`
                        }
                    }}
                />
            </SliderBox>
        </Container>
    );
};

export default VideoProgress;
