import React, { FC, useEffect, useState } from 'react';

//types
import { TestimonialTitleProps } from './TestimonialTitle.component.d';

//assets

import { ReactComponent as ModifyIcon } from '../assets/modifyIcon.svg';

//style
import { NodeTitleBox, TitleModify } from './style/TestimonialTitle.component.style';
import { Testimonial } from 'types/Testimonial';

const TestimonialTitle: FC<TestimonialTitleProps> = ({ 
	testimonialId, 
	testimonialTitle, 
	updateLocalTestimonial, 
	putRemoteTestimonial 
}) => {
	const [isEnabled, setIsEnabled] = useState(false);
	const [isOnHover, setIsOnHover] = useState(false);
	const [localTitle, setLocalTitle] = useState('');
	const [oldTitle, setOldTitle] = useState('');

	const onMouseOverHandler = () => {
		setIsOnHover(true);
	};

	const onMouseOutHandler = () => {
		setIsOnHover(false);
	};

	const onClickHandler = () => {
		setIsEnabled(true);
	};

	const updateRemoteTestimonial = async () => {
		try {
			await putRemoteTestimonial(testimonialId, {title: localTitle})
		} catch (error) {
			
		}
	}

	const onKeyDownHandler = async (event: React.KeyboardEvent<HTMLInputElement>) => {
		const code = event.code;

		if (code === 'Enter' || code === 'NumpadEnter') {
			updateLocalTestimonial(testimonialId, {title: localTitle} as Testimonial)
			event.currentTarget.blur();
		}
		if (code === 'Escape') {
			if (oldTitle) setLocalTitle(oldTitle);
			event.currentTarget.blur();
		}
	};

	const onFocusHandler = (event: React.FocusEvent<HTMLInputElement>) => {
		setOldTitle(event.currentTarget.value);
		event.currentTarget.select();
	};

	const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
		if (localTitle !== testimonialTitle) {
			updateLocalTestimonial(testimonialId, {title: localTitle} as Testimonial);
			updateRemoteTestimonial();
		}
	};

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		if(event.currentTarget.value) setLocalTitle(event.currentTarget.value);
	};

	useEffect(() => {
		if (testimonialTitle) setLocalTitle(testimonialTitle);
	}, [testimonialTitle]);

	return (
		<>
			<NodeTitleBox
				type='text'
				// disabled={!isEnabled}
				value={localTitle}
				size={localTitle ? localTitle.length+5 : 10}
				onMouseOver={onMouseOverHandler}
				onMouseOut={onMouseOutHandler}
				onClick={onClickHandler}
				onChange={onChangeHandler}
				onKeyDown={onKeyDownHandler}
				onBlur={onBlurHandler}
				onFocus={onFocusHandler}
			/>
			{isOnHover && (
				<TitleModify>
					<ModifyIcon />
				</TitleModify>
			)}
		</>
	);
};

export default TestimonialTitle;
