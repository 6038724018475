import { callPUT } from 'network/network';

const _USER_STATS_PATH = 'v1/users/stats';

export const putUserNotes = async (userId: number, notes: string) => {
	try {
		const response = await callPUT(_USER_STATS_PATH, { id: userId, notes: notes }, true);

		return response;
	} catch (error: any) {
		throw error;
	}
};
