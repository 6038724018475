import { FC, useEffect, useMemo, useState } from 'react';

//hooks
import useAppSelector from 'hooks/useAppSelector';
import useHasRole from 'hooks/useHasRole';
import useHasPermission from 'hooks/useHasPermission';

//types
import { TitleBreadCrumbSettings } from 'components/DashboardTitleSection/DashboardTitleSection.component.d';
import { Level } from 'types/Level';
import { TabSelectorItem, TabSelectorSettings } from 'components/TabSelector/TabSelector.component.d';

//components
import DashboardTitleSection from 'components/DashboardTitleSection';
import LevelsTable from './LevelsTable/LevelsTable.component';
import TabSelector from 'components/TabSelector';

//utils
import { putLevel } from 'services/level.services';

//assets
import { ReactComponent as SearchIconSVG } from './assets/searchIcon.svg';
import { ReactComponent as ClearButtonIconSVG } from './assets/clearButtonIcon.svg';
import { ReactComponent as AddBoxIcon } from './assets/addBoxIcon.svg';
import { ReactComponent as VisibleON } from './assets/visibleON.svg';
import { ReactComponent as VisibleOFF } from './assets/visibleOFF.svg';


//style
import { 
	Page, 
	SearchLevelsBox, 
	SearchInput, 
	SearchClearButton, 
	SearchButton, 
	AddLevel,
	NotVisibleFilterButton,
	NotVisibleFilterInput,
} from './style/Levels.component.style';
import { BaseContentCard } from 'style/wrappers/wrappers';
import useAppDispatch from 'hooks/useAppDispatch';
import { updateLevel } from 'redux/actions/actions-global';
import AddLevelModal from './AddLevelModal';
import { enableAppMain } from 'redux/slices/slice-app-main';
import { constants } from 'http2';

const LevelsPage: FC = () => {
	const [searchInput, setSearchInput] = useState<string>('');
	const [searchInputValue, setSearchInputValue] = useState<string>('');
	const [localLevelsList, setLocalLevelsList] = useState<Level[]>([]);
	const [tabSelectorValue, setTabSelectorValue] = useState<string>('');
	const [isAddLevelModalVisible, setIsAddLevelModalVisible] = useState(false);
	const [showHiddenFilter, setShowHiddenFilter] = useState<boolean>(false);

	const isAdmin = useHasRole('ADMIN');
	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');
	const isAuthor = useHasRole('AUTORE');
	const isAnimator = useHasRole('ANIMATORE');
	const isCoach = useHasRole('COACH');

	// const [isLoading, setIsLoading] = useState(true);

	const levelsList = useAppSelector((state) => state.global.allLevels) as Level[];
	const canCreateLevel = useHasPermission('CREATE_LEVEL_LDL');

	const dispatch = useAppDispatch();

	const addLevelCLickHandler = () => {
		setIsAddLevelModalVisible(true);
	};

	const closeAddLevelModal = () => {
		setIsAddLevelModalVisible(false);
		dispatch(enableAppMain());
	};

	const clearSearchHandler = () => {
		setSearchInput('');
		setSearchInputValue('');
	};

	const searchChangeHandler = (searchValue: any) => {
		setSearchInputValue(searchValue.target.value);
	};

	const searchKeyHandler = (event: any) => {
		if (event.keyCode === 27) {
			clearSearchHandler();
		}
		if (event.keyCode === 13) {
			setSearchInput(event.target.value);
		}
	};

	const searchButtonHandler = () => {};

	const handleShowHiddenFilterClick = () => {
		setShowHiddenFilter((prevState) => !prevState);
	};

	const putUpdateLevel = async (updatedLevel: Partial<Level>) => {
		try {
			await putLevel(updatedLevel);
			dispatch(updateLevel(levelsList, updatedLevel));
		} catch (error) {}
	};

	useEffect(() => { 
		var newData: any = []; // Create a new array

		if (!levelsList) return;
		levelsList.forEach((item) => {
		if (!newData.some((newItem: any) => newItem.pathLevel === item.pathLevel)) {
			newData.push(item);
		}
		});
		setLocalLevelsList(newData?.filter((p: any) => p.description.toLowerCase().includes(searchInput.toLowerCase())));
	}, [levelsList, searchInput, showHiddenFilter]);

	useEffect(() => {
		//setLocalLevelsList(levelsList); ///// !!!!!!! RIPRISTINARE?
	}, [levelsList]);

	//leggere localStorage per ultimo ProductType selezionato
	useEffect(() => {
		const tabSelectorValue = localStorage.getItem('tabSelectorValue');
		setTabSelectorValue(tabSelectorValue ? tabSelectorValue : '');
	}, []);

	const table = useMemo(() => {
		if (localLevelsList !== undefined)
			return (
				<LevelsTable
					data={localLevelsList}
					putLevel={putUpdateLevel}
				/>
			);
	}, [localLevelsList]);

	const isSearchButtonVisible: boolean = searchInput !== '';
	const breadCrumbSettings: TitleBreadCrumbSettings = { subSectionText: 'Gestione Traguardi' };

	const tabItems: TabSelectorItem[] = [
		{
			title: 'Traguardi',
			value: 'levels',
		}
	];

	const tabSettings: TabSelectorSettings = { tabItems: tabItems, handleSelection: setTabSelectorValue, display: true };

	if (!isAdmin && !isSuperAdmin && !isMiniAdmin && !isAuthor && !isCoach) return <></>;

	return (
		<>
			<Page>
				<DashboardTitleSection breadCrumbSettings={breadCrumbSettings}>
					{/* <NotVisibleFilterButton onClick={handleShowHiddenFilterClick}>
						<NotVisibleFilterInput
							id='showhiddenfilter'
							type={'checkbox'}
							checked={showHiddenFilter}
						/>
						<VisibleOFF /> Mostra nascosti
					</NotVisibleFilterButton> */}
					{canCreateLevel && (
						<AddLevel onClick={addLevelCLickHandler}>
							<AddBoxIcon /> Nuovo Traguardo
						</AddLevel>
					)}
					<SearchLevelsBox>
						<SearchInput
							onKeyDown={searchKeyHandler}
							onChange={searchChangeHandler}
							value={searchInputValue}
						/>

						{isSearchButtonVisible && (
							<SearchClearButton onClick={clearSearchHandler}>
								<ClearButtonIconSVG />
							</SearchClearButton>
						)}

						<SearchButton onClick={searchButtonHandler}>
							<SearchIconSVG />
						</SearchButton>
					</SearchLevelsBox>
				</DashboardTitleSection>
				<TabSelector tabSelectorSettings={tabSettings}></TabSelector>
				<BaseContentCard>
					{localLevelsList === undefined && <div>Loader...</div>}

					{table}
				</BaseContentCard>
			</Page>
			{isAddLevelModalVisible && <AddLevelModal onCloseButtonModal={closeAddLevelModal} />}
		</>
	);
};

export default LevelsPage;
