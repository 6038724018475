// types
import { AppDispatch } from '../store';
import { Brand } from 'types/Brand';

// slice actions
import {
    setIsAppMobile as actionSetIsAppMobile,
    setBrand as actionSetBrand,
} from '../slices/slice-ui';


export const setIsAppMobile = (isMobile: boolean) => {

    return async (dispatch: AppDispatch) => {
        dispatch( actionSetIsAppMobile(isMobile) );
    };
};

export const setBrand = (brand: Brand | undefined) => {

    return async (dispatch: AppDispatch) => {
        dispatch( actionSetBrand(brand) );
    };
};