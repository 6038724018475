import { FC, useEffect, useState } from 'react';

//hooks
import useHasRole from 'hooks/useHasRole';
import useHasPermission from 'hooks/useHasPermission';

//types
import { MissionsProps } from './Missions.component.d';
import Mission from './Mission/Mission.component';

//style
import { MissionsBox } from './style/Missions.component.style';

//assets
import { ReactComponent as FacebookIcon } from './assets/facebookIcon.svg';
import { ReactComponent as AppIcon } from './assets/appIcon.svg';
import { ReactComponent as BetfairIcon } from './assets/betfairIcon.svg';

//utils
import { putUserMission } from './services/Missions.services';

const Missions: FC<MissionsProps> = ({ userId, missions }) => {
	const [facebook, setFacebook] = useState<boolean>(missions.some((m) => m.mission.name === 'Facebook' && m.completionDate));
	const [app, setApp] = useState<boolean>(missions.some((m) => m.mission.name === 'App' && m.completionDate));
	const [betfair, setBetfair] = useState<boolean>(missions.some((m) => m.mission.name === 'Betfair' && m.completionDate));

	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isAdmin = useHasRole('ADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');
	const isAmministrazione = useHasRole('CONTABILITÀ');
	const isAuthor = useHasRole('AUTORE');
	const isPersonalTrainer = useHasRole('PERSONAL TRAINER');

	const canUpdateMission = isSuperAdmin || isAdmin;

	const betfairClickHandler = async () => {
		if (!canUpdateMission) return;
		try {
			await putUserMission(userId, 4, !betfair);
			setBetfair((betfair) => !betfair);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		setFacebook(missions.some((m) => m.mission.name === 'Facebook' && m.completionDate));
		setApp(missions.some((m) => m.mission.name === 'App' && m.completionDate));
		setBetfair(missions.some((m) => m.mission.name === 'Betfair' && m.completionDate));
	}, [missions]);

	return (
		<MissionsBox>
			<Mission
				title='FACEBOOK'
				key={userId + '_facebook_mission'}
				name='facebook'
				MissionIcon={FacebookIcon}
				isCompleted={facebook}
				clickHandler={undefined}
			/>
			<Mission
				title='APP'
				key={userId + '_app_mission'}
				name='app'
				MissionIcon={AppIcon}
				isCompleted={app}
				clickHandler={undefined}
			/>
			<Mission
				title='BETFAIR'
				key={userId + '_betfair_mission'}
				name='betfair'
				MissionIcon={BetfairIcon}
				isCompleted={betfair}
				clickHandler={betfairClickHandler}
				isClickable={canUpdateMission}
			/>
		</MissionsBox>
	);
};

export default Missions;
