import React, { FC, useCallback, useEffect } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

//hooks
import { useState } from 'react';
import useHasRole from 'hooks/useHasRole';
import useAppDispatch from 'hooks/useAppDispatch';

//types
import { Course, CourseNode, CourseLesson } from 'types/Course';
import { Product } from 'types/Product';
import { ContentContainerProps } from './ContentContainer.component.d';

//components
import ProductPage from 'pages/ProductPage/ProductPage.component';
import ContentNode from './ContentNode';

//assets
import { ReactComponent as AddBoxIcon } from './assets/addBoxIcon.svg';

//utils
import { addCourse, deleteCourseNode, postCourseNodeLesson, putCourseContent } from 'services/course.services';
import { deleteNodeAndDescendants, rebasePositions } from './ContentContainer.helper';
import { getEnvironmentTestBaseUrl } from 'configs/config.helper';

//style
import { AddNode, ContentContainerBox, ContentNodesBox, SaveButton, ButtonRow } from './style/ContentContainer.component.style';

const ContentContainer: FC<ContentContainerProps> = ({ product, putProduct }) => {
	const [remoteNodes, setRemoteNodes] = useState<CourseNode[]>([]);
	const [localNodes, setLocalNodes] = useState<CourseNode[]>([]);
	const [isContentModified, setIsContentModified] = useState(false);
	const [isPreviewVisible, setIsPreviewVisible] = useState<boolean>(false);
	const [newProduct, setNewProduct] = useState<Product>(product);

	const isAdmin = useHasRole('ADMIN');
	const isSuperAdmin = useHasRole('SUPERADMIN');
	const userHasAdminRole = isAdmin || isSuperAdmin;

	const dispatch = useAppDispatch();

	const addNode = async (node: Partial<CourseNode>) => {
		try {
			const content = localNodes ? [...localNodes, node] : [node];
			const response = await putCourseContent(content);
			setRemoteNodes(response);
		} catch (error) {
			console.log(error);
		}
	};

	const createCourse = async () => {
		try {
			const newCourse: Course = await addCourse(product.name);
			newCourse.content = [];
			await putProduct({ id: product.id, course: newCourse });
			return newCourse.id;
		} catch (error) {
			console.log(error);
		}
	};

	const deleteNode = async (id: number) => {
		let newLocalNodes = [...localNodes];
		deleteNodeAndDescendants(newLocalNodes, id);
		try {
			await deleteCourseNode(id);
			setRemoteNodes(newLocalNodes);
		} catch (error) {
			console.log(error);
		}
	};

	const addLessonToNode = async (node: Partial<CourseNode>) => {
		try {
			const response = await postCourseNodeLesson({ ...node, lesson: {} as CourseLesson });
			console.log('response', response);

			const newRemoteNodes = [...remoteNodes];

			const nodeIndex = remoteNodes.findIndex((n) => n.id === node.id);
			console.log('nodeIndex', nodeIndex);
			newRemoteNodes[nodeIndex] = response;
			// setRemoteNodes(newRemoteNodes);

			const response2 = await putCourseContent(newRemoteNodes);
			setRemoteNodes(response2);

		} catch (error) {
			console.log(error);
		}
	};

	const updateLocalNode = (nodeId: number, updatedNode: Partial<CourseNode>) => {
		setLocalNodes((prevState) =>
			prevState.map((node) => {
				if (node.id === nodeId) return { ...node, ...updatedNode };
				return node;
			})
		);
	};

	const reArrangePositions = () => {
		const newLocalNodes = localNodes;
		rebasePositions(newLocalNodes);
		setLocalNodes(newLocalNodes);
	};

	const addNodeHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
		if (!product.course) {
			const newCourseId = await createCourse();
			addNode({ courseId: newCourseId, title: 'Aggiungi Titolo', pos: 0 });
		}
		if (product.course) addNode({ courseId: product.course.id, title: 'Aggiungi Titolo', pos: localNodes.filter((n) => !n.parentId).length });
	};

	const saveContentHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
		const firstItem = localNodes.find(node => node.pos === 0 && node.parentId === undefined);
		if (firstItem) {		
			const existsChild = localNodes.some(node => node.parentId === firstItem.id);
		
			if (existsChild) {
				if (window.confirm('Salvare le modifiche al corso del prodotto ' + product.name + ' ?')) {
					try {
						const response = await putCourseContent(localNodes);
						setRemoteNodes(response);
					} catch (error) {
						console.log(error);
					}
				}			
			} else {
				const snackbarData: SnackbarOptions = {};
                snackbarData.type = 'error';
                snackbarData.message = "La prima lezione della prima sezione non può essere vuota!";
                dispatch(addSnackbar(snackbarData));
			}
		} else {
			const snackbarData: SnackbarOptions = {};
                snackbarData.type = 'error';
                snackbarData.message = "Deve esistere almeno una sezione con almeno una lezione!";
                dispatch(addSnackbar(snackbarData));
		}

		
	};

	const previewOpenHandler = () => setIsPreviewVisible(prevState => !prevState);

	const onSalesPageClickHandler = () => window.open(
		`${getEnvironmentTestBaseUrl(`app/reservedArea/product-page/${newProduct.type}/${newProduct.id}`).replace('backoffice.','')}`,
		'_blank',
		'noreferrer noopener'
	);

	useEffect(() => {
		setRemoteNodes(
			product.course?.content?.map((n) => {
				return {
					id: n.id,
					title: n.title,
					parentId: n.parentId,
					pos: n.pos,
					lesson: n.lesson,
					courseId: n.courseId,
				};
			})
		);
	}, [product.course?.content]);

	useEffect(() => {
		setLocalNodes(remoteNodes);
	}, [remoteNodes]);

	useEffect(() => {
		setIsContentModified(JSON.stringify(remoteNodes) !== JSON.stringify(localNodes));
	}, [localNodes, remoteNodes]);

	useEffect(() => {
		// console.log("LOCAL NODES:");
		// console.log(localNodes);
		reArrangePositions();
	}, [localNodes]);

	const moveNode = useCallback(
		(dragId: number, hoverId: number) => {
			// find the dragged and hovered nodes
			const dragNode = localNodes.find((n) => n.id === dragId) as CourseNode;
			const hoverNode = localNodes.find((n) => n.id === hoverId) as CourseNode;

			if (dragId !== hoverId && dragNode.parentId !== hoverNode.parentId) {
				//*************
				//move to a different parent
				//*************
				// console.log('different parent, hoverId: ', hoverId);

				// find the index of the new parent node
				const newParentId = hoverNode.parentId;
				// update the dragged node's parentId and pos properties IMMUTABILITY
				// const newLocalNodes = update(localNodes, {
				// 	[localNodes.indexOf(dragNode)]: {
				// 		parentId: { $set: newParentId },
				// 		pos: { $set: hoverNode.pos },
				// 	},
				// });
				// update the dragged node's parentId and pos properties
				const newLocalNodes = localNodes.map((n) => {
					if (n.id === dragId) return { ...n, parentId: newParentId, pos: hoverNode.pos };
					return n;
				});
				//update the pos property of the siblings
				const siblings = newLocalNodes.filter((n) => n.parentId === newParentId);
				siblings
					.sort((a, b) => a.pos - b.pos)
					.forEach((n, i) => {
						if (n.id !== dragId && n.pos >= hoverNode.pos) {
							console.log('sibling id: ', n.id, ' n.pos: ', n.pos);

							n.pos = n.pos + 1;
						}
					});
				//update the pos property of the old siblings
				const oldParentId = dragNode.parentId;
				const oldSiblings = newLocalNodes.filter((n) => n.parentId === oldParentId);
				oldSiblings.sort((a, b) => a.pos - b.pos).forEach((n, i) => (n.pos = i));

				// set the localNodes to the updated newLocalNodes array
				setLocalNodes(newLocalNodes);
			} else {
				//*************
				//move inside the same parent
				//*************

				// console.log('same parent, hoverId: ', hoverId);
				// find the index of the dragged and hovered nodes
				const dragIndex = localNodes.findIndex((n) => n.id === dragId);
				const hoverIndex = localNodes.findIndex((n) => n.id === hoverId);
				// update the pos property of the dragged node and its siblings IMMUTABILITY
				// const hoverParentId = localNodes[hoverIndex].parentId;
				// const newLocalNodes = update(localNodes, {
				// 	[dragIndex]: { pos: { $set: hoverNode.pos } },
				// 	[hoverIndex]: { pos: { $set: dragNode.pos } },
				// });
				// update the pos property of the dragged node and its siblings
				const newLocalNodes = localNodes.map((n) => {
					if (n.id === dragId) return { ...n, pos: hoverNode.pos };
					if (n.id === hoverId) return { ...n, pos: dragNode.pos };
					return n;
				});
				setLocalNodes(newLocalNodes);
			}
		},
		[localNodes]
	);

	const renderContentNode = useCallback(
		(node) => {
			return (
				<ContentNode
					key={node.id}
					node={node}
					product={product}
					nodes={localNodes}
					moveNode={moveNode}
					updateLocalNode={updateLocalNode}
					reArrangePositions={reArrangePositions}
					addNode={addNode}
					deleteNode={deleteNode}
					addLessonToNode={addLessonToNode}
				/>
			);
		},
		[localNodes, moveNode]
	);

	useEffect(() =>  {
		
		if (newProduct.course === undefined) return;

		const prodUpload: Product = {...newProduct, course: {
			content: localNodes,
			brands: newProduct.course.brands,
			id: newProduct.course.id,
			title: newProduct.course.title
		}}
		setNewProduct(prodUpload);
	}, [localNodes]);
	// console.log("PRODUCT:", product);
	// console.log("LN:", {...product,course: {content: localNodes}});
	
	return (
		<>
			<ContentContainerBox>
				<ButtonRow>
					{userHasAdminRole && (
						<SaveButton
							onClick={saveContentHandler}
							disabled={!isContentModified}
						>
							SALVA MODIFICHE
						</SaveButton>
					)}

					{(product.course !== undefined) && (
						<SaveButton
							onClick={previewOpenHandler}
						>
							{!isPreviewVisible && `ANTEPRIMA`}
							
							{isPreviewVisible && `MODIFICA`}
						</SaveButton>)
					}

					{(product.course !== undefined) && (
						<SaveButton
							onClick={onSalesPageClickHandler}
						>
							VAI ALLA PAGINA DEL PRODOTTO
						</SaveButton>)
					}
				</ButtonRow>

				{!isPreviewVisible && (
					<>
						<ContentNodesBox>
							{localNodes &&
								localNodes
									.filter((node) => !node.parentId)
									.sort((a, b) => {
										if (a.pos > b.pos) return 1;
										if (a.pos < b.pos) return -1;
										return 0;
									})
									.map((node) => renderContentNode(node))}
						</ContentNodesBox>

						<ButtonRow>
							{userHasAdminRole && (
								<SaveButton
									onClick={saveContentHandler}
									disabled={!isContentModified}
									style={{left: -203, position: 'relative'}}
								>
									SALVA MODIFICHE
								</SaveButton>
							)}
						</ButtonRow>

						{userHasAdminRole && (
							<AddNode onClick={addNodeHandler}>
								<AddBoxIcon /> Aggiungi Nodo
							</AddNode>
						)}
					</>
				)}

				{isPreviewVisible && (
					<ProductPage 
						product={{...product, course: {content: localNodes}}}
					/>
				)}
			</ContentContainerBox>

		</>
	);
};

export default ContentContainer;
