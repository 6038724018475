import styled from 'styled-components';

import colors from 'style/palette/palette';

export const FederatedBox = styled.div`
	display: flex;
	padding: 1rem;
	flex-direction: column;
	color: ${colors.white};
`;

export const FacebookImg = styled.img`
	height: 40px;
	border-radius: 50%;
	border: 2px solid ${colors.blue};
	cursor: pointer;
`;

export const FederatedName = styled.div`
	padding: 0.5rem;
	font-weight: bold;
	text-transform: uppercase;
`;

export const FederatedData = styled.div`
	display: flex;
	gap: 0.5rem;
	align-items: center;
	justify-content: flex-start;
	font-size: 0.75rem;
`;

export const NameOnFederated = styled.div``;

export const FederatedEmail = styled.div`
	cursor: copy;
`;

export const FederateUserdId = styled.div`
	cursor: copy;
`;
