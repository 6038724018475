import { useEffect, useState } from 'react';

//services
// import { AuthorServicesManagementTo } from './services/AuthorServicesManagementTable.services';

//utils
import { footerElementsCount } from './AuthorServicesManagementTable.helper';

//types
import { tableColumnAttr, AuthorServicesManagementTableItem, AuthorServicesManagementTableProps } from './AuthorServicesManagementTable.component.d';

//assets
import { ReactComponent as ChevronLeftIcon } from './assets/chevronLeft.svg';
import { ReactComponent as ChevronRightIcon } from './assets/chevronRight.svg';
import { ReactComponent as DownArrowIcon } from './assets/downArrowIcon.svg';


//style
import {
    FooterNavigationButton,
	FooterElementsCount,
	Table,
	TableBody,
	TableBodyCell,
	TableBodyRow,
	TableFooter,
	TableHead,
	TableHeadCell,
	TableHeadRow,
	DetailsButton,
	RefundButton,
	DeleteButton,
} from './style/AuthorServicesManagementTable.style';

const AuthorServicesManagementTableColumns: tableColumnAttr[] = [
	{
        name: 'id',
        title: 'Id Sott.',
    },
	{
        name: 'user',
        title: 'Utente',
    },
	{
        name: 'subscription',
        title: 'Sottoscrizione',
		align: 'center',
    },
	{
        name: 'date',
        title: 'Data Sott.',
		align: 'center',
    },
	{
        name: 'deadline',
        title: 'Data Scadenza.',
		align: 'center',
    },
	{
        name: 'type',
        title: 'Tipo',
		align: 'center',
    },
];

type sortParamsType = {
	column: string | null;
	direction: 'asc' | 'desc' | null;
};

const AuthorServicesManagementTable: React.FC<AuthorServicesManagementTableProps> = ({ data, totalPages, totalElements, pageSize, searchAuthorServicesManagement }) => {
	const [tableData, setTableData] = useState<AuthorServicesManagementTableItem[]>([]);
	const [sortParams, setSortParams] = useState<sortParamsType>({column: null, direction: null});
	const [currentPage, setCurrentPage] = useState(1);

	const pageChangeHandler = (page: number) => {
		setCurrentPage(page);
	};

	const sortTable = (items: AuthorServicesManagementTableItem[], sortBy: string | null, direction: 'asc' | 'desc' | null) => {
		if (sortBy === null) return items;
		const sortedItems = [...items].sort((a, b) => {
			if (a[sortBy] < b[sortBy]) {
			  return -1;
			}
			if (a[sortBy] > b[sortBy]) {
			  return 1;
			}
			return 0;
		});
		
		if (direction === 'desc') {
			return sortedItems.reverse();
		}
		
		return sortedItems;
	};

	const handleSort = (key: string) => {
		if (key === sortParams.column) {
			setSortParams({column: key, direction: sortParams.direction === 'asc' ? 'desc' : 'asc'});
		} else {
			setSortParams({column: key, direction: 'desc'});
		}
	};

	useEffect(() => {
		console.log('tableData', tableData);
	}, [tableData]);

	useEffect(() => {
		searchAuthorServicesManagement(currentPage);
	}, [currentPage]);

	useEffect(() => {
		const sortedTable = sortTable(tableData, sortParams.column, sortParams.direction);
		setTableData(sortedTable);
	}, [sortParams]);

	useEffect(() => {
		const sortedTable = sortTable(data, sortParams.column, sortParams.direction);
		setTableData(sortedTable);
	}, [data]);

    let columns: string[] = [];
	if (tableData.length > 0) columns = Object.keys(tableData[0]);

	return (
		<>
			<Table>
				<TableHead>
					<TableHeadRow>
						{columns.map((c) => (
							<TableHeadCell
								key={c}
								onClick={() => handleSort(c)}
							>
								{AuthorServicesManagementTableColumns.find(x => x?.name === c)?.title ? AuthorServicesManagementTableColumns.find(x => x?.name === c)?.title?.toUpperCase() : c.toUpperCase()}
								{(sortParams.column === c && (sortParams.direction === 'desc' ? <DownArrowIcon transform="rotate(180 0 0)"/> : <DownArrowIcon />))}
							</TableHeadCell>
						))}
                        <TableHeadCell
                            key={"rinnovoautom"}>
                            RINNOVO AUTOM.
                        </TableHeadCell>
					</TableHeadRow>
				</TableHead>
				<TableBody>
					{tableData.map((row, index) => (
						<TableBodyRow key={index}>
							{columns.map((c) => {
								const value = row[c as keyof AuthorServicesManagementTableItem];
								return (
									<TableBodyCell
                                        align={AuthorServicesManagementTableColumns.find(x => x?.name === c)?.align}
										key={c}
										id={c}
										tooltip={(Object.prototype.toString.call(value) === '[object Date]') ? value.toLocaleString("it-IT") : null}
									>
										{(Object.prototype.toString.call(value) === '[object Date]') ? value.toLocaleString("it-IT").split(",")[0] : value}
									</TableBodyCell>
								);
							})}
                            <TableBodyCell 
                                align={"center"}
                            >
								<DeleteButton >&#10006;</DeleteButton>
                            </TableBodyCell>
						</TableBodyRow>
					))}
				</TableBody>
			</Table>
			{totalElements > 0 && (
				<TableFooter>
					<FooterElementsCount>{footerElementsCount({ currentPage, totalPages, totalElements, pageSize })}</FooterElementsCount>
					<FooterNavigationButton
						onClick={() => pageChangeHandler(currentPage - 1)}
						disabled={currentPage === 1}
					>
						<ChevronLeftIcon />
					</FooterNavigationButton>
					<FooterNavigationButton
						onClick={() => pageChangeHandler(currentPage + 1)}
						disabled={currentPage === totalPages}
					>
						<ChevronRightIcon />
					</FooterNavigationButton>
				</TableFooter>
			)}
		</>
	);
};

export default AuthorServicesManagementTable;