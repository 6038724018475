import { FC, useState } from 'react';

// hooks
import useHasRole from 'hooks/useHasRole';

//types
import { EditableVideoProps } from './EditableVideo.component.d';

//style
import { 
	TextBlock,
	EditBox
 } from './style/EditableVideo.component.style';
import SectionEditorModal from 'components/SectionEditorModal/SectionEditorModal.component';
import MediaPlayer from 'components/MediaPlayer';

const EditableVideo: FC<EditableVideoProps> = ({
	productId,
	webpageId,
	blockId,
	videoElementId,
	media,
	poster,
	isRequired,
	typeOfLayout,
	numberOfElements,
	update,
	refreshProduct,
	videoUrl = '',
	pictureUrl = '',
}) => {
	const [isEditorEnabled, setIsEditorEnabled] = useState(false);
	const [isOnHover, setIsOnHover] = useState(false);

    const isAdmin = useHasRole('ADMIN');
    const isSuperAdmin = useHasRole('SUPERADMIN');
	const isEditorLDL = useHasRole('EDITOR-LDL');
	const isEditorMB = useHasRole('EDITOR-MB');
	const isEditorTCR = useHasRole('EDITOR-TCR');
    const userCanEditVideo = isAdmin || isSuperAdmin || isEditorLDL || isEditorMB || isEditorTCR;

	const onMouseOverHandler = () => setIsOnHover(true);
	const onMouseOutHandler = () => setIsOnHover(false);
	const onCloseModalHandler = () => setIsEditorEnabled(false);
	
	const onModifyImageClickHandler = () => {
		        
        if (!userCanEditVideo) return;
		setIsEditorEnabled(prevState => !prevState);
	};
	
	return (
		<>
			<TextBlock
				onMouseOver={onMouseOverHandler}
				onMouseLeave={onMouseOutHandler}
				onClick={onModifyImageClickHandler}
				isUserAdmin={userCanEditVideo}
			>
				<MediaPlayer 
					media={media}
					preload='metadata'
					poster={poster}
				/>

				{isOnHover && userCanEditVideo && (
					<EditBox>
						Modifica video
					</EditBox>
				)}
			</TextBlock>

			{isEditorEnabled && userCanEditVideo && (
				<SectionEditorModal 
					onCloseButtonModal={onCloseModalHandler}
					update={update!}
					productId={productId}
					webpageId={webpageId}
					blockId={blockId}
					elementId={videoElementId}
					typeOfElement='VIDEO'
					isRequired={isRequired}
					updateData={refreshProduct}
					typeOfLayout={typeOfLayout}
					numberOfElements={numberOfElements}
					videoUrl={videoUrl}
					pictureUrl={pictureUrl}
				/>
			)}
		</>
	);
};

export default EditableVideo;
