import colors from 'style/palette';
import styled from 'styled-components';

export const AssignProductModalBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 600px;
	/* height: 400px; */
	color: ${colors.white};
	padding: 2rem;
	gap: 1rem;
	background-color: ${colors.veryDarkGrey};
	border-radius: 15px;
`;

export const OptionList = styled.ul`
	background-color: white;
	color: black;
	position: relative;
	z-index: 2;
	max-height: 250px;
	overflow-y: auto;
	width: 100%;
	list-style-type: none;

	li {
		padding: 0.25rem;
		cursor: pointer;

		&:hover {
			background-color: lightgray;
		}
	}
`;

export const ProductLabel = styled.label``;

export const ProductSelection = styled.div``;

export const ProductInput = styled.input``;

export const ProductExpandButton = styled.button``;

export const ExpirationSelection = styled.div``;

export const ExpirationLabel = styled.label``;

export const ExpirationInput = styled.input``;

export const AssignButton = styled.button`
	align-self: center;
`;

export const Price = styled.input``;

export const PaymentTypeSelection = styled.select``;
