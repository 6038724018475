import { FC, useEffect, useState } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';
import useHasRole from 'hooks/useHasRole';
import useBrand from 'hooks/useBrand';

//types
import { AddRoadmapModalProps } from './AddRoadmapModal.component.d';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { RoadmapEvent } from 'types/RoadmapEvent';
import { RoadmapEventType } from 'types/RoadmapEventType';


//components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';

//utils
import { addEvent, addEventType } from '../services/RoadmapPage.services';

//style
import { CardContent, CloseButton, IconBox, EventName, TextContent, TextDisclaimer, Title } from './style/AddRoadmapModal.component.style';
import { useNavigate } from 'react-router-dom';
import { enableAppMain } from 'redux/slices/slice-app-main';


const AddEventModal: FC<AddRoadmapModalProps> = ({ elementType, onCloseButtonModal }) => {
	const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);
	const [eventName, setEventName] = useState('');
	const [isAddEnabled, setIsAddEnabled] = useState(false);

	const isAdmin = useHasRole('ADMIN');
	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');
	const isAuthor = useHasRole('AUTORE');
	const isAnimator = useHasRole('ANIMATORE');
	const isRoadmap = useHasRole('ROADMAP'); // IT SHOULD CHECK FOR ALL BRANDS

	const canCreateRoadmap = isSuperAdmin || isRoadmap; // WE ARE NOT USING IT

	const element = elementType === 'types' ? "il tipo evento" : "l'evento";

	const brand = useBrand();

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const closeButtonHandler = () => {
		if (onCloseButtonModal) onCloseButtonModal();
		dispatch(enableAppMain());
	};

	const onEventNameChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEventName(event.currentTarget.value);
	};

	const addEventHandler = () => {
		if (window.confirm(`Aggiungere ${element} col nome "${eventName}" ?`)) {
			if (elementType !== 'types') callAddEvent();
			if (elementType === 'types') callAddType();
		}
	};

	function capitalizeFirstLetter(lowstring: string) {
		return lowstring.charAt(0).toUpperCase() + lowstring.slice(1);
	}

	const callAddEvent = async () => {
		if (!brand) return;
		const snackbarData: SnackbarOptions = {};
		const eventType: RoadmapEventType = { id: 1 } as RoadmapEventType;
		const newEvent: Partial<RoadmapEvent> = { name: eventName, eventType: eventType, visible: true };
		try {
			const response = await addEvent(newEvent, brand.code);
			console.log(response);

			snackbarData.type = 'success';
			snackbarData.message = capitalizeFirstLetter(`${element} è stato aggiunto correttamente`);
			await dispatch(addSnackbar(snackbarData));
			if (onCloseButtonModal) onCloseButtonModal();
			// console.log('reservedArea/roadmap/' + response.id);

			navigate('/reservedArea/roadmap/' + response.id);
		} catch (error: any) {
			snackbarData.type = 'error';
			snackbarData.message = `Non è stato possibile aggiungere ${element}.`;
			dispatch(addSnackbar(snackbarData));
		}
	};

	const callAddType = async () => {
		if (!brand) return;
		const snackbarData: SnackbarOptions = {};
		const eventType: RoadmapEventType = { id: 1 } as RoadmapEventType;
		const newEvent: Partial<RoadmapEventType> = { name: eventName };
		try {
			const response = await addEventType(newEvent, brand.code);
			console.log(response);

			snackbarData.type = 'success';
			snackbarData.message = capitalizeFirstLetter(`${element} è stato aggiunto correttamente`);
			await dispatch(addSnackbar(snackbarData));
			if (onCloseButtonModal) onCloseButtonModal();
			// console.log('reservedArea/roadmap/' + response.id);

			navigate('/reservedArea/roadmap/type/' + response.id);
		} catch (error: any) {
			snackbarData.type = 'error';
			snackbarData.message = `Non è stato possibile aggiungere ${element}.`;
			dispatch(addSnackbar(snackbarData));
		}
	};

	useEffect(() => {
		if (eventName?.length > 0) setIsAddEnabled(true); else setIsAddEnabled(false);
	}, [eventName]);

	return (
		<BaseModal
			isBackdropClickActive={true}
			onClose={closeButtonHandler}
			isCloseButtonClicked={isCloseButtonClicked}
		>
			<BaseCard
				flavor='outlined'
				maxWidth='700px'
			>
				<CardContent>
					<EventName>
						<label>Nome:</label>
						<input
							type='text'
							size={50}
							value={eventName}
							onChange={onEventNameChangeHandler}
						></input>
					</EventName>
					<button
						disabled={!isAddEnabled}
						onClick={addEventHandler}
					>
						Aggiungi {element}
					</button>
				</CardContent>
			</BaseCard>
		</BaseModal>
	);
};

export default AddEventModal;
