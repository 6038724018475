import colors from 'style/palette/palette';
import styled from 'styled-components';

export const CloseHighwayRouteBox = styled.div`
	display: flex;
	flex-direction: column;
	width: 680px;
	color: ${colors.white};
	padding: 1rem;
	gap: 1rem;
	background-color: ${colors.veryDarkGrey};
	border-radius: 15px;
`;

export const Label = styled.label``;

export const NotesTextArea = styled.textarea`

    width: 100%;
    height: 120px;
    padding: 7px 9px;

    background-color: transparent;
    border: 1px solid #FFFFFF;
    border-radius: 15px;

    color: white;
    :focus{
        outline: none;
        border-color: white;
        box-shadow: 0 0 0px white;
        -moz-box-shadow: 0 0 0px white;
        -webkit-box-shadow: 0 0 0px white;
    }
`;

export const ButtonsRow = styled.div`
	display: flex;
	width: 70%;
	align-self: center;
	align-items: center;
	justify-content: space-around;
`;

export const NotesRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 15px;

    width: 640px;
`;

export const NotesColumn = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
`;

export const NotesTitle = styled.p`
`;

export const SaveButton = styled.button`
    width: 164px;
    height: 24px;

    border-radius: 416px;
    border: 0;
    background-color: ${colors.green};

    font-weight: 400;
    font-size: 13px;
    color: #ffffff;
	cursor: pointer;

    :disabled {
        background-color: #FFFFFF1F;
        color: #29353E;
        cursor: not-allowed;
    }
`;

export const CancelButton = styled.button`
    width: 124px;
    height: 24px;

    border-radius: 416px;
    border: 0;
    background-color: ${colors.red};

    font-weight: 400;
    font-size: 13px;
    color: #ffffff;
	cursor: pointer;

    :disabled {
        background-color: #FFFFFF1F;
        color: #29353E;
        cursor: not-allowed;
    }
`;

