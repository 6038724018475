import { FC } from 'react';

//types
import { ButtonProps } from './RowButton.component.d';

//style
import { EventSingleButton } from './style/RowButton.component.style';

const Button: FC<ButtonProps> = ({ title, name, ButtonIcon, clickHandler }) => {
	return (
		<EventSingleButton
			title={title}
			name={name}
			onClick={clickHandler}
		>
			<ButtonIcon />
		</EventSingleButton>
	);
};

export default Button;
