// types
import { AppDispatch, RootState } from '../store';

// slice actions
import {
    disableAppMain as actionDisableAppMain,
    enableAppMain as actionEnableAppMain,
    setIsMainActive
} from '../slices/slice-app-main';


export const disableAppMain = () => {

    return (dispatch: AppDispatch) => {
        dispatch( actionDisableAppMain() );
    };
};

export const enableAppMain = () => {

    return (dispatch: AppDispatch) => {
        dispatch( actionEnableAppMain() );
    };
};

export const toggleisAppMainActive = () => {

    return (dispatch: AppDispatch, getState: () => RootState) => {

        const { isMainActive } = getState().appMain;
        dispatch( setIsMainActive(!isMainActive) );
    };
};
