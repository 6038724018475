import { FC } from 'react';

//types
import { LessonTitleProps } from './LessonTitle.component.d';

//style
import { LessonTitleBox, Title } from './style/LessonTitle.component.style';
import { Label } from '../style/ModifyLessonModal.component.style';

const LessonTitle: FC<LessonTitleProps> = ({ title, setLocalTitle }) => {
	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLocalTitle(event.currentTarget.value);
	};

	return (
		<LessonTitleBox>
			<Label>Titolo</Label>
			<Title
				value={title}
				onChange={onChangeHandler}
			></Title>
		</LessonTitleBox>
	);
};

export default LessonTitle;
