import { callDELETE } from 'network/network';

import { getBrand } from 'configs/brandMap/brandMap.helper';

const _PRODUCT_SUBSCRIPTIONS_PATH = 'v1/productsubscriptions/{id}';


export const softDeleteProductSubscription = async (subscriptionId: number) => {
	const brand = getBrand();
	if (!brand) return;
	try {
		const response = await callDELETE(_PRODUCT_SUBSCRIPTIONS_PATH.replace(/{id}/g, subscriptionId.toString()), {}, true, { brandCode: brand.code });

		//204

		return response;
	} catch (error: any) {
		throw error;
	}
};
