import { callGET, callOldGET } from 'network/network';


const _USER_PROFILE_PATH = 'v1/users/self/currentuser';
const _OLD_USER_PROFILE_PATH = 'user/getCurrentUser';

export const getCurrentAuthUser = async () => {

    try {

        const response = await callGET(_USER_PROFILE_PATH, true);

        if (response === undefined || !response) {
            throw new Error();
        }

        return response;
        
    } catch (error: any) {
        throw error;
    }
};

export const oldGetCurrentAuthUser = async () => {

    try {

        const response = await callOldGET(_OLD_USER_PROFILE_PATH, true);

        if (response === undefined || !response) {
            throw new Error();
        }

        return response;
        
    } catch (error: any) {
        throw error;
    }
};
