import styled, { css } from 'styled-components';

// types
import { CircleFrameProps } from './EmptyUserImageProfile.style.d';

// style
import colors from 'style/palette/palette';


export const CircleFrame = styled.div<CircleFrameProps>`

    min-width: 80px;
    max-width: 80px;

    min-height: 80px;
    max-height: 80px;

    border-radius: 50%;
    border: 1px solid ${colors.white};
    background: ${colors.lightGrey};
    
    display: flex;
    align-items: center;
    justify-content: center;
    
    ${({ isSmall }) => isSmall && (

        css`
            min-width: 40px;
            max-width: 40px;

            min-height: 40px;
            max-height: 40px;
        `
    )}

    ${({ flavor }) => (flavor === 'facebook') && (

        css`
            border: 3px solid ${colors.blue};
        `
    )}
`;

export const CircleFrameRef = styled.div`

    width: auto;
    position: relative;

    > svg {

        min-width: 28px;
        max-width: 28px;

        min-height: 28px;
        max-height: 28px;
    }
`;

export const EditButton = styled.button`

    width: 36px;
    height: 36px;

    margin: 0;
    padding: 0;

    border-radius: 50%;
    background: ${colors.darkGrey};
    border: 1px solid ${colors.white};

    position: absolute;
    bottom: 0;
    right: -10px;

    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;
    cursor: pointer;

    > svg {

        max-width: 36px;
        max-height: 36px;
    }

    :disabled {

        cursor: default;
    }
`;
