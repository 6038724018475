import styled from 'styled-components';

export const Page = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: stretch;
	flex: 1;

	padding: 0 30px;

	width: 100%;
	height: 100%;

	overflow: hidden;
`;

export const MainBox = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 100%;
	align-items: flex-start;
`;

export const RowBox = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	gap: 1rem;
	align-items: stretch;
`;

export const ColumnBox = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 1rem;
	align-items: stretch;
`;

export const Row = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-start;
	gap: 1rem;
	align-items: center;
`;

export const Col = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
`;

export const RemoveButton = styled.button`
    font-size: 1.1em;

    background-color: red;
    color: white;

    border: none;
    border-radius: 4px;

    cursor: pointer;
`;
