import { FC, useState } from 'react';

// hooks
import useHasRole from 'hooks/useHasRole';

//types
import { ThematicBroadcastDetailProps } from './ThematicBroadcastDetail.component.d';
import { TabSelectorSettings, TabSelectorItem } from 'components/TabSelector/TabSelector.component.d';

//components
import { BaseContentCard } from 'style/wrappers/wrappers';
import TabSelector from 'components/TabSelector/TabSelector.component';
import ProductTab from '../ProductTab';
import PercentagesTab from '../PercentagesTab';
import ThematicBroadcastLinksTab from '../ProductTab/ThematicBroadcastLinksTab';
import SalesPageTab from '../SalesPageTab';
import RequirementsTab from '../RequirementsTab';

const ThematicBroadcastDetail: FC<ThematicBroadcastDetailProps> = ({ product, putProduct, updateProductData }) => {
	const isAdmin = useHasRole('ADMIN');
	const isSuperAdmin = useHasRole('SUPERADMIN');
	var isDiretta = useHasRole('DIRETTA');
	const isRegistaLDL = useHasRole('REGISTA-LDL');
	const isRegistaMB = useHasRole('REGISTA-MB')
	const isRegistaTCR = useHasRole('REGISTA-TCR');
	const isEditorLDL = useHasRole('EDITOR-LDL');
	const isEditorMB = useHasRole('EDITOR-MB');
	const isEditorTCR = useHasRole('EDITOR-TCR');
	isDiretta = isDiretta || isRegistaLDL || isRegistaMB || isRegistaTCR || isEditorLDL || isEditorMB || isEditorTCR;
	
	const hasAdminRole = isAdmin || isSuperAdmin;

	const TAB_ITEMS: TabSelectorItem[] = [
		{
			title: (product.productThematicCall.type === 'ESPERIENZA') ? 'Esperienza' :'Aula',
			value: 'thematicBroadcast',
			display: hasAdminRole || isDiretta,
		},
		{
			title: 'Prezzo e %',
			value: 'percentages',
			display: hasAdminRole || isDiretta,
		},
		{
			title: 'Links',
			value: 'thematicBroadcastLinks',
			display: hasAdminRole || isDiretta,
		},
		{
			title: 'Pagina di vendita',
			value: 'salesPage',
			display: hasAdminRole || isDiretta,
		},
		{
			title: 'Requisiti',
			value: 'requirements',
			display: hasAdminRole || isDiretta,
		},
	];

	const [tabSelectorValue, setTabSelectorValue] = useState<string>('');

	const tabSettings: TabSelectorSettings = { tabItems: TAB_ITEMS, handleSelection: setTabSelectorValue };

	return (
		<>
			<TabSelector tabSelectorSettings={tabSettings}></TabSelector>
			<BaseContentCard>
				{tabSelectorValue === 'thematicBroadcast' && (
					<ProductTab
						product={product}
						putProduct={putProduct}
						updateProductData={updateProductData}
					></ProductTab>
				)}
				{tabSelectorValue === 'percentages' && (
					<PercentagesTab
						product={product}
						putProduct={putProduct}
					></PercentagesTab>
				)}
				{tabSelectorValue === 'thematicBroadcastLinks' && (
					<ThematicBroadcastLinksTab
						product={product}
						putProduct={putProduct}
						updateProductData={updateProductData}
					></ThematicBroadcastLinksTab>
				)}
				{tabSelectorValue === 'salesPage' && product.salesData && (
					<SalesPageTab
						product={product}
						putProduct={putProduct!}
						updateProductData={updateProductData}
						videoOnly={true}
					></SalesPageTab>
				)}
				{tabSelectorValue === 'requirements' && (
					<RequirementsTab
						product={product}
						putProduct={putProduct}
						updateProductData={updateProductData}
					></RequirementsTab>
				)}
			</BaseContentCard>
		</>
	);
};

export default ThematicBroadcastDetail;
