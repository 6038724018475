import { BrandBox } from 'App/layout/Header/style/Header.style';
import { callPUT } from 'network/network';

import { getBrand } from 'configs/brandMap/brandMap.helper';

const _SAVE_ZOOM_LINK_PATH = 'v1/productlessonpacks/updatezoom/{lessonId}';
const _SAVE_NOTES_LINK_PATH = 'v1/productlessonpacks/closelessoncoach/{lessonId}';
const _SAVE_NOTES_LESSON_LINK_PATH = '/v1/productlessonpacks/lessons/{lessonId}/closelessoncoach';
const _SAVE_CLOSE_PACK_PATH = 'v1/productlessonpacks/subscriptions/{subscriptionId}/close';


export const saveZoomLink = async (lessonId: number, zoomLink: string) => {
    try { 
        const response = await callPUT(_SAVE_ZOOM_LINK_PATH.replace(
			/{lessonId}/g,
			lessonId.toString()
		  ), {}, true, { url: zoomLink });

        return response;
    } catch (error: any) {
        throw error;
    }
}

export const saveNotes = async (lessonId: number, publicNotes?: string, privateNotes?: string) => {
    try { 
        var params: any = {
        }
        if (publicNotes) {
            params = {...params, coachNotes: publicNotes};
        }
        if (privateNotes) {
            params = {...params, coachPrivateNotes: privateNotes};
        }
        const response = await callPUT(_SAVE_NOTES_LINK_PATH.replace(
			/{lessonId}/g,
			lessonId.toString()
		  ), {}, true, params);

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const saveLessonNotes = async (lessonId: number, publicNotes?: string, privateNotes?: string) => {
    try { 
        var params: any = {
        }
        if (publicNotes) {
            params = {...params, coachNotes: publicNotes};
        }
        if (privateNotes) {
            params = {...params, coachPrivateNotes: privateNotes};
        }
        const response = await callPUT(_SAVE_NOTES_LINK_PATH.replace(
			/{lessonId}/g,
			lessonId.toString()
		  ), {}, true, params);

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const saveSubscribtionNotes = async (subscriptionId: number, success: boolean, closingNotes?: string) => {
    const brand = getBrand();
    if (!brand) return;
    try { 
        var params: any = {
            brandCode: brand.code,
            success: success,
        }
        if (closingNotes) {
            params = {...params, closingNotes: closingNotes};
        }

        const response = await callPUT(_SAVE_CLOSE_PACK_PATH.replace(
			/{subscriptionId}/g,
			subscriptionId.toString()
		  ), {}, true, params);

        return response;
    } catch (error: any) {
        throw error;
    }
};