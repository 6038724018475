import { callPUT } from 'network/network';

import { getBrand } from 'configs/brandMap/brandMap.helper';

const _LESSON_ATTACHMENT_PATH = 'v1/courses/nodes/lesson/{lessonId}/attachments';

export const uploadAttachment = async (lessonId: number, form?: FormData, url?: string) => {
    const brand = getBrand();
    if (!brand) return;
    try {
        const response = await callPUT(_LESSON_ATTACHMENT_PATH.replace(/{lessonId}/g,
        lessonId.toString()), form ? form : {}, true, { url: url, brandCode: brand.code});

        if (response === undefined || !response) {
            throw new Error();
        }

        return response;
    } catch (error: any) {
        throw error;
    }
};

