import { FC, useEffect, useRef, MouseEvent, useCallback } from 'react';
import ReactDOM from 'react-dom';

// redux
import useAppDispatch from 'hooks/useAppDispatch';
import { disableAppMain, enableAppMain } from 'redux/actions/actions-app-main';

// types
import { BaseModalProps } from './BaseModal.d';

// style
import { Backdrop, ModalWindow, ModalPanel } from './style/BaseModal.style';


/**
 * Modal base
 * 
 * @author Alessio Grassi
 * 
 * @param onClose - function that delete the rendering of the modal
 * @param isCloseButtonClicked - state of the close button click
 * @param isBackdropClickActive - state of the backdrop
 * @param isModalCardClickActive - set the closure of the modal with click inside the modal
 * @param children - content of the modal
 * 
 * @returns JSX
 */


const BaseModal: FC<BaseModalProps> = ({
        onClose,
        isCloseButtonClicked,
        isBackdropClickActive,
        isModalCardClickActive,
        children
    }) => {

    const backdropPortalElement = document.getElementById('backdrop-root');
    const modalPortalElement = document.getElementById('modal-root');
    const modalPanelRef = useRef<HTMLDivElement | null>(null);
    const dispatch = useAppDispatch();

    const closeModalHandler = useCallback(() => {

        if (!onClose) return;
        onClose();
        dispatch( enableAppMain() );

    }, [dispatch, onClose]);

    const modalClickHandler = (event: MouseEvent<HTMLDivElement>) => {

        if (modalPanelRef == null || !modalPanelRef?.current) return;

        if (isBackdropClickActive && !modalPanelRef.current.contains(event.target as Node)) {
            
            closeModalHandler();
            return;
        }

        if (isModalCardClickActive && modalPanelRef.current.contains(event.target as Node)) {

            closeModalHandler();
            return;
        }
    };

    useEffect(() => dispatch( disableAppMain() ), [dispatch]);

    useEffect(() => {
        
        if (!isCloseButtonClicked) return;
        closeModalHandler();

    }, [isCloseButtonClicked, closeModalHandler]);

    if (backdropPortalElement == null || modalPortalElement == null) {
        return <></>;
    }

    return (
        <>
            {ReactDOM.createPortal(
                <Backdrop 
                    onClick={modalClickHandler}
                />,
                backdropPortalElement
            )}

            {ReactDOM.createPortal(
                <ModalWindow onClick={modalClickHandler}>
                    <ModalPanel ref={modalPanelRef}>
                        {children}
                    </ModalPanel>
                </ModalWindow>,
                modalPortalElement
            )}
        </>
    );
}

export default BaseModal;
