import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getCurrentBaseUrlFromConfig } from './network.utils';
import { awsGetTokenID } from 'services/awsUser.services';
import config from 'configs/config';

const { newBaseUrl: _baseURL } = getCurrentBaseUrlFromConfig();
const { oldBaseUrl: _baseOldURL } = getCurrentBaseUrlFromConfig();

export const callPOST = async (endPoint: string, payload: {}, isAuthNeeded?: boolean | undefined, params?: any) => {
	try {
		const axiosRequestConfig: AxiosRequestConfig = {};

		if (isAuthNeeded) {
			const tokenID = await awsGetTokenID();
			if (tokenID === undefined) throw Error("L'utente non ha eseguito l'accesso");
			axiosRequestConfig.headers = {};
			axiosRequestConfig.headers.Authorization = `Bearer ${tokenID}`;
			axiosRequestConfig.withCredentials = false;
		}

		if (params) axiosRequestConfig.params = params;

		const response = await axios.post(`${_baseURL}${endPoint}`, payload, axiosRequestConfig);
		if (response.status >= 400) throw Error("C'è stato un errore nella risposta");

		return response.data;
	} catch (error: any) {
		throw error;
	}
};

export const callOldPOST = async (endPoint: string, payload: {} | null, isAuthNeeded?: boolean | undefined, params?: any) => {
	try {
		const axiosRequestConfig: AxiosRequestConfig = {};

		if (params) axiosRequestConfig.params = params;
		if (isAuthNeeded) axiosRequestConfig.withCredentials = true;

		const response = await axios.post(`${_baseOldURL}${endPoint}`, payload, axiosRequestConfig);
		if (response.status >= 400) throw Error("C'è stato un errore nella risposta");

		return response.data;
	} catch (error: any) {
		throw error;
	}
};

export const callGET = async (endPoint: string, isAuthNeeded?: boolean | undefined, params?: any) => {
	try {
		const axiosRequestConfig: AxiosRequestConfig = {};

		if (isAuthNeeded) {
			const tokenID = await awsGetTokenID();
			if (tokenID === undefined) throw Error("L'utente non ha eseguito l'accesso");
			axiosRequestConfig.headers = {};
			axiosRequestConfig.headers.Authorization = `Bearer ${tokenID}`;
			axiosRequestConfig.withCredentials = false;
		}

		if (params) {
			axiosRequestConfig.params = params;
			axiosRequestConfig.paramsSerializer = { indexes: null };
		}

		const response = await axios.get(`${_baseURL}${endPoint}`, axiosRequestConfig);
		if (response.status >= 400) throw Error("C'è stato un errore nella risposta");

		return response.data;
	} catch (error: any) {
		throw error;
	}
};

export const callOldGET = async (endPoint: string, isAuthNeeded?: boolean | undefined, params?: any) => {
	try {
		const axiosRequestConfig: AxiosRequestConfig = {};

		if (params) axiosRequestConfig.params = params;
		if (isAuthNeeded) axiosRequestConfig.withCredentials = true;

		const response = await axios.get(`${_baseOldURL}${endPoint}`, axiosRequestConfig);
		if (response.status >= 400) throw Error("C'è stato un errore nella risposta");

		return response.data;
	} catch (error: any) {
		throw error;
	}
};

export const callPUT = async (endPoint: string, payload: {}, isAuthNeeded?: boolean | undefined, params?: any) => {
	try {
		const axiosRequestConfig: AxiosRequestConfig = {};

		if (isAuthNeeded) {
			const tokenID = await awsGetTokenID();
			if (tokenID === undefined) throw Error("L'utente non ha eseguito l'accesso");
			axiosRequestConfig.headers = {};
			axiosRequestConfig.headers.Authorization = `Bearer ${tokenID}`;
			axiosRequestConfig.withCredentials = false;
		}

		if (params) axiosRequestConfig.params = params;

		const response = await axios.put(`${_baseURL}${endPoint}`, payload, axiosRequestConfig);
		if (response.status >= 400) throw Error("C'è stato un errore nella risposta");

		return response.data;
	} catch (error: any) {
		throw error;
	}
};

export const callOldPUT = async (endPoint: string, payload: {}, isAuthNeeded?: boolean | undefined, params?: any) => {
	try {
		const axiosRequestConfig: AxiosRequestConfig = {};

		if (params) axiosRequestConfig.params = params;
		if (isAuthNeeded) axiosRequestConfig.withCredentials = true;

		const response = await axios.put(`${_baseOldURL}${endPoint}`, payload, axiosRequestConfig);
		if (response.status >= 400) throw Error("C'è stato un errore nella risposta");

		return response.data;
	} catch (error: any) {
		throw error;
	}
};

export const callDELETE = async (endPoint: string, payload: {}, isAuthNeeded?: boolean | undefined, params?: any) => {
	try {
		const axiosRequestConfig: AxiosRequestConfig = {};

		if (isAuthNeeded) {
			const tokenID = await awsGetTokenID();
			if (tokenID === undefined) throw Error("L'utente non ha eseguito l'accesso");
			axiosRequestConfig.headers = {};
			axiosRequestConfig.headers.Authorization = `Bearer ${tokenID}`;
			axiosRequestConfig.withCredentials = false;
		}

		if (params) axiosRequestConfig.params = params;

		const response = await axios.delete(`${_baseURL}${endPoint}`, {
			data: payload,
			headers: axiosRequestConfig.headers,
			params: axiosRequestConfig.params,
		});
		if (response.status >= 400) throw Error("C'è stato un errore nella risposta");

		return response.data;
	} catch (error: any) {
		throw error;
	}
};

export const httpFacebookGraphClient = (token: string | null) => {
	const defaultOptions: any = {
		baseURL: 'https://graph.facebook.com/v14.0',
		method: 'get',
		// withCredentials: true,
		headers: {
			'Content-Type': 'application/json',
		},
	};

	// Create instance
	let instance = axios.create(defaultOptions);

	// Set the access token parameter for any request
	instance.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig => {
		console.log(config);
		if (!config) {
			config = {};
		}
		if (!config.params) {
			config.params = {};
		}

		config.params.access_token = token;
		if (config.method === 'GET' || config.method === 'get') config.params.limit = '999';
		if (config.method === 'POST' || config.method === 'post') config.params.limit = '1';
		return config;
	});

	return instance;
};

const FB_GROUP_ID = () => {
	if (!config || config === undefined) return;
	if (!config.socialLinks || config.socialLinks === undefined) return;
	if (config.environment === 'production') return config.socialLinks.fbGroupID;
	return config.socialLinks.fbGroupTestID;
};

export const postSvgToFacebook = (
	facebookToken: string | null,
	width: string,
	height: string,
	svgHtml: string,
	caption: string,
	successHandler: (res: AxiosResponse) => void,
	errorHandler: (res: AxiosResponse) => void,
	refreshFunction: (() => any) | undefined
) => {
	let svg = document.createElement('svg');
	svg.append(svgHtml);
	console.log('svg', svg);
	let canvas = document.createElement('canvas');
	let ctx = canvas.getContext('2d');
	canvas.width = +width;
	canvas.height = +height;
	canvas.style.width = width;
	canvas.style.height = height;
	console.log('canvas', canvas);
	let win = window.URL || window.webkitURL || window;
	let blob = new Blob([svgHtml], { type: 'image/svg+xml' });
	let url = win.createObjectURL(blob);
	let img = new Image();
	img.onload = () => {
		ctx?.drawImage(img, 0, 0, +width, +height);
		let imgURI = canvas.toDataURL('image/png');
		console.log('imgURI', imgURI);
		canvas.toBlob((blob) => {
			if (blob !== null) {
				console.log('pngBlob', blob);
				const formData = new FormData();
				formData.append('source', blob);
				formData.append('caption', caption);
				console.log('FINAL FB', formData);

				httpFacebookGraphClient(facebookToken)
					.post('/' + FB_GROUP_ID() + '/photos', formData, {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					})
					.then(successHandler)
					.then(() => {
						if (refreshFunction) refreshFunction();
					})
					.catch(errorHandler);
			}
		}, 'image/png');
	};
	img.src = url;
};
