import { callPUT } from 'network/network';

import { getBrand } from 'configs/brandMap/brandMap.helper';

const _PRODUCT_VIDEO_PATH = 'v1/products/{productId}/thematicbroadcast/recordedvideo';



export const uploadRecordedVideo = async (productId: number, videoUrl: string) => {
    const brand = getBrand();
    if (!brand) return;
    try {
        const response = await callPUT(_PRODUCT_VIDEO_PATH.replace(/{productId}/g,
        productId.toString()), {}, true, { url: videoUrl, brandCode: brand.code});

        if (response === undefined || !response) {
            throw new Error();
        }

        return response;
    } catch (error: any) {
        throw error;
    }
};