import { callPUT } from 'network/network';

import { getBrand } from 'configs/brandMap/brandMap.helper';

const _PRODUCT_UPLOAD_PICTURE_PATH = 'v1/products/{id}/sellimage';

const brand = getBrand();

export const uploadFile = async (productId: number, form: FormData) => {
    const brand = getBrand();
    if (!brand) return;
    try {
        const response = await callPUT(_PRODUCT_UPLOAD_PICTURE_PATH.replace(
			/{id}/g,
			productId.toString()
		  ), form, true, { brandCode: brand.code});

        return response;
    } catch (error: any) {
        throw error;
    }
};