import styled, { css } from 'styled-components';
import colors from 'style/palette/palette';


export const ButtonBox = styled.div <{isPresentStyle?: boolean}>`

    display: flex;
    justify-content: center; 
    flex-direction: column;
    align-self: center;
    
    width: 100%;

    max-width: 337px;
`;


export const ProductPriceBox = styled.div<{isDuble?: boolean}>`

    display: flex;
    align-items: center;
    justify-content: center;

    margin: 15px 0 0;

    width: 100%;

    ${({isDuble}) => isDuble && 
        css`
            align-items: flex-start;
        `
    }
`;

export const ProductCourseButton = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 42px; 

    margin: 0;
    /* padding: 7px 0 5px; */

    background: #FFC000;

    border: none;
    border-radius: 5px;
    box-shadow:
        0px 3px 1px -2px rgba(0, 0, 0, 0.2), 
        0px 2px 2px rgba(0, 0, 0, 0.14), 
        0px 1px 5px rgba(0, 0, 0, 0.12);

    text-align: center;

    cursor: pointer;

    :disabled {

        background: ${colors.white050};
        color: ${colors.black087};

        cursor: default;
    }
`;

export const ProductPriceText = styled.p`

    margin: 0;
    padding: 0;

    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    color: ${colors.black087};
    letter-spacing: 0.4px;
    /* text-transform: uppercase; */
    white-space: nowrap;
`;

export const ProductPriceTimingText = styled.span`

    font-size: 11px;

    text-transform: none;
`;


export const ProductAlternativePaymentGlobalBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    position: relative;

    margin: 7px 0 0;

    width: 100%;
    min-height: 23px;
`;

export const ProductAlternativePaymentBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-end;

    width: 100%;

    gap: 5px;
`;

export const ProductPriceSelectorBox = styled.button<{isActive?: boolean}>`

    display: flex;
    align-items: center;
    justify-content: center;

    padding:  10px 7px 8px;

    background: #29353E;

    border-radius: 5px;
    border: none;

    cursor: pointer;

    ${({isActive}) => isActive && 
        css`
            border-radius: 5px 5px 0 0;
        `
    }
`;

export const ProductAlternativePriceBox = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;


    gap: 3px;

    margin: 0;
    padding: 0;

    font-weight: 600;
    font-size: 11px;

    font-style: italic;

    color: #21262B;

    white-space: nowrap;
`;

export const PresentText = styled.p`

    margin: 0;
    padding: 0;

    font-size: 14px;
    font-weight: 500;
    color: ${colors.black087};
    letter-spacing: 0.4px;
    /* text-transform: uppercase; */
    white-space: nowrap;

    gap: 5px;
`;