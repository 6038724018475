import colors from "style/palette";
import styled, { css } from "styled-components";

export const Page = styled.div`
    display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: stretch;
	flex: 1;

	width: 100%;
	height: 100%;
	padding: 0 30px;

`

export const PageContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 100%;
`;

export const SaveButton = styled.button`
`;

export const TestimonialsBox = styled.div`
	display: flex;
	flex-direction: column;

	gap: 1rem;
`;

export const AddTestimonial = styled.button`
	/* background-color: transparent; */
	display: flex;
	align-items: center;
	> svg {
		width: 30px;
		height: 30px;
		> path {
			fill: ${colors.green};
		}
	}
`;

export const ButtonsRow = styled.div`
	display: flex;
	justify-content: center;
	align-items: stretch;
	gap: 2rem;
`