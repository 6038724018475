import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

// types
import { AppDispatch } from '../store';
import { Role } from 'types/Role';
import { Permission } from 'types/Permission';

// slice actions
import {
	setTokenID,
	setId,
	setEmail,
	setName,
	setSurname,
	setIsLoggedInWithFacebook,
	setFacebookProfilePicture,
	setRoles,
	setPermissions,
} from '../slices/slice-user';

// utils
import { FnError } from 'utils/logErrors';
import { awsGetCurrentAuthenticatedUser, awsRefreshUserSession, awsSignOutUser } from 'services/awsUser.services';

export const saveUserTokenID = (newUserTokenID?: string | undefined) => {
	return (dispatch: AppDispatch) => {
		dispatch(setTokenID(newUserTokenID));
	};
};

export const clearUserTokenID = () => {
	return (dispatch: AppDispatch) => {
		dispatch(setTokenID(undefined));
	};
};

export const saveUserId = (newUserId?: number | undefined) => {
	return (dispatch: AppDispatch) => {
		dispatch(setId(newUserId));
	};
};

export const saveUserEmail = (newUserEmail?: string | undefined) => {
	return (dispatch: AppDispatch) => {
		dispatch(setEmail(newUserEmail));
	};
};

export const saveUserName = (newUserName?: string | undefined) => {
	return (dispatch: AppDispatch) => {
		dispatch(setName(newUserName));
	};
};

export const saveUserSurname = (newUserSurname?: string | undefined) => {
	return (dispatch: AppDispatch) => {
		dispatch(setSurname(newUserSurname));
	};
};

export const userIsLoggedInWithFacebook = () => {
	return (dispatch: AppDispatch) => {
		dispatch(setIsLoggedInWithFacebook(true));
	};
};

export const userIsNotLoggedInWithFacebook = () => {
	return (dispatch: AppDispatch) => {
		dispatch(setIsLoggedInWithFacebook(false));
	};
};

export const saveUserFacebookProfilePicture = (newFacebookProfilePicture?: string | undefined) => {
	return (dispatch: AppDispatch) => {
		dispatch(setFacebookProfilePicture(newFacebookProfilePicture));
	};
};

const setUserInfo = (dispatch: AppDispatch, payload: any) => {
	dispatch(setTokenID(payload.signInUserSession.accessToken.jwtToken));
	// dispatch(setId(payload.id));
	dispatch(setEmail(payload.attributes.email));
	dispatch(setName(payload.attributes.name));
	dispatch(setSurname(payload.attributes.family_name));
};

const resetUserInfo = (dispatch: AppDispatch) => {
	dispatch(setTokenID(undefined));
	dispatch(setId(undefined));
	dispatch(setEmail(undefined));
	dispatch(setName(undefined));
	dispatch(setSurname(undefined));
};

export const saveUserLoginInfo = () => {
	return async (dispatch: AppDispatch) => {
		try {
			const response = await awsGetCurrentAuthenticatedUser();

			if (response === undefined || !response) {
				throw new FnError('actions-user/refreshUserSession()', 'awsGetCurrentAuthenticatedUser() undefined response');
			}

			setUserInfo(dispatch, response);
		} catch (error: any) {
			console.error(error);
			resetUserInfo(dispatch);
		}
	};
};

export const refreshUserSession = () => {
	return async (dispatch: AppDispatch) => {
		try {
			const response = await awsRefreshUserSession();

			if (response === undefined || !response) {
				throw new FnError('actions-user/refreshUserSession()', 'awsRefreshUserSession() undefined response');
			}

			// const currentUserAccessToken = response.getAccessToken().getJwtToken();
			// dispatch( setTokenID(currentUserAccessToken) );
			setUserInfo(dispatch, response);
		} catch (error: any) {
			console.error(error);
			resetUserInfo(dispatch);
		}
	};
};

// export const checkIfUserIsLoggedWithFacebook = () => {
// 	return async (dispatch: AppDispatch) => {
// 		try {
// 			const response = await awsGetCurrentAuthenticatedUser();

// 			if (response === undefined || !response) {
// 				throw new FnError('actions-user/checkIfUserIsLoggedWithFacebook()', 'awsGetCurrentAuthenticatedUser() undefined response');
// 			}

// 			if (!response.attributes?.identities) {
// 				dispatch(userIsNotLoggedInWithFacebook());
// 				return;
// 			}

// 			const identities = JSON.parse(response.attributes.identities);

// 			for (let i = 0; i < identities.length; i++) {
// 				if (identities[i].providerName === CognitoHostedUIIdentityProvider.Facebook) {
// 					dispatch(userIsLoggedInWithFacebook());
// 					return;
// 				}
// 			}

// 			dispatch(userIsNotLoggedInWithFacebook());
// 		} catch (error: any) {
// 			console.error(error);
// 			dispatch(userIsNotLoggedInWithFacebook());
// 			resetUserInfo(dispatch);
// 		}
// 	};
// };

export const checkIfUserIsLoggedWithFacebook = () => {

    return async (dispatch: AppDispatch) => {

        try {

            const response = await awsGetCurrentAuthenticatedUser();

            if (response === undefined || !response) {
                throw new FnError("actions-user/checkIfUserIsLoggedWithFacebook()", "awsGetCurrentAuthenticatedUser() undefined response");
            }

            if (!response.attributes?.identities) {

                dispatch( userIsNotLoggedInWithFacebook() );
                return;
            }

            const identities = JSON.parse(response.attributes.identities);

            for(let i=0; i < identities.length; i++) {

                if (identities[i].providerName === CognitoHostedUIIdentityProvider.Facebook) {
    
                    dispatch( userIsLoggedInWithFacebook() );
                    return;
                }
            }

            dispatch( userIsNotLoggedInWithFacebook() );
            
        } catch (error: any) {
            console.log("checkIfUserIsLoggedWithFacebook PROBLEM");            
            console.error(error);
            dispatch( userIsNotLoggedInWithFacebook() );
            resetUserInfo(dispatch);
        }
    };
};

export const saveFacebookPicture = () => {
	return async (dispatch: AppDispatch) => {
		try {
			const response = await awsGetCurrentAuthenticatedUser();

			if (response === undefined || !response) {
				throw new FnError('actions-user/saveFacebookPicture()', 'awsGetCurrentAuthenticatedUser() undefined response');
			}

			if (!response.attributes?.picture) {
				dispatch(saveUserFacebookProfilePicture(undefined));
				return;
			}

			const userProfilePicture = JSON.parse(response.attributes.picture).data.url;
			dispatch(saveUserFacebookProfilePicture(userProfilePicture));
		} catch (error: any) {
			console.error(error);
			dispatch(saveUserFacebookProfilePicture(undefined));
		}
	};
};

export const logout = () => {
	return async (dispatch: AppDispatch) => {
		try {
			const response = await awsSignOutUser();

			if (response === undefined || !response) {
				throw new FnError('actions-user/refreshUserSession()', 'awsSignOutUser() undefined response');
			}

			resetUserInfo(dispatch);
		} catch (error: any) {
			console.error(error);
			resetUserInfo(dispatch);
		}
	};
};

export const saveRoles = (roles?: Role[] | undefined) => {
	return (dispatch: AppDispatch) => {
		dispatch(setRoles(roles));
	};
};

export const savePermissions = (permissions?: Permission[] | undefined) => {
	return (dispatch: AppDispatch) => {
		dispatch(setPermissions(permissions));
	};
};
