import { FC, useState } from "react";

//assets
import { ReactComponent as OpenChapterIconSVG } from './assets/openChapterIcon.svg';
import { ReactComponent as ClosedChapterIconSVG } from './assets/closedChapterIcon.svg';

//style
import {
    Chapters,
} from './style/ChapterListItem.style';

const ChapterListItem: FC<{chapterElement: any, children?: any, isOpen: boolean, collapseHandler: () => void}> = ({chapterElement, children, isOpen, collapseHandler}) => {

    const [isButtonChapterActive, setIsButtonChapterActive] = useState<boolean>(false);

    const { title, pos } = chapterElement;

    const onClickButtonChapterHandler = () => {
        if (isOpen){
            collapseHandler()
            setIsButtonChapterActive(true)
        }
        setIsButtonChapterActive((prevState) => !prevState);       
    };

    return (
        <>
            <Chapters isActive={isButtonChapterActive} onClick={onClickButtonChapterHandler}>
                {pos + 1}. {title ?? "Manca il titolo..."}

                {!(isButtonChapterActive || isOpen) && <OpenChapterIconSVG />}

                {(isButtonChapterActive || isOpen) && <ClosedChapterIconSVG />}
            </Chapters>
            {(isButtonChapterActive || isOpen) && children}
        </>
    )
}

export default ChapterListItem;