import { useEffect, useState } from 'react';

//hooks
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

//services
import { saveZoomLink, saveNotes, saveLessonNotes, saveSubscribtionNotes } from './services/MyLessonsOpenTable.services';

//utils
import { footerElementsCount } from './MyLessonsOpenTable.helper';

//types
import { tableColumnAttr, MyLessonsOpenTableItem, MyLessonsOpenTableProps } from './MyLessonsOpenTable.component.d';

//assets
import { ReactComponent as ChevronLeftIcon } from './assets/chevronLeft.svg';
import { ReactComponent as ChevronRightIcon } from './assets/chevronRight.svg';
import { ReactComponent as DownArrowIcon } from './assets/downArrowIcon.svg';
import { ReactComponent as EmailIcon } from 'assets/imgs/backoffice-page/sendingEmailIcon.svg';
import { ReactComponent as FacebookIcon } from './assets/facebook-icon.svg';
import { ReactComponent as WhatsappIcon } from './assets/whatsapp-icon.svg';
import ZoomLinkModal from './ZoomLinkModal';
import CloseLessonModal from './CloseLessonModal';
import CloseWhatsappLessonModal from './CloseWhatsappLessonModal';

//components
import MultilineSelect from 'components/MultilineSelect/MultilineSelect.component';

//style
import {
    FooterNavigationButton,
	FooterElementsCount,
	Table,
	TableBody,
	TableBodyCell,
	TableBodyRow,
	TableFooter,
	TableHead,
	TableHeadCell,
	TableHeadRow,
	ZoomLinkButton,
	BeginLessonButton,
	CloseLessonButton,
	CloseButton,
	CustomerRow,
	CustomerName,
	UserLink,
	Row,
} from './style/MyLessonsOpenTable.style';
import { getSearchAPLessons } from 'pages/APLessonsPage/services/APLessonsPage.services';

const MyLessonsOpenTableColumns: tableColumnAttr[] = [
	{
		name: 'id',
		title: 'id lez.',
	},
	{
		name: 'productsubscriptionid',
		display: 'hidden',
	},
    {
        name: 'customer',
        title: 'Cliente',
    },
	{
		name: 'customertooltip',
		display: 'hidden',
	},
	{
		name: 'email',
		display: 'hidden',
	},
	{
		name: 'nickname',
		display: 'hidden',
	},
	{
		name: 'phoneNumber',
		display: 'hidden',
	},
	{
		name: 'zoom',
		title: 'Link Zoom',
	},
	{
		name: 'creationDate',
		title: 'Data Acquisto',
		align: 'center',
	},
	{
		name: 'expirationDate',
		title: 'Data Scadenza',
		align: 'center',
	},
    {
        name: 'type',
        title: 'Tipo Lezione',
		align: 'center',
    },
	{
		name: 'duration',
        title: 'Durata',
		align: 'center',
	},
	{
		name: 'description',
		display: 'hidden',
	},
	{
		name: 'coachNotes',
		display: 'hidden',
	},
];

type sortParamsType = {
	column: string | null;
	direction: 'asc' | 'desc' | null;
};

const MyLessonsOpenTable: React.FC<MyLessonsOpenTableProps> = ({ data, totalPages, totalElements, pageSize, personalAssistants, searchLesson }) => {
	const [tableData, setTableData] = useState<MyLessonsOpenTableItem[]>([]);
	const [sortParams, setSortParams] = useState<sortParamsType>({column: null, direction: null});
	const [currentPage, setCurrentPage] = useState(1);

	const [activeLessonForEditing, setActiveLessonForEditing] = useState<number | null>(null);
	const [zoomLink, setZoomLink] = useState<string>('');

	const [closeFailureReasons, setCloseFailureReasons] = useState<string[]>([]);

	const [isZoomLinkModalVisible, setIsZoomLinkModalVisible] = useState<boolean>(false);
	const [isCloseLessonModalVisible, setIsCloseLessonModalVisible] = useState<boolean>(false);
	const [isCloseWhatsappLessonModalVisible, setIsCloseWhatsappLessonModalVisible] = useState<boolean>(false);


	const dispatch = useAppDispatch();

	function isStringDate(inputString: string): boolean {
		if (!inputString) return false;
		
		let hasTwoHyphens: boolean   = false; 
		let hasTwoColons: boolean    = false;
		let hasLetterT: boolean      = false;
	
		for(let i = 0; i < inputString.length; i++) {
			if (inputString[i] === '-') {
				hasTwoHyphens = true;
			}
			if (inputString[i] === ':') {
				hasTwoColons = true;
			}
			if (inputString[i] === 'T') {
				hasLetterT = true;
			}
		}
	
		// If all three conditions are met, return true
		if (hasTwoHyphens && hasTwoColons && hasLetterT) {
			return true;
		} 
		return false;
	}

	const isValidDate = (value: string): boolean => {
		if (!isStringDate(value)) return false;
		const dateObj = new Date(value);
		return !Number.isNaN(dateObj.getTime());
	}

	const convertDate = (value: string): string => {
		const dateObj = new Date(value);
  		return dateObj.toLocaleString('IT-it');
	}

	const formatPhoneNumber = (value: string): string => {
		if (value.length === 0) return value;
		if (value.charAt(0) !== '+') return value;
		if (value.length <= 3) return value;
		var intPrefix = value.substring(0, 3);
		var number = value.substr(3);
		return intPrefix + " " + number;
	}

	const pageChangeHandler = (page: number) => {
		setCurrentPage(page);
	};

	const sortTable = (items: MyLessonsOpenTableItem[], sortBy: string | null, direction: 'asc' | 'desc' | null) => {
		if (sortBy === null) return items;
		const sortedItems = [...items].sort((a, b) => {
			if (a[sortBy] < b[sortBy]) {
			  return -1;
			}
			if (a[sortBy] > b[sortBy]) {
			  return 1;
			}
			return 0;
		});
		
		if (direction === 'desc') {
			return sortedItems.reverse();
		}
		
		return sortedItems;
	};

	const handleSort = (key: string) => {
		if (key === sortParams.column) {
			setSortParams({column: key, direction: sortParams.direction === 'asc' ? 'desc' : 'asc'});
		} else {
			setSortParams({column: key, direction: 'desc'});
		}
	};

	const beginLesson = (idLesson: number) => {
		// eslint-disable-next-line no-restricted-globals
		if (confirm("Vuoi veramente iniziare la lezione #"+idLesson+"?")) {
			closeLesson(idLesson, false);
		}
	}

	const openZoomLinkModal = (idLesson: number, url: string) => {
		setActiveLessonForEditing(idLesson);
		setZoomLink(url);
		setIsZoomLinkModalVisible(true);
	}

	const closeZoomLinkModal = () => {
		setIsZoomLinkModalVisible(false);
	}

	const saveZoomLinkModal = async (zoomLink: string) => {
		if (activeLessonForEditing) {
			try {
				await saveZoomLink(activeLessonForEditing, zoomLink);
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'success';
				snackbarData.message = `Link Zoom salvato con successo!`;
				dispatch(addSnackbar(snackbarData));
				searchLesson(currentPage);
			} catch (error: any) {
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'error';
				snackbarData.message = `C'è stato qualche problema nel salvare il link Zoom!`;
				dispatch(addSnackbar(snackbarData));
				throw error;
			}
		}
	}

	const openCloseLessonModal = (idLesson: number) => {
		setActiveLessonForEditing(idLesson);
		setIsCloseLessonModalVisible(true);
	}

	const closeCloseLessonModal = () => {
		setIsCloseLessonModalVisible(false);
	}

	const openCloseWhatsappLessonModal = (idLesson: number) => {
		setActiveLessonForEditing(idLesson);
		setIsCloseWhatsappLessonModalVisible(true);
	}

	const closeCloseWhatsappLessonModal = () => {
		setIsCloseWhatsappLessonModalVisible(false);
	}

	const saveCloseLessonModal = (customerNotes: string, privateNotes: string) => {
		if (activeLessonForEditing) { 
			try {
				saveNotes(activeLessonForEditing, customerNotes, privateNotes);
				closeLesson(activeLessonForEditing, true);
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'success';
				snackbarData.message = `Lezione chiusa con successo!`;
				dispatch(addSnackbar(snackbarData));
			} catch (error: any) {
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'error';
				snackbarData.message = `C'è stato qualche problema nel chiudere la lezione con le note!`;
				dispatch(addSnackbar(snackbarData));
				throw error;
			}
		}
	}

	const saveCloseWhasappLessonModal = async (customerNotes: string, privateNotes: string) => {
		if (activeLessonForEditing) { 
			try {
				await saveLessonNotes(activeLessonForEditing, customerNotes, privateNotes);
				await closeLesson(activeLessonForEditing, true);
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'success';
				snackbarData.message = `Lezione aggiornata con le note private!`;
				dispatch(addSnackbar(snackbarData));
				setTimeout(() => {searchLesson(currentPage);}, 100);
			} catch (error: any) {
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'error';
				snackbarData.message = `C'è stato qualche problema nell'aggiornare la lezione con le note!`;
				dispatch(addSnackbar(snackbarData));
				throw error;
			}
		}
	}

	const closeLesson = (idLesson: number, onlyConfirmation: boolean) => {
		if (!onlyConfirmation) { 
			try{
				saveNotes(idLesson);
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'success';
				snackbarData.message = `Lezione iniziata con successo!`;
				dispatch(addSnackbar(snackbarData));
			} catch (error: any) {
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'error';
				snackbarData.message = `C'è stato qualche problema nell'iniziare la lezione!`;
				dispatch(addSnackbar(snackbarData));
				throw error;
			}
		}
		searchLesson(currentPage);
	}

	const updateCloseFailureReasons = (idLesson: number, reason: string | undefined) => {
		if ((!reason) || (!idLesson)) return;
		setCloseFailureReasons(prevReasons => {
			const reasonsCopy = [...prevReasons];
			reasonsCopy[idLesson] = reason;
			return reasonsCopy;
		  });
	}

	const closeFailure = async (idLesson: number, idSubscription: number) => {
		// if (!closeFailureReasons[idLesson]) {
		// 	const snackbarData: SnackbarOptions = {};
		// 	snackbarData.type = 'error';
		// 	snackbarData.message = `Devi specificare il motivo per chiudere la lezione come fallimento!`;
		// 	dispatch(addSnackbar(snackbarData));
		// 	return;
		// }
		// eslint-disable-next-line no-restricted-globals
		if (confirm("Vuoi veramente chiudere la lezione #"+idLesson+"?")) {
			try{
				await saveSubscribtionNotes(idSubscription, false, "1"); // closeFailureReasons[idLesson]);
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'success';
				snackbarData.message = `Lezione chiusa con successo!`;
				dispatch(addSnackbar(snackbarData));
				setTimeout(() => {searchLesson(currentPage);}, 100);
			} catch (error: any) {
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'error';
				snackbarData.message = `C'è stato qualche problema nel chiudere la lezione!`;
				dispatch(addSnackbar(snackbarData));
				throw error;
			}
		}
	}

	useEffect(() => {
		console.log('tableData', tableData);
	}, [tableData]);

	useEffect(() => {
		searchLesson(currentPage);
	}, [currentPage]);

	useEffect(() => {
		const sortedTable = sortTable(tableData, sortParams.column, sortParams.direction);
		setTableData(sortedTable);
	}, [sortParams]);

	useEffect(() => {
		const sortedTable = sortTable(data, sortParams.column, sortParams.direction);
		setTableData(sortedTable);
	}, [data]);

    let columns: string[] = [];
	if (data.length > 0) columns = Object.keys(data[0]);

	const closeWithoutSuccessOptions = [
		{ value: "1. Non ha risposto", label: "1. Non ha risposto" },
		{ value: "2. Non si è presentato", label: "2. Non si è presentato" },
		{ value: "3. Non è interessato", label: "3. Non è interessato" },
		{ value: "4. Aveva detto che l'avrebbe comprata ma non l'ha fatto", label: "4. Aveva detto che l'avrebbe comprata ma non l'ha fatto" },
		{ value: "5. È interessato ma non ora (p.e. non ha soldi)", label: "5. È interessato ma non ora (p.e. non ha soldi)" }
	  ];

	return (
		<>
			<Table>
				<TableHead>
					<TableHeadRow>
						{columns.map((c) => (
							<>
							{!(MyLessonsOpenTableColumns.find(x => x?.name === c)?.display && MyLessonsOpenTableColumns.find(x => x?.name === c)?.display === 'hidden') &&
							<TableHeadCell
								key={c}
								onClick={() => handleSort(c)}
							>
								{MyLessonsOpenTableColumns.find(x => x?.name === c)?.title ? MyLessonsOpenTableColumns.find(x => x?.name === c)?.title?.toUpperCase() : c.toUpperCase()}
								{(sortParams.column === c && (sortParams.direction === 'desc' ? <DownArrowIcon transform="rotate(180 0 0)"/> : <DownArrowIcon />))}
							</TableHeadCell>
							}
							</>
						))}
                        <TableHeadCell
                            key={"operazioni"}>
                            
                        </TableHeadCell>
					</TableHeadRow>
				</TableHead>
				<TableBody>
					{tableData.map((row, index) => (
						<TableBodyRow key={index}>
							{columns.map((c) => {
								const value = row[c as keyof MyLessonsOpenTableItem];
								return (
									<>
									{!(MyLessonsOpenTableColumns.find(x => x?.name === c)?.display && MyLessonsOpenTableColumns.find(x => x?.name === c)?.display === 'hidden') &&
									<TableBodyCell
                                        align={MyLessonsOpenTableColumns.find(x => x?.name === c)?.align}
										key={c}
										id={c}
										tooltip={c === 'id' ? row['description'] : undefined}
									>
										{ c === 'customer' ?
										 <CustomerRow>
											<CustomerName><UserLink href={`/reservedArea/users/${value.split("|")[0]}`} target='_blank'>{value}</UserLink></CustomerName>

											{ row['email'] && <EmailIcon title={row['email']+" (click per copiare)"} onClick={() => {navigator.clipboard.writeText(row['email'])}}/>}

											{ row['nickname'] && <FacebookIcon title={(row['nickname'].toLowerCase().startsWith("fb ") ? row['nickname'].slice(3) : row['nickname'])+" (click per copiare)"} onClick={() => {navigator.clipboard.writeText(row['nickname'].toLowerCase().startsWith("fb ") ? row['nickname'].slice(3) : row['nickname'])}}/>}

											{ row['phoneNumber'] && <WhatsappIcon title={formatPhoneNumber(row['phoneNumber'])+" (click per copiare)"} onClick={() => {navigator.clipboard.writeText(row['phoneNumber'])}}/>}

										</CustomerRow>	 :
											isValidDate(value) ? 
											convertDate(value).split(',')[0] :
											 ((c === 'zoom') && (value) && (value.length > 40)) ? 
											value.substring(0, 40)+"…"
											: value
										}
									</TableBodyCell>
									}
									</>
								);
							})}
							<TableBodyCell 
							align={"center"}
							key={"assegna"+row['id']}
							id={"assegna"+row['id']}
							>
                                { row['type'] !== 'Aiuto Attivo' ? <ZoomLinkButton onClick={() => openZoomLinkModal(row['id'], row['zoom'] ? row['zoom'] : '')}>Link Zoom</ZoomLinkButton> : null}
								{ ((row['zoom'] == null) || (row['zoom'] === '')) ?
									row['type'] === 'Aiuto Attivo' ?
									!row['coachNotes'] && <ZoomLinkButton onClick={() => openCloseWhatsappLessonModal(row['id'])}>Aggiungi Note</ZoomLinkButton> : null
									: row['type'] === 'Autostrada' ?
									<CloseLessonButton onClick={() => openCloseLessonModal(row['id'])}>Chiudi Lezione</CloseLessonButton>
									: (row['type'] === 'Specialized' || row['type'] === 'Bonus' || row['type'] === 'Lezione AP') ?
									<BeginLessonButton onClick={() => beginLesson(row['id'])}>Inizia Lezione</BeginLessonButton>
									: null
								}
								{ row['type'] === 'Aiuto Attivo' && row['coachNotes'] && 
								<Row>
									{false && <MultilineSelect title="MOTIVO" options={closeWithoutSuccessOptions} maxWidth="180px" minWidth="180px" dropdownMinWidth="200px" isAbsolutePosition={false} onSelect={(selectedValue) => updateCloseFailureReasons(Number(row['id']), selectedValue)}></MultilineSelect>}
									<CloseButton
											onClick={async () => closeFailure(row['id'], row['productsubscriptionid'])}
											title='Chiudi Lezione'
										>
											{/* &#10006; */}
											Chiudi Lezione
									</CloseButton>
								</Row>}
                            </TableBodyCell>
						</TableBodyRow>
					))}
				</TableBody>
			</Table>
			{totalElements > 0 && (
				<TableFooter>
					<FooterElementsCount>{footerElementsCount({ currentPage, totalPages, totalElements, pageSize })}</FooterElementsCount>
					<FooterNavigationButton
						onClick={() => pageChangeHandler(currentPage - 1)}
						disabled={currentPage === 1}
					>
						<ChevronLeftIcon />
					</FooterNavigationButton>
					<FooterNavigationButton
						onClick={() => pageChangeHandler(currentPage + 1)}
						disabled={currentPage === totalPages}
					>
						<ChevronRightIcon />
					</FooterNavigationButton>
				</TableFooter>
				)}
				{isZoomLinkModalVisible && (
					<ZoomLinkModal
						onCloseButtonModal={closeZoomLinkModal}
						onSaveButtonModal={saveZoomLinkModal}
						url={zoomLink}
					/>
				)}
				{isCloseLessonModalVisible && (
					<CloseLessonModal
						onCloseButtonModal={closeCloseLessonModal}
						onSaveButtonModal={saveCloseLessonModal}
					/>
				)}
				{isCloseWhatsappLessonModalVisible && (
					<CloseWhatsappLessonModal
						personalAssistants={personalAssistants}
						onCloseButtonModal={closeCloseWhatsappLessonModal}
						onSaveButtonModal={saveCloseWhasappLessonModal}
					/>
				)}
		</>
	);
};

export default MyLessonsOpenTable;