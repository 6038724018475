import config from 'configs/config';


export const getCurrentBaseUrlFromConfig = () => {
    
    if (!config?.environment || config.environment === 'development') {

        return (
            {
                newBaseUrl: config.baseTestURL,
                oldBaseUrl: "http://localhost:8080/api/"
            }
        );
    }
    
    if (config.environment === 'test') {

        return (
            {
                newBaseUrl: config.baseTestURL,
                oldBaseUrl: config.baseOldTestURL
            }
        );
    }
    
    if (config.environment === 'production') {

        return (
            {
                newBaseUrl: config.baseProductionURL,
                oldBaseUrl: config.baseOldProductionURL
            }
        );
    }

    return (
        {
            newBaseUrl: "http://localhost:8080/",
            oldBaseUrl: "http://localhost:8080/"
        }
    );
};
