import { FC, useState } from 'react';

//types
import { WithdrawalsTabProps } from './WithdrawalsTab.component.d';
import { PoolTransaction } from 'types/PoolTransaction';

//utils
import { months } from 'configs/months';
import { getPoolsTransactions } from 'services/pools.services';

//components
import WithdrawalsTable from './WithdrawalsTable';

//style
import { Filters, WithdrawalsTabBox } from './style/WithdrawalsTab.component.style';

const WithdrawalsTab: FC<WithdrawalsTabProps> = ({}) => {
	const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
	const [selectedMonth, setSelectedMonth] = useState<number>(new Date().getMonth());
	const [withdrawals, setWithdrawals] = useState<PoolTransaction[]>([]);
	const [totalPages, setTotalPages] = useState<number>(0);
	const [totalElements, setTotalElements] = useState<number>(0);
	const [pageSize, setPageSize] = useState<number>(0);
	const [pageNumber, setPageNumber] = useState<number>(1);

	const onYearChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedYear(+event.currentTarget.value);
	};

	const onMonthChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedMonth(+event.currentTarget.value);
	};

	const nextPageHandler = () => setPageNumber((prevState) => prevState + 1);

	const prevPageHandler = () => setPageNumber((prevState) => prevState - 1);

	const getWithdrawals = async () => {
		try {
			console.log('fromDate', fromDate);
			console.log('toDate', toDate);

			const response = await getPoolsTransactions(['WITHDRAWAL'], fromDate.toISOString(), toDate.toISOString(), pageNumber - 1, 50);
			setWithdrawals(response.content);
			setTotalElements(response.totalElements);
			setTotalPages(response.totalPages);
			setPageSize(response.size);
		} catch (error) {
			console.log(error);
		}
	};

	const years = [];

	for (let i = new Date().getFullYear(); i > new Date().getFullYear() - 5; i--) {
		years.push(i);
	}

	const fromDate = new Date(selectedYear, selectedMonth, 1);
	const toDate = new Date(selectedYear, selectedMonth + 1, 1);

	return (
		<WithdrawalsTabBox>
			<Filters>
				<label htmlFor='year'>Anno:</label>
				<select
					id='year'
					name='year'
					value={selectedYear}
					onChange={onYearChangeHandler}
				>
					{years.map((y) => (
						<option key={y}>{y}</option>
					))}
				</select>
				<label htmlFor='month'>Mese</label>
				<select
					id='month'
					name='month'
					value={selectedMonth}
					onChange={onMonthChangeHandler}
				>
					{months.map((m) => (
						<option
							key={m.name}
							value={m.num}
						>
							{m.name}
						</option>
					))}
				</select>
			</Filters>
			<button onClick={getWithdrawals}>Cerca Prelievi</button>
			<WithdrawalsTable
				data={withdrawals}
				pageSize={pageSize}
				totalElements={totalElements}
				totalPages={totalPages}
				pageNumber={pageNumber}
				nextPageHandler={nextPageHandler}
				prevPageHandler={prevPageHandler}
			/>
		</WithdrawalsTabBox>
	);
};

export default WithdrawalsTab;
