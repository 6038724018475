import styled, { css } from 'styled-components';

//style
import colors from 'style/palette/palette';
import { pointer } from 'd3';

export const SingleBadge = styled.button<{ name: 'cheater' | 'banned' | 'problematic'; isSwitchedOn?: boolean | undefined; clickHandler?: () => void, isClickable?: boolean}>`
	display: flex;
	background-color: ${colors.transparent};
	border: 0;
	cursor: ${({ isClickable }) => isClickable ? 'pointer' : 'default'};
	
	${({ name, isSwitchedOn }) =>
		isSwitchedOn &&
		css`
			> svg path {
				fill: ${name === 'banned' ? colors.red : name === 'cheater' ? colors.black : name === 'problematic' ? colors.orange : colors.primaryColor};
				fill-opacity: 1;
			}
		`}
`;
