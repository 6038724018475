import { callGET, callPUT, callPOST, callDELETE } from 'network/network';

import { PaymentsSearchParams } from './PaymentsPage.services.d';

const _PAYMENTS_SEARCH_PATH = 'v1/purchases';
const _PAYMENT_DETAILS_PATH = 'v1/purchases/{id}';
const _PAYMENT_REFUND_PATH = 'v1/purchases/{purchaseId}/refund';
const _PAYMENT_ASSIGN_PATH = 'v1/purchases/{id}/assign/{userId}';
const _TRANSACTION_UPDATE_PATH = 'v1/transactions/{transactionId}';
const _PAYMENT_DELETE_PATH = ''; // TO BE PROVIDED
const _SINGLE_PURCHASE_PAYPAL_CHECK = 'v1/purchases/{purchaseId}/paypal/single';
const _SERVICE_PURCHASE_PAYPAL_CHECK = 'v1/purchases/{purchaseId}/paypal/service';
const _PURCHASE_CHECK_SUBSCRIPTION = 'v1/purchases/checkPurchaseForServiceSubscription/{productSubscriptionId}';
const _PURCHASE_CHECK = 'v1/publicpurchase/single/paypal/capture/{transactionId}';
const _PRODUCTS_SEARCH_PATH = 'v1/products';
const _PRODUCTS_ALL_MINIMAL_PATH = 'v1/products/minimal';


export const getSearchPayments = async (paymentsSearchParams: PaymentsSearchParams) => {
	try {
		const response = await callGET(_PAYMENTS_SEARCH_PATH, true, paymentsSearchParams);

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const getPayment = async (purchaseId: number) => {
	try {
		const response = await callGET(_PAYMENT_DETAILS_PATH.replace(/{id}/g, purchaseId.toString()), true);

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const refundPayment = async (purchaseId: number) => {
	try {
		const response = await callPUT(_PAYMENT_REFUND_PATH.replace(/{purchaseId}/g, purchaseId.toString()), {}, true);

		//204

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const deletePayment = async (purchaseId: number) => {
	try {
		const response = await callDELETE(_PAYMENT_REFUND_PATH.replace(/{purchaseId}/g, purchaseId.toString()), true);

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const assignPayment = async (purchaseId: number, userId: number) => {
	try {
		const response = await callPUT(_PAYMENT_ASSIGN_PATH.replace(/{id}/g, purchaseId.toString()).replace(/{userId}/g, userId.toString()), {}, true);

		//204

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const completePaymentTransaction = async (transactionId: number) => {
	try {
		const response = await callPUT(_TRANSACTION_UPDATE_PATH.replace(/{transactionId}/g, transactionId.toString()) + '?status=COMPLETED', {}, true);

		//204

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const getSinglePurchasePaypalDetails = async (purchaseId: number) => {
	try {
		const response = await callGET(_SINGLE_PURCHASE_PAYPAL_CHECK.replace(/{purchaseId}/g, purchaseId.toString()), true);

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const getServicePurchasePaypalDetails = async (purchaseId: number) => {
	try {
		const response = await callGET(_SERVICE_PURCHASE_PAYPAL_CHECK.replace(/{purchaseId}/g, purchaseId.toString()), true);

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const checkPurchaseSubscription = async (productSubscriptionId: number) => {
	try {
		const response = await callPOST(_PURCHASE_CHECK_SUBSCRIPTION.replace(/{productSubscriptionId}/g, productSubscriptionId.toString()), {}, true);

		//204

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const checkPurchase = async (transactionId: number) => {
	try {
		const response = await callPOST(_PURCHASE_CHECK.replace(/{transactionId}/g, transactionId.toString()), {}, true);

		//204

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const getAllProductsMinimal = async () => {
	try {
		const response = await callGET(_PRODUCTS_ALL_MINIMAL_PATH, true, {});

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};