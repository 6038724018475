import config from "configs/config";

// config
import devAuthConfig from 'configs/aws/cognito-config-development';
import testAuthConfig from 'configs/aws/cognito-config-test';
import prodAuthConfig from 'configs/aws/cognito-config-production';


let finalAuthConfiguration;
if (config.environment === "development") finalAuthConfiguration = devAuthConfig;
if (config.environment === "test") finalAuthConfiguration = testAuthConfig;
if (config.environment === "production") finalAuthConfiguration = prodAuthConfig;

const baseCookieUrl = finalAuthConfiguration?.cookieStorage.domain;

export const setCookie = (cname: string, cvalue: string | null, exdays: number, path: string) => {
  const data = new Date();
  data.setTime(exdays);
  let expires = "expires=" + data;
  document.cookie = cname + "=" + cvalue + ";" + expires + ";domain=" + baseCookieUrl + ";path=" + path + "; secure";
};

export const getCookie = (cname: string) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const deleteAllNamedCookie = (cname: string) => {
  // let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    let suppArr = [];
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
        c = c.substring(1);      
        }

  suppArr.push(c.split('=')[0]);
  }
// console.log("FOUND COOKIES: ",suppArr);
  const cookieToDelete = suppArr.filter((el: any) => el.includes(cname));
// console.log("TO BE DELETED COOKIES:",cookieToDelete);
  for (let i=0; i<cookieToDelete.length; i++) {
    deleteCookie(cookieToDelete[i])
  }
  return "";
};

export const getCookieByStrings = (cname: string, cname2: string) => {
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(cname) >= 0 && c.indexOf(cname2) >= 0) {
            return c.substring(c.indexOf('=')+1, c.length);
        }
    }
    return "";
};

// delete all cookies that contain a string in the name and don't contain other 2 strings in the name
export const deleteAllNamedCookieByStrings = (cname: string, cname2: string, cname3: string) => {
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    let suppArr = [];
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
        c = c.substring(1);      
        }

  suppArr.push(c.split('=')[0]);
  }
// console.log("FOUND COOKIES: ",suppArr);
    const cookieToDelete = suppArr.filter((el: any) => el.includes(cname) && !(el.includes(cname2) || el.includes(cname3) || el.includes("accessToken")));
// console.log("TO BE DELETED COOKIES:",cookieToDelete);
    for (let i=0; i<cookieToDelete.length; i++) {
        deleteCookie(cookieToDelete[i])
    }
    return "";
}

export const checkCookie = (cname: string) => {
  let val = getCookie(cname);
  if (val === "" || val === null) {
    return false;
  } else {
    return true;
  }
};

export const deleteCookie = (cname: string) => {
  document.cookie = cname + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=" + baseCookieUrl + ";path= /;maxAge=-99999999;SameSite=Lax;secure";
};

