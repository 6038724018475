import colors from 'style/palette/palette';
import styled from 'styled-components';

export const EditableTextAreaBox = styled.input`
	background-color: transparent;
	border: none;
	width: auto;
	font-size: 1rem;
	color: ${colors.white};

	:hover,
	:focus {
		background-color: ${colors.blueGrey};
		color: ${colors.black};
	}
`;

export const TitleModify = styled.div`
	margin-left: -2rem;
`;

export const TextBlock = styled.div<{isUserAdmin: boolean}>`

	cursor: ${({isUserAdmin}) => isUserAdmin ? 'pointer' : ' default'};
	position: relative;
`;

export const TextBox = styled.div`
	cursor: pointer;
	overflow-wrap: anywhere;
`;

export const EditBox = styled.div`

	display: flex;
	align-items: center;
	justify-content: center;

	width: 100% !important;
	height: 100%;
	min-height: 25px;

	border-radius: 15px;
	border: 2px solid ${colors.white070};
	background: ${colors.black087};

	font-size: 25px;
	color: ${colors.white070};

	cursor: pointer;
	position: absolute;
	top: 0;
	left: 0;
`;
