import styled, { css } from 'styled-components';

export const Title = styled.button<{isActive?: boolean}>`

display: flex;
justify-content: flex-start;
align-items: center;

width: 100%;
border: none;

padding: 13px 20px 13px 30px;

font-weight: 500;
font-size: 13px;

color: #FFFFFF;
background: #29353E;

cursor: pointer;

    ${({isActive}) => isActive && 
        css`
            background: #FFE662;

            color: #000000;
        `
    }
`;