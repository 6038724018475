import { FC } from 'react';
import { Label } from '../style/ModifyTestimonialModal.component.style';

//types
import { TestimonialVideoProps } from './TestimonialVideo.component.d';

//style
import { LessonVideoBox, VideoUrl } from './style/TestimonialVideo.component.style';

const TestimonialVideo: FC<TestimonialVideoProps> = ({ videoUrl, setLocalVideoUrl }) => {
	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLocalVideoUrl(event.currentTarget.value);
	};
	return (
		<LessonVideoBox>
			<Label>Video</Label>
			<VideoUrl
				value={videoUrl}
				onChange={onChangeHandler}
				placeholder='Inserire url ftp'
			></VideoUrl>
		</LessonVideoBox>
	);
};

export default TestimonialVideo;
