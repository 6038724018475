import styled from 'styled-components';

// utils
import { getEnvironmentLinkUrlForCSS } from 'configs/config.helper';

// types
import { SubmenuCardProps } from './UserProfileSubmenu.style.d';

// assets
import mainBackground from 'assets/imgs/main-background.svg';

// style
import devices from 'style/breakpoints/breakpoints';
import colors from 'style/palette/palette';

const backgroundUrl = getEnvironmentLinkUrlForCSS(mainBackground);


export const Container = styled.div<SubmenuCardProps>`

    width: 100%;
    max-width: 390px;

    padding: 0 20px;

    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 50;

    transform: translate(-50%, -55%);

    display: ${({ isOpen }) => isOpen ? "block" : "none"};

    @media ${devices.tablet} {

        width: auto;
        max-width: none;

        margin: 0;
        padding: 0;

        position: absolute;
        top: 100%;
        left: auto;
        right: -20px;

        transform: translate(0, 0);
    }
`;

export const Title = styled.h2`

    margin: 0 0 30px 10px;
    padding: 0;

    font-size: 28px;
    font-weight: 400;
    
    color: ${colors.white};
`;

export const SubmenuCard = styled.div`

    width: 100%;

    padding: 20px 0;
    border-radius: 15px;

    background: ${colors.white};
    box-shadow:
        0 0 0 1px ${colors.white012};

    a {

        text-decoration: none;
    }

    @media ${devices.tablet} {

        min-width: 250px;
        width: auto;

        padding: 30px;
    }
`;

export const MenuBackdrop = styled.div`

    display: none;

    width: 100vw;
    height: 100vh;
    
    background: ${colors.veryDarkGrey};

	position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
	z-index: 20;

    @media ${devices.appMinWidth} {

        display: block;
    }

    @media ${devices.tablet} {

        background: ${colors.transparent};
    }
`;
