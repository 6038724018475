import { FC, useEffect, useState } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsMounted } from 'usehooks-ts';

//types
import { AssignPaymentModalProps } from './AssignPaymentModal.component.d';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { User } from 'types/User';

//components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';

//utils
import { getSearchUsers } from 'pages/UsersPage/services/UsersPage.services';

//assets
import { ReactComponent as SearchIconSVG } from '../../assets/searchIcon.svg';
import { ReactComponent as ClearButtonIconSVG } from '../../assets/clearButtonIcon.svg';

//style
import { CardContent, ProductsColumn, Title, ProductName, ProductTable } from './style/AssignPaymentModal.component.style';
import { SearchInput, SearchClearButton, SearchButton } from 'pages/BackOfficePage/style/BackOfficePage.style';
import { SearchUsersBox } from 'pages/PaymentsPage/style/PaymentsPage.style';
import { assignPayment } from 'pages/PaymentsPage/services/PaymentsPage.services';

const AssignPaymentModal: FC<AssignPaymentModalProps> = ({ onCloseButtonModal, purchase }) => {
	const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);

	const [searchUserInputValue, setSearchUserInputValue] = useState<string>('');
	const [searchUserInput, setSearchUserInput] = useState<string>('');
	const [pageUserNumber, setPageUserNumber] = useState<number>(1);
	const [searchUserResults, setSearchUserResults] = useState<User[]>();
	const [totalUserPages, setTotalUserPages] = useState<number>(0);
	const [totalUserElements, setTotalUserElements] = useState<number>(0);
	const [pageUserSize, setPageUserSize] = useState<number>(0);
	const [selectedUserId, setSelectedUserId] = useState(-1);

	const dispatch = useAppDispatch();
	const isMounted = useIsMounted();

	const clearSearchHandler = () => {
		setSearchUserInput('');
		setSearchUserInputValue('');
	};

	const searchChangeHandler = (searchValue: any) => {
		setSearchUserInputValue(searchValue.target.value);
	};

	const nextPageHandler = () => setPageUserNumber((prevState) => prevState + 1);

	const prevPageHandler = () => setPageUserNumber((prevState) => prevState - 1);

	const searchKeyHandler = (event: any) => {
		if (event.keyCode === 27) {
			clearSearchHandler();
		}
		if (event.keyCode === 13) {
			setSearchUserInput(event.target.value);
		}
	};

	const searchButtonHandler = () => {
		if (searchUserInputValue === '') {
			setSearchUserResults([]);
			setPageUserNumber(1);
			return;
		}
		searchUsers();
	};

	const closeButtonHandler = () => {
		if (onCloseButtonModal) onCloseButtonModal();
	};

	const selectionChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedUserId(+event.currentTarget.value);
	};

	const assignPurchaseHandler = async () => {
		if (!selectedUserId) return;
		if (window.confirm('Associare il pagamento ' + purchase.id + ' di ' + purchase.product.name + " all'utente " + selectedUserId + '?')) {
			try {
				await assignPayment(purchase.id, selectedUserId);
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'success';
				snackbarData.message = `Pagamento associato con successo!`;
				dispatch(addSnackbar(snackbarData));
				if (onCloseButtonModal) onCloseButtonModal();
			} catch (error: any) {
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'error';
				snackbarData.message = `C'è stato un problema nell'associazione del pagamento!`;
				dispatch(addSnackbar(snackbarData));
				throw error;
			}
		}
	};

	const searchUsers = async () => {
		const snackbarData: SnackbarOptions = {};

		try {
			if (searchUserInput.length === 0) return;
			const response = await getSearchUsers({ q: searchUserInput, page: pageUserNumber - 1, size: 100 });
			console.log(response.content);

			setSearchUserResults(response.content);
			setTotalUserElements(response.totalElements);
			setTotalUserPages(response.totalPages);
			setPageUserSize(response.size);
		} catch (error: any) {
			snackbarData.type = 'error';
			snackbarData.message = 'Errore nella ricerca degli utenti';
			dispatch(addSnackbar(snackbarData));
			if (!isMounted()) return;
		}
	};

	useEffect(() => {
		searchUsers();
	}, [searchUserInput, pageUserNumber]);

	const isSearchButtonVisible: boolean = searchUserInputValue !== '';

	return (
		<BaseModal
			isBackdropClickActive={true}
			onClose={closeButtonHandler}
			isCloseButtonClicked={isCloseButtonClicked}
		>
			<BaseCard
				flavor='outlined'
				maxWidth='700px'
			>
				<CardContent>
					<ProductTable>
						<thead>
							<tr>
								<th>ID</th>
								<th>PRODOTTO</th>
								<th>DATA</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>{purchase.id}</td>
								<td>{purchase.product.name}</td>
								<td>{new Date(purchase.creationDate).toLocaleString()}</td>
							</tr>
						</tbody>
					</ProductTable>

					<SearchUsersBox>
						<SearchInput
							onKeyDown={searchKeyHandler}
							onChange={searchChangeHandler}
							placeholder='Ricerca Utente'
							value={searchUserInputValue}
						/>

						{isSearchButtonVisible && (
							<SearchClearButton onClick={clearSearchHandler}>
								<ClearButtonIconSVG />
							</SearchClearButton>
						)}

						<SearchButton onClick={searchButtonHandler}>
							<SearchIconSVG />
						</SearchButton>
					</SearchUsersBox>

					{searchUserResults && (
						<select
							id='users'
							value={selectedUserId}
							onChange={selectionChangeHandler}
						>
							<option></option>
							{searchUserResults.map((su) => (
								<option
									value={su.id}
									key={su.id}
								>{`${su.id} | ${su.name} ${su.surname}`}</option>
							))}
						</select>
					)}
					<button
						onClick={assignPurchaseHandler}
						disabled={selectedUserId === -1}
					>
						Associa Pagamento
					</button>
				</CardContent>
			</BaseCard>
		</BaseModal>
	);
};

export default AssignPaymentModal;
