import styled, { css } from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';

export const ProductGeneralBox = styled.div<{ isDragging: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	width: 290px;

	padding: 1rem;

	border: 2px solid #29353e;
	border-radius: 7px;

	opacity: ${({ isDragging }) => (isDragging ? 0 : 1)};

	/* @media ${devices.tablet} {
		width: 320px;

		padding: 0 25px 25px;
	} */
`;

export const ProductTitle = styled.h2`
	margin: 0;
	padding: 0;

	/* min-height: 40px; */

	font-weight: 400;
	font-size: 15px;

	color: #ffc000;

	text-transform: uppercase;
	cursor: grab;
`;

export const ProductInnerBox = styled.div`
	margin: 16px 0 0;
	display: flex;
	align-items: stretch;
	justify-content: center;
	flex-grow: 1;

	gap: 17px;
`;

export const CourseImage = styled.img`
	max-width: 115px;
`;

export const ProductInfoBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
`;

export const ProductIconBox = styled.div`
	margin: -15px 0 0 20px;

	display: flex;
	justify-content: center;
	align-items: center;
`;

export const InfoIconBox = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 42px;
	height: 42px;

	cursor: pointer;

	background: #000000;

	border: 1px solid #ffffff;
	border-radius: 100px;
	box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
`;

export const ProductDescriptionBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;

	flex-grow: 1;
`;

export const ProductDesciptionEllipsis = styled.div`
	overflow: hidden;
`;

export const ProductDesciption = styled.p`
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 6;
	-webkit-box-orient: vertical;

	overflow: hidden;

	margin: 0;
	padding: 0;

	font-weight: 400;
	font-size: 12px;
	line-height: 19px;

	color: #ffffff;
`;

export const Handle = styled.div`
	> svg {
		cursor: move;
		width: 15px;
		height: 15px;
		> path {
			fill: ${colors.white};
		}
	}
`;
