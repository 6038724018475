import styled, { css } from 'styled-components';

//style
import colors from 'style/palette/palette';

export const VisibleBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 20px;
`;

export const VisibleIcon = styled.button<{ visible: boolean; canToggle: boolean }>`
	display: flex;
	background-color: ${colors.transparent};
	border: none;

	${({ canToggle }) =>
		canToggle &&
		css`
			cursor: pointer;
		`}

	${({ visible }) =>
		visible &&
		css`
			> svg path {
				fill: ${colors.green};
				fill-opacity: 1;
			}
		`}

	${({ visible }) =>
		!visible &&
		css`
			> svg path {
				fill: ${colors.red};
				fill-opacity: 1;
			}
		`}
`;
