import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Permission } from 'types/Permission';
import { Role } from 'types/Role';

interface UserState {
	tokenID: string | undefined;
	id: number | undefined;
	email: string | undefined;
	name: string | undefined;
	surname: string | undefined;
	isLoggedInWithFacebook: boolean | undefined;
	facebookProfilePicture: string | undefined;
	roles: Role[] | undefined;
	permissions: Permission[] | undefined;
}

// initial slice state
const initialState: UserState = {
	tokenID: undefined,
	id: undefined,
	email: undefined,
	name: undefined,
	surname: undefined,
	isLoggedInWithFacebook: undefined,
	facebookProfilePicture: undefined,
	roles: undefined,
	permissions: undefined,
};

const userSlice = createSlice({
	name: 'user',
	initialState,

	reducers: {
		setTokenID: (state, action: PayloadAction<string | undefined>) => {
			state.tokenID = action.payload;
		},

		setId: (state, action: PayloadAction<number | undefined>) => {
			state.id = action.payload;
		},

		setEmail: (state, action: PayloadAction<string | undefined>) => {
			state.email = action.payload;
		},

		setName: (state, action: PayloadAction<string | undefined>) => {
			state.name = action.payload;
		},

		setSurname: (state, action: PayloadAction<string | undefined>) => {
			state.surname = action.payload;
		},

		setIsLoggedInWithFacebook: (state, action: PayloadAction<boolean | undefined>) => {
			state.isLoggedInWithFacebook = action.payload;
		},

		setFacebookProfilePicture: (state, action: PayloadAction<string | undefined>) => {
			state.facebookProfilePicture = action.payload;
		},

		setRoles: (state, action: PayloadAction<Role[] | undefined>) => {
			state.roles = action.payload;
		},

		setPermissions: (state, action: PayloadAction<Permission[] | undefined>) => {
			state.permissions = action.payload;
		},
	},
});

// slice actions mapping
export const { setTokenID, setId, setEmail, setName, setSurname, setIsLoggedInWithFacebook, setFacebookProfilePicture, setRoles, setPermissions } = userSlice.actions;

// reducer for store
export default userSlice.reducer;
