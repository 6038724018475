import { FC, useEffect, useState } from 'react';

//hooks
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';
import useHasRole from 'hooks/useHasRole';
import useHasPermission from 'hooks/useHasPermission';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

//utils
import { getCoaches, getSearchHighwayRoutes, assignLessonToCoach, closeHighwayRoute } from './services/HighwayRoutesPage.services';

//types
import { TitleBreadCrumbSettings } from 'components/DashboardTitleSection/DashboardTitleSection.component.d';
import { TabSelectorSettings, TabSelectorItem } from 'components/TabSelector/TabSelector.component.d';
import { HighwayRoutesNewTableItem } from './HighwayRoutesNewTable/HighwayRoutesNewTable.component.d';
import { HighwayRoutesOpenTableItem } from './HighwayRoutesOpenTable/HighwayRoutesOpenTable.component.d';
import { HighwayRoutesClosedTableItem } from './HighwayRoutesClosedTable/HighwayRoutesClosedTable.component.d';
import { PersonalAssistant } from './HighwayRoutesPage.d';
import { HighwayRoutesOpenDetailsTableItem } from './HighwayRoutesOpenDetailsTable/HighwayRoutesOpenDetailsTable.component.d';
import { User } from 'types/User.d';


//components
import DashboardTitleSection from 'components/DashboardTitleSection';
import TabSelector from 'components/TabSelector/TabSelector.component';
import HighwayRoutesNewTable from './HighwayRoutesNewTable/HighwayRoutesNewTable.component';
import HighwayRoutesOpenTable from './HighwayRoutesOpenTable/HighwayRoutesOpenTable.component';
import HighwayRoutesOpenDetailsTable from './HighwayRoutesOpenDetailsTable/HighwayRoutesOpenDetailsTable.component';
import HighwayRoutesClosedTable from './HighwayRoutesClosedTable/HighwayRoutesClosedTable.component';


//assets
import { ReactComponent as SearchIconSVG } from './assets/searchIcon.svg';
import { ReactComponent as ClearButtonIconSVG } from './assets/clearButtonIcon.svg';
import CloseHighwayRouteModal from './HighwayRoutesOpenDetailsTable/CloseHighwayRouteModal';


//style
import { BaseContentCard } from 'style/wrappers/wrappers';
import {
    Page,
    SearchChannelBox,
    SearchInput,
    SearchClearButton,
    SearchButton,
    AssignBox,
    AssignSelect,
    AssignButton,
    TableDetailsHeader,
    TableDetailsName,
    TableDetailsBackButton,
} from './style/HighwayRoutesPage.style';

const HighwayRoutesPage: FC<{}> = () => {
	const [searchInput, setSearchInput] = useState<any>('');
    const [searchNewResults, setSearchNewResults] = useState<HighwayRoutesNewTableItem[]>([]);
    const [searchOpenResults, setSearchOpenResults] = useState<HighwayRoutesOpenTableItem[]>([]);
    const [originalOpenResults, setOriginalOpenResults] = useState<any>([]);
    const [searchClosedResults, setSearchClosedResults] = useState<HighwayRoutesClosedTableItem[]>([]);
    const [openDetailsResults, setOpenDetailsResults] = useState<HighwayRoutesOpenDetailsTableItem[]>([]);
    const [hiddenOpenDetails, setHiddenOpenDetails] = useState<number[]>([]);
    const [originalClosedResults, setOriginalClosedResults] = useState<any>([]);
    const [totalPages, setTotalPages] = useState<number>(0);
	const [totalElements, setTotalElements] = useState<number>(0);
	const [pageSize, setPageSize] = useState<number>(0);
    const [coaches, setCoaches] = useState<any[]>([]);
    const [coachDetails, setCoachDetails] = useState<number | null>(null);
    const [personalAssistants, setPersonalAssistants] = useState<any[]>([]);
    const [tabSelectorValue, setTabSelectorValue] = useState<string>('');
    const [assignAssistantSelect, setAssignAssistantSelect] = useState<number>(0);
    const [selectedIds, setSelectedIds] = useState<number[]>([]);
	const [isCloseHighwayRouteModalVisible, setIsCloseHighwayRouteModalVisible] = useState<boolean>(false);
    const [activeHighwayRouteForEditing, setActiveHighwayRouteForEditing] = useState<number | null>(null);
    const [successHighwayRouteForEditing, setSuccessHighwayRouteForEditing] = useState<boolean>(false);

    const { brand } = useAppSelector((state) => state.ui);

    const isSuperAdmin = useHasRole('SUPERADMIN');
	const isAssistentePersonale = useHasRole('ASSISTENTE-PERSONALE');
	const isSupervisoreAutostrada = useHasRole('SUPERVISORE_AUTOSTRADA');
	const isAdminAutostrada = useHasRole('ADMIN_AUTOSTRADA');

	interface CoachData {
		id: string;
		name: string;
		totalLessons: number;
		totalRatedLessons: number;
		averageRating: number;
        totalPacks: number;
        totalSuccess: number;
        totalFailure: number;
	}

	const dispatch = useAppDispatch();

    const clearSearchHandler = () => {
		setSearchInput('');
	};

	const searchChangeHandler = (searchValue: any) => {
		setSearchInput(searchValue.target.value);
	};

	const searchKeyHandler = (event: any) => {
		if (event.keyCode === 27) {
			clearSearchHandler();
		}
	};

	const openCloseHighwayRouteModal = (idHighwayRoute: number, success: boolean) => {
		setActiveHighwayRouteForEditing(idHighwayRoute);
        setSuccessHighwayRouteForEditing(success);
		setIsCloseHighwayRouteModalVisible(true);
	}

	const closeCloseHighwayRouteModal = () => {
		setIsCloseHighwayRouteModalVisible(false);
        setActiveHighwayRouteForEditing(null);
	}

	const saveCloseHighwayRouteModal = (notes: string) => {
		if (activeHighwayRouteForEditing) closeSubscription(activeHighwayRouteForEditing, successHighwayRouteForEditing, notes);
	}

    const getAllCoaches = async () => {
		var coaches = [];
		const response = await getCoaches();
		if (response) {
			coaches = response.filter((coach: any) => coach.deleted === false);
		}
		setCoaches(coaches);
	}
	
	function getCoachData(closedResults: any): CoachData[] {
		const coachMap = new Map<string, CoachData>();
		
		closedResults.forEach((result: any) => {
			const coach = result.productLessonPack.coach;
			const coachId = coach.userId;
			const lessonCount = result.productLessonPack.lessons.length;
		
			if (!coachMap.has(coachId)) {
				coachMap.set(coachId, {
					id: coachId,
					name: coach.name,
					totalLessons: 0,
					totalRatedLessons: 0,
					averageRating: 0,
                    totalPacks: 0,
                    totalSuccess: 0,
                    totalFailure: 0,
				});
			}
		
			const coachData = coachMap.get(coachId);
			if (coachData) {
                coachData.totalLessons += lessonCount;
                coachData.totalPacks += 1; 
                if (result.productLessonPack.status === 'CLOSED_SUCCESS') {
                    coachData.totalSuccess += 1;
                }
                if (result.productLessonPack.status === 'CLOSED_FAILURE') {
                    coachData.totalFailure += 1;
                }
            }
		
			result.productLessonPack.lessons.forEach((lesson: any) => {
			if ((lesson.rating !== null)&&(coachData)&&(coachData.averageRating !== null)) {
				coachData.totalRatedLessons += 1;
				coachData.averageRating += lesson.rating;
			}
			});
		});
		
		return Array.from(coachMap.values()).map((coachData) => ({
			...coachData,
			averageRating: (coachData.totalRatedLessons>0) ? (coachData.averageRating / coachData.totalRatedLessons) : 0,
		}));
	}

	function extractLessonsByCoachIdAndUserId(
		results: any[],
		coachId: number
	  ): any[] {
		const lessons: any[] = [];
	  
		results.forEach((result) => {
			if (result.productLessonPack.coach.userId === coachId) {
                var closedLessons = 0;
                var openLessons = 0;
                result.productLessonPack.lessons.forEach((lesson: any) => {
                    if (lesson.status === 'CLOSED') {
                        closedLessons += 1;
                    } else {
                        openLessons += 1;
                    }
			    });
                lessons.push({ highwayid: result.productLessonPack.productSubscriptionId, lessons: closedLessons, openLessons: openLessons, user: result.user, creationDate: result.creationDate, assistant: result.productLessonPack.coach.userId+" | "+result.productLessonPack.coach.name });
			}
		  });
	  
		return lessons;
	  }

    const searchNewRoutes = async (page: number) => {
        if (!brand) return;
        var lessons = [];
		const response = await getSearchHighwayRoutes({ q: searchInput, assigned: false, types: [3], brandCode: brand.code });
		if (response) {
			lessons = response;
		}
		setSearchNewResults(
			lessons.map((lesson: any ) => ({ id: lesson.productLessonPack.productSubscriptionId, customer: lesson.user.id+" | "+lesson.user.name+" "+lesson.user.surname, purchaseDate: lesson.creationDate }))
		);

		getAllCoaches();

		setTotalElements(response.totalElements);
		setTotalPages(response.totalPages);
		setPageSize(response.size);    
    };

    const searchOpenRoutes = async (page: number) => {
        if (!brand) return;
        var lessons = [];
		const response = await getSearchHighwayRoutes({ q: searchInput, assigned: true, statuses: ["OPENED"], types: [3], brandCode: brand.code });
		if (response) {
			lessons = response; 
		}

		const openTable = getCoachData(lessons);

        setOriginalOpenResults(lessons);
		setSearchOpenResults(
			openTable.map((assistant: any ) => ({ id: assistant.id, assistant: assistant.name, openroutes: assistant.totalPacks }))
		);

		setTotalElements(response.totalElements);
		setTotalPages(response.totalPages);
		setPageSize(response.size);
    };

    const searchAssistantDetails = async (assistantId: number) => {
        setCoachDetails(assistantId);
        const detailsTable = extractLessonsByCoachIdAndUserId(originalOpenResults, assistantId);
        setOpenDetailsResults(
			detailsTable.map((lesson: any ) => ({ highwayid: lesson.highwayid, customer: lesson.user.id+" | "+lesson.user.name+" "+lesson.user.surname, assistant: lesson.assistant, paymentdate: lesson.creationDate, lessons: lesson.lessons, openlessons: lesson.openLessons }))
        );
        // setTotalElements(response.totalElements);
        // setTotalPages(response.totalPages);
        // setPageSize(response.size);
    }

    const handleCloseDetails = () => {
        setCoachDetails(null);
        setHiddenOpenDetails([]);
		setOpenDetailsResults([]);
    }

    const searchClosedRoutes = async (page: number) => {
        if (!brand) return;
        var lessons = [];
		const response = await getSearchHighwayRoutes({ q: searchInput, assigned: true, statuses: ['CLOSED_SUCCESS', 'CLOSED_FAILURE'], types: [3], brandCode: brand.code });
		if (response) {
			lessons = response;
		}

		setOriginalClosedResults(lessons);

		const closedTable = getCoachData(lessons);
		setSearchClosedResults(
			closedTable.map((assistant: any ) => ({ id: assistant.id, assistant: assistant.name, closedroutes: assistant.totalSuccess, lessons: (assistant.totalPacks>0) ? (assistant.totalLessons/assistant.totalPacks) : 0, rating: assistant.averageRating, failed: assistant.totalFailure }))
		);

		setTotalElements(response.totalElements);
		setTotalPages(response.totalPages);
		setPageSize(response.size);
    };

    const handleAssignAssistantSelectChange = (value: string) => {
        setAssignAssistantSelect(Number(value));
    };

    const getCheckedIds = (checkboxes: number[]) => {
        setSelectedIds(checkboxes);
    }

    const assignLessonTo = async () => {
		try {
			await selectedIds.forEach(async (id) => {
				await assignLessonToCoach(id, assignAssistantSelect);
			});
			const snackbarData: SnackbarOptions = {};
			snackbarData.type = 'success';
			snackbarData.message = `Autostrade assegnate con successo!`;
			dispatch(addSnackbar(snackbarData));
			setTimeout(() => {
                getAllCoaches();
                searchNewRoutes(0);
            }, 2000);
		} catch (error: any) {
			const snackbarData: SnackbarOptions = {};
			snackbarData.type = 'error';
			snackbarData.message = `C'è stato qualche problema nell'assegnazione delle autostrade!`;
			dispatch(addSnackbar(snackbarData));
			throw error;
		}
    };

    const closeSubscription = async (id: number, success: boolean, notes: string) => {
			try {
				await closeHighwayRoute(id, success, notes);
                await searchOpenRoutes(0);
                setHiddenOpenDetails([...hiddenOpenDetails, id]);
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'success';
				snackbarData.message = `Percorso chiuso correttamente!`;
				dispatch(addSnackbar(snackbarData));
                // if (coachDetails) {
                //     setTimeout(async () => {
                //         setHiddenOpenDetails([]);
                //         searchAssistantDetails(coachDetails);
                //     }, 2000);
                // }
			} catch (error: any) {
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'error';
				snackbarData.message = `C'è stato qualche problema nella chiusura del percorso!`;
				dispatch(addSnackbar(snackbarData));
				throw error;
			}
    }

    useEffect(() => {
		const prepareAssistantsList = async () => { 
            if (originalOpenResults.length === 0) 
				{
					await searchOpenRoutes(0);
					return;
				}
			if (coaches.length === 0) return;
			if (searchOpenResults.length === 0) return;
            
			var assistants: any[] = [];
			if (coaches) {
				assistants = coaches.filter((item: any) => {
					if (item.user.roles) {
						return item.user.roles.some((role: any) => role.role === 'ASSISTENTE-PERSONALE');
					}
					return false;
				});
			} 
			const assistantsWithLessonCount = assistants.map((assistant) => {
				var assistantCopy = {...assistant}; 
				if (searchOpenResults.length>0) {
					assistantCopy.openroutes = originalOpenResults.reduce((count: number, lesson: any) => {
					if (lesson.productLessonPack && lesson.productLessonPack?.coach?.userId === assistant.userId) {
						return count + 1;
					}
					return count;
					}, 0);
				} else {
					assistantCopy.openroutes = 0;
				}
				return assistantCopy;
			  });
			  
			setPersonalAssistants(assistantsWithLessonCount.sort((a: any, b: any) => {
				return a.user.surname.localeCompare(b.user.surname);
			}));
		}
		prepareAssistantsList();
	}, [coaches, originalOpenResults]);

	useEffect(() => {
		if (!personalAssistants) return;
		if (!searchNewResults) return;

	}, [personalAssistants, searchNewResults]);

    const breadCrumbSettings: TitleBreadCrumbSettings = { subSectionText: 'Gestione Percorsi Autostrada' };
    const tabItems: TabSelectorItem[] = [
        {
            title: "Da assegnare",
            value: "tobeassigned",
        },
        {
            title: "In corso",
            value: "open",
        },
        {
            title: "Concluse",
            value: "closed"
        }
    ];
    const tabSettings: TabSelectorSettings = { tabItems: tabItems, handleSelection: setTabSelectorValue, display: openDetailsResults.length === 0 ? true : false};


    const assignBox =  (<AssignBox>
                        <AssignSelect disabled={(personalAssistants.length === 0)} value={assignAssistantSelect} onChange={(event) => handleAssignAssistantSelectChange(event.target.value)}>
                            <option value={0}>Scegli un assistente</option>
                            {personalAssistants.map((a) => {
                                return (
                                    <option value={a.userId}>
                                        {a.name} {a.surname} ({a.openroutes ? a.openroutes : 0})
                                    </option>
                                )
                            })}
                        </AssignSelect>

                        <AssignButton
                            onClick={() => assignLessonTo()}
                            disabled={(assignAssistantSelect === 0) || (selectedIds.length === 0)}
                        >
                        Assegna
                        </AssignButton>
                    </AssignBox>);

    if (!isSuperAdmin && !isSupervisoreAutostrada && !isAdminAutostrada) return (<></>);

    return(
        <>
        <Page>
            <DashboardTitleSection breadCrumbSettings={breadCrumbSettings}>
                { false && <SearchChannelBox>
                    <SearchInput
                        onKeyDown={searchKeyHandler}
                        onChange={searchChangeHandler}
                        value={searchInput}
                    />

                    {searchInput !== '' && (
                        <SearchClearButton onClick={clearSearchHandler}>
                            <ClearButtonIconSVG />
                        </SearchClearButton>
                    )}

                    <SearchButton>
                        <SearchIconSVG />
                    </SearchButton>
                </SearchChannelBox>
                }
            </DashboardTitleSection>

            <TabSelector tabSelectorSettings={tabSettings}>
            </TabSelector>


            { tabSelectorValue === "open" && openDetailsResults.length > 0 &&
                <TableDetailsHeader>
                    <TableDetailsName>{openDetailsResults[0].assistant}: in corso</TableDetailsName>

                    <TableDetailsBackButton onClick={() => handleCloseDetails()}>Chiudi dettagli</TableDetailsBackButton>
                </TableDetailsHeader>
            }

            <BaseContentCard>
                { tabSelectorValue === "tobeassigned" &&
                    <>
                   {assignBox}

                    <HighwayRoutesNewTable
                        data={searchNewResults}
                        totalElements={totalElements}
                        totalPages={totalPages}
                        pageSize={pageSize}
                        searchRoutes={searchNewRoutes}
                        getCheckedIds={getCheckedIds}
                    />

                    {assignBox}
                    </>
                }
                { tabSelectorValue === "open" && openDetailsResults.length === 0 &&
                    <>
                    <HighwayRoutesOpenTable
                        data={searchOpenResults}
                        totalElements={totalElements}
                        totalPages={totalPages}
                        pageSize={pageSize}
                        searchAssistants={searchOpenRoutes}
                        searchAssistantDetails={searchAssistantDetails}
                    />
                    </>
                }
                { tabSelectorValue === "open" && openDetailsResults.length > 0 &&
                    <>
                    <HighwayRoutesOpenDetailsTable
                        data={openDetailsResults}
                        totalElements={totalElements}
                        totalPages={totalPages}
                        pageSize={pageSize}
                        searchAssistants={searchOpenRoutes}
                        closeHighwayRoute={openCloseHighwayRouteModal}
                        closeCloseHighwayRouteModal={closeCloseHighwayRouteModal}
                        saveCloseHighwayRouteModal={saveCloseHighwayRouteModal}
                        hiddenHighwayRoutes={hiddenOpenDetails}
                    />
                    </>
                }
                { tabSelectorValue === "closed" &&
                    <>
                    <HighwayRoutesClosedTable
                        data={searchClosedResults}
                        totalElements={totalElements}
                        totalPages={totalPages}
                        pageSize={pageSize}
                        searchAssistants={searchClosedRoutes}
                    />
                    </>
                }
            </BaseContentCard>
        </Page>
        {isCloseHighwayRouteModalVisible && (
            <CloseHighwayRouteModal
                success={successHighwayRouteForEditing}
                onCloseButtonModal={closeCloseHighwayRouteModal}
                onSaveButtonModal={saveCloseHighwayRouteModal}
            />
        )}
        </>
    );

}

export default HighwayRoutesPage;
