// routing
import { Outlet } from 'react-router-dom';

const Points = () => {
	return (
		<>
			<Outlet />
		</>
	);
};

export default Points;
