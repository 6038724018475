import { useCallback, useEffect } from 'react';

// redux
import useAppDispatch from './useAppDispatch';
import { setIsAppMobile } from 'redux/actions/actions-ui';

import { breakpoints } from 'style/breakpoints';


const _MOBILE_BREAKPOINT_VALUE: number = +(breakpoints?.tablet ?? 769);

const useAppIsMobile = () => {

    const dispatch = useAppDispatch();

    const resizeHandler = useCallback(() => {
        
        const windowIsMobileSize = (window.innerWidth <= _MOBILE_BREAKPOINT_VALUE);
        dispatch( setIsAppMobile(windowIsMobileSize) );

    }, [dispatch]);

    useEffect(() => {

        resizeHandler();
        window.addEventListener('resize', resizeHandler);

        return () => window.removeEventListener('resize', resizeHandler);
        
    }, [resizeHandler]);
};

export default useAppIsMobile;
