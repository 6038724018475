import colors from 'style/palette/palette';
import styled from 'styled-components';

export const GroupBlock = styled.div<{isUserAdmin: boolean}>`

	display: flex;
	align-items: center;
	justify-content: center;
	
	flex-wrap: wrap;
	gap: 30px;

	width: 100%;

	cursor: ${({isUserAdmin}) => isUserAdmin ? 'pointer' : ' default'};
	position: relative;
`;

export const EditBox = styled.div`

	display: flex;
	align-items: center;
	justify-content: center;

	width: 100% !important;
	height: 100%;
	min-height: 25px;

	border-radius: 15px;
	border: 2px solid ${colors.white070};
	background: ${colors.black087};

	font-size: 25px;
	color: ${colors.white070};

	cursor: pointer;
	position: absolute;
	top: 0;
	left: 0;
`;
