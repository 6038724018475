import { FC } from 'react';

// routing
import { NavLink } from 'react-router-dom';

// hooks
import useAppSelector from 'hooks/useAppSelector';

// types
// import { Navbar } from './Navbar.d';

// style
import { Nav } from './style/Navbar.style';
import { getEnvironmentMonolithLinkUrl } from 'configs/config.helper';
import { Link } from 'react-router-dom';


/**
 * NavBar
 * 
 * @author Alessio Grassi
 * 
 * @returns JSX
 */


const Navbar: FC<{ onClick: (() => void | undefined) | undefined }> = ({ onClick }) => {

    const { tokenID } = useAppSelector(state => state.user);

    const isUserLoggedIn = !!tokenID;
    const homeLinkTo = isUserLoggedIn ? '/reservedArea/' : '/landing';

    return (
        <Nav onClick={onClick}>
            {/* <NavLink to={homeLinkTo}>Home</NavLink> */}
            {/* <a href={getEnvironmentMonolithLinkUrl('reservedArea/home/EntrateAutomatiche110')}>Entrate Automatiche</a> */}
        </Nav>
    );
}

export default Navbar;
