import colors from 'style/palette';
import styled from 'styled-components';

export const MainBox = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 100%;
	align-items: flex-start;
`;

export const PercentagesForm = styled.form`
	display: flex;
	flex-direction: column;
	margin: 2rem;
	gap: 1rem;
`;

export const PriceBox = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	gap: 1rem;
	align-items: stretch;
	margin-bottom: 2rem;
`;

export const PriceLabel = styled.label`
	color: ${colors.white};
	font-size: 1.5rem;
`;

export const PriceLabelwGap = styled.label`
	margin-left: 35px;
	color: ${colors.white};
	font-size: 1.5rem;
`;

export const PriceInput = styled.input`
	margin: 0 5px;
	text-align: right;
	width: 7rem;
	font-size: 1.5rem;
`;

export const ColumnBox = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 1rem;
	align-items: stretch;
`;

export const RowBox = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-end;
	gap: 1rem;
	align-items: stretch;
`;

export const PriceRowBox = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-start;
	gap: 1rem;
	align-items: stretch;
`;

export const PriceCol = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 1rem;
	align-items: stretch;
	margin: 0 0 0 30px;
`;

export const PriceRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	gap: 5px;
	align-items: space-between;
`;

export const Label = styled.label``;

export const Input = styled.input`
	text-align: right;
	width: 5rem;
`;

export const PoolResult = styled.input`
	text-align: right;
	width: 2rem;
`;

export const SubTotalBox = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	gap: 1rem;
	align-items: center;
	/* margin-bottom: 1rem; */
`;

export const SubTotalLabel = styled.label`
	color: ${colors.orange};
	font-size: 1rem;
`;

export const SubTotalInput = styled.input`
	text-align: right;
	width: 7rem;
	font-size: 1.5rem;
	background-color: ${colors.orange};
`;

export const ExpandButton = styled.button`
	width: 25px;
	align-self: flex-end;
	display: flex;
	align-items: center;
	justify-content: center;
	> svg {
		width: 15px;
		height: 15px;
		> path {
			fill: ${colors.black};
		}
	}
`;

export const AddAuthorPoolButton = styled.button`
	width: 25px;
	/* align-self: flex-end; */
	display: flex;
	align-items: center;
	justify-content: center;
	> svg {
		width: 15px;
		height: 15px;
		> path {
			fill: ${colors.green};
		}
	}
`;

export const WarningBox = styled.div`
	display: flex;
	flex-direction: row;
	background-color: ${colors.orange};
	color: ${colors.black};
	font-weight: bold;
	padding: 2px 20px;
	border-radius: 45px;
`;

export const SaveButton = styled.button``;

// export const priceSel = styled.input([type='radio'])``;
