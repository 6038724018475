import useAppSelector from 'hooks/useAppSelector';
import { FC, useEffect, useState } from 'react';

//types
import { RolesProps } from './Roles.component.d';

//components
import Role from './Role';

//style
import { RolesBox } from './style/Roles.component.style';

const Roles: FC<RolesProps> = ({ user, updateLocalUser }) => {
	const allRoles = useAppSelector((state) => state.global.allRoles);
	const userRoles = user.roles;

	const sortedRoles = Array.isArray(allRoles) ? [...allRoles].sort((a, b) => (a.role > b.role ? 1 : a.role < b.role ? -1 : 0)) : allRoles;
	return (
		<RolesBox>
			{sortedRoles?.map((role, index) => {
				return (
					<Role
						key={index}
						role={role}
						userId={user.id}
						userRoles={userRoles}
						updateLocalUser={updateLocalUser}
					/>
				);
			})}
		</RolesBox>
	);
};

export default Roles;
