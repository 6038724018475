import { useEffect, useState } from 'react';

// routing
import { useNavigate } from 'react-router-dom';

// redux
import { refreshUserSession } from 'redux/actions/actions-user';

// hooks
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { useIsFirstRender } from 'usehooks-ts';



const useCheckUserLogin = () => {
    
    const { tokenID } = useAppSelector(state => state.user)
    const [secondCheck, setSecondCheck] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isFirstRender = useIsFirstRender();
    const isUserLoggedIn = !!tokenID;

    useEffect (() => {

        const checkUser = async () => {

            if (isFirstRender) {

                if (isUserLoggedIn) return;

                await dispatch( refreshUserSession() );                
                setSecondCheck(true);
            } 

            if (!isFirstRender) {

                if (isUserLoggedIn) return;
                if (secondCheck) {
                navigate('/login');
                }
            };
        };
        
        checkUser();
    }, [dispatch, isFirstRender, isUserLoggedIn, navigate, secondCheck]);
};

export default useCheckUserLogin;
