import Visible from 'pages/ProductsPage/ProductsTable/Visible';
import OnShop from 'pages/ProductsPage/ProductsTable/OnShop';
import { FC, useEffect, useRef, useState } from 'react';
import { Row, Col } from 'style/layout';

//hooks
import useBrand from 'hooks/useBrand';

//types
import { CourseGenericInfoProps } from './CourseGenericInfo.component.d';
import { Product } from 'types/Product';
import { RoadmapEvent } from 'types/RoadmapEvent';
import { RoadmapEventType } from 'types/RoadmapEventType';

//assets
import { ReactComponent as EditIconSVG } from './assets/editIcon.svg';
import emptyPicIconSVG from './assets/defaultProductPic.png';

//utils
import EditableInput from 'components/EditableComponents/EditableInput';
import { uploadFile } from './services/CourseGenericInfo.services';
import { getRoadmapEvents, getRoadmapEventTypes } from '../../../RoadmapPage/services/RoadmapPage.services';
import { putRoadmapEvent } from 'services/roadmapevent.services';

//style
import {
	GenericInfoBox,
	InfoContent,
	InfoLabel,
	ProductImageContainer,
	ProductImage,
	ProductImageNewFile,
	ImageEditIconOverlay,
	PreviewButton,
} from './style/CourseGenericInfo.component.style';
import { findAllInRenderedTree } from 'react-dom/test-utils';
import EditableTextArea from 'components/EditableComponents/EditableTextArea/EditableTextArea.component';
import { updateElement } from 'pages/PublicProductPages/services/PublicProductPage.services';
import { getEnvironmentTestBaseUrl } from 'configs/config.helper';


const CourseGenericInfo: FC<CourseGenericInfoProps> = ({ product, putProduct, updateProductData }) => {
	const [imageIsClickable, setImageIsClickable] = useState<boolean>(false);
	const [finalDelivery, setFinalDelivery] = useState("Al momento non c'è nessuna descrizione...");
	const [thematicBroadcastDescription, setThematicBroadcastDescription] = useState("Al momento non c'è nessuna descrizione...");

	const [isGoingToRoadmap, setIsGoingToRoadmap] = useState<boolean>(false);
	const [originalRoadmapEvent, setOriginalRoadmapEvent] = useState<RoadmapEvent>({} as RoadmapEvent);
	const [originalRoadmapTypesList, setOriginalRoadmapTypesList] = useState<RoadmapEventType[]>();
	
	const productImageNewFileInput = useRef<HTMLInputElement>(null);

	const brand = useBrand();


	const uploadB64 = async (productId: number, fileB64: string): Promise<any | null> => {
		if (typeof fileB64 === 'undefined') return null;
		if (fileB64.length === 0) return null;
		var uploaded: any;
		await fetch(fileB64)
			.then((res) => res.blob())
			.then(async (blob) => {
				var formData = new FormData();
				formData.append('file', blob);
				uploaded = await uploadFile(productId, formData);
			});
		return uploaded;
	};

	const getFileContentInBase64 = (files: File[]) => {
		const fileReadPromises = files.map((file) => {
			return new Promise<string>((resolve, reject) => {
				const reader = new FileReader();
				reader.onload = () => {
					resolve(reader.result as string);
				};
				reader.onerror = (error) => {
					reject(error);
				};
				reader.readAsDataURL(file);
			});
		});
		return Promise.all(fileReadPromises);
	};

	const handleSingleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		if (files && files.length > 0) {
			const fileB64: string[] = await getFileContentInBase64([files[0]]);
			var uploaded = await uploadB64(product.id, fileB64[0]);
			if (uploaded) {
				var partial: Partial<Product> = { id: product.id, sellImage: uploaded };
				putProduct(partial);
			}
		}
	};

	const onImageOver = () => {
		setImageIsClickable(true);
	};

	const onImageOut = () => {
		setImageIsClickable(false);
	};

	const onImageClick = () => {
		if (imageIsClickable) {
			if (productImageNewFileInput.current) {
				productImageNewFileInput.current.click();
			}
		}
	};

	const previewOpenHandler = () => window.open(
		`${getEnvironmentTestBaseUrl(
			product?.productThematicCall.type === 'ESPERIENZA' ? 
			`app/public/product-esperienza/${product.id}`.replace('backoffice.', '') :
			`app/reservedArea/product-diretta-tematica/${product.id}`).replace('backoffice.', '')}`,
		'_blank',
		'noreferrer noopener'
	);

	const updateRoadmapName = async (name: string) => {
		if (!brand) {
			throw new Error('brand not defined');
			console.log("2ROADMAP: brand not defined");
		}
		if (!originalRoadmapTypesList) {
			throw new Error('originalRoadmapTypesList not defined');
			console.log("2ROADMAP: originalRoadmapTypesList not defined");
		}
		if (!originalRoadmapEvent?.id) {
			throw new Error('originalRoadmapEvent not defined');
			console.log("2ROADMAP: originalRoadmapEvent not defined");
		}
		let roadmapEvent = {} as Partial<RoadmapEvent>;
		roadmapEvent.name = name;
		roadmapEvent.id = originalRoadmapEvent.id;
		await putRoadmapEvent(roadmapEvent, brand.code);
	};

	const updateProductInfo = (id: string, content: string) => {
		var partial: Partial<Product> = {};
		if (id === 'name') {
			partial = { id: product.id, name: content };
			if (Object.keys(originalRoadmapEvent).length > 0) {
				updateRoadmapName(content);
			}
		}
		if (id === 'description') partial = { id: product.id, description: content };

		if (Object.keys(partial).length > 0) putProduct(partial);
	};

	const refreshProduct = async () => { 
		if (updateProductData) await updateProductData(product.id);
	};

	const elementUpdate = async (elementId: number, editedData: string | undefined, newFileUrl: string | undefined) => {
		try { 
			const updatedElement = await updateElement(elementId, editedData, newFileUrl);

			if (updateProductData) await updateProductData(product.id);

			return updatedElement;
		} catch (error: any) {
			console.warn('ERROR:', error);
		}
	};

	useEffect(() => {
		if (product?.salesData?.delivery) setFinalDelivery(product.salesData.delivery);
		if (product?.productThematicCall?.description) setThematicBroadcastDescription(product.productThematicCall.description);
	}, [product]);

	useEffect(() => {
		if (!brand) return;
		if (!product) return;
		const gre = async () => {
			const response = await getRoadmapEvents(brand);
			// console.log("2ROADMAP: looking for /"+product.id.toString());
			const targetEvent = response.find((e: any) => {
				// console.log("Checking event: ", e);
				if (e.link && e.link.includes(product?.productThematicCall?.type === 'INIZIATIVA' ? 'iniziativa' : 'product-diretta-tematica') && e.link.endsWith("/"+product?.id.toString())) {
					return e;
				}
			})
			// console.log("2ROADMAP: target event: ", targetEvent);
			if (targetEvent) {
				// console.log("2ROADMAP: found event: ", targetEvent.id);
				setOriginalRoadmapEvent(targetEvent);
			} else { 
				//console.log("2ROADMAP: not found event"); 
			}
			
		}
		const gret = async () => {
			const response = await getRoadmapEventTypes(brand);
			if (response) { setOriginalRoadmapTypesList(response); }
		}
		gre();
		gret();
	}, [brand, product]);

	return (
		<GenericInfoBox>
			<Row>
				{ product.type !== 'THEMATIC_BROADCAST' &&
				<ProductImageContainer>
					<ProductImage
						src={product.sellImage?.url ? product.sellImage.url : emptyPicIconSVG}
						onMouseOver={onImageOver}
						onMouseOut={onImageOut}
						onClick={onImageClick}
					/>

					<ImageEditIconOverlay style={{ opacity: imageIsClickable ? 1 : 0 }}>
						<EditIconSVG />
					</ImageEditIconOverlay>
				</ProductImageContainer>
				}

				<ProductImageNewFile
					ref={productImageNewFileInput}
					onChange={handleSingleFileChange}
				/>

				<Col width="100%">
					<Row>
						<Visible
							productId={product.id}
							visible={product.visible}
							putProduct={putProduct}
						/>
						{ product.type === 'THEMATIC_BROADCAST' &&
						<OnShop
							product={product}
							putProduct={putProduct}
						/>
						}
					</Row>

					<Row>
						<InfoLabel>Nome</InfoLabel>

						<EditableInput
							inputId={'name'}
							editableInput={product.name}
							update={updateProductInfo}
						/>
					</Row>

					<Row>
						<InfoLabel>Descrizione per Negozio</InfoLabel>

						<EditableTextArea
							editableTextArea={finalDelivery}
							update={elementUpdate}
							productId={product.id}
							refreshProduct={refreshProduct}
							typeOfTextToUpload='delivery'
						/>
					</Row>

					{product.type === 'THEMATIC_BROADCAST' &&
					<Row>
						<InfoLabel>{(product.productThematicCall.type === 'ESPERIENZA') ? "Istruzioni" : "CTA Video Aula"}</InfoLabel>

						<EditableTextArea
							editableTextArea={thematicBroadcastDescription}
							update={elementUpdate}
							productId={product.id}
							refreshProduct={refreshProduct}
							typeOfTextToUpload='thematicbroadcastdescription'
						/>
					</Row>
					}

					{product.type === 'THEMATIC_BROADCAST' &&
						<PreviewButton
							onClick={previewOpenHandler}
						>
							ANTEPRIMA
						</PreviewButton>
					}

					{/* <Row>
						<InfoLabel>Descrizione</InfoLabel>
						<EditableInput
							inputId={"description"}
							editableInput={product.description}
							update={updateProductInfo}
						/>
					</Row> */}
					{/* <FieldBox>
					<InfoLabel>Prezzo</InfoLabel>
					<InfoContent>{price.toLocaleString()} €</InfoContent>
				</FieldBox> */}
				</Col>
			</Row>
		</GenericInfoBox>
	);
};

export default CourseGenericInfo;

