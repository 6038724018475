import { FC, useEffect, useState } from 'react';

//types
import { VipPathLevelProps } from './VipPathLevel.component.d';

//utils
import { getUserVipPathLevel } from './services/VipPathLevel.services';

//style
import { 
	VipPathLevelBox,
	Row,
	VipPathLevelNumber,
} from './style/VipPathLevel.component.style';

const VipPathLevel: FC<VipPathLevelProps> = ({user}) => {
	const [vipPathLevel, setVipPathLevel] = useState<any | undefined>(undefined);

	useEffect(() => {
		const gvpl = async () => {
			if (!user) return;
			var vipLevel = await getUserVipPathLevel(user.id);
			if (vipLevel) setVipPathLevel(vipLevel);
		}
		gvpl();
	}, [user]);

	return (<VipPathLevelBox>
			<Row>
				<VipPathLevelNumber>
					{vipPathLevel ? vipPathLevel.pathLevel : "Caricamento..."}
				</VipPathLevelNumber>
			</Row>

		</VipPathLevelBox>);
};

export default VipPathLevel;
