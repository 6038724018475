import styled, { css } from "styled-components";

interface ChannelIconProps {
    isSelected?: boolean;
}

export const Page = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 0 30px;

    width: 100%;
    height: 100%;

    overflow: hidden;
`;

export const SearchChannelBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    max-width: 1360px;
`;

export const SearchInput = styled.input`
    display: flex;
    align-items: center;
    flex-direction: column;

    width: 100%;
    height: 29px;
    padding: 7px 9px;
    margin: 0 13px 0 0;

    background-color: transparent;
    border: 1px solid #FFFFFF;
    border-radius: 100px;

    color: white;
    :focus{
        outline: none;
        border-color: white;
        box-shadow: 0 0 0px white;
        -moz-box-shadow: 0 0 0px white;
        -webkit-box-shadow: 0 0 0px white;
    }
`;

export const SearchClearButton = styled.div`
    position: relative;
    top: 1px;
    left: -38px;
    width: 0;

    cursor: pointer;

    > svg {
            min-width: 15px;
            min-height: 15px;          
    }
`;

export const SearchButton = styled.div`
    > svg {
            min-width: 23px;
            min-height: 23px;          
    }
`;

export const ChannelsBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    max-width: 1360px;
    margin: 0 0 30px;

    > a:link {
        text-decoration: none !important;
    }
`;

export const ChannelIcon = styled.div<ChannelIconProps>`

    > img { 
        min-width: 48px;
        width: 72px;
        height: 72px;
        border: ${props => (props.isSelected ? '2px solid white' : '0')};
        opacity: ${props => (props.isSelected ? '1' : '0.8')};
        filter: ${props => (props.isSelected ? '' : 'grayscale(100%);')};
        border-radius: 50%;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);

    }

    cursor: pointer;
`;

export const NotificationEditorBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;

    min-width: 100%;

    margin: 30px;
`;

export const OddsscasserLinkBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: left;

    min-width: 100%;

    color: #f0f0f0;
`;

export const PremiumBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: left;

    min-width: 100%;

    color: #f0f0f0;
`;

export const UploadMultipleFilesBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;

    margin: 30px 0 0;

    color: #f0f0f0;
`;

export const UploadMultipleFilesTitle = styled.p`
    color: #f0f0f0;
`;

export const UploadMultipleFilesBoxTitle = styled.p`
    color: #f0f0f0;
`;

export const UploadMultipleFilesButtonBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: left;

    min-width: 100%;
`;

export const UploadMultipleFilesSelector = styled.button`
    font-size: 1.2em;

    color: gba(0, 0, 0, 0.87);

    border: none;
    border-radius: 4px;
    background-color: rgb(255, 192, 0);

    cursor: pointer;
`; 

export const PreviewBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    margin: 10px 0 20px;
`;

export const PreviewTitle = styled.div`
    color: #f0f0f0;   
    margin: 0 10px 0 0;
`;

export const InputPreview = styled.input.attrs({
    type: 'text',
    maxLength: 60,
})`
    min-width: 40em;
    /* height: 2em; */
`;

export const UploadMultipleFiles = styled.input.attrs({ 
    type: 'file',
    multiple: true,
    // accept: 'image/png, image/jpg, image/gif',
  })`
    display: none;
`;

export const UploadMultipleFilesList = styled.div`
    margin: 10px 10px;
`;

export const UploadMultipleFilesItem = styled.div`
    margin: 5px 0 0;
`;

export const UploadMultipleFilesRemoveButton = styled.button`
    margin: 0 0 0 5px;

    font-size: 1.1em;
    color: #ffffff;

    border: none;
    border-radius: 4px;
    background-color: red;

    cursor: pointer;
`;

export const PasteImagesBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: left;
    gap: 10px;
`;

export const MiniEditBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: right;
`;

export const MiniPreviewBox = styled.div`
  height: 115px;
  width: auto;
`;

export const MiniPreview = styled.img`
  height: 100%;
  width: auto;
  object-fit: contain;
  border: 1px solid white;
`;

export const ClearMiniEditButton = styled.button`
    font-size: 1.1em;

    background-color: red;
    color: white;

    border: none;
    border-radius: 4px;

    cursor: pointer;
`;

export const MainTextEditorBox = styled.div`
    min-width: 100%;
    margin: 30px 0 0;
`;

export const MainTextEditorTitle = styled.p`
    color: #f0f0f0;
`;

export const NotificationSendBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: left;
    gap: 10px;

    min-width: 100%;
`;

export const NotificationSendButton = styled.button`
    font-size: 1.5em;
    color: #ffffff;

    border: none;
    border-radius: 4px;
    background-color: rgb(102, 187, 106);

    cursor: pointer;

    ${(props: {disabled: boolean}) => props.disabled && css`
        background-color: gray;
    `}
`;

export const NotificationSendSingleFile = styled.button`
    font-size: 1.5em;
    color: #ffffff;

    border: none;
    border-radius: 4px;
    background-color: rgb(102, 187, 106);

    cursor: pointer;
`;

export const NotificationStopEditingButton = styled.button`
    font-size: 1.5em;
    color: #ffffff;

    border: none;
    border-radius: 4px;
    background-color: red;

    cursor: pointer;
`;

export const NotificationSingleFile = styled.input.attrs({ 
    type: 'file',
  })`
    display: none;
`;

export const NotificationHistoryTitle = styled.h1`
    color: #f0f0f0;
`;

export const NotificationHistoryBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    min-width: 100%;

    color: #f0f0f0;

    a {
        color: rgb(255,192,0);
    }
`;

export const NotificationHistoryMessage = styled.div`
    display: flex;
    flex-direction: row;
    align-items: top;
    padding: 5px;

    border-bottom: 1px solid #f0f0f0;

    /* min-width: 100%; */
    width: 865px;
    text-align: center;
`;

export const NotificationHistoryMessagePremiumFlag = styled.div` 
    font-weight: bold;
`;

export const NotificationHistoryMessageContent = styled.div`
    flex: 1 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-weight: 400;
    font-size: 13px;
    margin: 0;
    padding: 0px;

    p {
        margin: 0;
        padding: 0;
    }
`;

export const NotificationHistoryMessageImage = styled.img`
    max-width: 600px;
`;

export const NotificationHistoryMessageDate = styled.div`
    width: 100px;
`;

export const NotificationHistoryMessageEditButton = styled.button`
    height: 1.5em;
    margin: 0 5px 0 0;
    
    font-size: 1.1em;

    background-color: blue;
    color: white;

    border: none;
    border-radius: 4px;

    cursor: pointer;
`;

export const NotificationHistoryMessageDeleteButton = styled.button`
    height: 1.5em;
    font-size: 1.1em;

    background-color: red;
    color: white;

    border: none;
    border-radius: 4px;

    cursor: pointer;
`;
