import { useEffect, useState } from 'react';

//services
import { assignWhatsappTo } from './services/WhatsappOpenTable.services';

//utils
import { footerElementsCount } from './WhatsappOpenTable.helper';

//types
import { tableColumnAttr, WhatsappOpenTableItem, WhatsappOpenTableProps } from './WhatsappOpenTable.component.d';

//assets
import { ReactComponent as ChevronLeftIcon } from './assets/chevronLeft.svg';
import { ReactComponent as ChevronRightIcon } from './assets/chevronRight.svg';
import { ReactComponent as DownArrowIcon } from './assets/downArrowIcon.svg';


//style
import {
    FooterNavigationButton,
	FooterElementsCount,
	Table,
	TableBody,
	TableBodyCell,
	TableBodyRow,
	TableFooter,
	TableHead,
	TableHeadCell,
	TableHeadRow,
	DetailsButton,
} from './style/WhatsappOpenTable.style';

const WhatsappOpenTableColumns: tableColumnAttr[] = [
    {
        name: 'assistant',
        title: 'Assistente Personale',
    },
    {
        name: 'opencalls',
        title: 'Call Whatsapp in corso',
        align: 'center',
    }
];

type sortParamsType = {
	column: string | null;
	direction: 'asc' | 'desc' | null;
};

const WhatsappOpenTable: React.FC<WhatsappOpenTableProps> = ({ data, totalPages, totalElements, pageSize, searchAssistants, searchAssistantDetails }) => {
	const [tableData, setTableData] = useState<WhatsappOpenTableItem[]>([]);
	const [sortParams, setSortParams] = useState<sortParamsType>({column: null, direction: null});
	const [currentPage, setCurrentPage] = useState(1);

	const pageChangeHandler = (page: number) => {
		setCurrentPage(page);
	};

	const sortTable = (items: WhatsappOpenTableItem[], sortBy: string | null, direction: 'asc' | 'desc' | null) => {
		if (sortBy === null) return items;
		const sortedItems = [...items].sort((a, b) => {
			if (a[sortBy] < b[sortBy]) {
			  return -1;
			}
			if (a[sortBy] > b[sortBy]) {
			  return 1;
			}
			return 0;
		});
		
		if (direction === 'desc') {
			return sortedItems.reverse();
		}
		
		return sortedItems;
	};

	const handleSort = (key: string) => {
		if (key === sortParams.column) {
			setSortParams({column: key, direction: sortParams.direction === 'asc' ? 'desc' : 'asc'});
		} else {
			setSortParams({column: key, direction: 'desc'});
		}
	};

	useEffect(() => {
		console.log('tableData', tableData);
	}, [tableData]);

	useEffect(() => {
		searchAssistants(currentPage);
	}, [currentPage]);

	useEffect(() => {
		const sortedTable = sortTable(tableData, sortParams.column, sortParams.direction);
		setTableData(sortedTable);
	}, [sortParams]);

	useEffect(() => {
		const sortedTable = sortTable(data, sortParams.column, sortParams.direction);
		setTableData(sortedTable);
	}, [data]);

    let columns: string[] = [];
	if (tableData.length > 0) columns = Object.keys(tableData[0]);

	return (
		<>
			<Table>
				<TableHead>
					<TableHeadRow>
						{columns.map((c) => (
							<TableHeadCell
								key={c}
								onClick={() => handleSort(c)}
							>
								{WhatsappOpenTableColumns.find(x => x?.name === c)?.title ? WhatsappOpenTableColumns.find(x => x?.name === c)?.title?.toUpperCase() : c.toUpperCase()}
								{(sortParams.column === c && (sortParams.direction === 'desc' ? <DownArrowIcon transform="rotate(180 0 0)"/> : <DownArrowIcon />))}
							</TableHeadCell>
						))}
                        <TableHeadCell
                            key={"dettaglio"}>
                            
                        </TableHeadCell>
					</TableHeadRow>
				</TableHead>
				<TableBody>
					{tableData.map((row, index) => (
						<TableBodyRow key={index}>
							{columns.map((c) => {
								const value = row[c as keyof WhatsappOpenTableItem];
								return (
									<TableBodyCell
                                        align={WhatsappOpenTableColumns.find(x => x?.name === c)?.align}
										key={c}
										id={c}
									>
										{(Object.prototype.toString.call(value) === '[object Date]') ? value.toLocaleString("it-IT") : value}
									</TableBodyCell>
								);
							})}
                            <TableBodyCell 
                                align={"center"}
                            >
                                <DetailsButton onClick={()=>searchAssistantDetails(row['id'])}>Dettagli</DetailsButton>
                            </TableBodyCell>
						</TableBodyRow>
					))}
				</TableBody>
			</Table>
			{totalElements > 0 && (
				<TableFooter>
					<FooterElementsCount>{footerElementsCount({ currentPage, totalPages, totalElements, pageSize })}</FooterElementsCount>
					<FooterNavigationButton
						onClick={() => pageChangeHandler(currentPage - 1)}
						disabled={currentPage === 1}
					>
						<ChevronLeftIcon />
					</FooterNavigationButton>
					<FooterNavigationButton
						onClick={() => pageChangeHandler(currentPage + 1)}
						disabled={currentPage === totalPages}
					>
						<ChevronRightIcon />
					</FooterNavigationButton>
				</TableFooter>
			)}
		</>
	);
};

export default WhatsappOpenTable;