import { FC, useEffect, useState } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';
import useHasPermission from 'hooks/useHasPermission';

//types
import { PointsWithdrawProps, UserWithdrawal } from './PointsWithdraw.component.d';
import { WithdrawalType } from 'types/WithdrawalType';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

//utils
import { getWithdrawalTypes, postWithdraw } from '../services/PointsPage.services';

//style
import { PointsWithdrawBox, WithdrawalOptions, Withdraw, Instructions, Attachment } from './style/PointsWithdraw.component.style';

const typePermissionMapping = {
	IVA: 'WITHDRAW_IVA',
	RIT: 'WITHDRAW_RIT',
	SPA: 'WITHDRAW_SPA',
};

const PointsWithdraw: FC<PointsWithdrawProps> = ({ pool, getUserSelfPools }) => {
	const [withdrawalTypes, setWithdrawalTypes] = useState<WithdrawalType[]>();
	const [selectedWithdrawalTypeCode, setSelectedWithdrawalTypeCode] = useState('');
	const [selectedWithdrawalType, setSelectedWithdrawalType] = useState<WithdrawalType | undefined>();
	const [amount, setAmount] = useState<number | undefined>();
	const [isWithdrawEnable, setIsWithdrawEnabled] = useState(false);

	const canWithdrawIva = useHasPermission('WITHDRAW_IVA');
	const canWithdrawRitenuta = useHasPermission('WITHDRAW_RIT');
	const canWithdrawSpagna = useHasPermission('WITHDRAW_SPA');

	const dispatch = useAppDispatch();

	const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedWithdrawalTypeCode(event.target.value);
	};

	const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (+event.currentTarget.value <= 0) {
			setAmount(0);
			setIsWithdrawEnabled(false);
		}
		if (+event.currentTarget.value > 0) {
			setAmount(+event.currentTarget.value);
			setIsWithdrawEnabled(true);
		}
	};

	const handleWithdrawClick = () => {
		if (window.confirm(`Prelevare ${amount?.toLocaleString()} punti con il metodo ${selectedWithdrawalType?.name}?`)) {
			withdrawFromPool();
		}
	};

	const getAllWithdrawalTypes = async () => {
		try {
			const response = await getWithdrawalTypes();
			setWithdrawalTypes(response);
		} catch (error) {}
	};

	const withdrawFromPool = async () => {
		if (!pool || !amount || !selectedWithdrawalTypeCode) return;
		const snackbarData: SnackbarOptions = {};
		try {
			const response = await postWithdraw(pool.id, selectedWithdrawalTypeCode, amount);
			snackbarData.type = 'success';
			snackbarData.message = `Prelievo effettuato con successo`;
			dispatch(addSnackbar(snackbarData));
			getUserSelfPools();
		} catch (error: any) {
			console.log(error);

			snackbarData.type = 'error';
			snackbarData.message = `Errore nel prelievo.`;
			dispatch(addSnackbar(snackbarData));
			throw error;
		}
	};

	useEffect(() => {
		getAllWithdrawalTypes();
	}, []);

	useEffect(() => {
		setSelectedWithdrawalType(withdrawalTypes?.find((w) => w.code === selectedWithdrawalTypeCode));
	}, [selectedWithdrawalTypeCode, withdrawalTypes]);

	const userWithdrawalTypes: UserWithdrawal = {
		IVA: canWithdrawIva,
		RIT: canWithdrawRitenuta,
		SPA: canWithdrawSpagna,
	};

	return (
		<PointsWithdrawBox>
			<WithdrawalOptions>
				<legend>Seleziona una modalità:</legend>

				{withdrawalTypes
					?.filter((w) => userWithdrawalTypes[w.code as keyof UserWithdrawal] && w.visible)
					.map((withdrawalType) => (
						<label key={withdrawalType.name}>
							<input
								type='radio'
								value={withdrawalType.code}
								onChange={handleOptionChange}
								name='withdrawalTypeSelection'
							/>
							{withdrawalType.name}
						</label>
					))}
			</WithdrawalOptions>
			{selectedWithdrawalTypeCode !== '' && (
				<Withdraw>
					<Instructions>
						{selectedWithdrawalType?.description?.replaceAll('<br>', '\n').replaceAll('<span>', '').replaceAll('</span>', '')}
					</Instructions>
					{selectedWithdrawalType?.file && (
						<Attachment
							href={selectedWithdrawalType?.file?.url}
							target={'_blank'}
						>
							{selectedWithdrawalType?.file?.fileName}
						</Attachment>
					)}
					<legend>Importo da prelevare:</legend>
					<input
						type={'number'}
						min={0}
						value={amount}
						onChange={handleAmountChange}
					></input>
					<button
						disabled={!isWithdrawEnable}
						onClick={handleWithdrawClick}
					>
						Preleva
					</button>
				</Withdraw>
			)}
		</PointsWithdrawBox>
	);
};

export default PointsWithdraw;
