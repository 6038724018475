import {moduleListInterface, modulesListModel} from '../modules';

//store
import store from 'redux/store';

//types
import { Brand } from 'types/Brand';

const getCurrentPageAddress = (): string => {
    return window.location.href;
};

export const getBrand = (): Brand | undefined => {
    const state = store.getState();
    return state.ui.brand; 
};

const isBrandInKey = (key: string, brand: string, modulesListModel: moduleListInterface[]): boolean => {
    const item = modulesListModel.find((item: moduleListInterface) => item.url === key);
    return item?.brands.includes(brand) ?? false;
};

export const checkBrandMap = (key: string) => {
    const brand: Brand | undefined = getBrand();
    if (!brand) return true;
    const check = isBrandInKey(key, brand.code, modulesListModel);
    return check;
}

export const checkActualUrlforBrand = () => {
    const location: string = getCurrentPageAddress();
    const segments: string[] = location.split('/'); 
    const reservedAreaIndex: number = segments.findIndex(segment => segment === 'reservedArea');
    const fallbackIndex: number = segments.findIndex(segment => segment === 'fallback-page');

    const hasContentAfterReservedArea: boolean = reservedAreaIndex >= 0 && reservedAreaIndex < (segments.length - 1);

    console.log("location", location);
    console.log("reservedAreaIndex", reservedAreaIndex);
    console.log("fallbackIndex", fallbackIndex);
    console.log("hasContentAfterReservedArea", hasContentAfterReservedArea);

    if (fallbackIndex > 0) return true;
    if (!hasContentAfterReservedArea) return true;

    let check: boolean = false;
    let unprotected: boolean = true;

    modulesListModel.forEach((item: moduleListInterface) => {
        if (location.includes('/'+item.url.replace('app.',''))) {
            unprotected = false;
            check = checkBrandMap(item.url.replace('app.','')); 
            console.log("FOUND "+item.url.replace('app.',''),check);
        }
    });
    
    console.log("RETURNING ",check || unprotected);

    return check || unprotected;
    
}