import Visible from 'pages/LevelsPage/LevelsTable/Visible';
import { FC, useRef, useState } from 'react';

//hooks
import useAppSelector from 'hooks/useAppSelector';
import useHasRole from 'hooks/useHasRole';

//types
import { LevelGenericInfoProps } from './LevelGenericInfo.component.d';
import { Level } from 'types/Level';

//assets
import { ReactComponent as EditIconSVG } from './assets/editIcon.svg';
import emptyPicIconSVG from './assets/defaultLevelPic.png';

//utils
import EditableInput from 'components/EditableComponents/EditableInput';
import { uploadFile } from './services/LevelGenericInfo.services';

//style
import {
	GenericInfoBox,
	InfoContent,
	InfoLabel,
	SelectInput,
	LevelImageContainer,
	LevelImage,
	LevelImageNewFile,
	ImageEditIconOverlay,
	Row,
	Col,
} from './style/LevelGenericInfo.component.style';
import { findAllInRenderedTree } from 'react-dom/test-utils';
import EditableTextArea from 'components/EditableComponents/EditableTextArea/EditableTextArea.component';
import { updateElement } from '../../services/PublicLevelPage.services';

const LevelGenericInfo: FC<LevelGenericInfoProps> = ({ level, putLevel, updateLevelData }) => {

	const isAdmin = useHasRole('ADMIN');
	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isAnimator = useHasRole('ANIMATORE');
	const isCoach = useHasRole('COACH');
	const canEditLevel = isAdmin || isSuperAdmin || isAnimator;

	const levelsList = useAppSelector((state) => state.global.allLevels) as Level[];

	const updateLevelInfo = (id: string, content: string) => {
		var partial: Partial<Level> = {};
		// if (id === 'name') partial = { id: level.id, name: content };
		if (id === 'description') partial = { id: level.id, description: content };

		if (Object.keys(partial).length > 0) putLevel(partial);
	};

	const handleLevelSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const selNumber = parseInt(event.target.value);
		var partial: any = {};
		partial = { id: level.id, pathLevel: selNumber };
		var foundLevel = levelsList.find(item => item.id !== level.id && item.pathLevel === selNumber && item.course !== null);
		if (foundLevel) partial.course = foundLevel.course;
		if ((!foundLevel) && (level.course)) {
			partial.course = null;
		}
		putLevel(partial);
	};

	const refreshLevel = async () => {
		if (updateLevelData) await updateLevelData(level.id);
	};

	const elementUpdate = async (elementId: number, editedData: string | undefined, newFileUrl: string | undefined) => {
		try {
			const updatedElement = await updateElement(elementId, editedData, newFileUrl);

			if (updateLevelData) await updateLevelData(level.id);

			return updatedElement;
		} catch (error: any) {
			console.warn('ERROR:', error);
		}
	};

	const options = [];

	for (let i = 1; i <= 9; i++) {
	  options.push(
		<option key={i} value={i}>
		  {i}
		</option>
	  );
	}

	return (
		<GenericInfoBox>
			<Row>
				<Col width="50%">

					{/* <Row>
						<InfoLabel>Nome</InfoLabel>

						<EditableInput
							inputId={'name'}
							editableInput={level.name}
							update={updateLevelInfo}
						/>
					</Row> */}

					{/* <Row>
						<InfoLabel>Descrizione</InfoLabel>

						<EditableTextArea
							editableTextArea={finalDelivery}
							update={elementUpdate}
							productId={level.id}
							refreshProduct={refreshLevel}
							typeOfTextToUpload='delivery'
						/>
					</Row> */}
					<Row>
						<InfoLabel>Descrizione</InfoLabel>
						<EditableInput
							inputId={"description"}
							editableInput={level.description}
							update={updateLevelInfo}
							enabled={canEditLevel}
						/> 
					</Row>
				</Col>
				<Col width="50%">
					<InfoLabel>Traguardo Percorso</InfoLabel>
					<SelectInput disabled value={level.pathLevel} onChange={handleLevelSelect}>{options}</SelectInput>
				</Col>
			</Row>
		</GenericInfoBox>
	);
};

export default LevelGenericInfo;

