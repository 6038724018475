//types
import { BadgeProps } from './Badge.component.d';

//style
import { SingleBadge } from './style/Badge.component.style';

const Badge: React.FC<BadgeProps> = ({ title, name, BadgeIcon, isSwitchedOn, clickHandler, isClickable }) => {
	return (
		<SingleBadge
			title={title}
			name={name}
			isSwitchedOn={isSwitchedOn}
			onClick={clickHandler}
			isClickable={isClickable}
		>
			<BadgeIcon />
		</SingleBadge>
	);
};

export default Badge;
