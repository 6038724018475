import { FC, useEffect, useState } from 'react';

// redux
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

//hooks
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';

//types
import { PaymentPlansTabProps } from './PaymentPlansTab.component.d';
import { TabSelectorSettings, TabSelectorItem } from 'components/TabSelector/TabSelector.component.d';
import { PaymentPlan } from 'types/PaymentPlan';
import { PaymentPlanPaymentType } from 'types/PaymentPlanPaymentType';

//components
import { BaseContentCard } from 'style/wrappers/wrappers';
import BaseFieldset from 'components/ui/BaseFieldset';
import ScrollableTextList from 'components/ScrollableTextList';

//utils
import { getPaymentPlans, getPaymentTypes, saveNewPlan } from './services/PaymentPlansTab.services';

//style
import { 
    MainBox, 
    RowBox, 
    ColumnBox,
    InputBox,
    ComputedDiscount,
    CheckboxInput,
    Label,
    SimpleInput,
    SimpleSelect,
    ButtonsRowBox,
    CreateButton,
    ResetButton,
    CancelButton,
} from './style/PaymentPlansTab.component.style';
import { Row } from 'style/layout';

const PaymentPlansTab: FC<PaymentPlansTabProps> = ({ product, putProduct }) => {

    const [paymentTypes, setPaymentTypes] = useState([]);
    const [selectedPaymentTypeId, setSelectedPaymentTypeId] = useState(null);
    const [paymentPlans, setPaymentPlans] = useState<PaymentPlan[] | null>(null);
    const [paymentPlansList, setPaymentPlansList] = useState<any[]>([]);
    const [selectedPaymentPlanId, setSelectedPaymentPlanId] = useState<number | null>(null);
    const [activePlan, setActivePlan] = useState<Partial<PaymentPlan> | null>(null);
    const [newPlan, setNewPlan] = useState<any | null>(null);
    const [yearOption, setYearOption] = useState<boolean>(false);
    const [yearValue, setYearValue] = useState<string>('');
    const [originalYearOption, setOriginalYearOption] = useState<any>({});
    const [isCreating, setIsCreating] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);

	const dispatch = useAppDispatch();

    const thisPaymentPlan = {
        // id: number;
        // trialMonths: number;
        // trialPrice: number;
        // frequency: number;
        // price: number;
        // productId: number;
        // oldPlan: true;
        // paymentPlanPaymentTypes: PaymentPlanPaymentType[];
        // annualOption: true;
        // annualDiscountPerc: number;
    }

    const removeProperty = (obj: Record<string, any>, prop: string) => {
        const result: any = {};
        Object.keys(obj).forEach(key => {
          if (key !== prop) {
            result[key] = obj[key];
          }
        });
        return Object.keys(result).length > 0 ? result : null;
      };

    const onChangeTypeSelectHandler = (event: any) => {
        setSelectedPaymentTypeId(event.target.value);
    };

    const formatNumber = (num: number | null | undefined): string => {
        if (!num) return '';
        const formattedNum = num % 1 != 0 ? num?.toFixed(2) : num;
        return formattedNum.toString().replaceAll('.', ',');
    }

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.currentTarget.name;
        const value = event.currentTarget.value;

        if ((newPlan)&&(name)) {
            if (value !== '') {
                setNewPlan({...newPlan, [name]: value});
            } else {
                const changedPlan = removeProperty(newPlan, name); 
                if ((changedPlan) && (Object.keys(changedPlan).length === 1) && (changedPlan.annualOption === false)) {
                    setNewPlan(null);
                } else {
                    setNewPlan(changedPlan);
                }
            }
        } else {
            setNewPlan({ [name]: Number(value)});
        }
	};

    const handleActiveYearOption = () => {
        if (!activePlan) return;
        var oldOption = activePlan.annualOption;
        activePlan.annualOption = !activePlan.annualOption;
        setActivePlan({...activePlan, annualOption: !oldOption});
        if ((!oldOption !== originalYearOption.status) ||
            ((!oldOption)&&(activePlan.annualDiscountPerc?.toString() !== originalYearOption.value.toString())))
        {
            setIsEditing(true);
        } else {
            setIsEditing(false);
        }
    }

    const handleYearOption = () => {
        var oldOption = yearOption;
        setYearOption((prevState) => !prevState);
        if (newPlan) {
            if ((Object.keys(newPlan).length === 1)&&(oldOption)) {
                setNewPlan(null);
            } else {
                setNewPlan({...newPlan, annualOption: !oldOption});
            }
        } else {
            setNewPlan({ annualOption: !oldOption});
        }  
    }

    const handleReset = () => {
        setNewPlan(null);
        setYearOption(false);
    }

    const handleCreatePlan = () => {
        setIsCreating(true);
    }

    const handleClose = () => {
        setIsCreating(false);
        handleReset();
    }

    const handleRestorePlan = async () => {
        if (!selectedPaymentPlanId) return;
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Vuoi veramente sostituire il PIANO ATTIVO con quello selezionato in ARCHIVIO PIANI???')) {
            try {
                const restorePlan = paymentPlans?.find(plan => plan.id === selectedPaymentPlanId)
                if (!restorePlan) return;
                await saveNewPlan(restorePlan);
                const snackbarData: SnackbarOptions = {};
                snackbarData.type = 'success';
                snackbarData.message = "Piano ripristinato con successo!";
                dispatch(addSnackbar(snackbarData));
                loadPlan(restorePlan);
            } catch (error: any) {
                const snackbarData: SnackbarOptions = {};
                snackbarData.type = 'error';
                snackbarData.message = "C'è stato un problema nel ripristino del vecchio piano!";
                dispatch(addSnackbar(snackbarData));
                throw error;
            }
            

        }
    }

    const checkNewPlan = (): boolean => {
        if (!newPlan) return false;    
        var message: string = "";
        if ((newPlan.annualOption) && (!newPlan.annualDiscountPerc)) message = "Devi specificare la percentuale di sconto annuale.";
        if ((!newPlan.annualOption) && (newPlan.annualDiscountPerc)) message = "Devi attivare lo sconto annuale o rimuovere la percentuale applicata.";
        
        if ((newPlan.trialMonths) && (!newPlan.trialPrice)) message = "Devi specificare il prezzo per il periodo di prova.";
        if ((!newPlan.trialMonths) && (newPlan.trialPrice)) message = "Devi specificare la durata in mesi per il periodo di prova.";

        if (!newPlan.price) message = "Devi specificare il prezzo.";
        if (!newPlan.frequency) message = "Devi specificare una frequenza.";

        if (message === "") return true;
        const snackbarData: SnackbarOptions = {};
        snackbarData.type = 'error';
        snackbarData.message = message;
        dispatch(addSnackbar(snackbarData));
        return false;
    }

    const checkAnnualDiscount = (): boolean => {
        if (!activePlan) return false;    
        var message: string = "";
        if ((activePlan.annualOption) && (!activePlan.annualDiscountPerc)) message = "Devi specificare la percentuale di sconto annuale.";

        if (message === "") return true;
        const snackbarData: SnackbarOptions = {};
        snackbarData.type = 'error';
        snackbarData.message = message;
        dispatch(addSnackbar(snackbarData));
        return false;
    }

    const onChangeActualAnnualDiscount = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value;
        setYearValue(value);
        setActivePlan({...activePlan, annualDiscountPerc: Number(value.replaceAll(',', '.'))});
        if ((Number(value.replaceAll(',', '.')) !== originalYearOption.value) && (Number(value.replaceAll(',', '.')) < 100)){
            setIsEditing(true);
        } else {
            setIsEditing(false);
        }
    }

    const handleSaveAnnualOption = async () => {
        if (!activePlan) return;
        if (!checkAnnualDiscount()) return;
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Vuoi veramente sostituire lo sconto annuale in vigore con quello nuovo???')) {
            try {
                var saveAnnual = activePlan;
                if (!activePlan.annualOption) {
                    saveAnnual.annualDiscountPerc = undefined;
                    setActivePlan(saveAnnual);
                }
                setIsEditing(false);
                await saveNewPlan(saveAnnual);
                const snackbarData: SnackbarOptions = {};
                snackbarData.type = 'success';
                snackbarData.message = "Nuovo sconto annuale salvato con successo!";
                dispatch(addSnackbar(snackbarData));
                loadPlan(activePlan);
            } catch (error: any) {
                const snackbarData: SnackbarOptions = {};
                snackbarData.type = 'error';
                snackbarData.message = "C'è stato un problema nel salvataggio del nuovo sconto annuale!";
                dispatch(addSnackbar(snackbarData));
                setIsEditing(true);
                throw error;
            }
        }
    }

	const handleSavePlan = async () => {
        if (!newPlan) return;
        if (checkNewPlan()) {
            // eslint-disable-next-line no-restricted-globals
            if (confirm('Vuoi veramente sostituire il piano attuale con quello nuovo???')) {
                try {
                    // const planToSave = {...newPlan, productId: product.id, paypalPaymentPlan: [paymentTypes[0]]};
                    const planToSave = {...newPlan, productId: product.id};
                    if (!planToSave.trialMonths) {
                        planToSave.trialMonths = 0;
                        removeProperty(planToSave, 'trialPrice');
                    } else {
                        planToSave.trialMonths = parseFloat(planToSave.trialMonths.toString().replaceAll(',', '.')) || 0;
                        planToSave.trialPrice = parseFloat(planToSave.trialPrice.toString().replaceAll(',', '.')) || 0;
                        if (planToSave.trialPrice === 0) {
                            planToSave.trialPrice = null;
                        }
                    }
                    if (!planToSave.frequency) {
                        planToSave.frequency = 0;
                        removeProperty(planToSave, 'price');
                    } else {
                        planToSave.frequency = parseFloat(planToSave.frequency.toString().replaceAll(',', '.')) || 0;
                        planToSave.price = parseFloat(planToSave.price.toString().replaceAll(',', '.')) || 0;
                    }
                    if (!planToSave.annualOption) {
                        planToSave.annualOption = false;
                        removeProperty(planToSave, 'annualDiscountPerc');
                    } else {
                        planToSave.annualDiscountPerc = parseFloat(planToSave.annualDiscountPerc.replaceAll(',', '.')) || 0;
                    }
                    const savedPlan = await saveNewPlan(planToSave);
                    const snackbarData: SnackbarOptions = {};
                    snackbarData.type = 'success';
                    snackbarData.message = "Nuovo piano salvato con successo!";
                    dispatch(addSnackbar(snackbarData));
                    handleReset();
                    loadPlan(savedPlan);
                } catch (error: any) {
                    const snackbarData: SnackbarOptions = {};
                    snackbarData.type = 'error';
                    snackbarData.message = "C'è stato un problema nel salvataggio del nuovo piano!";
                    dispatch(addSnackbar(snackbarData));
                    throw error;
                }
            }
        }
	};

    const loadPlan = async (paymentPlan: Partial<PaymentPlan>) => {
        const productPaymentPlans = await getPaymentPlans(product.id); 
        setPaymentPlans(productPaymentPlans);           
        setActivePlan(paymentPlan);
        setOriginalYearOption({ status: paymentPlan.annualOption, value: paymentPlan.annualDiscountPerc});
        setYearValue(paymentPlan.annualDiscountPerc ? formatNumber(paymentPlan.annualDiscountPerc) : '');
    }

    useEffect(() => {
        const getPT = async () => {
            try{
                const types = await getPaymentTypes();
                setPaymentTypes(types);
            } catch(err) {
    
            }
        } 
        getPT();
    }, []);

    useEffect(() => {
        if (product) loadPlan(product.productService.paymentPlan);
    }, [product]);

    useEffect(() => {
        if (paymentPlans) {
            var paymentPlansStrings: any[] = [];
            paymentPlans.forEach((plan: PaymentPlan) => {
                var planString = "[id: "+plan.id+"] => ";
                if (plan.trialMonths) {
                    planString += "("+plan.trialMonths+" mes"+(plan.trialMonths > 1 ? "i" : "e")+" di prova a "+formatNumber(plan.trialPrice)+"€) e poi ";
                }
                planString += "("+formatNumber(plan.price)+"€ ogni "+(plan.frequency === 1 ? "mese" : plan.frequency+" mesi")+")";
                if (plan.annualOption) {
                    planString += " ("+formatNumber(plan.annualDiscountPerc)+"% sconto annuale: "+formatNumber(plan.price*12)+"€ diventano "+formatNumber(plan.price*12*(100-plan.annualDiscountPerc)/100)+"€ )";
                }
                paymentPlansStrings.push({
                    id: plan.id,
                    label: planString,
                });
            })
            setPaymentPlansList(paymentPlansStrings.sort((a, b) => b.id - a.id));
        }
    }, [paymentPlans]);

    return (
    <MainBox>
        <RowBox>
            <BaseFieldset legend='PIANO ATTIVO'>
                {!activePlan && "nessuno"}
                {activePlan && 
                <RowBox>
                        <ColumnBox>
                            <InputBox>
                                <Label>Mesi di prova:</Label> 
                                <SimpleInput disabled={true} value={formatNumber(activePlan.trialMonths)} />
                            </InputBox>

                            <InputBox>
                                <Label>Prezzo di prova:</Label>
                                <SimpleInput disabled={true} value={formatNumber(activePlan.trialPrice)} />
                            </InputBox>
                        </ColumnBox>
                
                        <ColumnBox>
                            <InputBox>
                                <Label>Frequenza:</Label>
                                <SimpleInput disabled={true} value={formatNumber(activePlan.frequency)} />
                            </InputBox>
                        
                            <InputBox>
                                <Label>Prezzo:</Label>
                                <SimpleInput disabled={true} value={formatNumber(activePlan.price)} />
                            </InputBox>
                        </ColumnBox>

                        <ColumnBox>
                            <InputBox>
                                <Label>Opz.Annuale:</Label>
                                <CheckboxInput disabled={false} checked={activePlan.annualOption} onChange={handleActiveYearOption} />
                            </InputBox>

                            <InputBox>
                                <Label>% sconto Annuale:</Label>
                                <SimpleInput disabled={!activePlan.annualOption} value={yearValue} onChange={onChangeActualAnnualDiscount}/>
                            </InputBox>

                            <ComputedDiscount>
                            { (activePlan && activePlan.price && activePlan.annualDiscountPerc && yearValue !== '' && activePlan.annualDiscountPerc > 0 && activePlan.annualDiscountPerc < 100) ?
                                <span>{formatNumber(activePlan.price*12)}€ &#8674; {formatNumber(activePlan.price*12*(100-activePlan.annualDiscountPerc)/100)}€</span> : ( activePlan.annualDiscountPerc && activePlan.annualDiscountPerc >= 100) ? "GRATIS?!?" : ""
                            }
                            </ComputedDiscount>
                            

                            <CreateButton
                                disabled={!isEditing}
                                onClick={handleSaveAnnualOption}
                            >
                                Salva Opz. Annuale
                            </CreateButton>
                        </ColumnBox>

                </RowBox>
                }
            </BaseFieldset>
        </RowBox>
        <RowBox>
            <BaseFieldset legend='ARCHIVIO PIANI'>
                <ScrollableTextList 
                    items={paymentPlansList} 
                    selectedItemId={activePlan ? activePlan.id : null} 
                    onItemSelected={(number: number) => setSelectedPaymentPlanId(number) } />
            </BaseFieldset>
        </RowBox>
        <RowBox>
            <ButtonsRowBox>
                    <ResetButton
                        disabled={(!selectedPaymentPlanId) || !!((activePlan) && (activePlan.id) && (selectedPaymentPlanId) && (selectedPaymentPlanId === activePlan.id))}
                        onClick={handleRestorePlan}
                    >
                        Applica il Piano selezionato
                    </ResetButton>
                    <CreateButton
                        onClick={handleCreatePlan}
                    >
                        Crea Piano
                    </CreateButton>
                </ButtonsRowBox>
        </RowBox>
        <RowBox>
            { isCreating &&
            <BaseFieldset legend='CREA PIANO'>
                <RowBox>
                    {/* <ColumnBox>
                        <InputBox>
                            <Label>Tipo Piano:</Label>
                            <SimpleSelect onChange={onChangeTypeSelectHandler}>
                                {
                                    paymentTypes.map((paymentType: any) => {
                                        return (<option value={paymentType.id}>{paymentType.name}</option>);
                                    })
                                }
                            </SimpleSelect>
                        </InputBox>
                    </ColumnBox> */}
                    <ColumnBox>
                        <InputBox>
                            <Label>Mesi di prova:</Label>
                            <SimpleInput
                                name='trialMonths'
                                value={newPlan ? newPlan.trialMonths!==null ? newPlan.trialMonths : '' : ''}
                                onChange={onChangeHandler}
                            />
                        </InputBox>
                        <InputBox>
                            <Label>Prezzo di prova:</Label>
                            <SimpleInput
                                name='trialPrice'
                                value={newPlan ? newPlan.trialPrice!==null ? newPlan.trialPrice : '' : ''}
                                onChange={onChangeHandler}
                            />
                        </InputBox>
                    </ColumnBox>
                    <ColumnBox>
                        <InputBox>
                            <Label>Frequenza:</Label>
                            <SimpleInput
                                name='frequency'
                                value={newPlan ? newPlan.frequency!==null ? newPlan.frequency : '' : ''}
                                onChange={onChangeHandler}
                            />
                        </InputBox>
                        <InputBox>
                            <Label>Prezzo:</Label>
                            <SimpleInput
                                name='price'
                                value={newPlan ? newPlan.price!==null ? newPlan.price : '' : ''}
                                onChange={onChangeHandler}
                            />
                        </InputBox>
                    </ColumnBox>
                    <ColumnBox>
                        <InputBox>
                            <Label>Opz.Annuale:</Label>
                            <CheckboxInput
                                name='opzAnnuale'
                                id='opzAnnuale'
                                value=''
                                checked={yearOption}
                                onChange={handleYearOption}
                            />
                        </InputBox>
                        <InputBox>
                            <Label>% sconto Annuale:</Label>
                            <SimpleInput
                                name='annualDiscountPerc'
                                value={newPlan ? newPlan.annualDiscountPerc!==null ? newPlan.annualDiscountPerc : '' : ''}
                                onChange={onChangeHandler}
                            />
                        </InputBox>
                    </ColumnBox>
                </RowBox>
                <ButtonsRowBox>
                    <CancelButton
                        onClick={handleClose}
                    >
                        Annulla
                    </CancelButton>
                    <ResetButton
                        disabled={!newPlan}
                        onClick={handleReset}
                    >
                        Azzera Campi
                    </ResetButton>
                    <CreateButton
                        disabled={!newPlan}
                        onClick={handleSavePlan}
                    >
                        Salva e applica il nuovo Piano
                    </CreateButton>
                </ButtonsRowBox>
            </BaseFieldset> 
            }          
        </RowBox>
    </MainBox>
    );
}

export default PaymentPlansTab;