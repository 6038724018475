import { FC, useState, FormEvent } from 'react';

// routing
import { useNavigate } from 'react-router-dom';

// redux
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

// hooks
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsMounted } from 'usehooks-ts';

// components
import TextInput from 'components/ui/TextInput';

// utils
import { validateEmailInput } from 'utils/inputValidation';
import { getAwsAmplifyErrorMessage } from 'utils/awsErrorMessages';
import { sendForgotPasswordConfirmation } from './services/PasswordRecoveryForm.services';
import { SendForgotPasswordConfirmationPayload } from './services/PasswordRecoveryForm.services.d';

// style
import { Form, SendNewPasswordButton, TextSeparator } from './style/PasswordRecoveryForm.style';


/**
 * Password recovery form
 * 
 * @author Alessio Grassi
 * 
 * @returns JSX
 */


const PasswordRecoveryForm: FC<{}> = () => {

    const [emailInput, setEmailInput] = useState<string>("");
    const [emailErrorMessage, setEmailErrorMessage] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isMounted = useIsMounted();

    const validateEmailHandler = (newEmail: string) => {

        const error = validateEmailInput(newEmail);
        setEmailErrorMessage(error);

        return error;
    };

    const emailInputChangeHandler = (newEmail: string) => {

        validateEmailHandler(newEmail);
        setEmailInput(newEmail);
    };

    const cleanFormInputs = () => {

        setEmailInput("");
        setEmailErrorMessage(undefined);
    };

    const formSubmitHandler = async (event: FormEvent<HTMLFormElement>) => {
        
        event.preventDefault();
        if (isLoading) return;
        const emailError = validateEmailHandler(emailInput);
        if (emailError) return;

        const snackbarData: SnackbarOptions = {};

        setIsLoading(true);

        try {

            const userPayload: SendForgotPasswordConfirmationPayload = {
                
                username: emailInput
            };

            const response = await sendForgotPasswordConfirmation(userPayload);
            if (!isMounted()) return;

            if (response === undefined || !response) {
                throw new Error();
            }

            cleanFormInputs();
            snackbarData.type = 'success';
            snackbarData.message = "Ti abbiamo inviato un'email per confermare il cambio di password";
            dispatch( addSnackbar(snackbarData) );
            setIsLoading(false);
            navigate('/login/change-password', { replace: true });
            
        } catch (error: any) {

            snackbarData.type = 'error';
            snackbarData.message = getAwsAmplifyErrorMessage(error.code);
            dispatch( addSnackbar(snackbarData) );
            setIsLoading(false);
        }
    };


    return (
        <Form onSubmit={formSubmitHandler}>
            <TextInput
                label="Email:"
                type="text"
                flavor="filled"
                value={emailInput}
                errorMsg={emailErrorMessage}
                onChange={emailInputChangeHandler}
                onValidate={() => validateEmailHandler(emailInput)}
            />

            <SendNewPasswordButton
                type="submit"
                value="recoveryPassword"
                disabled={isLoading}
            >
                Cambia la password
            </SendNewPasswordButton>

            <TextSeparator>oppure</TextSeparator>
        </Form>
    );
};

export default PasswordRecoveryForm;
