import { CourseNode } from 'types/Course';

export const rebasePositions = (nodes: CourseNode[], parentId?: number) => {
	if (!nodes) return;
	let currentLevel = nodes.filter((node) => node.parentId === parentId);
	currentLevel.sort((a, b) => a.pos - b.pos);

	currentLevel.forEach((node, index) => {
		// console.log('reBasing position of node ' + node.id + ' from ' + node.pos + ' to ' + index);
		node.pos = index;
		rebasePositions(nodes, node.id);
	});
};

export const deleteNodeAndDescendants = (nodes: CourseNode[], nodeId: number) => {
	let descendants = nodes.filter((node) => node.parentId === nodeId);
	descendants.forEach((descendant) => {
		deleteNodeAndDescendants(nodes, descendant.id);
	});

	nodes.splice(
		nodes.findIndex((node) => node.id === nodeId),
		1
	);
};
