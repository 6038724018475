import colors from 'style/palette/palette';
import styled from 'styled-components';


export const BulletListBlock = styled.div<{isUserAdmin: boolean}>`

	cursor: ${({isUserAdmin}) => isUserAdmin ? 'pointer' : ' default'};
	position: relative;
`;

export const BulletList = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;
    gap: 15px;
`;

export const BulletListItem = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;

    gap: 20px;

    font-size: 15px;
    font-weight: 500;
    color: ${colors.white};
`;

export const EditBox = styled.div`

	display: flex;
	align-items: center;
	justify-content: center;

	width: 100% !important;
	height: 100%;
	min-height: 25px;

	padding: 10px;

	border-radius: 5px;
	border: 2px solid ${colors.white070};
	background: ${colors.black087};

	font-size: 25px;
	color: ${colors.white070};

	cursor: pointer;
	position: absolute;
	top: 0;
	left: 0;

`;