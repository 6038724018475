import React, { FC, useEffect, useState } from 'react';

//types
import { EditableInputProps } from './EditableInput.component.d';

//assets
import { ReactComponent as EditIcon } from './assets/editIcon.svg';

//style
import { EditableInputBox, TitleModify } from './style/EditableInput.component.style';

const EditableInput: FC<EditableInputProps> = ({ inputId, editableInput, placeholder, update, enabled, pencil = true, mandatoryToBeCompleted }) => {
	const [isEnabled, setIsEnabled] = useState(true);
	const [isOnHover, setIsOnHover] = useState(false);
	const [localContent, setLocalContent] = useState('');
	const [oldContent, setOldContent] = useState('');

	const onMouseOverHandler = () => {
		setIsOnHover(true);
	};

	const onMouseOutHandler = () => {
		setIsOnHover(false);
	};

	const onClickHandler = () => {
		setIsEnabled(true);
	};

	const onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
		const code = event.code;

		if (code === 'Enter' || code === 'NumpadEnter') {
			// update(inputId, localContent);
			event.currentTarget.blur();
		}
		if (code === 'Escape') {
			setLocalContent(oldContent);
			event.currentTarget.blur();
		}
	};

	const onFocusHandler = (event: React.FocusEvent<HTMLInputElement>) => {
		setOldContent(event.currentTarget.value);
		event.currentTarget.select();
	};

	const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
		if (mandatoryToBeCompleted && !checkIfMandatoryToBeCompleted(event.currentTarget.value, mandatoryToBeCompleted)) return;
		update(inputId, localContent);
	};

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLocalContent(event.currentTarget.value);
	};

	const checkIfMandatoryToBeCompleted = (content: string, mandatoryToBeCompleted: string[]) => {
		return mandatoryToBeCompleted.some((suffix) => content.endsWith(suffix));
	}

	useEffect(() => {
		if (editableInput === undefined) return;
		setLocalContent(editableInput);
	}, [editableInput]);

	useEffect(() => {
		if (enabled === undefined) return;
		setIsEnabled(enabled);
	}, [enabled]);

	return (
		<>
			<EditableInputBox
				type='text'
				disabled={!isEnabled}
				value={localContent}
				size={localContent?.length > 0 ? localContent?.length+4 : placeholder ? placeholder.length+4 : 5}
				onMouseOver={onMouseOverHandler}
				onMouseOut={onMouseOutHandler}
				onClick={onClickHandler}
				onChange={onChangeHandler}
				onKeyDown={onKeyDownHandler}
				onBlur={onBlurHandler}
				onFocus={onFocusHandler}
				localContent={localContent}
				placeholder={placeholder}
			/>
			{isOnHover && pencil && (
				<TitleModify>
					<EditIcon />
				</TitleModify>
			)}
		</>
	);
};

export default EditableInput;
