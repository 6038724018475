import { callGET, callPUT } from 'network/network';

import { whatsappSearchParams } from './WhatsappPage.services.d';

import { getBrand } from 'configs/brandMap/brandMap.helper';
import { CoachDescription } from 'pages/UserDetailPage/Coach/style/Coach.component.style';

const PRODUCT_ID = 378; // Lezione Assistente Personale
const _COACHES_GET_PATH = 'v1/coaches';
const _WHATSAPP_SEARCH_PATH = 'v1/productlessonpacks/subscriptions';
const _WHATSAPP_ASSIGN_PATH = 'v1/productlessonpacks/subscriptions/{subscriptionId}/assign/{coachId}';
const _APLESSONS_CLOSE_PATH = 'v1/productlessonpacks/subscriptions/{subscriptionId}/close';
const _WHATSAPP_REASSIGN_PATH = 'v1/productlessonpacks/subscriptions/{subscriptionId}/assign/{coachId}';

export const getCoaches = async () => {
	try {
		const response = await callGET(_COACHES_GET_PATH, true);

		if (response === undefined || !response) {
			throw new Error();
		}
		console.log(response);

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const getSearchWhatsapp = async (whatsappSearchParams: whatsappSearchParams) => {
	try {
		const response = await callGET(_WHATSAPP_SEARCH_PATH, true, whatsappSearchParams);

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const assignLessonToCoach = async (subscriptionId: number, coachId: number) => {
	const brand = getBrand();
	if (!brand) return;
    try { 
        const response = await callPUT(_WHATSAPP_ASSIGN_PATH.replace(
			/{subscriptionId}/g,
			subscriptionId.toString()
		  ).replace(
			/{coachId}/g,
			coachId.toString()
		  ), {}, true, { brandCode: brand.code });

        if (response === undefined || !response) {
            throw new Error();
        }

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const reassignLessonToAP = async (apLessonId: number, apId: number) => {
	const brand = getBrand();
	if (!brand) return;
    try { 
        const response = await callPUT(_WHATSAPP_REASSIGN_PATH.replace(
			/{subscriptionId}/g,
			apLessonId.toString()
		  ).replace(
			/{coachId}/g,
			apId.toString()
		  ), {}, true, { brandCode: brand.code });

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const closeWhatsapp = async (subscriptionId: number, success: boolean, notes: string) => {
	const brand = getBrand();
	if (!brand) return;
    try { 
        const response = await callPUT(_APLESSONS_CLOSE_PATH.replace(
			/{subscriptionId}/g,
			subscriptionId.toString()
		  ), {}, true, { success: success, closingNotes: notes, brandCode: brand.code });

        return response;
    } catch (error: any) {
        throw error;
    }
};