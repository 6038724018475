import { callDELETE, callPOST, callPUT } from 'network/network';
import { CourseNode } from 'types/Course';

import { getBrand } from 'configs/brandMap/brandMap.helper';

const _COURSES_PATH = 'v1/courses';
const _COURSES_NODES_PATH = 'v1/courses/nodes';
const _COURSES_NODES_LESSONS_PATH = 'v1/courses/nodes/lesson';


export const putCourseContent = async (updatedContent: Partial<CourseNode>[]) => {
	const brand = getBrand();
	if (!brand) return;
	try {
		const response = await callPUT(_COURSES_NODES_PATH, updatedContent, true, { brandCode: brand.code });

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const deleteCourseNode = async (nodeId: number) => {
	const brand = getBrand();
	if (!brand) return;
	try {
		await callDELETE(_COURSES_NODES_PATH + '/' + nodeId, {}, true, { brandCode: brand.code });
	} catch (error: any) {
		throw error;
	}
};

export const postCourseNodeLesson = async (node: Partial<CourseNode>) => {
	const brand = getBrand();
	if (!brand) return;
	try {
		const response = await callPOST(_COURSES_NODES_LESSONS_PATH, node, true, { brandCode: brand.code });

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const addCourse = async (title: string) => {
	const brand = getBrand();
	if (!brand) return;
	try {
		const response = await callPOST(_COURSES_PATH, {}, true, { title, brandCode: brand.code });

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};
