import { FC, useState } from 'react';

// hooks
import useHasRole from 'hooks/useHasRole';

//types
import { EditableBulletListProps } from './EditableBulletList.component.d';

//assets
import { ReactComponent as VectorSVG } from './assets/Vector.svg';

//components
import SectionEditorModal from 'components/SectionEditorModal/SectionEditorModal.component';

//style
import {
    BulletListBlock,
    BulletList,
    BulletListItem,
    EditBox
 } from './style/EditableBulletList.style';


const EditableBulletList:FC<EditableBulletListProps> = ({
    productId,
    blockId,
    bulletListId,
    bulletList,
    isRequired,
    update,
    refreshProduct
}) => {

    const [isEditorEnabled, setIsEditorEnabled] = useState(false);
	const [isOnHover, setIsOnHover] = useState(false);

    const isAdmin = useHasRole('ADMIN');
    const isSuperAdmin = useHasRole('SUPERADMIN');
    const userHasAdminRole = isAdmin || isSuperAdmin;

	const onMouseOverHandler = () => setIsOnHover(true);
	const onMouseOutHandler = () => setIsOnHover(false);
	const onCloseModalHandler = () => setIsEditorEnabled(false);
	
	const onModifyButtonClickHandler = () => {
        
        if (!userHasAdminRole) return;
		setIsEditorEnabled(prevState => !prevState);
	};

    const finalBulletList1 = (bulletList === null || bulletList === undefined) ? "BULLET LIST" : bulletList[0];
    const finalBulletList2 = ((bulletList === null || bulletList === undefined) ? "BULLET LIST" : ( bulletList[1] ?? "BULLET LIST"));
    const finalBulletList3 = ((bulletList === null || bulletList === undefined) ? "BULLET LIST" : ( bulletList[2] ?? "BULLET LIST"));
    const bulletListToDisplay = {
        finalBulletList1,
        finalBulletList2,
        finalBulletList3
    };

    return (
        <>
            <BulletListBlock
				onMouseOver={onMouseOverHandler}
				onMouseLeave={onMouseOutHandler}
				onClick={onModifyButtonClickHandler}
                isUserAdmin={userHasAdminRole}
			>
                <BulletList>
                    <BulletListItem>
                        <VectorSVG />

                        {finalBulletList1}
                    </BulletListItem>

                    <BulletListItem>
                        <VectorSVG />

                        {finalBulletList2}
                    </BulletListItem>

                    <BulletListItem>
                        <VectorSVG />

                        {finalBulletList3}
                    </BulletListItem>
                </BulletList>
				{isOnHover && userHasAdminRole && (
					<EditBox>
						Modifica lista
					</EditBox>
				)}
			</BulletListBlock>

			{isEditorEnabled && userHasAdminRole && (
				<SectionEditorModal 
					onCloseButtonModal={onCloseModalHandler}
					update={update}
					blockId={blockId}
					elementId={bulletListId}
					typeOfElement='BULLETLIST'
					isRequired={isRequired}
                    elementsToDisplay={bulletListToDisplay}
                    productId={productId}
                    updateData={refreshProduct}
				/>
			)}
</>
    );
};

export default EditableBulletList;