import styled, { css } from 'styled-components';

//style
import colors from 'style/palette/palette';

export const OnShopBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const OnShopIcon = styled.button<{ onshop: boolean; canToggle: boolean }>`
	display: flex;
	background-color: ${colors.transparent};
	border: none;

	${({ canToggle }) =>
		canToggle &&
		css`
			cursor: pointer;
		`}

	${({ onshop }) =>
		onshop &&
		css`
			> svg path {
				fill: ${colors.green};
				fill-opacity: 1;
			}
		`}

	${({ onshop }) =>
		!onshop &&
		css`
			> svg path {
				fill: ${colors.red};
				fill-opacity: 1;
			}
		`}
`;
