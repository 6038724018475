import styled, { css } from 'styled-components';
import colors from "style/palette";

export const CoachBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15px;
    margin: 0 0 10px;
`;

export const CoachImageContainer = styled.div`
	    display: flex;

        flex-shrink: 0;

        width: 100px;
        height: 100px;

        border-radius: 50%;

        overflow: hidden;
        position: relative;
`;

export const CoachImage = styled.img`
	width: 7rem;
	align-self: flex-start;

	cursor: pointer;
`;

export const ImageEditIconOverlay = styled.div`
	margin-left: -70px;
    margin-bottom: -100px;

	> svg {
            min-width: 15px;
            min-height: 15px; 
			filter: brightness(0) saturate(100%) invert(84%) sepia(30%) saturate(5368%) hue-rotate(359deg) brightness(103%) contrast(105%);       
    }
`;

export const CoachImageNewFile = styled.input.attrs({ 
    type: 'file',
  })`
    display: none;
`;

export const Col = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    > svg path {
            min-width: 15px;
            min-height: 15px; 
            fill: white;
            fill-opacity: 1;
    }
`;

export const CoachTopic = styled.input.attrs({
    type: 'text',
})`
    width: 433px;
    font-family: "Montserrat",sans-serif;
    font-size: 15px;
    font-style: italic;
    font-weight: 700;
`;

export const CoachCalendly = styled.input.attrs({
    type: 'text',
})`
    width: 408px;
    margin: 5px 0 5px 5px;
`;

export const CoachDescription = styled.textarea`
    width: 248px;
    font-family: "Montserrat",sans-serif;
    font-size: 13px;
`;

export const CoachSaveButton = styled.button`
    width: 150px;

    color: #ffffff;

    border: none;
    border-radius: 4px;
    background-color: rgb(102, 187, 106);

    cursor: pointer;

    ${(props: {disabled: boolean}) => props.disabled && css`
        background-color: gray;
    `}
`;