import styled from 'styled-components';

export const PoolSelectionBox = styled.div``;

export const Selection = styled.div``;

export const PoolLabel = styled.label``;

export const Balance = styled.span`
	font-size: 0.7rem;
`;

export const PoolInput = styled.input``;

export const PoolExpandButton = styled.button``;

export const OptionList = styled.ul`
	background-color: white;
	color: black;
	position: relative;
	z-index: 2;
	max-height: 250px;
	overflow-y: auto;
	width: 100%;
	list-style-type: none;

	li {
		padding: 0.25rem;
		cursor: pointer;

		&:hover {
			background-color: lightgray;
		}
	}
`;
