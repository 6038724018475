import { FC, useRef } from 'react';

//hooks
import useCopyToClipboard from 'hooks/useCopyToCliboard';

//types
import { FacebookLinkedAccountProps } from './FacebookLinkedAccount.component.d';

//assets
import { ReactComponent as AvatarIcon } from './assets/avatarIcon.svg';

//style
import { FacebookFederatedContainer, FirstRow, FacebookImg, FacebookName, FacebookCognitoId } from './style/FacebookLinkedAccount.style';

const FacebookLinkedAccount: FC<FacebookLinkedAccountProps> = ({ userFederateds, cognitoIdEnabled }) => {
	const hasFacebook = userFederateds.some((f) => f.federatedName === 'Facebook');
	const facebookFederated = userFederateds.filter((f) => f.federatedName === 'Facebook')[0];
	const imgSrc = hasFacebook && facebookFederated.pictureOnFederated ? JSON.parse(facebookFederated.pictureOnFederated).data.url : '';
	const nameOnFederated = hasFacebook ? facebookFederated.nameOnFederated : '-';
	const facebookCognitoId = hasFacebook ? facebookFederated.federateUserdId : '-';

	const facebookCognitoIdRef = useRef<HTMLDivElement>(null);
	const { copyToClipboard, copied } = useCopyToClipboard();

	const profileUrl = hasFacebook ? `https://www.facebook.com/search/top/?q=${facebookFederated.nameOnFederated.split(' ').join('+')}` : undefined;

	const clickHandler = () => {
		window.open(profileUrl, '_blank');
	};

	const facebooCognitoIdClickHandler = () => {
		copyToClipboard(facebookCognitoIdRef);
	};

	return (
		<FacebookFederatedContainer>
			<FirstRow
				hasFacebook={hasFacebook}
				onClick={clickHandler}
			>
				{hasFacebook && <FacebookImg src={imgSrc} />}
				{!hasFacebook && <AvatarIcon />}
				<FacebookName>{nameOnFederated}</FacebookName>
			</FirstRow>
			{cognitoIdEnabled && (
				<FacebookCognitoId
					title='click per copiare!'
					ref={facebookCognitoIdRef}
					onClick={facebooCognitoIdClickHandler}
				>
					{copied ? 'Copiato!' : facebookCognitoId}
				</FacebookCognitoId>
			)}
		</FacebookFederatedContainer>
	);
};

export default FacebookLinkedAccount;
