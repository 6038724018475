import styled from 'styled-components';
import devices from 'style/breakpoints';
import { Button } from 'style/buttons';
import colors from 'style/palette/palette';

export const CardContent = styled.div`
	display: flex;
	width: 100%;

	padding: 30px;

	background-color: ${colors.veryDarkGrey};
	border-radius: 15px;

	text-align: center;

	@media ${devices.tablet} {
		padding: 35px 40px 50px;
	}
`;

export const IconBox = styled.div`
	margin: 30px 0 0;
	text-align: center;

	@media ${devices.tablet} {
		margin: 0;
	}
`;

export const CloseButton = styled.button`
	margin: 0;
	padding: 0;

	background-color: ${colors.transparent};
	border: none;

	cursor: pointer;
`;

export const Title = styled.h2`
	width: 100%;
	margin: 30px 0 0;
	padding: 0;

	font-size: 16px;
	font-weight: 400;
	line-height: 28px;

	color: ${colors.primaryColor};

	text-align: center;
	text-transform: uppercase;
`;

export const TextContent = styled.p`
	margin: 30px 0 0;
	padding: 0;

	font-size: 15px;
	font-weight: 400;
	line-height: 28px;

	color: ${colors.white070};

	text-align: center;
`;

export const TextDisclaimer = styled.span`
	color: ${colors.white};
`;

export const CommentBox = styled.textarea`
	width: 100%;
	min-height: 200px;

	margin: 30px 0 0;
	padding: 25px;

	border: 1px solid ${colors.white023};
	border-radius: 10px;

	font-size: 15px;
	font-weight: 400;
	line-height: 28px;

	background-color: ${colors.transparent};
	color: ${colors.white070};
`;

export const SendRatingButton = styled(Button)`
	margin: 30px 0 0;
	padding: 15px 30px;
`;

export const ProductsColumn = styled.div`
	display: flex;
	flex-direction: column;
`;

export const ProductName = styled.div`
	font-size: 0.75rem;

	color: ${colors.white070};

	/* text-align: center; */
`;
