import styled from 'styled-components';

export const FlowBoxContainer = styled.div`

display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;

width: 100%;

padding: 0;

>div:first-child {
    border-radius: 10px 10px 0 0;
}

>div:last-child {
    border-radius: 0 0 10px 10px;
}
`;
