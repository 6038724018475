import styled, { css } from 'styled-components';

//style
import colors from 'style/palette/palette';
import { isClickableInput } from '@testing-library/user-event/dist/utils';

export const SingleMission = styled.button<{ name: string; isCompleted?: boolean | undefined; clickHandler?: () => void; isClickable?: boolean }>`
	display: flex;
	background-color: ${colors.transparent};
	border: none;
	cursor: default;

	${({ name, isClickable }) =>
		name === 'betfair' && isClickable &&
		css`
			cursor: pointer;
		`}

	${({ isCompleted }) =>
		isCompleted &&
		css`
			> svg path {
				fill: ${colors.green};
				fill-opacity: 1;
			}
		`}
`;
