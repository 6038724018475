import { FC, useEffect, useState } from 'react';

//types
import { CloseWhatsappModalProps } from './CloseWhatsappModal.component.d';

//components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';

//style
import { 
	ButtonsRow, 
	CancelButton, 
	SaveButton, 
	CloseWhatsappBox, 
	NotesTextArea,
	NotesRow,
	NotesColumn,
	NotesTitle,
} from './style/CloseWhatsappModal.component.style';

import { enableAppMain } from 'redux/slices/slice-app-main';
import useAppDispatch from 'hooks/useAppDispatch';

const CloseWhatsappModal: FC<CloseWhatsappModalProps> = ({ success, onCloseButtonModal, onSaveButtonModal }) => {
	const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);

	const [notes, setNotes] = useState<string>('');
	const [privateNotes, setPrivateNotes] = useState<string>('');

	const [isContentModified, setIsContentModified] = useState<boolean>(false);

	const dispatch = useAppDispatch();

	const clearInput = () => {
		setNotes('');
		setPrivateNotes('');
	}

	const inputClosingChangeHandler = (inputValue: any) => {
		if (isContentModified === false) setIsContentModified(true);
		setNotes(inputValue.target.value);
	};

	const inputClosingKeyHandler = (event: any) => {
		if (event.keyCode === 27) {
			closeButtonHandler();
		}
		if (event.keyCode === 13 && !event.shiftKey && isContentModified) {
			onSaveClickHandler();
		}
	};

	const closeButtonHandler = () => {
		if (onCloseButtonModal) onCloseButtonModal();
		clearInput();
		dispatch(enableAppMain());
	};

	const onSaveClickHandler = () => {
		// eslint-disable-next-line no-restricted-globals
		if (confirm("Vuoi veramente chiudere questa Autostrada?")) {
			if (onCloseButtonModal) onCloseButtonModal();
			onSaveButtonModal(notes);
			clearInput();
			dispatch(enableAppMain());
		}
	};

	return (
		<BaseModal
			isBackdropClickActive={true}
			onClose={closeButtonHandler}
			isCloseButtonClicked={isCloseButtonClicked}
		>
			<BaseCard
				flavor='outlined'
				maxWidth='700px'
			>
				<CloseWhatsappBox>
					<NotesRow>
						<NotesColumn>
							<NotesTitle>
								Note chiusura {success ? "SUCCESSO" : "FALLIMENTO"}:
							</NotesTitle>

							<NotesTextArea
								onKeyDown={inputClosingKeyHandler}
								onChange={inputClosingChangeHandler}
								value={notes}
								autoFocus
							/>
						</NotesColumn>

					</NotesRow>
					<ButtonsRow>
						<CancelButton onClick={closeButtonHandler}>Annulla</CancelButton>

						<SaveButton
							disabled={!isContentModified}
							onClick={onSaveClickHandler}
						>
							Chiudi Call Whatsapp
						</SaveButton>
					</ButtonsRow>
				</CloseWhatsappBox>
			</BaseCard>
		</BaseModal>
	);
};

export default CloseWhatsappModal;
