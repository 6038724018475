import { FC } from 'react';

//types
import { MissionProps } from './Mission.component.d';

//style
import { SingleMission } from './style/Mission.component.style';

const Mission: FC<MissionProps> = ({ title, name, MissionIcon, isCompleted, clickHandler, isClickable }) => {
	return (
		<SingleMission
			title={title}
			name={name}
			isCompleted={isCompleted}
			onClick={clickHandler}
			isClickable={isClickable}
		>
			<MissionIcon />
		</SingleMission>
	);
};

export default Mission;
