import { FC } from 'react';

import { DashboardTitleSectionProps } from './DashboardTitleSection.component.d';

//style
import { TitleSection } from './style/DashboardTitleSection.style';
import TitleBreadCrumb from './TitleBreadCrumb';

const DashboardTitleSection: FC<DashboardTitleSectionProps> = ({ breadCrumbSettings, children }) => {
	return (
		<TitleSection>
			<TitleBreadCrumb
				index={breadCrumbSettings.index}
				subSectionText={breadCrumbSettings.subSectionText}
				subSubSectionText={breadCrumbSettings.subSubSectionText}
				subSectionLink={breadCrumbSettings.subSectionLink}
			/>
			{children}
		</TitleSection>
	);
};

export default DashboardTitleSection;
