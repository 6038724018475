import { FC, useEffect, useState } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsMounted } from 'usehooks-ts';

//types
import { OnShopProps } from './OnShop.component.d';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

//assets
import { ReactComponent as OnShopOnIcon } from './assets/onshopON.svg';
import { ReactComponent as OnShopOffIcon } from './assets/onshopOFF.svg';

//utils

//style
import { OnShopBox, OnShopIcon } from './style/OnShop.component.style';
import useHasRole from 'hooks/useHasRole';

const OnShop: FC<OnShopProps> = ({ product, putProduct }) => {
	const dispatch = useAppDispatch();
	const isMounted = useIsMounted();

	const isAdmin = useHasRole('ADMIN');
	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');
	var isDiretta = useHasRole('DIRETTA');
	const isRegistaLDL = useHasRole('REGISTA-LDL');
	const isRegistaMB = useHasRole('REGISTA-MB')
	const isRegistaTCR = useHasRole('REGISTA-TCR');
	const isEditorLDL = useHasRole('EDITOR-LDL');
	const isEditorMB = useHasRole('EDITOR-MB');
	const isEditorTCR = useHasRole('EDITOR-TCR');
	isDiretta = isDiretta || isRegistaLDL || isRegistaMB || isRegistaTCR || isEditorLDL || isEditorMB || isEditorTCR;
	
	const clickHandler = async () => {
		const snackbarData: SnackbarOptions = {};

		try {
			await putProduct({ id: product.id, productThematicCall: {...product.productThematicCall, onShop: !product.productThematicCall.onShop }});
		} catch (error) {
			snackbarData.type = 'error';
			snackbarData.message = "Errore nell'aggiornamento permanenza in negozio";
			dispatch(addSnackbar(snackbarData));
			if (!isMounted()) return;
		}
	};

	return (
		<OnShopBox>
			<OnShopIcon
				title={isSuperAdmin ? (product.productThematicCall.onShop ? 'Non caricare nel catalogo' : 'Carica nel catalogo') : ''}
				onshop={product.productThematicCall.onShop}
				canToggle={isSuperAdmin || isDiretta}
				onClick={(isSuperAdmin || isDiretta) ? clickHandler : () => alert('Non hai i permessi per modificare la presenza del prodotto nel catalogo')}
			>
				{product.productThematicCall.onShop && <OnShopOnIcon />}
				{!product.productThematicCall.onShop && <OnShopOffIcon />}
			</OnShopIcon>
		</OnShopBox>
	);
};

export default OnShop;
