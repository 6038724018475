import { FC } from 'react';

// routing
import { Link, useNavigate } from 'react-router-dom';

// hooks
import useAppSelector from 'hooks/useAppSelector';

// components
import HamburgerButton from './HamburgerButton';
import TabButton from './TabButton';
import UserProfileSubmenu from '../UserProfileSubmenu';

// types
import { TabBarProps } from './TabBar.d';

// assets
import { ReactComponent as BrandSVG } from '../assets/brand-icon.svg';
import { ReactComponent as SupportSVG } from '../assets/support-icon.svg';
import { ReactComponent as LoginSVG } from '../assets/login-icon.svg';
import { ReactComponent as DashboardSVG } from './assets/dashboard-icon.svg';
import { ReactComponent as ShopSVG } from './assets/shop-icon.svg';
import { ReactComponent as NotifySVG } from '../assets/notify-icon.svg';

// style
import { LoginArea, SupportBox, LoginBox } from '../style/Header.style';
import { TabBarBox, BrandBox, SignupText, IconsBar } from './style/TabBar.style';
import { getEnvironmentMonolithLinkUrl } from 'configs/config.helper';


/**
 * TabBar
 * 
 * @author Alessio Grassi
 * 
 * @param isSubmenuOpen -  the "open" state of the Submenu
 * @param onSubmenuButtonClick -  toggle handler of the Submenu
 * @param onSubmenuLinkClick -  handler for Submenu's closure
 * 
 * @returns JSX
 */


const TabBar: FC<TabBarProps> = ({ isSubmenuOpen, onSubmenuButtonClick, onSubmenuLinkClick }) => {

    const { tokenID } = useAppSelector(state => state.user);
    const navigate = useNavigate();

    const dashboardClickHandler = () => {
        navigate('reservedArea/libertydashboard')
    };

    const shopClickHandler = () => {
        window.location.href = getEnvironmentMonolithLinkUrl('reservedArea/home/prodotti')
    };

    const notifyClickHandler = () => {
        window.location.href = getEnvironmentMonolithLinkUrl('reservedArea/notification')
    };

    const isUserLoggedIn = !!tokenID;
    const brandSvgLinkTo = isUserLoggedIn ? '/home-page' : '/landing';

    return (
        <TabBarBox isUserLoggedIn={isUserLoggedIn}>
            <BrandBox>
                <Link to={brandSvgLinkTo}>
                    <BrandSVG />
                </Link>
            </BrandBox>

            {(null) && !isUserLoggedIn && (
                <>
                    <SignupText>
                        <Link to='/sign-up'>Iscriviti Ora!</Link>
                    </SignupText>

                    <LoginArea>
                        <SupportBox
                            href='https://www.liberidalavoro.it/public/supporto' 
                            target={'_blank'} 
                            rel='noreferrer noopener'
                        >
                            <SupportSVG />
                        </SupportBox>

                        <LoginBox>
                            <Link to='/login'>
                                <LoginSVG />
                            </Link>
                        </LoginBox>
                    </LoginArea>
                </>
            )}

            {isUserLoggedIn && (
                <>
                    {/* <IconsBar>
                        <TabButton 
                            SVGAsset={<DashboardSVG />}
                            onClick={dashboardClickHandler}
                        />
                        <TabButton 
                            SVGAsset={<ShopSVG />}
                            onClick={shopClickHandler}
                        />
                        <TabButton 
                            SVGAsset={<NotifySVG />}
                            onClick={notifyClickHandler}
                        />
                    </IconsBar> */}
        
                    <HamburgerButton onClick={onSubmenuButtonClick} />
        
                    <UserProfileSubmenu
                        isSubmenuOpen={isSubmenuOpen}
                        onLinkClick={onSubmenuLinkClick}
                    />
                </>
            )}
        </TabBarBox>
    );
}

export default TabBar;
