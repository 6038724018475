import { callGET, callPOST, callDELETE } from 'network/network';


const _NOTIFICATIONS_CHANNELS_PATH = 'v1/channels';
const _NOTIFICATIONS_CHANNEL_MESSAGES_PATH = 'v1/channelmessages';

const sortByName = (channelsList: any[]) => {
    const sorted = channelsList.sort((a, b) => {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });
    return sorted;
};

export const getChannelsList = async () => {
    try {
        const response = await callGET(_NOTIFICATIONS_CHANNELS_PATH, true);

        if (response === undefined || !response) {
            throw new Error();
        }

        return sortByName(response);
    } catch (error: any) {
        throw error;
    }
};

export const getMessagesList = async (channelId: number) => {
    try {
        const response = await callGET(_NOTIFICATIONS_CHANNEL_MESSAGES_PATH+"/"+channelId+"/all", true);

        if (response === undefined || !response) {
            throw new Error();
        }

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const deleteMessage = async (messageId: number) => {
    try {
        const response = await callDELETE(_NOTIFICATIONS_CHANNEL_MESSAGES_PATH+"/"+messageId, {}, true);

        if (response === undefined) {
            throw new Error();
        }

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const postMessage = async (message: any) => {
    try {
        const response = await callPOST(_NOTIFICATIONS_CHANNEL_MESSAGES_PATH, message, true);

        if (response === undefined || !response) {
            throw new Error();
        }

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const uploadFile = async (channelId: number, form: FormData) => {
    try {
        const response = await callPOST(_NOTIFICATIONS_CHANNELS_PATH+'/'+channelId+'/upload', form, true);

        if (response === undefined || !response) {
            throw new Error();
        }

        return response;
    } catch (error: any) {
        throw error;
    }
};