import { useEffect, useState } from 'react';

// hooks
import useAppSelector from 'hooks/useAppSelector';

/**
 *
 * @param {*} requiredPermission
 * @returns
 */
const useHasPermission = (requiredPermission: string) => {
	const [isVisible, setIsVisible] = useState(false);
	const { permissions } = useAppSelector((state) => state.user);
	// const { brand } = useAppSelector(state => state.ui);

	useEffect(() => {
		// console.log('Checking role');
		if (permissions === undefined) return;

		let hasPermission = true;

		// console.log('Required Permission', requiredPermission);
		// console.log("Required domain", brand);

		let userPermission = permissions.filter((el) => el.name === requiredPermission);

		// console.log('userPermission', userPermission);

		if (userPermission.length < 1) hasPermission = false;

		setIsVisible(hasPermission);
	}, [requiredPermission, permissions]);

	return isVisible;
};

export default useHasPermission;
