import { useEffect, useState } from 'react';

//services

//utils
import { footerElementsCount } from './MyLessonsHuntersTable.helper';

//types
import { tableColumnAttr, MyLessonsHuntersTableItem, MyLessonsHuntersTableProps } from './MyLessonsHuntersTable.component.d';

//assets
import { ReactComponent as ChevronLeftIcon } from './assets/chevronLeft.svg';
import { ReactComponent as ChevronRightIcon } from './assets/chevronRight.svg';
import { ReactComponent as DownArrowIcon } from './assets/downArrowIcon.svg';


//style
import {
    FooterNavigationButton,
	FooterElementsCount,
	Table,
	TableBody,
	TableBodyCell,
	TableBodyRow,
	TableFooter,
	TableHead,
	TableHeadCell,
	TableHeadRow,
	UserLink,
	TakeLessonButton,
} from './style/MyLessonsHuntersTable.style';
import CheckboxInput from 'components/ui/CheckboxInput';

const MyLessonsHuntersTableColumns: tableColumnAttr[] = [
    {
        name: 'id',
        title: 'id pack',
        align: 'center',
    },
    {
        name: 'customer',
        title: 'Cliente',
    },
	{
		name: 'nickname',
		title: 'Facebook',
	},
	{
		name: 'phoneNumber',
		title: 'Cellulare',
		align: 'center',
	},
    {
        name: 'giftdate',
        title: 'Data Richiesta',
        align: 'center',
    }
];

type sortParamsType = {
	column: string | null;
	direction: 'asc' | 'desc' | null;
};

const MyLessonsHuntersTable: React.FC<MyLessonsHuntersTableProps> = ({ data, totalPages, totalElements, pageSize, searchLessons, assignLesson }) => {
	const [tableData, setTableData] = useState<MyLessonsHuntersTableItem[]>([]);
	const [sortParams, setSortParams] = useState<sortParamsType>({column: null, direction: null});
	const [currentPage, setCurrentPage] = useState(1);
    const [selectedCheckbox, setSelectedCheckbox] = useState<number[]>([]);

	function isStringDate(inputString: string): boolean {
		if (!inputString) return false;
		
		let hasTwoHyphens: boolean   = false; 
		let hasTwoColons: boolean    = false;
		let hasLetterT: boolean      = false;
	
		for(let i = 0; i < inputString.length; i++) {
			if (inputString[i] === '-') {
				hasTwoHyphens = true;
			}
			if (inputString[i] === ':') {
				hasTwoColons = true;
			}
			if (inputString[i] === 'T') {
				hasLetterT = true;
			}
		}
	
		// If all three conditions are met, return true
		if (hasTwoHyphens && hasTwoColons && hasLetterT) {
			return true;
		} 
		return false;
	}

	const isValidDate = (value: string): boolean => {
		if (!isStringDate(value)) return false;
		const dateObj = new Date(value);
		return !Number.isNaN(dateObj.getTime());
	}

	const convertDate = (value: string): string => {
		const utcDate = new Date(value);
		const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000);
  		return localDate.toLocaleString('IT-it');
	}

	const formatPhoneNumber = (value: string): string => {
		if (!value) return "";
		if (value.length === 0) return value;
		if (value.charAt(0) !== '+') return value;
		if (value.length <= 3) return value;
		var intPrefix = value.substring(0, 3);
		var number = value.substr(3);
		return intPrefix + " " + number;
	}

	const pageChangeHandler = (page: number) => {
		setCurrentPage(page);
	};

	const sortTable = (items: MyLessonsHuntersTableItem[], sortBy: string | null, direction: 'asc' | 'desc' | null) => {
		if (sortBy === null) return items;
		const sortedItems = [...items].sort((a, b) => {
			if (a[sortBy] < b[sortBy]) {
			  return -1;
			}
			if (a[sortBy] > b[sortBy]) {
			  return 1;
			}
			return 0;
		});
		
		if (direction === 'desc') {
			return sortedItems.reverse();
		}
		
		return sortedItems;
	};

	const handleSort = (key: string) => {
		if (key === sortParams.column) {
			setSortParams({column: key, direction: sortParams.direction === 'asc' ? 'desc' : 'asc'});
		} else {
			setSortParams({column: key, direction: 'desc'});
		}
	};

    const handleCheckChange = (rowID: number, value: boolean) => {
        let newCheckbox = selectedCheckbox;
        const index = newCheckbox.indexOf(rowID);
        if ((value === true) && (index === -1)) newCheckbox = [...newCheckbox, rowID];
        if ((value === false) && (index !== -1)) newCheckbox = newCheckbox.filter(e => e !== rowID);
        setSelectedCheckbox(newCheckbox);
    }

	useEffect(() => {
		console.log('tableData', tableData);
	}, [tableData]);

	useEffect(() => {
		searchLessons(currentPage);
	}, [currentPage]);

	useEffect(() => {
		const sortedTable = sortTable(tableData, sortParams.column, sortParams.direction);
		setTableData(sortedTable);
	}, [sortParams]);

    // useEffect(() => {
    //     getCheckedIds(selectedCheckbox);
    // }, [selectedCheckbox])

	useEffect(() => {
		const sortedTable = sortTable(data, sortParams.column, sortParams.direction);
		setTableData(sortedTable);
	}, [data]);

    let columns: string[] = [];
	if (tableData.length > 0) columns = Object.keys(tableData[0]);

	return (
		<>
			<Table>
				<TableHead>
					<TableHeadRow>
						{columns.map((c) => (
							<TableHeadCell
								key={c}
								onClick={() => handleSort(c)}
							>
								{MyLessonsHuntersTableColumns.find(x => x?.name === c)?.title ? MyLessonsHuntersTableColumns.find(x => x?.name === c)?.title?.toUpperCase() : c.toUpperCase()}
								{(sortParams.column === c && (sortParams.direction === 'desc' ? <DownArrowIcon transform="rotate(180 0 0)"/> : <DownArrowIcon />))}
							</TableHeadCell>
						))}
                        { false && <TableHeadCell
                            key={"assegna"}>
                            ASSEGNA A
                        </TableHeadCell> }
					</TableHeadRow>
				</TableHead>
				<TableBody>
					{tableData.map((row, index) => (
						<TableBodyRow key={index}>
							{columns.map((c) => {
								const value = row[c as keyof MyLessonsHuntersTableItem];
								return (
									<TableBodyCell
                                        align={MyLessonsHuntersTableColumns.find(x => x?.name === c)?.align}
										key={c}
										id={c}
									>
										{/*(Object.prototype.toString.call(value) === '[object Date]') ? value.toLocaleString("it-IT") : value*/}
										{(isValidDate(value)) ? convertDate(value) 
										: 
										(MyLessonsHuntersTableColumns.find(x => x?.name === c)?.name === 'customer') ? <UserLink href={`/reservedArea/users/${value.split("|")[0]}`} target='_blank'>{value}</UserLink>
										:
										(MyLessonsHuntersTableColumns.find(x => x?.name === c)?.name === 'phoneNumber') ? formatPhoneNumber(value)
										:
										value
										}
									</TableBodyCell>
								);
							})}
                            <TableBodyCell 
                                align={"center"}
                                key={"assegna"+row['id']}
                                id={"assegna"+row['id']}
                            >
                                {false && <CheckboxInput inputName={"check"+row['id']} isChecked={(selectedCheckbox.indexOf(row['id']) !== -1)} onChange={(event) => handleCheckChange(row['id'], event.valueOf())}></CheckboxInput>}
								{assignLesson && <TakeLessonButton onClick={() => assignLesson(row['id'])}>Prendi in carico</TakeLessonButton>}
                            </TableBodyCell>
						</TableBodyRow>
					))}
				</TableBody>
			</Table>
			{totalElements > 0 && (
				<TableFooter>
					<FooterElementsCount>{footerElementsCount({ currentPage, totalPages, totalElements, pageSize })}</FooterElementsCount>
					<FooterNavigationButton
						onClick={() => pageChangeHandler(currentPage - 1)}
						disabled={currentPage === 1}
					>
						<ChevronLeftIcon />
					</FooterNavigationButton>
					<FooterNavigationButton
						onClick={() => pageChangeHandler(currentPage + 1)}
						disabled={currentPage === totalPages}
					>
						<ChevronRightIcon />
					</FooterNavigationButton>
				</TableFooter>
			)}
		</>
	);
};

export default MyLessonsHuntersTable;