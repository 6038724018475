import { FC, useEffect, useMemo, useState } from 'react';

//hooks
import useAppSelector from 'hooks/useAppSelector';
import useHasRole from 'hooks/useHasRole';
import useHasPermission from 'hooks/useHasPermission';

//types
import { ProductsProps } from 'pages/UsersPage/UsersTable/Products/Products.component.d';
import { TitleBreadCrumbSettings } from 'components/DashboardTitleSection/DashboardTitleSection.component.d';
import { Product, ProductType } from 'types/Product';
import { TabSelectorItem, TabSelectorSettings } from 'components/TabSelector/TabSelector.component.d';

//components
import DashboardTitleSection from 'components/DashboardTitleSection';
import ProductsTable from './ProductsTable/ProductsTable.component';
import TabSelector from 'components/TabSelector';

//utils
import { putProduct } from 'services/product.services';

//assets
import { ReactComponent as SearchIconSVG } from './assets/searchIcon.svg';
import { ReactComponent as ClearButtonIconSVG } from './assets/clearButtonIcon.svg';
import { ReactComponent as AddBoxIcon } from './assets/addBoxIcon.svg';
import { ReactComponent as VisibleON } from './assets/visibleON.svg';
import { ReactComponent as VisibleOFF } from './assets/visibleOFF.svg';


//style
import { 
	Page, 
	SearchProductsBox, 
	SearchInput, 
	SearchClearButton, 
	SearchButton, 
	AddProduct,
	NotVisibleFilterButton,
	NotVisibleFilterInput,
} from './style/Products.component.style';
import { BaseContentCard } from 'style/wrappers/wrappers';
import useAppDispatch from 'hooks/useAppDispatch';
import { updateProduct } from 'redux/actions/actions-global';
import AddProductModal from './AddProductModal';
import { enableAppMain } from 'redux/slices/slice-app-main';
import { constants } from 'http2';

const ProductsPage: FC = () => {
	const [searchInput, setSearchInput] = useState<string>('');
	const [searchInputValue, setSearchInputValue] = useState<string>('');
	const [localProductsList, setLocalProductsList] = useState<Product[]>();
	const [tabSelectorValue, setTabSelectorValue] = useState<string>('');
	const [productType, setProductType] = useState<ProductType>('' as ProductType);
	const [productCategory, setProductCategory] = useState<string>('');
	const [isAddProductModalVisible, setIsAddProductModalVisible] = useState(false);
	const [showHiddenFilter, setShowHiddenFilter] = useState<boolean>(false);

	const isAdmin = useHasRole('ADMIN');
	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');
	const isAuthor = useHasRole('AUTORE');
	const isAnimator = useHasRole('ANIMATORE');
	var isDiretta = useHasRole('DIRETTA');
	const isRegistaLDL = useHasRole('REGISTA-LDL');
	const isRegistaMB = useHasRole('REGISTA-MB')
	const isRegistaTCR = useHasRole('REGISTA-TCR');
	const isEditorLDL = useHasRole('EDITOR-LDL');
	const isEditorMB = useHasRole('EDITOR-MB');
	const isEditorTCR = useHasRole('EDITOR-TCR');
	isDiretta = isDiretta || isRegistaLDL || isRegistaMB || isRegistaTCR || isEditorLDL || isEditorMB || isEditorTCR;
	
	const canViewCourseServiceLessonTab = isAdmin || isSuperAdmin || isMiniAdmin || isAuthor;
	const canViewThematicCallBroadcastTab = isAdmin || isSuperAdmin || isMiniAdmin || isAuthor || isDiretta;
	const canViewInitiativeBroadcastTab = isAdmin || isSuperAdmin || isMiniAdmin || isAuthor || isDiretta;


	// const [isLoading, setIsLoading] = useState(true);

	const productsList = useAppSelector((state) => state.global.allProducts) as Product[];
	const canCreateLDL = useHasPermission('CREATE_PRODUCT_LDL');
	const canCreateMB = useHasPermission('CREATE_PRODUCT_MB');
	const canCreateTCR = useHasPermission('CREATE_PRODUCT_TCR');
	const canCreateProduct = canCreateLDL || canCreateMB || canCreateTCR;

	const dispatch = useAppDispatch();


	const addProductCLickHandler = () => {
		setIsAddProductModalVisible(true);
	};

	const closeAddProductModal = () => {
		setIsAddProductModalVisible(false);
		dispatch(enableAppMain());
	};

	const clearSearchHandler = () => {
		setSearchInput('');
		setSearchInputValue('');
	};

	const searchChangeHandler = (searchValue: any) => {
		setSearchInputValue(searchValue.target.value);
	};

	const searchKeyHandler = (event: any) => {
		if (event.keyCode === 27) {
			clearSearchHandler();
		}
		if (event.keyCode === 13) {
			setSearchInput(event.target.value);
		}
	};

	const searchButtonHandler = () => {
		setSearchInput(searchInputValue);
	};

	const handleShowHiddenFilterClick = () => {
		setShowHiddenFilter((prevState) => !prevState);
	};

	const putUpdateProduct = async (updatedProduct: Partial<Product>) => {
		try {
			await putProduct(updatedProduct);
			dispatch(updateProduct(productsList, updatedProduct));
		} catch (error) {}
	};

	useEffect(() => {
		let filteredProducts = productsList?.filter((p) => 
			(p.name.toLowerCase().includes(searchInput.toLowerCase()) || 
			 (p.authorsNames && p.authorsNames.find((author) => author.toLowerCase().includes(searchInput.toLowerCase())))) && 
			p.type === productType && 
			((showHiddenFilter && (p.visible === false)) || (p.visible === true))
		);
	
		if (productType === "THEMATIC_BROADCAST") {
			if (productCategory === "ESPERIENZA") {
				filteredProducts = filteredProducts?.filter((p) => p.productThematicCall?.type === "ESPERIENZA");
			} else {
				filteredProducts = filteredProducts?.filter((p) => p.productThematicCall?.type !== "ESPERIENZA");
			}
			
			filteredProducts = filteredProducts?.sort((a, b) => {
				const dateA = new Date(a.productThematicCall?.liveDate);
				const dateB = new Date(b.productThematicCall?.liveDate);
				return dateB.getTime() - dateA.getTime(); // Sort in descending order
			});
		} else {
			filteredProducts = filteredProducts?.sort((a, b) => {
				return b.id - a.id;
			});
		}
	
		setLocalProductsList(filteredProducts);
	}, [productType, productsList, searchInput, showHiddenFilter]);	

	useEffect(() => {
		// setLocalProductsList(productsList); ///// !!!!!!! RIPRISTINARE?
	}, [productsList]);

	//setProductType on tabSelector Change
	useEffect(() => {
		const productTypeSelected: string =
			tabSelectorValue === 'courses'
				? 'COURSE'
				: tabSelectorValue === 'services'
				? 'SERVICE'
				: tabSelectorValue === 'lessonPacks'
				? 'LESSON_PACK'
				: tabSelectorValue === 'thematicBroadcasts'
				? 'THEMATIC_BROADCAST'
				: tabSelectorValue === 'initiatives'
				? 'THEMATIC_BROADCAST[ESPERIENZA]'
				: '';
		if (productTypeSelected !== '') {
			setProductType(removeBrackets(productTypeSelected) as ProductType);
			setProductCategory(extractBrackets(productTypeSelected));
		}
		setSearchInput('');
		setSearchInputValue('');
		if (tabSelectorValue !== '') {
			localStorage.setItem('tabSelectorValue', tabSelectorValue);
		}
	}, [tabSelectorValue]);


	const removeBrackets = (str: string) => {
		return str.replace(/\[\w+\]/g, '');
	};

	const extractBrackets = (str: string) => {
		return str.match(/\[(.*?)\]/)?.[1] || '';
	};


	//filter productList on productType change
	useEffect(() => {
		let filteredProducts = productsList?.filter((p) => 
			p.name.toLowerCase().includes(searchInput.toLowerCase()) && 
			(p.type === productType) && 
			((showHiddenFilter && (p.visible === false)) || (p.visible === true))
		);

		if (productType === "THEMATIC_BROADCAST") {
			if (productCategory === "ESPERIENZA") {
				filteredProducts = filteredProducts?.filter((p) => p.productThematicCall?.type === "ESPERIENZA");
			} else {
				filteredProducts = filteredProducts?.filter((p) => p.productThematicCall?.type !== "ESPERIENZA");
			}

			filteredProducts = filteredProducts?.sort((a, b) => {
				const dateA = new Date(a.productThematicCall?.liveDate);
				const dateB = new Date(b.productThematicCall?.liveDate);
				return dateB.getTime() - dateA.getTime(); // Sort in descending order
			});
		} else {
			filteredProducts = filteredProducts?.sort((a, b) => {
				return b.id - a.id;
			});
		}
	
		setLocalProductsList(filteredProducts);
	}, [productType, productCategory, productsList]);

	//leggere localStorage per ultimo productType selezionato
	useEffect(() => {
		const tabStoredSelectorValue = localStorage.getItem('tabSelectorValue');
		const tabReadSelectorValue = tabStoredSelectorValue ? tabStoredSelectorValue : '';
		setTabSelectorValue(tabReadSelectorValue);
	}, []);

	const table = useMemo(() => {
		if (localProductsList !== undefined)
			return (
				<ProductsTable
					productType={productType}
					productCategory={productCategory}
					data={localProductsList}
					putProduct={putUpdateProduct}
				/>
			);
	}, [localProductsList]);

	const isSearchButtonVisible: boolean = searchInput !== '';
	const breadCrumbSettings: TitleBreadCrumbSettings = { subSectionText: 'Gestione Prodotti' };

	const tabItems: TabSelectorItem[] = [
		{
			title: 'Corsi',
			value: 'courses',
			display: canViewCourseServiceLessonTab,
		},
		{
			title: 'Servizi',
			value: 'services',
			display: canViewCourseServiceLessonTab,
		},
		{
			title: 'Lezioni',
			value: 'lessonPacks',
			display: canViewCourseServiceLessonTab,
		},
		{
			title: 'Aule',
			value: 'thematicBroadcasts',
			display: canViewThematicCallBroadcastTab,
		},
		{
			title: 'Esperienze',
			value: 'initiatives',
			display: canViewInitiativeBroadcastTab,
		}
	];

	const tabSettings: TabSelectorSettings = { tabItems: tabItems, handleSelection: setTabSelectorValue, display: true, defaultSelection: tabSelectorValue };

	if (!isAdmin && !isSuperAdmin && !isMiniAdmin && !isAuthor && !isDiretta) return <></>;

	return (
		<>
			<Page>
				<DashboardTitleSection breadCrumbSettings={breadCrumbSettings}>
					<NotVisibleFilterButton onClick={handleShowHiddenFilterClick}>
						<NotVisibleFilterInput
							id='showhiddenfilter'
							type={'checkbox'}
							checked={showHiddenFilter}
						/>
						<VisibleOFF /> Mostra nascosti
					</NotVisibleFilterButton>
					{canCreateProduct && (
						<AddProduct onClick={addProductCLickHandler}>
							<AddBoxIcon /> Nuovo Prodotto
						</AddProduct>
					)}
					<SearchProductsBox>
						<SearchInput
							onKeyDown={searchKeyHandler}
							onChange={searchChangeHandler}
							value={searchInputValue}
						/>

						{isSearchButtonVisible && (
							<SearchClearButton onClick={clearSearchHandler}>
								<ClearButtonIconSVG />
							</SearchClearButton>
						)}

						<SearchButton onClick={searchButtonHandler}>
							<SearchIconSVG />
						</SearchButton>
					</SearchProductsBox>
				</DashboardTitleSection>
				<TabSelector tabSelectorSettings={tabSettings}></TabSelector>
				<BaseContentCard>
					{localProductsList === undefined && <div>Loader...</div>}

					{table}
				</BaseContentCard>
			</Page>
			{isAddProductModalVisible && <AddProductModal defaultType={productType} defaultCategory={productCategory} onCloseButtonModal={closeAddProductModal} />}
		</>
	);
};

export default ProductsPage;
