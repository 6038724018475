import colors from 'style/palette';
import styled, { css } from 'styled-components';

export const Lesson = styled.div<{isActive?: boolean, isCompleted?: boolean}>`

display: flex;
justify-content: space-between;
align-items: center;

width: 100%;
height: 40px;

padding: 0 25px 0 0;

font-weight: 500;
font-size: 13px;
color: #FFFFFF;

background: #4C5862;

border: none;

cursor: pointer;

svg {
    flex-shrink: 0;
}

    ${({isActive}) => isActive && 
        css`
            color: ${colors.primaryColor};
        `
    }

    ${({isCompleted}) => isCompleted && 
        css`
            color: ${colors.green};
        `
    }
`;

export const TypeLessonBox = styled.div<{ isCompleted: boolean | undefined}>`

    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
    width: 33px;
    min-width: 33px;

    padding: 0;

    background: rgba(255, 255, 255, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.2);

    > svg {
        max-width: 13px;
                
    }

    ${({isCompleted}) => isCompleted &&
    
        css`
            color: ${colors.green};

            svg path {
                fill: ${colors.green};
            }
        `
    }
`;

export const StateLessonBox = styled.div<{ isCompleted?: boolean | undefined}>`

    display: flex;
    justify-content: flex-start;
    align-items: center;

    width: 100%;

    margin: 0 0 0 25px;

    gap: 10px;

    background: transparent;

    > svg {
        max-width: 20px;
                
    }

    ${({isCompleted}) => isCompleted &&
    
        css`
            color: ${colors.green};

            > svg path {
                fill: ${colors.green};
            }
        `
    }

`;