import { FC } from 'react';

//hooks
import useHasRole from 'hooks/useHasRole';
import useHasPermission from 'hooks/useHasPermission';

//utils
import { footerElementsCount } from './ActiveInactiveTable.helper';

//types
import { ActiveInactiveTableProps } from './ActiveInactiveTable.component.d';
import { ActiveInactiveTableColumn } from './ActiveInactiveTable.component.d';

//components
import FacebookLinkedAccount from './FacebookLinkedAccount';
import Missions from './Missions';
import Badges from './Badges';
import Products from './Products';
import Stats from './Stats';
import Notes from './Notes';
import Buttons from './Buttons';
import GenericInfo from './GenericInfo';

//assets
import { ReactComponent as ChevronLeftIcon } from './assets/chevronLeft.svg';
import { ReactComponent as ChevronRightIcon } from './assets/chevronRight.svg';

//style
import {
	FooterNavigationButton,
	FooterElementsCount,
	Table,
	TableBody,
	TableBodyCell,
	TableBodyRow,
	TableFooter,
	TableHead,
	TableHeadCell,
	TableHeadRow,
} from './style/ActiveInactiveTable.style';

const ActiveInactiveTable: FC<ActiveInactiveTableProps> = ({
	data,
	columnsMapping,
	totalPages,
	totalElements,
	pageSize,
	nextPageHandler,
	prevPageHandler,
	pageNumber,
	productsClickHandler,
	notesClickHandler,
}) => {

	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isAdmin = useHasRole('ADMIN');
	const isAuthor = useHasRole('AUTORE');

	const canReadSubcriptionsLdL = useHasPermission('READ_PRODUCT_SUBSCRIPTION_LDL'); 
	const canReadSubcriptions = useHasPermission('READ_PRODUCT_SUBSCRIPTION') || canReadSubcriptionsLdL;
	const carReadHisProducts = useHasPermission('READ_MY_PRODUCT');
	const canUpdateUserRole = isSuperAdmin || isAdmin;

	function isStringDate(inputString: string): boolean {
		let hasTwoHyphens: boolean   = false; 
		let hasTwoColons: boolean    = false;
		let hasLetterT: boolean      = false;
	
		for(let i = 0; i < inputString.length; i++) {
			if (inputString[i] === '-') {
				hasTwoHyphens = true;
			}
			if (inputString[i] === ':') {
				hasTwoColons = true;
			}
			if (inputString[i] === 'T') {
				hasLetterT = true;
			}
		}
	
		// If all three conditions are met, return true
		if (hasTwoHyphens && hasTwoColons && hasLetterT) {
			return true;
		} 
		return false;
	}

	const isValidDate = (value: string): boolean => {
		if (!isStringDate(value)) return false;
		const dateObj = new Date(value);
		return !Number.isNaN(dateObj.getTime());
	}

	const convertDate = (value: string): string => {
		const dateObj = new Date(value);
  		return dateObj.toLocaleString('IT-it');
	}

	return (
		<>
			<Table>
				<TableHead>
					<TableHeadRow>
						{columnsMapping
							.filter((c) => c.enabled)
							.map((c) => (
								<TableHeadCell
									key={c.label}
									width={c.width}
									// onClick={() => handleSort(key)}
								>
									{c.label.toUpperCase()}
									{/* {sortColumn === c && <span>{sortDirection === 'asc' ? '⬆️' : '⬇️'}</span>} */}
								</TableHeadCell>
							))}
					</TableHeadRow>
				</TableHead>
				<TableBody>
					{data.map((row, index) => (
						<TableBodyRow key={index}>
							<TableBodyCell
								key={index + '_buttons'}
								id={index + '_buttons'}
							>
								{ (isSuperAdmin || isAdmin ) && <Buttons
									key={index + '_buttons-box'}
									userId={row.id}
								/> }
							</TableBodyCell>
							{columnsMapping.find((c) => c.label === 'ID')?.enabled && (
								<TableBodyCell
									key={index + '_id'}
									id={index + '_id'}
								>
									{row.id}
								</TableBodyCell>
							)}
							{columnsMapping.find((c) => c.label === 'ANAGRAFICA')?.enabled && (
								<TableBodyCell
									key={index + '_email'}
									id={index + '_email'}
								>
									<GenericInfo
										name={row.name}
										surname={row.surname}
										email={row.email}
										cognitoId={row.federateds.filter((f: any) => f.federatedName === 'Cognito')[0]?.federateUserdId}
										referral={row.referral}
									/>
								</TableBodyCell>
							)}
							{columnsMapping.find((c) => c.label === 'SALDO PUNTI')?.enabled && (
								<TableBodyCell
									key={index + '_punti'}
									id={index + '_punti'}
								>
									{row.pools.reduce((acc: any, p: any) => (acc + p.balance ? p.balance : 0), 0)}
								</TableBodyCell>
							)}
							{columnsMapping.find((c) => c.label === 'FB COLLEGATO')?.enabled && (
								<TableBodyCell
									key={index + '_fb'}
									id={index + '_fb'}
								>
									<FacebookLinkedAccount
										key={index + '_fbaccount'}
										userFederateds={row.federateds}
										cognitoIdEnabled={true}
									/>
								</TableBodyCell>
							)}
							{columnsMapping.find((c) => c.label === 'MISSIONI')?.enabled && (
								<TableBodyCell
									key={index + '_missions'}
									id={index + '_missions'}
								>
									<Missions
										key={index + '_user-missions'}
										userId={row.id}
										missions={row.missions}
									/>
								</TableBodyCell>
							)}
							{columnsMapping.find((c) => c.label === 'BADGE')?.enabled && (
								<TableBodyCell
									key={index + '_badges'}
									id={index + '_badges'}
								>
									<Badges
										key={index + '_user-badges'}
										userId={row.id}
										badges={row.badges}
									/>
								</TableBodyCell>
							)}
							{columnsMapping.find((c) => c.label === 'STATS')?.enabled && (
								<TableBodyCell
									key={index + '_stats'}
									id={index + '_stats'}
								>
									<Stats
										signupDate={row.stats?.registrationDate}
										lastLoginDate={row.stats?.lastLoginDate}
										firstPurchaseDate={row.stats?.firstPurchaseDate}
										lastPurchaseDate={row.stats?.lastPurchaseDate}
									/>
								</TableBodyCell>
							)}
							{/* <TableBodyCell
								key={index + '_highway'}
								id={index + '_highway'}
							>
								Autostrada
							</TableBodyCell>
							<TableBodyCell
								key={index + '_APlessons'}
								id={index + '_APlessons'}
							>
								Lez. AP
							</TableBodyCell>*/}
							{columnsMapping.find((c) => c.label === 'NOTE')?.enabled && (
								<TableBodyCell
									key={index + '_notes'}
									id={index + '_notes'}
								>
									<Notes
										userId={row.id}
										notes={row.stats?.notes}
										notesClickHandler={notesClickHandler}
									/>
								</TableBodyCell>
							)}
							{columnsMapping.find((c) => c.label === 'ATTIVAZ.')?.enabled && (
								<TableBodyCell
									key={index + '_attivazione'}
									id={index + '_attivazione'}
								>
									{(isValidDate(row.creationDate)) ? convertDate(row.creationDate).split(',')[0] : "N.D."}
								</TableBodyCell>
							)}
							{columnsMapping.find((c) => c.label === 'SCADENZA')?.enabled && (
								<TableBodyCell
									key={index + '_scadenza'}
									id={index + '_scadenza'}
								>
									{(isValidDate(row.expirationDate)) ? convertDate(row.expirationDate).split(',')[0] : "N.D."}
								</TableBodyCell>
							)}
						</TableBodyRow>
					))}
				</TableBody>
			</Table>
			{totalElements > 0 && (
				<TableFooter>
					<FooterElementsCount>{footerElementsCount({ currentPage: pageNumber, totalPages, totalElements, pageSize })}</FooterElementsCount>
					<FooterNavigationButton
						onClick={prevPageHandler}
						disabled={pageNumber === 1}
					>
						<ChevronLeftIcon />
					</FooterNavigationButton>
					<FooterNavigationButton
						onClick={nextPageHandler}
						disabled={pageNumber === totalPages}
					>
						<ChevronRightIcon />
					</FooterNavigationButton>
				</TableFooter>
			)}
		</>
	);
};

export default ActiveInactiveTable;
