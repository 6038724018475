import { FC, useEffect, useState } from 'react';

// routing
import { Link } from 'react-router-dom';

// hooks
import useAppSelector from 'hooks/useAppSelector';

// utils
import { getEnvironmentMonolithLinkUrl } from 'configs/config.helper';
import { getNewNotifications } from './services/Header.services';

// components
import Navbar from './Navbar';
import BrandSelector from './BrandSelector';
import UserProfileSubmenu from './UserProfileSubmenu';
import EmptyUserImageProfile from 'components/EmptyUserImageProfile';

// types
import { HeaderProps } from './Header.d';

// assets
import { ReactComponent as BrandSVG } from './assets/brand-icon.svg';
import { ReactComponent as SupportSVG } from './assets/support-icon.svg';
import { ReactComponent as LoginSVG } from './assets/login-icon.svg';
import { ReactComponent as NotifySVG } from './assets/notify-icon.svg';

// style
import {
	Container,
	Content,
	BrandBox,
	SignupText,
	LoginArea,
	SupportBox,
	NotifyBotton,
	NewNotificationAlert,
	LoginBox,
	UserArea,
	UserData,
	UserProfileImage,
	UserInfo,
	UserInfoTitle,
} from './style/Header.style';
import config from 'configs/config';

/**
 * Header
 *
 * @author Alessio Grassi
 *
 * @param isSubmenuOpen -  the "open" state of the Submenu
 * @param onSubmenuButtonClick -  toggle handler of the Submenu
 * @param onSubmenuLinkClick -  handler for Submenu's closure
 *
 * @returns JSX
 */

const Header: FC<HeaderProps> = ({ isSubmenuOpen, onSubmenuButtonClick, onSubmenuLinkClick }) => {
	const [hasUserNewNotifications, setHasUserNewNotifications] = useState<boolean>(false);
	const { tokenID, name: userName, facebookProfilePicture } = useAppSelector((state) => state.user);

	const notifyButtonHandler = () => {
		setHasUserNewNotifications(false);
		window.open(getEnvironmentMonolithLinkUrl('reservedArea/notification'), '_blank', 'noopener noreferrer');
	};

	const checkNotifications = async () => {
		try {
			const userHasNewNotifications = await getNewNotifications();

			setHasUserNewNotifications(userHasNewNotifications);
		} catch (error: any) {
			console.warn('Unable checking new Notifications.', error);
		}
	};

	// useEffect(() => {
	//     checkNotifications();
	// }, []);

	const finalUserName = `${userName}`;
	const isUserLoggedIn = !!tokenID;
	const domain = window.location.hostname;
    const topLevelDomain = domain.split('.').slice(-2).join('.');
    const brandSvgLinkTo = `https://www.${topLevelDomain}`;
	const isTest = config.environment === 'test'

	return (
		<Container isTest={isTest}>
			<Content>
				{/* <BrandBox
					isNavbarVisible={isUserLoggedIn}
					onClick={onSubmenuLinkClick}
				>
					<a href={brandSvgLinkTo}>
						<BrandSVG />
						{isTest && <>TEST</>}
					</a>
				</BrandBox> */}

				<BrandBox 
                    isNavbarVisible={isUserLoggedIn}
                    onClick={onSubmenuLinkClick}    
                >
                    <BrandSelector />
                </BrandBox>

				{isUserLoggedIn && (
					<>
						<Navbar onClick={onSubmenuLinkClick} />

						<UserArea>
							<UserData onClick={onSubmenuButtonClick}>
								{!facebookProfilePicture && (
									<EmptyUserImageProfile
										isSmall
										isEditButtonDisabled
									/>
								)}

								{facebookProfilePicture && (
									<UserProfileImage
										src={facebookProfilePicture}
										alt='User profile picture'
									/>
								)}

								<UserInfo>
									<UserInfoTitle>{finalUserName}</UserInfoTitle>

									<span>Il mio profilo</span>
								</UserInfo>
							</UserData>
						</UserArea>

						<UserProfileSubmenu
							isSubmenuOpen={isSubmenuOpen}
							onLinkClick={onSubmenuLinkClick}
						/>
					</>
				)}
			</Content>
		</Container>
	);
};

export default Header;
