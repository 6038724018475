import { FC, useEffect, useRef, useState } from 'react';

// type
import { VideoUpdateElementProps } from './VideoUpdateElement.component.d';

// style
import { 
    CardContent,
    ButtonRow,
    RowContent,
    CloseAndCancel,
    SaveAndCloseButton,
    DeleteElementButton,
    UploadPosterButton,
    UrlInput,
    UrlLabel,    
    ColumnContent,
    ActualPicture
} from './style/VideoUpdateElement.style';
import { createNewBlock, createNewElement, updateImageIntoElements, updateVideoIntoElements, updateVideoOnSalesData, deleteVideoOnSalesData, updateVideoPosterOnSalesData, uploadImageIntoElements } from 'pages/PublicProductPages/services/PublicProductPage.services';
import DeleteElementModal from 'components/DeleteElementModal/DeleteElementModal.component';


const VideoUpdateElement: FC<VideoUpdateElementProps> = ({
    productId,
    webpageId,
    blockId,
    elementId,
    isRequired,
    newBlockPosition,
    newBlockLayout,
    isNewElement,
    numberOfElements,
    typeOfLayout,
    update,
    updateData,
    onClose,
    videoUrl,
    pictureUrl
}) => {
    
    const [urlInputValue, setUrlInputValue] = useState<string>('');
    const [isRemoveElementModalVisible, setIsRemoveElementModalVisible] = useState<boolean>(false);
    const [imageToUpload, setImageToUpload] = useState<File>();

    const onDeleteButtonHandler = () => setIsRemoveElementModalVisible(true);

    const videoRef = useRef<HTMLInputElement | null>(null);
    
        const onCancelClickHandler = () => {
            onClose();
        };
    
        const handleFileUpload = (newFile: FileList | null) => {

            if (!newFile) return;
            setImageToUpload(newFile[0]);
        };
    
        const onSaveAndCloseHandler = async () => {
            
            console.log("file:", imageToUpload);
            console.log("P id:", productId);
            
            if (isNewElement && (newBlockLayout !== undefined)) {

                await createNewVideoElement();
                if (updateData) await updateData();
                onCancelClickHandler();        
                return;
            }
            if ((videoRef.current !== null) && (videoRef.current.value !== '')) await updateVideoUrl();
            if ((videoRef.current !== null) && (videoRef.current.value === '')) await deleteVideoUrl();
            if ((imageToUpload) && isRequired) await updateVideoPoster();
            if (updateData) await updateData();
            onClose();
        };

    const updateVideoUrl = async () => {

        // to do snackbar and spinner
        try {
            
            if (videoRef.current === null) return;            
            if (productId) await updateVideoOnSalesData(productId, videoRef.current.value);
            if (webpageId && elementId) await updateVideoIntoElements(webpageId, elementId, videoRef.current.value);
        } catch (error: any) {
            console.warn("ERROR:", error);
        }
    };

    const deleteVideoUrl = async () => {

        // to do snackbar and spinner
        try {
            
            if (videoRef.current === null) return;            
            if (productId) await deleteVideoOnSalesData(productId);
        } catch (error: any) {
            console.warn("ERROR:", error);
        }
    };

    const updateVideoPoster = async () => {

        // to do snackbar and spinner
        try {
            
            if (imageToUpload === undefined) return;
            if (productId) await updateVideoPosterOnSalesData(productId, imageToUpload);
        } catch (error: any) {
            console.warn("ERROR:", error);
        }
    };

    const createNewVideoElement = async () => {

        // to do snackbar and spinner
        try {

            if (webpageId === undefined) return;
            if (newBlockPosition === undefined) return;
            if (newBlockLayout === undefined) return;
            const newBlock = await createNewBlock(webpageId, newBlockPosition, newBlockLayout);
            
            if (newBlock.id === undefined) return;
            const newElement = await createNewElement(newBlock.id, 'VIDEO', 0);
            
            if (newElement.id === undefined) return;
            if (videoRef.current === null) return;
            await updateVideoIntoElements(webpageId, newElement.id, videoRef.current.value);            
        } catch (error: any) {
            console.warn("ERROR:", error);
        }
    };

    const updateUrlInputValue = (e: React.ChangeEvent<HTMLInputElement>) => setUrlInputValue(e.target.value);

    useEffect(() => {
        if (videoUrl) setUrlInputValue(videoUrl);
    }, [videoUrl]);

    return (
        <>
            <CardContent>
                <RowContent>
                    <UrlLabel>
                        URL del video (modificabile):

                        <UrlInput 
                            ref={videoRef}
                            type={"text"}
                            value={urlInputValue}
                            onChange={updateUrlInputValue}
                        />
                    </UrlLabel>

                    {isRequired && 
                    !isNewElement && 
                    (newBlockLayout === undefined) && (
                        // <UrlLabel>
                        //     URL dell'immagine nuova (poster)

                        //     <UrlInput 
                        //         ref={posterRef}
                        //     />
                        // </UrlLabel>
                        <ColumnContent>
                            <UrlLabel>
                                <ActualPicture>
                                    URL dell'immagine: <UrlInput type="text" value={pictureUrl} />
                                </ActualPicture>

                                Carica nuova immagine

                                <UploadPosterButton onClick={() => document.getElementById('image')?.click()} >
                                    Sfoglia...
                                </UploadPosterButton>

                                <UrlInput 
                                    type="file"
                                    id="image"
                                    accept=".png, .jpeg, .jpg"
                                    onChange={(e) => handleFileUpload(e.target.files)}
                                    style={{ display: 'none' }}
                                />
                            </UrlLabel>

                            {imageToUpload ? imageToUpload.name : "Nessuna immagine selezionata"}
                        </ColumnContent>
                        )
                    }
                </RowContent>

                <ButtonRow>
                    {!isRequired && 
                    !isNewElement && 
                    (newBlockLayout === undefined) && (
                        <DeleteElementButton
                            onClick={onDeleteButtonHandler}
                        >
                            Elimina il Video
                        </DeleteElementButton>
                        )
                    }

                    <CloseAndCancel
                            onClick={onCancelClickHandler}
                    >
                        Annulla e Chiudi
                    </CloseAndCancel>

                    <SaveAndCloseButton
                            onClick={onSaveAndCloseHandler}
                    >
                        Salva e Chiudi
                    </SaveAndCloseButton>
                </ButtonRow>
            </CardContent>

            {isRemoveElementModalVisible && (
                <DeleteElementModal 
                    onCloseButtonModal={onCancelClickHandler}
                    elementId={elementId}
                    updateData={updateData}
                    typeOfLayout={typeOfLayout}
                    numberOfElements={numberOfElements}
                />)
            }
        </>
    );
};

export default VideoUpdateElement;
