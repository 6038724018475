import styled from 'styled-components';
import colors from 'style/palette';


export const VipPathLevelBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 0 17px;

    color: ${colors.white};
    font-size: 0.8em;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;

    min-width: 250px;
`;

export const VipPathLevelName = styled.div`
    font-weight: bold;
`;

export const VipPathLevelNumber = styled.div`

`;
