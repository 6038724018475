import styled from 'styled-components';
import colors from 'style/palette/palette';

export const Page = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	padding: 0 30px;

	width: 100%;
	height: 100%;

	overflow: hidden;
`;

export const SearchChannelBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 100%;
	max-width: 1360px;
`;

export const SearchUserInput = styled.input`
	display: flex;
	align-items: center;
	flex-direction: column;

	width: 100%;
	height: 29px;
	padding: 7px 9px;
	margin: 0 13px 0 0;

	background-color: transparent;
	border: 1px solid #ffffff;
	border-radius: 100px;

	color: white;
	:focus {
		outline: none;
		border-color: white;
		box-shadow: 0 0 0px white;
		-moz-box-shadow: 0 0 0px white;
		-webkit-box-shadow: 0 0 0px white;
	}
`;

export const AssignBox = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: right;
	width: 90%;
	margin: 0 0 25px;
`;

export const AssignSelect = styled.select`
	margin: 0 10px 0 0;
	background-color: transparent;
	color: #ffffff;
`;

export const AssignButton = styled.button`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 3px 4px;

	width: 84px;
	height: 24px;

	border-radius: 416px;
	border: 0;
	background-color: #66bb6a;

	font-weight: 400;
	font-size: 13px;
	color: #ffffff;
	cursor: pointer;

	:disabled {
		background-color: #ffffff1f;
		color: #29353e;
		cursor: not-allowed;
	}
`;

export const TableDetailsHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;

	width: 100%;
	height: 36px;
`;

export const TableDetailsName = styled.button`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 10px;
	padding: 6px 16px;
	border: none;
	outline: none;
	background-color: #4c5862;
	font-size: 14px;
	color: #ffffff;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
`;

export const TableDetailsBackButton = styled.button`
	width: 128px;
	height: 30px;

	border-radius: 416px;
	border: 0;
	background-color: ${colors.blue};

	font-weight: 400;
	font-size: 13px;
	color: #ffffff;
	cursor: pointer;
`;

export const SearchModeFieldSet = styled.fieldset`
	color: white;
	display: flex;
	justify-content: space-between;
	gap: 1rem;
`;

export const SearchFiltersFieldSet = styled.fieldset`
	color: white;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	gap: 1rem;
`;

export const ModeBox = styled.div`
	display: flex;
	gap: 1rem;
	flex-wrap: wrap;
`;

export const CheckBoxesBox = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
`;

export const PageContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 100%;
`;

export const SearchUsersBox = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
	width: 100%;
	max-width: 1360px;
`;

export const SearchInput = styled.input`
	display: flex;
	align-items: center;
	flex-direction: column;

	width: 25%;
	height: 29px;
	padding: 7px 9px;
	margin: 0 13px 0 0;

	background-color: ${colors.transparent};
	border: 1px solid ${colors.white};
	border-radius: 100px;

	color: ${colors.white};
	:focus {
		outline: none;
		border-color: ${colors.white};
		box-shadow: 0 0 0px ${colors.white};
		-moz-box-shadow: 0 0 0px ${colors.white};
		-webkit-box-shadow: 0 0 0px ${colors.white};
	}
`;

export const UsersSelect = styled.select`
	min-width: 25%;
`;

export const FilterClearButton = styled.div`
	cursor: pointer;

	> svg {
		min-width: 15px;
		min-height: 15px;
	}
	> svg path {
		fill: ${colors.red};
	}
`;

export const SearchButton = styled.button`
	background: transparent;
	border: none;
	cursor: pointer;
	> svg {
		min-width: 23px;
		min-height: 23px;
	}
`;

export const ZeroPaymentsCheckBox = styled.div`
	margin-left: auto;
`;

export const ProductLabel = styled.label``;

export const ProductSelection = styled.div`
	display: flex;
	gap: 1rem;
	width: 100%;
`;

export const ProductInput = styled.input``;

export const ProductExpandButton = styled.button``;

export const OptionList = styled.ul`
	background-color: white;
	color: black;
	position: relative;
	z-index: 2;
	max-height: 250px;
	overflow-y: auto;
	width: 100%;
	list-style-type: none;

	li {
		padding: 0.25rem;
		cursor: pointer;

		&:hover {
			background-color: lightgray;
		}
	}
`;
