import { FC, useEffect, useState } from 'react';

//type
import { PoolRowProps } from './PoolRow.component.d';

//assets
import { ReactComponent as PersonRemoveIcon } from './assets/personRemoveIcon.svg';

//style
import { AuthorOption, AuthorSelect, PoolName, PoolPerc, PoolRowBox, PoolValue, RemoveAuthorPoolButton } from './style/PoolRow.component.style';

const PoolRow: FC<PoolRowProps> = ({
	localPoolIndex,
	poolId,
	name,
	productPrice,
	type,
	perc,
	value,
	isDisabled,
	hideValue,
	isAuthor,
	isLessonCoach,
	allAuthorPools,
	onChange,
	removeAuthorPoolHandler,
	changeAuthorPoolHandler,
}) => {
	const [localPoolId, setLocalPoolId] = useState<number | undefined>();
	const [percString, setPercString] = useState<string>("");

	const onPercChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPercString(event.target?.value.toString());
		perc = +event.target?.value.toString().replaceAll(',','.');
		onChange(event);
	}

	useEffect(() => {
		setLocalPoolId(poolId);
	}, [poolId]);

	useEffect(() => {
		setPercString(perc.toString().replaceAll('.',','));
	}, [perc]);

	return (
		<PoolRowBox>
			{isAuthor && !isLessonCoach && (
				<RemoveAuthorPoolButton
					value={localPoolId}
					onClick={removeAuthorPoolHandler}
				>
					<PersonRemoveIcon />
				</RemoveAuthorPoolButton>
			)}
			{!isAuthor && <PoolName htmlFor={localPoolId?.toString()}>{name}</PoolName>}
			{isAuthor && (
				<>
					<AuthorSelect
						name='authors'
						id='authors'
						onChange={changeAuthorPoolHandler}
						data-pool-index={localPoolIndex}
					>
						{allAuthorPools?.map((pool, index) => {
							return (
								<AuthorOption
									key={index}
									value={pool.id}
									selected={pool.id === localPoolId}
								>
									{pool.name}
								</AuthorOption>
							);
						})}
					</AuthorSelect>
				</>
			)}
			<PoolPerc
				id={localPoolId?.toString()}
				value={percString}
				disabled={isDisabled}
				onChange={onPercChange}
			/>
			{ !hideValue &&
				<PoolValue
					disabled
					value={value.toString().replaceAll('.',',')}
				/>
			}
		</PoolRowBox>
	);
};

export default PoolRow;
