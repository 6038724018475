import styled from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';


export const Backdrop = styled.div`

    width: 100vw;
    height: 100vh;

	position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
	z-index: 100;

    // real modal background here
	background: ${colors.transparent};

    display: none;

    @media ${devices.appMinWidth} {

        display: block;
    }
`;

export const ModalWindow = styled.div`

    width: fit-content;
    max-width: 100vw;
    
    height: fit-content;

    margin: auto;
    padding: 40px 0 0;
    
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    
    display: none;
    align-items: center;
    justify-content: center;

    overflow-y: auto;
    overflow-x: auto;

    @media ${devices.appMinWidth} {

        display: flex;
    }

    @media ${devices.tablet} {

        padding: 0;
    }
`;

export const ModalPanel = styled.div`

    width: auto;
    max-width: 100vw;

    height: auto;
    // max - modal top padding
    max-height: calc(100vh - 40px);

    margin: 0 15px;
`;
