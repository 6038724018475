import { FC } from 'react';

// types
import { IconTextBoxProps } from './IconTextBox.d';

// style
import { 
    Box,
    PointsTitle,
    PointContainer,
    PointsBox,
    PointsName
} from './style/IconTextBox.style';


/**
 * Icon text box
 * 
 * @author Alessio Grassi
 * 
 * @param SVGAsset - asset for the button text
 * @param label - text displayed in the button
 * @param flavor - flavor of the box
 * 
 * @returns JSX
 */


const IconTextBox: FC<IconTextBoxProps> = ({ SVGAsset, label, flavor, isPointBox }) => {

    return (
        <Box 
            flavor={flavor}
            isPointBox={isPointBox}
        >
            {isPointBox && (
                <PointsTitle>
                    Hai accumulato:
                </PointsTitle>
            )}

            <PointContainer>
                {SVGAsset}

                <PointsBox>
                    {label}

                    {isPointBox && <PointsName>Punti Libertà</PointsName>}
                </PointsBox>
            </PointContainer>
        </Box>
    );
}

export default IconTextBox;
