import styled from 'styled-components';
import devices from 'style/breakpoints';

export const Page = styled.div`

    width: 100%;
    height: 100%;
`;

export const MobileVersion = styled.div`

    display: block;

    @media ${devices.tablet} {

        display: none;
}
`;

export const DesktopVersion = styled.div`

    display: none;

    @media ${devices.tablet} {

        display: block;
}
`;