import { FC } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//types
import { ButtonsProps } from './RowButtons.component.d';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

//components
import RowButton from './RowButton';

//hooks
import { useNavigate } from 'react-router-dom';
import useAppDispatch from 'hooks/useAppDispatch';
import useBrand from 'hooks/useBrand';


//assets
import { ReactComponent as ModifyIcon } from './assets/modifyIcon.svg';
import { ReactComponent as DeleteIcon } from './assets/deleteIcon.svg';

//utils
import { deleteRoadmapEventFromId } from 'services/roadmapevent.services';

//style
import { ButtonsBox } from './style/RowButtons.component.style';
import useHasRole from 'hooks/useHasRole';

const Buttons: FC<ButtonsProps> = ({ eventId }) => {
	const isAdmin = useHasRole('ADMIN');
	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');

	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const brand = useBrand();

	const modifyButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
		if (event.ctrlKey || event.metaKey) {
			event.preventDefault();
			window.open(eventId.toString(), '_blank');
		} else {
			navigate(eventId.toString());
		}
	};

	const deleteButtonHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
		if (!brand) return;
		if (window.confirm(`Eliminare l'evento ${eventId} ?`)) {
			const snackbarData: SnackbarOptions = {};

			try {
				const response = await deleteRoadmapEventFromId(eventId, brand.code);
				snackbarData.type = 'success';
				snackbarData.message = "Evento eliminato con successo";
				dispatch(addSnackbar(snackbarData));
				setTimeout(() => {window.location.reload();}, 2000);
			} catch (error: any) {
				snackbarData.type = 'error';
				snackbarData.message = "Errore nell'eliminazione dell'evento";
				dispatch(addSnackbar(snackbarData));
			}
		}
	};

	return (
		<ButtonsBox>
			{/* {isSuperAdmin && ( */}
				<RowButton
					title='Modifica'
					name='modify'
					ButtonIcon={ModifyIcon}
					clickHandler={modifyButtonHandler}
				></RowButton>
			{/* )} */}
			<RowButton
				title='Elimina'
				name='delete'
				ButtonIcon={DeleteIcon}
				clickHandler={deleteButtonHandler}
			></RowButton>
		</ButtonsBox>
	);
};

export default Buttons;
