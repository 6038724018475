import { v4 as uuidv4 } from 'uuid';

// types
import { AppDispatch, RootState } from '../store';
import { SnackbarOptions } from '../slices/slice-snackbar.d';

// slice actions
import {
    setItem
} from '../slices/slice-snackbar';


export const addSnackbar = (snackbarOptions: SnackbarOptions) => {

    return async (dispatch: AppDispatch, getState: () => RootState) => {

        const { items } = getState().snackbar;

        const newItems = [

            ...items,
            {
                ...snackbarOptions,
                id: uuidv4()
            }
        ];

        dispatch( setItem(newItems) );
    };
};

export const removeSnackbarByID = (snackbarID: string) => {

    return async (dispatch: AppDispatch, getState: () => RootState) => {

        const { items } = getState().snackbar;
        const newItems = items.filter((snackbarData) => snackbarData!.id !== snackbarID);
        
        dispatch( setItem(newItems) );
    };
};

export const removeFirstSnackbar = () => {

    return async (dispatch: AppDispatch, getState: () => RootState) => {

        const { items } = getState().snackbar;        
        const newItems = [...items];

        newItems.shift();
        dispatch( setItem(newItems) );
    };
};
