import colors from 'style/palette/palette';
import styled from 'styled-components';

export const MainBox = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 100%;
	align-items: flex-start;
`;
