import { callDELETE, callGET, callPOST, callPUT } from 'network/network';
import { RoadmapEvent } from 'types/RoadmapEvent';

const _ROADMAPEVENTS_PATH = 'v1/roadmap/events';
const _ROADMAPEVENTS_TYPES_PATH = 'v1/roadmap/eventstypes';
const _ROADMAPEVENTS_PICTURE_PATH = 'v1/roadmap/events/{id}/customimage';

export const uploadFile = async (eventId: number, form: FormData, brandCode: string) => {
    try {
        const response = await callPOST(_ROADMAPEVENTS_PICTURE_PATH.replace(
			/{id}/g,
			eventId.toString()
		  ), form, true, { brandCode: brandCode});

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const deleteFile = async (eventId: number, brandCode: string) => {
    try {
        const response = await callDELETE(_ROADMAPEVENTS_PICTURE_PATH.replace(
			/{id}/g,
			eventId.toString()
		  ), {}, true, { brandCode: brandCode});

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const getRoadmapEventFromId = async (roadmapeventId: number, brandCode: string) => {
	try {
		const response = await callGET(_ROADMAPEVENTS_PATH + '/' + roadmapeventId, true, { brandCode: brandCode });

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const putRoadmapEvent = async (updatedRoadmapEvent: Partial<RoadmapEvent>, brandCode: string) => {
	try {
		await callPUT(_ROADMAPEVENTS_PATH, updatedRoadmapEvent, true, { brandCode: brandCode });
	} catch (error: any) {
		throw error;
	}
};

export const getRoadmapEventTypes = async (brandCode: string) => {
	try {
		const response = await callGET(_ROADMAPEVENTS_TYPES_PATH, true, { brandCode });

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const deleteRoadmapEventFromId = async (roadmapeventId: number, brandCode: string) => {
	try {
		const response = await callDELETE(_ROADMAPEVENTS_PATH + '/' + roadmapeventId, {}, true, { brandCode });

		return response;
	} catch (error: any) {
		throw error;
	}
};