import { FC, useEffect, useState } from 'react';

// hooks
import useHasRole from 'hooks/useHasRole';

//types
import { TabSelectorProps } from './TabSelector.component.d';

//style
import {
    TabSelectorBar,
    TabSelectorTab,
} from './style/TabSelector.style';
import { useParams } from 'react-router-dom';

const TabSelector: FC<TabSelectorProps> = ({ tabSelectorSettings, children }) => {

    const [activeTab, setActiveTab] = useState<string>('');
    const [isVisible, setIsVisible] = useState<boolean>(false);

    useEffect(() => {
        tabSelectorSettings.handleSelection(activeTab);
        let visible = true;
        if (tabSelectorSettings?.display === false) visible = false;
        setIsVisible(visible);
    }, [activeTab, tabSelectorSettings]);

    useEffect(() => {
        if ((tabSelectorSettings.tabItems) && (tabSelectorSettings.tabItems.length > 0) && (activeTab === '')) {
            if (tabSelectorSettings.defaultSelection) {
                setActiveTab(tabSelectorSettings.defaultSelection);
            } else {
                setActiveTab(tabSelectorSettings.tabItems[0].value);
            }
        }
    }, [tabSelectorSettings, activeTab]);

    return (
        <TabSelectorBar display={isVisible}>
            { tabSelectorSettings.tabItems.map((item, index) => {

                if (item.display !== undefined && item.display === false) return <></>;

                return(
                <TabSelectorTab 
                    key={index}
                    onClick={() => setActiveTab(item.value)}
                    isActive={item.value === activeTab}
                >
                    {item.title.toUpperCase()}
                </TabSelectorTab> 
               )
            })}
        </TabSelectorBar>
    );
}

export default TabSelector;