import { FC, useRef, useState } from 'react';

//hooks
import useCopyToClipboard from 'hooks/useCopyToCliboard';
import useHasRole from 'hooks/useHasRole';

//types
import { GenericInfoProps } from './GenericInfo.component.d';

//style
import { GenericInfoBox, FieldBox, RowBox, InfoLabel, InfoContent } from './style/GenericInfo.component.style';

const GenericInfo: FC<GenericInfoProps> = ({ user }) => {
	const [emailCopied, setEmailCopied] = useState(false);
	const [mobileCopied, setMobileCopied] = useState(false);

	const emailRef = useRef<HTMLTableCellElement>(null);
	const mobileRef = useRef<HTMLTableCellElement>(null);
	const { copyToClipboard } = useCopyToClipboard();

	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isAdmin = useHasRole('ADMIN');
	const isPersonalTrainer = useHasRole('PERSONAL TRAINER');
	const isCoach = useHasRole('COACH');
	const isSupervisoreAutostrada = useHasRole('SUPERVISORE_AUTOSTRADA');
	const isAdminAutostrada = useHasRole('ADMIN_AUTOSTRADA');
	const isAssistentePersonale = useHasRole('ASSISTENTE-PERSONALE');

	const canReadTelephoneNumber = isAdmin || isSuperAdmin || isPersonalTrainer || isAssistentePersonale || isCoach || isAdminAutostrada || isSupervisoreAutostrada;
	
	const formatPhoneNumber = (value: string): string => {
		if (!value) return "";
		if (value.length === 0) return value;
		if (value.charAt(0) !== '+') return value;
		if (value.length <= 3) return value;
		var intPrefix = value.substring(0, 3);
		var number = value.substr(3);
		return intPrefix + " " + number;
	}

	const emailClickHandler = () => {
		copyToClipboard(emailRef);
		setEmailCopied(true);
		setTimeout(() => {
			setEmailCopied(false);
		}, 1000);
	};

	const mobileClickHandler = () => {
		copyToClipboard(mobileRef);
		setMobileCopied(true);
		setTimeout(() => {
			setMobileCopied(false);
		}, 1000);
	};

	return (
		<GenericInfoBox>
			<RowBox>
				<FieldBox>
					<InfoLabel>Nome</InfoLabel>
					<InfoContent>{user.name}</InfoContent>
				</FieldBox>
				<FieldBox>
					<InfoLabel>Cognome</InfoLabel>
					<InfoContent>{user.surname}</InfoContent>
				</FieldBox>
				<FieldBox>
					<InfoLabel>Referral</InfoLabel>
					<InfoContent>{user.referral}</InfoContent>
				</FieldBox>
			</RowBox>
			<RowBox>
				<FieldBox>
					<InfoLabel>Email</InfoLabel>
					<InfoContent
						type='email'
						onClick={emailClickHandler}
						ref={emailRef}
					>
						{emailCopied ? 'Copiato!' : user.email}
					</InfoContent>
				</FieldBox>
			</RowBox>
			{canReadTelephoneNumber && <RowBox>
				<FieldBox>
					<InfoLabel>Cellulare</InfoLabel>
					<InfoContent
						type='mobile'
						onClick={mobileClickHandler}
						ref={mobileRef}
					>
						{mobileCopied ? 'Copiato!' : formatPhoneNumber(user.phoneNumber)}
					</InfoContent>
				</FieldBox>
			</RowBox>}
		</GenericInfoBox>
	);
};

export default GenericInfo;
