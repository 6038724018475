import { FC, useRef, useState } from 'react';

//hooks
import useCopyToClipboard from 'hooks/useCopyToCliboard';

//types
import { FederatedProps } from './Federated.component.d';

//assets
import { ReactComponent as AvatarIcon } from './assets/avatarIcon.svg';

//style
import { FederatedBox, FederatedData, FederatedEmail, FederatedName, NameOnFederated, FederateUserdId, FacebookImg } from './style/Federated.component.style';

const Federated: FC<FederatedProps> = ({ federated }) => {
	const [emailCopied, setEmailCopied] = useState(false);
	const [cognitoIdCopied, setCognitoIdCopied] = useState(false);

	const emailRef = useRef<HTMLTableCellElement>(null);
	const cognitoIdRef = useRef<HTMLTableCellElement>(null);

	const { copyToClipboard } = useCopyToClipboard();

	const imgClickHandler = () => {
		window.open(profileUrl, '_blank');
	};

	const emailClickHandler = () => {
		copyToClipboard(emailRef);
		setEmailCopied(true);
		setTimeout(() => {
			setEmailCopied(false);
		}, 1000);
	};

	const cognitoIdClickHandler = () => {
		copyToClipboard(cognitoIdRef);
		setCognitoIdCopied(true);
		setTimeout(() => {
			setCognitoIdCopied(false);
		}, 1000);
	};

	const isFacebook = federated.federatedName === 'Facebook';
	const imgSrc = isFacebook && federated.pictureOnFederated ? JSON.parse(federated.pictureOnFederated).data.url : '';
	const profileUrl = isFacebook ? `https://www.facebook.com/search/top/?q=${federated.nameOnFederated.split(' ').join('+')}` : undefined;

	return (
		<FederatedBox>
			<FederatedName>{federated.federatedName}</FederatedName>
			<FederatedData>
				{isFacebook && (
					<FacebookImg
						src={imgSrc}
						onClick={imgClickHandler}
					/>
				)}
				{!isFacebook && <AvatarIcon />}
				<NameOnFederated>{federated.nameOnFederated}</NameOnFederated>
				<FederatedEmail
					ref={emailRef}
					onClick={emailClickHandler}
				>
					{emailCopied ? 'Copiato!' : federated.federatedEmail}
				</FederatedEmail>
				<FederateUserdId
					ref={cognitoIdRef}
					onClick={cognitoIdClickHandler}
				>
					{cognitoIdCopied ? 'Copiato!' : federated.federateUserdId}
				</FederateUserdId>
			</FederatedData>
		</FederatedBox>
	);
};

export default Federated;
