import devAuthConfig from 'configs/aws/cognito-config-development';
import testAuthConfig from 'configs/aws/cognito-config-test';
import prodAuthConfig from 'configs/aws/cognito-config-production';

//types
import { Role } from 'types/Role';
import { Permission } from 'types/Permission';
import { User } from 'types/User';

//utils
import { getCookie, deleteCookie } from 'utils/cookie';
import config from 'configs/config';
import { getItemFromLocalStorage, removeItemFromLocalStorage } from 'utils/localStorage';

// to do refactor and move all the cookie storage into localStorage
let finalAuthConfiguration: any;
if (config.environment === 'development') finalAuthConfiguration = devAuthConfig;
if (config.environment === 'test') finalAuthConfiguration = testAuthConfig;
if (config.environment === 'production') finalAuthConfiguration = prodAuthConfig;

export const facebookCheckConnection = () => {
	const fbaCookie: any = getCookie('fba');
	if (fbaCookie === '1') {
		const RealCheck = () => {
			const COOKIE_PREFIX = 'CognitoIdentityServiceProvider.' + finalAuthConfiguration.userPoolWebClientId + '.';
			const initialLastAuthUser = getCookie(COOKIE_PREFIX + 'LastAuthUser');
			const cognitoEmail = getItemFromLocalStorage('cognitoEmail');

			if (initialLastAuthUser.includes('facebook') && cognitoEmail != null) {
				let cognitoAccessToken = getCookie(COOKIE_PREFIX + encodeURI(cognitoEmail) + '.accessToken');
				let fbAccessToken = getCookie(COOKIE_PREFIX + initialLastAuthUser + '.accessToken');
				let fbUrl = getCookie('fbUrl');
				const associateFBFN = async () => {
					// NON SOSTITUIRE con httpApiClient perché userebbe il token di FB invece di Cognito e quindi
					// l'utente non verrebbe riconosciuto lato backend
					const response = await fetch(config.baseTestURL + 'v1/users/self/addfacebook', {
						mode: 'cors',
						headers: {
							'Access-Control-Allow-Origin': '*',
							'content-type': 'application/json',
							Authorization: 'Bearer ' + cognitoAccessToken,
							accept: 'application/json',
						},
						method: 'post',
						body: fbAccessToken,
					});
					// console.log("RESPONSE addfacebook:" + response.status);
					if (response.status === 200) {
						deleteCookie('fba');
						deleteCookie('fbUrl');
						removeItemFromLocalStorage('cognitoEmail');
						window.location.replace(fbUrl);
					}
				};
				associateFBFN();
			}
		};
		RealCheck();
	}
};

export const extractRolesAndPermissionFromUser = (user: User) => {
	let roles: Role[] = [];

	let permissions: Permission[] = [];
	let tempPermissions: Permission[] = [];

	if (user.roles.length === 0) return { roles, permissions };

	for (let i = 0; i < user.roles.length; i++) {
		const role = user.roles[i];

		roles.push(role);

		for (let j = 0; j < role.permissions.length; j++) {
			let permission = role.permissions[j];

			tempPermissions.push(permission);
		}
	}

	permissions = [...Array.from(new Set(tempPermissions))];

	return { roles, permissions };
};

export const extractGlobalRoles = (rolesResponse: any) => {
	let roles: Role[] = [];

	if (rolesResponse.length === 0) return { rolesResponse };

	for (let i = 0; i < rolesResponse.length; i++) {
		const role = rolesResponse[i];

		roles.push(role.role);
	}

	return { roles };
};
