interface Month {
	name: string;
	shortName: string;
	num: number;
}

export const months: Month[] = [
	{ name: 'Gennaio', shortName: 'Gen', num: 0 },
	{ name: 'Febbraio', shortName: 'Feb', num: 1 },
	{ name: 'Marzo', shortName: 'Mar', num: 2 },
	{ name: 'Aprile', shortName: 'Apr', num: 3 },
	{ name: 'Maggio', shortName: 'Mag', num: 4 },
	{ name: 'Giugno', shortName: 'Giu', num: 5 },
	{ name: 'Luglio', shortName: 'Lug', num: 6 },
	{ name: 'Agosto', shortName: 'Ago', num: 7 },
	{ name: 'Settembre', shortName: 'Set', num: 8 },
	{ name: 'Ottobre', shortName: 'Ott', num: 9 },
	{ name: 'Novembre', shortName: 'Nov', num: 10 },
	{ name: 'Dicembre', shortName: 'Dic', num: 11 },
];
