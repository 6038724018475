import { callGET, callPOST } from 'network/network';

import { PaymentPlan } from 'types/PaymentPlan';

import { getBrand } from 'configs/brandMap/brandMap.helper';

const _PAYMENT_TYPES_PATH = 'v1/paymenttypes';
const _PAYMENT_PLANS_PATH = 'v1/paymentplans';
const _PAYMENT_PLANS_ALL_PATH = 'v1/paymentplans/products/{productId}';
const _PAYMENT_PLANS_SAVE_PATH = 'v1/products/services/addpaymentplan';

const brand = getBrand();


export const getPaymentPlans = async (productId: number) => {
	const brand = getBrand();
	if (!brand) return;
	try {
		const response = await callGET(_PAYMENT_PLANS_ALL_PATH.replace(
			/{productId}/g,
			productId.toString()
		  ), true, { brandCode: brand.code });

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const getPaymentTypes = async () => {
	try {
		const response = await callGET(_PAYMENT_TYPES_PATH, true);

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const saveNewPlan = async (paymentPlan: Partial<PaymentPlan>) => {
	const brand = getBrand();
	if (!brand) return;
	try {
		const response = await callPOST(_PAYMENT_PLANS_SAVE_PATH, paymentPlan, true, { brandCode: brand.code});

		return response;
	} catch (error: any) {
		throw error;
	}
};
