import { FC, useState, useEffect } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { updateLevel } from 'redux/actions/actions-global';

//hooks
import { useParams, useSearchParams } from 'react-router-dom';
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsMounted } from 'usehooks-ts';
import useAppSelector from 'hooks/useAppSelector';
import useHasRole from 'hooks/useHasRole';
import useHasPermission from 'hooks/useHasPermission';

//types
import { LevelDetailPageProps } from './LevelDetailPage.component.d';
import { TitleBreadCrumbSettings } from 'components/DashboardTitleSection/DashboardTitleSection.component.d';
import { Level } from 'types/Level';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

//components
import DashboardTitleSection from 'components/DashboardTitleSection';
import LevelDetail from './LevelDetail';

//utils
import { getLevelFromId } from 'services/level.services';
import { getBrand } from 'configs/brandMap/brandMap.helper';

//style
import { Page } from './style/LevelDetailPage.component.style';

const LevelDetailPage: FC<LevelDetailPageProps> = ({ putLevel }) => {
	const [level, setLevel] = useState<Level>({} as Level);
	const [subPath, setSubPath] = useState<string | undefined>('');
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isAdmin = useHasRole('ADMIN');
	const isAuthor = useHasRole('AUTORE');
	const isCoach = useHasRole('COACH');

	const levelsList = useAppSelector((state) => state.global.allLevels) as Level[];

	const { levelId } = useParams();
	const dispatch = useAppDispatch();
	const isMounted = useIsMounted();
	const numericId: number = levelId ? +levelId : 0;

	const brand = getBrand();

	const getLevel = async (numericId: number) => {
		if (!brand) return;
		if (numericId === 0) return;
		const snackbarData: SnackbarOptions = {};

		try {
			const response = await getLevelFromId(numericId, brand.code);
			setLevel(response);
			setIsLoading(false);
		} catch (error: any) {
			snackbarData.type = 'error';
			snackbarData.message = 'Errore nella ricerca dei prodotti';
			dispatch(addSnackbar(snackbarData));
			if (!isMounted()) return;
		}
	};

	const updateLocalLevel = async (p: Partial<Level>) => {
		try {
			await putLevel(p);
			setLevel((prevState) => ({ ...prevState, ...p }));
			dispatch(updateLevel(levelsList, p));
		} catch (error) {
			throw error;
		}
	};

	useEffect(() => {
		getLevel(numericId);
	}, [numericId, brand]);

	useEffect(() => {
		if (!isLoading) setSubPath((subPath) => levelId?.toString() + ' | ' + level?.description);
	}, [isLoading, level, levelId]);

	const breadCrumbSettings: TitleBreadCrumbSettings = {
		subSectionText: 'Gestione Traguardi',
		subSubSectionText: subPath,
		subSectionLink: '/reservedArea/levels',
	};

	if (!isAdmin && !isSuperAdmin && !isAuthor && !isCoach) return (<></>);

	return (
		<Page>
			<DashboardTitleSection breadCrumbSettings={breadCrumbSettings}></DashboardTitleSection>

			{isLoading && <>Loader</>}
			{!isLoading && (
				<LevelDetail
					level={level}
					putLevel={updateLocalLevel}
					updateLevelData={getLevel}
				/>
			)}
		</Page>
	);
};

export default LevelDetailPage;
