import { FC, useEffect, useState } from 'react';

//redux
import { enableAppMain } from 'redux/slices/slice-app-main';
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';

//components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';

//utils
import { uploadAttachment } from '../services/LessonAttachments.services';

//types
import { CourseNode } from 'types/Course';
import { AttachmentByURLModalProps } from './AttachmentByURLModal.component.d';

//style
import { 
	ButtonsRow, 
	CancelButton, 
	SaveButton, 
	LinkTitle, 
	AddAttachmentBox, 
	LinkInput 
} from './style/AttachmentByURLModal.component.style';

const _DEFAULT_URL = 'https://www.viveredipoker.it/';

const AttachmentByURLModal: FC<AttachmentByURLModalProps> = ({ node, onCloseButtonModal, updateLocalNode, setIsContentModified }) => {
    const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);

    const [attachmentLink, setAttachmentLink] = useState<string>(_DEFAULT_URL);

	const [isLinkModified, setIsLinkModified] = useState<boolean>(false);

	const dispatch = useAppDispatch();

    const closeButtonHandler = () => {
		if (onCloseButtonModal) onCloseButtonModal();
	};
    
    const clearInput = () => {
		setAttachmentLink(_DEFAULT_URL);
	}

	const inputChangeHandler = (inputValue: any) => {
		if (isLinkModified === false) setIsLinkModified(true);
		setAttachmentLink(inputValue.target.value);
	};

	const inputKeyHandler = (event: any) => {
		if (event.keyCode === 27) {
			closeButtonHandler();
		}
		if (event.keyCode === 13 && !event.shiftKey && isLinkModified) {
			onSaveClickHandler();
		}
	};

    const isValidUrl = async (urlString: string): Promise<boolean> => {
        try {
          const url = new URL(urlString);
          const response = await fetch(url.toString());
          return response.ok;
        } catch (error) {
          return false;
        }
    }

    const onSaveButtonModal = async (attachmentLink: string) => {
        var updatedNode = { ...node} as Partial<CourseNode>;
        if (!updatedNode.lesson) return;
        try {
            var uploaded = await uploadAttachment(node.id, undefined, attachmentLink);
            updatedNode.lesson.attachments = uploaded.attachments;
            updateLocalNode(node.id, updatedNode);
            setIsContentModified();
        } catch (error) {
            const snackbarData: SnackbarOptions = {};
            snackbarData.type = 'error';
            snackbarData.message = `C'è stato qualche problema nella cancellazione dell'allegato!`;
            dispatch(addSnackbar(snackbarData));
            throw error;
        }
        // var updatedNode = { ...node} as Partial<CourseNode>;
        // if (!updatedNode.lesson) return;
        // var updatedAttachments = { ...updatedNode.lesson.attachments};
        // // {
        // //     "id": 5062, // GENERANDOLO A MANO MI MANCHEREBBE QUESTO
        // //     "fileName": "assistente.jpg",
        // //     "url": "https://www.viveredipoker.it/ldlapifiletest/course/attachment/assistente.jpg",
        // //     "fileType": {
        // //       "id": 2,
        // //       "name": "Course Attachment",
        // //       "relativePath": "course/attachment"
        // //     }
        // //   }
		// updatedNode.lesson.attachments = updatedAttachments;
        // // QUI DEVO FARE IL SALVATAGGIO/UPLOAD VERO E PROPRIO MA MANCA ENDPOINT PER FARLO
    }

	const onSaveClickHandler = async () => {
        if (attachmentLink === '') return;
        if (onCloseButtonModal) onCloseButtonModal();
        onSaveButtonModal(attachmentLink);
        clearInput();
        return;
	};

    return (
        <BaseModal
            isBackdropClickActive={true}
			onClose={closeButtonHandler}
			isCloseButtonClicked={isCloseButtonClicked}
        >
            <BaseCard
                flavor='outlined'
                maxWidth='700px'
            >
                <AddAttachmentBox>
                    <LinkTitle>Link per un nuovo Attachment della Lezione:</LinkTitle>
                    <LinkInput
                        onKeyDown={inputKeyHandler}
                        onChange={inputChangeHandler}
                        value={attachmentLink}
                        autoFocus
                    />
                    <ButtonsRow>
                        <CancelButton onClick={closeButtonHandler}>Annulla</CancelButton>

                        <SaveButton
                            disabled={!isLinkModified || attachmentLink.trim().length === 0}
                            onClick={onSaveClickHandler}
                        >
                            Salva Modifiche
                        </SaveButton>
                    </ButtonsRow>
                </AddAttachmentBox>
            </BaseCard>
        </BaseModal>
    );
};

export default AttachmentByURLModal;
