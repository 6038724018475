import { FC, useEffect, useState } from 'react';

//types
import { ThematicBroadcastLinksTabProps } from './ThematicBroadcastLinksTab.component.d';
import { Product } from 'types/Product';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';

//redux
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from 'redux/actions/actions-snackbar';

//components
import BaseFieldset from 'components/ui/BaseFieldset';
import { InfoLabel } from '../GenericInfo/style/CourseGenericInfo.component.style';
import EditableInput from 'components/EditableComponents/EditableInput/EditableInput.component';

//utils
import { uploadRecordedVideo } from './services/ThematicBroadcastLinksTab.services';

//style
import { MainBox, RowBox, Row, Col, RemoveButton } from './style/ProductTab.component.style';
import { ProductThematicBroadcast } from 'types/ProductThematicBroadcast';


const ThematicBroadcastLinksTab: FC<ThematicBroadcastLinksTabProps> = ({ product, putProduct, updateProductData }) => {
	
	const [ localRegistrationLink, setLocalRegistrationLink ] = useState('');
	const dispatch = useAppDispatch();

	function extractYTVideoId(url: string) {
		// Extracts video ID from YouTube URL if possible. 
		// Examples giving the same video ID:
		// https://www.youtube.com/watch?v=_qMZAx2EJdI
		// https://youtu.be/_qMZAx2EJdI?si=8oUp9beq5UVVHTy1
		// https://youtu.be/_qMZAx2EJdI
		// _qMZAx2EJdI

		const vIndex = url.lastIndexOf('?v=');
		const lastSlashIndex = url.lastIndexOf('/');
		const lastQuestionMarkIndex = url.lastIndexOf('?'); 

		if (vIndex > 0) return url.substring(vIndex + 3);
		if (lastQuestionMarkIndex > lastSlashIndex) return url.substring(lastSlashIndex + 1, lastQuestionMarkIndex);
		if (lastSlashIndex > 0) return url.substring(lastSlashIndex + 1);
		return url;
	}
	
	const updateProductInfo = async (id: string, content: string) => {
		var partial: Partial<Product> = {};
		var thematic: ProductThematicBroadcast = {...product.productThematicCall, status: product.productThematicCall.status};
		if (id === 'protagonist') thematic.protagonistLink = content;
		if (id === 'spectator') thematic.spectatorLink = content;//extractYTVideoId(content);
		if (id === 'registration') {
			try {
				var uploadedVideo = await uploadRecordedVideo(product.id, content);
				thematic.registrationLink = uploadedVideo;
				if (product.productThematicCall.type !== 'ESPERIENZA') {
					thematic.status = 'PUBLISHED';
				}
			} catch (error) {
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'error';
				snackbarData.message = `C'è stato qualche problema nel salvataggio del video!`;
				dispatch(addSnackbar(snackbarData));
				throw error;
			}
		}
		partial = { id: product.id, productThematicCall: thematic}
		await putProduct(partial);
	};

	const handleRemoveRegistrationLinkBtn = async () => {
		var thematic: ProductThematicBroadcast = {...product.productThematicCall, status: product.productThematicCall.status};
		if (product.productThematicCall.type !== 'ESPERIENZA') {
			thematic.status = 'SCHEDULED';
		}
		
	}

	useEffect(() => {
		if ((product.productThematicCall?.registrationLink?.url) && (product.productThematicCall?.registrationLink?.url !== localRegistrationLink)) setLocalRegistrationLink(product.productThematicCall.registrationLink.url);
	}, [product]);

	return (
		<MainBox>
			<RowBox style={{ visibility: (product.productThematicCall.type === 'ESPERIENZA') ? 'hidden' : 'visible' }}>
				<BaseFieldset legend='Diretta'>
					{(
						((product?.productThematicCall?.maxProtagonist !== null) && (product?.productThematicCall?.maxProtagonist > 0)) || // with n protagonists
						(product?.productThematicCall?.maxProtagonist === null) // with unlimited protagonists
					) &&
					<Row>
						<Col>
							<InfoLabel>Link Protagonista:</InfoLabel>
						</Col>

						<Col>
							<Row>
								<EditableInput
									inputId={'protagonist'}
									editableInput={product.productThematicCall.protagonistLink}
									placeholder={"Inserisci il link per partecipare alla diretta"}
									update={updateProductInfo}
								/>
							</Row>
						</Col>
					</Row>
					}

					<Row>
						<Col>
							<InfoLabel>ID Streamyard:</InfoLabel>
						</Col>

						<Col>
							<Row>
								<EditableInput
									inputId={'spectator'}
									editableInput={product.productThematicCall.spectatorLink}
									placeholder={"Inserisci l'id del video Streamyard"}
									update={updateProductInfo}
								/>
							</Row>
						</Col>
					</Row>

				</BaseFieldset>
			</RowBox>
			{ product.productThematicCall.onShop && <RowBox>
				<BaseFieldset legend='Registrazione'>
					
				<Row>
						<Col>
							<InfoLabel>Link {(product.productThematicCall.type === 'ESPERIENZA') ? "Video Istruzioni" : "Registrazione Aula"}:</InfoLabel>
						</Col>

						<Col>
							<Row>
								<EditableInput
									inputId={'registration'}
									editableInput={localRegistrationLink ?? ''}
									placeholder={"Inserisci link FTP"}
									update={updateProductInfo}
								/>
							</Row>
						</Col>

						<Col>
							{ false && <RemoveButton onClick={handleRemoveRegistrationLinkBtn} disabled={!localRegistrationLink} title={"Rimuovi il link"}>&#10006;</RemoveButton>}
						</Col>
					</Row>

				</BaseFieldset>
			</RowBox>
			}
		</MainBox>
	);
};

export default ThematicBroadcastLinksTab;
