import { callGET } from 'network/network';

//types
import { UsersSearchByParams } from './globalServices.d';

const _ROLES_PATH = 'v1/roles';
const _CATEGORIES_PATH = 'v1/categories';
const _SUBCATEGORIES_PATH = 'v1/subcategories';
const _PRODUCTS_SEARCH_PATH = 'v1/products';
const _LEVELS_SEARCH_PATH = 'v1/vippathlevel';
const _USERS_ROLE_PATH = 'v1/users/searchby';
const _PAYMENT_TYPES_PATH = 'v1/paymenttypes';

export const getRoles = async () => {
	try {
		const response = await callGET(_ROLES_PATH, true);

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const getCategories = async (brandCode: string) => {
	try {
		const params = { brandCode };
		const response = await callGET(_CATEGORIES_PATH, true, params);

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const getSubCategories = async (brandCode: string) => {
	try {
		const params = { brandCode };
		const response = await callGET(_SUBCATEGORIES_PATH, true, params);

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const getProducts = async (brandCode: string, types: string[]) => {
	try {
		const response = await callGET(_PRODUCTS_SEARCH_PATH, true, { brandCode, types });

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const getLevels = async () => {
	try {
		const response = await callGET(_LEVELS_SEARCH_PATH, true);

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const getUsersByRoles = async (usersSearchByParams: UsersSearchByParams) => {
	try {
		const response = await callGET(_USERS_ROLE_PATH, true, usersSearchByParams);

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const getPaymentTypes = async () => {
	try {
		const response = await callGET(_PAYMENT_TYPES_PATH, true);

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};
