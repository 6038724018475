import { FC, useEffect, useState } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsMounted } from 'usehooks-ts';

//types
import { IsVipBroadcastProps } from './IsVipBroadcast.component.d';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

//utils

//style
import { IsVipBroadcastBox, IsVipBroadcastIcon, PriceType, PriceTypeSpec } from './style/IsVipBroadcast.component.style';
import useHasRole from 'hooks/useHasRole';

const IsVipBroadcast: FC<IsVipBroadcastProps> = ({ product, putProduct, disabled }) => {
	const dispatch = useAppDispatch();
	const isMounted = useIsMounted();

	const isAdmin = useHasRole('ADMIN');
	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');

	const clickHandler = async () => {
		
		const snackbarData: SnackbarOptions = {};

		try {
			await putProduct({ id: product.id, productThematicCall: {...product.productThematicCall, vip: !product.productThematicCall.vip }});
		} catch (error) {
			snackbarData.type = 'error';
			snackbarData.message = "Errore nell'aggiornamento tipologia prezzo";
			dispatch(addSnackbar(snackbarData));
			if (!isMounted()) return;
		}
	};

	const changeHandler = async (event: any) => {
		const isVip = (event.target.value === 'vip');

		const snackbarData: SnackbarOptions = {};

		try {
			await putProduct({ id: product.id, productThematicCall: {...product.productThematicCall, vip: isVip }});
		} catch (error) {
			snackbarData.type = 'error';
			snackbarData.message = "Errore nell'aggiornamento tipologia " + ((product.productThematicCall.type === 'ESPERIENZA') ? "esperienza" : "diretta");
			dispatch(addSnackbar(snackbarData));
			if (!isMounted()) return;
		}
	}

	return (
		<IsVipBroadcastBox>
			<PriceType>Tipologia {(product.productThematicCall.type === 'ESPERIENZA') ? 'Esperienza' : 'Diretta'}:&nbsp;</PriceType>
				<select name='liveType' onChange={changeHandler} disabled={disabled}>
					<option value='standard' selected={!product.productThematicCall.vip}>Standard</option>
					<option value='vip' selected={product.productThematicCall.vip}>Vip</option>
				</select>
		</IsVipBroadcastBox>
	);
};

export default IsVipBroadcast;
