import { Product } from 'types/Product';

export const getProductPrice = (p: Product) => {
	const t = p.type;
	let price: number | null = 0;
	if (t === 'COURSE') price = p.productCourse.price;
	if (t === 'SERVICE') price = p.productService.paymentPlan?.price;
	if (t === 'LESSON_PACK') price = p.productLessonPack.price;
	if (t === 'CREDIT_PACK') price = p.productCreditPack.price;
	if (t === 'THEMATIC_BROADCAST') {
		if (p.productThematicCall.type === 'ESPERIENZA') {
			price = p.productThematicCall.protagonistPrice;
		} else {
			price = p.productThematicCall?.price;
		}
	}
	return price ? price : 0;
};

export const setProductPriceAttributes = (p: Product, newPrice: number): Partial<Product> => {
	const t = p.type;
	let updatedProductAttributes = {} as Partial<Product>;
	if (t === 'COURSE') updatedProductAttributes = { productCourse: { ...p.productCourse, price: newPrice } };
	if (t === 'SERVICE') {
	}
	if (t === 'LESSON_PACK') updatedProductAttributes = { productLessonPack: { ...p.productLessonPack, price: newPrice } };
	if (t === 'CREDIT_PACK') updatedProductAttributes = { productCreditPack: { ...p.productCreditPack, price: newPrice } };
	if (t === 'THEMATIC_BROADCAST') {
		if (p.productThematicCall.type === 'ESPERIENZA') {
			updatedProductAttributes = { productThematicCall: { ...p.productThematicCall, protagonistPrice: newPrice, discountProtagonistPrice: null, discountSpectatorPrice: null, spectatorPrice: null, price: null, discountExpirationDate: null  } };
		} else {
			updatedProductAttributes = { productThematicCall: { ...p.productThematicCall, price: newPrice, discountProtagonistPrice: null, discountSpectatorPrice: null } };
		}
	}
	return updatedProductAttributes;
};
