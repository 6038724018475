import { FC, useEffect, useState } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsMounted } from 'usehooks-ts';
import useHasRole from 'hooks/useHasRole';
import useHasPermission from 'hooks/useHasPermission';

//utils
import { getSearchOldPayments } from './services/OldPaymentsPage.services';
import { getSearchUsers } from 'pages/UsersPage/services/UsersPage.services';

//types
import { TitleBreadCrumbSettings } from 'components/DashboardTitleSection/DashboardTitleSection.component.d';
import { TabSelectorSettings, TabSelectorItem } from 'components/TabSelector/TabSelector.component.d';
import { OldPaymentsTableItem } from './OldPaymentsTable/OldPaymentsTable.component.d';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { User } from 'types/User';
import { OldPaymentsSearchParams } from './services/OldPaymentsPage.services.d';
//components
import DashboardTitleSection from 'components/DashboardTitleSection';
import OldPaymentsTable from './OldPaymentsTable/OldPaymentsTable.component';

//assets
import { ReactComponent as SearchIconSVG } from './assets/searchIcon.svg';
import { ReactComponent as ClearButtonIconSVG } from './assets/clearButtonIcon.svg';

//style
import { BaseContentCard } from 'style/wrappers/wrappers';
import {
    Page,
    PageContent,
    SearchUsersBox,
    SearchInput,
    SearchClearButton,
    SearchButton,
	ZeroPaymentsCheckBox,
} from './style/OldPaymentsPage.style';

const OldPaymentsPage: FC<{}> = () => {
    const [searchResults, setSearchResults] = useState<OldPaymentsTableItem[]>([]);
	const [totalPages, setTotalPages] = useState<number>(0);
	const [totalElements, setTotalElements] = useState<number>(0);
	const [pageSize, setPageSize] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(1);

    const [searchMode, setSearchMode] = useState('all');

	const [searchUserInputValue, setSearchUserInputValue] = useState<string>('');
	const [searchUserInput, setSearchUserInput] = useState<string>('');
	const [searchUserResults, setSearchUserResults] = useState<User[]>();
    const [totalUserPages, setTotalUserPages] = useState<number>(0);
	const [totalUserElements, setTotalUserElements] = useState<number>(0);
	const [pageUserSize, setPageUserSize] = useState<number>(0);
	const [selectedUserId, setSelectedUserId] = useState<number | undefined>();

	const [zeroPaymentsEnabled, setZeroPaymentsEnabled] = useState(false);

	const dispatch = useAppDispatch();
	const isMounted = useIsMounted();

	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isAdmin = useHasRole('ADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');
	const isAmministrazione = useHasRole('CONTABILITÀ');

	const canReadUsers = useHasPermission('READ_USER'); 

	const nextPageHandler = () => setPageNumber((prevState) => prevState + 1);

	const prevPageHandler = () => setPageNumber((prevState) => prevState - 1);

    const searchUsers = async () => {
		const snackbarData: SnackbarOptions = {};

		try {
			if (searchUserInput.length === 0) return;
			const response = await getSearchUsers({ q: searchUserInput, page: 0, size: 500 });

			setSearchUserResults(response.content);
			setTotalUserElements(response.totalElements);
			setTotalUserPages(response.totalPages);
			setPageUserSize(response.size);
		} catch (error: any) {
			snackbarData.type = 'error';
			snackbarData.message = 'Errore nella ricerca degli utenti';
			dispatch(addSnackbar(snackbarData));
			if (!isMounted()) return;
		}
	};

    const clearSearchHandler = () => {
		setSearchUserInput('');
		setSearchUserInputValue('');
        setSearchMode('all');
        setSelectedUserId(undefined);
        setSearchUserResults(undefined);
	};

	const searchChangeHandler = (searchValue: any) => {
		setSearchUserInputValue(searchValue.target.value);
	};

	const selectionChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedUserId(+event.currentTarget.value);
	};

	const searchKeyHandler = (event: any) => {
		if (event.keyCode === 27) {
			clearSearchHandler();
		}
		if (event.keyCode === 13) {
			setSearchUserInput(event.target.value);
            setSearchMode('');
		}
	};

	const searchButtonHandler = () => {
		if (searchUserInputValue === '') {
			setSearchUserResults([]);
			setPageSize(0);
			setTotalElements(0);
			setTotalPages(0);
			return;
		}
		setSearchUserInput(searchUserInputValue);
	};

    const searchOldPayments = async (page: number) => {
        const response = await getSearchOldPayments({ mode: searchMode, userId: selectedUserId, includeZeros: zeroPaymentsEnabled, page: page - 1, size: 10 });

		setSearchResults(
			response.content.map((payment: any ) => ({ id: payment.id, customer: payment.userId+" | "+payment.utente, paymentdate: payment.insertDate, paymenttype: payment.paymentType, method: payment.metodo, paypalId: payment.paypalId, deadline: payment.expirationDate, amount: payment.payedAmount, producttype: payment.tipoProdotto, prodotto: payment.idProdotto+" | "+payment.nomeProdotto }))
        );
        setTotalElements(response.totalElements);
        setTotalPages(response.totalPages);
        setPageSize(response.size);
    };

	// const searchUsers = async () => {
	// 	const snackbarData: SnackbarOptions = {};

	// 	try {
	// 		if (searchUserInput.length === 0) return;
	// 		const response = await getSearchUsers({ q: searchUserInput, page: 0, size: 500 });
	// 		console.log(response.content);

	// 		setSearchUserResults(response.content);
	// 		setTotalUserElements(response.totalElements);
	// 		setTotalUserPages(response.totalPages);
	// 		setPageUserSize(response.size);
	// 	} catch (error: any) {
	// 		snackbarData.type = 'error';
	// 		snackbarData.message = 'Errore nella ricerca degli utenti';
	// 		dispatch(addSnackbar(snackbarData));
	// 		if (!isMounted()) return;
	// 	}
	// };

	const handleZeroPaymentsClick = () => {
		setZeroPaymentsEnabled((prevState) => !prevState);
	};

	useEffect(() => {
		searchUsers();
	}, [searchUserInput]);

	useEffect(() => {
		searchOldPayments(0);
	}, [selectedUserId]);

	useEffect(() => {
		searchOldPayments(pageNumber);
	}, [zeroPaymentsEnabled]);
	
    const breadCrumbSettings: TitleBreadCrumbSettings = { subSectionText: 'Vecchi Pagamenti' };

	const isSearchButtonVisible: boolean = searchUserInputValue !== '';

	if (!isAdmin && !isSuperAdmin && !isMiniAdmin && !isAmministrazione) return (<></>);

    return(
        <Page>
            <DashboardTitleSection breadCrumbSettings={breadCrumbSettings}>
                             
            </DashboardTitleSection>


            <BaseContentCard>
                <PageContent>
                <SearchUsersBox>

					<ZeroPaymentsCheckBox>
						<input
							id='zeroPaymentsCheckbox'
							type={'checkbox'}
							checked={zeroPaymentsEnabled}
							onClick={handleZeroPaymentsClick}
						/>
						<label htmlFor='zeroPaymentsCheckbox'>Visualizza pagamenti a 0</label>
					</ZeroPaymentsCheckBox>

                    { canReadUsers && <SearchInput
                        onKeyDown={searchKeyHandler}
                        onChange={searchChangeHandler}
                        value={searchUserInputValue}
                        placeholder='Ricerca utente'
                    />}

                    {isSearchButtonVisible && canReadUsers && (
                        <SearchClearButton onClick={clearSearchHandler}>
                            <ClearButtonIconSVG />
                        </SearchClearButton>
                    )}

                    { canReadUsers && <SearchButton onClick={searchButtonHandler}>
                        <SearchIconSVG />
                    </SearchButton>
					}
					
                </SearchUsersBox>
                {searchUserResults && (
							<select
								id='users'
								value={selectedUserId}
								onChange={selectionChangeHandler}
							>
								<option></option>
								{searchUserResults.map((su) => (
									<option
										value={su.id}
										key={su.id}
									>{`${su.id} | ${su.name} ${su.surname}`}</option>
								))}
							</select>
				)}
                
                <OldPaymentsTable
                    data={searchResults}
                    totalElements={totalElements}
                    totalPages={totalPages}
                    pageSize={pageSize}
                    searchOldPayments={searchOldPayments}
                />
                </PageContent>
            </BaseContentCard>
        </Page>
    );

}

export default OldPaymentsPage;
