import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Brand } from 'types/Brand';

interface UiState {

    isAppMobile: boolean;
    brand: Brand | undefined;
};

// initial slice state
const initialState: UiState = {

    isAppMobile: true,
    brand: undefined,
};

const uiSlice = createSlice(
    {
        name: 'ui',
        initialState,

        reducers: {

            setIsAppMobile: (state, action: PayloadAction<boolean>) => {
                state.isAppMobile = action.payload;
            },

            setBrand: (state, action: PayloadAction<Brand | undefined>) => {
                state.brand = action.payload;
            }
        }
    }
);

// slice actions mapping
export const {
    setIsAppMobile,
    setBrand
} = uiSlice.actions;

// reducer for store
export default uiSlice.reducer;
