import { FC } from 'react';

//types
import { LevelTabProps } from './LevelTab.component.d';

//components
import LevelGenericInfo from './GenericInfo';
import BaseFieldset from 'components/ui/BaseFieldset';

//style
import { MainBox, RowBox } from './style/LevelTab.component.style';

const LevelTab: FC<LevelTabProps> = ({ level, putLevel, updateLevelData }) => {
	return (
		<MainBox>
			<RowBox>
				<BaseFieldset legend='INFO'>
					<LevelGenericInfo
						level={level}
						putLevel={putLevel}
						updateLevelData={updateLevelData}
					/>
				</BaseFieldset>
			</RowBox>
		</MainBox>
	);
};

export default LevelTab;
