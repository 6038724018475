
import { FC, useEffect, useMemo, useState } from "react";

// types
import { MediaProp } from 'components/MediaPlayer/MediaPlayer.d';

//assets
import { ReactComponent as SearchIconSVG } from '../../assets/searchIcon.svg';
import { ReactComponent as ExpandIconSVG } from '../../assets/expandIcon.svg';
import { ReactComponent as CollapseIconSVG } from '../../assets/collapseIcon.svg';

//components
import VideoCourseList from "pages/ProductPage/VideoCourseList/VideoCourseList.component";
import MediaPlayer from "components/MediaPlayer";
import DescriptionTabDesktop from "./DescriptionTabDesktop/DescriptionTabDesktop.component";

//style
import {
    // CurrentLesson,
    // CourseInfoBox,
    // ProgressBar,
    InterectionLessonBox,
    SearchBox,
    SearchBar,
    LessonSearch,
    ExpandLesson,
    PageCourse,
    PageContent,
    ReproductionBox,
    BreadCrumb,
    VideoBox,
    CourseTitle,
    InterectionBox,
    DescriptionBox,
    LessonTitle,
    LessonDescription,
    FlowBox
} from './style/ProductPageDesktop.style';
import { Link } from "react-router-dom";


const ProductPageDesktop: FC<{courseItem: any, lessonToDispaly: any, typeOfProduct?: string}> = ({courseItem, lessonToDispaly, typeOfProduct}) => {

    // const { productsLessonsStatus } = useAppSelector(state => state.user);
    const [isLessonRendering, setIsLessonRendering] = useState<any>(lessonToDispaly ?? undefined);
    const [title, setTitle] = useState<string | undefined>(lessonToDispaly.title ?? undefined);
    const [lesson, setLesson] = useState<any>(lessonToDispaly.lesson ?? undefined);
    const [video, setVideo] = useState<any>(lessonToDispaly.lesson !== undefined ? lessonToDispaly.lesson.video : {});
    const [fileType, setFileType] = useState<any>((lessonToDispaly.lesson !== undefined) && (lessonToDispaly.lesson.video !== null) && (lessonToDispaly.lesson.video.fileType ?? undefined));
    const [videoUrl, setVideoUrl] = useState<string | undefined>((lessonToDispaly.lesson !== undefined) && (lessonToDispaly.lesson.video !== null) && (
        lessonToDispaly.lesson.video.url ? 
        lessonToDispaly.lesson.video.url : 
        lessonToDispaly.lesson.video.fileType.relativePath ? 
        `https://www.viveredipoker.it/ldlapifiletest/${lessonToDispaly.lesson.video.fileType.relativePath}/${lessonToDispaly.lesson.video.fileName}` :
         undefined
         ));
    const [fileName, setFileName] = useState<string | undefined>((lessonToDispaly.lesson !== undefined) && (lessonToDispaly.lesson.video !== null) && (lessonToDispaly.lesson.video.fileName ?? undefined));
    const [relativePath, setRelativePath] = useState<string | undefined>((lessonToDispaly.lesson !== undefined) && (lessonToDispaly.lesson.video !== null) && (lessonToDispaly.lesson.video.fileType.relativePath ?? undefined));
    const [finalUrl, setFinalUrl] = useState<string | undefined>(undefined);
    const [courseTitle, setcourseTitle] = useState<any>(courseItem.name ?? undefined);
    const [searchInput, setSearchInput] = useState<any>("");
    const [filteredLessons, setFilteredLessons] = useState<any>(courseItem.course.content ?? undefined);
    const [collapse, setCollapse] = useState(false);
    const [isButtonDescriptionActive, setIsButtonDescriptionActive] = useState(true);
    const [isLessonCompleted, setIsLessonCompleted] = useState<boolean | undefined>(undefined);

    const saveCompleteLessonData = (duration: number | undefined, currentTime: number | undefined) => {

        if ((currentTime === undefined) || (duration === undefined)) return setIsLessonCompleted(true);
        if ((currentTime / duration) > 0.9) return setIsLessonCompleted(true);
        return setIsLessonCompleted(false);
    };
    const onClickButtonDescriptionHandler = () => {
        setIsButtonDescriptionActive(true);
    };

    const collapseAll = () => {        
        setCollapse(!collapse);
    };

    const searchHandler = (searchValue: any) => {
        setSearchInput(searchValue.target.value)
    };
    
    const lessonHandler = (lessonItem: any) => {
        setIsLessonRendering(lessonItem)
        onClickButtonDescriptionHandler();
    };

    const closeCollapase = () => {
        setCollapse(true)
    };
    
    useEffect(() => {
        setFilteredLessons(courseItem.course.content.filter(
            (lesson: any) => {
                if (lesson.title === undefined || lesson.title === '') return lesson;
                if (lesson.title.toLowerCase().includes(searchInput.toLowerCase())) return lesson;
                if (lesson.lesson){
                if (lesson.lesson.description) return lesson.lesson.description.toLowerCase().includes(searchInput.toLowerCase())};
            })
        )
    }, [searchInput]);
        
    
    useEffect(() => {
        if (isLessonRendering !== undefined) {
            setTitle(isLessonRendering.title);
            setLesson(isLessonRendering.lesson);
        }

        if (lesson !== undefined) {
            setVideo(lesson.video);

        }

        if (video !== undefined && video !== null) {
            setVideoUrl(video.url);
            setFileType(video.fileType)
            setFileName(video.fileName);
        }
        
        if (fileType !== undefined) {
            setRelativePath(fileType.relativePath);
        }

        if (courseItem !== undefined) {
            setcourseTitle(courseItem.name);
        }

    }, [courseItem, lesson, video, fileType, isLessonRendering]);

    useEffect(() => {

        if (video === undefined && video === null) return;

        if (videoUrl && videoUrl !== null) return setFinalUrl(videoUrl);
        
        if (relativePath && 
            (relativePath !== null) &&
            fileName &&
            (fileName !== null)) return setFinalUrl(`https://www.viveredipoker.it/ldlapifiletest/${relativePath}/${fileName}`)
    }, [fileName, relativePath, videoUrl, video, isLessonRendering]);

    const localMedia: MediaProp = useMemo(() => {

        return {
           src: finalUrl ?? "",
           type: 'video/mp4'
       };
    }, [finalUrl]);

    const finalLinkTo = (
        (typeOfProduct === 'course') ?
        '/reservedArea/mycourses' :
        (
            (typeOfProduct === 'service') ?
            '/reservedArea/myservices' :
            '/reservedArea'
        ));
    const finalPath = (
        (typeOfProduct === 'course') ?
        'Corsi' :
        (
            (typeOfProduct === 'service') ?
            'Servizi' :
            ''
        ));

    return (
        <PageCourse>
            <PageContent>
                {isLessonRendering &&
                <ReproductionBox>
                    <BreadCrumb>
                        <CourseTitle>
                            <Link to={finalLinkTo}>
                                {finalPath}    
                            </Link> / {courseTitle}
                            
                        </CourseTitle>
                    </BreadCrumb>

                    {(isLessonRendering.lesson !== undefined) && isLessonRendering.lesson.video && (
                    <VideoBox>
                        <MediaPlayer media={localMedia} preload='metadata' maxWidth="none" />    
                    </VideoBox>
                    )}

                    {(isLessonRendering.lesson !== undefined) && <DescriptionBox isFirstElement={!isLessonRendering.lesson.video} >
                        <LessonTitle>{title}</LessonTitle>

                        {(isLessonRendering.lesson !== undefined) && (isLessonRendering.lesson.description !== undefined) && <LessonDescription dangerouslySetInnerHTML={{__html: isLessonRendering.lesson.description}} />}
                        {isButtonDescriptionActive && <DescriptionTabDesktop courseItem={isLessonRendering} />}

                    </DescriptionBox>}

                </ReproductionBox>
                }

                <InterectionBox>
                    <InterectionLessonBox>
                        <SearchBox>
                            <SearchBar 
                                onChange={searchHandler}
                                value={searchInput}
                                />

                            <LessonSearch>
                                <SearchIconSVG />
                            </LessonSearch>
                        </SearchBox>

                        <ExpandLesson onClick={collapseAll}>
                            {!collapse && <ExpandIconSVG />}
                            {collapse && <CollapseIconSVG />}
                        </ExpandLesson>
                    </InterectionLessonBox>

                    <FlowBox>
                        <VideoCourseList 
                            lessonSetting={lessonHandler} 
                            courseItem={filteredLessons} 
                            collapse={collapse} 
                            collapseHandler={closeCollapase} 
                            isLessonCompleted={isLessonCompleted}
                        />
                    </FlowBox>
                </InterectionBox>
            </PageContent>
        </PageCourse>
    );
};

export default ProductPageDesktop;