import { FC, useEffect, useState } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsMounted } from 'usehooks-ts';

//components
import PoolSelection from './PoolSelection';

//utils
import { movePointsBetweenPools } from '../services/PointsPage.services';

//types
import { PoolsMovementProps } from './PoolsMovement.component.d';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { Pool } from 'types/Pool';

//style
import { PoolsMovementBox } from './style/PoolsMovement.component.style';

const PoolsMovement: FC<PoolsMovementProps> = ({ myPools }) => {
	const [fromPool, setFromPool] = useState<Pool>();
	const [toPool, setToPool] = useState<Pool>();
	const [transferValue, setTransferValue] = useState(0);
	const [transferDescription, setTransferDescription] = useState('');
	const [isValueEnabled, setIsValueEnabled] = useState(false);
	const [isTransferEnabled, setIsTransferEnabled] = useState(false);

	const dispatch = useAppDispatch();
	const isMounted = useIsMounted();

	const authorPools = useAppSelector((state) => state.global.authorPools);

	const onTransferValueChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTransferValue(+event.currentTarget.value);
	};

	const onTransferDescriptionChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTransferDescription(event.currentTarget.value);
	};

	const onInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
		event.currentTarget.select();
	};

	const onTransferClickHandler = async () => {
		const snackbarData: SnackbarOptions = {};
		if (fromPool && toPool && transferValue && transferDescription)
			try {
				await movePointsBetweenPools(fromPool?.id, toPool?.id, transferValue, transferDescription);
				snackbarData.type = 'success';
				snackbarData.message = 'Trasferimento punti completato con successo';
				dispatch(addSnackbar(snackbarData));
				setTransferValue(0);
				// setTransferDescription('');
			} catch (error) {
				snackbarData.type = 'error';
				snackbarData.message = "Errore nell'assegnazione prodotto";
				dispatch(addSnackbar(snackbarData));
				if (!isMounted()) return;
			}
	};

	useEffect(() => {
		if (fromPool && toPool) setIsValueEnabled(true);
		if (!fromPool || !toPool) setIsValueEnabled(false);
	}, [fromPool, toPool]);

	useEffect(() => {
		if (isValueEnabled && transferValue !== 0 && transferDescription !== '') setIsTransferEnabled(true);
		if (!isValueEnabled || transferValue === 0 || transferDescription === '') setIsTransferEnabled(false);
	}, [isValueEnabled, transferDescription, transferValue]);

	return (
		<PoolsMovementBox>
			<PoolSelection
				label='DA: '
				setPool={setFromPool}
				pools={myPools}
			/>
			<PoolSelection
				label='A: '
				setPool={setToPool}
				pools={authorPools ? authorPools : []}
			/>
			<label>Punti:</label>
			<input
				type='number'
				disabled={!isValueEnabled}
				value={transferValue}
				onChange={onTransferValueChangeHandler}
				onFocus={onInputFocus}
			/>
			<label>Descrizione:</label>
			<input
				type='text'
				disabled={!isValueEnabled}
				value={transferDescription}
				onChange={onTransferDescriptionChangeHandler}
				onFocus={onInputFocus}
			/>
			<button
				disabled={!isTransferEnabled}
				onClick={onTransferClickHandler}
			>
				Trasferisci punti
			</button>
		</PoolsMovementBox>
	);
};

export default PoolsMovement;
