import { FC, useState } from 'react';

// hooks
import useHasRole from 'hooks/useHasRole';

//types
import { EditableGroupProps } from './EditableGroup.component.d';

//style
import { 
	GroupBlock,
	EditBox
 } from './style/EditableGroup.component.style';
import SectionEditorModal from 'components/SectionEditorModal/SectionEditorModal.component';

const EditableGroup: FC<EditableGroupProps> = ({
	groupId,
	update,
	children,
	newElementPos,
	refreshProduct,
	webpageId
}) => {
	const [isEditorEnabled, setIsEditorEnabled] = useState(false);
	const [isOnHover, setIsOnHover] = useState(false);

	const isAdmin = useHasRole('ADMIN');
    const isSuperAdmin = useHasRole('SUPERADMIN');
    const userHasAdminRole = isAdmin || isSuperAdmin;

	const onMouseOverHandler = () => setIsOnHover(true);
	const onMouseOutHandler = () => setIsOnHover(false);
	const onCloseModalHandler = () => setIsEditorEnabled(false);
	
	const onModifyImageClickHandler = () => {

        if (!userHasAdminRole) return;
		setIsEditorEnabled(prevState => !prevState);
	};
	
	return (
		<>
			<GroupBlock
				onMouseOver={onMouseOverHandler}
				onMouseLeave={onMouseOutHandler}
				onClick={onModifyImageClickHandler}
				isUserAdmin={userHasAdminRole}
			>
				{children}

				{isOnHover && userHasAdminRole && (
					<EditBox>
						Modifica gruppo
					</EditBox>
				)}
			</GroupBlock>

			{isEditorEnabled && userHasAdminRole && (
				<SectionEditorModal 
					onCloseButtonModal={onCloseModalHandler}
					update={update}
					elementId={groupId}
					elementsToDisplay={children}
					typeOfLayout='GROUP'
					newElementPos={newElementPos}
					blockId={groupId}
					updateData={refreshProduct}
					webpageId={webpageId}
				/>
			)}
		</>
	);
};

export default EditableGroup;
