import { FC, useState } from 'react';

//hooks
import useHasRole from 'hooks/useHasRole';
import useHasPermission from 'hooks/useHasPermission';

//types
import { RoleProps } from './Role.component.d';

//utils
import { addRoleToUser, removeRoleFromUser } from './services/Role.services';

//style
import { RoleBox } from './style/Role.component.style';

const Role: FC<RoleProps> = ({ userId, role, userRoles, updateLocalUser }) => {
	const [enabled, setEnabled] = useState(userRoles?.some((ur) => ur.role === role.role));

	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isAdmin = useHasRole('ADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');
	const isAmministrazione = useHasRole('CONTABILITÀ');
	const isAuthor = useHasRole('AUTORE');
	const isPersonalTrainer = useHasRole('PERSONAL TRAINER');
	const isSupervisoreAutostrada = useHasRole('SUPERVISORE_AUTOSTRADA');
	const isAdminAutostrada = useHasRole('ADMIN_AUTOSTRADA');
	const isAssistentePersonale = useHasRole('ASSISTENTE-PERSONALE');

	const canEditRoles = isSuperAdmin || isAdmin || isMiniAdmin || isAdminAutostrada || isSupervisoreAutostrada;

	const clickHandler = async () => {
		if (!canEditRoles) return;
		if (!enabled) {
			try {
				await addRoleToUser(userId, role.id);
				setEnabled(true);
				updateLocalUser();
			} catch (error) {}
		}
		if (enabled) {
			try {
				await removeRoleFromUser(userId, role.id);
				setEnabled(false);
				updateLocalUser();
			} catch (error) {}
		}
	};

	return (
		<RoleBox
			onClick={clickHandler}
			enabled={enabled}
			isClickable={canEditRoles}
		>
			{role?.role}
		</RoleBox>
	);
};

export default Role;
