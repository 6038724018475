import styled from 'styled-components';

export const WithdrawalsTabBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1rem;
	color: white;
`;

export const Filters = styled.div`
	display: flex;
	gap: 1rem;
`;
