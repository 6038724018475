import { callGET, callPUT } from 'network/network';
import { ProductSalesData } from 'types/ProductSalesData';

import { getBrand } from 'configs/brandMap/brandMap.helper';

const _PRODUCTS_FOR_SELL_PATH = 'v1/productsforsell';
const _PRODUCTS_WEIGHTS_PATH = 'v1/products/weights';


export const getProductsForSell = async (brandCode: string) => {
	try {
		const response = await callGET(_PRODUCTS_FOR_SELL_PATH, true, { brandCode });

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const putProductsWeights = async (productSalesDatas: Partial<ProductSalesData>[]) => {
	const brand = getBrand();
	if (!brand) return;
	try {
		const response = await callPUT(_PRODUCTS_WEIGHTS_PATH, productSalesDatas, true, { brandCode: brand.code });

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};
