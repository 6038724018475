import { FC, useState, useEffect } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { updateProduct } from 'redux/actions/actions-global';

//hooks
import { useParams, useSearchParams } from 'react-router-dom';
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsMounted } from 'usehooks-ts';
import useAppSelector from 'hooks/useAppSelector';
import useHasRole from 'hooks/useHasRole';
import useHasPermission from 'hooks/useHasPermission';

//types
import { ProductDetailPageProps } from './ProductDetailPage.component.d';
import { TitleBreadCrumbSettings } from 'components/DashboardTitleSection/DashboardTitleSection.component.d';
import { Product } from 'types/Product';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

//components
import DashboardTitleSection from 'components/DashboardTitleSection';
import CourseDetail from './CourseDetail';
import ServiceDetail from './ServiceDetail';
import LessonPackDetail from './LessonPackDetail';
import AutomaticIncomeDetail from './AutomaticIncomeDetail';
import ThematicBroadcastDetail from './ThematicBroadcastDetail';

//utils
import { getProductFromId } from 'services/product.services';
import { getBrand } from 'configs/brandMap/brandMap.helper';

//style
import { Page } from './style/ProductDetailPage.component.style';

const ProductDetailPage: FC<ProductDetailPageProps> = ({ putProduct }) => {
	const [product, setProduct] = useState<Product>({} as Product);
	const [subPath, setSubPath] = useState<string | undefined>('');
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isAdmin = useHasRole('ADMIN');
	const isAuthor = useHasRole('AUTORE');
	var isDiretta = useHasRole('DIRETTA');
	var isEsperienza = useHasRole('ESPERIENZA');
	const isRegistaLDL = useHasRole('REGISTA-LDL');
	const isRegistaMB = useHasRole('REGISTA-MB')
	const isRegistaTCR = useHasRole('REGISTA-TCR');
	const isEditorLDL = useHasRole('EDITOR-LDL');
	const isEditorMB = useHasRole('EDITOR-MB');
	const isEditorTCR = useHasRole('EDITOR-TCR');
	isDiretta = isDiretta || isRegistaLDL || isRegistaMB || isRegistaTCR || isEditorLDL || isEditorMB || isEditorTCR;
	isEsperienza = isDiretta || isEsperienza;

	const productsList = useAppSelector((state) => state.global.allProducts) as Product[];

	const brand = getBrand();


	const { productId } = useParams();
	const dispatch = useAppDispatch();
	const isMounted = useIsMounted();
	const numericId: number = productId ? +productId : 0;

	const getProduct = async (numericId: number) => {
		if (!brand) return;
		if (numericId === 0) return;
		const snackbarData: SnackbarOptions = {};

		try {
			const response = await getProductFromId(numericId, brand.code);
			setProduct(response);
			setIsLoading(false);
		} catch (error: any) {
			snackbarData.type = 'error';
			snackbarData.message = 'Errore nella ricerca dei prodotti';
			dispatch(addSnackbar(snackbarData));
			if (!isMounted()) return;
		}
	};

	const updateLocalProduct = async (p: Partial<Product>) => {
		try {
			await putProduct(p);
			setProduct((prevState) => ({ ...prevState, ...p }));
			dispatch(updateProduct(productsList, p));
		} catch (error) {
			throw error;
		}
	};

	useEffect(() => {
		getProduct(numericId);
	}, [numericId, brand]);

	useEffect(() => {
		if (!isLoading) setSubPath((subPath) => productId?.toString() + ' | ' + product?.name);
	}, [isLoading, product, productId]);

	const breadCrumbSettings: TitleBreadCrumbSettings = {
		subSectionText: 'Gestione Prodotti',
		subSubSectionText: subPath,
		subSectionLink: '/reservedArea/products',
	};

	if (!isAdmin && !isSuperAdmin && !isAuthor && !isDiretta) return (<></>);

	return (
		<Page>
			<DashboardTitleSection breadCrumbSettings={breadCrumbSettings}></DashboardTitleSection>

			{isLoading && <>Loader</>}
			{!isLoading && product.type === 'COURSE' && (
				<CourseDetail
					product={product}
					putProduct={updateLocalProduct}
					updateProductData={getProduct}
				/>
			)}
			{!isLoading && product.type === 'SERVICE' && (
				<ServiceDetail
					product={product}
					putProduct={updateLocalProduct}
					updateProductData={getProduct}
				/>
			)}
			{!isLoading && product.type === 'LESSON_PACK' && (
				<LessonPackDetail
					product={product}
					putProduct={updateLocalProduct}
					updateProductData={getProduct}
				/>
			)}
			{!isLoading && product.type === 'AUTOMATIC_INCOME' && (
				<AutomaticIncomeDetail
					product={product}
					putProduct={updateLocalProduct}
					updateProductData={getProduct}
				/>
			)}
			{!isLoading && product.type === 'THEMATIC_BROADCAST' && (
				<ThematicBroadcastDetail
					product={product}
					putProduct={updateLocalProduct}
					updateProductData={getProduct}
				/>
			)}
		</Page>
	);
};

export default ProductDetailPage;
