import { callPOST, callPUT, callDELETE } from 'network/network';

const _ADD_ROLE_TO_USER_PATH = 'v1/users/addrole';
const _REMOVE_ROLE_FROM_USER_PATH = 'v1/users/removerole';
const _COACH_PATH = 'v1/coaches';

export const addRoleToUser = async (userId: number, roleId: number) => {
	try {
		const response = await callPOST(_ADD_ROLE_TO_USER_PATH + '/' + userId + '/' + roleId, {}, true);

		if (response === undefined || !response) {
			throw new Error();
		}

		if (roleId === 23) {
			await addCoach(userId);
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const removeRoleFromUser = async (userId: number, roleId: number) => {
	try {
		const response = await callDELETE(_REMOVE_ROLE_FROM_USER_PATH + '/' + userId + '/' + roleId, {}, true);

		if (response === undefined || !response) {
			throw new Error();
		}

		if (roleId === 23) {
			await removeCoach(userId);
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

const addCoach = async (userId: number) => {
	try {
		const response = await callPOST(_COACH_PATH, { userId: userId }, true);
		
		if (response === undefined || !response) {
			throw new Error();
		}

	} catch (error: any) {
		if (error.response.data.message === ("This user is already a coach")) {
			try {
				const reactivate = await reactivateCoach(userId);
			} catch (error: any) {
				throw error;
			}
		} else {
			throw error;
		}
	}
}

const removeCoach = async (userId: number) => {
	try {
		const response = await callDELETE(_COACH_PATH+"/"+userId, {}, true);

	} catch (error: any) {
		throw error;
	}
}

const reactivateCoach = async (userId: number) => {
	try {
		const response = await callPUT(_COACH_PATH, { userId: userId, deleted: false }, true);
		
		if (response === undefined || !response) {
			throw new Error();
		}

	} catch (error: any) {
		throw error;
	}
}