import { FC, useEffect, useState } from 'react';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';
import { useNavigate } from 'react-router-dom';
import { getBrand } from 'configs/brandMap/brandMap.helper';

//redux
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from 'redux/actions/actions-snackbar';

//types
import { ProductsTableProps } from './ProductsTable.component.d';
import { ProductsTableColumn } from './ProductsTable.component.d';
import { Product } from 'types/Product';
import { LdLFile } from 'types/LdLFile';
import { ProductThematicBroadcast } from 'types/ProductThematicBroadcast';

//utils
import config from 'configs/config';
import { uploadRecordedVideo } from 'pages/ProductDetailPage/ProductTab/ThematicBroadcastLinksTab/services/ThematicBroadcastLinksTab.services';
import { addProduct } from '../services/ProductsPage.services';
import { getProductFromId } from 'services/product.services';
import { removeThematicBroadcastDiscountPrices } from 'pages/ProductDetailPage/PercentagesTab/services/PercentagesTab.services';
import { 
	createWebPageForAProduct, 
	createNewBlock, 
	createNewElement, 
	updateImageIntoElements, 
	updateVideoIntoElements, 
	updateVideoOnSalesData, 
	updateVideoPosterOnSalesData, 
	uploadImageIntoElements } from 'pages/PublicProductPages/services/PublicProductPage.services';

//assets
import emptyPicIconSVG from './assets/emptyPicIcon.svg';
import { ReactComponent as LinkIcon } from './assets/linkIcon.svg';
import DefaultProductPic from './assets/defaultProductPic.png';

//components
import Visible from './Visible';
import RowButtons from './RowButtons';
import Price from './Price';
import EditableInput from 'components/EditableComponents/EditableInput/EditableInput.component';


//style
import { 
	Table,
	TableBody, 
	TableBodyCell, 
	TableBodyRow, 
	TableHead, 
	TableHeadCell, 
	TableHeadRow, 
	SellImage, 
	ProductName, 
	Subscriptions, 
	RedBackText, 
	GreenBackText,
	ThematicBroadcastDoneLink,
} from './style/ProductsTable.style';
import useHasRole from 'hooks/useHasRole';



const url = window.location.origin;
const online = !url.includes('localhost');
const test = url.includes('ldl-test') || !online;

const salesPageBaseUrl = test ? config.testUrl.replace('backoffice.','') : config.productionUrl.replace('backoffice.','');

const ProductsTable: FC<ProductsTableProps> = ({ data, productType, productCategory, putProduct }) => {

	const [columns, setColumns] = useState<ProductsTableColumn[]>([]);
	const [editLinkRowId, setEditLinkRowId] = useState<number | null>(null);
	const [tableData, setTableData] = useState<Product[]>([]);
	
	const dispatch = useAppDispatch();
	const navigate = useNavigate();


	// const productTypeLabel = {
	// 	COURSE: 'CORSO',
	// 	SERVICE: 'SERVIZIO',
	// 	LESSON_PACK: 'PACCHETTO LEZIONI',
	// 	AUTOMATIC_INCOME: 'ENTRATA AUTOMATICA',
	// 	CREDIT_PACK: 'PACCHETTO CREDITI',
	// 	THEMATIC_BROADCAST: 'DIRETTA TEMATICA',
	// };


	const brand = getBrand();
	const isAdmin = useHasRole('ADMIN');
	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');
	const hasAdminRole = isAdmin || isSuperAdmin;

	const handleClickEditLink = (id: number) => {
		setEditLinkRowId(id);
	}
	  
	const updateProductInfo = async (id: string, content: string) => {
		if (id !== 'registration') return;
		if (editLinkRowId === null) return;
		const productRegistrationLink = data.find(row => row.id === editLinkRowId)?.productThematicCall.registrationLink;
		try {
			var uploadedVideo = await uploadRecordedVideo(editLinkRowId, content);
			const oldRow = data.find(row => row.id === editLinkRowId);
			if (!oldRow) return;
			var updatedThematicCall: ProductThematicBroadcast = {
					...oldRow.productThematicCall,
					registrationLink: uploadedVideo as LdLFile,
			};
			if ((oldRow.productThematicCall.type !== 'ESPERIENZA') && (!productRegistrationLink)) {
				updatedThematicCall.status = 'PUBLISHED';
			}
			const updatedProduct: Partial<Product> = {
				id: editLinkRowId,
				productThematicCall: updatedThematicCall as ProductThematicBroadcast,
			};
			await putProduct(updatedProduct);
			setEditLinkRowId(null);
		} catch (error) {
			const snackbarData: SnackbarOptions = {};
			snackbarData.type = 'error';
			snackbarData.message = `C'è stato qualche problema nel salvataggio del video!`;
			dispatch(addSnackbar(snackbarData));
			throw error;
		}
	}

	const fetchImageAsFile = async (imageUrl: string, fileName: string): Promise<File> => {
		const response = await fetch(imageUrl);
		const blob = await response.blob();
		return new File([blob], fileName, { type: blob.type });
	};
	
	const updateVideoPosterFromUrl = async (productId: number, imageUrl: string) => {
		try {
			const fileName = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
			const imageFile = await fetchImageAsFile(imageUrl, fileName);
			return await updateVideoPosterOnSalesData(productId, imageFile);
		} catch (error: any) {
			throw error;
		}
	};

	const duplicateProduct = async (productId: number) => {
		if (!brand) return;
		const product = await getProductFromId(productId, brand.code);
		if (!product) return;
		if (product.type !== 'THEMATIC_BROADCAST') return; // at the moment we can duplicate ONLY thematic broadcasts
		/* eslint-disable no-restricted-globals */
		if (!confirm("Vuoi veramente duplicare il prodotto '"+product.name+"'?")) return;
		const newProduct: Partial<Product> = { name: product.name + " (copia)", visible: false, duplicationIncentiveActive: false };
		newProduct.productThematicCall = { price: 0, discountProtagonistPrice: 0, discountSpectatorPrice: 0, onShop: false, protagonistPrice: 0, spectatorPrice: 0, vip: false, type: product.productThematicCall.type,} as ProductThematicBroadcast;
		try {
			var response = await addProduct(newProduct);
			if ((product?.salesData?.videoCover) || (product?.salesData?.videoSale)) {
				await createWebPageForAProduct(response.name, response.id, false);
				if (product.salesData?.videoSale) {
					await updateVideoOnSalesData(response.id, product.salesData.videoSale.url);
				}
				if (product.salesData?.videoCover) {
					await updateVideoPosterFromUrl(response.id, product.salesData.videoCover.url);
				}
				response = await getProductFromId(response.id, brand.code);
			}
			response.authorsNames = product.authorsNames;
			response.paymentTypes = product.paymentTypes;
			response.productPools = product.productPools;
			response.free = product?.free ? product.free : false;
			response.salesData.delivery = product.salesData.delivery;
			response.salesData.weight = product.salesData.weight;
			response.subCategories = product?.subCategories ? product.subCategories : [];
			response.requisites = product?.requisites ? product.requisites : [];
			
			if (response.productPools.length > 0) {
				const updatedPools = response.productPools.map((poolEntry: any) => {
					if (poolEntry.id.productId === productId) {
					  return {
						...poolEntry,
						id: {
						  ...poolEntry.id,
						  productId: response.id
						}
					  };
					}
					return poolEntry;
				  });
				response.productPools = updatedPools;
			}

			if (response.requisites.length > 0) {
				const updatedRequisites = response.requisites.map((requisite: any) => {
					if (requisite.id.productId === productId) {
					  return {
						...requisite,
						id: {
						  ...requisite.id,
						  productId: response.id
						}
					  };
					}
					return requisite;
				  });
				response.requisites = updatedRequisites;
			}

			if ((product.productThematicCall.type === 'ESPERIENZA') && (product.productThematicCall?.registrationLink?.url)) {
				var uploadedVideo = await uploadRecordedVideo(response.id, product.productThematicCall.registrationLink.url);
				response.productThematicCall.registrationLink = uploadedVideo as LdLFile;
			}

			response.productThematicCall = { 
				...response.productThematicCall, 
				description: product.productThematicCall.description,
				discountProtagonistPrice: product.productThematicCall.discountProtagonistPrice,
				discountSpectatorPrice: product.productThematicCall.discountSpectatorPrice,
				facebookGroupId: product.productThematicCall.facebookGroupId,
				maxProtagonist: product.productThematicCall.maxProtagonist,
				maxSpectator: product.productThematicCall.maxSpectator,
				price: product.productThematicCall.price,
				protagonistPrice: product.productThematicCall.protagonistPrice,
				spectatorPrice: product.productThematicCall.spectatorPrice,
				vip: product.productThematicCall.vip,
				onShop: product.productThematicCall.onShop,
			} as ProductThematicBroadcast;
			await putProduct(response);
			if ((product.productThematicCall.discountProtagonistPrice === null) &&(product.productThematicCall.discountSpectatorPrice === null)) {
				await removeThematicBroadcastDiscountPrices(response.id);
			}
			navigate('/reservedArea/products/' + response.id);
		} catch (error: any) {
			const snackbarData: SnackbarOptions = {};
			snackbarData.type = 'error';
			snackbarData.message = `C'è stato qualche problema nel duplicare il prodotto!`;
			dispatch(addSnackbar(snackbarData));
			throw error;
		}
	}

	useEffect(()=> {
		const baseColumns = [
			{ label: '', width: '3%' },
			{ label: 'ID', width: '2%' },
			{ label: productType !== 'THEMATIC_BROADCAST' ? 'IMMAGINE' : 'DATA', width: '5%' },
			{ label: 'NOME', width: '12%' },
			{ label: productType === 'THEMATIC_BROADCAST' ? productCategory === 'ESPERIENZA' ? 'PREZZO' : 'PREZZO CATALOGO' : 'PREZZO', width: '5%' },
			{ label: productType === 'SERVICE' ? 'ABBONATI' :  'COPIE', width: '3%' },
			// { label: 'METODI PAGAMENTO', width: '5%' },
			// { label: 'PESO', width: '5%' },
			{ label: 'VISIBILE', width: '3%' },
			// { label: 'BRAND', width: '5%' },
			// { label: 'PRODOTTI', width: '4%' },
			// {label:'AUTOSTRADA',width: '1%'},
			// {label:'LEZ. AP',width: '1%'},
			// { label: 'STATS', width: '5%' },
			// { label: 'NOTE', width: '2%' },
		];
		const addAuthorsColumn = (columns: ProductsTableColumn[]): ProductsTableColumn[] => {
			const newColumn: ProductsTableColumn = { label: 'AUTORI', width: '5%' };
			const alreadyInserted = columns.findIndex(col => col.label === 'AUTORI');
			const indexToInsert = columns.findIndex(col => productType === 'THEMATIC_BROADCAST' && col.label === 'VISIBILE');
			if ((indexToInsert !== -1) && (alreadyInserted === -1)) {
				columns.splice(indexToInsert, 0, newColumn);
			}
			return columns;
		};
		const updatedColumns = addAuthorsColumn(baseColumns);
		setColumns(updatedColumns);
	}, [productType]);

	return (
		<>
			<Table>
				<TableHead>
					<TableHeadRow>
						{columns.map((c) => (
							<TableHeadCell
								key={c.label}
								width={c.width}
								// onClick={() => handleSort(key)}
							>
								{c.label.toUpperCase()}
								{/* {sortColumn === c && <span>{sortDirection === 'asc' ? '⬆️' : '⬇️'}</span>} */}
							</TableHeadCell>
						))}
					</TableHeadRow>
				</TableHead>
				<TableBody>
					{data.map((row, index) => (
						<TableBodyRow key={index}>
							<TableBodyCell
								key={index + '_buttons'}
								id={index + '_buttons'}
							>
								<RowButtons 
									productId={row.id} 
									duplicateProduct = {productType === 'THEMATIC_BROADCAST' ? duplicateProduct : undefined}
								/>
							</TableBodyCell>
							<TableBodyCell
								key={index + '_id'}
								id={index + '_id'}
							>
								{row.id}
							</TableBodyCell>

							{productType !== 'THEMATIC_BROADCAST' && <TableBodyCell
								key={index + '_image'}
								id={index + '_image'}
							>
								<SellImage
									alt='-'
									src={row.sellImage?.url ? row.sellImage.url : DefaultProductPic}
								/>
							</TableBodyCell>
							}

							{productType === 'THEMATIC_BROADCAST' && <TableBodyCell
								key={index + '_image'}
								id={index + '_image'}
							>
								{row?.productThematicCall?.liveDate && (
									<>
									{(new Date(row.productThematicCall.liveDate+"Z")).getDate().toString().padStart(2, '0')}/
									{((new Date(row.productThematicCall.liveDate+"Z")).getMonth() + 1).toString().padStart(2, '0')}/
									{(new Date(row.productThematicCall.liveDate+"Z")).getFullYear().toString()}&nbsp;
									{(new Date(row.productThematicCall.liveDate+"Z")).getHours().toString().padStart(2, '0')}:
									{(new Date(row.productThematicCall.liveDate+"Z")).getMinutes().toString().padStart(2, '0')}<br/>
									{
										row.productThematicCall.status === "CREATED" ? "Creata" :
										row.productThematicCall.status === "PUBLISHED" ? (
											<a href={row.productThematicCall.registrationLink?.url} target='_blank' style={{ color: 'inherit', textDecoration: 'none', backgroundColor: 'transparent' }}>
												<GreenBackText>
													{row.productThematicCall.type === "ESPERIENZA" ? 
														(new Date(row.productThematicCall.liveDate+"Z") <= (new Date())) ? 
															(new Date(row.productThematicCall.saleCollectionDate+"Z") >= (new Date())) ? "Iniziata" : "Finita" : "Pubblicata" :
														"Link caricato"
													}
												</GreenBackText>
											</a>) :
										row.productThematicCall.status === "SCHEDULED" ? 
											row.productThematicCall.type === "ESPERIENZA" ? 
												(new Date(row.productThematicCall.liveDate+"Z") <= (new Date())) ? 
												(new Date(row.productThematicCall.saleCollectionDate+"Z") >= (new Date())) ? "Iniziata" : "Finita" : "Pubblicata" :		
										(new Date(row.productThematicCall.liveDate) >= (new Date())) ? "Pubblicata" : 
										<ThematicBroadcastDoneLink>
											{editLinkRowId === row.id ? <>
												<EditableInput
													inputId={'registration'}
													editableInput={''}
													placeholder={"Inserisci link FTP"}
													update={updateProductInfo}
													pencil={false}
													mandatoryToBeCompleted={['.mp4', '.mp3', 'wav']}
												/>
											</>
											 : <>
												<RedBackText onClick={() => handleClickEditLink(row.id)}>Fatta</RedBackText>
											</>
											}
										</ThematicBroadcastDoneLink> :
										""
									}
									</>)
								}
							</TableBodyCell>
							}

							<TableBodyCell
								key={index + '_name'}
								id={index + '_name'}
							>
								<ProductName>
									<a
										target='_blank'
										rel='noreferrer'
										href={`${salesPageBaseUrl}${productType === 'THEMATIC_BROADCAST' ? productCategory === 'ESPERIENZA' ? 'app/public/product-esperienza/' : 'app/public/product-diretta-tematica/' : 'app/public/product/'}${row.id}`}
									>
										<LinkIcon />
									</a>
									{row.name}
								</ProductName>
							</TableBodyCell>
							<TableBodyCell
								key={index + '_price'}
								id={index + '_price'}
							>
								<Price
									product={row}
									putProduct={putProduct}
								/>
							</TableBodyCell>
							<TableBodyCell
								key={index + '_subscriptions'}
								id={index + '_subscriptions'}
							>
								<Subscriptions>{row.subscriptions.toLocaleString()}</Subscriptions>
							</TableBodyCell>
							{/* <TableBodyCell
								key={index + '_paymentMethods'}
								id={index + '_paymentMethods'}
							></TableBodyCell> */}
							{/* <TableBodyCell
								key={index + '_weight'}
								id={index + '_weigth'}
							>
								{row.salesData?.weight}
							</TableBodyCell> */}
							{productType === 'THEMATIC_BROADCAST' && <TableBodyCell
								key={index + '_authors'}
								id={index + '_authors'}
							>
								{row.authorsNames && (
									<>
									{row.authorsNames?.map((author, index) => <>{author}<br/></>)}
									</>)
								}
							</TableBodyCell>
							}
							<TableBodyCell
								key={index + '_visible'}
								id={index + '_visible'}
							>
								<Visible
									productId={row.id}
									visible={row.visible}
									putProduct={putProduct}
								/>
							</TableBodyCell>
							{/* <TableBodyCell
								key={index + '_brands'}
								id={index + '_brands'}
							>
								{row.brands.map((b, i) => (
									<div key={i}>{b.code}</div>
								))}
							</TableBodyCell> */}
						</TableBodyRow>
					))}
				</TableBody>
			</Table>

			{/* {totalElements > 0 && (
				<TableFooter>
					<FooterElementsCount>{footerElementsCount({ currentPage: pageNumber, totalPages, totalElements, pageSize })}</FooterElementsCount>
					<FooterNavigationButton
						onClick={prevPageHandler}
						disabled={pageNumber === 1}
					>
						<ChevronLeftIcon />
					</FooterNavigationButton>
					<FooterNavigationButton
						onClick={nextPageHandler}
						disabled={pageNumber === totalPages}
					>
						<ChevronRightIcon />
					</FooterNavigationButton>
				</TableFooter>
			)} */}
		</>
	);
};

export default ProductsTable;
