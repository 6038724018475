import styled, { css } from 'styled-components';

import colors from 'style/palette/palette';

export const EventSingleButton = styled.button<{ name: string }>`
	display: flex;
	background-color: transparent;
	border: none;
	cursor: pointer;

	> svg {
		height: 15px;
		width: 15px;
	}

	${({ name }) =>
		name &&
		css`
			> svg path {
				fill: ${name === 'modify' ? colors.lightBlue : colors.primaryColor};
				fill-opacity: 1;
			}
		`}
`;
