import { useEffect, useRef, useState } from 'react';

//utils
import { footerElementsCount } from './OldPaymentsTable.helper';

//types
import { tableColumnAttr, OldPaymentsTableItem, OldPaymentsTableProps } from './OldPaymentsTable.component.d';

//assets
import { ReactComponent as ChevronLeftIcon } from './assets/chevronLeft.svg';
import { ReactComponent as ChevronRightIcon } from './assets/chevronRight.svg';
import { ReactComponent as DownArrowIcon } from './assets/downArrowIcon.svg';


//style
import {
    FooterNavigationButton,
	FooterElementsCount,
	Table,
	TableBody,
	TableBodyCell,
	TableBodyRow,
	TableFooter,
	TableHead,
	TableHeadCell,
	TableHeadRow,
	DetailsButton,
	RefundButton,
	DeleteButton,
} from './style/OldPaymentsTable.style';

const OldPaymentsTableColumns: tableColumnAttr[] = [
	{
        name: 'id',
        title: 'Id Pag.',
    },
	{
        name: 'customer',
        title: 'Utente',
		align: 'center',
    },
	{
        name: 'producttype',
        title: 'Tipo Prod.',
		align: 'center',
    },
	{
        name: 'product',
        title: 'Prodotto',
    },
	{
        name: 'paymentdate',
        title: 'Data',
    },
	{
        name: 'paymenttype',
        title: 'Tipo',
		align: 'center',
    },
	{
        name: 'method',
        title: 'Metodo',
		align: 'center',
    },
	{
        name: 'amount',
        title: 'Importo',
		align: 'right',
		format: 'rounded',
    },
    {
        name: 'deadline',
        title: 'Scadenza',
        align: 'center',
    },
	{
        name: 'paypalId',
        title: 'Id Paypal',
		display: 'hidden',
    },
];

type sortParamsType = {
	column: string | null;
	direction: 'asc' | 'desc' | null;
};

const OldPaymentsTable: React.FC<OldPaymentsTableProps> = ({ data, totalPages, totalElements, pageSize, searchOldPayments }) => {
	const [tableData, setTableData] = useState<OldPaymentsTableItem[]>([]);
	const [sortParams, setSortParams] = useState<sortParamsType>({column: null, direction: null});
	const [currentPage, setCurrentPage] = useState(1);
	const [isCopied, setIsCopied] = useState<boolean>(false);
	
	const copyToClipboard = (what: string) => {
		navigator.clipboard.writeText(what);
		setIsCopied(true);
		setTimeout(() => { setIsCopied(false)}, 2000);
	}

	function isStringDate(inputString: string): boolean {
		if (!inputString) return false;
		
		let hasTwoHyphens: boolean   = false; 
		let hasTwoColons: boolean    = false;
		let hasLetterT: boolean      = false;
	
		for(let i = 0; i < inputString.length; i++) {
			if (inputString[i] === '-') {
				hasTwoHyphens = true;
			}
			if (inputString[i] === ':') {
				hasTwoColons = true;
			}
			if (inputString[i] === 'T') {
				hasLetterT = true;
			}
		}
	
		// If all three conditions are met, return true
		if (hasTwoHyphens && hasTwoColons && hasLetterT) {
			return true;
		} 
		return false;
	}

	const isValidDate = (value: string): boolean => {
		if (!isStringDate(value)) return false;
		const dateObj = new Date(value);
		return !Number.isNaN(dateObj.getTime());
	}

	const convertDate = (value: string): string => {
		const dateObj = new Date(value);
  		return dateObj.toLocaleString('IT-it');
	}

	const pageChangeHandler = (page: number) => {
		setCurrentPage(page);
	};

	const sortTable = (items: OldPaymentsTableItem[], sortBy: string | null, direction: 'asc' | 'desc' | null) => {
		if (sortBy === null) return items;
		const sortedItems = [...items].sort((a, b) => {
			if (a[sortBy] < b[sortBy]) {
			  return -1;
			}
			if (a[sortBy] > b[sortBy]) {
			  return 1;
			}
			return 0;
		});
		
		if (direction === 'desc') {
			return sortedItems.reverse();
		}
		
		return sortedItems;
	};

	const handleSort = (key: string) => {
		if (key === sortParams.column) {
			setSortParams({column: key, direction: sortParams.direction === 'asc' ? 'desc' : 'asc'});
		} else {
			setSortParams({column: key, direction: 'desc'});
		}
	};

	useEffect(() => {
		console.log('tableData', tableData);
	}, [tableData]);

	useEffect(() => {
		searchOldPayments(currentPage);
	}, [currentPage]);

	useEffect(() => {
		const sortedTable = sortTable(tableData, sortParams.column, sortParams.direction);
		setTableData(sortedTable);
	}, [sortParams]);

	useEffect(() => {
		const sortedTable = sortTable(data, sortParams.column, sortParams.direction);
		setTableData(sortedTable);
	}, [data]);

    let columns: string[] = [];
	if (tableData.length > 0) columns = Object.keys(tableData[0]);

	return (
		<>
			<Table>
				<TableHead>
					<TableHeadRow>
						{columns.map((c) => (
							<>
							{!(OldPaymentsTableColumns.find(x => x?.name === c)?.display && OldPaymentsTableColumns.find(x => x?.name === c)?.display === 'hidden') &&
							<TableHeadCell
								key={c}
								onClick={() => handleSort(c)}
							>
								{OldPaymentsTableColumns.find(x => x?.name === c)?.title ? OldPaymentsTableColumns.find(x => x?.name === c)?.title?.toUpperCase() : c.toUpperCase()}
								{(sortParams.column === c && (sortParams.direction === 'desc' ? <DownArrowIcon transform="rotate(180 0 0)"/> : <DownArrowIcon />))}
							</TableHeadCell>
							}
							</>
						))}
					</TableHeadRow>
				</TableHead>
				<TableBody>
					{tableData.map((row, index) => (
						<TableBodyRow key={index}>
							{columns.map((c) => {
								const value = row[c as keyof OldPaymentsTableItem];
								return (
									<>
									{!(OldPaymentsTableColumns.find(x => x?.name === c)?.display && OldPaymentsTableColumns.find(x => x?.name === c)?.display === 'hidden') &&
									<TableBodyCell
                                        align={OldPaymentsTableColumns.find(x => x?.name === c)?.align}
										key={c}
										id={c}
										tooltip={isValidDate(value) ? convertDate(value) : (c === 'method' && value === 'PAYPAL') ? isCopied ? "(copiato negli appunti!)" : row['paypalId']+" (clicca per copiare)" : undefined}
										onClick={() => { if (c === 'method' && value === 'PAYPAL') copyToClipboard(row['paypalId'])}}
									>
										{/* {(Object.prototype.toString.call(value) === '[object Date]') ? value.toLocaleString("it-IT").split(",")[0] : value} */}
										{(isValidDate(value)) ? convertDate(value).split(",")[0] : value}
									</TableBodyCell>
									}
									</>
								);
							})}
						</TableBodyRow>
					))}
				</TableBody>
			</Table>
			{totalElements > 0 && (
				<TableFooter>
					<FooterElementsCount>{footerElementsCount({ currentPage, totalPages, totalElements, pageSize })}</FooterElementsCount>
					<FooterNavigationButton
						onClick={() => pageChangeHandler(currentPage - 1)}
						disabled={currentPage === 1}
					>
						<ChevronLeftIcon />
					</FooterNavigationButton>
					<FooterNavigationButton
						onClick={() => pageChangeHandler(currentPage + 1)}
						disabled={currentPage === totalPages}
					>
						<ChevronRightIcon />
					</FooterNavigationButton>
				</TableFooter>
			)}
		</>
	);
};

export default OldPaymentsTable;