import styled from 'styled-components';

import colors from 'style/palette';

export const LessonAttachmentsBox = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

export const Attachments = styled.div`
  display: flex;
  flex-direction: column;
	font-size: 0.9rem;
	color: ${colors.white};
`;

export const AttachDeleteButton = styled.button`
  color: ${colors.white};
  background-color: ${colors.red};
  cursor: pointer;
`

export const AttachmentNewFile = styled.input.attrs({ 
    type: 'file',
  })`
    display: none;
`;

export const AttachNewFileButton = styled.button`
	color: #000000;

  	max-width: 100px;

    border: none;
    border-radius: 4px;
    background-color: ${colors.lightSimpleGrey};

    cursor: pointer;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;