import colors from 'style/palette/palette';
import styled, { css } from 'styled-components';


export const CardContent = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 30px;

    width: 1000px;

    margin: 0;
    padding: 30px;

    border: 1px solid ${colors.primaryColor};
    border-radius: 15px;

    background: ${colors.grey};
    color: ${colors.white};

    div {
        width: 100%;
    }
`

export const RowContent = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 30px;

    width: 100%;
`;

export const ColumnContent = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;
    gap: 30px;

    width: 100%;
`;

export const ButtonRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-end;

    gap: 30px;

    width: 100%;
`;

export const SaveAndCloseButton = styled.button`

    margin: 0;
    padding: 20px;

    border: 1px solid ${colors.primaryColor};
    border-radius: 5px;

    background: ${colors.blueGrey};
    color: ${colors.black087};
    font-size: 15px;
    font-weight: 500;

    cursor: pointer;
`;

export const CloseAndCancel = styled(SaveAndCloseButton)``;

export const DeleteElementButton = styled(SaveAndCloseButton)``;

export const UploadPosterButton = styled(SaveAndCloseButton)`
    margin: 0 25px;
`;

export const UrlInput = styled.input`

    width: 100%;
    margin: 0 0 10px 0;
    
    border-radius: 5px;
    border: 1px solid ${colors.white070};
    background: ${colors.lightGrey};
`;

export const UrlLabel = styled.label`
    width: 100%;
`;

export const ActualPicture = styled.div`
    margin: 0 0 30px 0;
`;
