import styled, { css } from 'styled-components';
import colors from 'style/palette/palette';
import { Link } from 'react-router-dom';
import exp from 'constants';

interface TableBodyCellProps {
	align?: string;
	tooltip?: string;
}

export const Table = styled.table`
	color: ${colors.white};
	border-collapse: collapse;
	width: 100%;
`;

export const TableHead = styled.thead``;

export const TableHeadRow = styled.tr`
	cursor: pointer;
	user-select: none;

	th:not(:nth-last-of-type(1)) {
		border-right: 1px solid ${colors.white012};
	}
`;

export const TableHeadCell = styled.th<{ width?: string }>`
	padding: 10px;
	border-bottom: 1px solid ${colors.white012};

	${({ width }) =>
		width &&
		css`
			width: ${width};
		`}

	> svg {
		margin: 0 0 0 10px;
	}
`;

export const TableBody = styled.tbody``;

export const TableBodyRow = styled.tr`
	&:hover {
		background-color: ${colors.tableRowHover};
		/* td {
			border-bottom: 1px solid ${colors.lightBlue};
		} */
	}
`;

export const TableBodyCell = styled.td<TableBodyCellProps>`
	padding: 10px;
	border-bottom: 1px solid ${colors.white012};
	text-align: ${(props) => props.align};
	position: relative;

	&:hover {
		${(props) =>
			props.tooltip &&
			css`
				&:after {
					content: '${props.tooltip}';
					display: block;
					position: absolute;
					background-color: ${colors.black};
					color: #ffffff;
					padding: 5px;
					border-radius: 4px;
					font-size: 14px;
					top: -30px;
					left: 50%;
					transform: translateX(-50%);
					width: auto;
					z-index: 1000;
					visibility: visible;
					opacity: 1;
					transition: visibility 0s, opacity 0.5s linear;
				}
			`}
	}

	& svg {
		width: 25px;
		height: auto;
	}
`;

export const TableFooter = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	gap: 25px;
	width: 90%;
	padding: 10px;
	margin-top: 10px;
`;

export const FooterElementsCount = styled.div`
	color: ${colors.white};
	font-size: 0.75rem;
	font-weight: 400;
	margin: 0 15px;
`;

export const FooterNavigationButton = styled.button`
	background: none;
	border: none;
	cursor: pointer;
	> svg {
		fill: ${colors.white};
		margin: auto;
	}
	&:disabled {
		cursor: not-allowed;
		> svg {
			fill: ${colors.white009};
		}
	}
`;

export const DetailsButton = styled.button`
	width: 84px;
	height: 24px;

	border-radius: 416px;
	border: 0;
	background-color: ${colors.blue};

	font-weight: 400;
	font-size: 13px;
	color: #ffffff;
	cursor: pointer;

	:disabled {
		background-color: #ffffff1f;
		color: #29353e;
		cursor: not-allowed;
	}
`;

export const RefundButton = styled.button`
	width: 84px;
	height: 24px;

	border-radius: 416px;
	border: 0;
	background-color: ${colors.red};

	font-weight: 400;
	font-size: 13px;
	color: #ffffff;
	cursor: pointer;

	:disabled {
		background-color: #ffffff1f;
		color: #29353e;
		cursor: not-allowed;
	}
`;

export const AssignButton = styled.button`
	width: 84px;
	height: 24px;

	border-radius: 416px;
	border: 0;
	background-color: ${colors.green038};

	font-weight: 400;
	font-size: 13px;
	color: #ffffff;
	cursor: pointer;

	:disabled {
		background-color: #ffffff1f;
		color: #29353e;
		cursor: not-allowed;
	}
`;

export const ConcludeBankButton = styled.button`
	width: 84px;
	height: 24px;

	border-radius: 416px;
	border: 0;
	background-color: ${colors.primaryColor};

	font-weight: 400;
	font-size: 13px;
	color: #ffffff;
	cursor: pointer;

	:disabled {
		background-color: #ffffff1f;
		color: #29353e;
		cursor: not-allowed;
	}
`;

export const Buttons = styled.div`
	display: flex;
	gap: 0.1rem;
`;

export const BankInfo = styled.div`
	display: flex;
`;

export const UserLink = styled(Link)`
	color: ${colors.white};
`;

export const MiniBrandContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	width: 100%;

	& > * {
		flex: 1 0 50%;
	}
`;

export const MiniBrand = styled.img`
	max-width: 21px;
	height: auto;
	margin: 5px;
`;
