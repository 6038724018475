import { FC, useState, useEffect } from 'react';

//hooks
import useHasRole from 'hooks/useHasRole';
import useHasPermission from 'hooks/useHasPermission';

//redux
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from 'redux/actions/actions-snackbar';

//types
import { UserDetailPageProps } from './UserDetailPage.component.d';
import { TitleBreadCrumbSettings } from 'components/DashboardTitleSection/DashboardTitleSection.component.d';
import { User } from 'types/User';

//hooks
import { useParams } from 'react-router-dom';
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsMounted } from 'usehooks-ts';

//components
import DashboardTitleSection from 'components/DashboardTitleSection';
import BaseFieldset from 'components/ui/BaseFieldset';
import GenericInfo from './GenericInfo';
import Badges from 'pages/UsersPage/UsersTable/Badges';
import Missions from 'pages/UsersPage/UsersTable/Missions';
import Author from './Author';
import Coach from './Coach';
import Federateds from './Federateds';
import Payments from './Payments';
import Points from './Points';
import VipPathLevel from './VipPathLevel';
import Roles from './Roles';
import ProductSubscriptions from './ProductSubscriptions';
import AssignProductModal from './AssignProductModal';
import ResultsModal from './ResultsModal';

//assets
import { ReactComponent as AddBoxIcon } from './assets/addBoxIcon.svg';
import { ReactComponent as EarningsIcon } from './assets/earnings.svg';

//style
import { BaseContentCard } from 'style/wrappers/wrappers';
import { AddProductButton, MainBox, Page, RowBox, Earnings } from './style/UserDetailPage.component.style';

//utils
import { getSingleUserFromId } from './services/UserDetail.services';

const UserDetailPage: FC<UserDetailPageProps> = () => {
	const [user, setUser] = useState<User>({} as User);
	const [subPath, setSubPath] = useState<string | undefined>('');
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isAssignProductModalVisible, setIsAssignProductModalVisible] = useState(false);
	const [isResultsModalVisible, setIsResultsModalVisible] = useState<boolean>(false);

	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isAdmin = useHasRole('ADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');
	const isAmministrazione = useHasRole('CONTABILITÀ');
	const isAuthor = useHasRole('AUTORE');
	const isPersonalTrainer = useHasRole('PERSONAL TRAINER');
	const isCoach = useHasRole('COACH');
	const isSupervisoreAutostrada = useHasRole('SUPERVISORE_AUTOSTRADA');
	const isAdminAutostrada = useHasRole('ADMIN_AUTOSTRADA');
	const isAssistentePersonale = useHasRole('ASSISTENTE-PERSONALE');

	const canEditUser = isSuperAdmin || isAdmin || isMiniAdmin || isAdminAutostrada || isSupervisoreAutostrada;

	const { userId } = useParams();
	const numericId: number = userId ? +userId : 0;
	const dispatch = useAppDispatch();
	const isMounted = useIsMounted();

	const onAssignProductClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
		setIsAssignProductModalVisible(true);
	};

	const closeAssignProductModal = () => {
		setIsAssignProductModalVisible(false);
	};

	const openResultsModal = () => {
		setIsResultsModalVisible(true);
	}

	const closeResultsModal = () => {
		setIsResultsModalVisible(false);
	}

	const getUser = async (numericId: number) => {
		const snackbarData: SnackbarOptions = {};

		try {
			const response = await getSingleUserFromId(numericId);
			setUser(response);
			console.log(response);

			setIsLoading(false);
		} catch (error: any) {
			snackbarData.type = 'error';
			snackbarData.message = 'Errore nella ricerca degli utenti';
			dispatch(addSnackbar(snackbarData));
			if (!isMounted()) return;
		}
	};

	const updateLocalUser = () => {
		getUser(numericId);
	};

	useEffect(() => {
		getUser(numericId);
	}, [numericId]);

	useEffect(() => {
		if (user) setSubPath((subPath) => userId?.toString() + ' | ' + user?.name + ' ' + user?.surname);
	}, [user, userId]);

	const breadCrumbSettings: TitleBreadCrumbSettings = {
		subSectionText: 'Gestione Utenti',
		subSubSectionText: subPath,
		subSectionLink: '/reservedArea/users',
	};

	if (!isSuperAdmin && !isAdmin && !isAmministrazione && !isMiniAdmin && !isAuthor && !isPersonalTrainer && !isCoach && !isAdminAutostrada && !isSupervisoreAutostrada && !isAssistentePersonale) return (<></>);

	return (
		<Page>
			<DashboardTitleSection breadCrumbSettings={breadCrumbSettings}></DashboardTitleSection>
			<BaseContentCard>
				{isLoading && <>Loader</>}
				{!isLoading && (
					<>
						<MainBox>
							<RowBox>
								<BaseFieldset legend='ANAGRAFICA'>
									<GenericInfo user={user}></GenericInfo>
								</BaseFieldset>
								<BaseFieldset legend='FEDERATED IDs'>
									<Federateds federateds={user.federateds}></Federateds>
								</BaseFieldset>
							</RowBox>
							<RowBox>
								<BaseFieldset legend='MISSIONI'>
									<Missions
										userId={numericId}
										missions={user.missions}
									></Missions>
								</BaseFieldset>
								<BaseFieldset legend='BADGE'>
									<Badges
										userId={numericId}
										badges={user.badges}
									></Badges>
								</BaseFieldset>
								<BaseFieldset legend='PUNTI'>
									<Points
										user={user}
									></Points>
								</BaseFieldset>
								<BaseFieldset legend='TRAGUARDO'>
									<VipPathLevel
										user={user}
									></VipPathLevel>
								</BaseFieldset>
								<BaseFieldset legend='RISULTATI'>
									<Earnings>
										<EarningsIcon
											onClick={() => openResultsModal()}
										/>
									</Earnings>
								</BaseFieldset>
							</RowBox>
							<BaseFieldset legend='RUOLI'>
								<Roles user={user} updateLocalUser={updateLocalUser}></Roles>
							</BaseFieldset>
							{user.roles.some((role) => role.role === "COACH") && <BaseFieldset legend='COACH'>
								<Coach 
									user={user}
									updateLocalUser={updateLocalUser}	
								></Coach>
							</BaseFieldset>}
							{ canEditUser && <AddProductButton onClick={onAssignProductClickHandler}>
								<AddBoxIcon /> ASSEGNA PRODOTTO
							</AddProductButton>}
							<BaseFieldset legend='CORSI'>
								<ProductSubscriptions
									updateLocalUser={updateLocalUser}
									productSubscriptions={user.productSubscriptions?.filter((p) => p.product.type === 'COURSE')}
								></ProductSubscriptions>
							</BaseFieldset>
							<BaseFieldset legend='SERVIZI'>
								<ProductSubscriptions
									updateLocalUser={updateLocalUser}
									productSubscriptions={user.productSubscriptions?.filter((p) => p.product.type === 'SERVICE')}
								></ProductSubscriptions>
							</BaseFieldset>
							<BaseFieldset legend='LEZIONI'>
								<ProductSubscriptions
									updateLocalUser={updateLocalUser}
									productSubscriptions={user.productSubscriptions?.filter((p) => p.product.type === 'LESSON_PACK')}
								></ProductSubscriptions>
							</BaseFieldset>
							<BaseFieldset legend='DIRETTE TEMATICHE'>
								<ProductSubscriptions
									updateLocalUser={updateLocalUser}
									productSubscriptions={user.productSubscriptions?.filter((p) => p.product.type === 'THEMATIC_BROADCAST' && p.product.productThematicCall.type !== 'ESPERIENZA')}
								></ProductSubscriptions>
							</BaseFieldset>
							<BaseFieldset legend='ESPERIENZE'>
								<ProductSubscriptions
									updateLocalUser={updateLocalUser}
									productSubscriptions={user.productSubscriptions?.filter((p) => p.product.type === 'THEMATIC_BROADCAST' && p.product.productThematicCall.type === 'ESPERIENZA')}
								></ProductSubscriptions>
							</BaseFieldset>
							{/* <BaseFieldset legend='PUNTI'>
								<Points></Points>
							</BaseFieldset>
							<BaseFieldset legend='AUTORE'>
								<Author></Author>
							</BaseFieldset>
							<BaseFieldset legend='PAGAMENTI'>
								<Payments></Payments>
							</BaseFieldset> */}
						</MainBox>
					</>
				)}
			</BaseContentCard>
			{isAssignProductModalVisible && (
				<AssignProductModal
					onCloseButtonModal={closeAssignProductModal}
					user={user}
					updateLocalUser={updateLocalUser}
				/>
			)}
			{isResultsModalVisible && (
				<ResultsModal
					onCloseButtonModal={closeResultsModal}
					userId={user.id}
					userName={user.name+" "+user.surname}
				/>
			)}
		</Page>
	);
};

export default UserDetailPage;
