import { useEffect, useState } from 'react';

// hooks
import useAppSelector from 'hooks/useAppSelector';

/**
 *
 * @param {*} requiredRole
 * @returns
 */
const useHasRole = (requiredRole: string) => {
	const [isVisible, setIsVisible] = useState(false);
	const { roles } = useAppSelector((state) => state.user);
	// const { brand } = useAppSelector(state => state.ui);

	useEffect(() => {
		// console.log('Checking role');
		if (roles === undefined) return;

		let hasRole = true;

		// console.log('Required role', requiredRole);
		// console.log("Required domain", brand);

		let userRole = roles.filter((el) => el.role === requiredRole);

		// console.log('userRole', userRole);

		if (userRole.length < 1) hasRole = false;

		setIsVisible(hasRole);
	}, [requiredRole, roles]);

	return isVisible;
};

export default useHasRole;
