import colors from 'style/palette';
import styled from 'styled-components';

export const Box = styled.div`
	display: flex;
	flex-direction: column;
`;

export const TxsTable = styled.table``;

export const TableFooter = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	gap: 25px;
	width: 90%;
	padding: 10px;
	margin-top: 10px;
`;

export const FooterElementsCount = styled.div`
	color: ${colors.white};
	font-size: 0.75rem;
	font-weight: 400;
	margin: 0 15px;
`;

export const FooterNavigationButton = styled.button`
	background: none;
	border: none;
	cursor: pointer;
	> svg {
		fill: ${colors.white};
		margin: auto;
	}
	&:disabled {
		cursor: not-allowed;
		> svg {
			fill: ${colors.white009};
		}
	}
`;
