import { FC, useEffect, useState } from 'react';

//hooks
import useAppSelector from 'hooks/useAppSelector';
import useHasRole from 'hooks/useHasRole';

//types
import { PointsPageProps } from './PointsPage.component.d';
import { TitleBreadCrumbSettings } from 'components/DashboardTitleSection/DashboardTitleSection.component.d';
import { Pool } from 'types/Pool';

//components
import BaseFieldset from 'components/ui/BaseFieldset';
import DashboardTitleSection from 'components/DashboardTitleSection';
import { BaseContentCard } from 'style/wrappers/wrappers';
import PoolsTable from './PoolsTable';

//utils
import { getPoolsBalance, getSelfPools, getSinglePool, getSinglePoolTransactions } from './services/PointsPage.services';

//style
import { MainBox, Page } from './style/PointsPage.component.style';
import PoolsMovement from './PoolsMovement';
import { Row } from 'style/layout';
import PoolTransactions from './PoolTransactions';
import { PoolTransaction } from 'types/PoolTransaction';
import PointsWithdraw from './PointsWithdraw';
import useHasPermission from 'hooks/useHasPermission';

const PointsPage: FC = () => {
	const [allPoolsBalance, setAllPoolsBalance] = useState(0);
	const [circulatingBalance, setCirculatingBalance] = useState(0);

	const [selfPools, setSelfPools] = useState<Pool[]>([]);
	const [selectedSelfPoolId, setSelectedSelfPoolId] = useState(-1);

	const authorPools = useAppSelector((state) => state.global.authorPools);

	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isAdmin = useHasRole('ADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');
	const isAmministrazione = useHasRole('CONTABILITÀ');
	const isAuthor = useHasRole('AUTORE');
	const isAssistentePersonale = useHasRole('ASSISTENTE-PERSONALE');
	const isPersonalTrainer = useHasRole('PERSONAL TRAINER');
	const isCoach = useHasRole('COACH');
	const isSuperVisoreAutostrada = useHasRole('SUPERVISORE_AUTOSTRADA');
	const isAdminAutostrada = useHasRole('ADMIN_AUTOSTRADA');

	const canWithdrawIva = useHasPermission('WITHDRAW_IVA');
	const canWithdrawRitenuta = useHasPermission('WITHDRAW_RIT');
	const canWithdrawSpagna = useHasPermission('WITHDRAW_SPA');
	const canMovePoints = useHasPermission('MOVE_CREDITS');
	const canMoveMyPoints = useHasPermission('MOVE_MY_CREDITS');

	const getUserSelfPools = async () => {
		try {
			const response = await getSelfPools();
			setSelfPools(response);
		} catch (error) {}
	};

	const getAllPoolsBalance = async () => {
		try {
			const response = await getPoolsBalance();
			setAllPoolsBalance(+response);
		} catch (error) {}
	};

	const getCirculatingBalance = async () => {
		try {
			const url = window.location.origin;
			const online = !url.includes('localhost');
			const test = url.includes('ldl-test') || !online;
			const response = await getSinglePool(test ? 598 : 611);

			setCirculatingBalance(+response.balance);
		} catch (error) {}
	};

	useEffect(() => {
		getUserSelfPools();
		getAllPoolsBalance();
		getCirculatingBalance();
	}, []);

	const breadCrumbSettings: TitleBreadCrumbSettings = { subSectionText: 'Punti Libertà' };
	const canWithdraw = canWithdrawIva || canWithdrawRitenuta || canWithdrawSpagna;

	if (
		!isSuperAdmin &&
		!isAdmin &&
		!isMiniAdmin &&
		!isAmministrazione &&
		!isAuthor &&
		!isAssistentePersonale &&
		!isPersonalTrainer &&
		!isCoach && 
		!isSuperVisoreAutostrada &&
		!isAdminAutostrada
	)
		return <></>;

	return (
		<Page>
			<DashboardTitleSection breadCrumbSettings={breadCrumbSettings}></DashboardTitleSection>
			<BaseContentCard>
				<MainBox>
					{isSuperAdmin && (
						<BaseFieldset legend='QUADRATURA'>
							<div>
								<label>SALDO TOT: </label>
								<span>{allPoolsBalance.toLocaleString()}</span>
							</div>
							<div>
								<label>CIRCOLANTE: </label>
								<span>{circulatingBalance.toLocaleString()}</span>
							</div>
						</BaseFieldset>
					)}
					{/* <Row>
						<BaseFieldset legend='TOP 10 POOL'></BaseFieldset>
						<BaseFieldset legend='POOL IN NEGATIVO'></BaseFieldset>
					</Row> */}
					{/* <BaseFieldset legend='RICERCA POOL'>
						RICERCA UTENTI E TABELLA POOLS, SE NON CERCO X UTENTE METTO LE POOLS PAGINATE IN ORDINE DI SALDO
					</BaseFieldset> */}

					{isSuperAdmin && (
						<BaseFieldset legend='SPOSTA PUNTI'>
							<PoolsMovement myPools={authorPools ? authorPools : []} />
						</BaseFieldset>
					)}

					{!isSuperAdmin && canMoveMyPoints && (
						<BaseFieldset legend='SPOSTA PUNTI'>
							<PoolsMovement myPools={selfPools ? selfPools : []} />
						</BaseFieldset>
					)}

					<BaseFieldset legend='LE MIE POOL'>
						<Row crossAxis='flex-start'>
							<PoolsTable
								pools={selfPools}
								selectedSelfPoolId={selectedSelfPoolId}
								setSelectedSelfPoolId={setSelectedSelfPoolId}
							/>
							{selectedSelfPoolId !== -1 && <PoolTransactions selectedSelfPoolId={selectedSelfPoolId} />}
						</Row>
					</BaseFieldset>
					{canWithdraw && (
						<BaseFieldset legend='PRELIEVO'>
							<PointsWithdraw
								pool={selfPools.find((p) => p.type === 'PERSONAL')}
								getUserSelfPools={getUserSelfPools}
							></PointsWithdraw>
						</BaseFieldset>
					)}
				</MainBox>
			</BaseContentCard>
		</Page>
	);
};

export default PointsPage;
