import { callGET } from 'network/network';

const _POOLS_TRANSACTIONS_PATH = 'v1/pools/transactions';

export const getPoolsTransactions = async (types: string[], from?: string, to?: string, page?: number, size?: number, sort?: string[]) => {
	try {
		const params = { types, from, to, page, size, sort };
		const response = await callGET(_POOLS_TRANSACTIONS_PATH, true, params);

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};
