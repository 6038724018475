import { FC, useEffect, useState } from 'react';

// hooks
import useAppSelector from 'hooks/useAppSelector';
import { useNavigate } from 'react-router-dom';

//utils
import { formattedCurrency } from 'utils/supportFunctions';

//assets
import { ReactComponent as PriceSelectorIconOpenSVG } from './assets/dropdownIcon.svg';
import { ReactComponent as PriceSelectorIconClosedSVG } from './assets/dropupIcon.svg';
import { ReactComponent as LibertyPointIconSVG } from './assets/libertyPointIcon.svg';
import { ReactComponent as PaypalIconSVG } from './assets/paypalIcon.svg';
import { ReactComponent as BankIconSVG } from './assets/bankIcon.svg';
import { ReactComponent as PresentIconSVG } from './assets/presentIcon.svg';

//style
import {
	ButtonBox,
	ProductPriceBox,
	ProductAlternativePaymentGlobalBox,
	ProductAlternativePaymentBox,
	ProductCourseButton,
	ProductPriceSelectorBox,
	ProductAlternativePriceBox,
	ProductPriceTimingText,
	ProductPriceText,
	PresentText,
} from './style/ButtonShop.style';
import { Product } from 'types/Product';

/**
 * Button Shop
 *
 * @author   Piero Foschi <19pfox81@gmail.com>
 *
 * @returns JSX
 */

const ButtonShop: FC<{
	product: Product;
}> = ({ product }) => {
	const [typePaymentTextButton, setTypePaymentTextButton] = useState<string>('Euro');
	const [isAnnualActive, setIsAnnualActive] = useState(false);
	const [priceReturnFromBackend, setPriceReturnFromBackend] = useState<number>();

	const { productService, productCourse } = product;

	const paymentPlan = productService?.paymentPlan;
	const trialMonths = paymentPlan?.trialMonths;
	const trialPrice = paymentPlan?.trialPrice;
	const moreMonths: number = paymentPlan?.frequency;

	useEffect(() => {
		if (product.type === 'COURSE') {
			setTypePaymentTextButton('Euro');
		}
	}, [product.type]);

	const isTrial = trialMonths !== 0;
	const isAnnual = isAnnualActive;
	const isPackMonths = moreMonths > 1;
	const isSingolMonth = !isAnnual && !isTrial && !isPackMonths;
	const finalServicePrice = priceReturnFromBackend ? priceReturnFromBackend : paymentPlan ? paymentPlan.price : 0;
	const isPresent = paymentPlan?.price === 0 || productCourse?.price === 0;

	return (
		<ButtonBox isPresentStyle={isPresent}>
			<ProductPriceBox>
				<ProductCourseButton>
					{product.type === 'SERVICE' && (
						<ProductPriceText>
							{isTrial && (
								<>
									€ {trialPrice}
									<br />
									<ProductPriceTimingText>per {trialMonths} mesi</ProductPriceTimingText>
								</>
							)}

							{isPackMonths && !isAnnual && (
								<>
									€ {formattedCurrency(finalServicePrice)}
									<br />
									<ProductPriceTimingText>ogni {moreMonths} mesi</ProductPriceTimingText>
								</>
							)}

							{isPackMonths && isAnnual && (
								<>
									€ {formattedCurrency(finalServicePrice * 12)} all'anno
									<br />
									<ProductPriceTimingText>
										anzichè {formattedCurrency(finalServicePrice)} ogni {moreMonths} mesi
									</ProductPriceTimingText>
								</>
							)}

							{isAnnual && !isPackMonths && (
								<>
									€ {formattedCurrency(finalServicePrice * 12)} all'anno
									<br />
									<ProductPriceTimingText>anzichè {formattedCurrency(finalServicePrice)} al mese</ProductPriceTimingText>
								</>
							)}

							{isSingolMonth && (
								<>
									€ {formattedCurrency(finalServicePrice)}
									<br />
									<ProductPriceTimingText>al mese</ProductPriceTimingText>
								</>
							)}
						</ProductPriceText>
					)}

					{product.type === 'COURSE' && !isPresent && `€ ${formattedCurrency(product.productCourse.price)}`}

					{isPresent && (
						<PresentText>
							<PresentIconSVG /> IN REGALO
						</PresentText>
					)}
				</ProductCourseButton>
			</ProductPriceBox>
		</ButtonBox>
	);
};

export default ButtonShop;
