import React, { FC, useEffect, useState, useRef, } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsMounted } from 'usehooks-ts';

//types
import { LocalPool, PercentagesTabProps } from './PercentagesTab.component.d';
import { StandardPool } from 'types/StandardPool';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { Product } from 'types/Product';

//components
import BaseFieldset from 'components/ui/BaseFieldset';
import PoolRow from './PoolRow';
import { Pool } from 'types/Pool';
import IsVipBroadcast from '../IsVipBroadcast';
import StyledRadioButton from 'components/StyledRadioButton/StyledRadioButton.component';

//utils
import { getStandardPools, removeThematicBroadcastDiscountPrices } from './services/PercentagesTab.services';
import { roundTwoDigits } from 'utils/formatNumbers';
import { arrayOfObjectEquals } from './PercentagesTab.helper';
import { getProductPrice, setProductPriceAttributes } from 'pages/ProductsPage/ProductsPage.helper';

//assets
import { ReactComponent as ExpandMoreIcon } from './assets/expandMoreIcon.svg';
import { ReactComponent as ExpandLessIcon } from './assets/expandLessIcon.svg';
import { ReactComponent as PersonAddIcon } from './assets/personAddIcon.svg';
import { ReactComponent as VipIcon } from './assets/vipIcon.svg';
import { ReactComponent as LibertyIcon } from './assets/libertyIcon.svg';

//style
import {
	AddAuthorPoolButton,
	ExpandButton,
	MainBox,
	PercentagesForm,
	PriceBox,
	PriceInput,
	PriceLabel,
	PriceLabelwGap,
	SaveButton,
	SubTotalBox,
	SubTotalInput,
	SubTotalLabel,
	PriceRowBox,
	PriceCol,
	PriceRow,
	WarningBox,
	// PriceSel,
} from './style/PercentagesTab.component.style';
import { PoolRowBox, StandarPoolsBox } from './PoolRow/style/PoolRow.component.style';
import { Col, Row } from 'style/layout';
import { setSelectionRange } from '@testing-library/user-event/dist/utils';

const _FEE_PERC = 4;
const _VAT_PERC = 22;
const _LIB_POINT_EUR = 1.22;

const PercentagesTab: FC<PercentagesTabProps> = ({ product, putProduct }) => {
	//remote product pools
	const [remoteAuthorPools, setRemoteAuthorPools] = useState<LocalPool[]>([]);
	const [remoteProductPools, setRemoteProductPools] = useState<LocalPool[]>([]);
	const [remoteProductLessonPackPools, setRemoteProductLessonPackPools] = useState<LocalPool[]>([]);

	//remote price
	const [remotePrice, setRemotePrice] = useState<number | null>(0);

	//local pools
	const [localStandardPools, setLocalStandardPools] = useState<LocalPool[]>([]);
	const [localAuthorPools, setLocalAuthorPools] = useState<LocalPool[]>([]);
	const [localIncomePool, setLocalIncomePool] = useState<LocalPool>({} as LocalPool);

	const [localProductLessonPackPools, setLocalProductLessonPackPools] = useState<LocalPool[]>([]);

	// const { value: incomeValue, net2Perc: incomePerc } = localIncomePool;

	//standard pools array
	const [standardPools, setStandardPools] = useState<StandardPool[]>([]);

	//subtotal values
	const [localPrice, setLocalPrice] = useState<number>(0);
	const [localPriceString, setLocalPriceString] = useState("0");
	const [feesValue, setFeesValue] = useState(0);
	const [vatValue, setVatValue] = useState(0);
	const [net1, setNet1] = useState(0);
	const [standardPoolsValue, setStandardPoolsValue] = useState(0);
	const [net2, setNet2] = useState(0);
	const [authorsValue, setAuthorsValue] = useState(0);
	const [authorsPerc, setAuthorsPerc] = useState(0);

	//ThematicBroadcast prices
	const [selectedPrice, setSelectedPrice] = useState('thematicbroadcastprice');
	const [thematicBroadcastLocalPrice, setThematicBroadcastLocalPrice] = useState<number>(0);
	const [thematicBroadcastLocalPriceString, setThematicBroadcastLocalPriceString] = useState<string>("0");
	const [thematicBroadcastLocalProtagonistPrice, setThematicBroadcastLocalProtagonistPrice] = useState<number>(0);
	const [thematicBroadcastLocalProtagonistPriceString, setThematicBroadcastLocalProtagonistPriceString] = useState("0");
	const [thematicBroadcastLocalDiscountProtagonistPrice, setThematicBroadcastLocalDiscountProtagonistPrice] = useState(0);
	const [thematicBroadcastLocalDiscountProtagonistPriceString, setThematicBroadcastLocalDiscountProtagonistPriceString] = useState("0");
	const [thematicBroadcastLocalSpectatorPrice, setThematicBroadcastLocalSpectatorPrice] = useState<number>(0);
	const [thematicBroadcastLocalSpectatorPriceString, setThematicBroadcastLocalSpectatorPriceString] = useState<string>("0");
	const [thematicBroadcastLocalDiscountSpectatorPrice, setThematicBroadcastLocalDiscountSpectatorPrice] = useState(0);
	const [thematicBroadcastLocalDiscountSpectatorPriceString, setThematicBroadcastLocalDiscountSpectatorPriceString] = useState("0");

	//subtotal percentages
	const [origStandardPoolsPerc, setOrigStandardPoolsPerc] = useState(0);
	const [standardPoolsPerc, setStandardPoolsPerc] = useState(0);

	//booleans
	const [isStandardCollapsed, setIsStandardCollapsed] = useState(true);
	const [isModified, setIsModified] = useState(false);
	const [authorsAreModified, setAuthorsAreModified] = useState(false);
	const [hasDiscount, setHasDiscount] = useState(false);
	const [isFree, setIsFree] = useState(false);
	const [isComputingPool, setIsComputingPools] = useState(false);

	//options for author pools
	const authorPools = useAppSelector((state) => state.global.authorPools);

	const standardPoolsDetailRef = useRef();
	const dispatch = useAppDispatch();
	const isMounted = useIsMounted();

	//save product
	const saveProductPoolsHandler = async () => {
		const snackbarData: SnackbarOptions = {};

		if ((product.type === 'THEMATIC_BROADCAST') && 
			(
				((product?.productThematicCall?.maxProtagonist !== null) && (product?.productThematicCall?.maxProtagonist > 0)) || // with n protagonists
				(product?.productThematicCall?.maxProtagonist === null) // with unlimited protagonists
			) &&
			(
				((product?.productThematicCall?.maxSpectator !== null) && (product?.productThematicCall?.maxSpectator > 0)) || // with n spectators
				 (product?.productThematicCall?.maxSpectator === null) // with unlimited spectators
			) && 
				(thematicBroadcastLocalProtagonistPrice < thematicBroadcastLocalSpectatorPrice)) {
				snackbarData.type = 'error';
				snackbarData.message = "Il prezzo protagonista deve essere superiore o uguale al prezzo spettatore";
				dispatch(addSnackbar(snackbarData));
				return;
		}

		if ((product.type === 'THEMATIC_BROADCAST') && 
			(
				((product?.productThematicCall?.maxProtagonist !== null) && (product?.productThematicCall?.maxProtagonist > 0)) || // with n protagonists
				(product?.productThematicCall?.maxProtagonist === null) // with unlimited protagonists
			) &&
			(thematicBroadcastLocalProtagonistPrice < thematicBroadcastLocalDiscountProtagonistPrice*_LIB_POINT_EUR)) {
				snackbarData.type = 'error';
				snackbarData.message = "Il prezzo lancio protagonista deve essere inferiore o uguale al prezzo protagonista";
				dispatch(addSnackbar(snackbarData));
				return;
		}

		if ((product.type === 'THEMATIC_BROADCAST') && 
		(
			((product?.productThematicCall?.maxSpectator !== null) && (product?.productThematicCall?.maxSpectator > 0)) || // with n spectators
			(product?.productThematicCall?.maxSpectator === null) // with unlimited spectators
		) &&
		(thematicBroadcastLocalSpectatorPrice < thematicBroadcastLocalDiscountSpectatorPrice*_LIB_POINT_EUR)) {
			snackbarData.type = 'error';
			snackbarData.message = "Il prezzo lancio spettatore deve essere inferiore o uguale al prezzo spettatore";
			dispatch(addSnackbar(snackbarData));
			return;
		}

		if (window.confirm('Sei sicuro di voler aggiornare i dati inseriti in questa scheda?')) {
			let updatedProduct: Product = { id: product.id } as Product;
			const newPools = localStandardPools.concat(localAuthorPools, localIncomePool);
			console.log(newPools);
			updatedProduct.productPools = newPools.map((p) => {
				return {
					id: { productId: product.id, poolId: p.id },
					perc: roundTwoDigits(p.net1Perc),
					pool: p.originalPool,
				};
			});
			if (product.type !== 'THEMATIC_BROADCAST') {
				if (localPrice !== remotePrice) updatedProduct = { ...updatedProduct, ...setProductPriceAttributes(product, localPrice) };
			}
			if (product.type === 'THEMATIC_BROADCAST') {
				updatedProduct = { ...updatedProduct, 
					productThematicCall: { 
						...product.productThematicCall, 
						price: thematicBroadcastLocalPrice,
						protagonistPrice: thematicBroadcastLocalProtagonistPrice,
						discountProtagonistPrice: hasDiscount ? thematicBroadcastLocalDiscountProtagonistPrice : null,
						spectatorPrice: thematicBroadcastLocalSpectatorPrice,
						discountSpectatorPrice: hasDiscount ? thematicBroadcastLocalDiscountSpectatorPrice : null
					}
				}
			}
			if (product.productLessonPack)
				updatedProduct.productLessonPack.productLessonPackPools = localProductLessonPackPools.map((p) => {
					return {
						id: { productId: product.id, poolId: p.id },
						perc: roundTwoDigits(p.net1Perc),
						pool: p.originalPool,
					};
				});

			try {
				await putProduct(updatedProduct);
				snackbarData.type = 'success';
				snackbarData.message = 'Dati prodotto aggiornati correttamente';
				dispatch(addSnackbar(snackbarData));
				setIsModified(false);
				if (!isMounted()) return;
			} catch (error) {
				snackbarData.type = 'error';
				snackbarData.message = "Errore nell'aggiornamento dati prodotto";
				dispatch(addSnackbar(snackbarData));
				if (!isMounted()) return;
			}
		}
	};

	const checkThematicBroadcastForCreated = () => {
		if (product.type !== 'THEMATIC_BROADCAST') return false;
		if (product.productThematicCall.status === 'CREATED') return true;
	}

	const checkThematicBroadcastForScheduled = () => {
		if (product.type !== 'THEMATIC_BROADCAST') return false;
		if (product.productThematicCall.status === 'SCHEDULED') return true;
	}

	const checkThematicBroadcastForPublished = () => {
		if (product.type !== 'THEMATIC_BROADCAST') return false;
		if (product.productThematicCall.status === 'PUBLISHED') return true;
	}

	const handleCheckboxIsFreeChange = () => {
		// eslint-disable-next-line no-restricted-globals
		if (!isFree && (!confirm('Vuoi veramente AZZERARE i prezzi della '+(product.productThematicCall.type === 'ESPERIENZA' ? 'esperienza' : 'diretta')+' ??? In tal caso perderai eventuali prezzi impostati in precedenza.'))) {
			return;
		}
		if (!isFree) {
			if (product.type !== 'THEMATIC_BROADCAST') {
				setLocalPrice(0);
				setLocalPriceString("0");
			}
			if (product.type === 'THEMATIC_BROADCAST') {
				setThematicBroadcastLocalPrice(0);
				setThematicBroadcastLocalPriceString("0");
				if (!checkThematicBroadcastForPublished()) {
					setThematicBroadcastLocalProtagonistPrice(0);
					setThematicBroadcastLocalProtagonistPriceString("0");
					setThematicBroadcastLocalDiscountProtagonistPrice(0);
					setThematicBroadcastLocalDiscountProtagonistPriceString("0");
					setThematicBroadcastLocalSpectatorPrice(0);
					setThematicBroadcastLocalSpectatorPriceString("0");
					setThematicBroadcastLocalDiscountSpectatorPrice(0);
					setThematicBroadcastLocalDiscountSpectatorPriceString("0");
				}
			}
		}
		setIsFree(!isFree);
	}

	const handleCheckboxHasDiscountChange = async () => {
		// eslint-disable-next-line no-restricted-globals
		if (hasDiscount && (!confirm('Vuoi veramente DISATTIVARE i prezzi lancio della diretta ??? In tal caso verranno azzerati eventuali prezzi impostati in precedenza.'))) {
			return;
		}
		if (hasDiscount) {
			setIsModified(product.productThematicCall.discountProtagonistPrice !== 0);
			setIsModified(product.productThematicCall.discountSpectatorPrice !== 0);
			setThematicBroadcastLocalDiscountProtagonistPrice(0);
			setThematicBroadcastLocalDiscountProtagonistPriceString("0");
			setThematicBroadcastLocalDiscountSpectatorPrice(0);
			setThematicBroadcastLocalDiscountSpectatorPriceString("0");
			setSelectedPrice('thematicbroadcastprice');
			const response = await removeThematicBroadcastDiscountPrices(product.id);
		}
		setHasDiscount(!hasDiscount);
	}

	const handleSelectedPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedPrice(event.target.value);
	}

	const priceFocusHanlder = (event: React.ChangeEvent<HTMLInputElement>) => {
		const priceId = event.target.id;
		if ((priceId === 'thematicbroadcastprice') && (selectedPrice !== 'thematicbroadcastprice')) setSelectedPrice('thematicbroadcastprice');
		if ((priceId === 'protagonist') && (selectedPrice !== 'protagonist')) setSelectedPrice('protagonist');
		if ((priceId === 'discountprotagonist') && (selectedPrice !== 'discountprotagonist')) setSelectedPrice('discountprotagonist');
		if ((priceId === 'spectator') && (selectedPrice !== 'spectator')) setSelectedPrice('spectator');
		if ((priceId === 'discountspectator') && (selectedPrice !== 'discountspectator')) setSelectedPrice('discountspectator');
	}

	const priceChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setIsComputingPools(true);
		const newPrice = roundTwoDigits(+event.target.value.replaceAll(',','.'));
		const priceId = event.target.id;
		if (priceId === 'price') {
			setLocalPriceString(event.target.value);
			setLocalPrice(newPrice);
			setIsModified(getProductPrice(product) !== newPrice);
		}
		if (priceId === 'thematicbroadcastprice') {
			setThematicBroadcastLocalPriceString(event.target.value);
			setThematicBroadcastLocalPrice(newPrice);
			setIsModified(product.productThematicCall.price !== newPrice);
		}
		if (priceId === 'protagonist') {
			setThematicBroadcastLocalProtagonistPriceString(event.target.value);
			setThematicBroadcastLocalProtagonistPrice(newPrice);
			setIsModified(product.productThematicCall.protagonistPrice !== newPrice);
		}
		if (priceId === 'discountprotagonist') {
			setThematicBroadcastLocalDiscountProtagonistPriceString(event.target.value);
			setThematicBroadcastLocalDiscountProtagonistPrice(newPrice);
			setIsModified(product.productThematicCall.discountProtagonistPrice !== newPrice);
		}
		if (priceId === 'spectator') {
			setThematicBroadcastLocalSpectatorPriceString(event.target.value);
			setThematicBroadcastLocalSpectatorPrice(newPrice);
			setIsModified(product.productThematicCall.spectatorPrice !== newPrice);
		}
		if (priceId === 'discountspectator') {
			setThematicBroadcastLocalDiscountSpectatorPriceString(event.target.value);
			setThematicBroadcastLocalDiscountSpectatorPrice(newPrice);
			setIsModified(product.productThematicCall.discountSpectatorPrice !== newPrice);
		}
	};

	const standardPercChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const totStdPerc = +event.target.value;
		setStandardPoolsPerc(totStdPerc);
		setStandardPoolsValue((totStdPerc / 100) * net1);
		setIsModified(!arrayOfObjectEquals(localStandardPools.concat(localAuthorPools, localIncomePool), remoteProductPools));
	};

	const authorPoolPercChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const poolId = +event.target.id;

		setLocalAuthorPools((prevState) =>
			prevState.map((pool) => {
				return {
					...pool,
					net2Perc: pool.id === poolId ? +event.target?.value.toString().replaceAll(',','.') : pool.net2Perc,
					net1Perc: pool.id === poolId ? (+event.target?.value.toString().replaceAll(',','.') * (100 - standardPoolsPerc)) / 100 : pool.net1Perc,
					value: pool.id === poolId ? (+event.target?.value.toString().replaceAll(',','.') / 100) * net2 : pool.value,
				};
			})
		);
	};

	const lessonPackPoolPercChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const poolId = +event.target.id;

		setLocalProductLessonPackPools((prevState) =>
			prevState.map((pool) => {
				return {
					...pool,
					net1Perc: pool.id === poolId ? +event.target?.value : pool.net1Perc,
					value: pool.id === poolId ? ((+event.target?.value / 100) * net2) / product.productLessonPack?.lessonsNumber : pool.value,
				};
			})
		);
		setIsModified(!arrayOfObjectEquals(localProductLessonPackPools, remoteProductLessonPackPools));
	};

	const collapsedChangedHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		setIsStandardCollapsed((prevState) => !prevState);
	};

	//author pools handlers
	const changeAuthorPoolHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
		event.preventDefault();
		console.log(event.currentTarget);
		const poolId = +event.currentTarget.value;
		const poolIndex = event.currentTarget.dataset.poolIndex ? +event.currentTarget.dataset.poolIndex : -9;
		let selectedPool: Pool | undefined = {} as Pool;
		if (authorPools?.some((p) => p.id === poolId)) {
			selectedPool = authorPools?.find((p) => p.id === poolId);
			if (selectedPool) {
				setLocalAuthorPools((prevState) => {
					const updatedState = prevState.map((pool) => {
						if (pool.index === poolIndex) {
							return {
								...pool,
								id: selectedPool ? selectedPool.id : pool.id,
								name: selectedPool ? selectedPool.name : pool.name,
								type: selectedPool ? selectedPool.type : pool.name,
								originalPool: selectedPool ? selectedPool : pool.originalPool,
							};
						}
						return pool;
					});
					return updatedState;
				});
			}
		}

		// setIsModified(!arrayOfObjectEquals(localStandardPools.concat(localAuthorPools, localIncomePool), remoteProductPools));
	};

	const addAuthorPoolHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		if (localAuthorPools.find((p) => p.id === -1)) return;
		setLocalAuthorPools((prevState) =>
			prevState.concat({ net1Perc: 0, net2Perc: 0, value: 0, id: -1, index: localStandardPools.length + prevState.length + 1 } as LocalPool)
		);
		// setAuthorsAreModified(!arrayOfObjectEquals(localStandardPools.concat(localAuthorPools, localIncomePool), remoteProductPools));
	};

	const removeAuthorPoolHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		const poolId = +event.currentTarget.value;
		setLocalAuthorPools((prevState) => prevState.filter((p) => p.id !== poolId));
		// setIsModified(true);
		// setAuthorsAreModified(!arrayOfObjectEquals(localStandardPools.concat(localAuthorPools, localIncomePool), remoteProductPools));
	};

	const changeLessonPackPoolHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
		event.preventDefault();
		console.log(event.currentTarget);
		const poolId = +event.currentTarget.value;
		const poolIndex = event.currentTarget.dataset.poolIndex ? +event.currentTarget.dataset.poolIndex : -9;
		let selectedPool: Pool | undefined = {} as Pool;
		if (authorPools?.some((p) => p.id === poolId)) {
			selectedPool = authorPools?.find((p) => p.id === poolId);
			if (selectedPool) {
				setLocalProductLessonPackPools((prevState) => {
					const updatedState = prevState.map((pool) => {
						if (pool.index === poolIndex) {
							return {
								...pool,
								id: selectedPool ? selectedPool.id : pool.id,
								name: selectedPool ? selectedPool.name : pool.name,
								type: selectedPool ? selectedPool.type : pool.name,
								originalPool: selectedPool ? selectedPool : pool.originalPool,
							};
						}
						return pool;
					});
					return updatedState;
				});
			}
		}

		setIsModified(!arrayOfObjectEquals(localProductLessonPackPools, remoteProductLessonPackPools));
	};

	const addLessonPackPoolHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		if (localProductLessonPackPools.find((p) => p.id === -1)) return;
		setLocalProductLessonPackPools((prevState) =>
			prevState.concat({ net1Perc: 0, net2Perc: 0, value: 0, id: -1, index: prevState.length + 1 } as LocalPool)
		);
	};

	const removeLessonPackPoolHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		const poolId = +event.currentTarget.value;
		setLocalProductLessonPackPools((prevState) => prevState.filter((p) => p.id !== poolId));
		setIsModified(!arrayOfObjectEquals(localProductLessonPackPools, remoteProductLessonPackPools));
	};

	const getAllStandardPools = async () => {
		try {
			const response = await getStandardPools();
			setStandardPools(response);
		} catch (error) {}
	};

	useEffect(() => {
		if (localPrice === remotePrice) setIsModified(!arrayOfObjectEquals(localStandardPools.concat(localAuthorPools, localIncomePool), remoteProductPools));
	}, [localStandardPools, localAuthorPools, localIncomePool, remoteProductPools, remoteAuthorPools]);

	useEffect(() => {
		if (isComputingPool === false) setAuthorsAreModified(false);
		if (localAuthorPools && remoteAuthorPools) setAuthorsAreModified(!arrayOfObjectEquals(localAuthorPools, remoteAuthorPools));
	}, [localAuthorPools, remoteAuthorPools, isComputingPool]);

	//get standard pools split
	useEffect(() => {
		getAllStandardPools();
	}, []);

	//set initial local & remote Pools
	useEffect(() => {
		let tempStandardPools: LocalPool[] = [];
		let tempAuthorPools: LocalPool[] = [];
		let tempIncomePool: LocalPool = { net2Perc: 0, value: 0 } as LocalPool;
		product.productPools.forEach((pool, index) => {
			const localPool: LocalPool = {
				index: index,
				id: pool.pool.id,
				name: pool.pool.name,
				net1Perc: pool.perc,
				net2Perc: origStandardPoolsPerc === 100 ? 0 : pool.perc / (1 - origStandardPoolsPerc / 100),
				stdPerc: pool.pool.type === 'STANDARD' ? standardPools.find((p) => p.poolId === pool.pool.id)?.perc : 0,
				type: pool.pool.type,
				value: (pool.perc / 100) * net1,
				originalPool: pool.pool,
			};
			if (pool.pool.type === 'STANDARD') tempStandardPools.push(localPool);
			if (pool.pool.type !== 'STANDARD' && pool.pool.name !== 'Guadagno') tempAuthorPools.push(localPool);
			if (pool.pool.type !== 'STANDARD' && pool.pool.name === 'Guadagno') tempIncomePool = localPool;
		});
		if (tempStandardPools.length === 0) {
			tempStandardPools = standardPools.map((pool, index) => {
				return {
					index: index,
					id: pool.pool.id,
					name: pool.pool.name,
					net1Perc: 0,
					net2Perc: 0,
					stdPerc: pool.perc,
					type: pool.pool.type,
					value: (pool.perc / 100) * net1,
					originalPool: pool.pool,
				};
			});
		}
		setLocalStandardPools(tempStandardPools);
		setLocalAuthorPools(tempAuthorPools);
		setRemoteAuthorPools(tempAuthorPools);
		setLocalIncomePool(tempIncomePool);
		setRemoteProductPools(tempStandardPools.concat(tempAuthorPools, tempIncomePool));
	}, [origStandardPoolsPerc, standardPools, product, net1]);

	//set initial local & remote LessonPack Pools
	useEffect(() => {
		let tempLessonPackPools: LocalPool[] = [];
		product.productLessonPack?.productLessonPackPools?.forEach((pool, index) => {
			const localPool: LocalPool = {
				index: index,
				id: pool.pool.id,
				name: pool.pool.name,
				net1Perc: pool.perc,
				net2Perc: 0,
				stdPerc: 0,
				type: pool.pool.type,
				value: ((net2 / product.productLessonPack.lessonsNumber) * pool.perc) / 100,
				originalPool: pool.pool,
			};
			tempLessonPackPools.push(localPool);
		});
		if (tempLessonPackPools.length === 0) {
		}
		setRemoteProductLessonPackPools(tempLessonPackPools);
		setLocalProductLessonPackPools(tempLessonPackPools);
	}, [net2, product.productLessonPack?.lessonsNumber, product.productLessonPack?.productLessonPackPools]);

	//set initial standardPoolsPerc
	useEffect(() => {
		const stdPerc = product.productPools.filter((p) => p.pool.type === 'STANDARD').reduce((acc, p) => acc + p.perc, 0);
		setOrigStandardPoolsPerc(stdPerc);
		setStandardPoolsPerc(stdPerc);
	}, [product]);

	//set initial price
	useEffect(() => {
		if (product.type !== 'THEMATIC_BROADCAST') {
			const price = getProductPrice(product);
			setRemotePrice(price);
			setLocalPrice(price);
			setLocalPriceString(price.toString().replaceAll('.',','));
		}
		if (product.type === 'THEMATIC_BROADCAST') {
			setThematicBroadcastLocalPrice(product.productThematicCall.price??0);
			setRemotePrice(product.productThematicCall.price);
			setThematicBroadcastLocalPriceString(product.productThematicCall.price ? product.productThematicCall.price.toString().replaceAll('.',',') : '0');
			setThematicBroadcastLocalProtagonistPrice(product.productThematicCall.protagonistPrice??0);
			setThematicBroadcastLocalProtagonistPriceString(product.productThematicCall.protagonistPrice ? product.productThematicCall.protagonistPrice.toString().replaceAll('.',',') : '0');
			if (product.productThematicCall.discountProtagonistPrice !== null) {
				setThematicBroadcastLocalDiscountProtagonistPrice(product.productThematicCall.discountProtagonistPrice);
				setThematicBroadcastLocalDiscountProtagonistPriceString(product.productThematicCall.discountProtagonistPrice.toString().replaceAll('.',','));
			} else {
				setThematicBroadcastLocalDiscountProtagonistPrice(0);
				setThematicBroadcastLocalDiscountProtagonistPriceString("");
			}
			setThematicBroadcastLocalSpectatorPrice(product.productThematicCall.spectatorPrice??0);
			setThematicBroadcastLocalSpectatorPriceString(product.productThematicCall.spectatorPrice ? product.productThematicCall.spectatorPrice.toString().replaceAll('.',',') : '0');
			if (product.productThematicCall.discountSpectatorPrice !== null) {
				setThematicBroadcastLocalDiscountSpectatorPrice(product.productThematicCall.discountSpectatorPrice);
				setThematicBroadcastLocalDiscountSpectatorPriceString(product.productThematicCall.discountSpectatorPrice.toString().replaceAll('.',','));
			} else {
				setThematicBroadcastLocalDiscountSpectatorPrice(0);
				setThematicBroadcastLocalDiscountSpectatorPriceString("");
			}
			if ((product.productThematicCall.discountProtagonistPrice === null) && (product.productThematicCall.discountSpectatorPrice === null)) {
				setHasDiscount(false);
			} else {
				setHasDiscount(true && false);
			}
		}
	}, [product]);

	//set feesValue and vatValue
	useEffect(() => {
		setFeesValue((_FEE_PERC / 100) * localPrice);
		setVatValue(localPrice * (1 - 1 / (1 + _VAT_PERC / 100)));
		// setIsModified(localPrice !== product.singlePrice);
	}, [localPrice]);

	//set net1
	useEffect(() => {
		setNet1(localPrice - feesValue - vatValue);
	}, [feesValue, localPrice, vatValue]);

	//setStandardPoolsValue
	useEffect(() => {
		setStandardPoolsValue((net1 * standardPoolsPerc) / 100);
	}, [net1, standardPoolsPerc]);

	//setNet2
	useEffect(() => {
		setNet2(net1 - standardPoolsValue);
	}, [net1, standardPoolsValue]);

	//set authorsValue
	useEffect(() => {
		setAuthorsValue(localAuthorPools.reduce((a, p) => a + p.value, 0));
		setAuthorsPerc(localAuthorPools.reduce((a, p) => a + p.net2Perc, 0));
		
	}, [localAuthorPools, standardPoolsPerc]);

	//update authorPools
	useEffect(() => {
		setLocalAuthorPools((prevState) =>
			prevState.map((pool) => {
				return {
					...pool,
					net1Perc: standardPoolsPerc === 100 ? 0 : pool.net2Perc * (1 - standardPoolsPerc / 100),
					value: (pool.net2Perc / 100) * net2,
				};
			})
		);
	}, [net2, standardPoolsPerc]);

	//update standardPools
	useEffect(() => {
		setLocalStandardPools((prevState) =>
			prevState.map((pool) => {
				return {
					...pool,
					net1Perc: pool.stdPerc ? (pool.stdPerc * standardPoolsPerc) / 100 : 0,
					value: pool.stdPerc ? (((pool.stdPerc / 100) * standardPoolsPerc) / 100) * net1 : 0,
				};
			})
		);
	}, [net1, standardPoolsPerc]);

	useEffect(() => {
		setIsComputingPools(false);
	}, [localAuthorPools]);

	//update income Pool
	useEffect(() => {
		const localIncomeValue = net2 - authorsValue;

		setLocalIncomePool((prevState) => {
			return {
				...prevState,
				value: localIncomeValue ? localIncomeValue : 0,
				net2Perc: net2 === 0 ? 100 - authorsPerc : (localIncomeValue / net2) * 100,
				net1Perc: net1 === 0 ? 100 - authorsPerc : (localIncomeValue / net1) * 100,
			};
		});
	}, [authorsPerc, authorsValue, net2, net1]);

	useEffect(() => {
		setLocalIncomePool((prevState) => {
			return { ...prevState, net1Perc: (prevState.value > 0) ? (prevState.value / net1) * 100 : 100 - authorsPerc };
		});
	}, [net1]);

	useEffect(() => {
		if (isFree) setIsModified(true);
	}, [isFree])

	useEffect(() => {
		if (product.type === 'THEMATIC_BROADCAST') {
			if (selectedPrice === 'thematicbroadcastprice') {
				setLocalPrice(thematicBroadcastLocalPrice);
				console.log("LocalPrice cambiato!");
				if (product.productThematicCall?.price) setRemotePrice(product.productThematicCall.price);
			}
			if (selectedPrice === 'protagonist') {
				setLocalPrice(thematicBroadcastLocalProtagonistPrice);
				setRemotePrice(product.productThematicCall.protagonistPrice)
			}
			if (selectedPrice === 'discountprotagonist') {
				setLocalPrice(thematicBroadcastLocalDiscountProtagonistPrice*_LIB_POINT_EUR);
				setRemotePrice(product.productThematicCall.discountProtagonistPrice ?? 0);
			}
			if (selectedPrice === 'spectator') {
				setLocalPrice(thematicBroadcastLocalSpectatorPrice);
				setRemotePrice(product.productThematicCall.spectatorPrice);
			}
			if (selectedPrice === 'discountspectator') {
				setLocalPrice(thematicBroadcastLocalDiscountSpectatorPrice*_LIB_POINT_EUR);
				setRemotePrice(product.productThematicCall.discountSpectatorPrice ?? 0);
			}
		}
	}, [
		selectedPrice,
		thematicBroadcastLocalPrice,
		thematicBroadcastLocalProtagonistPrice,
		thematicBroadcastLocalDiscountProtagonistPrice,
		thematicBroadcastLocalSpectatorPrice,
		thematicBroadcastLocalDiscountSpectatorPrice,
		product.productThematicCall?.price,
		product.productThematicCall?.protagonistPrice,
		product.productThematicCall?.discountProtagonistPrice,
		product.productThematicCall?.spectatorPrice,
		product.productThematicCall?.discountSpectatorPrice,
		product.type
	]);
//    Sotto Lordo: Espresso in punti =>
//    sotto ai prezzi Lancio il calcolo in Euro
//    Protagonista Lancio
//    Spettatore Lancio 
// 	  ??? quando e come si dichiara che la diretta è in vendita? (e partono le notifiche)
//    Se messa in vendita entro i tre giorni prima della diretta, avviso che non verranno applicati prezzi lancio
	return (
		<MainBox>
			<Row>
				<PriceBox>
					<PriceRowBox>

			{ product.type === 'THEMATIC_BROADCAST' && 	
					<PriceCol>
						<PriceRow style={{visibility: 'hidden'}}>
							<IsVipBroadcast
								product={product}
								putProduct={putProduct}
								disabled={checkThematicBroadcastForScheduled() || checkThematicBroadcastForPublished()}
							/>
						</PriceRow>
					</PriceCol>
			}

					<PriceCol>
						<PriceRow style={{visibility: 'hidden'}}>
							<input
								type="checkbox"
								checked={isFree}
								onChange={handleCheckboxIsFreeChange}
								disabled={checkThematicBroadcastForScheduled() || checkThematicBroadcastForPublished()}
							/><PriceLabel><small>in regalo</small></PriceLabel>
						</PriceRow>
					</PriceCol>

			{ product.type === 'THEMATIC_BROADCAST' && 	
					<PriceCol>
						<PriceRow style={{visibility: 'hidden'}}>
							<input
								type="checkbox"
								checked={hasDiscount && false}
								onChange={handleCheckboxHasDiscountChange}
								disabled={checkThematicBroadcastForScheduled() || checkThematicBroadcastForPublished()}
							/><PriceLabel><small>Attiva Prezzi Lancio</small></PriceLabel>
						</PriceRow>
					</PriceCol>
			}

					</PriceRowBox>
				</PriceBox>
			</Row>

			{ authorsAreModified && !isComputingPool &&
				<Row>
					<WarningBox>
						ATTENZIONE! Prima di modificare i prezzi SALVA le modifiche effettuate alla tabella AUTORI
					</WarningBox>
				</Row>
				}
			<Row>
				<PriceBox>
					{ product.type !== 'THEMATIC_BROADCAST' && 
					<PriceRowBox>
						<Col>
						</Col>

						<Col>
						</Col>

						<Col>
							<PriceRow>
								<PriceLabel htmlFor='price'>Prezzo Lordo</PriceLabel>
								<PriceInput
									id={'price'}
									value={localPriceString}
									onChange={priceChangeHandler}
									disabled={isFree}
								/>
							</PriceRow>
						</Col>
					</PriceRowBox>
					}
					{ product.type === 'THEMATIC_BROADCAST' &&
					<>
					<PriceRowBox>
						
						<PriceCol style={{visibility: product.productThematicCall.type === 'ESPERIENZA' ? 'hidden' : 'visible'}}>
							<PriceRow>
								<StyledRadioButton
									value="thematicbroadcastprice"
									checked={selectedPrice === 'thematicbroadcastprice'}
									tooltip="Visualizza la redistribuzione per il prezzo catalogo"
									onChange={handleSelectedPriceChange}
								/>
								<PriceLabel htmlFor='thematicbroadcastprice'>Catalogo
								<PriceInput
									id={'thematicbroadcastprice'}
									value={thematicBroadcastLocalPriceString}
									onChange={priceChangeHandler}
									onFocus={priceFocusHanlder}
									disabled={isFree}
								/><small>{product.productThematicCall.vip ? <VipIcon /> : "€"}</small></PriceLabel>
							</PriceRow>

							{ hasDiscount && 
							<PriceRow>
								<PriceLabel><small>Espressi in punti {product.productThematicCall.vip ? "Vip" : "Libertà"} ➡️</small></PriceLabel>
							</PriceRow>
							}

							{!product.productThematicCall.vip && hasDiscount &&
							<PriceRow>
								<PriceLabel>&nbsp;</PriceLabel>
							</PriceRow>
							}
							
						</PriceCol>

						{(
							((product?.productThematicCall?.maxProtagonist !== null) && (product?.productThematicCall?.maxProtagonist > 0)) || // with n protagonists
							(product?.productThematicCall?.maxProtagonist === null) // with unlimited protagonists
						) &&
						<PriceCol>
							<PriceRow>
								<StyledRadioButton
									value="protagonist"
									checked={selectedPrice === 'protagonist'}
									tooltip="Visualizza la redistribuzione per il prezzo protagonista"
									onChange={handleSelectedPriceChange}
								/>
								<PriceLabel htmlFor='protagonist'>{product.productThematicCall.type === 'ESPERIENZA' ? "Prezzo" : "Prot.Listino"}
								<PriceInput
									id={'protagonist'}
									value={thematicBroadcastLocalProtagonistPriceString}
									onChange={priceChangeHandler}
									onFocus={priceFocusHanlder}
									disabled={false && (checkThematicBroadcastForScheduled() || checkThematicBroadcastForPublished() || isFree)}
								/><small>{product.productThematicCall.vip ? <VipIcon /> : "€"}</small></PriceLabel>
							</PriceRow>

							{ hasDiscount &&
							<PriceRow>
								<StyledRadioButton
									value="discountprotagonist"
									checked={selectedPrice === 'discountprotagonist'}
									tooltip="Visualizza la redistribuzione per il prezzo scontato protagonista"
									onChange={handleSelectedPriceChange}
								/>
								<PriceLabel htmlFor='discountprotagonist'>Prot.Lancio
								<PriceInput
									id={'discountprotagonist'}
									value={thematicBroadcastLocalDiscountProtagonistPriceString}
									onChange={priceChangeHandler}
									onFocus={priceFocusHanlder}
									disabled={false && (checkThematicBroadcastForScheduled() || checkThematicBroadcastForPublished() || isFree)}
								/><small>{product.productThematicCall.vip ? <VipIcon /> : <LibertyIcon />}</small></PriceLabel>
							</PriceRow>
							}

							{!product.productThematicCall.vip && hasDiscount &&
							<PriceRow>
								<PriceLabel><small>(valore Prot.Lancio: {roundTwoDigits(thematicBroadcastLocalDiscountProtagonistPrice*_LIB_POINT_EUR).toString().replaceAll('.',',')}€)</small></PriceLabel>
							</PriceRow>
							}
						</PriceCol>
						}


						{(
							((product?.productThematicCall?.maxSpectator !== null) && (product?.productThematicCall?.maxSpectator > 0)) || // with n spectators
							(product?.productThematicCall?.maxSpectator === null) // with unlimited spectators
						) &&
						<PriceCol style={{visibility: (product.productThematicCall.type === 'ESPERIENZA') ? 'hidden' : 'visible'}}>
							<PriceRow>
								<StyledRadioButton
									value="spectator"
									checked={selectedPrice === 'spectator'}
									tooltip="Visualizza la redistribuzione per il prezzo spettatore"
									onChange={handleSelectedPriceChange}
								/>
								<PriceLabel htmlFor='spectator'>Spett.Listino
								<PriceInput
									id={'spectator'}
									value={thematicBroadcastLocalSpectatorPriceString}
									onChange={priceChangeHandler}
									onFocus={priceFocusHanlder}
									disabled={false && (checkThematicBroadcastForScheduled() || checkThematicBroadcastForPublished() || isFree)}
								/><small>{product.productThematicCall.vip ? <VipIcon /> : "€"}</small></PriceLabel>
							</PriceRow>

							{ hasDiscount &&
							<PriceRow>
								<StyledRadioButton
									value="discountspectator"
									checked={selectedPrice === 'discountspectator'}
									tooltip="Visualizza la redistribuzione per il prezzo scontato spettatore"
									onChange={handleSelectedPriceChange}
								/>
								<PriceLabel htmlFor='discountspectator'>Spett.Lancio
								<PriceInput
									id={'discountspectator'}
									value={thematicBroadcastLocalDiscountSpectatorPriceString}
									onChange={priceChangeHandler}
									onFocus={priceFocusHanlder}
									disabled={false && (checkThematicBroadcastForScheduled() || checkThematicBroadcastForPublished() || isFree)}
								/><small>{product.productThematicCall.vip ? <VipIcon /> : <LibertyIcon />}</small></PriceLabel>
							</PriceRow>
							}

							{!product.productThematicCall.vip && hasDiscount &&
							<PriceRow>
								<PriceLabel><small>(valore Spett.Lancio: {roundTwoDigits(thematicBroadcastLocalDiscountSpectatorPrice*_LIB_POINT_EUR).toString().replaceAll('.',',')}€)</small></PriceLabel>
							</PriceRow>
							}
						</PriceCol>
					}
					
					</PriceRowBox>
					</>
					}
				</PriceBox>
			</Row>

			<Row>
				<Col>
						<PercentagesForm>
						<PoolRow
							poolId={0}
							productPrice={localPrice}
							name='Commissioni'
							perc={_FEE_PERC}
							value={roundTwoDigits(feesValue)}
							type='OTHER'
							isDisabled={true}
							onChange={() => null}
							hideValue={((product.type === 'THEMATIC_BROADCAST') && (product.productThematicCall.vip))}
						/>
						<PoolRow
							poolId={0.77777}
							productPrice={localPrice}
							name='IVA'
							perc={_VAT_PERC}
							value={roundTwoDigits(vatValue)}
							type='OTHER'
							isDisabled={true}
							onChange={() => null}
							hideValue={((product.type === 'THEMATIC_BROADCAST') && (product.productThematicCall.vip))}
						/>
						<SubTotalBox>
							<SubTotalLabel htmlFor='net1'>Netto 1</SubTotalLabel>
							<SubTotalInput
								id='net1'
								value={roundTwoDigits(net1).toString().replaceAll('.',',')}
								disabled
							></SubTotalInput>
						</SubTotalBox>
						<PoolRow
							poolId={0.99999}
							productPrice={localPrice}
							name='% STANDARD LDL'
							perc={roundTwoDigits(standardPoolsPerc)}
							value={roundTwoDigits(standardPoolsValue)}
							type='STANDARD'
							isDisabled={false}
							onChange={standardPercChangeHandler}
							hideValue={((product.type === 'THEMATIC_BROADCAST') && (product.productThematicCall.vip))}
						/>
						<ExpandButton onClick={collapsedChangedHandler}>
							{isStandardCollapsed && <ExpandMoreIcon />}
							{!isStandardCollapsed && <ExpandLessIcon />}
						</ExpandButton>
						{!isStandardCollapsed && (
							<BaseFieldset
								legend='POOL STANDARD LDL'
								ref={standardPoolsDetailRef}
							>
								{/* <Col
									gap='0.5rem'
									crossAxis='flex-end'
								> */}
								% riferita al netto 1
								<StandarPoolsBox>
									{standardPoolsPerc === 0 &&
										standardPools?.map((pool, index) => {
											return (
												<PoolRow
													key={index}
													productPrice={localPrice}
													poolId={pool.pool.id}
													name={pool.pool.name}
													perc={0}
													type={pool.pool.type}
													value={0}
													isDisabled={true}
													onChange={() => null}
													hideValue={((product.type === 'THEMATIC_BROADCAST') && (product.productThematicCall.vip))}
												/>
											);
										})}
									{standardPoolsPerc > 0 &&
										localStandardPools.map((pool, index) => {
											return (
												<PoolRow
													localPoolIndex={pool.index}
													key={index}
													productPrice={localPrice}
													poolId={pool.id}
													name={pool.name}
													perc={roundTwoDigits(pool.net1Perc)}
													type={pool.type}
													value={roundTwoDigits(pool.value)}
													isDisabled={true}
													onChange={() => null}
													hideValue={((product.type === 'THEMATIC_BROADCAST') && (product.productThematicCall.vip))}
												/>
											);
										})}
								</StandarPoolsBox>
								{/* </Col> */}
							</BaseFieldset>
						)}
						<SubTotalBox>
							<SubTotalLabel htmlFor='net2'>Netto 2</SubTotalLabel>
							<SubTotalInput
								id='net2'
								value={roundTwoDigits(net2).toString().replaceAll('.',',')}
								disabled
							></SubTotalInput>
						</SubTotalBox>
						<BaseFieldset legend='AUTORI'>
							<Col gap='0.5rem'>
								% riferita al netto 2
								<StandarPoolsBox>
									{localAuthorPools.map((pool, index) => {
										return (
											<PoolRow
												localPoolIndex={pool.index}
												key={index}
												productPrice={localPrice}
												poolId={pool.id}
												name={pool.name}
												perc={roundTwoDigits(pool.net2Perc)}
												type={pool.type}
												value={roundTwoDigits(pool.value)}
												isAuthor={true}
												allAuthorPools={authorPools}
												onChange={authorPoolPercChangeHandler}
												removeAuthorPoolHandler={removeAuthorPoolHandler}
												changeAuthorPoolHandler={changeAuthorPoolHandler}
												hideValue={((product.type === 'THEMATIC_BROADCAST') && (product.productThematicCall.vip))}
											/>
										);
									})}
								</StandarPoolsBox>
								<AddAuthorPoolButton onClick={addAuthorPoolHandler}>
									<PersonAddIcon />
								</AddAuthorPoolButton>
								<PoolRow
									localPoolIndex={localIncomePool.index}
									productPrice={localPrice}
									poolId={0.88888}
									name={'Guadagno LDL'}
									perc={roundTwoDigits(localIncomePool.net2Perc)}
									type={'ADMINISTRATION'}
									isDisabled={true}
									value={roundTwoDigits(localIncomePool.value)}
									onChange={() => null}
									hideValue={((product.type === 'THEMATIC_BROADCAST') && (product.productThematicCall.vip))}
								/>
							</Col>
						</BaseFieldset>
					</PercentagesForm>
				</Col>
				<Col>
					{product.productLessonPack && (
						<BaseFieldset legend='DIVISIONE SINGOLA LEZIONE'>
							<Col gap='0.5rem'>
								<StandarPoolsBox>
									{localProductLessonPackPools.map((pool, index) => {
										return (
											<PoolRow
												localPoolIndex={pool.index}
												key={index}
												productPrice={localPrice}
												poolId={pool.id}
												name={pool.name}
												perc={roundTwoDigits(pool.net1Perc)}
												type={pool.type}
												value={roundTwoDigits(pool.value)}
												isAuthor={true}
												allAuthorPools={authorPools}
												onChange={lessonPackPoolPercChangeHandler}
												removeAuthorPoolHandler={removeLessonPackPoolHandler}
												changeAuthorPoolHandler={changeLessonPackPoolHandler}
												hideValue={((product.type === 'THEMATIC_BROADCAST') && (product.productThematicCall.vip))}
											/>
										);
									})}

									<PoolRow
										productPrice={localPrice}
										poolId={-1}
										name='Titolare lezione'
										perc={100 - localProductLessonPackPools.reduce((a, b) => a + b.net1Perc, 0)}
										type={'coach'}
										value={roundTwoDigits(
											((net2 / product.productLessonPack?.lessonsNumber) *
												(100 - localProductLessonPackPools.reduce((a, b) => a + b.net1Perc, 0))) /
												100
										)}
										allAuthorPools={[]}
										isDisabled={true}
										onChange={authorPoolPercChangeHandler}
										hideValue={((product.type === 'THEMATIC_BROADCAST') && (product.productThematicCall.vip))}
									/>
								</StandarPoolsBox>
								<AddAuthorPoolButton onClick={addLessonPackPoolHandler}>
									<PersonAddIcon />
								</AddAuthorPoolButton>
							</Col>
						</BaseFieldset>
					)}
					<table>
						<tbody>
							{localAuthorPools
								.concat(localStandardPools)
								.concat(localIncomePool)
								.map((pool, index) => {
									return (
										<tr key={index}>
											<td key={index + 'id'}>{pool.id}</td>
											<td key={index + 'type'}>{pool.type}</td>
											<td key={index + 'name'}>{pool.name}</td>
											<td key={index + 'perc'}>{roundTwoDigits(pool.net1Perc).toString().replaceAll('.',',')}</td>
											{ ((product.type !== 'THEMATIC_BROADCAST') || (!product.productThematicCall.vip)) && <td key={index + 'value'}>{roundTwoDigits(pool.value).toString().replaceAll('.',',')}</td>}
										</tr>
									);
								})}
							<tr></tr>
							<tr>
								<td></td>
								<td></td>
								<td>TOTALE</td>
								<td>
									{roundTwoDigits(
										localAuthorPools
											.concat(localStandardPools)
											.concat(localIncomePool)
											.reduce((acc, p) => acc + p.net1Perc, 0)
									).toString().replaceAll('.',',')}
								</td>
								{ ((product.type !== 'THEMATIC_BROADCAST') || (!product.productThematicCall.vip)) &&
								<td>
									{roundTwoDigits(
										localAuthorPools
											.concat(localStandardPools)
											.concat(localIncomePool)
											.reduce((acc, p) => acc + p.value, 0)
									).toString().replaceAll('.',',')}
								</td>
								}
							</tr>
						</tbody>
					</table>
				</Col>
			</Row>
			<Row mainAxis='center'>
				<SaveButton
					disabled={!isModified}
					onClick={saveProductPoolsHandler}
				>
					SALVA MODIFICHE
				</SaveButton>
			</Row>
		</MainBox>
	);
};

export default PercentagesTab;
