import styled from 'styled-components';
import colors from 'style/palette/palette';

export const Page = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: stretch;
	flex: 1;

	padding: 0 30px;

	width: 100%;
	height: 100%;

	overflow: hidden;
`;

export const BaseContentCard = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;

	flex-direction: column;
	flex: 1;

	padding: 20px;

	width: 100%;
	height: 100%;

	overflow: hidden;

	background-color: ${colors.darkGrey};

	border-radius: 15px;
`;

export const InfoLabel = styled.div`
	padding: 0.5rem;
	margin: 1rem;
	font-weight: bold;
	min-width: 25%;
	color: ${colors.white};
	text-transform: uppercase;
`;

export const EditableArea = styled.textarea`
	width: 100%;
	min-height: 80px;
`;

export const SubscriptionLabel = styled.div`
	padding: 0.5rem;
	font-weight: bold;
	width: 25%;
	color: ${colors.white};
	text-transform: uppercase;
`;

export const SubscriptionMaxDate = styled.input``;

export const HoursDiv = styled.div`
	margin: 0px;
	padding: 2px;
	text-align: center;
	font-size: 0.8em;
	color: white;
`;

export const HoursSelect = styled.select`
	margin: 0px;
	padding: 0px;
`;

export const ProductImageContainer = styled.div`
	display: flex;
	align-items: stretch;
`;

export const ProductImage = styled.img`
	width: 7rem;
	align-self: flex-start;

	cursor: pointer;
`;

export const ImageEditIconOverlay = styled.div`
	margin-left: -16px;

	> svg {
            min-width: 15px;
            min-height: 15px; 
			filter: brightness(0) saturate(100%) invert(84%) sepia(30%) saturate(5368%) hue-rotate(359deg) brightness(103%) contrast(105%);       
    }
`;

export const ProductImageNewFile = styled.input.attrs({ 
    type: 'file',
  })`
    display: none;
`;

export const PicButton = styled.button`
	margin: 0 0 0 20px;
`;