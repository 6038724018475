
import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";
import { FC, useEffect, useState } from "react";

// routing
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { saveProductsLessonsStatus } from "redux/actions/actions-user";

//component
import ProductPageDesktop from "./layout/ProductPageDesktop/ProductPageDesktop.component";
import ProductPageMobile from "./layout/ProductPageMobile/ProductPageMobile.component";
import { getCourseWithId, getLessonData } from "./services/ProductPage.services";

//style
import {
    Page,
    MobileVersion,
    DesktopVersion
} from './style/ProductPage.style';

const ProductPage: FC<{product: any}> = ({ product }) => {

    const [firstLesson, setFirstLesson] = useState<any>({})

    product.course.content.sort((elA: any, elB: any) => elA.pos - elB.pos);
        
    const chapterPos = product.course.content.flatMap((el: any) => { 
        if (!el.parentId) return +el.pos;
        return 1000;
    });

    const firstChapterPos = chapterPos.sort((elA: number, elB: number) => elA - elB)[0];
    const firstChapter = product.course.content.filter((el: any) => (!el.parentId) && (el.pos === firstChapterPos));
    console.log("!:", firstChapter);
    
    const firstLessonOnVideo = () => {
        if (firstChapter === undefined) return;
        if (!firstChapter[0].children) return firstChapter[0];
        if (firstChapter[0].children[0].lesson) return firstChapter[0].children[0];
        if (firstChapter[0].children[0].children[0].lesson) return firstChapter[0].children[0].children[0];
        return firstChapter[0].children[0].children[0].children[0];
    };
            
    useEffect(() => {
        
        setFirstLesson(firstLessonOnVideo());
    },[])

    return (

        <Page>
            {/* <MobileVersion>
                {firstLesson.id && (
                    <ProductPageMobile
                        courseItem={product}
                        lessonToDispaly={firstLesson}
                        // typeOfProduct={typeOfProduct!}
                    />)}
            </MobileVersion> */}

            <DesktopVersion>
                {firstLesson.id && (
                    <ProductPageDesktop 
                        courseItem={product}
                        lessonToDispaly={firstLesson}
                        // typeOfProduct={typeOfProduct!}    
                    />)}
            </DesktopVersion>
        </Page>
    );
};

export default ProductPage;