import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

//hooks
import useAppSelector from 'hooks/useAppSelector';

//types
import { TitleBreadCrumbSettings } from 'components/DashboardTitleSection/DashboardTitleSection.component.d';

//components
import DashboardTitleSection from 'components/DashboardTitleSection';

//assets
import { ReactComponent as SearchIconSVG } from './assets/searchIcon.svg';
import { ReactComponent as ClearButtonIconSVG } from './assets/clearButtonIcon.svg';
import { moduleListInterface, modulesListModel } from 'configs/modules';

//utils
import ModuleFavouriteButton from './ModuleFavouriteButton/ModuleFavouriteButton.component';
import { getBrand } from 'configs/brandMap/brandMap.helper';

// style
import {
	Page,
	SearchModuleBox,
	SearchInput,
	SearchClearButton,
	SearchButton,
	ModulesBox,
	ModuleBox,
	ModuleBoxDetails,
	ModuleIcon,
	ModuleTitle,
} from './style/BackOfficePage.style';

const BackOfficePage: FC<{}> = () => {
	//TODO: read updated favourites from backend

	const sortByFavouriteAndTitle = (modulesList: moduleListInterface[]) => {
		const sorted = modulesList
			.sort((a, b) => {
				if (a.title < b.title) {
					return -1;
				}
				if (a.title > b.title) {
					return 1;
				}
				return 0;
			})
			.sort((a, b) => {
				if (a.isFavourite === true && b.isFavourite === false) {
					return -1;
				}
				if (a.isFavourite === false && b.isFavourite === true) {
					return 1;
				}
				return 0;
			});
		return sorted;
	};

	const [modulesList, setModulesList] = useState(sortByFavouriteAndTitle(modulesListModel));
	const [filteredModulesList, setFilteredModulesList] = useState<moduleListInterface[]>([]);
	const [searchInput, setSearchInput] = useState<any>('');

	const { roles } = useAppSelector((state) => state.user);

	const clearSearchHandler = () => {
		setSearchInput('');
	};

	const searchChangeHandler = (searchValue: any) => {
		setSearchInput(searchValue.target.value);
	};

	const searchKeyHandler = (event: any) => {
		if (event.keyCode === 27) {
			clearSearchHandler();
		}
	};

	const toggleFavourite = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, module: moduleListInterface) => {
		event.preventDefault();

		let updatedModulesList = modulesList.map((checkModule: moduleListInterface) => {
			if (module.title === checkModule.title) {
				return { ...checkModule, isFavourite: !checkModule.isFavourite };
			}
			return checkModule;
		});
		setModulesList(sortByFavouriteAndTitle(updatedModulesList));
		//TODO: send updated favourite to backend
	};

	const filterList = () => {
		setFilteredModulesList(
			modulesList.filter((module: moduleListInterface) => {
			  return module.roles.some(role => {
				if (roles) return roles.some((r: any) => r.role === role);
			  });
			}).filter((module: moduleListInterface) => {
			  const actualBrand = getBrand();
			  if ( actualBrand && module.brands.find((brand) => actualBrand && (brand === actualBrand.code))) return module;
			}).filter((module: moduleListInterface) => {
			  if (module.title.toLowerCase().includes(searchInput.toLowerCase())) return module;
			})
		  );
	}

	useEffect(() => {
		filterList();
	}, [searchInput, modulesList, roles]);

	useEffect(() => {
		filterList();
	}, []);

	const breadCrumbSettings: TitleBreadCrumbSettings = { index: true };

	return (
		<>
			<Page>
				<DashboardTitleSection breadCrumbSettings={breadCrumbSettings}></DashboardTitleSection>

				<SearchModuleBox>
					<SearchInput
						onKeyDown={searchKeyHandler}
						onChange={searchChangeHandler}
						value={searchInput}
					/>

					{searchInput !== '' && (
						<SearchClearButton onClick={clearSearchHandler}>
							<ClearButtonIconSVG />
						</SearchClearButton>
					)}

					<SearchButton>
						<SearchIconSVG />
					</SearchButton>
				</SearchModuleBox>

				<ModulesBox>
					{filteredModulesList.map((module: moduleListInterface, index: number) => {
						return (
							<Link
								key={index}
								to={module.url}
							>
								<ModuleBox>
									<ModuleBoxDetails>
										<ModuleIcon>{module.icon}</ModuleIcon>

										<ModuleTitle>{module.title}</ModuleTitle>
									</ModuleBoxDetails>

									{/* <ModuleFavouriteButton
										isActive={module.isFavourite}
										onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => toggleFavourite(e, module)}
									/> */}
								</ModuleBox>
							</Link>
						);
					})}
				</ModulesBox>
			</Page>
		</>
	);
};

export default BackOfficePage;
