import colors from 'style/palette';
import styled from 'styled-components';

export const PoolRowBox = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-end;
	gap: 1rem;
	align-items: center;
`;

export const PoolName = styled.label`
	font-size: 0.85rem;
	width: 10rem;
`;

export const PoolPerc = styled.input`
	text-align: right;
	width: 5rem;
`;

export const PoolValue = styled.input`
	text-align: right;
	width: 7rem;
`;

export const StandarPoolsBox = styled.div`
	align-items: flex-end;
`;

export const AuthorLabel = styled.label``;

export const AuthorSelect = styled.select`
	width: 15rem;
`;

export const AuthorOption = styled.option``;

export const RemoveAuthorPoolButton = styled.button`
	width: 25px;
	align-self: flex-end;
	display: flex;
	align-items: center;
	justify-content: center;
	> svg {
		width: 15px;
		height: 15px;
		> path {
			fill: ${colors.red};
		}
	}
`;
