import { FC, useState } from 'react';

// redux
import { enableAppMain } from 'redux/slices/slice-app-main';

// hooks
import useAppDispatch from 'hooks/useAppDispatch';

// type
import { DeleteElementModalProps } from './DeleteElementModal.component.d';

// components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';
import VideoUpdateElement from '../UpdateElements/VideoUpdateElement/VideoUpdateElement.component';
import ImageUpdateElement from '../UpdateElements/ImageUpdateElement/ImageUpdateElement.component';
import RowUpdateElement from '../UpdateElements/RowUpdateElement/RowUpdateElement.component';
import GroupUpdateElement from '../UpdateElements/GroupUpdateElement/GroupUpdateElement.component';
import TextUpdateElement from '../UpdateElements/TextUpdateElement/TextUpdateElement.component';
import ButtonUpdateElement from '../UpdateElements/ButtonUpdateElement/ButtonUpdateElement.component';
import BulletListUpdateElement from '../UpdateElements/BulletListUpdateElement/BulletListUpdateElement.component';

// style
import { 
    CardContent,
    ButtonRow,
    BoxRow,
    CloseAndCancel,
    LayoutBoxButton,
    ElementBoxButton
 } from './style/DeleteElementModal.style';
import { deleteBlock, deleteElement } from 'pages/PublicProductPages/services/PublicProductPage.services';


const DeleteElementModal: FC<DeleteElementModalProps> = ({
    blockId,
    elementId,
    typeOfLayout,
    numberOfElements,
    onCloseButtonModal,
    updateData
}) => {

    const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);
    const dispatch = useAppDispatch();

	const closeButtonHandler = () => {
        
		if (onCloseButtonModal) onCloseButtonModal();
        setIsCloseButtonClicked(true);
	};

    const onCancelClickHandler = async () => {

        try {
            if ((typeOfLayout === 'SINGLE') || (numberOfElements === 1)) {
                
                if (elementId) await deleteElementById();
                if (blockId) await deleteBlockById();
            };

            if ((typeOfLayout !== 'SINGLE') && (numberOfElements !== 1)) {

                if (elementId) {

                    await deleteElementById();
                };
                // if (blockId && (typeOfLayout === 'SINGLE')) await deleteBlockById();
                if (blockId && (elementId === undefined)) await deleteBlockById();
            };
            
            if (updateData) await updateData();
            if (onCloseButtonModal) onCloseButtonModal();

            setIsCloseButtonClicked(true);
            dispatch( enableAppMain() );        
        } catch (error: any) {
            console.warn("ERROR:", error);
        }
    };

    const deleteBlockById = async () => {

        try {

            if (!blockId) return;
            await deleteBlock(blockId);
        } catch (error: any) {
            console.warn("ERROR:", error);
        }
    };

    const deleteElementById = async () => {

        try {

            if (!elementId) return;
            await deleteElement(elementId);
        } catch (error: any) {
            console.warn("ERROR:", error);
        }
    };
    
    return (
		<BaseModal
			onClose={closeButtonHandler}
			isCloseButtonClicked={isCloseButtonClicked}
		>
			<BaseCard
				flavor='outlined'
				maxWidth='1000px'
			>
                <CardContent>
                        Vuoi cancellare l'elemento selezionato?

                        <BoxRow>
                            <CloseAndCancel
                                onClick={onCancelClickHandler}
                            >
                                Si, ELIMINA l'elemento
                            </CloseAndCancel>

                            <CloseAndCancel
                                onClick={closeButtonHandler}
                            >
                                No, annulla
                            </CloseAndCancel>
                        </BoxRow>
                </CardContent>
			</BaseCard>
		</BaseModal>
    )
};

export default DeleteElementModal;
