import { FC, useEffect, useState } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';

//types
import { NotesModalProps } from './NotesModal.component.d';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

//components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';

//style
import { CardContent, CloseSaveButton } from './style/NotesModal.component.style';
import { enableAppMain } from 'redux/slices/slice-app-main';
import { allowedNodeEnvironmentFlags } from 'process';

const NotesModal: FC<NotesModalProps> = ({ onCloseButtonModal, coachNotes, notes, type, savePrivateNotes }) => {
	const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);
	const [linkNotes, setLinkNotes] = useState<string>('');
	const [privateNotes, setPrivateNotes] = useState<string>('');

	const dispatch = useAppDispatch();

	const closeSaveButtonHandler = () => {
		if ((privateNotes !== notes) && (privateNotes !== '')) {
			savePrivateNotes(privateNotes);
		}
		if (onCloseButtonModal) onCloseButtonModal();
	};

	const inputPrivateChangeHandler = (inputValue: any) => {
		setPrivateNotes(inputValue.target.value);
	};

	useEffect(() => {
		if (notes) setPrivateNotes(notes);
	}, [notes]);

	useEffect(() => {
		if (coachNotes) setLinkNotes(coachNotes);
	}, [coachNotes]);

	return (
		<BaseModal
			isBackdropClickActive={true}
			onClose={closeSaveButtonHandler}
			isCloseButtonClicked={isCloseButtonClicked}
		>
			<BaseCard
				flavor='outlined'
				maxWidth='700px'
			>
				<CardContent>
					{linkNotes && <input type="text" value={linkNotes} readOnly></input>}
					<textarea
						rows={10}
						cols={75}
						value={privateNotes}
						disabled={type === 'Autostrada'}
						onChange={inputPrivateChangeHandler}					
					/>
					<CloseSaveButton
						onClick={closeSaveButtonHandler}
						canSave={(privateNotes !== notes) && (privateNotes !== '')}
					>
						{(privateNotes !== notes) && (privateNotes !== '') ? "Salva" : "Chiudi" }
					</CloseSaveButton>
				</CardContent>
			</BaseCard>
		</BaseModal>
	);
};

export default NotesModal;
