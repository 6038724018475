import { FC, useRef, useState } from 'react';

//hooks
import useCopyToClipboard from 'hooks/useCopyToCliboard';

//types
import { GenericInfoProps } from './GenericInfo.component.d';

//style
import {
	GenericInfoTable,
	GenericInfoTableBody,
	GenericInfoTableCellData,
	GenericInfoTableCellTitle,
	GenericInfoTableRow,
} from './style/GenericInfo.component.style';

const GenericInfo: FC<GenericInfoProps> = ({ name, surname, nickname, email, cognitoId, referral }) => {
	const [emailCopied, setEmailCopied] = useState(false);
	const [cognitoIdCopied, setCognitoIdCopied] = useState(false);

	const emailRef = useRef<HTMLTableCellElement>(null);
	const cognitoIdRef = useRef<HTMLTableCellElement>(null);

	const { copyToClipboard, copied } = useCopyToClipboard();

	const emailClickHandler = () => {
		copyToClipboard(emailRef);
		setEmailCopied(true);
		setTimeout(() => {
			setEmailCopied(false);
		}, 1000);
	};

	const cognitoIdClickHandler = () => {
		copyToClipboard(cognitoIdRef);
		setCognitoIdCopied(true);
		setTimeout(() => {
			setCognitoIdCopied(false);
		}, 1000);
	};

	return (
		<GenericInfoTable>
			<GenericInfoTableBody>
				<GenericInfoTableRow>
					<GenericInfoTableCellTitle>Nome:</GenericInfoTableCellTitle>
					<GenericInfoTableCellData>{`${name} ${surname} ${nickname ? '(old nickname: ' + nickname + ')' : ''}`}</GenericInfoTableCellData>
				</GenericInfoTableRow>
				<GenericInfoTableRow>
					<GenericInfoTableCellTitle>Email:</GenericInfoTableCellTitle>
					<GenericInfoTableCellData
						copyable={true}
						title='click per copiare!'
						ref={emailRef}
						onClick={emailClickHandler}
					>
						{emailCopied ? 'Copiato!' : email}
					</GenericInfoTableCellData>
				</GenericInfoTableRow>
				<GenericInfoTableRow>
					<GenericInfoTableCellTitle>CognitoId:</GenericInfoTableCellTitle>
					<GenericInfoTableCellData
						copyable={true}
						title='click per copiare!'
						ref={cognitoIdRef}
						onClick={cognitoIdClickHandler}
					>
						{cognitoIdCopied ? 'Copiato!' : cognitoId}
					</GenericInfoTableCellData>
				</GenericInfoTableRow>
				<GenericInfoTableRow>
					<GenericInfoTableCellTitle>Ref:</GenericInfoTableCellTitle>
					<GenericInfoTableCellData>{referral}</GenericInfoTableCellData>
				</GenericInfoTableRow>
			</GenericInfoTableBody>
		</GenericInfoTable>
	);
};

export default GenericInfo;
