import styled from 'styled-components';
import colors from 'style/palette/palette';

interface TableBodyCellProps {
    align?: string;
	tooltip?: string;
}

export const Table = styled.table`
	color: ${colors.white};
	border-collapse: collapse;
	width: 90%;
`;

export const TableHead = styled.thead``;

export const TableHeadRow = styled.tr`
	cursor: pointer;
	user-select: none;

    th:not(:nth-last-of-type(1)) {
        border-right: 1px solid ${colors.white012};
    }
`;

export const TableHeadCell = styled.th`
	padding: 10px;
	border-bottom: 1px solid ${colors.white012};
	> svg {
		margin: 0 0 0 10px;
	}
`;

export const TableBody = styled.tbody``;

export const TableBodyRow = styled.tr`
	&:hover {
		background-color: ${colors.tableRowHover};
		/* td {
			border-bottom: 1px solid ${colors.lightBlue};
		} */
	}
`;

export const TableBodyCell = styled.td<TableBodyCellProps>`
	padding: 10px;
	border-bottom: 1px solid ${colors.white012};
    text-align: ${(props) => props.align};
	position: relative;

	&:hover {
		${(props) => props.tooltip && `
        &:after {
            content: "${props.tooltip}";
            display: block;
            position: absolute;
            background-color: ${colors.black};
            color: #ffffff;
            padding: 5px;
            border-radius: 4px;
            font-size: 14px;
            top: -30px;
            left: 50%;
            transform: translateX(-50%);
            white-space: nowrap;
            z-index: 1000;
            visibility: visible;
            opacity: 1;
            transition: visibility 0s, opacity 0.5s linear;
        }
		`}
    }
`;

export const TableFooter = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	gap: 25px;
	width: 90%;
	padding: 10px;
	margin-top: 10px;
`;

export const FooterElementsCount = styled.div`
	color: ${colors.white};
	font-size: 0.75rem;
	font-weight: 400;
	margin: 0 15px;
`;

export const FooterNavigationButton = styled.button`
	background: none;
	border: none;
	cursor: pointer;
	> svg {
		fill: ${colors.white};
		margin: auto;
	}
	&:disabled {
		cursor: not-allowed;
		> svg {
			fill: ${colors.white009};
		}
	}
`;

export const DetailsButton = styled.button`
    width: 84px;
    height: 24px;

    border-radius: 416px;
    border: 0;
    background-color: ${colors.blue};

    font-weight: 400;
    font-size: 13px;
    color: #ffffff;
	cursor: pointer;

    :disabled {
        background-color: #FFFFFF1F;
        color: #29353E;
        cursor: not-allowed;
    }
`;

export const RefundButton = styled.button`
    width: 84px;
    height: 24px;

    border-radius: 416px;
    border: 0;
    background-color: ${colors.lightBlue};

    font-weight: 400;
    font-size: 13px;
    color: #ffffff;
	cursor: pointer;

    :disabled {
        background-color: #FFFFFF1F;
        color: #29353E;
        cursor: not-allowed;
    }
`;

export const DeleteButton = styled.button`
    width: 84px;
    height: 24px;

    border-radius: 416px;
    border: 0;
    background-color: ${colors.red};

    font-weight: 400;
    font-size: 13px;
    color: #ffffff;
	cursor: pointer;

    :disabled {
        background-color: #FFFFFF1F;
        color: #29353E;
        cursor: not-allowed;
    }
`;