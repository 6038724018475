import { callGET, callPOST } from 'network/network';
import { Product } from 'types/Product';

import { productsSearchParams } from './ProductsPage.services.d';

import { getBrand } from 'configs/brandMap/brandMap.helper';

const _PRODUCTS_PATH = 'v1/products';

export const getProducts = async (productsSearchParams: productsSearchParams) => {
	try {
		const response = await callGET(_PRODUCTS_PATH, true, productsSearchParams);

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const addProduct = async (newProduct: Partial<Product>) => {
	const brand = getBrand();
	if (!brand) return;
	try {
		const response = await callPOST(_PRODUCTS_PATH, newProduct, true, {
			brandCode: brand.code,
		});

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};
