import { Fragment, useEffect, useState } from 'react';

//redux
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsMounted } from 'usehooks-ts';
import useHasRole from 'hooks/useHasRole';
import useHasPermission from 'hooks/useHasPermission';

//utils
import { footerElementsCount } from './PaymentsTable.helper';
import { completePaymentTransaction, refundPayment, checkPurchaseSubscription, checkPurchase } from '../services/PaymentsPage.services';

//types
import { PaymentsTableProps, PaymentsTableColumn } from './PaymentsTable.component.d';

//assets
import { ReactComponent as ChevronLeftIcon } from './assets/chevronLeft.svg';
import { ReactComponent as ChevronRightIcon } from './assets/chevronRight.svg';
import { ReactComponent as DownArrowIcon } from './assets/downArrowIcon.svg';
import { ReactComponent as AttachmentIcon } from './assets/attachmentIcon.svg';
import { ReactComponent as DescriptionIcon } from './assets/descriptionIcon.svg';

//style
import {
	FooterNavigationButton,
	FooterElementsCount,
	Table,
	TableBody,
	TableBodyCell,
	TableBodyRow,
	TableFooter,
	TableHead,
	TableHeadCell,
	TableHeadRow,
	DetailsButton,
	RefundButton,
	AssignButton,
	ConcludeBankButton,
	Buttons,
	BankInfo,
	UserLink,
	MiniBrandContainer,
	MiniBrand
} from './style/PaymentsTable.style';
import { Row } from 'style/layout';
import { PurchaseBasic } from 'types/PurchaseBasic';
import { Link } from 'react-router-dom';
import { Transaction } from 'types/Transaction';
import PaymentMethods from './PaymentMethods';
import { Brand } from 'types/Brand';

const columns: PaymentsTableColumn[] = [
	{ label: 'ID', width: '1%' },
	{ label: 'UTENTE', width: '25%' },
	{ label: 'DATA', width: '12%' },
	{ label: 'PRODOTTO', width: '20%' },
	{ label: 'TIPO', width: '5%' },
	{ label: 'BRAND', width: '5%' },
	{ label: 'IMPORTO', width: '5%' },
	{ label: 'STATO', width: '5%' },
	{ label: 'METODO', width: '2%'},
	{ label: 'INFO', width: '3%'},
	{ label: 'FUNZIONI', width: '7%' },
];

const PaymentsTable: React.FC<PaymentsTableProps> = ({
	data,
	totalPages,
	totalElements,
	pageSize,
	pageNumber,
	nextPageHandler,
	prevPageHandler,
	searchPayments,
	assignPaymentClick,
}) => {
	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isAdmin = useHasRole('ADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');
	const isAmministrazione = useHasRole('CONTABILITÀ');

	const dispatch = useAppDispatch();
	const isMounted = useIsMounted();

	const handleDetails = (paymentId: number) => {};

	const handleRefund = async (paymentId: number) => {
		if (window.confirm('Vuoi veramente rimborsare il pagamento #' + paymentId + '?')) {
			try {
				await refundPayment(paymentId);
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'success';
				snackbarData.message = `Pagamento rimborsato con successo!`;
				dispatch(addSnackbar(snackbarData));
				searchPayments(0);
			} catch (error) {
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'error';
				snackbarData.message = `C'è stato un problema nel rimborso del pagamento!`;
				dispatch(addSnackbar(snackbarData));
				throw error;
			}
		}
	};
	const handleCompleteTransaction = async (transactionId: number) => {
		try {
			await completePaymentTransaction(transactionId);
			const snackbarData: SnackbarOptions = {};
			snackbarData.type = 'success';
			snackbarData.message = `Bonifico concluso con successo!`;
			dispatch(addSnackbar(snackbarData));
		} catch (error) {
			const snackbarData: SnackbarOptions = {};
			snackbarData.type = 'error';
			snackbarData.message = `C'è stato un problema nel completamento del bonifico!`;
			dispatch(addSnackbar(snackbarData));
			throw error;
		}
	};

	const handleConcludeBank = async (purchase: PurchaseBasic) => {
		if (window.confirm('Vuoi veramente concludere il pagamento con bonifico #' + purchase.id + " e associare il prodotto all'utente?")) {
			const bankTransactions = purchase.transactions?.filter((t) => t.paymentType.name === 'Bonifico');
			if (bankTransactions?.length === 1) {
				const id = bankTransactions[0]?.id;
				await handleCompleteTransaction(id);
				searchPayments(0);
				return;
			} else {
				alert("Operazione non effettuata. Lo stato deve essere 'CREATED' o 'PARTIAL_PAID'");
			}
			if (purchase.transactions?.length > 1) {
				alert("Troppe transazioni non completate");
				return;
			}
			
		}
	};

	const handleCheckPurchaseSubscription = async (purchase: PurchaseBasic) => {
		if (window.confirm('Vuoi veramente controllare la subscription #' + purchase.id + " ?")) {
			const paypalTransactions = purchase.transactions?.filter((t) => t.paymentType.name.includes('Paypal'));
			if (paypalTransactions?.length >= 1) {
				const id = purchase.productSubscriptionId;
				try {
					await checkPurchaseSubscription(id);
					searchPayments(0);
					const snackbarData: SnackbarOptions = {};
					snackbarData.type = 'success';
					snackbarData.message = `Controllo concluso con successo!`;
					dispatch(addSnackbar(snackbarData));
				} catch (error) {
					const snackbarData: SnackbarOptions = {};
					snackbarData.type = 'error';
					snackbarData.message = `C'è stato un problema durante il controllo!`;
					dispatch(addSnackbar(snackbarData));
				}
				return;
			}
		}
	}

	const handleCheckPurchase = async (purchase: PurchaseBasic) => {
		if (window.confirm("Vuoi veramente controllare l'acquisto #" + purchase.id + " ?")) {
			const paypalTransactions = purchase.transactions?.filter((t) => t.paymentType.name.includes('Paypal'));
			if (paypalTransactions?.length >= 1) {
				const id = paypalTransactions[0].id;
				try {
					await checkPurchase(id);
					searchPayments(0);
					const snackbarData: SnackbarOptions = {};
					snackbarData.type = 'success';
					snackbarData.message = `Controllo concluso con successo!`;
					dispatch(addSnackbar(snackbarData));
				} catch (error) {
					const snackbarData: SnackbarOptions = {};
					snackbarData.type = 'error';
					snackbarData.message = `C'è stato un problema durante il controllo!`;
					dispatch(addSnackbar(snackbarData));
				}
				return;
			}
		}
	}

	return (
		<>
			<Table>
				<TableHead>
					<TableHeadRow>
						{columns.map((c) => (
							<TableHeadCell
								key={c.label}
								width={c.width}
							>
								{c.label}
							</TableHeadCell>
						))}
						<TableHeadCell key={'dettaglio'}></TableHeadCell>
					</TableHeadRow>
				</TableHead>
				<TableBody>
					{data.map((row, index) => (
						<TableBodyRow key={index}>
							<TableBodyCell>{row.id}</TableBodyCell>
							<TableBodyCell>
								<UserLink to={'/reservedArea/users/' + row.user?.id}>
									{row.user ? `${row.user?.id} | ${row.user?.name} ${row.user?.surname}` : ''}
								</UserLink>
							</TableBodyCell>
							<TableBodyCell
								title={
									'Pagamento: ' +
									new Date(row.creationDate)
										.toLocaleString()
										.concat(
											'\r',
											row.productSubscriptionExpirationDate
												? 'Scadenza: ' + new Date(row.productSubscriptionExpirationDate).toLocaleString()
												: ''
										)
								}
							>
								{new Date(row.creationDate).toLocaleDateString()}
							</TableBodyCell>
							<TableBodyCell>{row.product?.id} | {row.product?.name}</TableBodyCell>
							<TableBodyCell>{
								(row.product?.type === 'THEMATIC_BROADCAST' && row.product?.productThematicCall.type === 'ESPERIENZA') ?
									"ESPERIENZA" :
									(row.product?.type === 'THEMATIC_BROADCAST' && row.product?.productThematicCall.type !== 'ESPERIENZA') ?
									"DIRETTA" :
									row.product?.type
							}</TableBodyCell>
							<TableBodyCell>									
								<MiniBrandContainer>
								{row.product?.brands.map((branditem: Brand, index: number) => (
										<MiniBrand src={branditem.logo?.url} alt={branditem.code} title={branditem.code} />
								))}
								</MiniBrandContainer>
							</TableBodyCell>							<TableBodyCell
								title={row.transactions?.reduce((prev, curr) => {
									if (curr.status === 'COMPLETED')
										return (prev += `${curr.paymentType.name}: ${
											curr.paymentType.name.includes('Punti')
												? (curr.netAmount + curr.fees).toLocaleString()
												: curr.gross.toLocaleString()
										}\n`);
									return prev;
								}, '')}
							>
								{row.amount.toLocaleString()}
							</TableBodyCell>
							<TableBodyCell title={row.status}>{row.status.slice(0,10)}</TableBodyCell>
							<TableBodyCell>
								<PaymentMethods purchase={row}></PaymentMethods>
							</TableBodyCell>
							<TableBodyCell>
								<BankInfo>
									{row.file && (
										<a
											href={row.file?.url}
											target='_blank'
										>
											<AttachmentIcon></AttachmentIcon>
										</a>
									)}
									{row.invoiceData && (
										<DescriptionIcon
											title={`${row.invoiceData.name} ${row.invoiceData.surname}\n${row.invoiceData.cfOrPi}\n${row.invoiceData.address} ${row.invoiceData.cap} ${row.invoiceData.city}\n${row.invoiceData.province} ${row.invoiceData.country}`}
										></DescriptionIcon>
									)}
								</BankInfo>
							</TableBodyCell>
							<TableBodyCell align={'center'}>
								<Buttons>
									{/* <DetailsButton onClick={() => handleDetails(row['id'])}>Dettagli</DetailsButton> */}
									{!isMiniAdmin &&
										row.status !== 'CREATED' &&
										row.status !== 'REFUNDED' &&
										row.status !== 'CANCELED' &&
										row.user &&
										row.amount > 0 && <RefundButton onClick={() => handleRefund(row.id)}>Rimborsa</RefundButton>}
									{row.amount > 0 && row.status !== 'CREATED' && row.status !== 'REFUNDED' && row.status !== 'CANCELED' && !row.user && (
										<AssignButton onClick={() => assignPaymentClick(row)}>Associa</AssignButton>
									)}
									{row.user &&
										row.transactions?.some((t) => t.paymentType.name === 'Bonifico') &&
										(row.status === 'CREATED' || row.status === 'PARTIAL_PAID') &&
										row.invoiceData && <ConcludeBankButton onClick={() => handleConcludeBank(row)}>Concludi</ConcludeBankButton>}
									{row.transactions?.some((t) => t.paymentType.name.includes('Paypal')) && row.product?.type === 'SERVICE' && (
										<AssignButton onClick={() => handleCheckPurchaseSubscription(row)}>Controlla</AssignButton>)
									}
									{row.transactions?.some((t) => t.paymentType.name.includes('Paypal')) && row.product?.type !== 'SERVICE' && (
										<AssignButton onClick={() => handleCheckPurchase(row)}>Controlla</AssignButton>)
									}
								</Buttons>
							</TableBodyCell>
						</TableBodyRow>
					))}
				</TableBody>
			</Table>
			{totalElements > 0 && (
				<TableFooter>
					<FooterElementsCount>{footerElementsCount({ currentPage: pageNumber, totalPages, totalElements, pageSize })}</FooterElementsCount>
					<FooterNavigationButton
						onClick={prevPageHandler}
						disabled={pageNumber === 1}
					>
						<ChevronLeftIcon />
					</FooterNavigationButton>
					<FooterNavigationButton
						onClick={nextPageHandler}
						disabled={pageNumber === totalPages}
					>
						<ChevronRightIcon />
					</FooterNavigationButton>
				</TableFooter>
			)}
		</>
	);
};

export default PaymentsTable;
