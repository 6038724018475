import styled, { css } from 'styled-components';

import colors from 'style/palette/palette';
import { CourseNode } from 'types/Course';

export const ContentNodeBox = styled.div<{ isDragging: boolean; level: number; hasLesson: boolean }>`
	display: flex;
	align-items: center;
	gap: 1rem;
	border: 1px solid;
	border-radius: 0.25rem;
	width: 100%;
	padding: 0.5rem 1rem;
	justify-content: space-between;
	color: ${colors.white};

	${({ level }) =>
		level === 0 &&
		css`
			border-color: ${colors.black};
			background-color: ${colors.black};
		`}

	${({ level }) =>
		level === 1 &&
		css`
			width: 97.5%;
			border-color: ${colors.black053};
			background-color: ${colors.black053};
		`}

	${({ level }) =>
		level === 2 &&
		css`
			width: 95%;
			border-color: ${colors.black014};
			background-color: ${colors.black014};
		`}

	${({ isDragging }) =>
		isDragging &&
		css`
			opacity: 0;
		`}

	${({ hasLesson }) =>
		hasLesson &&
		css`
			border-color: ${colors.lightBlue};
		`}
`;

export const Handle = styled.div<{ level: number; node: CourseNode }>`
	> svg {
		cursor: grab;
		width: 15px;
		height: 15px;
		> path {
			fill: ${colors.white};
		}
	}
`;

export const ExpandCollapse = styled.div`
	> svg {
		width: 15px;
		height: 15px;
		> path {
			fill: ${colors.white};
		}
	}
`;

export const Video = styled.div`
	cursor: pointer;
	> svg {
		width: 15px;
		height: 15px;
		> path {
			fill: ${colors.red};
		}
	}
`;

export const Attachment = styled.div`
	> svg {
		width: 15px;
		height: 15px;
		> path {
			fill: ${colors.white};
		}
	}
`;

export const Description = styled.div`
	> svg {
		width: 15px;
		height: 15px;
		> path {
			fill: ${colors.primaryColor};
		}
	}
`;

export const ModifyLesson = styled.div`
	display: flex;
	cursor: pointer;
	justify-self: flex-end;
	> svg {
		width: 15px;
		height: 15px;
		> path {
			fill: ${colors.lightBlue};
		}
	}
`;

export const AddLesson = styled.div`
	display: flex;
	cursor: pointer;
	justify-self: flex-end;
	> svg {
		width: 15px;
		height: 15px;
		> path {
			fill: ${colors.lightBlue};
		}
	}
`;

export const DeleteNode = styled.button`
	background-color: transparent;
	border: none;
	display: flex;
	cursor: pointer;
	justify-self: flex-end;
	> svg {
		width: 15px;
		height: 15px;
		> path {
			fill: ${colors.red};
		}
	}
`;

export const AddChildNode = styled.button`
	background-color: transparent;
	border: none;
	display: flex;
	cursor: pointer;
	justify-self: flex-end;
	> svg {
		width: 15px;
		height: 15px;
		> path {
			fill: ${colors.green};
		}
	}
`;

export const LeftSection = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
	width: 68%;

	> svg {
		cursor: pointer;
	}
`;

export const LessonSection = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 1rem;
	width: 7%;
`;

export const RightSection = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 0.25rem;
	width: 25%;
`;

export const NodeId = styled.div`
	font-size: 0.5rem;
`;

export const LessonId = styled.div`
	font-size: 0.5rem;
`;
