import { FC, Fragment } from 'react';

import { BreadCrumb, Root, TextSeparator, SubSectionLink, SubSectionText, SubSubSectionText } from './style/TitleBreadCrumb.style';
import { TitleBreadCrumbProps } from './TitleBreadCrumb';

const TitleBreadCrumb: FC<TitleBreadCrumbProps> = ({ index, subSectionText, subSubSectionText, subSectionLink }) => {
	const cleanLink = subSectionLink === undefined ? '' : subSectionLink;

	return (
		<BreadCrumb>
			{index && 'Dashboard'}
			{(!index || index === undefined) && <Root to='/reservedArea'>Dashboard</Root>}

			{subSectionText !== undefined && (
				<>
					<TextSeparator>/</TextSeparator>

					{subSubSectionText === undefined && <SubSectionText>{subSectionText}</SubSectionText>}
					{subSubSectionText !== undefined && subSectionLink !== undefined && <SubSectionLink to={cleanLink}>{subSectionText}</SubSectionLink>}
				</>
			)}

			{subSubSectionText !== undefined && (
				<>
					<TextSeparator>/</TextSeparator>
					{subSubSectionText.split('/').map((part, index, array) => (
					<Fragment key={index}>
						{index > 0 && <TextSeparator>/</TextSeparator>}
						<SubSubSectionText>{part}</SubSubSectionText>
					</Fragment>
    				))}
				</>
			)}
		</BreadCrumb>
	);
};

export default TitleBreadCrumb;
