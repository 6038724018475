import { FC, useEffect, useState } from "react";

// hooks
import useAppSelector from "hooks/useAppSelector";

//utils
import ChapterListItem from "./ChapterListItem/ChapterListItem.component";
import TitleListItem from "./TitleListItem/TitleListItem.component";
import LessonListItem from "./LessonListItem/LessonListItem.component";

//style
import {
    FlowBoxContainer,
} from './style/VideoCourseList.style';


const VideoCourseList: FC<{
    lessonSetting?: any, 
    courseItem?: any, 
    collapse: boolean, 
    collapseHandler: () => void,
    isLessonCompleted?: boolean | undefined
}> = ({
    lessonSetting, 
    courseItem, 
    collapse, 
    collapseHandler,
    isLessonCompleted
}) => {

    const [activeLesson, setActiveLesson] = useState<number>(0);     

    const activeLessonHandler = (lessonId: number) => {
        setActiveLesson(lessonId)
    }

    return (
        <FlowBoxContainer>

            {(courseItem !== undefined) && (
            courseItem.map((chapter: any, index: number) => {
                if (!chapter.parentId) {
                    return (
                        <ChapterListItem key={index} chapterElement={chapter} isOpen={collapse} collapseHandler={collapseHandler}>
                            {courseItem.map((title: any, index: number) => {
                                if (title.parentId === chapter.id) {
                                    if (title.lesson) {
                                        return <LessonListItem 
                                            key={title.id}
                                            lessonElement={title}
                                            lessonSetting={lessonSetting}
                                            activeHandler={activeLessonHandler}
                                            activeId={activeLesson}
                                            isLessonCompleted={isLessonCompleted}
                                        />; 
                                    }
                                    return (
                                        <TitleListItem key={index} titleElement={title} chapterIndex={chapter.pos} isOpen={collapse} collapseHandler={collapseHandler}>
                                            {courseItem.map((lesson: any, index: number) => {
                                                if (lesson.parentId === title.id) {
                                                    return <LessonListItem
                                                        key={lesson.id}
                                                        lessonElement={lesson}
                                                        lessonSetting={lessonSetting}
                                                        activeHandler={activeLessonHandler}
                                                        activeId={activeLesson}
                                                        isLessonCompleted={isLessonCompleted}
                                                    />;
                                                }
                                            })}
                                        </TitleListItem>
                                    )
                                }
                            })}
                        </ChapterListItem>
                    )
                }
                const lessonsId = courseItem.map((el: any, index: number) => el.id);
                if (!lessonsId.includes(chapter.parentId)) {
                    if (chapter.lesson) {
                        return <LessonListItem 
                            key={chapter.id} 
                            lessonElement={chapter} 
                            lessonSetting={lessonSetting} 
                            activeHandler={activeLessonHandler} 
                            activeId={activeLesson}
                            isLessonCompleted={isLessonCompleted}    
                        />; 
                    };
                    return (
                        <TitleListItem key={index} titleElement={chapter} chapterIndex={chapter.pos} isOpen={collapse} collapseHandler={collapseHandler}>
                            {courseItem.map((lesson: any, index: number) => {
                                if (lesson.parentId === chapter.id) {
                                    return <LessonListItem
                                        key={lesson.id}
                                        lessonElement={lesson}
                                        lessonSetting={lessonSetting}
                                        activeHandler={activeLessonHandler}
                                        activeId={activeLesson}
                                        isLessonCompleted={isLessonCompleted}
                                    />;
                                }
                            })}
                        </TitleListItem>
                    );
                }
            }))}
        </FlowBoxContainer>
    );
};


export default VideoCourseList;