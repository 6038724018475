import { FC, useEffect, useState } from 'react';

//redux
import { enableAppMain } from 'redux/slices/slice-app-main';
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';

//types
import { ModifyTestimonialModalProps } from './ModifyTestimonialModal.component.d';
import { Testimonial } from 'types/Testimonial';

//components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';
import TestimonialTitle from './TestimonialTitle';
import TestimonialVideo from './TestimonialVideo';

//utils
import { putTestimonial, uploadProfilePic, uploadVideo, uploadVideoCover } from 'pages/TestimonialsPage/services/testimonialsPage.services';

//style
import { ButtonsRow, LessonCancelSaveButton, LessonSaveButton, ModifyDescription, ModifyLessonBox } from './style/ModifyTestimonialModal.component.style';
import TestimonialVideoCover from './TestimonialVideoCover/TestimonialVideoCover.component';
import TestimonialProfilePic from './TestimonialProfilePic/TestimonialProfilePic.component';

const ModifyTestimonialModal: FC<ModifyTestimonialModalProps> = ({ testimonial, onCloseButtonModal, updateLocalTestimonial }) => {
	const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);

	const [localTitle, setLocalTitle] = useState('');
	const [localDescription, setLocalDescription] = useState('');

	const [localVideoUrl, setLocalVideoUrl] = useState('');
	const [localVideoCoverUrl, setLocalVideoCoverUrl] = useState('');
	const [localProfilePicUrl, setLocalProfilePicUrl] = useState('');

	const [isContentModified, setIsContentModified] = useState<boolean>(false);


	const dispatch = useAppDispatch();

	const onModified = () => {
		setIsContentModified(true);
	}

	const closeButtonHandler = () => {
		if (onCloseButtonModal) onCloseButtonModal();
		dispatch(enableAppMain());
	};

	const descriptionModifyHandler = (event: React.ChangeEvent<HTMLTextAreaElement>)=>  {
		setLocalDescription(event.currentTarget.value)
	}

	const onSaveClickHandler = async () => {
		//save testimonial title and description
		let updatedTestimonial = { id: testimonial.id, title: localTitle, description: localDescription } as Partial<Testimonial>;
		// const localVideo = testimonial.video ? { ...testimonial.video } : null;
		// if (localDescription || localVideoUrl) updatedTestimonial = { ...updatedTestimonial, description: localDescription, video: localVideo} as Testimonial;
		if (updatedTestimonial && (localTitle !== testimonial.title || localDescription !== testimonial.description)){
			try {
				await putTestimonial(updatedTestimonial);
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'success';
				snackbarData.message = `Testimonianza aggiornata con successo!`;
				dispatch(addSnackbar(snackbarData));
				updateLocalTestimonial(testimonial.id, {...testimonial, ...updatedTestimonial});
			} catch (error) {
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'error';
				snackbarData.message = `C'è stato qualche problema nel salvataggio della testimonianza!`;
				dispatch(addSnackbar(snackbarData));
				throw error;
			}
		}

		//update video
		if (((testimonial.video) && (localVideoUrl !== testimonial.video.url)) || (localVideoUrl)) {
			if (updatedTestimonial) {
				if (((testimonial.video==null)||(localVideoUrl !== testimonial.video.url))) {
					try {
						let uploadedVideo = await uploadVideo(testimonial.id, localVideoUrl);
						updatedTestimonial.video = uploadedVideo;
						console.log('updatedTestimonial Video', updatedTestimonial);
						updateLocalTestimonial(testimonial.id, {...testimonial, ...updatedTestimonial});
					} catch (error) {
						const snackbarData: SnackbarOptions = {};
						snackbarData.type = 'error';
						snackbarData.message = `C'è stato qualche problema nel salvataggio del video!`;
						dispatch(addSnackbar(snackbarData));
						throw error;
					}
				} else {
					// updatedNode.lesson.video = {} as LdLFile;
					// updatedNode.lesson.video.url = localVideoUrl;
					updatedTestimonial.video = testimonial.video;
				}
			}
		}

		//update videoCover
		if (((testimonial.videoCover) && (localVideoCoverUrl !== testimonial.videoCover.url)) || (localVideoCoverUrl)) {
			if (updatedTestimonial) {
				if (((testimonial.videoCover==null)||(localVideoCoverUrl !== testimonial.videoCover.url))) {
					try {
						let uploadedVideoCover = await uploadVideoCover(testimonial.id, localVideoCoverUrl);
						updatedTestimonial.videoCover = uploadedVideoCover;
						console.log('updatedTestimonial VideoCover', updatedTestimonial);
						updateLocalTestimonial(testimonial.id, {...testimonial, ...updatedTestimonial});
					} catch (error) {
						const snackbarData: SnackbarOptions = {};
						snackbarData.type = 'error';
						snackbarData.message = `C'è stato qualche problema nel salvataggio della video Cover!`;
						dispatch(addSnackbar(snackbarData));
						throw error;
					}
				} else {
					// updatedNode.lesson.video = {} as LdLFile;
					// updatedNode.lesson.video.url = localVideoUrl;
					updatedTestimonial.videoCover = testimonial.videoCover;
				}
			}
		}

		//update profilePic
		if (((testimonial.profilePicture) && (localProfilePicUrl !== testimonial.profilePicture.url)) || (localProfilePicUrl)) {
			if (updatedTestimonial) {
				if (((testimonial.profilePicture==null)||(localProfilePicUrl !== testimonial.profilePicture.url))) {
					try {
						let uploadedProfilePic = await uploadProfilePic(testimonial.id, localProfilePicUrl);
						updatedTestimonial.profilePicture = uploadedProfilePic;
						console.log('updatedTestimonial ProfilePic', updatedTestimonial);
						updateLocalTestimonial(testimonial.id, {...testimonial, ...updatedTestimonial});
					} catch (error) {
						const snackbarData: SnackbarOptions = {};
						snackbarData.type = 'error';
						snackbarData.message = `C'è stato qualche problema nel salvataggio della profile picture!`;
						dispatch(addSnackbar(snackbarData));
						throw error;
					}
				} else {
					// updatedNode.lesson.video = {} as LdLFile;
					// updatedNode.lesson.video.url = localVideoUrl;
					updatedTestimonial.profilePicture = testimonial.profilePicture;
				}
			}
		}

		if (onCloseButtonModal) onCloseButtonModal();
		dispatch(enableAppMain());
	};

	useEffect(() => {
		setLocalTitle(testimonial.title);
	}, [testimonial]);

	useEffect(() => {
		setLocalDescription(testimonial.description);
	}, [testimonial.description]);

	useEffect(() => {
		setLocalVideoUrl(testimonial.video?.url);
	}, [testimonial.video?.url]);

	useEffect(() => {
		setLocalVideoCoverUrl(testimonial.videoCover?.url);
	}, [testimonial.videoCover?.url]);

	useEffect(() => {
		setLocalProfilePicUrl(testimonial.profilePicture?.url);
	}, [testimonial.profilePicture?.url]);


	//check modifications to save
	useEffect(() => {
		setIsContentModified(
			localTitle !== testimonial.title ||
				localDescription !== testimonial.description ||
				localVideoUrl !== testimonial.video?.url ||
				localVideoCoverUrl !== testimonial.videoCover?.url ||
				localProfilePicUrl !== testimonial.profilePicture?.url 
		);
	}, [
		localDescription,
		localTitle,
		localVideoUrl,
		localProfilePicUrl,
		localVideoCoverUrl,
		testimonial.description,
		testimonial.video?.url,
		testimonial.title,
		testimonial.profilePicture?.url,
		testimonial.videoCover?.url
	]);

	return (
		<BaseModal
			// isBackdropClickActive={false}
			onClose={closeButtonHandler}
			isCloseButtonClicked={isCloseButtonClicked}
		>
			<BaseCard
				flavor='outlined'
				maxWidth='700px'
			>
				<ModifyLessonBox>
					<TestimonialTitle
						title={localTitle}
						setLocalTitle={setLocalTitle}
					/>
					Descrizione
					<ModifyDescription value={localDescription} onChange={descriptionModifyHandler}/>
					<TestimonialVideo
						videoUrl={localVideoUrl}
						setLocalVideoUrl={setLocalVideoUrl}
					/>
					<TestimonialVideoCover 
						videoCoverUrl={localVideoCoverUrl}
						setLocalVideoCoverUrl={setLocalVideoCoverUrl}
					/>
					<TestimonialProfilePic 
						profilePicUrl={localProfilePicUrl}
						setLocalProfilePicUrl={setLocalProfilePicUrl}
					/>
					<ButtonsRow>
						<LessonSaveButton
							disabled={!isContentModified}
							onClick={onSaveClickHandler}
						>
							Salva Modifiche
						</LessonSaveButton>
						<LessonCancelSaveButton onClick={closeButtonHandler}>Chiudi</LessonCancelSaveButton>
					</ButtonsRow>
				</ModifyLessonBox>
			</BaseCard>
		</BaseModal>
	);
};

export default ModifyTestimonialModal;
