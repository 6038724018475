import { FC, useEffect, useState } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

//hooks
import useAppSelector from 'hooks/useAppSelector';

//types
import { ThematicBroadcastTypesProps } from './ThematicBroadcastTypes.component.d';

//utils
import { getThematicBroadcastTypes } from './services/ThematicBroadcastTypes.services';

//style
import { ThematicBroadcastTypesBox } from './style/ThematicBroadcastTypes.component.style';
import { ColumnBox } from 'pages/UserDetailPage/style/UserDetailPage.component.style';
import useAppDispatch from 'hooks/useAppDispatch';

const ThematicBroadcastTypes: FC<ThematicBroadcastTypesProps> = ({ product, putProduct }) => {
	
	const [thematicBroadcastTypesList, setThematicBroadcastTypesList] = useState<string[]>([]);

	const dispatch = useAppDispatch();

	const tbGetList = async () => {
		const tbList = await getThematicBroadcastTypes();
		setThematicBroadcastTypesList(tbList);
		if (product.productThematicCall.type === 'ESPERIENZA') {
			setThematicBroadcastTypesList(['ESPERIENZA']);
		} else { 
			setThematicBroadcastTypesList(tbList.filter((tb: string) => tb !== 'ESPERIENZA'));
		}
	}

	const handleOnChangeSelect = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        const tbType = event.target.value;
		try {
			await putProduct({ id: product.id, productThematicCall: {...product.productThematicCall, type: tbType }});
		} catch (error) {
			const snackbarData: SnackbarOptions = {};
			snackbarData.type = 'error';
			snackbarData.message = "Errore nell'aggiornamento tipo diretta";
			dispatch(addSnackbar(snackbarData));
		}
	}

	useEffect(() => {
		tbGetList();
	}, []);

	useEffect(() => {
		if (thematicBroadcastTypesList.length === 0) return;
		if (product.productThematicCall.type) return;
		const setDefault = async () => {
			await putProduct({ id: product.id, productThematicCall: {...product.productThematicCall, type: thematicBroadcastTypesList[0] }});
		}
		setDefault();
	}, [product, thematicBroadcastTypesList]);

	return (
		<ThematicBroadcastTypesBox>
			<select id='thematicBroadcastTypeSelect' name='thematicBroadcastTypeSelect' onChange={handleOnChangeSelect}>
			{thematicBroadcastTypesList?.map((tbType: string, index: number) => {
				return (
					<option value={tbType} selected={product.productThematicCall.type === tbType} >{tbType}</option>
				);
			})}
			</select>
		</ThematicBroadcastTypesBox>
	);
};

export default ThematicBroadcastTypes;
