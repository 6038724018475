import { useState } from 'react';

const useCopyToClipboard = () => {
	const [copied, setCopied] = useState(false);

	const copyToClipboard = (element: React.RefObject<HTMLElement>) => {
		const text = element.current!.innerText;
		navigator.clipboard.writeText(text);
		setCopied(true);

		setTimeout(() => {
			setCopied(false);
		}, 1000);
	};

	return { copyToClipboard, copied };
};

export default useCopyToClipboard;
