// hook
import useAppSelector from 'hooks/useAppSelector';
import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useIsMounted } from 'usehooks-ts';

// utils
import { getProductData } from './services/PublicProductPage.services';

// types
import { Product } from 'types/Product.d';

// asset
import PublicProductPage from './layout/PublicProductPage/PublicProductPage';
import PublicProductPageDesktop from './layout/PublicProductPageDescktop/PublicProductPageDesktop';

/**
 * Public Product Page
 *
 * @author Oliver Megale
 *
 * @returns JSX
 */

const PublicProductPages: FC<{ product: Product, updateProductData: (n: number) => Promise<void>, videoOnly?: boolean }> = ({ product, updateProductData, videoOnly = false }) => {
	const { isAppMobile } = useAppSelector((state) => state.ui);
	const [productData, setProductData] = useState<any>(product);
	const location = useLocation();
	const isMounted = useIsMounted();

	const locationPath = location.pathname;
	// const productType = locationPath.split('/')[2];
	// const productId = locationPath.split('/')[3];

	// const getProduct = async () => {
	// 	try {
	// 		const response = await getProductData(productId);
	// 		if (!isMounted) return;
	// 		if (!response || response === undefined) return;

	// 		console.log('PRODUCT DATA:', response);
	// 		setProductData(response);
	// 	} catch (error: any) {
	// 		console.warn('Unable gettiing product data.');
	// 	}
	// };

	useEffect(() => {
		if (product) return setProductData(product);
		// if (!productId) return;

		// getProduct();
	}, [product]);

	if (!isAppMobile) return (
		<>
			{productData !== undefined && (
				<PublicProductPageDesktop 
					productData={productData} 
					updateProductData={updateProductData} 
					videoOnly={videoOnly}
				/>
			)}
		</>
	);

	return (
		<>
			{productData !== undefined && (
				<PublicProductPage 
					productData={productData} 
				/>
			)}
		</>
	);
};

export default PublicProductPages;
