const colors = {
	primaryColor: '#FFC000',
	orange: '#FFA726',
	red: '#FF5C50',
	darkRed: '#8b0000',
	green038: '#66BB6A60',
	green: '#66BB6A',
	lightBlue: '#29B6F6',
	blue: '#1877F2',
	black: '#000',
	black087: '#000000de',
	black053: '#00000087',
	black050: '#00000080',
	black020: '#00000033',
	black014: '#00000024',
	black012: '#0000001F',
	black000: '#00000000',
	blackGrey: '#222222',
	darkGrey: '#4C5862',
	veryDarkGrey: '#1B1F22',
	veryDarkGrey019: '#1B1F2230',
	veryDarkGrey000: '#1B1F2200',
	grey: '#29353E',
	grey400: '#BDBDBD',
	simpleGrey: '#616161',
	lightSimpleGrey: '474545',
	blueGrey: '#A9B8C2',
	lightGrey: '#9E9E9E',
	veryLightGrey: '#A0A4AC',
	white: '#FFF',
	white070: '#FFFFFFb3',
	white060: '#FFFFFF99',
	white056: '#FFFFFF8F',
	white050: '#FFFFFF80',
	white042: '#FFFFFF6b',
	white023: '#FFFFFF3b',
	white012: '#FFFFFF1F',
	white009: '#FFFFFF17',
	transparent: 'transparent',
	tableRowHover: '#90CAF914',
	cyan: '#3276b1',
};

export default colors;
