import { tree } from 'd3';
import { callGET, callPOST, callPUT } from 'network/network';

import { PoolTransactionsParams } from './PointsPage.services.d';

const _SELF_POOLS_SEARCH_PATH = 'v1/self/pools';
const _POOLS_BALANCE_PATH = 'v1/pools/balance';
const _POOLS_PATH = 'v1/pools';
const _POOL_TRANSACTIONS_PATH = 'v1/pools/transactions';
const _WITHDRAWAL_TYPES_PATH = 'v1/withdrawaltypes';
const _WITHDRAWAL_PATH = 'v1/pools/{poolId}/withdrawaltypes/{withdrawalTypeCode}';

export const getSelfPools = async () => {
	try {
		const response = await callGET(_SELF_POOLS_SEARCH_PATH, true);

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const getPoolsBalance = async () => {
	try {
		const response = await callGET(_POOLS_BALANCE_PATH, true);

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const movePointsBetweenPools = async (fromPoolId: number, toPoolId: number, amount: number, description: string) => {
	try {
		const params = { amount, description };
		const response = await callPUT(`${_POOLS_PATH}/${fromPoolId}/${toPoolId}/move`, {}, true, params);

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const getSinglePool = async (poolId: number) => {
	try {
		const response = await callGET(`${_POOLS_PATH}/${poolId}`, true);

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const getSinglePoolTransactions = async (poolId: number, page: number) => {
	try {
		const response = await callGET(_POOL_TRANSACTIONS_PATH, true, { poolId, size: 10, page });

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const getWithdrawalTypes = async () => {
	try {
		const response = await callGET(_WITHDRAWAL_TYPES_PATH, true, {});

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const postWithdraw = async (poolId: number, withdrawalTypeCode: string, amount: number) => {
	try {
		const response = await callPOST(
			_WITHDRAWAL_PATH.replace(/{poolId}/g, poolId.toString()).replace(/{withdrawalTypeCode}/g, withdrawalTypeCode),
			{},
			true,
			{ amount }
		);

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};
