import React, { FC, useCallback, useEffect } from 'react';

//hooks
import { useState } from 'react';
import useHasRole from 'hooks/useHasRole';

//types
import { Course, CourseNode, CourseLesson } from 'types/Course';
import { Level } from 'types/Level';
import { ContentContainerProps } from './ContentContainer.component.d';

//components
import ProductPage from 'pages/ProductPage/ProductPage.component';
import ContentNode from './ContentNode';

//assets
import { ReactComponent as AddBoxIcon } from './assets/addBoxIcon.svg';

//utils
import { addCourse, deleteCourseNode, postCourseNodeLesson, putCourseContent } from 'services/course.services';
import { deleteNodeAndDescendants, rebasePositions } from './ContentContainer.helper';
import { getEnvironmentTestBaseUrl } from 'configs/config.helper';

//style
import { AddNode, ContentContainerBox, ContentNodesBox, SaveButton, ButtonRow } from './style/ContentContainer.component.style';
import { getBrand } from 'configs/brandMap/brandMap.helper';

const ContentContainer: FC<ContentContainerProps> = ({ level, putLevel }) => {
	const [remoteNodes, setRemoteNodes] = useState<CourseNode[]>([]);
	const [localNodes, setLocalNodes] = useState<CourseNode[]>([]);
	const [isContentModified, setIsContentModified] = useState(false);
	const [isPreviewVisible, setIsPreviewVisible] = useState<boolean>(false);
	const [newLevel, setNewLevel] = useState<Level>(level);

	const isAdmin = useHasRole('ADMIN');
	const isSuperAdmin = useHasRole('SUPERADMIN');
	const userHasAdminRole = isAdmin || isSuperAdmin;

	const addNode = async (node: Partial<CourseNode>) => {
		try {
			const content = localNodes ? [...localNodes, node] : [node];
			const response = await putCourseContent(content);
			setRemoteNodes(response);
		} catch (error) {
			console.log(error);
		}
	};

	const createCourse = async () => {
		try {
			const newCourse: Course = await addCourse(level.description);
			newCourse.content = [];
			await putLevel({ id: level.id, course: newCourse });
			return newCourse.id;
		} catch (error) {
			console.log(error);
		}
	};

	const deleteNode = async (id: number) => {
		let newLocalNodes = [...localNodes];
		deleteNodeAndDescendants(newLocalNodes, id);
		try {
			await deleteCourseNode(id);
			setRemoteNodes(newLocalNodes);
		} catch (error) {
			console.log(error);
		}
	};

	const addLessonToNode = async (node: Partial<CourseNode>) => {
		try {
			const response = await postCourseNodeLesson({ ...node, lesson: {} as CourseLesson });
			console.log('response', response);

			const newRemoteNodes = [...remoteNodes];

			const nodeIndex = remoteNodes.findIndex((n) => n.id === node.id);
			console.log('nodeIndex', nodeIndex);
			newRemoteNodes[nodeIndex] = response;
			// setRemoteNodes(newRemoteNodes);

			const response2 = await putCourseContent(newRemoteNodes);
			setRemoteNodes(response2);

		} catch (error) {
			console.log(error);
		}
	};

	const updateLocalNode = (nodeId: number, updatedNode: Partial<CourseNode>) => {
		setLocalNodes((prevState) =>
			prevState.map((node) => {
				if (node.id === nodeId) return { ...node, ...updatedNode };
				return node;
			})
		);
	};

	const reArrangePositions = () => {
		const newLocalNodes = localNodes;
		rebasePositions(newLocalNodes);
		setLocalNodes(newLocalNodes);
	};

	const addNodeHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
		if (!level.course) { 
			const newCourseId = await createCourse();
			addNode({ courseId: newCourseId, title: 'Aggiungi Titolo', pos: 0 });
		}
		if (level.course) addNode({ courseId: level.course.id, title: 'Aggiungi Titolo', pos: localNodes.filter((n) => !n.parentId).length });
	};

	const saveContentHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
		if (window.confirm('Salvare le modifiche al corso del prodotto ' + level.description + ' ?')) {
			try {
				const response = await putCourseContent(localNodes);
				setRemoteNodes(response);
			} catch (error) {
				console.log(error);
			}
		}
	};

	const previewOpenHandler = () => setIsPreviewVisible(prevState => !prevState);

	useEffect(() => {
		if (!level) return; 
		setRemoteNodes(
			level.course?.content?.map((n) => {
				return {
					id: n.id,
					title: n.title,
					parentId: n.parentId,
					pos: n.pos,
					lesson: n.lesson,
					courseId: n.courseId,
				};
			})
		);
	}, [level, level.course?.content]);

	useEffect(() => {
		setLocalNodes(remoteNodes);
	}, [remoteNodes]);

	useEffect(() => {
		setIsContentModified(JSON.stringify(remoteNodes) !== JSON.stringify(localNodes));
	}, [localNodes, remoteNodes]);

	useEffect(() => {
		// console.log("LOCAL NODES:");
		// console.log(localNodes);
		reArrangePositions();
	}, [localNodes]);

	const moveNode = useCallback(
		(dragId: number, hoverId: number) => {
			// find the dragged and hovered nodes
			const dragNode = localNodes.find((n) => n.id === dragId) as CourseNode;
			const hoverNode = localNodes.find((n) => n.id === hoverId) as CourseNode;

			if (dragId !== hoverId && dragNode.parentId !== hoverNode.parentId) {
				//*************
				//move to a different parent
				//*************
				// console.log('different parent, hoverId: ', hoverId);

				// find the index of the new parent node
				const newParentId = hoverNode.parentId;
				// update the dragged node's parentId and pos properties IMMUTABILITY
				// const newLocalNodes = update(localNodes, {
				// 	[localNodes.indexOf(dragNode)]: {
				// 		parentId: { $set: newParentId },
				// 		pos: { $set: hoverNode.pos },
				// 	},
				// });
				// update the dragged node's parentId and pos properties
				const newLocalNodes = localNodes.map((n) => {
					if (n.id === dragId) return { ...n, parentId: newParentId, pos: hoverNode.pos };
					return n;
				});
				//update the pos property of the siblings
				const siblings = newLocalNodes.filter((n) => n.parentId === newParentId);
				siblings
					.sort((a, b) => a.pos - b.pos)
					.forEach((n, i) => {
						if (n.id !== dragId && n.pos >= hoverNode.pos) {
							console.log('sibling id: ', n.id, ' n.pos: ', n.pos);

							n.pos = n.pos + 1;
						}
					});
				//update the pos property of the old siblings
				const oldParentId = dragNode.parentId;
				const oldSiblings = newLocalNodes.filter((n) => n.parentId === oldParentId);
				oldSiblings.sort((a, b) => a.pos - b.pos).forEach((n, i) => (n.pos = i));

				// set the localNodes to the updated newLocalNodes array
				setLocalNodes(newLocalNodes);
			} else {
				//*************
				//move inside the same parent
				//*************

				// console.log('same parent, hoverId: ', hoverId);
				// find the index of the dragged and hovered nodes
				const dragIndex = localNodes.findIndex((n) => n.id === dragId);
				const hoverIndex = localNodes.findIndex((n) => n.id === hoverId);
				// update the pos property of the dragged node and its siblings IMMUTABILITY
				// const hoverParentId = localNodes[hoverIndex].parentId;
				// const newLocalNodes = update(localNodes, {
				// 	[dragIndex]: { pos: { $set: hoverNode.pos } },
				// 	[hoverIndex]: { pos: { $set: dragNode.pos } },
				// });
				// update the pos property of the dragged node and its siblings
				const newLocalNodes = localNodes.map((n) => {
					if (n.id === dragId) return { ...n, pos: hoverNode.pos };
					if (n.id === hoverId) return { ...n, pos: dragNode.pos };
					return n;
				});
				setLocalNodes(newLocalNodes);
			}
		},
		[localNodes]
	);

	const renderContentNode = useCallback(
		(node) => {
			return (
				<ContentNode
					key={node.id}
					node={node}
					realLevel={level}
					nodes={localNodes}
					moveNode={moveNode}
					updateLocalNode={updateLocalNode}
					reArrangePositions={reArrangePositions}
					addNode={addNode}
					deleteNode={deleteNode}
					addLessonToNode={addLessonToNode}
				/>
			);
		},
		[localNodes, moveNode]
	);

	useEffect(() =>  {
		if (!localNodes || localNodes.length === 0) return;
		if (!newLevel.course) return;

		const brand = getBrand();
		if (!brand) return;

		const prodUpload: Level = {...newLevel, course: {
			content: localNodes,
			// brands: newLevel.course.brands,
			brands: [
				brand
			],
			id: newLevel.course.id,
			title: newLevel.course.title
		}};
		setNewLevel(prodUpload);
	}, [localNodes]);
	// console.log("LEVEL:", level);
	// console.log("LN:", {...level,course: {content: localNodes}});
	
	return (
		<>
			<ContentContainerBox>
				<ButtonRow>
					{userHasAdminRole && (
						<SaveButton
							onClick={saveContentHandler}
							disabled={!isContentModified}
						>
							SALVA MODIFICHE
						</SaveButton>
					)}

					{(level.course !== undefined) && localNodes && (
						<SaveButton
							onClick={previewOpenHandler}
						>
							{!isPreviewVisible && `ANTEPRIMA`}
							
							{isPreviewVisible && `MODIFICA`}
						</SaveButton>)
					}

				</ButtonRow>

				{!isPreviewVisible && (
					<>
						<ContentNodesBox>
							{localNodes &&
								localNodes
									.filter((node) => !node.parentId)
									.sort((a, b) => {
										if (a.pos > b.pos) return 1;
										if (a.pos < b.pos) return -1;
										return 0;
									})
									.map((node) => renderContentNode(node))}
						</ContentNodesBox>

						{userHasAdminRole && (
							<AddNode onClick={addNodeHandler}>
								<AddBoxIcon /> Aggiungi Nodo
							</AddNode>
						)}
					</>
				)}

				{isPreviewVisible && (
					<ProductPage 
						product={{...level, course: {content: localNodes }}}
					/>
				)}
			</ContentContainerBox>

		</>
	);
};

export default ContentContainer;
