import { FC } from 'react';

//types
import { RoadmapEventsTableProps } from './RoadmapEventsTable.component.d';
import { RoadmapEventsTableColumn } from './RoadmapEventsTable.component.d';

//utils
import config from 'configs/config';
import { DateTime } from 'luxon';

//assets
import { ReactComponent as LinkIcon } from './assets/linkIcon.svg';
import DefaultEventPic from './assets/defaultEventPic.png';

//components
import Visible from './Visible';
import RowButtons from './RowButtons';

//style
import { Table, TableBody, TableBodyCell, TableBodyRow, TableHead, TableHeadCell, TableHeadRow, SellImage, EventName, Subscriptions } from './style/RoadmapEventsTable.style';
import useHasRole from 'hooks/useHasRole';



const url = window.location.origin;
const online = !url.includes('localhost');
const test = url.includes('ldl-test') || !online;

const RoadmapEventsTable: FC<RoadmapEventsTableProps> = ({ data, putEvent }) => {

	var columns: RoadmapEventsTableColumn[] = [
		{ label: '', width: '3%' },
		{ label: 'ID', width: '2%' },
		{ label: 'IMG', width: '10%' },
		{ label: 'NOME', width: '45%' },
		{ label: 'DATA', width: '20%' },
		// { label: 'METODI PAGAMENTO', width: '5%' },
		// { label: 'PESO', width: '5%' },
		{ label: 'VISIBILE', width: '' },
		// { label: 'BRAND', width: '5%' },
		// { label: 'PRODOTTI', width: '4%' },
		// {label:'AUTOSTRADA',width: '1%'},
		// {label:'LEZ. AP',width: '1%'},
		// { label: 'STATS', width: '5%' },
		// { label: 'NOTE', width: '2%' },
	];

	const isAdmin = useHasRole('ADMIN');
	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');

	const hasAdminRole = isAdmin || isSuperAdmin;

	const isValidDate = (dateToCheck: Date | string): boolean => {
		if (!dateToCheck) return false;
		try {
			const testDate = new Date(dateToCheck);
			return true;
		} catch (err) {
			return false;
		}
	};

	const showDate = (originalDate: string): string => {
		if (!originalDate) return "";
		const dateDate = new Date(originalDate);
		const utcDateTime = DateTime.fromISO(originalDate, { zone: 'utc' });
		const localDateTime = utcDateTime.toLocal();
		if (dateDate.getHours()>=0 && dateDate.getHours()<=3) return localDateTime.toFormat('dd/MM/yyyy');
		return localDateTime.toFormat('dd/MM/yyyy HH:mm');
	}


	return (
		<>
			<Table>
				<TableHead>
					<TableHeadRow>
						{columns.map((c) => (
							<TableHeadCell
								key={c.label}
								width={c.width}
								// onClick={() => handleSort(key)}
							>
								{c.label.toUpperCase()}
								{/* {sortColumn === c && <span>{sortDirection === 'asc' ? '⬆️' : '⬇️'}</span>} */}
							</TableHeadCell>
						))}
					</TableHeadRow>
				</TableHead>
				<TableBody>
					{data.map((row, index) => (
						<TableBodyRow key={index}>
							<TableBodyCell
								key={index + '_buttons'}
								id={index + '_buttons'}
							>
								<RowButtons eventId={row.id} />
							</TableBodyCell>

							<TableBodyCell
								key={index + '_id'}
								id={index + '_id'}
							>
								{row.id}
							</TableBodyCell>

							<TableBodyCell
								key={index + '_image'}
								id={index + '_image'}
							>
								{row.customImage?.url && <SellImage
									alt=''
									src={row.customImage?.url ? row.customImage.url : DefaultEventPic}
								/>}
							</TableBodyCell>						

							<TableBodyCell
								key={index + '_name'}
								id={index + '_name'}
							>
								<EventName>
									{row.name}
								</EventName>
							</TableBodyCell>
							
							<TableBodyCell
								key={index + '_datetime'}
								id={index + '_datetime'}
							>
								<EventName>
									{row.tbd ? "" : showDate(row.beginDate)}
								</EventName>
							</TableBodyCell>

							<TableBodyCell
								key={index + '_visible'}
								id={index + '_visible'}
							>
								<Visible
									eventId={row.id}
									visible={row.visible}
									putEvent={putEvent}
								/>
							</TableBodyCell>

						</TableBodyRow>
					))}
				</TableBody>
			</Table>

			{/* {totalElements > 0 && (
				<TableFooter>
					<FooterElementsCount>{footerElementsCount({ currentPage: pageNumber, totalPages, totalElements, pageSize })}</FooterElementsCount>
					<FooterNavigationButton
						onClick={prevPageHandler}
						disabled={pageNumber === 1}
					>
						<ChevronLeftIcon />
					</FooterNavigationButton>
					<FooterNavigationButton
						onClick={nextPageHandler}
						disabled={pageNumber === totalPages}
					>
						<ChevronRightIcon />
					</FooterNavigationButton>
				</TableFooter>
			)} */}
		</>
	);
};

export default RoadmapEventsTable;
