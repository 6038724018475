import { updateSalesData } from 'pages/PublicProductPages/services/PublicProductPage.services';
import { FC, useRef } from 'react';

// type
import { BulletListUpdateElementProps } from './BulletListUpdateElement.component.d';

// style
import { 
    CardContent,
    ButtonRow,
    RowContent,
    CloseAndCancel,
    SaveAndCloseButton,
    DeleteElementButton,
    UrlInput,
    UrlLabel    
} from './style/BulletListUpdateElement.style';


const BulletListUpdateElement: FC<BulletListUpdateElementProps> = ({
    onClose,
    updateData,
    productId,
    elementToDisplay,
    isRequired
}) => {
    
    const bullet1Ref = useRef<HTMLInputElement | null>(null);
    const bullet2Ref = useRef<HTMLInputElement | null>(null);
    const bullet3Ref = useRef<HTMLInputElement | null>(null);

    const updateBulletList = async () => {

        // to do snackbar and spinner
        try {

            if ((bullet1Ref.current === null) && (bullet2Ref.current === null) && (bullet3Ref.current === null)) return;
            if (productId === undefined) return;

            const bulletListData1 = (bullet1Ref.current !== null) ? ((bullet1Ref.current.value !== '') ? bullet1Ref.current.value : elementToDisplay.finalBulletList1) : elementToDisplay.finalBulletList1;
            const bulletListData2 = (bullet2Ref.current !== null) ? ((bullet2Ref.current.value !== '') ? bullet2Ref.current.value : elementToDisplay.finalBulletList2) : elementToDisplay.finalBulletList2;
            const bulletListData3 = (bullet3Ref.current !== null) ? ((bullet3Ref.current.value !== '') ? bullet3Ref.current.value : elementToDisplay.finalBulletList3) : elementToDisplay.finalBulletList3;
            const updateBulletListData = {
                productId,
                bulletList: [
                    bulletListData1,
                    bulletListData2,
                    bulletListData3
                ]
            };
            if (productId) await updateSalesData(updateBulletListData);
            return;
        } catch (error: any) {
            console.warn("ERROR:", error);
        }
    };
    
    const onCancelClickHandler = () => {
        onClose();
    };
    
    const onSaveAndCloseHandler = async () => {
        
        await updateBulletList();
        if (updateData) await updateData();
        onClose();
    };

    return (
        <CardContent>
            <RowContent>
                Inserisci solo i punti che vuoi cambiare
                <UrlLabel>
                    Nuovo testo del primo punto

                    <UrlInput 
                        ref={bullet1Ref}
                        placeholder={elementToDisplay.finalBulletList1}
                    />
                </UrlLabel>

                <UrlLabel>
                    Nuovo testo del secondo punto

                    <UrlInput 
                        ref={bullet2Ref}
                        placeholder={elementToDisplay.finalBulletList2}
                    />
                </UrlLabel>

                <UrlLabel>
                    Nuovo testo del terzo punto

                    <UrlInput 
                        ref={bullet3Ref}
                        placeholder={elementToDisplay.finalBulletList3}
                    />
                </UrlLabel>
            </RowContent>

            <ButtonRow>
                {/* {!isRequired && (
                    <DeleteElementButton>
                        Elimina la Lista
                    </DeleteElementButton>
                    )
                } */}

                <CloseAndCancel
                        onClick={onCancelClickHandler}
                >
                    Annulla e Chiudi
                </CloseAndCancel>

                <SaveAndCloseButton
                        onClick={onSaveAndCloseHandler}
                >
                    Salva e Chiudi
                </SaveAndCloseButton>
            </ButtonRow>
        </CardContent>
    );
};

export default BulletListUpdateElement;
