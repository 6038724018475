import { Auth } from 'aws-amplify';

// types
import { ChangePasswordWithConfirmationPayload } from './PasswordChangeForm.services.d';


export const changePasswordWithConfirmation = async (payload: ChangePasswordWithConfirmationPayload) => {

    try {

        const response = await Auth.forgotPasswordSubmit(payload.username, payload.code, payload.newPassword);

        if (response === undefined || !response) {
            throw new Error();
        }
        
        return response;
        
    } catch (error: any) {
        throw error;
    }
};
