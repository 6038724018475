import styled from 'styled-components';

import colors from 'style/palette/palette';

export const ProductSubscriptionsBox = styled.div`
	padding: 1rem;
	display: flex;
	gap: 0.25rem;
	flex-wrap: wrap;
`;
