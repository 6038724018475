import styled from 'styled-components';
import { Button } from 'style/buttons';
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';


export const Form = styled.form`

    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
`;

export const SendNewPasswordButton = styled(Button)`

    width: 100%;

    margin: 24px 0 0;
    padding: 15px 8px;

    font-size: 15px;
    text-transform: uppercase;

    color: ${colors.black087};

    @media ${devices.tablet} {

        letter-spacing: normal;
    }
`;

export const TextSeparator = styled.p`

    margin: 24px 0 0;
    padding: 0;

    font-size: 15px;
    font-weight: 500;
    text-align: center;

    color: ${colors.white};
`;
