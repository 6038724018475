// types
import { AppDispatch } from '../store';
import { Role } from 'types/Role';
import { Category } from 'types/Category';
import { SubCategory } from 'types/SubCategory';
import { Product } from 'types/Product';
import { Level } from 'types/Level';
import { User } from 'types/User';

// slice actions
import {
	setAllRoles,
	setAllCategories,
	setAllSubCategories,
	setAllProducts,
	setAllLevels,
	setStaffUsers,
	setAuthorPoolsUsers,
	setAuthorPools,
	setPaymentTypes,
} from '../slices/slice-global';
import { Pool } from 'types/Pool';
import { PaymentType } from 'types/PaymentType';

export const saveAllRoles = (roles?: Role[] | undefined) => {
	return (dispatch: AppDispatch) => {
		dispatch(setAllRoles(roles));
	};
};

export const saveAllCategories = (categories?: Category[] | undefined) => {
	return (dispatch: AppDispatch) => {
		dispatch(setAllCategories(categories));
	};
};

export const saveAllSubCategories = (subCategories?: SubCategory[] | undefined) => {
	return (dispatch: AppDispatch) => {
		dispatch(setAllSubCategories(subCategories));
	};
};

export const saveAllProducts = (products?: Product[] | undefined) => {
	return (dispatch: AppDispatch) => {
		dispatch(setAllProducts(products));
	};
};

export const updateProduct = (products: Product[], updatedProduct: Partial<Product>) => {
	return (dispatch: AppDispatch) => {
		const newProducts = [...products];
		const updatingIndex = newProducts.findIndex((p) => p.id === updatedProduct.id);
		newProducts[updatingIndex] = { ...newProducts[updatingIndex], ...updatedProduct };
		dispatch(setAllProducts(newProducts));
	};
};

export const saveAllLevels = (levels?: Level[] | undefined) => {
	return (dispatch: AppDispatch) => {
		dispatch(setAllLevels(levels));
	};
};

export const updateLevel = (levels: Level[], updatedLevel: Partial<Level>) => {
	return (dispatch: AppDispatch) => {
		const newLevels = [...levels];
		const updatingIndex = newLevels.findIndex((p) => p.id === updatedLevel.id);
		newLevels[updatingIndex] = { ...newLevels[updatingIndex], ...updatedLevel };
		dispatch(setAllLevels(newLevels));
	};
};

export const saveStaffUsers = (staffUsers?: Partial<User>[] | undefined) => {
	return (dispatch: AppDispatch) => {
		dispatch(setStaffUsers(staffUsers));
	};
};

export const saveAuthorPoolsUsers = (authorPoolsUsers?: Partial<User>[] | undefined) => {
	return (dispatch: AppDispatch) => {
		dispatch(setAuthorPoolsUsers(authorPoolsUsers));

		if (authorPoolsUsers) {
			const tempArr: Pool[] = [{ name: '' } as Pool];
			authorPoolsUsers.forEach((u) => {
				u.pools?.forEach((p) => {
					if (!tempArr.some((tp) => tp.id === p.id))
						return p.type === 'PERSONAL' || p.type === 'STANDARD' || p.type === 'TEMPORARY' || p.type === 'INITIATIVE' ? tempArr.push(p) : null;
				});
			});
			tempArr.sort((a, b) => {
				if (a.name.toLowerCase() < b.name.toLowerCase()) {
					return -1;
				}
				if (a.name.toLowerCase() > b.name.toLowerCase()) {
					return 1;
				}
				return 0;
			});
			dispatch(setAuthorPools(tempArr));
		}
	};
};

export const savePaymentTypes = (paymentTypes?: PaymentType[] | undefined) => {
	return (dispatch: AppDispatch) => {
		dispatch(setPaymentTypes(paymentTypes));
	};
};
