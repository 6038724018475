import styled from 'styled-components';

// asset
import colors from 'style/palette/palette';

export const TotalPage = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	width: 100%;
	max-width: 1440px;
	padding: 20px 40px 0px;
`;

export const TitleBox = styled.div`
	/* display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 20px 20px 10px; */
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1rem;

	margin: 0px;
	/* padding: 0px; */
	padding: 20px 20px 10px;

	font-weight: 400;
	font-size: 28px;

	color: ${colors.white};
`;

export const TitleB = styled.h2`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	margin: 0px;
	padding: 0px;

	font-weight: 400;
	font-size: 28px;

	color: ${colors.white};
`;

export const FirstTwo = styled.div`
	display: flex;
	flex-direction: row;
	align-items: stretch;

	padding: 0px;
	gap: 40px;

	width: 100%;
`;

export const MediaGrid = styled.div`
	display: flex;
	align-items: stretch;
	justify-content: center;

	overflow: hidden;

	width: 100%;
	height: 400px;

	border-radius: 15px;
`;

export const CardPrice = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	padding: 0px;

	width: 100%;
	max-width: 620px;

	background: #4c5862;

	box-shadow: 0px 3px 1px -2px ${colors.black020}, 0px 2px 2px ${colors.black014}, 0px 1px 5px ${colors.black012};
	border-radius: 15px;
`;

export const CardContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	padding: 0px;
`;

export const InfoPrice = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;
	gap: 25px;

	color: ${colors.white};
	font-size: 15px;
	line-height: 28px;
	width: 100%;
	padding: 30px 36px;
`;

export const TitleCard = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	margin: 0;
	padding: 0;

	font-weight: 400;
	font-size: 21px;
	line-height: 35px;

	color: ${colors.primaryColor};

	h3 {
		
		margin: 0;
		padding: 0;
	}
`;

export const TextCheck = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;

	flex-direction: column;
	gap: 30px;

	width: 100%;
	padding: 0px 16px;
	margin: 0px;

	> svg {
		margin: 0 17px 0 0;
		flex-shrink: 0;
		width: 20px;
		height: 20px;
	}
`;

export const TextP = styled.p`
	display: flex;
	flex-direction: row;
	align-items: center;

	padding: 0px 0px 8px;
	margin: 0px;

	font-weight: 500;
	font-size: 15px;
	line-height: 28px;

	color: ${colors.white};
`;

export const ButtonP = styled.button`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	align-self: center;

	margin: 20px 0 0;
	padding: 4px 15px;

	border: none;
	box-shadow: none;

	font-weight: 500;
	font-size: 13px;
	letter-spacing: 0.46px;

	background: ${colors.primaryColor};

	border-radius: 4px;
	box-shadow: 0px 3px 1px -2px ${colors.black020}, 0px 2px 2px ${colors.black014}, 0px 1px 5px ${colors.black012};
`;

export const SecondaCard = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	margin: 40px 0 0 0;
	padding: 30px 36px;
	width: 100%;

	background: ${colors.darkGrey};

	box-shadow: 0px 3px 1px -2px ${colors.black020}, 0px 2px 2px ${colors.black014}, 0px 1px 5px ${colors.black012};
	border-radius: 15px;

	text-align: center;

	p {
		font-size: 16px !important;
		line-height: 20px !important;
		font-weight: 400 !important;

		span {
			font-size: 16px !important;
			line-height: 20px !important;
			font-weight: 400 !important;
		}
	}

	h6 {
		font-size: 20px !important;
		line-height: 24px !important;
		font-weight: 500 !important;

		span {
			font-size: 20px !important;
			line-height: 24px !important;
			font-weight: 500 !important;
		}
	}

	h5 {
		font-size: 26px !important;
		line-height: 30px !important;
		font-weight: 500 !important;

		span {
			font-size: 26px !important;
			line-height: 30px !important;
			font-weight: 500 !important;
		}
	}

	h4 {
		font-size: 30px !important;
		line-height: 35px !important;
		font-weight: 600 !important;

		span {
			font-size: 30px !important;
			line-height: 35px !important;
			font-weight: 600 !important;
		}
	}

	h3 {
		font-size: 35px !important;
		line-height: 40px !important;
		font-weight: 600 !important;

		span {
			font-size: 35px !important;
			line-height: 40px !important;
			font-weight: 600 !important;
		}
	}

	h2 {
		font-size: 40px !important;
		line-height: 50px !important;
		font-weight: 700 !important;

		span {
			font-size: 40px !important;
			line-height: 50px !important;
			font-weight: 700 !important;
		}
	}

	h1 {
		font-size: 48px !important;
		line-height: 60px !important;
		font-weight: 700 !important;
		color: ${colors.primaryColor} !important;

		span {
			font-size: 48px !important;
			line-height: 60px !important;
			font-weight: 700 !important;
			color: ${colors.primaryColor} !important;
		}
	}
`;

export const TextBulllet = styled.div`
	/* display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    
    padding: 4px 0px 4px 0px;
    margin: 0px; */

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;

	padding: 4px 0px 4px 0px;
	margin: 0px;

	font-weight: 500;
	font-size: 15px;
	line-height: 28px;

	color: ${colors.white};

	> svg {
		margin: 0 17px 0 0;
		flex-shrink: 0;
		width: 17px;
		height: 17px;
	}
`;

export const BlockText = styled.div`
	width: 100%;
	text-align: center;
`;

export const BlockImg = styled.img`
	max-width: 375px;
`;

export const BlockRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const BlockGroup = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	gap: 20px;
	flex-wrap: wrap;

	width: 100%;
`;

export const AddBlockButton = styled.button`
	justify-self: center;

	margin: 30px 0 0;
	padding: 25px;

	color: ${colors.black087};
	background: ${colors.primaryColor};
	font-size: 15px;
	font-weight: 600;

	border: none;
	border-radius: 15px;
	box-shadow: none;

	cursor: pointer;
`;
