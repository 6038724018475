import { FC } from 'react';

//types
import { TestimonialTitleProps } from './TestimonialTitle.component.d';

//style
import { LessonTitleBox, Title } from './style/TestimonialTitle.component.style';
import { Label } from '../style/ModifyTestimonialModal.component.style';

const TestimonialTitle: FC<TestimonialTitleProps> = ({ title, setLocalTitle }) => {
	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLocalTitle(event.currentTarget.value);
	};

	return (
		<LessonTitleBox>
			<Label>Titolo</Label>
			<Title
				value={title}
				onChange={onChangeHandler}
			></Title>
		</LessonTitleBox>
	);
};

export default TestimonialTitle;
