import { callGET, callPOST } from 'network/network';
import { RoadmapEvent } from 'types/RoadmapEvent';
import { RoadmapEventType } from 'types/RoadmapEventType';

import { Brand } from 'types/Brand';

const _ROADMAP_EVENTS_PATH = 'v1/roadmap/events';
const _ROADMAP_EVENT_TYPES_PATH = 'v1/roadmap/eventstypes';

export const getRoadmapEvents = async (usingBrand: Brand) => {
	if (!usingBrand) return;
	try {
		const response = await callGET(_ROADMAP_EVENTS_PATH, true, {brandCode: usingBrand.code});
		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const getRoadmapEventTypes = async (usingBrand: Brand) => {
	if (!usingBrand) return;
	try {
		const response = await callGET(_ROADMAP_EVENT_TYPES_PATH, true, {brandCode: usingBrand.code});
		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const addEvent = async (newEvent: Partial<RoadmapEvent>, brandCode: string) => {
	try {
		const response = await callPOST(_ROADMAP_EVENTS_PATH, newEvent, true, {
			brandCode: brandCode,
		});

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const addEventType = async (newEventType: Partial<RoadmapEventType>, brandCode: string) => {
	try {
		const response = await callPOST(_ROADMAP_EVENT_TYPES_PATH, newEventType, true, {
			brandCode: brandCode,
		});

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};
