import { FC, useEffect, useState } from "react";

// components
import MediaPlayer from "components/MediaPlayer";

// asset
import { ReactComponent as Vector } from './assets/Vector.svg';

// style
import { 
    ButtonP, 
    CardPrice, 
    GridCard, 
    TextPrice, 
    MediaGrid, 
    SecondaCard, 
    TextP, 
    TitleB, 
    TitleBox, 
    TitleCard, 
    TotalPage, 
    TextCheck,
    TextBulllet,
    BlockText,
    BlockImg,
    BlockRow,
    BlockGroup
} from "./style/PublicProductPage.style";


const PublicProductPage: FC<{productData: any}> = ({productData}) => {

    const [product, setProduct] = useState<any>(productData);

    const { name, salesData } = product;
    const bulletList = salesData?.bulletList;
    const headLine = salesData?.headLine;
    const purchaseButtonText = salesData?.purchaseButtonText;
    const salesPage = salesData?.salesPage;
    const blocks = salesPage?.blocks;

    const localMedia = {
        src: "https://www.viveredipoker.it/Libreria/Creare/Creare_Soldi_Bonus_Quanto_Guadagno.mp4",
        type: "video/mp4"
    };

    useEffect(() => {
        setProduct(productData);
    }, [productData]);

    const finalName = name !== null ? name : "TITOLO PRODOTTO";
    const finalHeadline = headLine !== null ? headLine : "HEADLINE";
    const finalBulletList1 = bulletList !== null ? bulletList[0] : "BULLET LIST";
    const finalBulletList2 = ((bulletList !== null) && bulletList[1]) ? bulletList[1] : "BULLET LIST";
    const finalBulletList3 = ((bulletList !== null) && bulletList[2]) ? bulletList[2] : "BULLET LIST";
    const finalPurchaseButtonText = purchaseButtonText !== null ? purchaseButtonText : "";
    const isBulletCardVisible = (headLine !== null) || (bulletList !== null);
    const isDescriptionCardVisible = (blocks !== null) || (purchaseButtonText !== null);

    const specialStyle = "<style type='text/css'>img { border-radius: 15px; } @media (max-width: 767px) { img { float: none; width: 100% !important; height: 100% !important; margin: 25px 0 !important; } } </style>";

    return(
        <TotalPage>
            <MediaGrid>
                <MediaPlayer
                    media={localMedia}
                    preload='medatada'
                />
            </MediaGrid>

            <TitleBox>
                <TitleB>
                    {name}
                </TitleB>
            </TitleBox>

            <GridCard>
                {isBulletCardVisible && (
                    <CardPrice>
                        <TitleCard dangerouslySetInnerHTML={{__html: finalHeadline}} />
                        
                        <TextPrice>
                            <TextBulllet>
                                <Vector/>

                                <TextP dangerouslySetInnerHTML={{__html: finalBulletList1}} />
                            </TextBulllet>

                            <TextBulllet>
                                <Vector/>
                                
                                <TextP dangerouslySetInnerHTML={{__html: finalBulletList2}} />
                            </TextBulllet>

                            <TextBulllet>
                                <Vector/>

                                <TextP dangerouslySetInnerHTML={{__html: finalBulletList3}} />
                            </TextBulllet>
                        </TextPrice>

                        <ButtonP>
                            {`${purchaseButtonText} €`}
                        </ButtonP>
                    </CardPrice>
                )}

                {isDescriptionCardVisible && (
                    <SecondaCard>                  
                        <TextPrice>
                            {(salesPage !== null) && (
                                <TextCheck>
                                    { blocks.map((block: any, index: number) => {

                                        if (block.layout === "SINGLE") {

                                            if (block.elements[0].type === 'TEXT') return <BlockText key={"BT" + index} dangerouslySetInnerHTML={{__html: block.elements[0].data}} />;
                                            if (block.elements[0].type === 'VIDEO') return <MediaPlayer key={"MP" + index} media={{src: block.elements[0].file.url, type: 'VIDEO/MP4'}} />;
                                            if (block.elements[0].type === 'IMAGE') return <BlockImg key={"I" + index} alt="" src={block.elements[0].file.url} />;
                                            if (block.elements[0].type === 'VSPACE') {
                                                for (let i = 0; i <= +block.elements[0].data; i++) {
                                                    return <br key={"BR" + index + i}/>;
                                                }
                                            };
                                        }

                                        if (block.layout === "ROW") {
                                            
                                            return (
                                                <BlockRow key={"R" + index}>
                                                    {block.elements.map((element: any, indexR: number) => {

                                                        if (element.type === 'TEXT') return <BlockText key={"BTR" + indexR} dangerouslySetInnerHTML={{__html: element.data}} />;
                                                        if (element.type === 'VIDEO') return <MediaPlayer key={"MPR" + indexR} media={{src: element.file.url, type: 'VIDEO/MP4'}} />;
                                                        if (element.type === 'IMAGE') return <BlockImg key={"IR" + indexR} alt="" src={element.file.url} />;
                                                        if (element.type === 'VSPACE') {
                                                            for (let i = 0; i <= +element.data; i++) {
                                                                return <br key={"BRR" + indexR + i}/>;
                                                            }
                                                        };
                                                    })}
                                                </BlockRow>
                                            );
                                        }

                                        if (block.layout === "GROUP") {
                                            
                                            return (
                                                <BlockGroup key={"G" + index}>
                                                    {block.elements.map((element: any, indexG: number) => {

                                                        if (element.type === 'TEXT') return <BlockText key={"BTG" + indexG} dangerouslySetInnerHTML={{__html: specialStyle+element.data}} />;
                                                        if (element.type === 'VIDEO') return <MediaPlayer key={"MPG" + indexG} media={{src: element.file.url, type: 'VIDEO/MP4'}} />;
                                                        if (element.type === 'IMAGE') return <BlockImg key={"IG" + indexG} alt="" src={element.file.url} />;
                                                        if (element.type === 'VSPACE') {
                                                            for (let i = 0; i <= +element.data; i++) {
                                                                return <br key={"BRG" + indexG + i}/>;
                                                            }
                                                        };
                                                    })}
                                                </BlockGroup>
                                            );
                                        }
                                    } )}
                                </TextCheck>
                            )}
                        </TextPrice>
                        
                    </SecondaCard>
                )}
            </GridCard>
        </TotalPage>
    );
};

export default PublicProductPage;