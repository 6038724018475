import { CourseNode } from 'types/Course';

export const getNodePath: (node: CourseNode, nodes: CourseNode[]) => string = (node, nodes) => {
	let path = node.pos.toString();
	const parentId = node.parentId;
	const parentNode = nodes.find((n) => n.id === parentId);
	const parentParentId = parentNode?.parentId;
	const parentParentNode = nodes.find((n) => n.id === parentParentId);
	if (parentId) path = parentNode?.pos.toString() + '-' + path;
	if (parentParentId) path = parentParentNode?.pos.toString() + '-' + path;
	return path;
};

export const getNodeLevel: (node: CourseNode, nodes: CourseNode[]) => number = (node, nodes) => {
	let level = 0;
	const parentId = node.parentId;
	const parentParentId = nodes.find((n) => n.id === parentId)?.parentId;
	if (parentId) level = 1;
	if (parentParentId) level = 2;
	return level;
};

export const getNodeDepth: (node: CourseNode, nodes: CourseNode[]) => number = (node, nodes) => {
	let depth = 0;
	const children = nodes.filter((n) => n.parentId === node.id);
	const hasChildren = children.length > 0;
	const hasChildrenChildren = children.some((c) => nodes.some((n) => n.parentId === c.id));
	if (hasChildren) depth = 1;
	if (hasChildrenChildren) depth = 2;
	return depth;
};

export const compareNodesPath: (node1Path: string, node2Path: string) => number = (node1Path, node2Path) => {
	let compare = 0;
	return compare;
};
