import { FC, useEffect, useState } from "react";

//assets
import { ReactComponent as PlayIconSVG } from './assets/play-icon.svg';
import { ReactComponent as DocIconSVG } from './assets/docIcon.svg';
import { ReactComponent as ToSeeIconSVG } from './assets/completed-icon.svg';
import { ReactComponent as ReproductionIconSVG } from './assets/pause-icon.svg';
import { ReactComponent as AttachmentIconSVG } from './assets/attachmentIcon.svg';

//style
import {
    Lesson,
    TypeLessonBox,
    StateLessonBox
} from './style/LessonListItem.style';
import useAppSelector from "hooks/useAppSelector";
import { updateLessonData } from "pages/ProductPage/services/ProductPage.services";

const LessonListItem: FC<{
    lessonElement: any,
    lessonSetting: any,
    activeHandler: (n: number) => void,
    activeId: number,
    lessonStatus?: any,
    isLessonCompleted: boolean | undefined
}> = ({
    lessonElement,
    lessonSetting,
    activeHandler,
    activeId, 
    lessonStatus,
    isLessonCompleted
}) => {

    // const { productsLessonsStatus } = useAppSelector(state => state.user);
    const [lessonStatusData, setLessonStatusData] = useState<any>(undefined);

    let title;
    let lessonId: number = 0;
    let video;
    let attachments;
    let isAttachmentsVisible;

    if (lessonElement !== undefined) {
        title = (lessonElement.title ?? "Manca il titolo...");
        lessonId = lessonElement.id;
    }

    if (lessonElement !== undefined && lessonElement.lesson !== undefined) {
        video = lessonElement.lesson.video;
        attachments = lessonElement.lesson.attachments;
        if ((attachments !== undefined) && (attachments !== null)) isAttachmentsVisible = attachments.length > 0;
    }

    const onClickButtonLessonHandler = () => {
        lessonSetting(lessonElement);
        activeHandler(lessonId);      
    };

    const getLessonStatus = (lessonId: number) => {
        // return productsLessonsStatus?.filter((lesson: any) => lesson.lessonId === lessonId)[0];
    };

    const updateLessonsDetails = () => {

        try {

            const updateData = {
                ...lessonStatusData,
                lastSeenDate: new Date().toISOString()
            };
            if (!lessonStatusData.completed && isLessonCompleted) updateData.completed = isLessonCompleted;

            updateLessonData(updateData);
        } catch (error: any) {
            
        }
    };

    
    useEffect(() => {
        
        if (lessonElement === undefined) return;
        if (lessonStatus !== undefined) return;

        const newLessonStatus = getLessonStatus(lessonId);
        setLessonStatusData(newLessonStatus);

        return () => {
            if (activeId === lessonId) updateLessonsDetails();
        };
        
    }, [lessonElement, lessonId, activeId]);

    const hasUserCompletedTheLesson = lessonStatusData?.completed;
    const isPlayIconVisible = (video && (video.id !== null)) && !hasUserCompletedTheLesson;
    const isPauseIconVisible = (activeId === lessonId);
    const isDocIconVisible = (!video || video === null);
    const isCompletedIconVisible = !(activeId === lessonId) && !isDocIconVisible && !isPlayIconVisible;
    // const isNewBadgeIconVisible = true;
    
    return (
        <Lesson isActive={activeId === lessonId} onClick={onClickButtonLessonHandler}>
            <StateLessonBox
                isCompleted={lessonStatusData?.completed === true}
                // isCompleted
                >
                {isPlayIconVisible && <PlayIconSVG />}
    
                {isDocIconVisible && <DocIconSVG />}

                {isCompletedIconVisible && <ToSeeIconSVG />}

                {isPauseIconVisible && <ReproductionIconSVG />}

                {title}          
            </StateLessonBox>

            {isAttachmentsVisible && <AttachmentIconSVG />}
        </Lesson>
    )
};

export default LessonListItem;