import styled from 'styled-components';

export const Page = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: stretch;
	flex: 1;

	padding: 0 30px;

	width: 100%;
	height: 100%;

	overflow: hidden;
`;

export const MainBox = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 100%;
	align-items: flex-start;
`;

export const RowBox = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	gap: 1rem;
	align-items: stretch;
`;

export const ColumnBox = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 1rem;
	align-items: stretch;
`;
