import React, { useState } from 'react';
import './StyledRadioButton.css'; // Import your CSS file for styling

interface StyledRadioButtonProps {
  label?: string;
  tooltip?: string;
  checked: boolean;
  name?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const StyledRadioButton: React.FC<StyledRadioButtonProps> = ({
  label,
  tooltip,
  checked,
  name,
  value,
  onChange,
}) => {
  return (
    <label className={`styled-radio-button ${label ? 'with-label' : ''}`}>
      <input type="radio" checked={checked} onChange={onChange} name={name} value={value}/>
      <span className="radio-indicator"></span>
      {label}
      {tooltip && (
        <span className="tooltip">
          {tooltip}
        </span>
      )}
    </label>
  );
};

export default StyledRadioButton;