import { FC, useRef } from 'react';

//hooks
import { useDrag, useDrop } from 'react-dnd';

//types
import { Product } from 'types/Product';
import { ProductDragItem } from './ProductBox.component.d';
import type { Identifier, XYCoord } from 'dnd-core';

//component
import ButtonShop from 'components/ButtonShop/ButtonShop.component';

//assets
import { ReactComponent as InfoIconSVG } from '../assets/infoIcon.svg';
import { ReactComponent as DragHandleIcon } from './assets/dragHandleIcon.svg';

//style
import {
	ProductGeneralBox,
	ProductTitle,
	ProductInnerBox,
	CourseImage,
	ProductInfoBox,
	ProductIconBox,
	InfoIconBox,
	ProductDescriptionBox,
	ProductDesciptionEllipsis,
	ProductDesciption,
	Handle,
} from './style/ProductBox.style';

/**
 * Course Box
 *
 * @author   Piero Foschi <19pfox81@gmail.com>
 *
 * @returns JSX
 */

export const ItemTypes = {
	PRODUCT: 'product',
};

const ProductBox: FC<{
	product: Product;
	index: number;
	moveProduct: (dragIndex: number, hoverIndex: number) => void;
}> = ({ product, index, moveProduct }) => {
	const { name, salesData, sellImage, productCourse, id, paymentTypes } = product;

	const dragRef = useRef<HTMLDivElement>(null);
	const previewRef = useRef<HTMLDivElement>(null);

	const [{ handlerId }, drop] = useDrop<ProductDragItem, void, { handlerId: Identifier | null }>({
		accept: ItemTypes.PRODUCT,
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
			};
		},
		hover(item: ProductDragItem, monitor) {
			if (!dragRef.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;

			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return;
			}

			// Determine rectangle on screen
			const hoverBoundingRect = dragRef.current?.getBoundingClientRect();

			// Get vertical middle
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

			// Determine mouse position
			const clientOffset = monitor.getClientOffset();

			// Get pixels to the top
			const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%

			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}

			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}

			// Time to actually perform the action
			moveProduct(dragIndex, hoverIndex);

			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex;
		},
	});

	const [{ isDragging }, drag, preview] = useDrag({
		type: ItemTypes.PRODUCT,
		item: () => {
			return { productId: product.id, index: index } as ProductDragItem;
		},
		collect: (monitor: any) => ({
			isDragging: monitor.isDragging(),
		}),
	});

	drag(dragRef);
	drop(preview(previewRef));

	const productImage = sellImage ? sellImage.url : '';

	return (
		<ProductGeneralBox
			ref={previewRef}
			isDragging={isDragging}
		>
			{/* <Handle>
				<DragHandleIcon />
			</Handle> */}
			<ProductTitle ref={dragRef}>{name}</ProductTitle>

			<ProductInnerBox>
				<ProductInfoBox>
					<CourseImage
						alt='Confezione Corso'
						src={productImage}
					/>

					{/* <ProductIconBox>
						<InfoIconBox onClick={infoButtonClickHandler}>
							<InfoIconSVG />
						</InfoIconBox>
					</ProductIconBox> */}
				</ProductInfoBox>

				<ProductDescriptionBox>
					<ProductDesciptionEllipsis>
						<ProductDesciption dangerouslySetInnerHTML={{ __html: salesData?.delivery }} />
					</ProductDesciptionEllipsis>

					<ButtonShop product={product} />
				</ProductDescriptionBox>
			</ProductInnerBox>
		</ProductGeneralBox>
	);
};

export default ProductBox;
