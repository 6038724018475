import { FC, useState } from 'react';

//utils
import { getSearchAuthorServicesManagement } from './services/AuthorServicesManagementPage.services';

//types
import { TitleBreadCrumbSettings } from 'components/DashboardTitleSection/DashboardTitleSection.component.d';
import { TabSelectorSettings, TabSelectorItem } from 'components/TabSelector/TabSelector.component.d';
import { AuthorServicesManagementTableItem } from './AuthorServicesManagementTable/AuthorServicesManagementTable.component.d';

//components
import DashboardTitleSection from 'components/DashboardTitleSection';
import AuthorServicesManagementTable from './AuthorServicesManagementTable/AuthorServicesManagementTable.component';

//assets
import { ReactComponent as SearchIconSVG } from './assets/searchIcon.svg';
import { ReactComponent as ClearButtonIconSVG } from './assets/clearButtonIcon.svg';

//style
import { BaseContentCard } from 'style/wrappers/wrappers';
import {
    Page,
    SearchChannelBox,
    SearchInput,
    SearchClearButton,
    SearchButton,
} from './style/AuthorServicesManagementPage.style';

const AuthorServicesManagementPage: FC<{}> = () => {
	const [searchInput, setSearchInput] = useState<any>('');
    const [searchResults, setSearchResults] = useState<AuthorServicesManagementTableItem[]>([]);
	const [totalPages, setTotalPages] = useState<number>(0);
	const [totalElements, setTotalElements] = useState<number>(0);
	const [pageSize, setPageSize] = useState<number>(0);

    const clearSearchHandler = () => {
		setSearchInput('');
	};

	const searchChangeHandler = (searchValue: any) => {
		setSearchInput(searchValue.target.value);
	};

	const searchKeyHandler = (event: any) => {
		if (event.keyCode === 27) {
			clearSearchHandler();
		}
	};

    const searchAuthorServicesManagement = async (page: number) => {
        // const response = await getSearchAuthorServicesManagement({ q: searchInput, page: page - 1, size: 10 });
        const ddd = new Date("2022-12-27T11:04:45.019Z");
        const response = {
            "content": [
              {
                id: 1458,
                user: "Simone Riccetti",
                email: "riccetti.simone@gmail.com",
                subscription: "test",
                date: ddd,
                deadline: ddd,
              },
              {
                id: 1458,
                user: "Ayeye Brazoff",
                email: "ayeye4ever@gmail.com",
                subscription: "test 2",
                date: ddd,
                deadline: ddd,
              },
            ],
            "pageable": {
              "sort": {
                "sorted": true,
                "unsorted": false,
                "empty": false
              },
              "pageNumber": 0,
              "pageSize": 10,
              "offset": 0,
              "paged": true,
              "unpaged": false
            },
            "last": false,
            "totalPages": 64,
            "totalElements": 638,
            "first": true,
            "number": 0,
            "sort": {
              "sorted": true,
              "unsorted": false,
              "empty": false
            },
            "numberOfElements": 10,
            "size": 10,
            "empty": false
        };
        setSearchResults(
            response.content //.map(({ id, customer, giftdate }: AuthorServicesManagementNewTableItem) => ({ id, cliente, data_regalo }))
        );
        setTotalElements(response.totalElements);
        setTotalPages(response.totalPages);
        setPageSize(response.size);
    };

    const breadCrumbSettings: TitleBreadCrumbSettings = { subSectionText: 'Gestione Sottoscrizioni' };

    return(
        <Page>
            <DashboardTitleSection breadCrumbSettings={breadCrumbSettings}>
                <SearchChannelBox>
                    <SearchInput
                        onKeyDown={searchKeyHandler}
                        onChange={searchChangeHandler}
                        value={searchInput}
                    />

                    {searchInput !== '' && (
                        <SearchClearButton onClick={clearSearchHandler}>
                            <ClearButtonIconSVG />
                        </SearchClearButton>
                    )}

                    <SearchButton>
                        <SearchIconSVG />
                    </SearchButton>
                </SearchChannelBox>
            </DashboardTitleSection>


            <BaseContentCard>
                <AuthorServicesManagementTable
                    data={searchResults}
                    totalElements={totalElements}
                    totalPages={totalPages}
                    pageSize={pageSize}
                    searchAuthorServicesManagement={searchAuthorServicesManagement}
                />
            </BaseContentCard>
        </Page>
    );

}

export default AuthorServicesManagementPage;
