import styled from 'styled-components';

export const Page = styled.div`
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: stretch;

	flex: 1;

	padding: 0 30px;

	width: 100%;
	height: 100%;

	overflow: hidden;
`;

export const MainBox = styled.div`
	display: flex;
	flex-direction: column;

	width: 100%;
	gap: 1rem;
`;
