import { configureStore } from '@reduxjs/toolkit';

// slices
import userReducer from './slices/slice-user';
import uiReducer from './slices/slice-ui';
import appMainReducer from './slices/slice-app-main';
import snackbarReducer from './slices/slice-snackbar';
import globalReducer from './slices/slice-global';

/* store map to provide to the toolkit provider ctx */
const store = configureStore({
	reducer: {
		user: userReducer,
		ui: uiReducer,
		appMain: appMainReducer,
		snackbar: snackbarReducer,
		global: globalReducer,
	},

	middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
