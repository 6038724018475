import { FC, useEffect, useState } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';

//types
import { ProductSubscriptionProps } from './ProductSubscription.component.d';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

//utils
import { softDeleteProductSubscription } from 'services/subscriptions.services';

//style
import { ProductSubscriptionBox } from './style/ProductSubscription.component.style';

const ProductSubscription: FC<ProductSubscriptionProps> = ({ productSubscription, updateLocalUser, isClickable }) => {
	const [isDeleted, setIsDeleted] = useState(false);
	const [isService, setIsService] = useState(false);
	const [isServiceAutoRenew, setIsServiceAutoRenew] = useState(false);
	const [isServiceExpired, setIsServiceExpired] = useState(false);

	// VERDE: attivo con rinnovo automatico
	// ARANCIONE: attivo senza rinnovo automatico
	// ROSSO: scaduto

	useEffect(() => {
		console.log(productSubscription.deletionDate);

		if (productSubscription.deletionDate) setIsDeleted(true);
		if (productSubscription.productService) {
			setIsService(true);
			const today = new Date();
			const expirationDate = new Date(productSubscription.productService.expirationDate);
			if (expirationDate < today) setIsServiceExpired(true);
			if (productSubscription.productService.paypalSubscriptionId !== null) setIsServiceAutoRenew(true);
		}
	}, [productSubscription]);

	const dispatch = useAppDispatch();

	const onClickHandler = async () => {
		if (!isClickable) return;
		const snackbarData: SnackbarOptions = {};
		if (window.confirm('Eliminare assegnazione del prodotto ' + productSubscription.product.name + '?')) {
			try {
				await softDeleteProductSubscription(productSubscription.id);
				snackbarData.type = 'success';
				snackbarData.message = `Prodotto eliminato con successo dall'utente!`;
				dispatch(addSnackbar(snackbarData));
				updateLocalUser();
			} catch (error) {
				snackbarData.type = 'error';
				snackbarData.message = `C'è stato un problema nell'eliminazione del prodotto dall'utente!`;
				dispatch(addSnackbar(snackbarData));
				throw error;
			}
		}
	};

	return (
		<ProductSubscriptionBox
			title={isClickable ? 'Click per annullare assegnazione prodotto' : undefined}
			isService={isService}
			isDeleted={isDeleted}
			isExpired={isServiceExpired}
			isServiceAutoRenew={isServiceAutoRenew}
			onClick={onClickHandler}
			isClickable={isClickable}
		>
			{productSubscription.product.name}
		</ProductSubscriptionBox>
	);
};

export default ProductSubscription;
