import styled, { css } from "styled-components";

export const MainBox = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 100%;
	align-items: flex-start;
`;

export const RowBox = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	gap: 1rem;
	align-items: stretch;
`;

export const ColumnBox = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: start;
	gap: 1rem;
	align-items: stretch;
	padding: 0 15px 0 0;
`;

export const ComputedDiscount = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	color: #ffffff;
`;

export const InputBox = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

export const Label = styled.p`

`;

export const SimpleInput = styled.input`
	margin: 0 0 0 10px;
	height: 25px;
	width: 50px;
`;

export const SimpleSelect = styled.select`
	margin: 0 0 0 10px;
	height: 25px;
`;

export const CheckboxInput = styled.input.attrs({ 
    type: 'checkbox'})`
	width: 20px;
	height: 20px;
`;

export const ButtonsRowBox = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	gap: 1rem;
	align-items: stretch;
`;

export const CreateButton = styled.button`
    font-size: 1.2em;
    color: #ffffff;

    border: none;
    border-radius: 4px;
    background-color: rgb(102, 187, 106);

    cursor: pointer;

    ${(props: {disabled?: boolean}) => props.disabled && css`
        background-color: gray;
    `}
`;

export const ResetButton = styled.button`
    font-size: 1.2em;
    color: #ffffff;

    border: none;
    border-radius: 4px;
    background-color: #29B6F6;

    cursor: pointer;

    ${(props: {disabled?: boolean}) => props.disabled && css`
        background-color: gray;
    `}
`;

export const CancelButton = styled.button`
    font-size: 1.2em;
    color: #ffffff;

    border: none;
    border-radius: 4px;
    background-color: red;

    cursor: pointer;
`;