import { FC, useEffect, useState } from 'react';

//types
import { PointsProps } from './Points.component.d';

//utils
import { getUserVipPoints } from './services/Points.services';

//style
import { 
	PointsBox,
	Row,
	PoolName,
	PoolBalance,
} from './style/Points.component.style';

const Points: FC<PointsProps> = ({user}) => {
	const [personalBalance, setPersonalBalance] = useState<number>(0);
	const [stakingBalance, setStakingBalance] = useState<number>(0);
	const [vipBalance, setVipBalance] = useState<number>(0);

	useEffect(() => {
		var pool = user.pools.find((pool) => pool.type === 'PERSONAL'); 
		if (pool) setPersonalBalance(pool.balance);
		pool = user.pools.find((pool) => pool.type === 'STACKING'); 
		if (pool) setStakingBalance(pool.balance);
		const gp = async () => {
			if (!user) return;
			var vipBalance = await getUserVipPoints(user.id);
			if (vipBalance) setVipBalance(vipBalance);
		}
		gp();
	}, [user]);

	return (<PointsBox>
			<Row>
				<PoolName>
				Punti Libertà - Liberi:
				</PoolName>
				<PoolBalance>
					{personalBalance.toLocaleString()}
				</PoolBalance>
			</Row>
			<Row>
				<PoolName>
				Punti Libertà - Staking:
				</PoolName>
				<PoolBalance>
					{stakingBalance.toLocaleString()}
				</PoolBalance>
			</Row>
			<Row>
				<PoolName>
					Punti VIP:
				</PoolName>
				<PoolBalance>
					{vipBalance.toLocaleString()}
				</PoolBalance>
			</Row>

		</PointsBox>);
};

export default Points;
