import { FC } from 'react';

//types
import { ButtonsProps } from './RowButtons.component.d';

//components
import RowButton from './RowButton';

//hooks
import { useNavigate } from 'react-router-dom';

//assets
import { ReactComponent as ModifyIcon } from './assets/modifyIcon.svg';
import { ReactComponent as AddPlusIcon } from './assets/addPlus.svg';

//style
import { ButtonsBox } from './style/RowButtons.component.style';
import useHasRole from 'hooks/useHasRole';

const Buttons: FC<ButtonsProps> = ({ levelId }) => {
	const isAdmin = useHasRole('ADMIN');
	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');

	const navigate = useNavigate();

	const modifyButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
		if (event.ctrlKey || event.metaKey) {
			event.preventDefault();
			window.open('/reservedArea/levels/' + levelId.toString(), '_blank');
		} else {
			navigate(levelId.toString());
		}
	};

	const duplicateButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
		alert('TO-DO: duplicate product');
	};

	return (
		<ButtonsBox>
			{/* {isSuperAdmin && ( */}
				<RowButton
					title='Modifica'
					name='modify'
					ButtonIcon={ModifyIcon}
					clickHandler={modifyButtonHandler}
				></RowButton>
			{/* )} */}
			{/* <RowButton
				title='Duplica'
				name='duplicate'
				ButtonIcon={AddPlusIcon}
				clickHandler={duplicateButtonHandler}
			></RowButton> */}
		</ButtonsBox>
	);
};

export default Buttons;
