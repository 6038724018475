import { FC, useEffect, useState } from 'react';

//hooks
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';
import useHasRole from 'hooks/useHasRole';
import useHasPermission from 'hooks/useHasPermission';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

//utils
import { createCoach, getCoaches, getSearchAPLessons, assignLessonToAP } from './services/APLessonsPage.services';

//types
import { TitleBreadCrumbSettings } from 'components/DashboardTitleSection/DashboardTitleSection.component.d';
import { TabSelectorSettings, TabSelectorItem } from 'components/TabSelector/TabSelector.component.d';
import { APLessonsNewTableItem } from './APLessonsNewTable/APLessonsNewTable.component.d';
import { APLessonsOpenTableItem } from './APLessonsOpenTable/APLessonsOpenTable.component.d';
import { APLessonsClosedTableItem } from './APLessonsClosedTable/APLessonsClosedTable.component.d';
import { APLessonsOpenDetailsTableItem } from './APLessonsOpenDetailsTable/APLessonsOpenDetailsTable.component.d';
import { APLessonsClosedDetailsTableItem } from './APLessonsClosedDetailsTable/APLessonsClosedDetailsTable.component.d';
import { PersonalAssistant } from './APLessonsPage';
import { User } from 'types/User.d';

//components
import DashboardTitleSection from 'components/DashboardTitleSection';
import TabSelector from 'components/TabSelector/TabSelector.component';
import APLessonsNewTable from './APLessonsNewTable/APLessonsNewTable.component';
import APLessonsOpenTable from './APLessonsOpenTable/APLessonsOpenTable.component';
import APLessonsOpenDetailsTable from './APLessonsOpenDetailsTable/APLessonsOpenDetailsTable.component';
import APLessonsClosedTable from './APLessonsClosedTable/APLessonsClosedTable.component';
import APLessonsClosedDetailsTable from './APLessonsClosedDetailsTable/APLessonsClosedDetailsTable.component';

//assets
import { ReactComponent as SearchIconSVG } from './assets/searchIcon.svg';
import { ReactComponent as ClearButtonIconSVG } from './assets/clearButtonIcon.svg';

//style
import { BaseContentCard } from 'style/wrappers/wrappers';
import { 
	Page, 
	SearchChannelBox, 
	SearchInput, 
	SearchClearButton, 
	SearchButton, 
	AssignBox, 
	AssignSelect, 
	AssignButton,
    TableDetailsHeader,
    TableDetailsName,
    TableDetailsBackButton,
 } from './style/APLessonsPage.style';

const APLessonsPage: FC<{}> = () => {
	const [searchInput, setSearchInput] = useState<any>('');
	const [searchNewResults, setSearchNewResults] = useState<APLessonsNewTableItem[]>([]);
	const [searchOpenResults, setSearchOpenResults] = useState<APLessonsOpenTableItem[]>([]);
	const [originalOpenResults, setOriginalOpenResults] = useState<any>([]);
	const [searchClosedResults, setSearchClosedResults] = useState<APLessonsClosedTableItem[]>([]);
	const [openDetailsResults, setOpenDetailsResults] = useState<APLessonsOpenDetailsTableItem[]>([]);
	const [originalClosedResults, setOriginalClosedResults] = useState<any>([]);
    const [closedDetailsResults, setClosedDetailsResults] = useState<APLessonsClosedDetailsTableItem[]>([]); 
	const [totalPages, setTotalPages] = useState<number>(0);
	const [totalElements, setTotalElements] = useState<number>(0);
	const [pageSize, setPageSize] = useState<number>(0);
	const [coaches, setCoaches] = useState<any[]>([]);
	const [personalAssistants, setPersonalAssistants] = useState<any[]>([]);
	const [tabSelectorValue, setTabSelectorValue] = useState<string>('');
	const [assignAssistantSelect, setAssignAssistantSelect] = useState<number>(0);
	const [selectedIds, setSelectedIds] = useState<number[]>([]);

	const { brand } = useAppSelector((state) => state.ui);


    const isSuperAdmin = useHasRole('SUPERADMIN');
	const isAssistentePersonale = useHasRole('ASSISTENTE-PERSONALE');
	const isSupervisoreAutostrada = useHasRole('SUPERVISORE_AUTOSTRADA');
	const isAdminAutostrada = useHasRole('ADMIN_AUTOSTRADA');

    // const personalAssistantsState = useAppSelector(state => state.global.staffUsers);

	interface CoachData {
		id: string;
		name: string;
		totalLessons: number;
		totalRatedLessons: number;
		averageRating: number;
	}

	const dispatch = useAppDispatch();

	const clearSearchHandler = () => {
		setSearchInput('');
	};

	const searchChangeHandler = (searchValue: any) => {
		setSearchInput(searchValue.target.value);
	};

	const searchKeyHandler = (event: any) => {
		if (event.keyCode === 27) {
			clearSearchHandler();
		}
	};

	const getAllCoaches = async () => {
		var coaches = [];
		const response = await getCoaches();
		if (response) {
			coaches = response.filter((coach: any) => coach.deleted === false);
		}
		setCoaches(coaches);
	}
	
	function getCoachData(closedResults: any): CoachData[] {
		const coachMap = new Map<string, CoachData>();
		
		closedResults.forEach((result: any) => {
			const coach = result.productLessonPack.coach;
			const coachId = coach.userId;
			const lessonCount = result.productLessonPack.lessons.length;
		
			if (!coachMap.has(coachId)) {
				coachMap.set(coachId, {
					id: coachId,
					name: coach.name,
					totalLessons: 0,
					totalRatedLessons: 0,
					averageRating: 0,
				});
			}
		
			const coachData = coachMap.get(coachId);
			if (coachData) coachData.totalLessons += lessonCount;
		
			result.productLessonPack.lessons.forEach((lesson: any) => {
			if ((lesson.rating !== null)&&(coachData)&&(coachData.averageRating !== null)) {
				coachData.totalRatedLessons += 1;
				coachData.averageRating += lesson.rating;
			}
			});
		});
		
		return Array.from(coachMap.values()).map((coachData) => ({
			...coachData,
			averageRating: coachData.averageRating / coachData.totalRatedLessons,
		}));
	}

	function extractLessonsByCoachId(
		results: any[],
		coachId: number
	  ): any[] {
		const lessons: any[] = [];
	  
		results.forEach((result) => {
			if (result.productLessonPack.coach.userId === coachId) {
				result.productLessonPack.lessons.forEach((lesson: any) => {
					lessons.push({ lesson, user: result.user, creationDate: result.creationDate, assistant: result.productLessonPack.coach.userId+" | "+result.productLessonPack.coach.name });
			  });
			}
		  });
	  
		return lessons;
	  }

	const searchNewLessons = async (page: number) => {
		if (!brand) return;
		var lessons = [];
		const response = await getSearchAPLessons({ q: searchInput, assigned: false, types: [4], brandCode: brand.code });
		if (response) {
			lessons = response;
		}
		setSearchNewResults(
			lessons.map((lesson: any ) => ({ id: lesson.productLessonPack.productSubscriptionId, customer: lesson.user.id+" | "+lesson.user.name+" "+lesson.user.surname, giftdate: lesson.creationDate }))
		);

		getAllCoaches();

		setTotalElements(response.totalElements);
		setTotalPages(response.totalPages);
		setPageSize(response.size);
	};

	const searchOpenLessons = async (page: number) => {
		if (!brand) return;
		var lessons = [];
		const response = await getSearchAPLessons({ q: searchInput, assigned: true, statuses: ["OPENED"], types: [4], brandCode: brand.code });
		if (response) {
			lessons = response; 
		}

		const openTable = getCoachData(lessons);

		setOriginalOpenResults(lessons);
		setSearchOpenResults(
			openTable.map((assistant: any ) => ({ id: assistant.id, assistant: assistant.name, openlessons: assistant.totalLessons }))
		);

		setTotalElements(response.totalElements);
		setTotalPages(response.totalPages);
		setPageSize(response.size);
	};

	const searchOpenDetails = async (assistantId: number) => {
		const detailsTable = extractLessonsByCoachId(originalOpenResults, assistantId);
        setOpenDetailsResults(
			detailsTable.map((lesson: any ) => ({ id: lesson.lesson.id, customer: lesson.user.id+" | "+lesson.user.name+" "+lesson.user.surname, giftdate: lesson.creationDate, assistant: lesson.assistant }))
        );
        // setTotalElements(response.totalElements);
        // setTotalPages(response.totalPages);
        // setPageSize(response.size);
    }

    const handleCloseDetails = () => {
		setOpenDetailsResults([]);
        setClosedDetailsResults([]);
    }

	const searchClosedLessons = async (page: number) => {
		if (!brand) return;
		var lessons = [];
		const response = await getSearchAPLessons({ q: searchInput, assigned: true, statuses: ['CLOSED_SUCCESS', 'CLOSED_FAILURE', 'CLOSED_EXPIRED'], types: [4], brandCode: brand.code });
		if (response) {
			lessons = response;
		}

		setOriginalClosedResults(lessons);

		const closedTable = getCoachData(lessons);
		setSearchClosedResults(
			closedTable.map((assistant: any ) => ({ id: assistant.id, assistant: assistant.name, lessons: assistant.totalLessons, rating: assistant.averageRating }))
		);

		setTotalElements(response.totalElements);
		setTotalPages(response.totalPages);
		setPageSize(response.size);
	};

	const searchClosedDetails = async (assistantId: number) => {
		const detailsTable = extractLessonsByCoachId(originalClosedResults, assistantId);
        setClosedDetailsResults(
			detailsTable.map((lesson: any ) => ({ id: lesson.lesson.id, customer: lesson.user.id+" | "+lesson.user.name+" "+lesson.user.surname, giftdate: lesson.creationDate, rating: lesson.lesson.rating, assistant: lesson.assistant }))
        );
        // setTotalElements(response.totalElements);
        // setTotalPages(response.totalPages);
        // setPageSize(response.size);
    }

	const searchAssistantOpenDetails = async() => {

	}

	const searchAssistantClosedDetails = async() => {

	}

	const handleAssignAssistantSelectChange = (value: string) => {
		setAssignAssistantSelect(Number(value));
	};

	const getCheckedIds = (checkboxes: number[]) => {
		setSelectedIds(checkboxes);
	};

	const assignLessonTo = async () => {
		try {
			selectedIds.forEach(async (id) => {
				await assignLessonToAP(id, assignAssistantSelect);
			});
			const snackbarData: SnackbarOptions = {};
			snackbarData.type = 'success';
			snackbarData.message = `Lezioni assegnate con successo!`;
			dispatch(addSnackbar(snackbarData));
			setTimeout(() => {
				getAllCoaches();
				searchNewLessons(0);
			}, 2000);
		} catch (error: any) {
			const snackbarData: SnackbarOptions = {};
			snackbarData.type = 'error';
			snackbarData.message = `C'è stato qualche problema nell'assegnazione delle lezioni!`;
			dispatch(addSnackbar(snackbarData));
			throw error;
		}
	};

	useEffect(() => {
		const prepareAssistantsList = async () => {
			if (originalOpenResults.length === 0) 
				{
					await searchOpenLessons(0);
					return;
				}
			if (coaches.length === 0) return;
			if (searchOpenResults.length === 0) return;
			var assistants: any[] = [];
			if (coaches) {
				assistants = coaches.filter((item: any) => {
					if (item.user.roles) {
						return item.user.roles.some((role: any) => role.role === 'ASSISTENTE-PERSONALE');
					}
					return false;
				});
			} 
			const assistantsWithLessonCount = assistants.map((assistant) => {
				var assistantCopy = {...assistant}; 
				if (searchOpenResults.length>0) {
					assistantCopy.lessons = originalOpenResults.reduce((count: number, lesson: any) => {
					if (lesson.productLessonPack && lesson.productLessonPack?.coach?.userId === assistant.userId) {
						return count + 1;
					}
					return count;
					}, 0);
				} else { 
					assistantCopy.lessons = 0;
				}
				return assistantCopy;
			  });
			setPersonalAssistants(assistantsWithLessonCount.sort((a: any, b: any) => {
				return a.user.surname.localeCompare(b.user.surname);
			}));
		}
		prepareAssistantsList();
	}, [coaches, originalOpenResults]);

	useEffect(() => {
		console.log("Coaches updated!");
	}, [coaches]);

	useEffect(() => {
		console.log("originalOpenResults updated!");
	}, [originalOpenResults]);

	const breadCrumbSettings: TitleBreadCrumbSettings = { subSectionText: 'Gestione Lezioni AP' };
	const tabItems: TabSelectorItem[] = [
		{
			title: 'Da assegnare',
			value: 'tobeassigned',
		},
		{
			title: 'Aperte',
			value: 'open',
		},
		{
			title: 'Concluse',
			value: 'closed',
		},
	];
	const tabSettings: TabSelectorSettings = { tabItems: tabItems, handleSelection: setTabSelectorValue, display: (openDetailsResults.length === 0 && closedDetailsResults.length === 0) ? true : false };

	const assignBox = (
		<AssignBox>
			<AssignSelect
				disabled={(personalAssistants.length === 0)}
				value={assignAssistantSelect}
				onChange={(event) => handleAssignAssistantSelectChange(event.target.value)}
			>
				<option value={0}>Scegli un assistente</option>
				{personalAssistants.map((a) => {
					return (
						<option value={a.userId}>
							{a.userId} | {a.name} {a.surname} ({a.lessons ? a.lessons : 0})
						</option>
					);
				})}
			</AssignSelect>

			<AssignButton
				onClick={() => assignLessonTo()}
				disabled={assignAssistantSelect === 0 || selectedIds.length === 0}
			>
				Assegna
			</AssignButton>
		</AssignBox>
	);

    if (!isSuperAdmin && !isSupervisoreAutostrada && !isAdminAutostrada) return (<></>);

	return (
		<Page>
			<DashboardTitleSection breadCrumbSettings={breadCrumbSettings}>
				{ false && <SearchChannelBox>
					<SearchInput
						onKeyDown={searchKeyHandler}
						onChange={searchChangeHandler}
						value={searchInput}
					/>

					{searchInput !== '' && (
						<SearchClearButton onClick={clearSearchHandler}>
							<ClearButtonIconSVG />
						</SearchClearButton>
					)}

					<SearchButton>
						<SearchIconSVG />
					</SearchButton>
				</SearchChannelBox>
				}
			</DashboardTitleSection>

			<TabSelector tabSelectorSettings={tabSettings}>
            </TabSelector>
			
			{ tabSelectorValue === "open" && openDetailsResults.length > 0 &&
                <TableDetailsHeader>
                    <TableDetailsName>{openDetailsResults[0].assistant}: aperte</TableDetailsName>

                    <TableDetailsBackButton onClick={() => handleCloseDetails()}>Chiudi dettagli</TableDetailsBackButton>
                </TableDetailsHeader>
            }

			{ tabSelectorValue === "closed" && closedDetailsResults.length > 0 &&
                <TableDetailsHeader>
                    <TableDetailsName>{closedDetailsResults[0].assistant}: concluse</TableDetailsName>

                    <TableDetailsBackButton onClick={() => handleCloseDetails()}>Chiudi dettagli</TableDetailsBackButton>
                </TableDetailsHeader>
            }

			<BaseContentCard>
				{tabSelectorValue === 'tobeassigned' && (
					<>
						{assignBox}

						<APLessonsNewTable
							data={searchNewResults}
							totalElements={totalElements}
							totalPages={totalPages}
							pageSize={pageSize}
							searchLessons={searchNewLessons}
							getCheckedIds={getCheckedIds}
						/>

						{assignBox}
					</>
				)}
				{tabSelectorValue === 'open' && openDetailsResults.length === 0 && (
					<>
						<APLessonsOpenTable
							data={searchOpenResults}
							totalElements={totalElements}
							totalPages={totalPages}
							pageSize={pageSize}
							searchAssistants={searchOpenLessons}
							searchAssistantDetails={searchOpenDetails}
						/>
					</>
				)}
				{ tabSelectorValue === "open" && openDetailsResults.length > 0 &&
                    <>
						<APLessonsOpenDetailsTable
							data={openDetailsResults}
							totalElements={totalElements}
							totalPages={totalPages}
							pageSize={pageSize}
							searchAssistants={searchAssistantOpenDetails}
						/>
                    </>
                }
				{tabSelectorValue === 'closed' && closedDetailsResults.length === 0 && (
					<>
						<APLessonsClosedTable
							data={searchClosedResults}
							totalElements={totalElements}
							totalPages={totalPages}
							pageSize={pageSize}
							searchAssistants={searchClosedLessons}
							searchAssistantDetails={searchClosedDetails}
						/>
					</>
				)}
				{ tabSelectorValue === "closed" && closedDetailsResults.length > 0 &&
                    <>
						<APLessonsClosedDetailsTable
							data={closedDetailsResults}
							totalElements={totalElements}
							totalPages={totalPages}
							pageSize={pageSize}
							searchAssistants={searchAssistantClosedDetails}
						/>
                    </>
                }
			</BaseContentCard>
		</Page>
	);
};

export default APLessonsPage;
