import colors from 'style/palette/palette';
import styled from 'styled-components';


export const CardContent = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 30px;

    width: 1000px;

    margin: 0;
    padding: 30px;

    border: 1px solid ${colors.primaryColor};
    border-radius: 15px;

    background: ${colors.grey};
    color: ${colors.white};

    div {
        /* width: 100%; */
    }
`

export const RowContent = styled.div`

    display: flex;
    align-items: flex-end;
    justify-content: center;

    flex-direction: column;
    gap: 30px;
`;

export const ButtonRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-end;

    gap: 30px;

    width: 100%;
`;

export const SaveAndCloseButton = styled.button`

    margin: 0;
    padding: 20px;

    border: 1px solid ${colors.primaryColor};
    border-radius: 5px;

    background: ${colors.blueGrey};
    color: ${colors.black087};
    font-size: 15px;
    font-weight: 500;

    cursor: pointer;
`;

export const CloseAndCancel = styled(SaveAndCloseButton)``;

export const DeleteElementButton = styled(SaveAndCloseButton)``;

export const UrlInput = styled.input`

    margin: 0 0 0 10px;
    
    border-radius: 5px;
    border: 1px solid ${colors.white070};
    background: ${colors.lightGrey};
`;

export const UrlLabel = styled.label``;
