export const formatPadNumber = (variable?: number): string => {

    if (typeof variable !== 'number' || variable === undefined) {
        return "00";
    }

    if (variable >= 0 && variable < 10) return `0${variable}`;
    if (variable < 0 && variable > -10) return `-0${variable * -1}`;

    return variable.toString();
};

export const formatDuration = (timeInSec: number): string => {

    const seconds = Math.floor(timeInSec % 60);
    const minutes = Math.floor(timeInSec / 60) % 60;
    const hours = Math.floor(timeInSec / 3600);

    if (hours === 0) {

        return `${minutes}:${formatPadNumber(seconds)}`
    }

    return `${hours}:${formatPadNumber(minutes)}:${formatPadNumber(seconds)}`;
};
