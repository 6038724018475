import { FC, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

// routing
import { Outlet, Link, useNavigate } from 'react-router-dom';

// components
import FacebookLoginButton from 'components/ui/FacebookLoginButton';

// assets
import { ReactComponent as TitleLogoSVG } from './assets/page-title-logo.svg';

// style
import { BaseCard } from 'style/wrappers';
import { Page, PageTitle, Content, SocialButtonsRow, NewUserLink } from './style/LoginPage.style';
import useAppSelector from 'hooks/useAppSelector';

/**
 * Login Page
 *
 * @author Alessio Grassi
 *
 * @returns JSX
 */

const LoginPage: FC<{}> = () => {
	const { tokenID } = useAppSelector((state) => state.user);
	const isUserLoggedIn = !!tokenID;
	const navigate = useNavigate();

	useEffect(() => {
		if (isUserLoggedIn) navigate('/reservedArea/');
	}, [isUserLoggedIn]);

	const facebookSignupHandler = () => {
		Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook });
	};

	return (
		<Page>
			<TitleLogoSVG />

			<PageTitle>Accedi al Backoffice!</PageTitle>

			<BaseCard
				flavor='simpleGrey'
				maxWidth='500px'
			>
				<Content>
					<Outlet />

					<SocialButtonsRow>
						<FacebookLoginButton onClick={facebookSignupHandler}>Accedi con Facebook</FacebookLoginButton>
					</SocialButtonsRow>

					<NewUserLink>
						<Link to='/sign-up'>Sei nuovo? Crea un account</Link>
					</NewUserLink>
				</Content>
			</BaseCard>
		</Page>
	);
};

export default LoginPage;
