import { FC, useState } from "react";

//style
import {
    Title
} from './style/TitleListItem.style';

const TitleListItem: FC<{titleElement: any, children?: any, chapterIndex: number, isOpen: boolean,collapseHandler: () => void}> = ({titleElement, children, chapterIndex, isOpen, collapseHandler}) => {

    const [isButtonTitleActive, setIsButtonTitleActive] = useState<boolean>(false);

    const { title, pos } = titleElement;

    const onClickButtonTitleHandler = () => {
        if (isOpen){
            collapseHandler()
            setIsButtonTitleActive(true)
        }
        setIsButtonTitleActive((prevState) => !prevState);       
    };

    return (
        <>
            <Title isActive={isButtonTitleActive} onClick={onClickButtonTitleHandler}>
                {chapterIndex + 1}.{pos + 1} {title ?? "Manca il titolo..."}
            </Title>

            {isButtonTitleActive && children}
        </>
    )
};

export default TitleListItem;