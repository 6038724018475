import { FC, useState } from 'react';

//types
import { ProductsModalProps } from './ProductsModal.component.d';

//components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';

//style
import { CardContent, CloseButton, IconBox, ProductName, ProductsColumn, TextContent, TextDisclaimer, Title } from './style/ProductsModal.component.style';

const ProductsModal: FC<ProductsModalProps> = ({ onCloseButtonModal, productSubscriptions }) => {
	const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);

	const closeButtonHandler = () => {
		if (onCloseButtonModal) onCloseButtonModal();
	};

	const formatDate = (when: string): string => {
		const options: Intl.DateTimeFormatOptions = {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: 'numeric',
			minute: 'numeric',
			second: 'numeric',
			hour12: false,
			timeZone: 'Europe/Rome'
		};
		if (!when) return "";
		return new Intl.DateTimeFormat('it-IT', options).format(new Date(when)).split(',')[0];
	}

const getExpirationDate = (thispackage: any): string => {
		var expirationDate = thispackage.product.productLessonPack.expirationDate;
		if (expirationDate) return expirationDate;
		if (thispackage.creationDate) {
			expirationDate = new Date(thispackage.creationDate);
		}
		const january2023 = new Date("2023-01-01");
		if (expirationDate < january2023) {
			expirationDate.setFullYear(expirationDate.getFullYear() + 1);
			return expirationDate.toISOString();
		}
		if (thispackage.productLessonPack?.price && thispackage.productLessonPack.price === 0) {
			expirationDate.setDate(expirationDate.getDate() + 15);
			return expirationDate.toISOString();
		}
		if (thispackage.product.productLessonPack.type.name === "Lezione AP") {
			expirationDate.setDate(expirationDate.getDate() + 30);
			return expirationDate.toISOString();
		}
		if (expirationDate instanceof Date && !isNaN(expirationDate.getTime())) { 
			const utcDate = expirationDate.toUTCString();
			expirationDate = new Date(utcDate);
			expirationDate.setDate(expirationDate.getDate() + 90);
			return expirationDate.toISOString();
		}
		return "";
	}

	return (
		<BaseModal
			isBackdropClickActive={true}
			onClose={closeButtonHandler}
			isCloseButtonClicked={isCloseButtonClicked}
		>
			<BaseCard
				flavor='outlined'
				maxWidth='80vw'
			>
				<CardContent>
					<ProductsColumn>
						<Title>CORSI</Title>

						{productSubscriptions
							.sort((a, b) => a.product.name.localeCompare(b.product.name))
							.filter((p) => p.product.type === 'COURSE')
							.map((productSubscription, index) => {
								return (
									<ProductName
										isService={productSubscription.productService !== undefined}
										isDeleted={productSubscription.deletionDate !== undefined}
										isExpired={new Date(productSubscription.productService?.expirationDate) < new Date()}
										isServiceAutoRenew={productSubscription.productService?.paypalSubscriptionId !== null}
										key={index}
										tooltip={"Acquistato: "+formatDate(productSubscription.creationDate)}
									>
										{productSubscription.product.name} | {productSubscription.product.id}
									</ProductName>
								);
							})}
					</ProductsColumn>
					<ProductsColumn>
						<Title>SERVIZI</Title>

						{productSubscriptions
							.filter((p) => p.product.type === 'SERVICE' && p.productService?.status !== 'CREATED')
							.sort((a, b) => {
								if (a.productService?.status === "ACTIVE" && b.productService?.status !== "ACTIVE") {
								  return -1; // a comes before b
								} else if (a.productService?.status !== "ACTIVE" && b.productService?.status === "ACTIVE") {
								  return 1; // b comes before a
								} else if (a.productService?.expirationDate && !b.productService?.expirationDate) {
								  return -1; // a comes before b
								} else if (!a.productService?.expirationDate && b.productService?.expirationDate) {
								  return 1; // b comes before a
								} else if (a.productService?.status === "ACTIVE" && b.productService?.status === "ACTIVE" &&
										   new Date(a.productService.expirationDate) < new Date() &&
										   new Date(b.productService.expirationDate) >= new Date()) {
								  return 1; // b comes before a
								} else if (a.productService?.status === "ACTIVE" && b.productService?.status === "ACTIVE" &&
										   new Date(b.productService.expirationDate) < new Date() &&
										   new Date(a.productService.expirationDate) >= new Date()) {
								  return -1; // a comes before b
								} else if (a.deletionDate && !b.deletionDate) {
								  return 1; // b comes before a
								} else if (!a.deletionDate && b.deletionDate) {
								  return -1; // a comes before b
								} else {
								  return 0; // no change in order
								}
							  })
							.map((productSubscription, index) => {
								return (
									<ProductName
										isService={productSubscription.productService !== undefined}
										isDeleted={productSubscription.deletionDate !== undefined}
										isExpired={new Date(productSubscription.productService?.expirationDate) < new Date()}
										isServiceAutoRenew={productSubscription.productService?.paypalSubscriptionId !== null}
										key={index}
										tooltip={
											"Acquistato: "+formatDate(productSubscription?.creationDate)+
											" Scadenza: "+formatDate(productSubscription.productService?.expirationDate)
										}
									>
										{productSubscription.product.name}
									</ProductName>
								);
							})}
					</ProductsColumn>
					<ProductsColumn>
						<Title>LEZIONI</Title>

						{productSubscriptions
							.filter((p) => p.product.type === 'LESSON_PACK')
							.map((productSubscription, index) => {
								return (
									<ProductName
										isService={productSubscription.productService !== undefined}
										isDeleted={productSubscription.deletionDate !== undefined}
										isExpired={new Date(productSubscription.productService?.expirationDate) < new Date()}
										isServiceAutoRenew={productSubscription.productService?.paypalSubscriptionId !== null}
										key={index}
										tooltip={
											"Acquistata: "+formatDate(productSubscription?.creationDate)+
											(productSubscription.productLessonPack?.closingDate ? 
												" Chiusura: "+formatDate(productSubscription.productLessonPack.closingDate) :
												" Scadenza: "+formatDate(getExpirationDate(productSubscription)))
											}
									>
										{productSubscription.product.name}
									</ProductName>
								);
							})}
					</ProductsColumn>
					<ProductsColumn>
						<Title>DIRETTE TEMATICHE</Title>

						{productSubscriptions
							.sort((a, b) => a.product.name.localeCompare(b.product.name))
							.filter((p) => p.product.type === 'THEMATIC_BROADCAST' && p.product.productThematicCall.type !== 'ESPERIENZA')
							.map((productSubscription, index) => {
								return (
									<ProductName
										isService={productSubscription.productService !== undefined}
										isDeleted={productSubscription.deletionDate !== undefined}
										isExpired={new Date(productSubscription.productService?.expirationDate) < new Date()}
										isServiceAutoRenew={productSubscription.productService?.paypalSubscriptionId !== null}
										key={index}
										tooltip={"Acquistato: "+formatDate(productSubscription.creationDate)}
									>
										{productSubscription.product.name} | {productSubscription.productThematicCall.type ? productSubscription.productThematicCall.type.charAt(0) : "N"} | {productSubscription.product.id}
									</ProductName>
								);
							})}
					</ProductsColumn>
					<ProductsColumn>
						<Title>ESPERIENZE</Title>

						{productSubscriptions
							.sort((a, b) => a.product.name.localeCompare(b.product.name))
							.filter((p) => p.product.type === 'THEMATIC_BROADCAST' && p.product.productThematicCall.type === 'ESPERIENZA')
							.map((productSubscription, index) => {
								return (
									<ProductName
										isService={productSubscription.productService !== undefined}
										isDeleted={productSubscription.deletionDate !== undefined}
										isExpired={new Date(productSubscription.productService?.expirationDate) < new Date()}
										isServiceAutoRenew={productSubscription.productService?.paypalSubscriptionId !== null}
										key={index}
										tooltip={"Acquistato: "+formatDate(productSubscription.creationDate)}
									>
										{productSubscription.product.name} | {productSubscription.productThematicCall.type ? productSubscription.productThematicCall.type.charAt(0) : "N"} | {productSubscription.product.id}
									</ProductName>
								);
							})}
					</ProductsColumn>
				</CardContent>
			</BaseCard>
		</BaseModal>
	);
};

export default ProductsModal;
