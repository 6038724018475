import { FC, useEffect, useMemo, useState } from 'react';

// redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import useAppSelector from 'hooks/useAppSelector';
import useHasRole from 'hooks/useHasRole';
import useHasPermission from 'hooks/useHasPermission';
import useBrand from 'hooks/useBrand';

//types
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { TitleBreadCrumbSettings } from 'components/DashboardTitleSection/DashboardTitleSection.component.d';
import { RoadmapEvent } from 'types/RoadmapEvent';
import { TabSelectorItem, TabSelectorSettings } from 'components/TabSelector/TabSelector.component.d';
import { RoadmapEventType } from 'types/RoadmapEventType';


//components
import DashboardTitleSection from 'components/DashboardTitleSection';
import RoadmapEventsTable from './RoadmapEventsTable/RoadmapEventsTable.component';
import RoadmapEventTypesTable from './RoadmapEventTypesTable/RoadmapEventTypesTable.component';
import TabSelector from 'components/TabSelector';

//utils
import { putRoadmapEvent } from 'services/roadmapevent.services';
import { getRoadmapEvents, getRoadmapEventTypes } from './services/RoadmapPage.services';

//assets
import { ReactComponent as SearchIconSVG } from './assets/searchIcon.svg';
import { ReactComponent as ClearButtonIconSVG } from './assets/clearButtonIcon.svg';
import { ReactComponent as AddBoxIcon } from './assets/addBoxIcon.svg';
import { ReactComponent as VisibleON } from './assets/visibleON.svg';
import { ReactComponent as VisibleOFF } from './assets/visibleOFF.svg';


//style
import { 
	Page, 
	SearchRoadmapBox, 
	SearchInput, 
	SearchClearButton, 
	SearchButton, 
	AddEvent,
	NotVisibleFilterButton,
	NotVisibleFilterInput,
} from './style/RoadmapPage.component.style';
import { BaseContentCard } from 'style/wrappers/wrappers';
import useAppDispatch from 'hooks/useAppDispatch';
// import { updateEvent } from 'redux/actions/actions-global';
import AddRoadmapModal from './AddRoadmapModal';
import { enableAppMain } from 'redux/slices/slice-app-main';
import { constants } from 'http2';

const RoadmapPage: FC = () => {
    const brand = useBrand();

	const [searchInput, setSearchInput] = useState<string>('');
	const [searchInputValue, setSearchInputValue] = useState<string>('');
	const [originalRoadmapList, setOriginalRoadmapList] = useState<RoadmapEvent[]>([]);
	const [originalRoadmapTypesList, setOriginalRoadmapTypesList] = useState<RoadmapEventType[]>();
	const [localRoadmapList, setLocalRoadmapList] = useState<RoadmapEvent[]>([]);
	const [localRoadmapTypesList, setLocalRoadmapTypesList] = useState<RoadmapEventType[]>();
	const [tabSelectorValue, setTabSelectorValue] = useState<string>('events');
	const [roadmapEventType, setEventType] = useState<RoadmapEventType>();
	const [isAddEventModalVisible, setIsAddEventModalVisible] = useState(false);
	const [showHiddenFilter, setShowHiddenFilter] = useState<boolean>(false);

	const isAdmin = useHasRole('ADMIN');
	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');
	const isAuthor = useHasRole('AUTORE');
	const isRoadmap = useHasRole('ROADMAP-'+brand?.code);


	const canCreateEvent = useHasPermission('CREATE_ROADMAP_EVENT_'+brand?.code);
	const canCreateEventType = useHasPermission('CREATE_ROADMAP_EVENT_'+brand?.code);

	const dispatch = useAppDispatch();


	const addEventClickHandler = () => {
		setIsAddEventModalVisible(true);
	};

	const closeAddEventModal = () => {
		setIsAddEventModalVisible(false);
		dispatch(enableAppMain());
	};

	const clearSearchHandler = () => {
		setSearchInput('');
		setSearchInputValue('');
	};

	const searchChangeHandler = (searchValue: any) => {
		setSearchInputValue(searchValue.target.value);
	};

	const searchKeyHandler = (event: any) => {
		if (event.keyCode === 27) {
			clearSearchHandler();
		}
		if (event.keyCode === 13) {
			setSearchInput(event.target.value);
		}
	};

	const searchButtonHandler = () => {
		setSearchInput(searchInputValue);
	};

	const handleShowHiddenFilterClick = () => {
		setShowHiddenFilter((prevState) => !prevState);
	};

	const putUpdateEvent = async (updatedEvent: Partial<RoadmapEvent>) => {
		if (!brand) return;
		try {
			await putRoadmapEvent(updatedEvent, brand.code);
			const oldList: RoadmapEvent[] | undefined = [ ...originalRoadmapList];
			if (!oldList) return;
			const newList: RoadmapEvent[] = oldList.map((item: RoadmapEvent) => {
				if (!updatedEvent.id) return item;
				if (updatedEvent.visible === undefined) return item;
				if (item.id === updatedEvent.id) {
				  return { ...item, visible: updatedEvent.visible };
				}
				return item;
			  });
			if (!newList) return;
			setOriginalRoadmapList(newList);
		} catch (error) {
			const snackbarData: SnackbarOptions = {};
			snackbarData.type = 'error';
			snackbarData.message = "Errore nell'aggiornamento visibilità evento";
			dispatch(addSnackbar(snackbarData));
		}
	};

	const putUpdateEventType = async (updatedEvent: Partial<RoadmapEventType>) => {
		try {
			
		} catch (error) {}
	};

	const filterRoadmapList = (unsorted: RoadmapEvent[]): RoadmapEvent[] => {
		if (!unsorted) return [];
		let filteredRoadmap = originalRoadmapList?.filter((p) => 
				p.name.toLowerCase().includes(searchInput.toLowerCase()) &&
				((showHiddenFilter && (p.visible === false)) || (p.visible === true))
			);
			if (filteredRoadmap) {
				filteredRoadmap.sort((a, b) => {
					if (a.tbd === true && b.tbd !== true) return -1;
					if (a.tbd !== true && b.tbd === true) return 1;
					return new Date(b.beginDate).getTime() - new Date(a.beginDate).getTime();
				});
				return filteredRoadmap;
			}
		return [];
	}

	//setEventType on tabSelector Change
	useEffect(() => {
		const eventTypeSelected: string =
			tabSelectorValue === 'events'
				? 'events'
				: tabSelectorValue === 'types'
				? 'types'
				: '';
		// if (eventTypeSelected !== '') setEventType(eventTypeSelected as RoadmapEventType);
		setSearchInput('');
		setSearchInputValue('');
		if (tabSelectorValue !== '') {
			// localStorage.setItem('tabSelectorValue', tabSelectorValue);
		}
	}, [tabSelectorValue]);

	//filter eventList on roadmapEventType change
	useEffect(() => {
		if (tabSelectorValue==='events') {
			if (!originalRoadmapList) return;
			let filteredRoadmap = filterRoadmapList(originalRoadmapList);
			setLocalRoadmapList(filteredRoadmap);
		}
		if (tabSelectorValue==='types') {
			let filteredRoadmapTypes = originalRoadmapTypesList?.filter((p) => 
				p.name.toLowerCase().includes(searchInput.toLowerCase())
			);
			setLocalRoadmapTypesList(filteredRoadmapTypes);
		}
	}, [searchInput, showHiddenFilter, originalRoadmapList]);
	

	//leggere localStorage per ultimo roadmapEventType selezionato
	// useEffect(() => {
	// 	const tabStoredSelectorValue = localStorage.getItem('tabSelectorValue');
	// 	const tabReadSelectorValue = tabStoredSelectorValue ? tabStoredSelectorValue : '';
	// 	// setTabSelectorValue(tabReadSelectorValue);
	// }, []);

	useEffect(() => {
		if (!brand) return;
		const gre = async () => {
			const response = await getRoadmapEvents(brand);
			response.sort((a: any, b: any) => {
				if (a.tbd === true && b.tbd !== true) return -1;
				if (a.tbd !== true && b.tbd === true) return 1;
				return new Date(b.beginDate).getTime() - new Date(a.beginDate).getTime();
			});
			// setLocalRoadmapList(response);
			setOriginalRoadmapList(response);
		}
		const gret = async () => {
			const response = await getRoadmapEventTypes(brand);
			setLocalRoadmapTypesList(response);
			setOriginalRoadmapTypesList(response);
		}
		gre();
		gret();
	}, [brand]);

	const eventsTable = useMemo(() => {
		if (localRoadmapList !== undefined)
			return (
				<RoadmapEventsTable
					data={localRoadmapList}
					putEvent={putUpdateEvent}
				/>
			);
	}, [localRoadmapList, putUpdateEvent]);

	const typesTable = useMemo(() => {
		if (localRoadmapTypesList !== undefined)
			return (
				<RoadmapEventTypesTable
					data={localRoadmapTypesList}
					putEvent={putUpdateEventType}
				/>
			);
	}, [localRoadmapTypesList]);

	const loader = useMemo(() => {
		return (<div>Loading...</div>);
	}, []);

	const isSearchButtonVisible: boolean = searchInput !== '';
	const breadCrumbSettings: TitleBreadCrumbSettings = { subSectionText: 'Gestione Roadmap' };

	const tabItems: TabSelectorItem[] = [
		{
			title: 'Eventi',
			value: 'events',
			display: true,
		},
		{
			title: 'Tipo Eventi',
			value: 'types',
			display: true,
		},
	];

	const tabSettings: TabSelectorSettings = { tabItems: tabItems, handleSelection: setTabSelectorValue, display: true, defaultSelection: tabSelectorValue };

	if (!isSuperAdmin && !isRoadmap) return <></>;

	return (
		<>
			<Page>
				<DashboardTitleSection breadCrumbSettings={breadCrumbSettings}>
					{ tabSelectorValue==='events' && <NotVisibleFilterButton onClick={handleShowHiddenFilterClick}>
						<NotVisibleFilterInput
							id='showhiddenfilter'
							type={'checkbox'}
							checked={showHiddenFilter}
						/>
						<VisibleOFF /> Mostra nascosti
					</NotVisibleFilterButton>}
					{((canCreateEvent && tabSelectorValue==='events') || (tabSelectorValue==='types' && canCreateEventType)) && (
						<AddEvent onClick={addEventClickHandler}>
							<AddBoxIcon /> Nuovo {tabSelectorValue==='types' ? "Tipo" : "Evento"} 
						</AddEvent>
					)}
					<SearchRoadmapBox>
						<SearchInput
							onKeyDown={searchKeyHandler}
							onChange={searchChangeHandler}
							value={searchInputValue}
						/>

						{isSearchButtonVisible && (
							<SearchClearButton onClick={clearSearchHandler}>
								<ClearButtonIconSVG />
							</SearchClearButton>
						)}

						<SearchButton onClick={searchButtonHandler}>
							<SearchIconSVG />
						</SearchButton>
					</SearchRoadmapBox>
				</DashboardTitleSection>
				<TabSelector tabSelectorSettings={tabSettings}></TabSelector>
				<BaseContentCard>
					{tabSelectorValue==='types' ? localRoadmapTypesList === undefined ? loader : typesTable : localRoadmapList === undefined ? loader : eventsTable}
				</BaseContentCard>
			</Page>
			{isAddEventModalVisible && <AddRoadmapModal elementType = {tabSelectorValue} onCloseButtonModal={closeAddEventModal} />}
		</>
	);
};

export default RoadmapPage;
