import styled, { css } from 'styled-components';
import devices from 'style/breakpoints';
import { Button } from 'style/buttons';
import colors from 'style/palette/palette';

interface ProductNameProps { 
	isService: boolean; 
	isDeleted: boolean; 
	isExpired: boolean; 
	isServiceAutoRenew: boolean, 
	tooltip?: string 
};

export const CardContent = styled.div`
	display: flex;
	width: 80vw;

	padding: 30px;

	overflow: auto;

	background-color: ${colors.veryDarkGrey};
	border-radius: 15px;

	text-align: center;

	@media ${devices.tablet} {
		padding: 35px 40px 50px;
	}
`;

export const IconBox = styled.div`
	margin: 30px 0 0;
	text-align: center;

	@media ${devices.tablet} {
		margin: 0;
	}
`;

export const CloseButton = styled.button`
	margin: 0;
	padding: 0;

	background-color: ${colors.transparent};
	border: none;

	cursor: pointer;
`;

export const Title = styled.h2`
	width: 100%;
	margin: 30px 0 15px;
	padding: 0;
	border-bottom: 2px solid ${colors.primaryColor};

	font-size: 16px;
	font-weight: 400;
	line-height: 28px;

	color: ${colors.primaryColor};

	text-align: center;
	text-transform: uppercase;
`;

export const TextContent = styled.p`
	margin: 30px 0 0;
	padding: 0;

	font-size: 15px;
	font-weight: 400;
	line-height: 28px;

	color: ${colors.white070};

	text-align: center;
`;

export const TextDisclaimer = styled.span`
	color: ${colors.white};
`;

export const CommentBox = styled.textarea`
	width: 100%;
	min-height: 200px;

	margin: 30px 0 0;
	padding: 25px;

	border: 1px solid ${colors.white023};
	border-radius: 10px;

	font-size: 15px;
	font-weight: 400;
	line-height: 28px;

	background-color: ${colors.transparent};
	color: ${colors.white070};
`;

export const SendRatingButton = styled(Button)`
	margin: 30px 0 0;
	padding: 15px 30px;
`;

export const ProductsColumn = styled.div`
	display: flex;
	width: 33.33%;
	max-height: 70vh;
	flex-direction: column;
	align-items: flex-start;
`;

export const ProductName = styled.div<ProductNameProps>`
	font-size: 0.75rem;
	margin: 0 0 2px;
	position: relative;

	text-align: left;
	
	color: ${colors.white070};

	&:hover {
		${(props) =>
			props.tooltip &&
			`
        &:after {
            content: "${props.tooltip}";
            display: block;
            position: absolute;
            background-color: ${colors.black};
            color: #ffffff;
            padding: 5px;
            border-radius: 4px;
            font-size: 14px;
            top: -50px;
            left: 50%;
            transform: translateX(-50%);
            white-space: pre-wrap;
			overflow: visible;
            z-index: 1000;
            visibility: visible;
            opacity: 1;
            transition: visibility 0s, opacity 0.5s linear;
			min-width: 230px;
        }
		`}
	}

	${({ isService, isExpired, isServiceAutoRenew }) =>
		isService &&
		!isExpired &&
		!isServiceAutoRenew &&
		css`
			color: ${colors.primaryColor};
		`}

	${({ isExpired }) =>
		isExpired &&
		css`
			color: ${colors.red};
		`}

	${({ isDeleted }) =>
		isDeleted &&
		css`
			display: none;
			//color: ${colors.white070};
			//text-decoration: line-through;
		`} /* text-align: center; */

	
`;
