import React, { FC } from 'react';

//types
import { ContentTabProps } from './ContentTab.component.d';

//components
import ContentContainer from './ContentContainer';

//style
import { MainBox } from './style/ContentTab.component.style';

const ContentTab: FC<ContentTabProps> = ({ product, putProduct }) => {
	return (
		<MainBox>
			<ContentContainer
				product={product}
				putProduct={putProduct}
			/>
		</MainBox>
	);
};

export default ContentTab;
