import React, { FC, useEffect, useState } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';
import { enableAppMain } from 'redux/slices/slice-app-main';
import { useCombobox } from 'downshift';
import { useIsMounted } from 'usehooks-ts';
import useAppSelector from 'hooks/useAppSelector';

//types
import { AssignProductModalProps } from './AssignProductModal.component.d';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { Product } from 'types/Product';

//utils
import { subscribeUserToProduct } from '../services/UserDetail.services';

//components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';

//style
import {
	AssignButton,
	AssignProductModalBox,
	ExpirationInput,
	ExpirationLabel,
	ExpirationSelection,
	OptionList,
	PaymentTypeSelection,
	Price,
	ProductExpandButton,
	ProductInput,
	ProductLabel,
	ProductSelection,
} from './style/AssignProductModal.component.style';
import { ProductSubscription } from 'types/ProductSubscription';

const AssignProductModal: FC<AssignProductModalProps> = ({ user, onCloseButtonModal, updateLocalUser }) => {
	const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);
	const [localProducts, setLocalProducts] = useState<Product[]>([]);
	const [selectedProduct, setSelectedProduct] = useState<Product>({} as Product);
	const [expirationDays, setExpirationDays] = useState(0);
	const [isSaveEnabled, setIsSaveEnabled] = useState(false);
	const [price, setPrice] = useState<number>(0);
	const [selectedPaymentTypeId, setSelectedPaymentTypeId] = useState<number>();
	const [paymentId, setPaymentId] = useState('');

	const products = useAppSelector((state) => state.global.allProducts);
	const paymentTypes = useAppSelector((state) => state.global.paymentTypes);

	const dispatch = useAppDispatch();
	const isMounted = useIsMounted();

	const onPriceChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPrice(+event.currentTarget.value);
	};

	const onPaymentTypeSelectionChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedPaymentTypeId(+event.currentTarget.value);
	};

	const onPaymentIdChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPaymentId(event.currentTarget.value);
	};

	useEffect(() => {
		if (products) 
		// setLocalProducts(products.filter((p) => !user.productSubscriptions.some((up) => checkIfDuplicate(up, p))));
		setLocalProducts(products)
	}, [products, user.productSubscriptions]);

	useEffect(() => {
		if (selectedProduct.type === 'COURSE') setIsSaveEnabled(true);
		if (selectedProduct.type === 'LESSON_PACK') setIsSaveEnabled(true);
		if (selectedProduct.type === 'SERVICE' && expirationDays > 0) setIsSaveEnabled(true);
		if (selectedProduct.type === 'SERVICE' && expirationDays <= 0) setIsSaveEnabled(false);
		if (selectedProduct.type === 'THEMATIC_BROADCAST') setIsSaveEnabled(true);

		if (price !== 0 && !selectedPaymentTypeId) setIsSaveEnabled(false);
		if (selectedPaymentTypeId && price === 0) setIsSaveEnabled(false);
		if (!selectedProduct) setIsSaveEnabled(false);
	}, [expirationDays, price, selectedPaymentTypeId, selectedProduct]);

	const checkIfDuplicate = (up: ProductSubscription, p: Product) => {
		if (up.product.id !== p.id) return false;
		if ((up.product.type === 'SERVICE') && (new Date(up.productService.expirationDate) > new Date())) return true;
		if ((up.product.type !== 'SERVICE') && (!up.deletionDate)) return true;
		return false;
	}

	const {
		inputValue,
		setInputValue,
		selectedItem,
		isOpen,
		getToggleButtonProps,
		getLabelProps,
		getMenuProps,
		highlightedIndex,
		getItemProps,
		getInputProps,
	} = useCombobox({
		items: localProducts,
		onInputValueChange({ inputValue }) {
			if (products)
				setLocalProducts(
					products.filter(
						(p) =>
							(!inputValue || p.name.toLowerCase().includes(inputValue.toLowerCase())) 
							// && !user.productSubscriptions.some((up) => checkIfDuplicate(up, p))
					)
				);
		},
		onSelectedItemChange: (changes) => {
			if (changes.selectedItem) setSelectedProduct(changes.selectedItem);
			if (changes.selectedItem?.type === 'COURSE') setIsSaveEnabled(true);
			if (!changes.selectedItem) setIsSaveEnabled(false);
		},
		itemToString(item) {
			return item ? item.name : '';
		},
	});

	const closeButtonHandler = () => {
		if (onCloseButtonModal) onCloseButtonModal();
		dispatch(enableAppMain());
	};

	const onAssignProductClickHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
		const snackbarData: SnackbarOptions = {};
		if (selectedItem)
			if (
				window.confirm(
					`Assegnare il prodotto ${selectedItem.name} all'utente ${user.name} ${user.surname} ${
						selectedPaymentTypeId
							? `al prezzo di ${price.toLocaleString()} € e metodo di pagamento ${
									paymentTypes?.find((pt) => pt.id === selectedPaymentTypeId)?.name
							  }`
							: ``
					}?`
				)
			)
				try {
					await subscribeUserToProduct(
						user.id,
						selectedItem.id,
						expirationDays,
						selectedPaymentTypeId,
						price,
						paymentId !== '' ? paymentId : undefined
					);
					// if (selectedItem.type === 'SERVICE') await subscribeUserToProduct(user.id, selectedItem.id, expirationDays);
					// if (selectedItem.type !== 'SERVICE') await subscribeUserToProduct(user.id, selectedItem.id);
					snackbarData.type = 'success';
					snackbarData.message = `${selectedItem.name} assegnato correttamente all'utente ${user.name} ${user.surname}`;
					dispatch(addSnackbar(snackbarData));
					setSelectedProduct({} as Product);
					setIsSaveEnabled(false);
					setInputValue('');
					updateLocalUser();
					closeButtonHandler();
				} catch (error) {
					snackbarData.type = 'error';
					snackbarData.message = "Errore nell'assegnazione prodotto";
					dispatch(addSnackbar(snackbarData));
					if (!isMounted()) return;
				}
	};

	const onDatePickerChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const date1 = new Date();
		const date2 = new Date(event.currentTarget.value);
		const diffTime = date2.getTime() - date1.getTime();
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		console.log('diffTime', diffTime);
		console.log('diffDays', diffDays);

		if (diffDays) setIsSaveEnabled(true);
		setExpirationDays(diffDays);
	};

	const onInputBlur = () => {
		if (!inputValue || !selectedProduct) {
			setIsSaveEnabled(false);
		}
	};

	return (
		<BaseModal
			isBackdropClickActive={true}
			onClose={closeButtonHandler}
			isCloseButtonClicked={isCloseButtonClicked}
		>
			<BaseCard
				flavor='outlined'
				maxWidth='700px'
			>
				<AssignProductModalBox>
					<ProductSelection>
						<ProductLabel {...getLabelProps()}>Scegli Prodotto:</ProductLabel>

						<ProductInput
							placeholder=''
							{...getInputProps({ onBlur: onInputBlur })}
						/>

						<ProductExpandButton
							aria-label='toggle menu'
							type='button'
							{...getToggleButtonProps()}
						>
							{isOpen ? <>&#8593;</> : <>&#8595;</>}
						</ProductExpandButton>
						{/* <span>{selectedProduct.id}</span> */}
					</ProductSelection>

					{selectedItem?.type === 'SERVICE' && (
						<ExpirationSelection>
							<ExpirationLabel>Scadenza servizio: </ExpirationLabel>
							<ExpirationInput
								type='date'
								onChange={onDatePickerChangeHandler}
							/>
						</ExpirationSelection>
					)}

					<OptionList {...getMenuProps()}>
						{isOpen &&
							localProducts.map((item, index) => (
								<li
									key={`${item.name}${index}`}
									{...getItemProps({ item, index })}
								>
									<span>{item.name}</span>
									<span>{' ' + item.type}</span>
								</li>
							))}
					</OptionList>
					{selectedProduct.id && (
						<>
							<label>Prezzo lordo pagato:</label>
							<Price
								type='number'
								placeholder='0'
								value={price}
								onChange={onPriceChangeHandler}
							/>
							<label>Metodo di pagamento:</label>
							<PaymentTypeSelection
								value={selectedPaymentTypeId}
								onChange={onPaymentTypeSelectionChangeHandler}
							>
								<option value={-1}></option>
								{paymentTypes?.map((pt) => (
									<option
										key={pt.id}
										value={pt.id}
									>
										{pt.name}
									</option>
								))}
							</PaymentTypeSelection>
							<label>Identificativo pagamento:</label>
							<input
								type='text'
								value={paymentId}
								onChange={onPaymentIdChangeHandler}
							/>
						</>
					)}
					<AssignButton
						disabled={!isSaveEnabled}
						onClick={onAssignProductClickHandler}
					>
						ASSEGNA PRODOTTO
					</AssignButton>
				</AssignProductModalBox>
			</BaseCard>
		</BaseModal>
	);
};

export default AssignProductModal;
