import { FC, useState } from 'react';

// hooks
import useHasRole from 'hooks/useHasRole';

//types
import { AutomaticIncomeDetailProps } from './AutomaticIncomeDetail.component.d';
import { TabSelectorSettings, TabSelectorItem } from 'components/TabSelector/TabSelector.component.d';

//components
import { BaseContentCard } from 'style/wrappers/wrappers';
import TabSelector from 'components/TabSelector/TabSelector.component';
import ContentTab from '../ContentTab';
import ProductTab from '../ProductTab';
import PercentagesTab from '../PercentagesTab';
import SalesPageTab from '../SalesPageTab';
import RequirementsTab from '../RequirementsTab';


const AutomaticIncomeDetail: FC<AutomaticIncomeDetailProps> = ({ product, putProduct, updateProductData }) => {
	const isAdmin = useHasRole('ADMIN');
	const isSuperAdmin = useHasRole('SUPERADMIN');
	const hasAdminRole = isAdmin || isSuperAdmin;

	const TAB_ITEMS: TabSelectorItem[] = [
		{
			title: 'Entrata MT4',
			value: 'automaticIncome',
			display: hasAdminRole,
		},
		{
			title: 'Requisiti',
			value: 'requirements',
			display: hasAdminRole,
		},
	];
	
	const [tabSelectorValue, setTabSelectorValue] = useState<string>('');

	const tabSettings: TabSelectorSettings = { tabItems: TAB_ITEMS, handleSelection: setTabSelectorValue };

	return (
		<>
			<TabSelector tabSelectorSettings={tabSettings}></TabSelector>
			<BaseContentCard>
				{tabSelectorValue === 'automaticIncome' && (
					<ProductTab
						product={product}
						putProduct={putProduct}
					></ProductTab>
				)}
				{tabSelectorValue === 'percentages' && (
					<PercentagesTab
						product={product}
						putProduct={putProduct}
					></PercentagesTab>
				)}
				{tabSelectorValue === 'content' && (
					<ContentTab
						product={product}
						putProduct={putProduct}
					></ContentTab>
				)}
				{tabSelectorValue === 'salesPage' && (
					<SalesPageTab
						product={product}
						putProduct={putProduct}
					></SalesPageTab>
				)}
				{tabSelectorValue === 'requirements' && (
					<RequirementsTab
						product={product}
						putProduct={putProduct}
						updateProductData={updateProductData}
					></RequirementsTab>
				)}
			</BaseContentCard>
		</>
	);
};

export default AutomaticIncomeDetail;
