import styled from 'styled-components';
import colors from 'style/palette';

export const Page = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: stretch;

	flex: 1;

	padding: 0 30px;

	width: 100%;
	height: 100%;

	overflow: hidden;
`;

export const SearchRoadmapBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 100%;
	max-width: 1360px;
`;

export const SearchInput = styled.input`
	display: flex;
	align-items: center;
	flex-direction: column;

	width: 100%;
	height: 29px;
	padding: 7px 9px;
	margin: 0 13px 0 0;

	background-color: ${colors.transparent};
	border: 1px solid ${colors.white};
	border-radius: 100px;

	color: ${colors.white};
	:focus {
		outline: none;
		border-color: ${colors.white};
		box-shadow: 0 0 0px ${colors.white};
		-moz-box-shadow: 0 0 0px ${colors.white};
		-webkit-box-shadow: 0 0 0px ${colors.white};
	}
`;

export const SearchClearButton = styled.div`
	position: relative;
	top: 1px;
	left: -38px;
	width: 0;

	cursor: pointer;

	> svg {
		min-width: 15px;
		min-height: 15px;
	}
`;

export const SearchButton = styled.button`
	background: transparent;
	border: none;

	cursor: pointer;
	> svg {
		min-width: 23px;
		min-height: 23px;
	}
`;

export const NotVisibleFilterButton = styled.button`
	background: transparent;
	border: none;
	width: 300px;

	color: ${colors.white};

	cursor: pointer;
	> svg {
		width: 15px;
		height: 15px;
	}
`;

export const NotVisibleFilterInput = styled.input`
	cursor: pointer;
`;

export const AddEvent = styled.button`
	/* background-color: transparent; */
	display: flex;
	align-items: center;
	> svg {
		width: 30px;
		height: 30px;
		> path {
			fill: ${colors.green};
		}
	}
`;
