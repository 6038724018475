import { FC, useEffect, useState } from 'react';

//hooks
import useAppSelector from 'hooks/useAppSelector';

//types
import { CloseWhatsappLessonModalProps } from './CloseWhatsappLessonModal.component.d';

//components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';

//assets
import { ReactComponent as CopyLinkIconSVG } from './assets/copyLinkIcon.svg';

//style
import { 
	ButtonsRow, 
	ButtonCopy,
	CancelButton, 
	SaveButton, 
	CloseLessonBox, 
	LinkTextInput,
	NotesTextArea,
	NotesRow,
	NotesColumn,
	NotesTitle,
	AssignSelect,
} from './style/CloseWhatsappLessonModal.component.style';

import { enableAppMain } from 'redux/slices/slice-app-main';
import useAppDispatch from 'hooks/useAppDispatch';

const AUTOSTRADA_REFERRAL_BASE_URL = 'https://liberidalavoro.it/app/public/product/151?ap=';

const CloseWhatsappLessonModal: FC<CloseWhatsappLessonModalProps> = ({ personalAssistants, onCloseButtonModal, onSaveButtonModal }) => {
	const { id: userId, name: userName } = useAppSelector((state) => state.user);
	
	const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);

	const [customerNotes, setCustomerNotes] = useState<string>(AUTOSTRADA_REFERRAL_BASE_URL+userId);
	const [privateNotes, setPrivateNotes] = useState<string>('');

	const [isContentModified, setIsContentModified] = useState<boolean>(false);

    const [assignAssistantSelect, setAssignAssistantSelect] = useState<number>(userId ? userId : 0);

	const dispatch = useAppDispatch();

	const clearInput = () => {
		setCustomerNotes('');
		setPrivateNotes('');
	}

	const inputCustomerChangeHandler = (inputValue: any) => {
		if (isContentModified === false) setIsContentModified(true);
		setCustomerNotes(inputValue.target.value);
	};

	const inputCustomerKeyHandler = (event: any) => {
		if (event.keyCode === 27) {
			closeButtonHandler();
		}
		// if (event.keyCode === 13 && !event.shiftKey && isContentModified) {
		// 	onSaveClickHandler();
		// }
	};

	const inputPrivateChangeHandler = (inputValue: any) => {
		if (isContentModified === false) setIsContentModified(true);
		setPrivateNotes(inputValue.target.value);
	};

	const inputPrivateKeyHandler = (event: any) => {
		if (event.keyCode === 27) {
			closeButtonHandler();
		}
		if (event.keyCode === 13 && !event.shiftKey && isContentModified) {
			onSaveClickHandler();
		}
	};

	const closeButtonHandler = () => {
		if (onCloseButtonModal) onCloseButtonModal();
		clearInput();
		dispatch(enableAppMain());
	};

	const onSaveClickHandler = () => {
		// eslint-disable-next-line no-restricted-globals
		if (confirm("Vuoi veramente procedere e salvare le Note Private?")) {
		// if (confirm("Vuoi veramente inviare il link di Autostrada all’utente e salvare le Note Private? Controlla bene il link perché non potrai modificarlo!")) {
			if (onCloseButtonModal) onCloseButtonModal();
			onSaveButtonModal(customerNotes, privateNotes);
			clearInput();
			dispatch(enableAppMain());
		}
	};

	const handleAssignAssistantSelectChange = (value: string) => {
		setAssignAssistantSelect(Number(value));
		setCustomerNotes(AUTOSTRADA_REFERRAL_BASE_URL+value);
	}

	return (
		<BaseModal
			isBackdropClickActive={true}
			onClose={closeButtonHandler}
			isCloseButtonClicked={isCloseButtonClicked}
		>
			<BaseCard
				flavor='outlined'
				maxWidth='700px'
			>
				<CloseLessonBox>
					{false && <NotesRow>
						<NotesColumn>
							<NotesRow>
								<NotesColumn>
									<NotesTitle>
										Link Autostrada:
									</NotesTitle>
								</NotesColumn>
								<NotesColumn>
									<AssignSelect disabled={(personalAssistants.length === 0)} value={assignAssistantSelect} onChange={(event) => handleAssignAssistantSelectChange(event.target.value)}>
										{personalAssistants.map((a: any) => {
											return (
												<option value={a.userId}>
													{a.name} {a.surname} | {a.userId} ({a.openroutes ? a.openroutes : 0})
												</option>
											)
										})}
									</AssignSelect>
								</NotesColumn>
							</NotesRow>
							<NotesRow>
								<NotesColumn>
									<LinkTextInput
										value={customerNotes}
										disabled
									/>
								</NotesColumn>
								<NotesColumn>
									<ButtonCopy
										onClick={() => {
											navigator.clipboard.writeText(customerNotes)
											.then(() => {
												alert("Link copiato!");
											})
											.catch(() => {
												alert("C'è stato un problema nel copiare il link. Riprova!");
											}); 
										}}
										title={"Click per copiare il link"}
									>
										<CopyLinkIconSVG />
									</ButtonCopy>
								</NotesColumn>
							</NotesRow>
						</NotesColumn>
					</NotesRow>}
					<NotesRow>
						<NotesColumn>
							<NotesTitle>
								Note Private:
							</NotesTitle>

							<NotesTextArea
								onKeyDown={inputPrivateKeyHandler}
								onChange={inputPrivateChangeHandler}
								value={privateNotes}
								autoFocus
							/>
						</NotesColumn>
					</NotesRow>
					<ButtonsRow>
						<CancelButton onClick={closeButtonHandler}>Annulla</CancelButton>

						<SaveButton
							disabled={!isContentModified}
							onClick={onSaveClickHandler}
						>
							Salva Modifiche
						</SaveButton>
					</ButtonsRow>
				</CloseLessonBox>
			</BaseCard>
		</BaseModal>
	);
};

export default CloseWhatsappLessonModal;
