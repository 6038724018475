import { useEffect, useState } from 'react';

//redux
import { enableAppMain } from 'redux/slices/slice-app-main';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';

//services
import { saveNotes } from './services/MyLessonsClosedTable.services';

//utils
import { footerElementsCount } from './MyLessonsClosedTable.helper';
import { formatNumbers } from 'utils/formatNumbers';

//types
import { tableColumnAttr, MyLessonsClosedTableItem, MyLessonsClosedTableProps } from './MyLessonsClosedTable.component.d';

//components
import NotesModal from './NotesModal';
import MultilineSelect from 'components/MultilineSelect/MultilineSelect.component';

//assets
import { ReactComponent as ChevronLeftIcon } from './assets/chevronLeft.svg';
import { ReactComponent as ChevronRightIcon } from './assets/chevronRight.svg';
import { ReactComponent as DownArrowIcon } from './assets/downArrowIcon.svg';
import { ReactComponent as NotesIcon } from './assets/notesIcon.svg';
import { ReactComponent as EmailIcon } from 'assets/imgs/backoffice-page/sendingEmailIcon.svg';
import { ReactComponent as FacebookIcon } from './assets/facebook-icon.svg';
import { ReactComponent as WhatsappIcon } from './assets/whatsapp-icon.svg';


//style
import {
    FooterNavigationButton,
	FooterElementsCount,
	Table,
	TableBody,
	TableBodyCell,
	TableBodyRow,
	TableFooter,
	TableHead,
	TableHeadCell,
	TableHeadRow,
	CustomerRow,
	CustomerName,
	UserLink,
	NotesBox,
} from './style/MyLessonsClosedTable.style';

const MyLessonsClosedTableColumns: tableColumnAttr[] = [
	{
		name: 'productsubscriptionid',
		title: 'id sub.',
	},
    {
        name: 'customer',
        title: 'Cliente',
    },
	{
		name: 'email',
		display: 'hidden',
	},
	{
		name: 'nickname',
		display: 'hidden',
	},
	{
		name: 'phoneNumber',
		display: 'hidden',
	},
    {
        name: 'type',
        title: 'Tipo Lezione',
		align: 'center',
    },
	{
		name: 'duration',
        title: 'Durata',
		align: 'center',
		display: 'hidden',
	},
	{
		name: 'description',
		display: 'hidden',
	},
	{
		name: 'creationDate',
		title: 'Data Acquisto',
		align: 'center',
	},
	{
		name: 'expirationDate',
		title: 'Data Scadenza',
		align: 'center',
	},
	{
		name: 'datelesson',
		title: 'Data Chiusura',
		align: 'center',
	},
	{
		name: 'closed',
		title: 'Chiusa Cliente',
		align: 'center',
	},
	{
		name: 'closedSuccess',
		title: 'Chiusa Successo',
		align: 'center',
	},
	{
		name: 'coachNotes',
		display: 'hidden',
	},
	{
		name: 'coachprivatenotes',
		display: 'hidden',
	},
	{
		name: 'status',
		display: 'hidden',
	},
	{
		name: 'lessonStatus',
		display: 'hidden',
	},
	{
		name: 'closingNotes',
		display: 'hidden',
	}
];

type sortParamsType = {
	column: string | null;
	direction: 'asc' | 'desc' | null;
};

const MyLessonsClosedTable: React.FC<MyLessonsClosedTableProps> = ({ data, totalPages, totalElements, pageSize, searchLesson }) => {
	const [tableData, setTableData] = useState<MyLessonsClosedTableItem[]>([]);
	const [sortParams, setSortParams] = useState<sortParamsType>({column: null, direction: null});
	const [currentPage, setCurrentPage] = useState(1);

	const [activeLessonForEditing, setActiveLessonForEditing] = useState<number | null>(null);
	const [notesForModal, setNotesForModal] = useState('');
	const [coachNotesForModal, setCoachNotesForModal] = useState('');
	const [typeForModal, setTypeForModal] = useState('');
	const [isNotesModalVisible, setIsNotesModalVisible] = useState<boolean>(false);

	const dispatch = useAppDispatch();

	function isStringDate(inputString: string): boolean {
		if (!inputString) return false;
		
		let hasTwoHyphens: boolean   = false; 
		let hasTwoColons: boolean    = false;
		let hasLetterT: boolean      = false;
	
		for(let i = 0; i < inputString.length; i++) {
			if (inputString[i] === '-') {
				hasTwoHyphens = true;
			}
			if (inputString[i] === ':') {
				hasTwoColons = true;
			}
			if (inputString[i] === 'T') {
				hasLetterT = true;
			}
		}
	
		// If all three conditions are met, return true
		if (hasTwoHyphens && hasTwoColons && hasLetterT) {
			return true;
		} 
		return false;
	}

	const isValidDate = (value: string): boolean => {
		if (!isStringDate(value)) return false;
		const dateObj = new Date(value);
		return !Number.isNaN(dateObj.getTime());
	}

	const convertDate = (value: string): string => {
		const dateObj = new Date(value);
  		return dateObj.toLocaleString('IT-it');
	}

	const formatPhoneNumber = (value: string): string => {
		if (value.length === 0) return value;
		if (value.charAt(0) !== '+') return value;
		if (value.length <= 3) return value;
		var intPrefix = value.substring(0, 3);
		var number = value.substr(3);
		return intPrefix + " " + number;
	}

	const pageChangeHandler = (page: number) => {
		setCurrentPage(page);
	};

	const openPrivateNotesModal = (idLesson: number, coachNotes: string, privateNotes: string, type: string) => {
		setActiveLessonForEditing(idLesson);
		openNotesModal(coachNotes, privateNotes, type);
	}

	const sortTable = (items: MyLessonsClosedTableItem[], sortBy: string | null, direction: 'asc' | 'desc' | null) => {
		if (sortBy === null) return items;
		const sortedItems = [...items].sort((a, b) => {
			if (a[sortBy] < b[sortBy]) {
			  return -1;
			}
			if (a[sortBy] > b[sortBy]) {
			  return 1;
			}
			return 0;
		});
		
		if (direction === 'desc') {
			return sortedItems.reverse();
		}
		
		return sortedItems;
	};

	const handleSort = (key: string) => {
		if (key === sortParams.column) {
			setSortParams({column: key, direction: sortParams.direction === 'asc' ? 'desc' : 'asc'});
		} else {
			setSortParams({column: key, direction: 'desc'});
		}
	};

	const savePrivateNotes = async (privateNotes: string) => {
		if (!activeLessonForEditing) return;
		if (!privateNotes) return;
		await saveNotes(activeLessonForEditing, undefined, privateNotes);
		setTimeout(() => {
			searchLesson(currentPage);
		}, 1000);
	}

	const openNotesModal = (coachNotes: string, notes: string, type: string) => {
		setCoachNotesForModal(coachNotes);
		setNotesForModal(notes);
		setIsNotesModalVisible(true);
		setTypeForModal(type);
	}

	const closeNotesModal = () => {
		setIsNotesModalVisible(false);
		dispatch(enableAppMain());
	};

	useEffect(() => {
		console.log('tableData', tableData);
	}, [tableData]);

	useEffect(() => {
		searchLesson(currentPage);
	}, [currentPage]);

	useEffect(() => {
		const sortedTable = sortTable(tableData, sortParams.column, sortParams.direction);
		setTableData(sortedTable);
	}, [sortParams]);

	useEffect(() => {
		const sortedTable = sortTable(data, sortParams.column, sortParams.direction);
		setTableData(sortedTable);
	}, [data]);

    let columns: string[] = [];
	if (tableData.length > 0) columns = Object.keys(data[0]);

	const closeWithoutSuccessOptions = [
		{ value: "0. Ha saltato la coda", label: "0. Ha saltato la coda" },
		{ value: "1. Non ha risposto", label: "1. Non ha risposto" },
		{ value: "2. Non si è presentato", label: "2. Non si è presentato" },
		{ value: "3. Non è interessato", label: "3. Non è interessato" },
		{ value: "4. Aveva detto che l'avrebbe comprata ma non l'ha fatto", label: "4. Aveva detto che l'avrebbe comprata ma non l'ha fatto" },
		{ value: "5. È interessato ma non ora (p.e. non ha soldi)", label: "5. È interessato ma non ora (p.e. non ha soldi)" }
	  ];
	  
	return (
		<>
			<Table>
				<TableHead>
					<TableHeadRow>
						{columns.map((c) => (
							<>
							{!(MyLessonsClosedTableColumns.find(x => x?.name === c)?.display && MyLessonsClosedTableColumns.find(x => x?.name === c)?.display === 'hidden') &&
							<TableHeadCell
								key={c}
								onClick={() => handleSort(c)}
							>
								{MyLessonsClosedTableColumns.find(x => x?.name === c)?.title ? MyLessonsClosedTableColumns.find(x => x?.name === c)?.title?.toUpperCase() : c.toUpperCase()}
								{(sortParams.column === c && (sortParams.direction === 'desc' ? <DownArrowIcon transform="rotate(180 0 0)"/> : <DownArrowIcon />))}
							</TableHeadCell>
							} </>
						)
						)}
						<TableHeadCell
                            key={"note"}>Stato chiusura e Note
                        </TableHeadCell>
					</TableHeadRow>
				</TableHead>
				<TableBody>
					{tableData.map((row, index) => (
						<TableBodyRow key={index}>
							{columns.map((c) => {
								const value = row[c as keyof MyLessonsClosedTableItem];
								return (
									<>
									{!(MyLessonsClosedTableColumns.find(x => x?.name === c)?.display && MyLessonsClosedTableColumns.find(x => x?.name === c)?.display === 'hidden') &&
									<TableBodyCell
									align={MyLessonsClosedTableColumns.find(x => x?.name === c)?.align}
									key={c}
									id={c}
									tooltip={c === 'id' ? row['description'] : undefined}
								>
									{ c === 'customer' ?
									 <CustomerRow>
										<CustomerName><UserLink href={`/reservedArea/users/${value.split("|")[0]}`} target='_blank'>{value}</UserLink></CustomerName>

										{ row['email'] && <EmailIcon title={row['email']+" (click per copiare)"} onClick={() => {navigator.clipboard.writeText(row['email'])}}/>}

										{ row['nickname'] && <FacebookIcon title={(row['nickname'].toLowerCase().startsWith("fb ") ? row['nickname'].slice(3) : row['nickname'])+" (click per copiare)"} onClick={() => {navigator.clipboard.writeText(row['nickname'].toLowerCase().startsWith("fb ") ? row['nickname'].slice(3) : row['nickname'])}}/>}

										{ row['phoneNumber'] && <WhatsappIcon title={formatPhoneNumber(row['phoneNumber'])+" (click per copiare)"} onClick={() => {navigator.clipboard.writeText(row['phoneNumber'])}}/>}

									</CustomerRow>	 :
									  ((c === 'closed') || (c === 'closedSuccess')) ?
										 <>{(value || ((c === 'closedSuccess') && row['type']==='Aiuto Attivo' && row['closingNotes']==='1'))? "Sì" : "NO"}</> 
									  :
										isValidDate(value) ? convertDate(value).split(',')[0] : value
									}

								</TableBodyCell>
									} </>
								);
							})}
							<TableBodyCell 
							align={"center"}
							key={"note"+row['id']}
							id={"note"+row['id']}
							>
								<NotesBox 									
									filledOut={row['coachprivatenotes'] || row['coachprivatenotes']===''}
									onClick={() => openPrivateNotesModal(row['id'], row['type'] === 'Aiuto Attivo' ? row['coachNotes'] : null, row['coachprivatenotes'], row['type'])}
								>
                                	<NotesIcon />
								</NotesBox>
								{row['type'] === 'Aiuto Attivo' && row['status'] === 'CLOSED_FAILURE' && row['closingNotes']!=='1' && <MultilineSelect title="MOTIVO" options={closeWithoutSuccessOptions} maxWidth="180px" minWidth="180px" dropdownMinWidth="200px" isActive={false} defaultValue={row['closingNotes']}></MultilineSelect>}
							</TableBodyCell>
						</TableBodyRow>
					))}
				</TableBody>
			</Table>
			{totalElements > 0 && (
				<TableFooter>
					<FooterElementsCount>{footerElementsCount({ currentPage, totalPages, totalElements, pageSize })}</FooterElementsCount>
					<FooterNavigationButton
						onClick={() => pageChangeHandler(currentPage - 1)}
						disabled={currentPage === 1}
					>
						<ChevronLeftIcon />
					</FooterNavigationButton>
					<FooterNavigationButton
						onClick={() => pageChangeHandler(currentPage + 1)}
						disabled={currentPage === totalPages}
					>
						<ChevronRightIcon />
					</FooterNavigationButton>
				</TableFooter>
			)}
			{isNotesModalVisible && (
			<NotesModal
				onCloseButtonModal={closeNotesModal}
				coachNotes={coachNotesForModal}
				notes={notesForModal}
				type={typeForModal}
				savePrivateNotes={savePrivateNotes}
			/>
		)}
		</>
	);
};

export default MyLessonsClosedTable;