import { FC, useEffect, useState } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsMounted } from 'usehooks-ts';

//types
import { RequisitesAndOrProps } from './RequisitesAndOr.component.d';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

//utils

//style
import { RequisitesAndOrBox, LogicOperator } from './style/RequisitesAndOr.component.style';
import useHasRole from 'hooks/useHasRole';
import { Product } from 'types/Product';

const RequisitesAndOr: FC<RequisitesAndOrProps> = ({ product, putProduct, disabled }) => {
	
	const [localProduct, setLocalProduct] = useState<Product | null>(null);
	const [requisitesLogicalOperator, setRequisiteLogicalOperator] = useState<String>('');
	
	const dispatch = useAppDispatch();
	const isMounted = useIsMounted();

	const isAdmin = useHasRole('ADMIN');
	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');

	const changeHandler = async (event: any) => {
		const andOr = event.target.value;

		const snackbarData: SnackbarOptions = {};

		try {
			await putProduct({ id: product.id, requisitesLogicalOperator: andOr});
		} catch (error) {
			snackbarData.type = 'error';
			snackbarData.message = "Errore nell'aggiornamento requisiti prodotto";
			dispatch(addSnackbar(snackbarData));
			if (!isMounted()) return;
		}
	}

	useEffect(() => {
		if (!product) return;
		setLocalProduct(product);
		setRequisiteLogicalOperator(product.requisitesLogicalOperator);
	}, [product]);

	return (
		<RequisitesAndOrBox>
			<LogicOperator>Richiesti:&nbsp;</LogicOperator>
				<select name='liveType' onChange={changeHandler} disabled={disabled}>
					<option value='AND' selected={product.requisitesLogicalOperator === "AND"}>TUTTI</option>
					<option value='OR' selected={product.requisitesLogicalOperator === "OR"}>ALMENO UNO</option>
				</select>
		</RequisitesAndOrBox>
	);
};

export default RequisitesAndOr;
