import { FC, useEffect, useState } from 'react';

//redux
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { enableAppMain } from 'redux/actions/actions-app-main';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsMounted } from 'usehooks-ts';
import useHasRole from 'hooks/useHasRole';
import useHasPermission from 'hooks/useHasPermission';
import useAppSelector from 'hooks/useAppSelector';


//utils
import { getSearchActiveInactive, getProducts } from './services/ActiveInactivePage.services';
import { months } from 'configs/months';

//types
import { User } from 'types/User';
import { TitleBreadCrumbSettings } from 'components/DashboardTitleSection/DashboardTitleSection.component.d';
import { ProductSubscription } from 'types/ProductSubscription';
import { ActiveInactiveTableColumn } from './ActiveInactiveTable/ActiveInactiveTable.component.d';
import { productsSearchParams } from 'pages/ProductsPage/services/ProductsPage.services.d';

//components
import DashboardTitleSection from 'components/DashboardTitleSection';
import ActiveInactiveTable from './ActiveInactiveTable';
import ProductsModal from './ActiveInactiveTable/Products/ProductsModal';
import NotesModal from './ActiveInactiveTable/Notes/NotesModal';

//assets
import { ReactComponent as SearchIconSVG } from './assets/searchIcon.svg';
import { ReactComponent as ClearButtonIconSVG } from './assets/clearButtonIcon.svg';

//style
import { Page, SearchButton, SearchActiveInactiveBox, SearchClearButton, SearchInput, ServiceSelect, Filters } from './style/ActiveInactivePage.style';
import { BrandBox } from 'App/layout/Header/style/Header.style';

const ActiveInactivePage: FC<{}> = () => {
	const [searchResults, setSearchResults] = useState<User[]>();
	const [searchType, setSearchType] = useState<string>('a');
	const [totalPages, setTotalPages] = useState<number>(0);
	const [totalElements, setTotalElements] = useState<number>(0);
	const [pageSize, setPageSize] = useState<number>(0);
	const [pageNumber, setPageNumber] = useState<number>(1);
	const [productsForSelect, setProductsForSelect] = useState<ProductSubscription[]>([]);
	const [productSelect, setProductSelect] = useState<number>(0);

	const [isNotesModalVisible, setIsNotesModalVisible] = useState<boolean>(false);
	const [notesForModal, setNotesForModal] = useState('');
	const [userIdForNotesModal, setUserIdForNotesModal] = useState(-1);

	const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
	const [selectedMonth, setSelectedMonth] = useState<number>(new Date().getMonth());

	const { brand } = useAppSelector((state) => state.ui);

	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isAdmin = useHasRole('ADMIN');
	const isAuthor = useHasRole('AUTORE');

	const canReadSubcriptionsLdL = useHasPermission('READ_PRODUCT_SUBSCRIPTION_LDL');
	const canReadSubcriptions = useHasPermission('READ_PRODUCT_SUBSCRIPTION') || canReadSubcriptionsLdL;
	const carReadHisProducts = useHasPermission('READ_MY_PRODUCT');
	const canUpdateUserRole = useHasPermission('UPDATE_USER_ROLE');

	const dispatch = useAppDispatch();
	const isMounted = useIsMounted();

	const nextPageHandler = () => setPageNumber((prevState) => prevState + 1);

	const prevPageHandler = () => setPageNumber((prevState) => prevState - 1);

	const notesClickHandler = (event: React.MouseEvent, notes: string, userId: number) => {
		setIsNotesModalVisible(true);
		setNotesForModal(notes);
		setUserIdForNotesModal(userId);
	};

	const closeNotesModal = () => {
		setIsNotesModalVisible(false);
	};

	const searchActiveInactive = async (productId: number) => {
		const snackbarData: SnackbarOptions = {};

		try {
			if (productId <= 0) return;
			if (!brand) return;
			const searchParams: any = {
				page: pageNumber - 1,
				size: 100,
				statuses: ['ACTIVE', 'CANCELLED', 'FAILED_PAYMENT', 'TERMINATED'],
				brandCode: brand.code,
			};
			if (searchType === 'a') {
				searchParams.creationFrom = fromDate.toISOString();
				searchParams.creationTo = toDate.toISOString();
				searchParams.sort = ['creationDate,DESC'];
			}
			if (searchType === 's') {
				searchParams.expirationFrom = fromDate.toISOString();
				searchParams.expirationTo = toDate.toISOString();
				searchParams.sort = ['productService.expirationDate,ASC'];
			}
			const response = await getSearchActiveInactive(productId, searchParams);
			console.log(response.content);
			// const users: any[] = response.content.filter((item: any) => item.user).flatMap((item: any) => { item.user, item.creationDate, item.productService.expirationDate });
			const users: any[] = response.content.reduce((acc: any, item: any) => {
				if (item.user) {
					const {
						user,
						creationDate,
						productService: { expirationDate },
					} = item;
					acc.push({ ...user, creationDate, expirationDate });
				}
				return acc;
			}, []);
			console.log(users);
			setSearchResults(users);
			setTotalElements(response.totalElements);
			setTotalPages(response.totalPages);
			setPageSize(response.size);
			// localStorage.setItem('activeInactiveearchResults', JSON.stringify(response.content));
		} catch (error: any) {
			snackbarData.type = 'error';
			snackbarData.message = 'Errore nella ricerca degli utenti per il servizio selezionato';
			dispatch(addSnackbar(snackbarData));
			if (!isMounted()) return;
		}
	};

	const handleProductSelectChange = (value: string) => {
		setProductSelect(Number(value));
	};

	const handleSearchTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSearchType(event.currentTarget.value);
	};

	const onYearChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedYear(+event.currentTarget.value);
	};

	const onMonthChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedMonth(+event.currentTarget.value);
	};

	// useEffect(() => {
	// 	const lastSearch = localStorage.getItem('activeInactiveearchResults');
	// 	console.log('lastSearch', lastSearch);
	// 	const lastSearchJSON: User[] = lastSearch && lastSearch !== 'undefined' ? JSON.parse(lastSearch) : [];
	// 	setSearchResults(lastSearchJSON);
	// }, []);

	useEffect(() => {
		if (productSelect > 0) {
			searchActiveInactive(productSelect);
		}
	}, [pageNumber]);

	useEffect(() => {
		if (productSelect > 0) {
			setSearchResults([]);
			setPageNumber(1);
			setPageSize(0);
			setTotalElements(0);
			setTotalPages(0);
			searchActiveInactive(productSelect);
		}
	}, [productSelect, searchType, selectedYear, selectedMonth]);

	useEffect(() => {
		if (!brand) return;
		const GP = async () => {
			const products = await getProducts({ types: ['service'], brandCode: brand.code}, isAdmin || isSuperAdmin);
			setProductsForSelect(products.sort((a: any, b: any) => (a.name > b.name ? 1 : -1)));
		};
		GP();
	}, [brand]);

	const breadCrumbSettings: TitleBreadCrumbSettings = { subSectionText: 'Attivi e Disattivi' };

	const columnsMapping: ActiveInactiveTableColumn[] = [
		{ label: '', width: '1%', enabled: true },
		{ label: 'ID', width: '2%', enabled: true },
		{ label: 'ANAGRAFICA', width: '12%', enabled: true },
		// { label: 'SALDO PUNTI', width: '4%', enabled: true },
		{ label: 'FB COLLEGATO', width: '7%', enabled: true },
		{ label: 'MISSIONI', width: '5%', enabled: true },
		{ label: 'BADGE', width: '5%', enabled: true },
		// { label: 'PRODOTTI', width: '4%', enabled: true },
		// // {label:'AUTOSTRADA',width: '1%', enabled: true},
		// // {label:'LEZ. AP',width: '1%', enabled: true},
		{ label: 'STATS', width: '4%', enabled: true },
		{ label: 'NOTE', width: '2%', enabled: true },
		{ label: 'ATTIVAZ.', width: '3%', enabled: true },
		{ label: 'SCADENZA', width: '3%', enabled: true },
	];

	const years = [];

	for (let i = new Date().getFullYear() + 2; i > new Date().getFullYear() - 5; i--) {
		years.push(i);
	}

	const fromDate = new Date(selectedYear, selectedMonth, 1);
	const toDate = new Date(selectedYear, selectedMonth + 1, 0, 23, 59, 59, 999);

	if (!isSuperAdmin && !isAdmin && !isAuthor) return <></>;

	return (
		<>
			<Page>
				<DashboardTitleSection breadCrumbSettings={breadCrumbSettings}>
					<Filters>
						<select
							id='searchType'
							name='searchType'
							onChange={handleSearchTypeChange}
						>
							<option
								key={1}
								value='a'
							>
								Attivazioni
							</option>
							<option
								key={2}
								value='s'
							>
								Scadenze
							</option>
						</select>
						<select
							id='year'
							name='year'
							value={selectedYear}
							onChange={onYearChangeHandler}
							disabled={productSelect <= 0}
						>
							{years.map((y) => (
								<option key={y}>{y}</option>
							))}
						</select>
						<select
							id='month'
							name='month'
							value={selectedMonth}
							onChange={onMonthChangeHandler}
							disabled={productSelect <= 0}
						>
							{months.map((m) => (
								<option
									key={m.name}
									value={m.num}
								>
									{m.name}
								</option>
							))}
						</select>
					</Filters>

					<ServiceSelect
						value={productSelect}
						onChange={(event) => handleProductSelectChange(event.target.value)}
					>
						<option value={0}>Scegli un servizio</option>
						{productsForSelect.map((a) => {
							return (
								<option value={a.id}>
									{a.name} | {a.id}
								</option>
							);
						})}
					</ServiceSelect>
				</DashboardTitleSection>

				{searchResults && (
					<ActiveInactiveTable
						data={searchResults}
						columnsMapping={columnsMapping}
						totalElements={totalElements}
						totalPages={totalPages}
						pageSize={pageSize}
						pageNumber={pageNumber}
						nextPageHandler={nextPageHandler}
						prevPageHandler={prevPageHandler}
						notesClickHandler={notesClickHandler}
					/>
				)}
			</Page>
			{isNotesModalVisible && (
				<NotesModal
					onCloseButtonModal={closeNotesModal}
					notes={notesForModal}
					userId={userIdForNotesModal}
					searchActiveInactive={() => {
						searchActiveInactive(productSelect);
					}}
				/>
			)}
		</>
	);
};

export default ActiveInactivePage;
