import React, { FC, useEffect, useState } from 'react';

//types
import { NodeTitleProps } from './NodeTitle.component.d';

//assets

import { ReactComponent as ModifyIcon } from '../assets/modifyIcon.svg';

//style
import { NodeTitleBox, TitleModify } from './style/NodeTitle.component.style';
import { CourseNode } from 'types/Course';

const NodeTitle: FC<NodeTitleProps> = ({ nodeId, nodeTitle, updateLocalNode, enabled }) => {
	const [isEnabled, setIsEnabled] = useState(false);
	const [isOnHover, setIsOnHover] = useState(false);
	const [localTitle, setLocalTitle] = useState('');
	const [oldTitle, setOldTitle] = useState('');

	const onMouseOverHandler = () => {
		setIsOnHover(true);
	};

	const onMouseOutHandler = () => {
		setIsOnHover(false);
	};

	const onClickHandler = () => {
		setIsEnabled(true);
	};

	const onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
		const code = event.code;

		if (code === 'Enter' || code === 'NumpadEnter') {
			updateLocalNode(nodeId, { title: localTitle } as CourseNode);
			event.currentTarget.blur();
		}
		if (code === 'Escape') {
			if (oldTitle) setLocalTitle(oldTitle);
			event.currentTarget.blur();
		}
	};

	const onFocusHandler = (event: React.FocusEvent<HTMLInputElement>) => {
		setOldTitle(event.currentTarget.value);
		event.currentTarget.select();
	};

	const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
		updateLocalNode(nodeId, { title: localTitle } as CourseNode);
	};

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		if(event.currentTarget.value) setLocalTitle(event.currentTarget.value);
	};

	useEffect(() => {
		if (nodeTitle) setLocalTitle(nodeTitle);
	}, [nodeTitle]);

	useEffect(() => {
		if (enabled === undefined) return;
		setIsEnabled(enabled);
	}, [enabled]);

	return (
		<>
			<NodeTitleBox
				type='text'
				disabled={!isEnabled}
				value={localTitle}
				size={localTitle ? localTitle.length+5 : 10}
				onMouseOver={onMouseOverHandler}
				onMouseOut={onMouseOutHandler}
				onClick={onClickHandler}
				onChange={onChangeHandler}
				onKeyDown={onKeyDownHandler}
				onBlur={onBlurHandler}
				onFocus={onFocusHandler}
			/>
			{isOnHover && isEnabled && (
				<TitleModify>
					<ModifyIcon />
				</TitleModify>
			)}
		</>
	);
};

export default NodeTitle;
