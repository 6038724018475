import { callGET } from 'network/network';

const _USER_VIP_POINTS_PATH = 'v1/vippoints/{userId}';

export const getUserVipPoints = async (userId: number) => {
	try {
		const response = await callGET(_USER_VIP_POINTS_PATH.replace(
			/{userId}/g,
			userId.toString()
		  ), true);

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};