import { callGET, callPOST } from 'network/network';
import { Level } from 'types/Level';

import { levelsSearchParams } from './LevelsPage.services.d';

import { getBrand } from 'configs/brandMap/brandMap.helper';

const _LEVELS_PATH = 'v1/levels';

export const getLevels = async (levelsSearchParams: levelsSearchParams) => {
	try {
		const response = await callGET(_LEVELS_PATH, true, levelsSearchParams);

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const addLevel = async (newLevel: Partial<Level>) => {
	const brand = getBrand();
	if (!brand) return;
	try {
		const response = await callPOST(_LEVELS_PATH, newLevel, true, {
			brandCode: brand.code,
		});

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};
