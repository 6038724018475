import { FC, useEffect, useState } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsFirstRender, useIsMounted } from 'usehooks-ts';
import useHasRole from 'hooks/useHasRole';
import useHasPermission from 'hooks/useHasPermission';

//utils
import { getSearchPayments, getAllProductsMinimal } from './services/PaymentsPage.services';
import { getSearchUsers } from 'pages/UsersPage/services/UsersPage.services';

//types
import { TitleBreadCrumbSettings } from 'components/DashboardTitleSection/DashboardTitleSection.component.d';
import { TabSelectorSettings, TabSelectorItem } from 'components/TabSelector/TabSelector.component.d';
import { RadioOption } from './PaymentsPage.d';
import { PurchaseBasic } from 'types/PurchaseBasic';
import { User } from 'types/User';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

//components
import DashboardTitleSection from 'components/DashboardTitleSection';
import PaymentsTable from './PaymentsTable/PaymentsTable.component';

//assets
import { ReactComponent as SearchIconSVG } from './assets/searchIcon.svg';
import { ReactComponent as ClearButtonIconSVG } from './assets/clearButtonIcon.svg';
import { ReactComponent as AttachmentIcon } from './assets/attachmentIcon.svg';
import { ReactComponent as DescriptionIcon } from './assets/descriptionIcon.svg';

//style
import { BaseContentCard } from 'style/wrappers/wrappers';
import {
	Page,
	SearchModeFieldSet,
	PageContent,
	SearchUsersBox,
	SearchInput,
	SearchButton,
	ZeroPaymentsCheckBox,
	ProductSelection,
	ProductLabel,
	ProductInput,
	ProductExpandButton,
	OptionList,
	ModeBox,
	CheckBoxesBox,
	SearchFiltersFieldSet,
	UsersSelect,
	FilterClearButton,
} from './style/PaymentsPage.style';
import AssignPaymentModal from './PaymentsTable/AssignPaymentModal';
import { enableAppMain } from 'redux/actions/actions-app-main';
import { PaymentsSearchParams } from './services/PaymentsPage.services.d';
import useAppSelector from 'hooks/useAppSelector';
import { useCombobox } from 'downshift';
import { Product } from 'types/Product';
import { Brand } from 'types/Brand';

const PaymentsPage: FC<{}> = () => {
	const [searchResults, setSearchResults] = useState<PurchaseBasic[]>([]);
	const [totalPages, setTotalPages] = useState<number>(0);
	const [totalElements, setTotalElements] = useState<number>(0);
	const [pageSize, setPageSize] = useState<number>(0);
	const [searchMode, setSearchMode] = useState('all');
	const [pageNumber, setPageNumber] = useState<number>(1);

	const [searchUserInputValue, setSearchUserInputValue] = useState<string>('');
	const [searchUserInput, setSearchUserInput] = useState<string>('');
	const [searchUserResults, setSearchUserResults] = useState<User[]>();
	const [userSelectEnabled, setUserSelectEnabled] = useState(false);
	const [totalUserPages, setTotalUserPages] = useState<number>(0);
	const [totalUserElements, setTotalUserElements] = useState<number>(0);
	const [pageUserSize, setPageUserSize] = useState<number>(0);
	const [selectedUserId, setSelectedUserId] = useState(-1);
	const [selectedProduct, setSelectedProduct] = useState<Product>({} as Product);

	const [zeroPaymentsEnabled, setZeroPaymentsEnabled] = useState(false);
	const [createdPaymentsEnabled, setCreatedPaymentsEnabled] = useState(false);
	const [enabledStatuses, setEnabledStatuses] = useState<string[]>(['COMPLETED', 'PRODUCT_ASSIGNED', 'REFUNDED']);

	const [isAssignPaymentModalVisible, setIsAssignPaymentModalVisible] = useState(false);
	const [purchaseForModal, setPurchaseForModal] = useState<PurchaseBasic>({} as PurchaseBasic);
	const [products, setProducts] = useState<Product[]>([]);
	const [localProducts, setLocalProducts] = useState<Product[]>([]);

	const isFirstRender = useIsFirstRender();

	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isAdmin = useHasRole('ADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');
	const isAmministrazione = useHasRole('CONTABILITÀ');

	const dispatch = useAppDispatch();
	const isMounted = useIsMounted();

	const availableStatuses = ['CREATED', 'WAITING_FOR_AUTHORIZATION', 'PARTIAL_PAID', 'COMPLETED', 'CANCELED', 'PRODUCT_ASSIGNED', 'REFUNDED', 'REFUND', 'COMPLETED_TO_BE_DISTRIBUTED'];

	const {
		inputValue,
		setInputValue,
		selectedItem,
		isOpen,
		getToggleButtonProps,
		getLabelProps,
		getMenuProps,
		highlightedIndex,
		getItemProps,
		getInputProps,
	} = useCombobox({
		items: localProducts,
		onInputValueChange({ inputValue }) {
			if (inputValue === '') setSelectedProduct({} as Product);
			if (products) setLocalProducts(products.filter((p) => !inputValue || p.name.toLowerCase().includes(inputValue.toLowerCase())));
		},
		onSelectedItemChange: (changes) => {
			if (changes.selectedItem) {
				console.log(changes.selectedItem);

				setSelectedProduct(changes.selectedItem);
				setPageNumber(1);
			}
		},
		itemToString(item) {
			return item ? item.name : '';
		},
	});

	const nextPageHandler = () => setPageNumber((prevState) => prevState + 1);

	const prevPageHandler = () => setPageNumber((prevState) => prevState - 1);

	const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchMode(event.target.value); 
		if (event.target.value === "bank") setEnabledStatuses(['CREATED', 'PARTIAL_PAID']);
		if (event.target.value !== "bank") setEnabledStatuses(['COMPLETED', 'PRODUCT_ASSIGNED', 'REFUNDED']);
		setPageNumber(1);
	};

	const clearUsersSearchHandler = () => {
		setSearchUserInput('');
		setSearchUserInputValue('');
		setSearchUserResults([]);
		setSelectedUserId(-1);
		setUserSelectEnabled(false);
	};

	const clearProductsSearchHandler = () => {
		setInputValue('');
		setSelectedProduct({} as Product);
	};

	const searchChangeHandler = (searchValue: any) => {
		setSearchUserInputValue(searchValue.target.value);
	};

	const selectionChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedUserId(+event.currentTarget.value);
		setPageNumber(1);
	};

	const searchKeyHandler = (event: any) => {
		if (event.keyCode === 27) {
			clearUsersSearchHandler();
		}
		if (event.keyCode === 13) {
			setSearchUserInput(event.target.value);
		}
	};

	const searchButtonHandler = () => {
		if (searchUserInputValue === '') {
			setSearchUserResults([]);
			setPageSize(0);
			setTotalElements(0);
			setTotalPages(0);
			return;
		}
		setSearchUserInput(searchUserInputValue);
	};

	const handleZeroPaymentsClick = () => {
		setZeroPaymentsEnabled((prevState) => !prevState);
	};

	const handleCreatedPaymentsClick = () => {
		setCreatedPaymentsEnabled((prevState) => !prevState);
	};

	const handleChangeStatusFilter: React.MouseEventHandler<HTMLInputElement> = (event) => {
		const status = event.currentTarget.value;
		if (event.currentTarget.checked) {
		  setEnabledStatuses(prevEnabledStatuses => [...prevEnabledStatuses, status]);
		} else {
		  setEnabledStatuses(prevEnabledStatuses => prevEnabledStatuses.filter(enabledStatuses => enabledStatuses !== status));
		}
	  };
	  
	  
	  

	const assignPaymentClick = (purchase: PurchaseBasic) => {
		setIsAssignPaymentModalVisible(true);
		setPurchaseForModal(purchase);
	};

	const closeAssignModal = () => {
		setIsAssignPaymentModalVisible(false);
		dispatch(enableAppMain());
		searchPayments(0);
	};

	const searchPayments = async (page: number) => {
		let params: PaymentsSearchParams = {
			mode: searchMode,
			page: page - 1,
			size: 50,
			includeZeros: zeroPaymentsEnabled,
			statuses: enabledStatuses,
			includeDeleted: true,
		};
		if (selectedUserId > 0) params.userId = selectedUserId;
		if (selectedProduct.id) params.productId = selectedProduct.id;
		const response = await getSearchPayments(params);

		setSearchResults(response.content);
		setTotalElements(response.totalElements);
		setTotalPages(response.totalPages);
		setPageSize(response.size);
	};

	const searchUsers = async () => {
		const snackbarData: SnackbarOptions = {};

		try {
			if (searchUserInput.length === 0) return;
			const response = await getSearchUsers({ q: searchUserInput, page: 0, size: 200 });
			console.log(response.content);

			setSearchUserResults(response.content);
			setTotalUserElements(response.totalElements);
			setTotalUserPages(response.totalPages);
			setPageUserSize(response.size);
			setUserSelectEnabled(true);
		} catch (error: any) {
			snackbarData.type = 'error';
			snackbarData.message = 'Errore nella ricerca degli utenti';
			dispatch(addSnackbar(snackbarData));
			if (!isMounted()) return;
		}
	};

	const detailsPayment = async (page: number) => {};

	const refundPayment = async (page: number) => {};

	const deletePayment = async (page: number) => {};

	const breadCrumbSettings: TitleBreadCrumbSettings = { subSectionText: 'Pagamenti' };

	const radioOptions: RadioOption[] = [
		{ label: 'Ultimi', value: 'all' },
		{ label: 'Non Associati', value: 'notAssociated' },
		{ label: 'Bonifici da assegnare', value: 'bank' },
		// { label: 'Per Utente', value: 'userSearch' },
		// { label: 'Per Prodotto', value: 'productSearch' },
	];

	useEffect(() => {
		if (products) setLocalProducts(products);
	}, [products]);

	useEffect(() => {
		searchPayments(0);
	}, [searchMode]);

	useEffect(() => {
		searchUsers();
	}, [searchUserInput]);

	useEffect(() => {

		if (isFirstRender) return;
		searchPayments(0);
	}, [selectedUserId]);

	useEffect(() => {

		if (isFirstRender) return;
		searchPayments(0);
	}, [selectedProduct]);

	// useEffect(() => {
	// 	console.log('selectedProduct Change');

	// 	searchPayments(0);
	// }, [selectedProduct.id]);

	useEffect(() => {

		if (isFirstRender) return;
		searchPayments(pageNumber);
	}, [zeroPaymentsEnabled]);

	useEffect(() => {

		if (isFirstRender) return;
		searchPayments(pageNumber);
	}, [enabledStatuses]);

	useEffect(() => {

		if (isFirstRender) return;
		searchPayments(pageNumber);
	}, [pageNumber]);

	useEffect(() => {
		const gp = async () => {
			const allProducts = await getAllProductsMinimal();
			var sortedProducts = null;
			if (allProducts) {
				sortedProducts = allProducts.sort((a: Product, b: Product) => {
					const nameA = a.name.toLowerCase();
					const nameB = b.name.toLowerCase();

					if (nameA > nameB) return 1;
					if (nameA < nameB) return -1;
					return 0;
				});
			}
			if (sortedProducts) setProducts(sortedProducts);
		}
		gp();
	}, []);

	const isSearchButtonVisible: boolean = searchUserInputValue !== '';

	if (!isSuperAdmin && !isAdmin && !isMiniAdmin && !isAmministrazione) return <></>;

	return (
		<>
			<Page>
				<DashboardTitleSection breadCrumbSettings={breadCrumbSettings}></DashboardTitleSection>

				<BaseContentCard>
					<PageContent>
						<SearchModeFieldSet>
							<legend>Seleziona una modalità:</legend>
							<ModeBox>
								{radioOptions.map((option) => (
									<label key={option.value}>
										<input
											type='radio'
											value={option.value}
											checked={searchMode === option.value}
											onChange={handleOptionChange}
										/>
										{option.label}
									</label>
								))}
							</ModeBox>
						</SearchModeFieldSet>
						<SearchFiltersFieldSet>
							<legend>Filtri:</legend>
							<CheckBoxesBox>
								<ZeroPaymentsCheckBox>
									<input
										id='zeroPaymentsCheckbox'
										type={'checkbox'}
										checked={zeroPaymentsEnabled}
										onClick={handleZeroPaymentsClick}
									/>
									<label htmlFor='zeroPaymentsCheckbox'>a ZERO</label>
								</ZeroPaymentsCheckBox>
								{availableStatuses.map((status) => { return (
								<ZeroPaymentsCheckBox>
									<input
										id={'statusFilter'+status}
										type={'checkbox'}
										checked={enabledStatuses.includes(status)}
										onClick={handleChangeStatusFilter}
										value={status}
									/>
									<label htmlFor='zeroPaymentsCheckbox' title={status}>{status.slice(0,10)}</label>
								</ZeroPaymentsCheckBox>)
								})}
							</CheckBoxesBox>
							<SearchUsersBox>
								<FilterClearButton onClick={clearUsersSearchHandler}>
									<ClearButtonIconSVG />
								</FilterClearButton>

								<SearchInput
									onKeyDown={searchKeyHandler}
									onChange={searchChangeHandler}
									value={searchUserInputValue}
									placeholder='Ricerca utente'
								/>

								<SearchButton onClick={searchButtonHandler}>
									<SearchIconSVG />
								</SearchButton>

								<UsersSelect
									id='users'
									value={selectedUserId}
									disabled={!userSelectEnabled}
									onChange={selectionChangeHandler}
								>
									<option></option>
									{searchUserResults &&
										searchUserResults
											.sort((a, b) => {
												if (a.name + a.surname > b.name + b.surname) return 1;
												if (a.name + a.surname < b.name + b.surname) return -1;
												return 0;
											})
											.map((su) => (
												<option
													value={su.id}
													key={su.id}
												>{`${su.id} | ${su.name} ${su.surname}`}</option>
											))}
								</UsersSelect>
							</SearchUsersBox>
							<ProductSelection>
								<FilterClearButton onClick={clearProductsSearchHandler}>
									<ClearButtonIconSVG />
								</FilterClearButton>
								<ProductLabel {...getLabelProps()}>Filtra Prodotto: </ProductLabel>

								<ProductInput
									placeholder=''
									{...getInputProps()}
								/>

								<ProductExpandButton
									aria-label='toggle menu'
									type='button'
									{...getToggleButtonProps()}
								>
									{isOpen ? <>&#8593;</> : <>&#8595;</>}
								</ProductExpandButton>
							</ProductSelection>
							<OptionList {...getMenuProps()}>
								{isOpen &&
									localProducts.map((item, index) => (
										<li
											key={`${item.name}${index}`}
											{...getItemProps({ item, index })}
										>
											<span>{item.name}</span>
											<span>{" " + item.type}</span>
											<span>{" (" + item.brands.map((branditem: Brand) => branditem.code).join(", ") + ")"}</span>										</li>
									))}
							</OptionList>
						</SearchFiltersFieldSet>

						<PaymentsTable
							data={searchResults}
							totalElements={totalElements}
							totalPages={totalPages}
							pageSize={pageSize}
							pageNumber={pageNumber}
							nextPageHandler={nextPageHandler}
							prevPageHandler={prevPageHandler}
							searchPayments={searchPayments}
							detailsPayment={detailsPayment}
							refundPayment={refundPayment}
							deletePayment={deletePayment}
							assignPaymentClick={assignPaymentClick}
						/>
					</PageContent>
				</BaseContentCard>
			</Page>
			{isAssignPaymentModalVisible && (
				<AssignPaymentModal
					onCloseButtonModal={closeAssignModal}
					purchase={purchaseForModal}
				/>
			)}
		</>
	);
};

export default PaymentsPage;
