import { FC, useState } from 'react';

// types
import { RowUpdateElementProps } from './RowUpdateElement.component.d';

// cpomponents
import NewElementModal from 'components/NewElementModal/NewElementModal.component';

// style
import { 
    CardContent,
    RowContent,
    ButtonRow,
    AddElementButton,
    DeleteElementButton,
    CloseAndCancel
} from './style/RowUpdateElement.style';
import DeleteElementModal from 'components/DeleteElementModal/DeleteElementModal.component';


const RowUpdateElement: FC<RowUpdateElementProps> = ({
    onCancelClickHandler,
    updateData,
    webpageId,
    elementsToDisplay,
    blockId,
    newElementPos
}) => {

    const [isAddNewElementModalVisible, setIsAddNewElementModalVisible] = useState<boolean>(false);
    const [isRemoveRowModalVisible, setIsRemoveRowModalVisible] = useState<boolean>(false);

    const addNewElementHandler = () => setIsAddNewElementModalVisible(prevState => !prevState);
    const onDeleteButtonHandler = () => setIsRemoveRowModalVisible(true);
    const onCancelButtonClickHandler = () => {

        setIsAddNewElementModalVisible(false);
        if (onCancelClickHandler) onCancelClickHandler();
    }


    return (
        <>
            <CardContent>
                <RowContent>
                    {elementsToDisplay}
                </RowContent>

                <ButtonRow>
                    <AddElementButton
                        onClick={addNewElementHandler}
                    >
                        Aggiungi un elemento
                    </AddElementButton>

                    <DeleteElementButton
                        onClick={onDeleteButtonHandler}
                    >
                        Elimina tutta la riga
                    </DeleteElementButton>

                    <CloseAndCancel
                        onClick={onCancelClickHandler}
                    >
                        Chiudi
                    </CloseAndCancel>
                </ButtonRow>
            </CardContent>

            {isAddNewElementModalVisible && (
                <NewElementModal 
                    onCloseButtonModal={onCancelButtonClickHandler}
                    typeOfLayout='ROW'
                    newElementPosition={newElementPos}
                    blockId={blockId}
                    updateData={updateData}
                    webpageId={webpageId}
                />)
            }

            {isRemoveRowModalVisible && (
                <DeleteElementModal 
                    onCloseButtonModal={onCancelButtonClickHandler}
                    blockId={blockId}
                    updateData={updateData}
                />)
            }
        </>
    );
};

export default RowUpdateElement;
