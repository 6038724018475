import { FC, useEffect, useState } from 'react';

//hooks
import { useCombobox } from 'downshift';
import useHasRole from 'hooks/useHasRole';

//types
import { PoolSelectionProps } from './PoolSelection.component.d';
import { Pool } from 'types/Pool';

//style
import { Balance, OptionList, PoolExpandButton, PoolInput, PoolLabel, PoolSelectionBox, Selection } from './style/PoolSelection.component.style';

const PoolSelection: FC<PoolSelectionProps> = ({ label, setPool, pools }) => {
	const [poolSelection, setPoolSelection] = useState<Pool[]>([]);
	const [selectedPool, setSelectedPool] = useState<Pool>();

	const isSuperAdmin = useHasRole('SUPERADMIN');

	useEffect(() => {
		setPoolSelection(pools);
	}, [pools]);

	const {
		inputValue,
		setInputValue,
		selectedItem,
		isOpen,
		getToggleButtonProps,
		getLabelProps,
		getMenuProps,
		highlightedIndex,
		getItemProps,
		getInputProps,
	} = useCombobox({
		items: poolSelection,
		onInputValueChange({ inputValue }) {
			if (!inputValue) {
				setSelectedPool(undefined);
				setPool(undefined);
			}
			if (pools) setPoolSelection(pools.filter((p) => !inputValue || p.name.toLowerCase().includes(inputValue.toLowerCase())));
		},
		onSelectedItemChange: (changes) => {
			if (changes.selectedItem) {
				setSelectedPool(changes.selectedItem);
				setPool(changes.selectedItem);
			}
			if (!changes.selectedItem) {
				setSelectedPool(undefined);
				setPool(undefined);
			}
		},
		itemToString(item) {
			return item ? item.name : '';
		},
	});

	const onInputBlur = () => {
		console.log("blur")
		if (!poolSelection.some(p => p.name === inputValue)) {
			setSelectedPool(undefined)
			setPool(undefined)
		}
	};

	const onInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
		event.currentTarget.select();
	};

	return (
		<PoolSelectionBox>
			<Selection>
				<PoolLabel {...getLabelProps()}>{label}</PoolLabel>

				<PoolInput
					placeholder=''
					{...getInputProps({ onBlur: onInputBlur })}
					onFocus={onInputFocus}
				/>

				<PoolExpandButton
					aria-label='toggle menu'
					type='button'
					{...getToggleButtonProps()}
				>
					{isOpen ? <>&#8593;</> : <>&#8595;</>}
				</PoolExpandButton>
				{selectedPool && isSuperAdmin && <Balance>{selectedPool?.availableBalance?.toLocaleString()}</Balance>}
			</Selection>
			<OptionList {...getMenuProps()}>
				{isOpen &&
					poolSelection.map((item, index) => (
						<li
							key={`${item.name}${index}`}
							{...getItemProps({ item, index })}
						>
							<span>{item.name}</span>
						</li>
					))}
			</OptionList>
		</PoolSelectionBox>
	);
};

export default PoolSelection;
