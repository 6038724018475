import { FC, useState } from 'react';

//utils
import { footerElementsCount } from './UsersTable.helper';

//types
import { UsersTableProps } from './UsersTable.component.d';

//components
import FacebookLinkedAccount from './FacebookLinkedAccount';
import Missions from './Missions';
import Badges from './Badges';
import Products from './Products';
import Stats from './Stats';
import Notes from './Notes';
import Buttons from './Buttons';
import GenericInfo from './GenericInfo';
import ResultsModal from '../../UserDetailPage/ResultsModal';

//assets
import { ReactComponent as ChevronLeftIcon } from './assets/chevronLeft.svg';
import { ReactComponent as ChevronRightIcon } from './assets/chevronRight.svg';
import { ReactComponent as EarningsIcon } from './assets/earnings.svg';

//style
import {
	FooterNavigationButton,
	FooterElementsCount,
	Table,
	TableBody,
	TableBodyCell,
	TableBodyCellLeft,
	TableBodyRow,
	TableFooter,
	TableHead,
	TableHeadCell,
	TableHeadRow,
	Earnings,
} from './style/UsersTable.style';

const UsersTable: FC<UsersTableProps> = ({
	data,
	columnsMapping,
	totalPages,
	totalElements,
	pageSize,
	nextPageHandler,
	prevPageHandler,
	pageNumber,
	productsClickHandler,
	notesClickHandler,
}) => {

	const [resultsModalUserId, setResultsModalUserId] = useState<number>(0);
	const [resultsModalUserName, setResultsModalUserName] = useState<string>("");
	const [isResultsModalVisible, setIsResultsModalVisible] = useState<boolean>(false);

	const openResultsModal = (userId: number, userName: string) => {
		setResultsModalUserId(userId);
		setResultsModalUserName(userName);
		setIsResultsModalVisible(true);
	}

	const closeResultsModal = () => {
		setIsResultsModalVisible(false);
		setResultsModalUserId(0);
	}

	return (
		<>
			<Table>
				<TableHead>
					<TableHeadRow>
						{columnsMapping
							.filter((c) => c.enabled)
							.map((c) => (
								<TableHeadCell
									key={c.label}
									width={c.width}
									// onClick={() => handleSort(key)}
								>
									{c.label.toUpperCase()}
									{/* {sortColumn === c && <span>{sortDirection === 'asc' ? '⬆️' : '⬇️'}</span>} */}
								</TableHeadCell>
							))}
					</TableHeadRow>
				</TableHead>
				<TableBody>
					{data.map((row, index) => (
						<TableBodyRow key={index}>
							<TableBodyCell
								key={index + '_buttons'}
								id={index + '_buttons'}
							>
								<Buttons
									key={index + '_buttons-box'}
									userId={row.id}
								/>
							</TableBodyCell>
							{columnsMapping.find((c) => c.label === 'ID')?.enabled && (
								<TableBodyCell
									key={index + '_id'}
									id={index + '_id'}
								>
									{row.id}
								</TableBodyCell>
							)}
							{columnsMapping.find((c) => c.label === 'ANAGRAFICA')?.enabled && (
								<TableBodyCell
									key={index + '_email'}
									id={index + '_email'}
								>
									<GenericInfo
										name={row.name}
										surname={row.surname}
										email={row.email}
										nickname={row.nickname ? row.nickname : undefined}
										cognitoId={row.federateds.filter((f) => f.federatedName === 'Cognito')[0]?.federateUserdId}
										referral={row.referral}
									/>
								</TableBodyCell>
							)}
							{columnsMapping.find((c) => c.label === 'SALDO PUNTI')?.enabled && (
								<TableBodyCellLeft
									key={index + '_punti'}
									id={index + '_punti'}
								>
									<small>liberi: {row.pools.reduce((acc, p) => acc + ((p.type ==="PERSONAL") && p.balance ? p.balance : 0) + 0, 0).toLocaleString().split(',')[0]}</small><br/>
									<small>staking: {row.pools.reduce((acc, p) => acc + ((p.type === 'STACKING') && p.balance ? p.balance : 0) + 0, 0).toLocaleString().split(',')[0]}</small>

								</TableBodyCellLeft>
							)}
							{columnsMapping.find((c) => c.label === 'FB COLLEGATO')?.enabled && (
								<TableBodyCell
									key={index + '_fb'}
									id={index + '_fb'}
								>
									<FacebookLinkedAccount
										key={index + '_fbaccount'}
										userFederateds={row.federateds}
										cognitoIdEnabled={true}
									/>
								</TableBodyCell>
							)}
							{/* {columnsMapping.find((c) => c.label === 'MISSIONI')?.enabled && (
								<TableBodyCell
									key={index + '_missions'}
									id={index + '_missions'}
								>
									<Missions
										key={index + '_user-missions'}
										userId={row.id}
										missions={row.missions}
									/>
								</TableBodyCell>
							)} */}
							{columnsMapping.find((c) => c.label === 'BADGE')?.enabled && (
								<TableBodyCell
									key={index + '_badges'}
									id={index + '_badges'}
								>
									<Badges
										key={index + '_user-badges'}
										userId={row.id}
										badges={row.badges}
									/>
								</TableBodyCell>
							)}
							{columnsMapping.find((c) => c.label === 'PRODOTTI')?.enabled && (
								<TableBodyCell
									key={index + '_products'}
									id={index + '_products'}
								>
									<Products
										key={index + '_user-products'}
										productSubscriptions={row.productSubscriptions}
										onClickHandler={productsClickHandler}
									/>
								</TableBodyCell>
							)}
							<TableBodyCell
									key={index + '_Results'}
									id={index + '_Results'}
								>
									<Earnings>
									<EarningsIcon
										onClick={() => openResultsModal(row.id, row.name+" "+row.surname)}
									/>
									</Earnings>
							</TableBodyCell>

							{columnsMapping.find((c) => c.label === 'STATS')?.enabled && (
								<TableBodyCell
									key={index + '_stats'}
									id={index + '_stats'}
								>
									<Stats
										signupDate={row.stats?.registrationDate}
										lastLoginDate={row.stats?.lastLoginDate}
										firstPurchaseDate={row.stats?.firstPurchaseDate}
										lastPurchaseDate={row.stats?.lastPurchaseDate}
									/>
								</TableBodyCell>
							)}
							{/* <TableBodyCell
								key={index + '_highway'}
								id={index + '_highway'}
							>
								Autostrada
							</TableBodyCell>
							<TableBodyCell
								key={index + '_APlessons'}
								id={index + '_APlessons'}
							>
								Lez. AP
							</TableBodyCell>*/}
							{columnsMapping.find((c) => c.label === 'NOTE')?.enabled && (
								<TableBodyCell
									key={index + '_notes'}
									id={index + '_notes'}
								>
									<Notes
										userId={row.id}
										notes={row.stats?.notes}
										notesClickHandler={notesClickHandler}
									/>
								</TableBodyCell>
							)}
						</TableBodyRow>
					))}
				</TableBody>
			</Table>
			{totalElements > 0 && (
				<TableFooter>
					<FooterElementsCount>{footerElementsCount({ currentPage: pageNumber, totalPages, totalElements, pageSize })}</FooterElementsCount>
					<FooterNavigationButton
						onClick={prevPageHandler}
						disabled={pageNumber === 1}
					>
						<ChevronLeftIcon />
					</FooterNavigationButton>
					<FooterNavigationButton
						onClick={nextPageHandler}
						disabled={pageNumber === totalPages}
					>
						<ChevronRightIcon />
					</FooterNavigationButton>
				</TableFooter>
			)}
			{isResultsModalVisible && (
				<ResultsModal
					onCloseButtonModal={closeResultsModal}
					userId={resultsModalUserId}
					userName={resultsModalUserName}
				/>
			)}
		</>
	);
};

export default UsersTable;
