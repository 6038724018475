import { FC } from 'react';

//types
import { WithdrawalsTableColumn, WithdrawalsTableProps } from './WithdrawalsTable.component.d';

//utils
import { footerElementsCount } from 'pages/APLessonsPage/APLessonsNewTable/APLessonsNewTable.helper';

//assets
import { ReactComponent as ChevronLeftIcon } from './assets/chevronLeft.svg';
import { ReactComponent as ChevronRightIcon } from './assets/chevronRight.svg';

//style
import {
	FooterElementsCount,
	FooterNavigationButton,
	Table,
	TableBody,
	TableBodyCell,
	TableBodyRow,
	TableFooter,
	TableHead,
	TableHeadCell,
	TableHeadRow,
} from './style/WithdrawalsTable.component.style';

const columns: WithdrawalsTableColumn[] = [
	{ label: 'ID', width: '2%' },
	{ label: 'NOME', width: '10%' },
	{ label: 'DESCRIZIONE', width: '10%' },
	{ label: 'DATA', width: '5%' },
	{ label: 'PUNTI', width: '5%' },
];

const WithdrawalsTable: FC<WithdrawalsTableProps> = ({ data, totalElements, totalPages, pageSize, pageNumber, nextPageHandler, prevPageHandler }) => {
	return (
		<>
			<Table>
				<TableHead>
					<TableHeadRow>
						{columns.map((c) => (
							<TableHeadCell
								key={c.label}
								width={c.width}
								// onClick={() => handleSort(key)}
							>
								{c.label.toUpperCase()}
								{/* {sortColumn === c && <span>{sortDirection === 'asc' ? '⬆️' : '⬇️'}</span>} */}
							</TableHeadCell>
						))}
					</TableHeadRow>
				</TableHead>
				<TableBody>
					{data
						.filter((w) => w.amount < 0)
						.map((row, index) => (
							<TableBodyRow key={index}>
								<TableBodyCell
									key={index + '_id'}
									id={index + '_id'}
								>
									{row.id}
								</TableBodyCell>
								<TableBodyCell
									key={index + '_name'}
									id={index + '_name'}
								>
									{row.pool.name}
								</TableBodyCell>
								<TableBodyCell
									key={index + '_description'}
									id={index + '_description'}
								>
									{row.description}
								</TableBodyCell>
								<TableBodyCell
									key={index + '_date'}
									id={index + '_date'}
								>
									{new Date(row.creationDate).toLocaleDateString()}
								</TableBodyCell>
								<TableBodyCell
									key={index + '_amount'}
									id={index + '_amount'}
								>
									{Math.abs(row.amount).toLocaleString()}
								</TableBodyCell>
							</TableBodyRow>
						))}
				</TableBody>
			</Table>
			{totalElements > 0 && (
				<TableFooter>
					<FooterElementsCount>{footerElementsCount({ currentPage: pageNumber, totalPages, totalElements, pageSize })}</FooterElementsCount>
					<FooterNavigationButton
						onClick={prevPageHandler}
						disabled={pageNumber === 1}
					>
						<ChevronLeftIcon />
					</FooterNavigationButton>
					<FooterNavigationButton
						onClick={nextPageHandler}
						disabled={pageNumber === totalPages}
					>
						<ChevronRightIcon />
					</FooterNavigationButton>
				</TableFooter>
			)}
		</>
	);
};

export default WithdrawalsTable;
