import { FC } from 'react';

// types
import { BaseFieldsetProps } from './BaseFieldset.d';

// style
import { Fieldset, Legend } from './style/BaseFieldset.style';

/**
 * BaseField
 *
 * @author Alessio Grassi
 *
 * @param legend - name that display on the top left corner inside the border line
 * @param children - the content of the fieldset
 *
 * @returns JSX
 */

const BaseFieldset: FC<BaseFieldsetProps> = ({ ref, legend, children }) => {
	return (
		<Fieldset ref={ref}>
			{legend !== undefined && <Legend>{legend}</Legend>}

			{children}
		</Fieldset>
	);
};

export default BaseFieldset;
