import { callGET, callPUT } from 'network/network';
import { Product } from 'types/Product';

import { getBrand } from 'configs/brandMap/brandMap.helper';

const _PRODUCTS_PATH = 'v1/products';



export const getProductFromId = async (productId: number, brandCode: string) => {
	try {
		const response = await callGET(_PRODUCTS_PATH + '/' + productId, true, { brandCode });

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const putProduct = async (updatedProduct: Partial<Product>) => {
	const brand = getBrand();
	if (!brand) return;
	try {
		await callPUT(_PRODUCTS_PATH, updatedProduct, true, { brandCode: brand.code });
	} catch (error: any) {
		throw error;
	}
};
