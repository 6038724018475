/**
 * Create or modify single primitive value in local storage by key
 * @param {string} key - key of local storage, this last is created if not already present
 * @param {string} textToSave - actual value to save, must be first converted to string since localStorage.setItem only accepts string as value
 * @returns {void | null} void if all was good, null if any errors were found
 */
export const setItemToLocalStorage = (key: string, textToSave: string): void | null => {

    if (typeof Storage === undefined) return null;

    if ( (typeof key !== 'string') || (typeof textToSave !== 'string') ) {
        return null;
    }

    try {

        localStorage.setItem(key, textToSave);
        
    } catch (error) {

        // aka local storage is full
        return null;
    }
};

/**
 * Create or modify derived object in local storage by key
 * @param {string} key - key of local storage, this last is created if not already present
 * @param {string} objToSave - actual value to save, expected to be as a map or an array, will be converted inside to string
 * @returns {void | null} void if all was good, null if any errors were found
 */
export const setObjToLocalStorage = (key: string, objToSave: object): void | null => {

    if (typeof Storage === undefined) return null;

    if ( (typeof key !== 'string') || (typeof objToSave !== 'object') || objToSave === null ) {
        return null;
    }

    const stringifiedObj = JSON.stringify(objToSave);

    try {

        localStorage.setItem(key, stringifiedObj);

    } catch (error) {

        // aka local storage is full
        return null;
    }
};

/**
 * Return single primitive value from local storage by key
 * @param {string} key - key of local storage, this last is created if not already present
 * @returns {string | null} value from local storage by key as string, null if any errors were found
 */
export const getItemFromLocalStorage = (key: string): string | null => {

    if (typeof Storage === undefined) return null;
    if (typeof key !== 'string') return null;
    const itemFromLocalStorage = localStorage.getItem(key);

    return itemFromLocalStorage;
};

/**
 * Return derived object value from local storage by key
 * @param {string} key - key of local storage, this last is created if not already present
 * @returns {object | null} value from local storage by key as object, null if any errors were found
 */
export const getObjFromLocalStorage = (key: string): object | null => {

    if (typeof Storage === undefined) return null;
    if (typeof key !== 'string') return null;
    const itemFromLocalStorage = localStorage.getItem(key);
    if (!itemFromLocalStorage) return null;
    const parsedItem = JSON.parse(itemFromLocalStorage);
    
    return parsedItem;
};

/**
 * Delete value from local storage by key
 * @param {string} key - key of local storage, this last is created if not already present
 * @returns {void | null} void if all was good, null if any errors were found
 */
export const removeItemFromLocalStorage = (key: string): void | null => {

    if (typeof Storage === undefined) return null;
    if (typeof key !== 'string') return null;    
    localStorage.removeItem(key);
};
