/**
 * MultilineSelect Component
 *
 * A custom select component that allows options with text written on multiple lines if the text is too long.
 *
 * Author: Simone Riccetti
 * 
 * Props:
 * - options: An array of objects representing the available options. Each object should have the following shape:
 *   {
 *     value: string; // The value of the option
 *     label: string; // The label to be displayed for the option
 *     selected?: boolean; // Optional. Indicates if the option should be selected by default
 *   }
 * - maxWidth: Optional. The maximum width of the component.
 * - minWidth: Optional. The minimum width of the component.
 * - dropdownMinWidth: Optional. The minimum width of the dropdown menu.
 * - title: Optional. The title to be displayed when no option is selected. Default: 'Scegli'.
 * - onSelect: Optional. A function to be called when an option is selected. Receives the selected option as a parameter.
 * - isActive: Optional. Indicates if the component is active. Default: true.
 * - isAbsolutePosition: Optional. Indicates if the component should be positioned absolutely. Default: false.
 * - defaultValue: Optional. The value of the option to be selected by default.
 */

import React, { useState, useEffect } from 'react';
import styles from './style/MultilineSelect.module.css';
interface Option {
  value: string;
  label: string;
  selected?: boolean;
}

interface MultilineSelectProps {
  options: Option[];
  maxWidth?: string;
  minWidth?: string;
  dropdownMinWidth?: string;
  title?: string;
  onSelect?: (selectedValue: string | undefined) => void;
  isActive?: boolean;
  isAbsolutePosition?: boolean;
  defaultValue?: string;
}

const MultilineSelect: React.FC<MultilineSelectProps> = ({
  options,
  maxWidth,
  minWidth,
  dropdownMinWidth,
  title = 'Scegli',
  onSelect,
  isActive = true,
  isAbsolutePosition = false,
  defaultValue,
}) => {
  const [selectedOption, setSelectedOption] = useState<Option | undefined>(undefined);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    if (selectedOption) return;
    const defaultOption = options.find((option) => option.value === defaultValue);
    setSelectedOption(defaultOption);
  }, [selectedOption, options, defaultValue]);

  const handleOptionClick = (option: Option) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
    if (onSelect) {
      onSelect(option.value);
    }
  };

  const toggleDropdown = () => {
    if (isActive) {
      setIsDropdownOpen(!isDropdownOpen);
    }
  };

  const renderTitle = () => {
    const arrow = isDropdownOpen ? '\u25B2' : '\u25BC';
    return (
      <span>
        {title} {isActive && arrow}
      </span>
    );
  };

  return (
    <div
      className={`${styles['multiselect-wrapper']} ${isActive ? '' : styles.disabled} ${
        isAbsolutePosition ? styles.absolute : ''
      }`}
      style={{ maxWidth, minWidth }}
    >
      <div className={`${styles['selected-option']} ${isActive ? styles.pointer : ''}`} onClick={toggleDropdown}>
        {selectedOption ? selectedOption.label : renderTitle()}
      </div>
      {isDropdownOpen && (
        <div className={styles.dropdown} style={{ minWidth: dropdownMinWidth }}>
          {options.map((option) => (
            <div key={option.value} className={styles.option} onClick={() => handleOptionClick(option)}>
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultilineSelect;
