import { callPUT } from 'network/network';

const _SAVE_NOTES_LINK_PATH = 'v1/productlessonpacks/updatecoachnotes/{lessonId}';

export const saveNotes = async (lessonId: number, publicNotes?: string, privateNotes?: string) => {
    try { 
        var params: any = {
        }
        if (publicNotes) {
            params = {...params, coachNotes: publicNotes};
        }
        if (privateNotes) {
            params = {...params, coachPrivateNotes: privateNotes};
        }
        const response = await callPUT(_SAVE_NOTES_LINK_PATH.replace(
			/{lessonId}/g,
			lessonId.toString()
		  ), {}, true, params);

        return response;
    } catch (error: any) {
        throw error;
    }
};
