import { FC, useEffect, useState } from 'react';

//hooks
import useAppSelector from 'hooks/useAppSelector';

// types
import { Brand } from 'types/Brand';

// style
import { CircularImage, CircularButton, BrandCode } from './style/BrandSelector.style';

import { getBrandsList } from 'services/brand.services';
import { current } from '@reduxjs/toolkit';


const BrandSelector: FC<{}> = () => {

    const [brandList, setBrandList] = useState<Brand[]>([]);
    const [urlParams, setUrlParams] = useState<URLSearchParams>();

    const currentBrand = useAppSelector((state) => state.ui.brand) as Brand;

	const domain = window.location.hostname;
    const topLevelDomain = domain.split('.').slice(-2).join('.');
    const brandSvgLinkTo = `https://www.${topLevelDomain}`;
    const path = window.location.pathname;
    const searchQuery = window.location.search;
    const completePathQuery = `${path}${searchQuery}`;


    const moveItemToFirstPosition = (array: Brand[], itemId: number): Brand[] => {
        const index = array.findIndex(item => item.id === itemId);
        
        if (index === -1) {
          return array;
        }
      
        const newArray = [array[index], ...array.slice(0, index), ...array.slice(index + 1)];
        return newArray;
    }

    const onBrandClick = (brandId: number) => {
        // console.log('Clicked brand ID:', brandId);
        // You can perform further actions based on the clicked brand ID here
      };

    useEffect(() => {
        const gb = async () => {
            const tempBrandList: Brand[] = await getBrandsList();
            setBrandList(tempBrandList);       
            const currentUrl = window.location.href;
            const url = new URL(currentUrl);
            const searchParams = new URLSearchParams(url.search);
            searchParams.delete('brand');
            setUrlParams(searchParams);     
        }
        gb();
    }, []);

    useEffect(() => {
        if (brandList.length === 0) return;
        if (!currentBrand) return;

        // #### Per testare un brand usare direttamente id numerico
        // if (brandList[0].id === 2) return;
        // const newList = moveItemToFirstPosition(brandList, 2);
        // #### Per gestire il vero brand attuale
        if (brandList[0].id === currentBrand.id) return;
        const newList = moveItemToFirstPosition(brandList, currentBrand.id);

        if (newList !== brandList) setBrandList(newList);
    }, [brandList, currentBrand]);

    return (
    <>
        {brandList.map((brand, index) => <>
            <a href={(index === 0) ? brandSvgLinkTo : brand.id === currentBrand.id ? domain.includes('localhost') ? '' : brandSvgLinkTo : domain.includes('localhost') ? window.location.href.split('?')[0]+'?'+urlParams?.toString()+'&brand='+brand.code : ('https://backoffice.'+brand.domain+completePathQuery).replace('app.', '')}>
                <CircularButton
                    key={brand.id}
                    size={index === 0 ? 112 : 32}
                    isMB={brand.code === 'MB'}
                    isTCR={brand.code === 'TCR'}
                    isCircular={index > 0} 
                    onClick={() => onBrandClick(brand.id)} // Click handler
                >
                    { brand.logo ? <img src={brand.logo.url ? brand.logo.url : ''} alt={brand.name} /> : <BrandCode>{brand.code}</BrandCode>}
                </CircularButton>
            </a>
        </>)}
    </>
    );

}

export default BrandSelector;