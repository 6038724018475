import { FC, useEffect, useState } from 'react';

//types
import { CloseLessonModalProps } from './CloseLessonModal.component.d';

//components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';

//style
import { 
	ButtonsRow, 
	CancelButton, 
	SaveButton, 
	CloseLessonBox, 
	NotesTextArea,
	NotesRow,
	NotesColumn,
	NotesTitle,
} from './style/CloseLessonModal.component.style';

import { enableAppMain } from 'redux/slices/slice-app-main';
import useAppDispatch from 'hooks/useAppDispatch';

const CloseLessonModal: FC<CloseLessonModalProps> = ({ onCloseButtonModal, onSaveButtonModal }) => {
	const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);

	const [customerNotes, setCustomerNotes] = useState<string>('');
	const [privateNotes, setPrivateNotes] = useState<string>('');

	const [isContentModified, setIsContentModified] = useState<boolean>(false);

	const dispatch = useAppDispatch();

	const clearInput = () => {
		setCustomerNotes('');
		setPrivateNotes('');
	}

	const inputCustomerChangeHandler = (inputValue: any) => {
		if (isContentModified === false) setIsContentModified(true);
		setCustomerNotes(inputValue.target.value);
	};

	const inputCustomerKeyHandler = (event: any) => {
		if (event.keyCode === 27) {
			closeButtonHandler();
		}
		// if (event.keyCode === 13 && !event.shiftKey && isContentModified) {
		// 	onSaveClickHandler();
		// }
	};

	const inputPrivateChangeHandler = (inputValue: any) => {
		if (isContentModified === false) setIsContentModified(true);
		setPrivateNotes(inputValue.target.value);
	};

	const inputPrivateKeyHandler = (event: any) => {
		if (event.keyCode === 27) {
			closeButtonHandler();
		}
		if (event.keyCode === 13 && !event.shiftKey && isContentModified) {
			onSaveClickHandler();
		}
	};

	const closeButtonHandler = () => {
		if (onCloseButtonModal) onCloseButtonModal();
		clearInput();
		dispatch(enableAppMain());
	};

	const onSaveClickHandler = () => {
		// eslint-disable-next-line no-restricted-globals
		if (confirm("Vuoi veramente chiudere questa lezione?")) {
			if (onCloseButtonModal) onCloseButtonModal();
			onSaveButtonModal(customerNotes, privateNotes);
			clearInput();
			dispatch(enableAppMain());
		}
	};

	return (
		<BaseModal
			isBackdropClickActive={true}
			onClose={closeButtonHandler}
			isCloseButtonClicked={isCloseButtonClicked}
		>
			<BaseCard
				flavor='outlined'
				maxWidth='700px'
			>
				<CloseLessonBox>
					<NotesRow>
						<NotesColumn>
							<NotesTitle>
								Note per il Cliente:
							</NotesTitle>

							<NotesTextArea
								onKeyDown={inputCustomerKeyHandler}
								onChange={inputCustomerChangeHandler}
								value={customerNotes}
								autoFocus
							/>
						</NotesColumn>

						<NotesColumn>
							<NotesTitle>
								Note Private:
							</NotesTitle>

							<NotesTextArea
								onKeyDown={inputPrivateKeyHandler}
								onChange={inputPrivateChangeHandler}
								value={privateNotes}
							/>
						</NotesColumn>
					</NotesRow>
					<ButtonsRow>
						<CancelButton onClick={closeButtonHandler}>Annulla</CancelButton>

						<SaveButton
							disabled={!isContentModified}
							onClick={onSaveClickHandler}
						>
							Salva Modifiche
						</SaveButton>
					</ButtonsRow>
				</CloseLessonBox>
			</BaseCard>
		</BaseModal>
	);
};

export default CloseLessonModal;
