import { FC, useEffect, useState } from 'react';

//redux
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { enableAppMain } from 'redux/actions/actions-app-main';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsMounted } from 'usehooks-ts';
import useHasRole from 'hooks/useHasRole';
import useHasPermission from 'hooks/useHasPermission';

//utils
import { getSearchUsers } from './services/UsersPage.services';

//types
import { User } from 'types/User';
import { TitleBreadCrumbSettings } from 'components/DashboardTitleSection/DashboardTitleSection.component.d';
import { ProductSubscription } from 'types/ProductSubscription';
import { UsersTableColumn } from './UsersTable/UsersTable.component.d';

//components
import DashboardTitleSection from 'components/DashboardTitleSection';
import UsersTable from './UsersTable';
import ProductsModal from './UsersTable/Products/ProductsModal';
import NotesModal from './UsersTable/Notes/NotesModal';

//assets
import { ReactComponent as SearchIconSVG } from './assets/searchIcon.svg';
import { ReactComponent as ClearButtonIconSVG } from './assets/clearButtonIcon.svg';

//style
import { Page, SearchButton, SearchUsersBox, SearchClearButton, SearchInput } from './style/UsersPage.style';

const UsersPage: FC<{}> = () => {
	const [searchInputValue, setSearchInputValue] = useState<string>('');
	const [searchInput, setSearchInput] = useState<string>('');
	const [searchResults, setSearchResults] = useState<User[]>();
	const [totalPages, setTotalPages] = useState<number>(0);
	const [totalElements, setTotalElements] = useState<number>(0);
	const [pageSize, setPageSize] = useState<number>(0);
	const [pageNumber, setPageNumber] = useState<number>(1);
	const [isProductsModalVisible, setIsProductModalVisible] = useState<boolean>(false);
	const [productsForModal, setProductsForModal] = useState<ProductSubscription[]>([]);

	const [isNotesModalVisible, setIsNotesModalVisible] = useState<boolean>(false);
	const [notesForModal, setNotesForModal] = useState('');
	const [userIdForNotesModal, setUserIdForNotesModal] = useState(-1);

	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isAdmin = useHasRole('ADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');
	const isAmministrazione = useHasRole('CONTABILITÀ');
	const isAuthor = useHasRole('AUTORE');
	const isPersonalTrainer = useHasRole('PERSONAL TRAINER');
	const isCoach = useHasRole('COACH');
	const isSupervisoreAutostrada = useHasRole('SUPERVISORE_AUTOSTRADA');
	const isAdminAutostrada = useHasRole('ADMIN_AUTOSTRADA');
	const isAssistentePersonale = useHasRole('ASSISTENTE-PERSONALE');

	const dispatch = useAppDispatch();
	const isMounted = useIsMounted();

	const clearSearchHandler = () => {
		setSearchInput('');
		setSearchInputValue('');
	};

	const searchChangeHandler = (searchValue: any) => {
		setSearchInputValue(searchValue.target.value);
	};

	const nextPageHandler = () => setPageNumber((prevState) => prevState + 1);

	const prevPageHandler = () => setPageNumber((prevState) => prevState - 1);

	const searchKeyHandler = (event: any) => {
		if (event.keyCode === 27) {
			clearSearchHandler();
		}
		if (event.keyCode === 13) {
			setSearchInput(event.target.value);
		}
	};

	const searchButtonHandler = () => {
		if (searchInputValue === '') {
			setSearchResults([]);
			setPageNumber(1);
			setPageSize(0);
			setTotalElements(0);
			setTotalPages(0);
			return;
		}
		setSearchInput(searchInputValue);
	};

	const productsClickHandler = (event: React.MouseEvent, productSubscriptions: ProductSubscription[]) => {
		setIsProductModalVisible(true);
		setProductsForModal(productSubscriptions);
		console.log(productSubscriptions);
	};

	const notesClickHandler = (event: React.MouseEvent, notes: string, userId: number) => {
		setIsNotesModalVisible(true);
		setNotesForModal(notes);
		setUserIdForNotesModal(userId);
	};

	const closeProductsModal = () => {
		setIsProductModalVisible(false);
		dispatch(enableAppMain());
	};

	const closeNotesModal = () => {
		setIsNotesModalVisible(false);
	};

	const searchUsers = async () => {
		const snackbarData: SnackbarOptions = {};

		try {
			var searchParams: any = { q: searchInput, page: pageNumber - 1, size: 20 };
			if (searchInput.length === 0) 
				searchParams = { ...searchParams}; //, sort: ["id,DESC"]};
			const response = await getSearchUsers(searchParams);
			console.log(response.content);

			setSearchResults(response.content);
			setTotalElements(response.totalElements);
			setTotalPages(response.totalPages);
			setPageSize(response.size);
			// localStorage.setItem('userSearchResults', JSON.stringify(response.content));
		} catch (error: any) {
			snackbarData.type = 'error';
			snackbarData.message = 'Errore nella ricerca degli utenti';
			dispatch(addSnackbar(snackbarData));
			if (!isMounted()) return;
		}
	};
	// useEffect(() => {
	// 	const lastSearch = localStorage.getItem('userSearchResults');
	// 	console.log('lastSearch', lastSearch);
	// 	const lastSearchJSON: User[] = lastSearch && lastSearch !== 'undefined' ? JSON.parse(lastSearch) : [];
	// 	setSearchResults(lastSearchJSON);
	// }, []);

	useEffect(() => {
		if (searchInput.trim() !== '') searchUsers();
	}, [searchInput, pageNumber]);

	const isSearchButtonVisible: boolean = searchInputValue !== '';
	const breadCrumbSettings: TitleBreadCrumbSettings = { subSectionText: 'Gestione Utenti' };

	const columnsMapping: UsersTableColumn[] = [
		{ label: '', width: '1%', enabled: true },
		{ label: 'ID', width: '2%', enabled: true },
		{ label: 'ANAGRAFICA', width: '12%', enabled: true },
		{ label: 'SALDO PUNTI', width: '4%', enabled: true },
		{ label: 'FB COLLEGATO', width: '7%', enabled: true },
		// { label: 'MISSIONI', width: '5%', enabled: true },
		{ label: 'BADGE', width: '5%', enabled: true },
		{ label: 'PRODOTTI', width: '4%', enabled: true },
		// {label:'AUTOSTRADA',width: '1%', enabled: true},
		// {label:'LEZ. AP',width: '1%', enabled: true},
		{ label: 'RISULTATI', width: '4%', enabled: true},
		{ label: 'STATS', width: '4%', enabled: true },
		{ label: 'NOTE', width: '2%', enabled: true },
	];

	if (!isSuperAdmin && !isAdmin && !isAmministrazione && !isMiniAdmin && !isAuthor && !isPersonalTrainer && !isCoach && !isSupervisoreAutostrada && !isAdminAutostrada && !isAssistentePersonale) return (<></>);

	return (
		<>
			<Page>
				<DashboardTitleSection breadCrumbSettings={breadCrumbSettings}>
					<SearchUsersBox>
						<SearchInput
							onKeyDown={searchKeyHandler}
							onChange={searchChangeHandler}
							placeholder='Ricerca utenti'
							value={searchInputValue}
						/>

						{isSearchButtonVisible && (
							<SearchClearButton onClick={clearSearchHandler}>
								<ClearButtonIconSVG />
							</SearchClearButton>
						)}

						<SearchButton onClick={searchButtonHandler}>
							<SearchIconSVG />
						</SearchButton>
					</SearchUsersBox>
				</DashboardTitleSection>

				{searchResults && (
					<UsersTable
						data={searchResults}
						columnsMapping={columnsMapping}
						totalElements={totalElements}
						totalPages={totalPages}
						pageSize={pageSize}
						pageNumber={pageNumber}
						nextPageHandler={nextPageHandler}
						prevPageHandler={prevPageHandler}
						productsClickHandler={productsClickHandler}
						notesClickHandler={notesClickHandler}
					/>
				)}
			</Page>
			{isProductsModalVisible && (
				<ProductsModal
					onCloseButtonModal={closeProductsModal}
					productSubscriptions={productsForModal}
				/>
			)}
			{isNotesModalVisible && (
				<NotesModal
					onCloseButtonModal={closeNotesModal}
					notes={notesForModal}
					userId={userIdForNotesModal}
					searchUsers={searchUsers}
				/>
			)}
		</>
	);
};

export default UsersPage;
