import styled from 'styled-components';

export const TitleSection = styled.div`
	display: flex;
	gap: 1rem;
	width: 100%;
	margin: 15px 0;
	padding: 15px;
	align-items: center;
	justify-content: space-between;
`;
