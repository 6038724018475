import styled, { css } from 'styled-components';

//style
import colors from 'style/palette/palette';

export const IsPinnedBroadcastBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const IsPinnedBroadcastIcon = styled.button<{ ispinnedbroadcast: boolean; canToggle: boolean }>`
	display: flex;
	background-color: ${colors.transparent};
	border: none;

	${({ canToggle }) =>
		canToggle &&
		css`
			cursor: pointer;
		`}

	${({ ispinnedbroadcast }) =>
		ispinnedbroadcast &&
		css`
			> svg path {
				fill: ${colors.green};
				fill-opacity: 1;
			}
		`}

	${({ ispinnedbroadcast }) =>
		!ispinnedbroadcast &&
		css`
			> svg path {
				fill: ${colors.red};
				fill-opacity: 1;
			}
		`}
`;

export const PriceType = styled.div`
	display: flex;
	flex-wrap: nowrap;
	color: ${colors.white};
`;

export const PriceTypeSpec = styled.div`
	display: flex;
	flex-wrap: nowrap;
	margin: 2px 0 0;
	font-size: 1.1em;
	font-weight: bold;
	color: ${colors.green};
	cursor: pointer;
`;