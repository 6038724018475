import { callPUT } from 'network/network';

const _USER_MISSION_PATH = 'v1/user_missions';

export const putUserMission = async (userId: number, missionId: number, completed: boolean) => {
	try {
		const params = { completed };
		const response = await callPUT(_USER_MISSION_PATH + '/' + userId + '/' + missionId, {}, true, params);

		return response;
	} catch (error: any) {
		throw error;
	}
};
